export const URL = 'info/authority'; // /info/authority/:pageSize/:pageNumber
export const URL_GET_ACCOUNT = 'info/admin';
export const URL_DELETE = 'admin/delete';
export const URL_EDIT = 'admin/edit';
export const URL_CREATE = 'admin/import-account';
export const URL_DOWNLOAD = 'admin/download/sample-account-file';
export const URL_EXPPORT_ACCOUNT = 'info/admin/export';

export const GET_LIST = 'GET_ACCOUNT_LIST_LIST';
export const SET_CHOOSE_LIST = 'SET_LIST_CHOOSE';
export const SET_FILTER = 'SET_ACCOUNT_LIST_FILTER';
export const SET_TOTAL = 'SET_ACCOUNT_TOTAL';
export const SET_SENDING = 'SET_SACCOUNT_STATUS_SENDING';
export const SET_LIST_CHOSE = 'SET_LIST_CHOSE';
