// export const URL = "hotline";
import * as constants from './constants';
import { callApi, callDownloadApi, callExportApi } from './../../../../common';
import { handleError } from '@configs/handleError';
import PNotify from 'pnotify/dist/es/PNotify';
import FileSaver from 'file-saver';
import i18n from 'i18n';

export const getGuestApi = (data) => {
  const { pageSize, pageNumber, search, online } = data;
  return (dispatch) => {
    if (search === '' || search === null || search === undefined)
      callApi(`${constants.URL_GET_GUEST}/${pageSize}/${pageNumber}`, 'GET', {
        search,
        online,
      })
        .then((res) => {
          dispatch(getListGuest(res.data.data.shareholders));
          dispatch(setTotal(res.data.data.total));
        })
        .catch(handleError);
    else
      callApi(`${constants.URL_GET_GUEST}/${pageSize}/${pageNumber}`, 'GET', {
        search,
        online,
      })
        .then((res) => {
          dispatch(getListGuest(res.data.data.shareholders));
          dispatch(setTotal(res.data.data.total));
        })
        .catch(handleError);
  };
};

export const createGuestApi = (data, filter, setToggleModal) => {
  return (dispatch) => {
    callApi(`${constants.URL_CREATE_GUEST}`, 'POST', data).then((res) => {
      setTimeout(() => {
        dispatch(getGuestApi(filter));
      }, 200);
      setToggleModal(false);
      dispatch(setListChose([]));
      PNotify.success({
        title: 'Thành công',
        text: 'Thêm tài khoản khách mời thành công',
        destroy: true,
        delay: 2000,
      });
    });
  };
};

export const editGuestApi = (id, data, filter, setToggleModal) => {
  return (dispatch) => {
    callApi(`${constants.URL_EDIT_GUEST}/${id}`, 'POST', data).then((res) => {
      setTimeout(() => {
        dispatch(getGuestApi(filter));
      }, 200);
      setToggleModal(false);
      dispatch(setListChose([]));
      PNotify.success({
        title: 'Thành công',
        text: 'Sửa tài khoản khách mời thành công',
        destroy: true,
        delay: 2000,
      });
    });
  };
};

export const deleteGuestApi = (ids, filter) => {
  const filterNew = {
    ...filter,
    pageNumber: 1,
    pageSize: 10,
  };
  return (dispatch) => {
    callApi(`${constants.URL_DELETE_GUEST}`, 'DELETE', { ids })
      .then((res) => {
        setTimeout(() => {
          dispatch(getGuestApi(filterNew));
          dispatch(setListChose([]));
        }, 200);
        PNotify.success({
          title: 'Thành công',
          text: 'Xóa tài khoản khách mời thành công',
          destroy: true,
          delay: 2000,
        });
      })
      .catch(handleError);
  };
};

export const addGuestListApi = (body, filter) => {
  return (dispatch) =>
    callApi(`${constants.URL_ADD_GUEST}`, 'POST', body)
      .then(function (res) {
        dispatch(setSending(false));
        PNotify.success({
          title: 'Thành công',
          text: 'Tạo danh sách thành công',
          destroy: true,
          delay: 2000,
        });
        setTimeout(() => {
          // dispatch(setFilter(filter));
          dispatch(getGuestApi(filter));
        }, 500);
      })
      .catch(function (err) {
        const message = err?.response?.data?.message;
        dispatch(setSending(false));
      });
};

export const downloadFileAPI = () => {
  return (dispatch) =>
    callDownloadApi(`${constants.URL_DOWNLOAD}`, 'GET', null).then(function (
      res,
    ) {
      var file = new Blob([res.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
      });
      FileSaver.saveAs(file, 'Danhsachmau');
    });
};

export const exportGuestApi = (body) => {
  return (dispatch) =>
    callExportApi(`${constants.URL_EXPORT_GUEST}`, 'POST', body)
      .then(function (res) {
        var file = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
        });
        FileSaver.saveAs(file, 'Danhsachkhachmoi');
      })
      .catch(handleError);
};

export const getListGuest = (value) => {
  return {
    type: constants.GET_LIST_GUEST,
    value,
  };
};

export const setFilter = (value) => {
  return {
    type: constants.SET_FILTER,
    value,
  };
};

export const setTotal = (value) => {
  return {
    type: constants.SET_TOTAL,
    value,
  };
};

export const setListChose = (value) => {
  return {
    type: constants.SET_LIST_CHOSE,
    value,
  };
};

export const setSending = (value) => {
  return {
    type: constants.SET_SENDING,
    value,
  };
};
