export const URL = "admin/register-officer";
export const URL_TABLE = "info/table";
export const URL_EDIT = "admin/edit";
export const URL_DELETE = "admin/delete";


export const GET_LIST = "GET_DEPARTMENT_LIST";
export const SET_CHOOSE_LIST = "SET_DEPARTMENT_CHOOSE_LIST";
export const TOGGLE_MODAL = "TOGGLE_MODAL";
export const CREATE_NEW_STATUS = "CREATE_NEW_STATUS";
export const SET_FILTER = "SET_FILTER";
export const SET_TOTAL = "SET_TOTAL";




