// the translations
// (tip move them in a JSON file and import them)
import i18n from 'i18n';
import { errorEn, errorVi } from './error';
import { vi1, en1 } from './file1';
import { vi2, en2 } from './file2';
import { en3, vi3 } from './file3';
import { en4, vi4 } from './file4';
import { en5, vi5 } from './file5';

const vi = { ...vi1, ...vi2, ...vi3, ...vi4, ...vi5, ...errorVi };
const en = { ...en1, ...en2, ...en3, ...en4, ...en5, ...errorEn };

const translate = {
  vi: {
    translation: vi,
  },
  en: {
    translation: en,
  },
};

export default translate;

const source = { vi, en };

export const t = (message) => {
  const lang = i18n?.language || 'vi';
  return source?.[lang]?.[message] || message;
};

export const translateError = async (error) => {
  const message =
    error?.response?.data instanceof Blob
      ? JSON.parse(await error?.response?.data.text()).message
      : error?.response?.data?.message || 'Error';
  if (typeof message === 'string') {
    return t(message);
  }
  if (message.error) {
    let temp = t(message.error);
    temp = temp
      .replaceAll('[identityNumber]', message.identityNumber || '')
      .replaceAll('${account}', message.account || '')
      .replaceAll('[name]', message.name || '')
      .replaceAll('[id]', message.id || '');
    return temp;
  }
};
