import * as types from './constants';

var initialState = {
   topic: null,
   report: null
};
var reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_TOPIC:
            return {...state, topic: action.value};
        case types.GET_REPORT:
            return {...state, report: action.value};
        default:
            return state;
    }
}
export default reducer;