import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  getSearchApi,
  setFilter,
} from './../../../../states/duck/pages/checkin/checkInHandReducer/actions';
import { SFrom } from '@styled/checkIn/checkInHandPage';
import inputIcons from '@components/icons/input';
import Input from '@components/input';
import ErrorMessageComponent from '@components/input/ErrorMessage';
import Button from '@components/button';
import { t } from 'translate';

export const optionStatus = [
  { value: 'all', label: t('Tất cả') },
  { value: 'true', label: t('Đã check in') },
  { value: 'false', label: t('Chưa check in') },
];
export const optiJoinType = [
  { value: 'all', label: t('Tất cả') },
  { value: 'none', label: t('Chưa checkin') },
  { value: 'authority', label: t('Ủy quyền') },
  { value: 'live', label: t('Trực tiếp') },
  { value: 'online', label: t('Trực tuyến') },
];
const Form = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    control,
  } = useForm();
  const dispatch = useDispatch();
  const { filter } = useSelector((state) => state.checkin.checkInHand);
  useEffect(() => {
    setValue('idCard', filter.idCard);
    setValue('isCheckedIn', filter.isCheckedIn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue]);

  const searchFn = (values) => {
    dispatch(
      getSearchApi({
        ...filter,
        identityNumber: values.idCard,
        isCheckedIn: values.isCheckedIn?.value || 'all',
        joinType: values.isJoinType?.value || 'all',
        pageNumber: 1,
      }),
    );
  };

  return (
    <SFrom>
      <h1 className="title">{t('Xử lý checkin thủ công tại điểm họp')}</h1>
      <form className="form-input" onSubmit={handleSubmit(searchFn)}>
        <div className="item">
          <Input.Text
            wrapperProps={{
              label: `${t('Họ tên/CMND/CCCD/SĐT')}`,
              icon: inputIcons.code,
            }}
            inputProps={{
              type: 'text',
            }}
            register={register('idCard', {
              required: false,
            })}
          />
          <ErrorMessageComponent errors={errors} field="idCard" />
        </div>

        <div className="item">
          <Input.Select
            name="isCheckedIn"
            control={control}
            selectProps={{
              placeholder: t('Trạng thái'),
              options: optionStatus,
            }}
            rules={{
              required: false,
            }}
          />
          <ErrorMessageComponent errors={errors} field="isCheckedIn" />
        </div>
        <div className="item">
          <Input.Select
            name="isJoinType"
            control={control}
            selectProps={{
              placeholder: t('Loại hình tham dự'),
              options: optiJoinType,
            }}
            rules={{
              required: false,
            }}
          />
          <ErrorMessageComponent errors={errors} field="isJoinType" />
        </div>

        <div className="item">
          <Button className="common__btn" type="submit">
            {t('Tìm kiếm')}
          </Button>
        </div>
      </form>
    </SFrom>
  );
};

export default Form;
