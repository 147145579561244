import * as constants from './constants';
import {
  callApi,
} from '../../../../common';
import { handleError } from '@configs/handleError';

export const getInfoConfigSystemApi = () => {
  return (dispatch) =>
    callApi(`${constants.URL}`, 'GET', null)
      .then((res) => {
        dispatch(getInfo(res.data.data));
      })
      .catch(handleError);
};

export const setConfigSystemApi = (body) => {
  return (dispatch) =>
    callApi(`${constants.URL_TOGGLE}`, 'PUT', body)
      .then((res) => {
        dispatch(getInfoConfigSystemApi());
      })
      .catch(handleError);
};

export const setRepliedMessagesApi = (body) => {
  return (dispatch) =>
    callApi(`${constants.URL_SET_REPLIED_MESSAGE}`, 'PUT', body)
      .then((res) => {
        dispatch(getInfoConfigSystemApi());
      })
      .catch(handleError);
};

export const setVoteSelectionsApi = (body) => {
  return (dispatch) =>
    callApi(`${constants.URL_SET_VOTE_SELECTION}`, 'PUT', body)
      .then((res) => {
        dispatch(getInfoConfigSystemApi());
      })
      .catch(handleError);
};

export const setLoading = (value) => {
  return {
    type: constants.SET_LOADING,
    value,
  };
};

export const getInfo = (value) => {
  return {
    type: constants.GET_INFO,
    value,
  };
};

export const setConfigSystem = (value) => {
  return {
    type: constants.SET_STATUS,
    value,
  };
};
