import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setFilter} from './../../../../states/duck/pages/checkCoupon/checkCouponArtisanReducer/actions';
import {useForm} from 'react-hook-form';
import Section from '@components/section';
import Input from '@components/input';
import inputIcons from '@components/icons/input';
import Button from '@components/button';
import {useTranslation} from 'react-i18next';
const optionStatus = [
  {value: 'all', label: 'Trạng thái'},
  {value: 'notJoin', label: 'Chưa tham gia'}, // joinType:none
  {value: 'notCopy', label: 'Chưa sao chép'}, //  status: notVote
  {value: 'copied', label: 'Đã sao chép'}, // status: voted, isManual: true
  {value: 'voted', label: 'Đã biểu quyết'}, // status: voted, isManual: false
];
const optionStatusEn = [
  {value: 'all', label: 'Status'},
  {value: 'notJoin', label: 'Not participate'}, // joinType:none
  {value: 'notCopy', label: 'Not copied yet'}, //  status: notVote
  {value: 'copied', label: 'Copied'}, // status: voted, isManual: true
  {value: 'voted', label: 'Voted'}, // status: voted, isManual: false
];
const Form = () => {
  const {filter} = useSelector((state) => state.checkCoupon.checkCouponArtisan);
  const {t, i18n} = useTranslation();
  const {handleSubmit, register, control} = useForm();
  const dispatch = useDispatch();
  const searchFn = (values) => {
    const {identityNumber, status} = values;
    let tmp = {
      ...filter,
    };
    if (status) {
      switch (status.value) {
        case 'all':
          tmp = {
            ...tmp,
            joinType: 'all',
            isManual: 'all',
            status: 'all',
          };
          break;
        case 'notJoin':
          tmp = {
            ...tmp,
            joinType: 'none',
            isManual: 'all',
            status: 'all',
          };
          break;
        case 'notCopy':
          tmp = {
            ...tmp,
            joinType: 'onlineOrLive',
            isManual: 'all',
            status: 'notVote',
          };
          break;
        case 'copied':
          tmp = {
            ...tmp,
            joinType: 'all',
            isManual: 'true',
            status: 'voted',
          };
          break;
        case 'voted':
          tmp = {
            ...tmp,
            joinType: 'all',
            isManual: 'false',
            status: 'voted',
          };
          break;
        default:
          tmp = {
            ...tmp,
            joinType: 'all',
            isManual: 'all',
            status: 'all',
          };
      }
    }
    dispatch(
      setFilter({
        ...tmp,
        pageNumber: 1,
        identityNumber,
        // status: status ? status.value : 'all',
      }),
    );
  };

  return (
    <Section header={t('Kiểm phiếu cho Cổ đông biểu quyết bằng phiếu giấy')}>
      <div className="module-content">
        <div className="search-wrapper">
          <div className="search-item">
            <Input.Text
              register={register('identityNumber')}
              wrapperProps={{
                label: t('CMND/CCCD'),
                icon: inputIcons.account,
                height: '40px',
              }}
            />
          </div>
          <div className="search-item">
            <Input.Select
              name="status"
              control={control}
              selectProps={{
                placeholder: t('Trạng thái'),
                options: i18n.language === 'vi' ? optionStatus : optionStatusEn,
              }}
            />
          </div>
          <div className="search-item">
            <Button onClick={handleSubmit(searchFn)}> {t('Tìm kiếm')} </Button>
          </div>
        </div>
      </div>
    </Section>
  );
};
export default Form;
