import {css} from 'styled-components';

export const mixinTextOverflow = (lineNumber) => `
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: ${lineNumber};
  -webkit-box-orient: vertical;
`;

export const mixinsFlexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;
