import React from 'react';
// import {SButtonCopy} from '@styled/checkCoupon/checkCouponArtisanPage';
import { useDispatch, useSelector } from 'react-redux';
import {
  setToggleModal,
  setFilterModal,
} from '@duck/pages/checkCoupon/checkCouponResultReducer/actions';
import { STableCustom } from '@styled/checkCoupon/checkCouponResultPage';
import {
  transformNumberPrinter,
  transformPercentNumber,
} from '@configs/transformNumber';
import { CumulativeVoteItem } from './cumulativeRow';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';

export const TableCustom = ({ kind, children, minWidth = '2100px' }) => {
  //kind = 1 2 3 : same => full table
  //kind = 4: => a half
  const { t } = useTranslation();
  const info = useSelector((state) => state.admin.configSystem.info);
  return (
    <STableCustom>
      <div className="table-container">
        <table style={{ minWidth }} className="sticky-custom">
          <thead>
            <tr className="bold">
              <th rowSpan={2} className="th_sticky">
                {t('STT')}
              </th>
              {/* <th rowSpan={2} colSpan={2} className="th_sticky">
              Xem chi tiết phiếu bầu
            </th> */}
              <th rowSpan={2} className="th_sticky">
                {t('Nội dung')}
              </th>
              <th colSpan={2}>{t('Tổng số phiếu biểu quyết')}</th>
              <th colSpan={3}>{t('Tổng số phiếu hợp lệ')}</th>
              <th colSpan={3}>{t('Tổng số phiếu không hợp lệ')}</th>
              {info?.voteSelections?.map((item, index) => {
                return (
                  kind !== 4 &&
                  item.lang === i18n?.language && (
                    <th colSpan={3}>{item.displayName}</th>
                  )
                );
              })}
              {/* {kind !== 4 && (
                <th colSpan={3}>{t('Tổng số phiếu tán thành')}</th>
              )}
              {kind !== 4 && (
                <th colSpan={3}>{t('Tổng số phiếu không tán thành')}</th>
              )}
              {kind !== 4 && (
                <th colSpan={3}>{t('Tổng số phiếu không ý kiến')}</th>
              )} */}
            </tr>
            <tr>
              <th>{t('Cổ đông')}</th>
              <th>{kind === 4 ? t('Phiếu bầu') : t('Cổ phần')}</th>

              <th>{t('Cổ đông')}</th>
              <th>{kind === 4 ? t('Phiếu bầu') : t('Cổ phần')}</th>
              <th>{t('Tỷ lệ')}</th>

              <th>{t('Cổ đông')}</th>
              <th>{kind === 4 ? t('Phiếu bầu') : t('Cổ phần')}</th>
              <th>{t('Tỷ lệ')}</th>

              {info?.voteSelections?.map((item, index) => {
                return (
                  kind !== 4 &&
                  item.lang === i18n?.language && (
                    <>
                      <th>{t('Cổ đông')}</th>
                      <th>{t('Cổ phần')}</th>
                      <th>{t('Tỷ lệ')}</th>
                    </>
                  )
                );
              })}

              {/* {kind !== 4 && <th>{t('Cổ đông')}</th>}
              {kind !== 4 && <th>{t('Cổ phần')}</th>}
              {kind !== 4 && <th>{t('Tỷ lệ')}</th>}

              {kind !== 4 && <th>{t('Cổ đông')}</th>}
              {kind !== 4 && <th>{t('Cổ phần')}</th>}
              {kind !== 4 && <th>{t('Tỷ lệ')}</th>}

              {kind !== 4 && <th>{t('Cổ đông')}</th>}
              {kind !== 4 && <th>{t('Cổ phần')}</th>}
              {kind !== 4 && <th>{t('Tỷ lệ')}</th>} */}
            </tr>
          </thead>
          <tbody>{children}</tbody>
        </table>
      </div>
    </STableCustom>
  );
};

export const TableCustomType123 = ({
  listTopic,
  kind,
  setVoteType,
  setValid,
  setKindTopic,
  setTopicName,
}) => {
  const dispatch = useDispatch();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const info = useSelector((state) => state.admin.configSystem.info);
  return (
    <TableCustom kind={kind} minWidth={kind === 4 ? '1300px' : '2100px'}>
      {listTopic.length > 0 ? (
        listTopic.map((item, index) => {
          return (
            <tr key={index}>
              <td className="td_sticky">{index + 1}</td>
              <td className="td_sticky left" style={{ maxWidth: '300px' }}>
                {item.topic
                  ? language === 'vi'
                    ? item.topic.name
                    : item.topic.name_2
                  : ''}{' '}
              </td>
              <td>
                {transformNumberPrinter(
                  item.validNumbers + item.invalidNumbers,
                )}
              </td>
              <td>
                {transformNumberPrinter(item.validShares + item.invalidShares)}
              </td>

              <td>
                <span
                  className="text-link"
                  onClick={() => {
                    setVoteType('bieu-quyet');
                    setValid(true);
                    setKindTopic(kind);
                    dispatch(setToggleModal(true));
                    setTopicName(
                      item.topic
                        ? language === 'vi'
                          ? item.topic.name
                          : item.topic.name_2
                        : '',
                    );
                    dispatch(
                      setFilterModal({
                        take: 10,
                        page: 1,
                        kind: kind,
                        topicId: item.topic._id,
                        point: 'all',
                        invalidVoted: false,
                        fixId: '',
                        selectName: '',
                        search: '',
                        voteStatus: 'voted',
                      }),
                    );
                  }}
                >
                  {transformNumberPrinter(item.validNumbers)}
                </span>
              </td>
              <td>{transformNumberPrinter(item.validShares)}</td>
              <td>
                {Number.isInteger(item.validSharesPercentPerVoted * 100)
                  ? item.validSharesPercentPerVoted * 100
                  : transformPercentNumber(
                      item.validSharesPercentPerVoted * 100,
                    )}
                %
              </td>

              <td>
                <span
                  className="text-link"
                  onClick={() => {
                    setVoteType('bieu-quyet');
                    setValid(false);
                    setKindTopic(kind);
                    dispatch(setToggleModal(true));
                    setTopicName(
                      item.topic
                        ? language === 'vi'
                          ? item.topic.name
                          : item.topic.name_2
                        : '',
                    );
                    dispatch(
                      setFilterModal({
                        take: 10,
                        page: 1,
                        kind: kind,
                        topicId: item.topic._id,
                        point: 'all',
                        invalidVoted: true,
                        fixId: '',
                        selectName: '',
                        search: '',
                        voteStatus: 'voted',
                      }),
                    );
                  }}
                >
                  {transformNumberPrinter(item.invalidNumbers)}
                </span>
              </td>
              <td>{transformNumberPrinter(item.invalidShares)}</td>
              <td>
                {Number.isInteger(item.invalidSharesPercentPerVoted * 100)
                  ? item.invalidSharesPercentPerVoted * 100
                  : transformPercentNumber(
                      item.invalidSharesPercentPerVoted * 100,
                    )}
                %
              </td>
              {kind !== 4 ? (
                <>
                  {info?.voteSelections?.find(
                    (item) => item.point === 1 && item.lang === language,
                  ) && (
                    <>
                      <td>{transformNumberPrinter(item.acceptanceNumbers)}</td>
                      <td>{transformNumberPrinter(item.acceptanceShares)}</td>
                      <td>
                        {Number.isInteger(
                          item.acceptanceSharesPercentPerValid * 100,
                        )
                          ? item.acceptanceSharesPercentPerValid * 100
                          : transformPercentNumber(
                              item.acceptanceSharesPercentPerValid * 100,
                            )}
                        %
                      </td>
                    </>
                  )}

                  {info?.voteSelections?.find(
                    (item) => item.point === 2 && item.lang === language,
                  ) && (
                    <>
                      <td>
                        {transformNumberPrinter(item.notAcceptanceNumbers)}
                      </td>
                      <td>
                        {transformNumberPrinter(item.notAcceptanceShares)}
                      </td>
                      <td>
                        {Number.isInteger(
                          item.notAcceptanceSharesPercentPerValid * 100,
                        )
                          ? item.notAcceptanceSharesPercentPerValid * 100
                          : transformPercentNumber(
                              item.notAcceptanceSharesPercentPerValid * 100,
                            )}
                        %
                      </td>
                    </>
                  )}

                  {info?.voteSelections?.find(
                    (item) => item.point === 3 && item.lang === language,
                  ) && (
                    <>
                      <td>{transformNumberPrinter(item.noIdeaNumbers)}</td>
                      <td>{transformNumberPrinter(item.noIdeaShares)}</td>
                      <td>
                        {Number.isInteger(
                          item.noIdeaSharesPercentPerValid * 100,
                        )
                          ? item.noIdeaSharesPercentPerValid * 100
                          : transformPercentNumber(
                              item.noIdeaSharesPercentPerValid * 100,
                            )}
                        %
                      </td>
                    </>
                  )}
                </>
              ) : (
                ''
              )}
            </tr>
          );
        })
      ) : (
        <tr>
          <td colSpan={100} className="center">
            {t('Chưa có dữ liệu')}
          </td>
        </tr>
      )}
    </TableCustom>
  );
};
export const TableCustomType4 = ({
  listTopic,
  kind,
  setVoteType,
  setValid,
  setKindTopic,
  setTopicName,
}) => {
  const { t } = useTranslation();
  return (
    <TableCustom kind={kind} minWidth={kind === 4 ? '1300px' : '2100px'}>
      {listTopic.length > 0 ? (
        listTopic.map((item, index) => {
          return (
            <CumulativeVoteItem
              key={index}
              {...{
                index,
                item,
                kind,
                setVoteType,
                setValid,
                setKindTopic,
                setTopicName,
              }}
            />
          );
        })
      ) : (
        <tr>
          <td colSpan={100} className="center">
            {t('Chưa có dữ liệu')}
          </td>
        </tr>
      )}
    </TableCustom>
  );
};
