import * as constants from './constants';
import { callApi } from '../../../../common';
import PNotify from 'pnotify/dist/es/PNotify';
import { handleError } from '@configs/handleError';

export const getUserApi = () => {
  return (dispatch) =>
    callApi(`${constants.URL_LIST}`, 'GET').then(function (res) {
      dispatch(getListAccount(res.data?.data?.data || {}));
    });
};

export const getStatisticApi = () => {
  return (dispatch) =>
    callApi(`${constants.URL_INFO}`, 'GET', null)
      .then(function (res) {
        dispatch(setStatistic(res.data.data));
      })
      .catch(function (err) {
        if (err) {
          if (err.response && err.response.data && err.response.data.message) {
            PNotify.error({
              title: 'Lỗi',
              text: err.response.data.message,
              destroy: true,
              delay: 2000,
            });
          } else if (err.response) {
            PNotify.error({
              title: 'Lỗi',
              text: err.response.statusText,
              destroy: true,
              delay: 2000,
            });
          }
        }
      });
};

export const getCreateApi = () => {
  return (dispatch) =>
    callApi(`${constants.URL_CREATE}`, 'POST')
      .then(function () {
        PNotify.success({
          title: 'Thành công',
          text: 'Xuất biên bản kiểm tra tư cách CĐ thành công',
          destroy: true,
          delay: 2000,
        });
        setTimeout(() => {
          dispatch(getUserApi());
        }, 500);
      })
      .catch(handleError);
};

export const setStatistic = (value) => {
  return {
    type: constants.GET_STATISTIC,
    value,
  };
};

export const getListAccount = (value) => {
  return {
    type: constants.GET_LIST,
    value,
  };
};
