import Button from '@components/button';
import { SModalConfirm } from '@styled/checkCoupon/checkCouponArtisanPage';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function ModalConfirm({
  kind,
  // agree,
  // disagree,
  // noIdea,
  onConfirm,
  onRefuse,
  totalTopic,
  result,
}) {
  const { t } = useTranslation();
  const [topicsValid, setTopicsValid] = useState([]); // list vote valid
  useEffect(() => {
    console.log(result);
    if (kind !== 4 && result) {
      let tmpResult = [...result];
      for (let i = 0; i < tmpResult.length - 1; i++) {
        let isInvalid = false;
        for (let j = i + 1; j < tmpResult.length; j++) {
          if (
            tmpResult[i] &&
            tmpResult[j] &&
            tmpResult[i].topicId === tmpResult[j].topicId
          ) {
            isInvalid = true;
            tmpResult.splice(j, 1);
            j--;
          }
        }
        if (isInvalid) {
          tmpResult.splice(i, 1);
          i--;
        }
      }
      setTopicsValid(tmpResult);
    }
  }, [result, kind, totalTopic]);
  return (
    <SModalConfirm>
      {kind === 4 ? (
        <div className="confirm" style={{ textAlign: 'center', padding: '0' }}>
          {t('Cổ đông chắc chắn muốn gửi biểu quyết này?')}
        </div>
      ) : (
        <>
          <div className="header">
            {t('Biểu quyết thông qua')}{' '}
            {kind === 1
              ? t('thủ tục khai mạc Đại hội')
              : kind === 2
              ? t('các Báo cáo/ Tờ trình')
              : kind === 6
              ? t('nội dung bầu cử (Bầu dồn phiếu)')
              : t('Biên bản và Nghị quyết Đại hội')}
          </div>
          <div className="list">
            <div>
              {t('Tổng số nội dung tán thành')}{' '}
              {`${
                topicsValid.filter((item) => item.point === 1).length
              }/${totalTopic}`}
            </div>
            <div>
              {t('Tổng số nội dung không tán thành')}{' '}
              {`${
                topicsValid.filter((item) => item.point === 2).length
              }/${totalTopic}`}
            </div>
            <div>
              {t('Tổng số nội dung không ý kiến')}{' '}
              {`${
                topicsValid.filter((item) => item.point === 3).length
              }/${totalTopic}`}
            </div>
            <div>
              {t('Tổng số nội dung chưa biểu quyết/không hợp lệ')}{' '}
              {`${totalTopic - topicsValid.length}/${totalTopic}`}
            </div>
          </div>
          <div className="confirm">
            {t('Cổ đông chắc chắn muốn gửi biểu quyết này?')}
          </div>
        </>
      )}
      <div className="button-container">
        <Button
          style={{ color: '#718098', backgroundColor: '#DFE9F5' }}
          onClick={onRefuse}
        >
          {t('Hủy bỏ')}
        </Button>
        <Button onClick={onConfirm}>{t('Xác nhận')}</Button>
      </div>
    </SModalConfirm>
  );
}
