import { StylesTable } from '@components/table';
import styled from 'styled-components';
export const SCheckCouponResultPage = styled.div`
  .big {
    margin: 24px 0 0;
    color: #333b6a;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
  .result-item {
    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        margin: 24px 0 20px;
        color: #333b6a;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }
      .group-btn {
        display: flex;
        align-items: center;
      }
      .button {
        button {
          width: 205px;
          border-radius: 0;
          margin: 8px;
          height: 32px;
          padding: 5px 10px;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            margin-right: 8px;
          }
          background: #2f80ed;
        }
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .result-item {
      .head {
        flex-wrap: wrap;
        .title {
          margin-bottom: 0;
        }
        .button {
          width: 100%;
          button {
            margin-left: auto;
          }
        }
      }
    }
  }
`;

export const SModalViewDetail = styled.div``;

export const SModal = styled.div`
  padding: 0 56px;
  .desc {
    color: #718098;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    margin-bottom: 44px;
  }
  .form {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin: 0 -8px;
    margin-bottom: 10px;
    .form-item {
      width: 25%;
      min-width: unset;
      padding: 0px 8px 10px;
      button {
        width: 100%;
      }
    }
  }
  table {
    tbody {
      tr {
        td {
          border: 1px solid #dfe9f5;
        }
      }
    }
  }
  @media only screen and (max-width: 991px) {
    .form {
      .form-item {
        width: 33%;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    padding: 0 15px;
    .form {
      .form-item {
        width: 50% !important;
      }
    }
  }
  @media only screen and (max-width: 479px) {
    .desc {
      font-size: 15px;
      margin-bottom: 10px;
    }
    .form {
      .form-item {
        width: 100% !important;
      }
    }
  }
`;

export const STableCustom = styled(StylesTable)`
  .sticky-custom {
    .text-link {
      font-weight: 600;
      text-decoration: underline;
      &:hover {
        color: #0cc567;
      }
    }
    thead tr .th_sticky {
      position: sticky;
      z-index: 2;

      &:nth-child(1) {
        min-width: 57px;
        left: 0;
      }
      &:nth-child(2) {
        min-width: 200px;
        left: 57px;
      }
      /* &:nth-child(2) {
        min-width: 270px;
        left: 57px;
      }
      &:nth-child(3) {
        min-width: 200px;
        left: 327px;
      } */
    }
    tbody {
      tr {
        .td_sticky {
          position: sticky;
          z-index: 2;
          background-color: #f4f8fc;
          &:nth-child(1) {
            min-width: 57px;
            left: 0;
          }
          &:nth-child(2) {
            min-width: 200px;
            left: 57px;
          }
          /* &:nth-child(2) {
            min-width: 120px;
            left: 57px;
            button {
              width: 70px;
            }
          }
          &:nth-child(3) {
            min-width: 150px;
            left: 177px;
            button {
              width: 115px;
            }
          }
          &:nth-child(4) {
            min-width: 200px;
            left: 327px;
          } */
        }
      }
    }
  }
  @media only screen and(max-width: 1100px) {
    .sticky-custom {
      thead tr .th_sticky {
        position: unset;
        z-index: unset;

        &:nth-child(1) {
          min-width: unset;
          left: unset;
        }
        &:nth-child(2) {
          min-width: unset;
          left: unset;
        }
        &:nth-child(3) {
          min-width: unset;
          left: unset;
        }
      }
      tbody {
        tr {
          .td_sticky {
            position: unset;
            z-index: unset;
            &:nth-child(1) {
              min-width: unset;
              left: unset;
            }
            &:nth-child(2) {
              min-width: unset;
              left: unset;
              button {
                width: unset;
              }
            }
            &:nth-child(3) {
              min-width: unset;
              left: unset;
              button {
                width: unset;
              }
            }
            &:nth-child(4) {
              min-width: unset;
              left: unset;
            }
          }
        }
      }
    }
  }
  @media only screen and(max-width: 990px) {
    .sticky-custom {
      thead tr .th_sticky {
        position: sticky;
        z-index: 2;

        &:nth-child(1) {
          min-width: 57px;
          left: 0;
        }
        &:nth-child(2) {
          min-width: 270px;
          left: 57px;
        }
        &:nth-child(3) {
          min-width: 200px;
          left: 327px;
        }
      }
      tbody {
        tr {
          .td_sticky {
            position: sticky;
            z-index: 2;
            background-color: #f4f8fc;
            &:nth-child(1) {
              min-width: 57px;
              left: 0;
            }
            &:nth-child(2) {
              min-width: 120px;
              left: 57px;
              button {
                width: 70px;
              }
            }
            &:nth-child(3) {
              min-width: 150px;
              left: 177px;
              button {
                width: 115px;
              }
            }
            &:nth-child(4) {
              min-width: 200px;
              left: 327px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .sticky-custom {
      thead tr .th_sticky {
        position: unset !important;
        z-index: unset !important;

        &:nth-child(1) {
          min-width: unset !important;
          left: unset !important;
        }
        &:nth-child(2) {
          min-width: unset !important;
          left: unset !important;
        }
        &:nth-child(3) {
          min-width: unset !important;
          left: unset !important;
        }
      }
      tbody {
        tr {
          .td_sticky {
            position: unset !important;
            z-index: unset !important;
            &:nth-child(1) {
              min-width: unset !important;
              left: unset;
            }
            &:nth-child(2) {
              min-width: unset !important;
              left: unset !important;
              button {
                width: unset !important;
              }
            }
            &:nth-child(3) {
              min-width: unset !important;
              left: unset !important;
              button {
                width: unset !important;
              }
            }
            &:nth-child(4) {
              min-width: unset !important;
              left: unset !important;
            }
          }
        }
      }
    }
  }
`;
