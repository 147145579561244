export const URL_RESULT = 'conference/result';
export const URL_CONFERENCE = 'conference';
export const URL_OPINION = 'info/opinion';
export const URL_INVITE_SPEAK = 'opinion/speak';
export const URL_SEND_BOSS = 'opinion/send-boss';
export const URL_TOPIC = 'topic/kind';
export const URL_MEMBER = 'info/voted-shareholder-report';

export const GET_LIST = 'GET_RESULT_TIME_LIST';
export const SET_CHOOSE_LIST = 'SET_RESULT_TIME_LIST';
export const SET_FILTER = 'SET_RESULT_TIME_FILTER';
export const SET_TOTAL = 'SET_RESULT_TIME_TOTAL';
export const GET_TOGGLE_MODAL = 'SET_RESULT_TIME_MODAL';

export const SET_TIME_END = 'SET_RESULT_TIME_END';
export const SET_DATA = 'SET_RESULT_DATA';
export const GET_TOPIC1 = 'GET_RESULT_TOPIC1';
export const GET_TOPIC2 = 'GET_RESULT_TOPIC2';
export const GET_TOPIC3 = 'GET_RESULT_TOPIC3';
export const GET_TOPIC4 = 'GET_RESULT_TOPIC4';
export const GET_TOPIC6 = 'GET_RESULT_TOPIC6';

export const SET_CONFERENCE_INFO = 'SET_CONFERENCE_INFO';
export const GET_DATA_MEMBER = 'GET_RESULT_DATA_MEMBER';
