import { SCheckInHandPage } from '@styled/checkIn/checkInHandPage';
import React, { Fragment } from 'react';
import Action from './action';
import Form from './form';
import TableCheckInHand from './table';

const CheckInHandPage = ({ match }) => {
  return (
    <SCheckInHandPage>
      {!match || match === 'thu-cong' ? (
        <div className="module-checkin">
          <div className="module-header">
            <Form />
          </div>
          <div className="module-content">
            <Fragment>
              <div className="table-result">
                <TableCheckInHand />
              </div>
            </Fragment>
          </div>
        </div>
      ) : (
        <Action id={match} type={match} />
      )}
    </SCheckInHandPage>
  );
};
export default CheckInHandPage;
