import * as types from './constants';
var initialState = {
    tableList: [],
    listChose: [],
    filter:{
        pageNumber: 1,
        pageSize:10,
    },
    toggleModal: false,
    total:0,
};
var reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_LIST:
            return {...state, tableList: action.value};
        case types.TOGGLE_MODAL:
            return {...state, toggleModal: action.value};
        case types.SET_CHOOSE_LIST:
            return {...state, listChose: action.value};
        case types.CREATE_NEW_STATUS:
            return {...state, createNewStatus: action.value};
        case types.SET_FILTER:
            return {...state, filter: action.value};
        case types.SET_TOTAL:
            return {...state, total: action.value};
        default:
            return state;
    }
}
export default reducer;