import * as types from './constants';

var initialState = {
  tableList: [],
  statistic: [],
};

var reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_STATISTIC:
      return { ...state, statistic: action.value };

    case types.GET_LIST:
      const result = Object.values(action.value);
      return { ...state, tableList: result };

    default:
      return state;
  }
}
export default reducer;