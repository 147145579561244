import { combineReducers } from "redux";
import checkInHand from "./checkInHandReducer";
import checkInManager from "./checkInManagerReducer";
import authorityList from "./authorityListReducer";
import authoritySetting from "./authoritySettingReducer";
import inspectionRecords from "./inspectionRecordsReducer";

const rootReducer = combineReducers({
    checkInHand,
    checkInManager,
    authorityList,
    authoritySetting,
    inspectionRecords
});
export default rootReducer;
