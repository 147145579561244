import { getInfoConfigSystemApi } from '@duck/pages/admin/configSystemReducer/actions';
import { HomePage, SettingPage } from '@pages/admin';
import ManualAuthorization from '@pages/admin/authorizationListPage/authorizationHandling';
import { getUserInfo } from '@pages/admin/systemPage/store';
import ResultPage from '@pages/resultPage';
import 'pnotify/dist/es/PNotifyButtons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import LoginPage from '../pages/loginPage';
import Header from './header';
import HeaderLeft from './headerLeft';
import { routers } from './router';

const RoutePrivate = ({ path, children, exact }) => {
  const token = useSelector((state) => state.login.token);
  if (token !== '' && token !== null) {
    return (
      <Route path={path} exact={exact} children={children} key={Date.now()} />
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: '/login',
        }}
      />
    );
  }
};

const Layout = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.login.token);
  const userInfo = useSelector((state) => state.login.userInfo);
  const [isNaviHome, setNaviHome] = useState(false);
  useEffect(() => {
    if (token) {
      getUserInfo();
      dispatch(getInfoConfigSystemApi());
    }
  }, [token]);

  useEffect(() => {
    const handlePaste = (e) => {
      setTimeout(() => {
        let value = e.target.value;
        if (value && typeof value == 'string') {
          e.target.value = trimSpace(value);
        }
      }, 100);
    };
    window.addEventListener('paste', handlePaste);
    return () => {
      window.removeEventListener('paste', handlePaste);
    };
  }, []);

  const permissionRouters = routers.filter((r) =>
    userInfo?.permissions?.includes(r.per),
  );
  const currenPage = window.location.pathname;
  const checkPerToNaviHome = () => {
    const temp = permissionRouters.filter((item) => item.path === currenPage);
    if (temp.length === 0 && permissionRouters.length > 0) {
      return true;
    }
  };
  useEffect(() => {
    const temp = checkPerToNaviHome();
    setNaviHome(temp);
  }, [permissionRouters, currenPage, window]);
  return (
    <Router>
      <div className="web-app">
        <div className={`web-content`} id="web-content">
          <Route
            exact
            children={({ history }) => {
              if (
                history.location.pathname !== '/login' &&
                history.location.pathname !== '/ket-qua' &&
                history.location.pathname !== '/chu-tich' &&
                history.location.pathname.indexOf('login') < 0
              ) {
                return (
                  <>
                    <HeaderLeft pathname={history.location.pathname} />
                    <Header pathname={history.location.pathname} />
                  </>
                );
              }
            }}
          />
          <div className="main-right">
            <div id="main">
              <Switch>
                <Route
                  path="/login"
                  exact={true}
                  children={({ match }) => <LoginPage match={match} />}
                />
                {permissionRouters.map((item, index) => {
                  return (
                    <RoutePrivate
                      key={index + 100}
                      path={item.path}
                      children={item.component}
                      exact={true}
                    />
                  );
                })}
                {isNaviHome && (
                  <Redirect
                    to={{
                      pathname: `${permissionRouters[0].path}`,
                    }}
                  />
                )}
                <RoutePrivate
                  path="/"
                  children={({ match }) => <ResultPage />}
                  exact={true}
                />

                <RoutePrivate
                  path="/xu-ly-uy-quyen/:id"
                  children={({ match }) => (
                    <ManualAuthorization match={match} />
                  )}
                  exact={true}
                />
                <RoutePrivate
                  path="/doi-mat-khau"
                  children={({ match }) => <SettingPage />}
                  exact={true}
                />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </Router>
  );
};

export default Layout;

export const trimSpace = (value) =>
  value.replace(/[\u200B-\u200D\uFEFF]/g, '').trim();
