import * as types from './constants';
// import * as common from '../../../../common/constants';
import * as common from 'states/common/constants';

var initialState = {
  list: [],
  listChose: [],
  filter: {
    pageSize: common.PAGE_SIZE,
    pageNumber: 1,
    pageSize: 10,
    search: '',
  },
  total: 0,
  // sending: false,
  // info: {},
};
var reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST:
      return { ...state, list: action.value };
    case types.SET_LIST_CHOSE:
      return { ...state, listChose: action.value };
    case types.SET_FILTER:
      return { ...state, filter: action.value };
    case types.SET_TOTAL:
      return { ...state, total: action.value };
    case types.SET_SENDING:
      return { ...state, sending: action.value };
    default:
      return state;
  }
};
export default reducer;
