import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const UITable = ({
  headers,
  items = [],
  children,
  customHeader,
  minWidth,
  props,
}) => {
  const { t } = useTranslation();
  return (
    <Styles {...props}>
      <table style={{ minWidth: minWidth || '940px' }}>
        {customHeader ? (
          customHeader
        ) : (
          <thead>
            <tr>
              {headers.map((h, ind) => (
                <th key={ind + 100}>{h}</th>
              ))}
            </tr>
          </thead>
        )}

        <tbody>
          {items.map(
            (item, ind) =>
              children && React.cloneElement(children(item, ind), { key: ind }),
          )}
          {items.length === 0 && (
            <tr>
              <td
                colSpan="100"
                style={{ padding: '30px', textAlign: 'center' }}
              >
                {t('Không có dữ liệu')}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </Styles>
  );
};

const Styles = styled.div`
  width: 100%;
  overflow: auto;
  table {
    width: 100%;
    /* border-collapse: separate;
    border-spacing: 2px; */
    thead {
      height: 52px;
      color: white;
      font-size: 14px;
      border-spacing: 0;
      th {
        background: ${(props) => props.theme.headerColor || ''};
        margin: 0 auto;
        padding: 5px 10px;
        white-space: pre;
        font-weight: 400;
        border-right: 1px solid white;
        :last-child {
          border-right: unset;
        }
      }
    }

    .vote-check {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    tbody {
      tr {
        :hover {
          color: #1d2e4b;
        }

        :nth-child(odd) {
          background: ${(props) => {
            return props.theme.backgroundColor || '';
          }};
        }
        :nth-child(even) {
          background: #eff4fa;
        }
      }
      td {
        padding: 10px 20px;
        min-height: 40px;
        font-weight: 700;
        color: ${(props) => props.theme.textColor || ''};
        > * {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        button {
          width: 100%;
          border-radius: unset;
        }
      }
    }
  }
`;
