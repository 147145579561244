import styled from 'styled-components';
export const STimeVotePage = styled.div`
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #ffffff;
  box-sizing: border-box;
  padding: 40px 128px 40px 40px;

  .title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: #333b6a;
    margin-bottom: 40px;
  }

  .module-content {
    margin-left: 90px;
  }

  @media screen and (max-width: 1199px) {
    padding: 40px;
    .module-content {
      margin-left: 0;
    }
  }
  @media screen and (max-width: 1199px) {
    padding: 5px;
  }
`;

export const STimeItem = styled.div`
  background: #ffffff;
  border: 1px solid #dfe9f5;
  box-sizing: border-box;
  padding: 32px 32px 12px 32px;
  margin-bottom: 20px;

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;

    .title {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #333b6a;
      margin: 0;
    }
  }

  .content {
    .time-setting {
      display: grid;
      grid-template-columns: 5fr 1fr;
      align-items: flex-end;

      .button-content {
        margin-bottom: 16px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        .time__btn {
          padding: 12px;
          position: relative;
          background: #ffffff;
          border: 1px solid #dfe9f5;
          box-sizing: border-box;
          height: 40px;
          width: 40px;

          i {
            color: #9ab1cc;
          }

          .icon1 {
            display: block;
          }
          .icon2 {
            display: none;
          }

          .btn_label {
            position: absolute;
            background: #ffffff;
            border: 1px solid #dfe9f5;
            box-sizing: border-box;
            border-radius: 4px;
            padding: 2px 18px;
            top: -32px;
            left: 50%;
            transform: translateX(-50%);
            display: none;

            span {
              font-family: 'Roboto';
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 21px;
              text-align: center;
              color: #333b6a;
            }
          }

          &:hover {
            .icon2 {
              display: block;
            }
            .icon1 {
              display: none;
            }

            i {
              color: #00cb82;
            }
            .btn_label {
              display: block;
            }
          }
        }

        .show__btn {
          .btn_label {
            min-width: 90px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    padding: 5px;
    .content .time-setting {
      display: block;
    }
  }
`

export const SwitchContent = styled.div`
  position: relative;
  display: table;

  .switch {
    background: #c0c4d0;
    width: 36px;
    height: 20px;
    display: block;
    border-radius: 111px;
    position: relative;
    cursor: pointer;

    &.on {
      background: #00cb82;

      &:after {
        left: 17px;
      }
    }

    &:after {
      content: '';
      width: 16px;
      height: 16px;
      background: #fff;
      border-radius: 10px;
      position: absolute;
      top: 2px;
      left: 2px;
      transition: all 0.3s;
    }
  }

  &:hover {
    .switch__title {
      transform: translateX(-15px) scale(1);
    }
  }

  /* .switch__title {
    bottom: 20px;
    border: 1px solid #E2E5EF;
    font-size: 9px;
    padding: 1px 5px;
    left: 50%;
    white-space: nowrap;
    min-width: 49px;
    text-align: center;
    color: #737886;
    border-radius: 4px;
    background: #fff;
    position: absolute;
    transform: translateX(-15px) scale(0);
    transition: all 0.3s;
    transform-origin: bottom left;

    &:after {
      content: "";
      display: block;
      width: 4px;
      height: 4px;
      background: #fff;
      position: absolute;
      border-left: 1px solid #E2E5EF;
      border-bottom: 1px solid #E2E5EF;
      transform: skewY(-47deg);
      left: 13px;
      top: 13px;
    }
  } */
`;

export const STable = styled.div`
  table tbody tr td {
    border: 1px solid #dfe9f5 !important;
  }
  .last_td {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    margin-left: 0;
  }
`;
