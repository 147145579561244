import * as constants from './constants';
import { callLoginApi } from '../../../common';
import { decode } from 'jsonwebtoken';
import PNotify from 'pnotify/dist/es/PNotify';

export const signIn = (body, timeRestart, t) => {
  return (dispatch) =>
    callLoginApi(constants.URL, 'POST', body, t, function (res) {
      if (res.data && res.data.isEnterOtp) {
        dispatch(setOTPForm(true));
        dispatch(setTimeRestart(timeRestart));
        PNotify.success({
          title: 'Thành công',
          text: res.data.message,
          delay: 2000,
        });
      } else {
        localStorage.setItem('token', res.data.token);
        localStorage.setItem(
          'adminId',
          JSON.stringify(
            decode(res.data.token.slice(7, res.data.token.length)),
          ),
        );
        dispatch(setAuthToken(res.data.token));
        dispatch(setOTPForm(true));
      }
    });
};
// export const getPhoneToOtpApi = (body, timeRestart) => {
//     return dispatch =>  callLoginApi(constants.URL_SET_PHONE_TO_OTP, "POST", body ,function(res){
//         dispatch(setOTPForm(true));
//         dispatch(setOTPPhone(body.phone));
//         dispatch(setTimeRestart(timeRestart));
//     });
// }
export const getOtpApi = (body, t) => {
  return (dispatch) =>
    callLoginApi(
      constants.URL_GET_OTP,
      'POST',
      body,
      t,
      function (res) {
        dispatch(setOTPForm(true));
        localStorage.setItem('token', res.data.token);
        localStorage.setItem(
          'adminId',
          JSON.stringify(
            decode(res.data.token.slice(7, res.data.token.length)),
          ),
        );
        dispatch(setAuthToken(res.data.token));
        dispatch(setOTPForm(true));
      },
      function (err) {
        console.log('err', err);
      },
    );
};

export const signOut = () => {
  return {
    type: constants.LOGOUT,
  };
};

export const setAuthToken = (value) => {
  return {
    type: constants.SET_AUTH_TOKEN,
    value: value,
  };
};
export const setOTPForm = (value) => {
  return {
    type: constants.SET_OTP_FORM,
    value: value,
  };
};
export const setOTPPhone = (value) => {
  return {
    type: constants.SET_OTP_PHONE,
    value: value,
  };
};
export const setRole = (value) => {
  return {
    type: constants.SET_ROLE,
    value: value,
  };
};
export const setTimeRestart = (value) => {
  return {
    type: constants.SET_TIME_RESTART,
    value: value,
  };
};

export const setUserInfo = (value) => {
  return {
    type: constants.SET_USER_INFO,
    value: value,
  };
};
