import React, {useState} from 'react';
import styled from 'styled-components';
import inputIcons from './icons/input';

const Styles = styled.div`
  background: #ffffff;
  border: 1px solid #dfe9f5;
  position: relative;
  .icon {
    position: absolute;
    top: 0;
    left: 0;
  }
  label {
    position: absolute;
  }
  input {
    background: transparent;
  }
`;

const InputConfig = ({onChange, value, type, label, icon}) => {
  const [onFocus, setOnFocus] = useState(false);
  return (
    <Styles className={`input-config ${onFocus ? 'focus' : ''}`}>
      <span className="icon">{inputIcons[icon]}</span>
      <label>{label}</label>
      <input
        type={type}
        value={value}
        onChange={onChange}
        onFocus={() => setOnFocus(true)}
        onBlur={() => setOnFocus(false)}
      />
    </Styles>
  );
};

export default InputConfig;
