import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PAGE_SIZE } from '../../../../states/common/constants';
import {
  getSearchApi,
  setFilter,
  setToggleModal,
  setChooseList,
  setListVotedResult,
} from './../../../../states/duck/pages/checkCoupon/checkCouponArtisanReducer/actions';
import Pagination from '@components/pagination';
import Table from '@components/table';
import { SButtonCopy } from '@styled/checkCoupon/checkCouponArtisanPage';
import Modal from '@components/modal';
import { ModalCopy } from './modalCopy';
import { transformNumberPrinter } from '@configs/transformNumber';
import { useTranslation } from 'react-i18next';
const theads = [
  { name: '#' },
  { name: 'Họ tên' },
  { name: 'CMND/CCCD' },
  { name: 'CP sở hữu' },
  { name: 'CP tham dự' },
  { name: 'Kiểm tra bỏ phiếu' },
  { name: 'Chức năng' },
];

const TableContent = () => {
  const { t } = useTranslation();
  const table = useSelector(
    (state) => state.checkCoupon.checkCouponArtisan.tableList,
  );
  const { filter, total, toggleModal } = useSelector(
    (state) => state.checkCoupon.checkCouponArtisan,
  );
  const dispatch = useDispatch();
  const getPage = (pageNumber) => {
    dispatch(
      setFilter({
        ...filter,
        pageNumber,
      }),
    );
  };
  const getPageSize = (pageSize) => {
    dispatch(
      setFilter({
        ...filter,
        pageNumber: 1,
        pageSize,
      }),
    );
  };
  useEffect(() => {
    return () => {
      dispatch(
        setFilter({
          identityNumber: '',
          joinType: 'all',
          status: 'all',
          pageSize: PAGE_SIZE,
          pageNumber: 1,
          isManual: false,
        }),
      );
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getSearchApi(filter));
  }, [dispatch, filter]);

  const closeModal = (value) => {
    dispatch(setToggleModal(false));
    dispatch(getSearchApi(filter));
  };

  const choseCheckBox = (item) => {
    var newListChose = [];
    newListChose.push(item);
    dispatch(setChooseList(newListChose));
  };
  return (
    <>
      <>
        <Table header={theads} minWidth="1000px">
          {table.length > 0 ? (
            <>
              {table.map((item, index) => {
                return (
                  <tr
                    key={index}

                    // className={findIndex(item, listChose) >= 0 ? 'active' : ''}
                  >
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.identityNumber}</td>
                    <td>{transformNumberPrinter(item.sharesNumber)}</td>
                    <td>
                      {item.isCheckedIn
                        ? item.sharesNumber === 0
                          ? transformNumberPrinter(
                              item.sharesJoinNumber +
                                item.sharesAuthorizedNumber,
                            )
                          : transformNumberPrinter(item.sharesJoinNumber)
                        : 0}
                    </td>
                    <td>
                      {item.invalidVoted
                        ? t('Đã sao chép')
                        : item.isVoted
                        ? item.joinType === 'authority'
                          ? t('Đã biểu quyết')
                          : item.isManual
                          ? t('Đã sao chép')
                          : t('Đã biểu quyết')
                        : item.joinType === 'live'
                        ? t('Chưa sao chép')
                        : item.joinType === 'none'
                        ? t('Chưa tham gia')
                        : t('Chưa sao chép')}
                    </td>
                    <td>
                      {item.joinType === 'none' ? (
                        <ButtonHaveCopied text={t('Chưa tham gia')} />
                      ) : item.isManual === false && item.isVoted ? (
                        <ButtonHaveCopied text={t('Đã biểu quyết')} />
                      ) : item.joinType === 'authority' ? (
                        <ButtonHaveCopied text={t('Đã ủy quyền')} />
                      ) : (
                        <ButtonCopy
                          onClick={() => {
                            choseCheckBox(item);
                            dispatch(setListVotedResult([]));
                            dispatch(setToggleModal(true));
                          }}
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
            </>
          ) : (
            <tr>
              <td colSpan={100} className="center">
                {t('Chưa có dữ liệu')}
              </td>
            </tr>
          )}
        </Table>
        {table.length > 0 && (
          <Pagination
            handleChangePageSize={(size) => {
              getPageSize(size);
            }}
            current={filter.pageNumber}
            total={total}
            pageSize={filter.pageSize}
            onChange={(page) => {
              getPage(page);
            }}
          />
        )}
      </>
      <Modal
        isOpen={toggleModal}
        toggleModal={closeModal}
        title={t('Sao chép kết quả biểu quyết')}
        overwriteModalStyle={{
          maxWidth: '1160px',
        }}
      >
        {toggleModal && <ModalCopy />}
      </Modal>
    </>
  );
};
export default TableContent;

const ButtonCopy = ({ onClick }) => {
  const { t } = useTranslation();
  return <SButtonCopy onClick={onClick}>{t('Sao chép kết quả')}</SButtonCopy>;
};

const ButtonHaveCopied = ({ text }) => {
  return <SButtonCopy className="have_copied">{text}</SButtonCopy>;
};
