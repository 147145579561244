export const vi3 = {
  'Check thủ công': 'Check thủ công',
  'Xuất mật khẩu của Cổ đông': 'Xuất mật khẩu của Cổ đông',
  'Khóa việc thay đổi mật khẩu': 'Khóa việc thay đổi mật khẩu',
  'Họ tên/CMND/CCCD/SĐT': 'Họ tên/CMND/CCCD/SĐT',
  'Đã check in': 'Đã check in',
  'Chưa check in': 'Chưa check in',
  'Cổ đông/ người đại diện': 'Cổ đông/ người đại diện',
  'ĐKSH/CMND/CCCD': 'ĐKSH/CMND/CCCD',
  'Tổng số cổ phần đại diện': 'Tổng số cổ phần đại diện',
  'In tài khoản đăng nhập': 'In tài khoản đăng nhập',
  'Xác nhận tư cách tham gia': 'Xác nhận tư cách tham gia',
  'Quét mã QR để thực hiện đăng nhập cho Cổ đông':
    'Quét mã QR để thực hiện đăng nhập cho Cổ đông',
  'Họ tên/ tài khoản': 'Họ tên/ tài khoản',
  'Danh sách khách mời': 'Danh sách khách mời',
  'Xóa khách mời': 'Xóa khách mời',
  'Nhập phiếu bầu': 'Nhập phiếu bầu',
  'Nhập tỷ lệ bầu cử': 'Nhập tỷ lệ bầu cử',
  'Thiết lập tin nhắn tự động': 'Thiết lập tin nhắn tự động',
  'Thiết lập phương án biểu quyết': 'Thiết lập phương án biểu quyết',
  'Tin nhắn tự động': 'Tin nhắn tự động',
  'Phương án biểu quyết': 'Phương án biểu quyết',
  'Hệ thống Admin': 'Hệ thống Admin',
  'Thành công': 'Thành công',
  'Cập nhật thành công': 'Cập nhật thành công',
  'Cần thêm phương án biểu quyết theo cặp tiếng Anh và tiếng Việt':
    'Cần thêm phương án biểu quyết theo cặp tiếng Anh và tiếng Việt',
};
export const en3 = {
  'Check thủ công': 'Manual check',
  'Xuất mật khẩu của Cổ đông': `Export password`,
  'Khóa việc thay đổi mật khẩu': 'Lock the change password',
  'Họ tên/CMND/CCCD/SĐT': 'Full name/Indentity number/Phone number',
  'Đã check in': 'Checked in',
  'Chưa check in': 'Not checked in yet',
  'Cổ đông/ người đại diện': 'Shareholders/ representatives',
  'ĐKSH/CMND/CCCD': 'Indentity number',
  'Tổng số cổ phần đại diện': 'Total number of representative shares',
  'In tài khoản đăng nhập': 'Print login account',
  'Xác nhận tư cách tham gia': 'Confirm your participation',
  'Quét mã QR để thực hiện đăng nhập cho Cổ đông':
    'Scan QR code to perform login for Shareholders',
  'Họ tên/ tài khoản': 'Full name/ Account',
  'Danh sách khách mời': 'Guest list',
  'Xóa khách mời': 'Delete guest',
  'Nhập phiếu bầu': 'Enter your vote',
  'Nhập tỷ lệ bầu cử': 'Enter the voting rate',
  'Thiết lập tin nhắn tự động': 'Set up automatic messages',
  'Thiết lập phương án biểu quyết': 'Set up voting plan',
  'Tin nhắn tự động': 'Automated messages',
  'Phương án biểu quyết': 'Voting plan',
  'Hệ thống Admin': 'Admin System',
  'Thành công': 'Success',
  'Cập nhật thành công': 'Update successful',
  'Cần thêm phương án biểu quyết theo cặp tiếng Anh và tiếng Việt':
    'Must add options in pairs in English and Vietnamese',
};
