import styled from 'styled-components';

export const ConfigPage = styled.div`
  .category {
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    color: #333b6a;
    margin: 32px 0 16px;
  }
  .system-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
`;

export const SItem = styled.div`
  background-color: #fff;
  width: calc(100% / 3 - 20px);
  padding: 8px 16px;
  border: 1px solid #dfe9f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .name {
    font-size: 14px;
    line-height: 24px;
    color: #333b6a;
  }

  @media screen and (max-width: 767px) {
    width: calc(100% / 2 - 20px);
  }
`;
