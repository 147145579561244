import Modal from '@components/modal';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { VALIDATE_REQUIRE } from '@constants/validate';
import Input from '@components/input';
import { useForm } from 'react-hook-form';
import inputIcons from '@components/icons/input';
import Button from '@components/button';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import PNotify from 'pnotify/dist/es/PNotify';
import { callApi } from '@state-common/';
import ErrorMessage from '@components/input/ErrorMessage';
import {
  getAccountApi,
  editAccountApi,
} from '@duck/pages/admin/systemReducer/adminAccount/actions';
import { useEffect } from 'react';

const EditModal = ({ toggleModal, setToggleModal, modalType, item }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { filter } = useSelector((state) => state.admin.initializeGuest);
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    // watch,
    // control,
  } = useForm();
  const onSubmit = (data) => {
    if (modalType === 'edit') {
      dispatch(
        editAccountApi(
          item._id,
          {
            ...data,
            password: data.password ? data.password : undefined,
          },
          filter,
          setToggleModal,
        ),
      );
    } else {
      callApi(`admin/register-officer`, 'POST', data)
        .then((res) => {
          setTimeout(() => {
            dispatch(getAccountApi(filter));
          }, 200);
          setToggleModal(false);
          setValue('name', '');
          setValue('account', '');
          setValue('description', '');
          setValue('password', '');
          PNotify.success({
            title: t('Thành công'),
            text: t('Tạo tài khoản quản trị thành công'),
            destroy: true,
            delay: 2000,
          });
        })
        .catch((err) => {
          let message =
            err &&
            err.response &&
            err.response.data &&
            err.response.data.message
              ? err.response.data.message
              : err.response.statusText;
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.message === 'CMND/CCCD đã tồn tại.'
          ) {
            // message = t('Tài khoản đã tồn tại!');
            PNotify.error({
              title: t('Lỗi'),
              text: t(message),
              destroy: true,
              delay: 2000,
            });
          }
        });
    }
  };

  useEffect(() => {
    if (modalType === 'edit' && item) {
      setValue('name', item.name);
      setValue('account', item.account);
      setValue('description', item.description);
      setValue('password', '');
    } else {
      setValue('name', '');
      setValue('account', '');
      setValue('description', '');
      setValue('password', '');
    }
  }, [setValue, modalType, item]);
  return (
    <Modal
      isOpen={toggleModal}
      toggleModal={setToggleModal}
      title={
        modalType === 'add'
          ? t('Thêm mới')
          : t('Sửa thông tin tài khoản quản trị')
      }
      overwriteModalStyle={{
        maxWidth: '560px',
      }}
    >
      <SModalContent>
        <form className="form">
          <div className="form-item">
            <Input.Text
              register={register('name', { required: t(VALIDATE_REQUIRE) })}
              wrapperProps={{
                label: t('Họ tên'),
                icon: inputIcons.user,
                required: true,
              }}
            />
            <ErrorMessage errors={errors} field="name" />
          </div>
          <div className="form-item">
            <Input.Text
              register={register('account', {
                required: t(VALIDATE_REQUIRE),
              })}
              wrapperProps={{
                label: t('Tài khoản'),
                icon: inputIcons.user,
                required: true,
              }}
            />
            <ErrorMessage errors={errors} field="account" />
          </div>
          <div className="form-item">
            <Input.Text
              register={register(
                'password',
                modalType === 'add' && { required: t(VALIDATE_REQUIRE) },
              )}
              wrapperProps={{
                label: t('Mật khẩu'),
                icon: inputIcons.code,
                required: true,
              }}
            />
            {modalType === 'add' && (
              <ErrorMessage errors={errors} field="password" />
            )}
          </div>
          <div className="form-item">
            <Input.Text
              register={register('description')}
              wrapperProps={{
                label: t('Thông tin'),
                icon: inputIcons.file,
                required: false,
              }}
            />
          </div>
        </form>

        <div className={`group-button`}>
          <Button
            style={{ color: '#718098', backgroundColor: '#DFE9F5' }}
            onClick={() => {
              setToggleModal(false);
            }}
          >
            {t('Hủy bỏ')}
          </Button>
          <Button type="submit" onClick={handleSubmit(onSubmit)}>
            {modalType === 'add' ? t('Thêm') : t('Cập nhật')}
          </Button>
        </div>
      </SModalContent>
    </Modal>
  );
};

export default EditModal;

const SModalContent = styled.div`
  padding: 0 80px;
  background-color: ${(props) => props.backGroundColor || 'white'};

  margin-top: 44px;

  @media (max-width: 1199px) {
    padding: 0 40px;
  }
  @media (max-width: 991px) {
    padding: 0 20px;
    margin-top: 20px;
  }
  @media (max-width: 767px) {
    padding: 0 15px;
  }

  .group-button {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;
    width: 100%;

    & > ${Button} {
      margin-left: 16px;
      margin-bottom: 8px;
    }

    @media (max-width: 991px) {
      margin-top: 20px;
    }
  }
  .form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;
