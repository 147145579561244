export const errorVi = {
  'Tờ trình không hợp lệ.': 'Tờ trình không hợp lệ.',
  'Mật khẩu đã được thay đổi.': 'Mật khẩu đã được thay đổi.',
  'Ủy quyền thành công.': 'Ủy quyền thành công.',
  'Tạo danh sách ủy quyền thành công.': 'Tạo danh sách ủy quyền thành công.',
  'Sửa thông tin ủy quyền thành công.': 'Sửa thông tin ủy quyền thành công.',
  'Xoá thông tin ủy quyền thành công.': 'Xoá thông tin ủy quyền thành công.',
  'Thêm đại biểu nhận ủy quyền thành công.':
    'Thêm đại biểu nhận ủy quyền thành công.',
  'Chỉnh sửa đại biểu nhận ủy quyền thành công.':
    'Chỉnh sửa đại biểu nhận ủy quyền thành công.',
  'Xóa đại biểu nhận ủy quyền thành công.':
    'Xóa đại biểu nhận ủy quyền thành công.',
  'Đổi trạng thái đại biểu nhận ủy quyền thành công.':
    'Đổi trạng thái đại biểu nhận ủy quyền thành công.',
  'Kiểm tra tư cách cổ đông thành công.':
    'Kiểm tra tư cách cổ đông thành công.',
  'Cài đặt ủy quyền thành công.': 'Cài đặt ủy quyền thành công.',
  'Tạo mới hội nghị thành công.': 'Tạo mới hội nghị thành công.',
  'Cấu hình thời gian biểu quyết thành công.':
    'Cấu hình thời gian biểu quyết thành công.',
  'Cài đặt thời gian gửi ý kiến thành công.':
    'Cài đặt thời gian gửi ý kiến thành công.',
  'Đóng mở thời gian gửi ý kiến thành công.':
    'Đóng mở thời gian gửi ý kiến thành công.',
  'Tải lên biểu mẫu thành công.': 'Tải lên biểu mẫu thành công.',
  'Cập nhật cấu hình hệ thống ẩn hiện thành công.':
    'Cập nhật cấu hình hệ thống ẩn hiện thành công.',
  'Cập nhật cấu hình hệ thống thành công.':
    'Cập nhật cấu hình hệ thống thành công.',
  'Xác nhận thành công.': 'Xác nhận thành công.',
  'Ý kiến đã được chuyển đến chủ toạ.': 'Ý kiến đã được chuyển đến chủ toạ.',
  'Ý kiến đã được công khai.': 'Ý kiến đã được công khai.',
  'Yêu cầu đã được gửi.': 'Yêu cầu đã được gửi.',
  'Bỏ chặn cổ đông gửi ý kiến thành công.':
    'Bỏ chặn cổ đông gửi ý kiến thành công.',
  'Chặn cổ đông gửi ý kiến thành công.': 'Chặn cổ đông gửi ý kiến thành công.',
  'Gửi phản hồi thành công.': 'Gửi phản hồi thành công.',
  'Tạo danh sách cổ đông thành công.': 'Tạo danh sách cổ đông thành công.',
  'Thiết lập bẩu cử lại thành công.': 'Thiết lập bẩu cử lại thành công.',
  'Sửa thông tin cổ đông thành công.': 'Sửa thông tin cổ đông thành công.',
  'Xóa cổ đông thành công.': 'Xóa cổ đông thành công.',
  'Thêm mới tài khoản khách mời thành công.':
    'Thêm mới tài khoản khách mời thành công.',
  'Chỉnh sửa thông tin khách mời thành công.':
    'Chỉnh sửa thông tin khách mời thành công.',
  'Xoá tài khoản khách mời thành công.': 'Xoá tài khoản khách mời thành công.',
  'Đổi trạng thái tờ trình thành công.': 'Đổi trạng thái tờ trình thành công.',
  'Tạo mới nội dung bầu cử thành công.': 'Tạo mới nội dung bầu cử thành công.',
  'Cập nhật dữ liệu thành công.': 'Cập nhật dữ liệu thành công.',
  'Sắp xếp dữ liệu thành công.': 'Sắp xếp dữ liệu thành công.',
  'Xóa dữ liệu thành công.': 'Xóa dữ liệu thành công.',
  'Chặn thành công.': 'Chặn thành công.',
  'Cài đặt thời gian thành công.': 'Cài đặt thời gian thành công.',
  'Cập nhật thành phiếu không hợp lệ thành công.':
    'Cập nhật thành phiếu không hợp lệ thành công.',
  'Biểu quyết thành công.': 'Biểu quyết thành công.',
  'Vui lòng nhập đầy đủ thông tin.': 'Vui lòng nhập đầy đủ thông tin.',
  'Chưa khởi tạo hội nghị.': 'Chưa khởi tạo hội nghị.',
  'Mật khẩu bao gồm các ký tự là chữ, số, ký tự đặc biệt và viết liền không dấu.':
    'Mật khẩu bao gồm các ký tự là chữ, số, ký tự đặc biệt và viết liền không dấu.',
  'Tài khoản đã tồn tại.': 'Tài khoản đã tồn tại.',
  'Quản trị không tồn tại.': 'Quản trị không tồn tại.',
  'Tệp mẫu không tồn tại.': 'Tệp mẫu không tồn tại.',
  'Không tìm thấy thông tin tài khoản.': 'Không tìm thấy thông tin tài khoản.',
  'Quyền cấp phép không hợp lệ.': 'Quyền cấp phép không hợp lệ.',
  'Đăng nhập thất bại.': 'Đăng nhập thất bại.',
  'Mật khẩu hiện tại không khớp.': 'Mật khẩu hiện tại không khớp.',
  'Mật khẩu ít nhất 6 ký tự.': 'Mật khẩu ít nhất 6 ký tự.',
  'Mật khẩu không trùng khớp.': 'Mật khẩu không trùng khớp.',
  'Dữ liệu không hợp lệ.': 'Dữ liệu không hợp lệ.',
  'Mã quản trị không hợp lệ.': 'Mã quản trị không hợp lệ.',
  'Người xử lý không tồn tại.': 'Người xử lý không tồn tại.',
  'Cổ đông không tồn tại.': 'Cổ đông không tồn tại.',
  'Cổ đông đã tham dự không được uỷ quyền.':
    'Cổ đông đã tham dự không được uỷ quyền.',
  'Người được uỷ quyền không được phép uỷ quyền.':
    'Người được uỷ quyền không được phép uỷ quyền.',
  'Không được phép ủy quyền cho chính mình.':
    'Không được phép ủy quyền cho chính mình.',
  'Cổ phần uỷ quyền vượt quá số lượng cho phép.':
    'Cổ phần uỷ quyền vượt quá số lượng cho phép.',
  'Không thể ủy quyền cho người đã ủy quyền.':
    'Không thể ủy quyền cho người đã ủy quyền.',
  'Người được uỷ quyền đã thực hiện biểu quyết.':
    'Người được uỷ quyền đã thực hiện biểu quyết.',
  'Người ủy quyền đã thực hiện ủy quyền hết lượng cổ phần.':
    'Người ủy quyền đã thực hiện ủy quyền hết lượng cổ phần.',
  'Cổ đông nhận ủy quyền không được tìm thấy.':
    'Cổ đông nhận ủy quyền không được tìm thấy.',
  'Hành động không hợp lệ.': 'Hành động không hợp lệ.',
  'Yêu cầu uỷ quyền không tồn tại.': 'Yêu cầu uỷ quyền không tồn tại.',
  'Yêu cầu uỷ quyền đã được xử lý.': 'Yêu cầu uỷ quyền đã được xử lý.',
  'File không đúng định dạng mẫu.': 'File không đúng định dạng mẫu.',
  'Số điện thoại không hợp lệ.': 'Số điện thoại không hợp lệ.',
  'Nhập sai giá trị cổ phần.': 'Nhập sai giá trị cổ phần.',
  'Nhập sai CMND/CCCD CĐ.': 'Nhập sai CMND/CCCD CĐ.',
  'Email không hợp lệ.': 'Email không hợp lệ.',
  'Người được ủy quyền không tồn tại.': 'Người được ủy quyền không tồn tại.',
  'Mã uỷ quyền không hợp lệ.': 'Mã uỷ quyền không hợp lệ.',
  'Lệnh ủy quyền không tồn tại.': 'Lệnh ủy quyền không tồn tại.',
  'Xóa ủy quyền không thành công.': 'Xóa ủy quyền không thành công.',
  'File mẫu ủy quyền không tồn tại!': 'File mẫu ủy quyền không tồn tại!',
  'Đại biểu đã được thêm vào danh sách nhận ủy quyền.':
    'Đại biểu đã được thêm vào danh sách nhận ủy quyền.',
  'Chưa nhập mật khẩu.': 'Chưa nhập mật khẩu.',
  'Đại biểu nhận ủy quyền không được tìm thấy.':
    'Đại biểu nhận ủy quyền không được tìm thấy.',
  'Đại biểu đã ủy quyền thành công cho người khác.':
    'Đại biểu đã ủy quyền thành công cho người khác.',
  'Đại biểu không có trong danh sách nhận ủy quyền.':
    'Đại biểu không có trong danh sách nhận ủy quyền.',
  'Thông tin ủy quyền của đại biểu nhận ủy quyền đã được phê duyệt.':
    'Thông tin ủy quyền của đại biểu nhận ủy quyền đã được phê duyệt.',
  'Đại biểu nhận ủy quyền đang hoạt động.':
    'Đại biểu nhận ủy quyền đang hoạt động.',
  'Đại biểu nhận ủy quyền đang bị khóa nhận ủy quyền mặc định.':
    'Đại biểu nhận ủy quyền đang bị khóa nhận ủy quyền mặc định.',
  'Chưa có thông tin biểu quyết của cổ đông.':
    'Chưa có thông tin biểu quyết của cổ đông.',
  'Không tìm thấy thông tin đại hội.': 'Không tìm thấy thông tin đại hội.',
  'File mẫu kết quả biểu quyết không tồn tại!':
    'File mẫu kết quả biểu quyết không tồn tại!',
  'Cổ đông chưa thực hiện biểu quyết.': 'Cổ đông chưa thực hiện biểu quyết.',
  'Dữ liệu chưa được thống kê.': 'Dữ liệu chưa được thống kê.',
  'Vui lòng checkin trong thời gian diễn ra đại hội.':
    'Vui lòng checkin trong thời gian diễn ra đại hội.',
  'Cổ đông đã ủy quyền.': 'Cổ đông đã ủy quyền.',
  'Không tìm thấy thông tin cổ đông.': 'Không tìm thấy thông tin cổ đông.',
  'Quản trị viên chưa lấy danh sách mật khẩu cổ đông.':
    'Quản trị viên chưa lấy danh sách mật khẩu cổ đông.',
  'Cổ đông phải đăng nhập bằng OTP.': 'Cổ đông phải đăng nhập bằng OTP.',
  'File mẫu thông tin tài khoản cổ đông không tồn tại!':
    'File mẫu thông tin tài khoản cổ đông không tồn tại!',
  'Mã hội nghị không hợp lệ.': 'Mã hội nghị không hợp lệ.',
  'Giá trị không hợp lệ.': 'Giá trị không hợp lệ.',
  'Tài khoản quản trị hệ thống không tồn tại.':
    'Tài khoản quản trị hệ thống không tồn tại.',
  'Đã tạo cuộc họp.': 'Đã tạo cuộc họp.',
  'Thời gian không hợp lệ.': 'Thời gian không hợp lệ.',
  'Loại không hợp lệ.': 'Loại không hợp lệ.',
  'Hội nghị đã mở.': 'Hội nghị đã mở.',
  'Hội nghị đã đóng.': 'Hội nghị đã đóng.',
  'Chưa chọn biểu mẫu.': 'Chưa chọn biểu mẫu.',
  'Loại biểu mẫu không hợp lệ.': 'Loại biểu mẫu không hợp lệ.',
  'Cấu hình hệ thống chưa được thiết lập.':
    'Cấu hình hệ thống chưa được thiết lập.',
  'Cấu hình hệ thống không tồn tại.': 'Cấu hình hệ thống không tồn tại.',
  'Cấu hình hệ thống không hợp lệ.': 'Cấu hình hệ thống không hợp lệ.',
  'Phương án biểu quyết không hợp lệ.': 'Phương án biểu quyết không hợp lệ.',
  //'Dữ liệu không hợp lệ.': 'Dữ liệu không hợp lệ.',
  'Phương án biểu quyết bị trùng lặp.': 'Phương án biểu quyết bị trùng lặp.',
  'Tin nhắn trả lời tự động không hợp lệ.':
    'Tin nhắn trả lời tự động không hợp lệ.',
  'Loại bảng không hợp lệ.': 'Loại bảng không hợp lệ.',
  'Trạng thái không hợp lệ.': 'Trạng thái không hợp lệ.',
  'Hình thức không hợp lệ.': 'Hình thức không hợp lệ.',
  'Loại cổ đông không hợp lệ.': 'Loại cổ đông không hợp lệ.',
  'Loại hình doanh nghiệp không hợp lệ.':
    'Loại hình doanh nghiệp không hợp lệ.',
  'CNTC không hợp lệ.': 'CNTC không hợp lệ.',
  'Cổ đông chưa có đủ thông tin để tạo qrcode.':
    'Cổ đông chưa có đủ thông tin để tạo qrcode.',
  'Tài khoản khách mời không tồn tại.': 'Tài khoản khách mời không tồn tại.',
  'ĐKSH/CMND/CCCD đã tồn tại.': 'ĐKSH/CMND/CCCD đã tồn tại.',
  'Đang tính toán dữ liệu, vui lòng thử lại sau.':
    'Đang tính toán dữ liệu, vui lòng thử lại sau.',
  'Cổ đông chưa biểu quyết.': 'Cổ đông chưa biểu quyết.',
  'Cấu trúc tệp không hợp lệ.': 'Cấu trúc tệp không hợp lệ.',
  'Mã tờ trình không hợp lệ.': 'Mã tờ trình không hợp lệ.',
  'Tờ trình không tồn tại.': 'Tờ trình không tồn tại.',
  'Loại nội dung không hợp lệ.': 'Loại nội dung không hợp lệ.',
  'Nội dung biểu quyết đã tồn tại.': 'Nội dung biểu quyết đã tồn tại.',
  'Tỉ lệ tán thành không được nhỏ hơn 0 và lớn hơn 100.':
    'Tỉ lệ tán thành không được nhỏ hơn 0 và lớn hơn 100.',
  'Số lượng bầu cử không hợp lệ.': 'Số lượng bầu cử không hợp lệ.',
  'Kiểu dữ liệu danh sách đề cử không hợp lệ.':
    'Kiểu dữ liệu danh sách đề cử không hợp lệ.',
  'Các phần nội dung thông qua không tồn tại.':
    'Các phần nội dung thông qua không tồn tại.',
  'Phần nội dung thông qua đã đóng.': 'Phần nội dung thông qua đã đóng.',
  'Nội dung biểu quyết không tồn tại hoặc đã được xác nhận.':
    'Nội dung biểu quyết không tồn tại hoặc đã được xác nhận.',
  'Kiểu dữ liệu không hợp lệ.': 'Kiểu dữ liệu không hợp lệ.',
  'Không chặn được loại này.': 'Không chặn được loại này.',
  'Loại biểu quyết không được hỗ trợ.': 'Loại biểu quyết không được hỗ trợ.',
  'Nội dung biểu quyết không hợp lệ.': 'Nội dung biểu quyết không hợp lệ.',
  'Lặp lại nội dung biểu quyết.': 'Lặp lại nội dung biểu quyết.',
  'Nội dung biểu quyết không tồn tại.': 'Nội dung biểu quyết không tồn tại.',
  'Loại dữ liệu không hợp lệ.': 'Loại dữ liệu không hợp lệ.',
  'Không tìm thấy thông tin nội dung biểu quyết.':
    'Không tìm thấy thông tin nội dung biểu quyết.',
  'Cổ đông [id] không tồn tại.': 'Cổ đông [id] không tồn tại.',
  'Cổ đông [name] ([identityNumber]) không được phép biểu quyết.':
    'Cổ đông [name] ([identityNumber]) không được phép biểu quyết.',
  'Cổ đông [name] ([identityNumber]) không đủ điều kiện biểu quyết.':
    'Cổ đông [name] ([identityNumber]) không đủ điều kiện biểu quyết.',
  'Cổ đông [name] ([identityNumber]) không được biểu quyết ở nội dung này.':
    'Cổ đông [name] ([identityNumber]) không được biểu quyết ở nội dung này.',
  'Cổ đông [name] ([identityNumber]) không ủy quyền cho bạn.':
    'Cổ đông [name] ([identityNumber]) không ủy quyền cho bạn.',
  'Cổ đông [name] ([identityNumber]) đã biểu quyết.':
    'Cổ đông [name] ([identityNumber]) đã biểu quyết.',
  'Cổ đông [name] ([identityNumber]) có số cổ phần biểu quyết không hợp lệ.':
    'Cổ đông [name] ([identityNumber]) có số cổ phần biểu quyết không hợp lệ.',
  'Tài khoản ${account} bị trùng lặp.': 'Tài khoản ${account} bị trùng lặp.',
  'Tài khoản ${account} không hợp lệ.': 'Tài khoản ${account} không hợp lệ.',
  'Số ĐKSH [identityNumber] bị trùng lặp trong danh sách.':
    'Số ĐKSH [identityNumber] bị trùng lặp trong danh sách.',
  'Không được để trống số ĐKSH.': 'Không được để trống số ĐKSH.',
  'Tài khoản [identityNumber] bị trùng lặp trong danh sách.':
    'Tài khoản [identityNumber] bị trùng lặp trong danh sách.',
  'Tài khoản [identityNumber] đã tồn tại.':
    'Tài khoản [identityNumber] đã tồn tại.',
  'Request failed with status code 422': 'Lỗi cấu hình không hợp lệ',
};
export const errorEn = {
  'Request failed with status code 422': 'Request failed',
  'Tờ trình không hợp lệ.': 'Invalid submission.',
  'Mật khẩu đã được thay đổi.': 'Password has been changed.',
  'Ủy quyền thành công.': 'Authorization successful.',
  'Tạo danh sách ủy quyền thành công.':
    'Authorization list created successfully.',
  'Sửa thông tin ủy quyền thành công.':
    'Edit authorization information successfully.',
  'Xoá thông tin ủy quyền thành công.':
    'Delete authorization information successfully.',
  'Thêm đại biểu nhận ủy quyền thành công.':
    'Add delegate successfully delegated.',
  'Chỉnh sửa đại biểu nhận ủy quyền thành công.':
    'Edit delegate received authorization successfully.',
  'Xóa đại biểu nhận ủy quyền thành công.':
    'Delegating delegate removed successfully.',
  'Đổi trạng thái đại biểu nhận ủy quyền thành công.':
    'Change delegate status successfully.',
  'Kiểm tra tư cách cổ đông thành công.': 'Successful shareholder check.',
  'Cài đặt ủy quyền thành công.': 'Proxy installation successful.',
  'Tạo mới hội nghị thành công.': 'Create new successful conference.',
  'Cấu hình thời gian biểu quyết thành công.':
    'Successful voting time configuration.',
  'Cài đặt thời gian gửi ý kiến thành công.':
    'Set the time to submit comments successfully.',
  'Đóng mở thời gian gửi ý kiến thành công.':
    'Open and close the comment submission period successfully.',
  'Tải lên biểu mẫu thành công.': 'Form upload successful.',
  'Cập nhật cấu hình hệ thống ẩn hiện thành công.':
    'The hidden system configuration update is now successful.',
  'Cập nhật cấu hình hệ thống thành công.':
    'System configuration update successful',
  'Xác nhận thành công.': 'Successful confirmation.',
  'Ý kiến đã được chuyển đến chủ toạ.':
    'Comments have been forwarded to the chairperson.',
  'Ý kiến đã được công khai.': 'Comments have been made public.',
  'Yêu cầu đã được gửi.': 'Request has been sent.',
  'Bỏ chặn cổ đông gửi ý kiến thành công.':
    'Unblock shareholders from submitting comments successfully.',
  'Chặn cổ đông gửi ý kiến thành công.':
    'Block shareholders from submitting comments successfully.',
  'Gửi phản hồi thành công.': 'Feedback submitted successfully.',
  'Tạo danh sách cổ đông thành công.': 'Create a successful shareholder list.',
  'Thiết lập bẩu cử lại thành công.': 'Successful re-election setup',
  'Sửa thông tin cổ đông thành công.':
    'Successfully edited shareholder information.',
  'Xóa cổ đông thành công.': 'Successful shareholder deletion.',
  'Thêm mới tài khoản khách mời thành công.':
    'New guest account added successfully.',
  'Chỉnh sửa thông tin khách mời thành công.':
    'Successfully edited guest information.',
  'Xoá tài khoản khách mời thành công.': 'Delete guest account successfully.',
  'Đổi trạng thái tờ trình thành công.': 'Report status change successful.',
  'Tạo mới nội dung bầu cử thành công.':
    'Create new successful election content.',
  'Cập nhật dữ liệu thành công.': 'Data update successful.',
  'Sắp xếp dữ liệu thành công.': 'Sort data successfully.',
  'Xóa dữ liệu thành công.': 'Delete data successfully.',
  'Chặn thành công.': 'Block success.',
  'Cài đặt thời gian thành công.': 'Successful time setting.',
  'Cập nhật thành phiếu không hợp lệ thành công.':
    'Update to invalid ticket successfully.',
  'Biểu quyết thành công.': 'Voting success.',
  'Vui lòng nhập đầy đủ thông tin.': 'Please enter full information.',
  'Chưa khởi tạo hội nghị.': 'The conference has not been initiated.',
  'Mật khẩu bao gồm các ký tự là chữ, số, ký tự đặc biệt và viết liền không dấu.':
    'Password includes alphanumeric characters, special characters and unsigned letters.',
  'Tài khoản đã tồn tại.': 'Account already exists',
  'Quản trị không tồn tại.': 'Administration does not exist',
  'Tệp mẫu không tồn tại.': 'Tệp mẫu không tồn tại.',
  'Không tìm thấy thông tin tài khoản.': 'Account information not found.',
  'Quyền cấp phép không hợp lệ.': 'Invalid permission.',
  'Đăng nhập thất bại.': 'Login failed.',
  'Mật khẩu hiện tại không khớp.': 'Current password does not match.',
  'Mật khẩu ít nhất 6 ký tự.': 'Password must be at least 6 characters.',
  'Mật khẩu không trùng khớp.': 'Password does not match',
  'Dữ liệu không hợp lệ.': 'Invalid data.',
  'Mã quản trị không hợp lệ.': 'Invalid admin code.',
  'Người xử lý không tồn tại.': 'The handler does not exist.',
  'Cổ đông không tồn tại.': 'Shareholders do not exist.',
  'Cổ đông đã tham dự không được uỷ quyền.':
    'Shareholders who attended were not authorized.',
  'Người được uỷ quyền không được phép uỷ quyền.':
    'The authorized person is not authorized to authorize.',
  'Không được phép ủy quyền cho chính mình.': 'Do not authorize yoursel',
  'Cổ phần uỷ quyền vượt quá số lượng cho phép.':
    'The authorized share exceeds the allowed number.',
  'Không thể ủy quyền cho người đã ủy quyền.':
    'Unable to authorize authorized person.',
  'Người được uỷ quyền đã thực hiện biểu quyết.':
    'The authorized person has made the vote.',
  'Người ủy quyền đã thực hiện ủy quyền hết lượng cổ phần.':
    'The authorized person has authorized all shares',
  'Cổ đông nhận ủy quyền không được tìm thấy.':
    'The authorized shareholder was not found.',
  'Hành động không hợp lệ.': 'Invalid action.',
  'Yêu cầu uỷ quyền không tồn tại.': 'Authorization request does not exist.',
  'Yêu cầu uỷ quyền đã được xử lý.':
    'Authorization request has been processed.',
  'File không đúng định dạng mẫu.':
    'The file is not in the correct template format.',
  'Số điện thoại không hợp lệ.': 'Invalid phone number.',
  'Nhập sai giá trị cổ phần.': 'Enter the wrong share value.',
  'Nhập sai CMND/CCCD CĐ.': 'Enter the wrong ID/CCCD CD.',
  'Email không hợp lệ.': 'Invalid email.',
  'Người được ủy quyền không tồn tại.': 'The authorized person does not exist.',
  'Mã uỷ quyền không hợp lệ.': 'Invalid authorization code.',
  'Lệnh ủy quyền không tồn tại.': 'Authorization command does not exist.',
  'Xóa ủy quyền không thành công.': 'Deleting authorization failed.',
  'File mẫu ủy quyền không tồn tại!':
    'Authorization template file does not exist!',
  'Đại biểu đã được thêm vào danh sách nhận ủy quyền.':
    'Delegate has been added to the delegated list.',
  'Chưa nhập mật khẩu.': 'No password entered.',
  'Đại biểu nhận ủy quyền không được tìm thấy.':
    'The authorized delegate could not be found.',
  'Đại biểu đã ủy quyền thành công cho người khác.':
    'The delegate has successfully delegated to another person.',
  'Đại biểu không có trong danh sách nhận ủy quyền.':
    'The delegate is not on the authorized list.',
  'Thông tin ủy quyền của đại biểu nhận ủy quyền đã được phê duyệt.':
    'Authorization information of authorized delegate has been approved.',
  'Đại biểu nhận ủy quyền đang hoạt động.':
    'The authorized delegate is active.',
  'Đại biểu nhận ủy quyền đang bị khóa nhận ủy quyền mặc định.':
    'The delegated delegate is blocked by default.',
  'Chưa có thông tin biểu quyết của cổ đông.':
    'There is no voting information of shareholders.',
  'Không tìm thấy thông tin đại hội.': 'Meeting information not found.',
  'File mẫu kết quả biểu quyết không tồn tại!':
    'Voting result sample file does not exist!',
  'Cổ đông chưa thực hiện biểu quyết.':
    'Shareholders have not yet made a vote.',
  'Dữ liệu chưa được thống kê.': 'The data has not been compiled.',
  'Vui lòng checkin trong thời gian diễn ra đại hội.':
    'Please check in during the congress.',
  'Cổ đông đã ủy quyền.': 'Shareholders authorized.',
  'Không tìm thấy thông tin cổ đông.': 'No shareholder information found.',
  'Quản trị viên chưa lấy danh sách mật khẩu cổ đông.':
    'The administrator has not obtained the list of shareholder passwords.',
  'Cổ đông phải đăng nhập bằng OTP.': 'Shareholders must login with OTP.',
  'File mẫu thông tin tài khoản cổ đông không tồn tại!':
    'Shareholder account information sample file does not exist!',
  'Mã hội nghị không hợp lệ.': 'Invalid conference code.',
  'Giá trị không hợp lệ.': 'Invalid value.',
  'Tài khoản quản trị hệ thống không tồn tại.':
    'The system administrator account does not exist.',
  'Đã tạo cuộc họp.': 'Meeting created.',
  'Thời gian không hợp lệ.': 'Invalid time.',
  'Loại không hợp lệ.': 'Invalid type.',
  'Hội nghị đã mở.': 'The conference is open',
  'Hội nghị đã đóng.': 'Conference closed.',
  'Chưa chọn biểu mẫu.': 'No form selected.',
  'Loại biểu mẫu không hợp lệ.': 'Invalid form type.',
  'Cấu hình hệ thống chưa được thiết lập.':
    'The system configuration has not been set.',
  'Cấu hình hệ thống không tồn tại.': 'System configuration does not exist.',
  'Cấu hình hệ thống không hợp lệ.': 'Invalid system configuration.',
  'Phương án biểu quyết không hợp lệ.': 'The voting plan is invalid.',
  //'Dữ liệu không hợp lệ.': 'Invalid data.',
  'Phương án biểu quyết bị trùng lặp.': 'Voting options are duplicated.',
  'Tin nhắn trả lời tự động không hợp lệ.': 'Invalid auto-reply message.',
  'Loại bảng không hợp lệ.': 'Invalid table type.',
  'Trạng thái không hợp lệ.': 'Invalid status.',
  'Hình thức không hợp lệ.': 'Invalid form.',
  'Loại cổ đông không hợp lệ.': 'Invalid shareholder type.',
  'Loại hình doanh nghiệp không hợp lệ.': 'Invalid business type.',
  //'Loại hình doanh nghiệp không hợp lệ.': 'Invalid business type.',
  'CNTC không hợp lệ.': 'Invalid CNTC.',
  'Cổ đông chưa có đủ thông tin để tạo qrcode.':
    'Shareholders do not have enough information to generate qrcode.',
  'Tài khoản khách mời không tồn tại.': 'Guest account does not exist.',
  'ĐKSH/CMND/CCCD đã tồn tại.': 'Birth Certificate/ID/CCCD already exists.',
  'Đang tính toán dữ liệu, vui lòng thử lại sau.':
    'Calculating data, please try again later.',
  'Cổ đông chưa biểu quyết.': 'Shareholders have not voted.',
  'Cấu trúc tệp không hợp lệ.': 'Invalid file structure.',
  'Mã tờ trình không hợp lệ.': 'Invalid submission code.',
  'Tờ trình không tồn tại.': 'The report does not exist.',
  'Loại nội dung không hợp lệ.': 'Invalid content type.',
  'Nội dung biểu quyết đã tồn tại.': 'Voting content already exists.',
  'Tỉ lệ tán thành không được nhỏ hơn 0 và lớn hơn 100.':
    'Approval rate should not be less than 0 and greater than 100.',
  'Số lượng bầu cử không hợp lệ.': 'Invalid vote count.',
  'Kiểu dữ liệu danh sách đề cử không hợp lệ.':
    'Invalid nomination list data type.',
  'Các phần nội dung thông qua không tồn tại.':
    'Passed content sections do not exist.',
  'Phần nội dung thông qua đã đóng.': 'The pass content section is closed.',
  'Nội dung biểu quyết không tồn tại hoặc đã được xác nhận.':
    'Voting content does not exist or has been confirmed.',
  'Kiểu dữ liệu không hợp lệ.': 'Invalid data type.',
  'Không chặn được loại này.': 'This type cannot be blocked.',
  'Loại biểu quyết không được hỗ trợ.': 'Voting type is not supported.',
  'Nội dung biểu quyết không hợp lệ.': 'Voting content is invalid.',
  'Lặp lại nội dung biểu quyết.': 'Repeat voting content.',
  'Nội dung biểu quyết không tồn tại.': 'Voting content does not exist.',
  'Loại dữ liệu không hợp lệ.': 'Invalid data type.',
  'Không tìm thấy thông tin nội dung biểu quyết.':
    'Voting content information not found.',
  'Cổ đông [id] không tồn tại.': 'Shareholder [id] does not exist.',
  'Cổ đông [name] ([identityNumber]) không được phép biểu quyết.':
    'Shareholders [name] ([identityNumber]) are not allowed to vote.',
  'Cổ đông [name] ([identityNumber]) không đủ điều kiện biểu quyết.':
    'Shareholders [name] ([identityNumber]) are not eligible to vote.',
  'Cổ đông [name] ([identityNumber]) không được biểu quyết ở nội dung này.':
    'Shareholders [name] ([identityNumber]) are not allowed to vote on this content.',
  'Cổ đông [name] ([identityNumber]) không ủy quyền cho bạn.':
    'Shareholders [name] ([identityNumber]) do not authorize you.',
  'Cổ đông [name] ([identityNumber]) đã biểu quyết.':
    'Shareholders [name] ([identityNumber]) voted.',
  'Cổ đông [name] ([identityNumber]) có số cổ phần biểu quyết không hợp lệ.':
    'Shareholder [name] ([identityNumber]) has an invalid number of voting shares.',
  'Tài khoản ${account} bị trùng lặp.': 'Account ${account} is duplicate.',
  'Tài khoản ${account} không hợp lệ.': 'The account ${account} is not valid.',
  'Số ĐKSH [identityNumber] bị trùng lặp trong danh sách.':
    'The registrant number [identityNumber] is duplicated in the list.',
  'Không được để trống số ĐKSH.': 'Numbers cannot be left blank ĐKSH',
  'Tài khoản [identityNumber] bị trùng lặp trong danh sách.':
    'Account [identityNumber] is duplicated in the list.',
  'Tài khoản [identityNumber] đã tồn tại.':
    'Account [identityNumber] already exists.',
};
