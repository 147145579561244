import * as types from './constants';
import * as common from '../../../../common/constants';

var initialState = {
  // tableList: [],
  // listTopicChoose: [],
  // toggleModal: false,
  // filter: {
  //     "identityNumber": "",
  //     "joinType": "all",
  //     "status": "all",
  //     "pageSize": common.PAGE_SIZE,
  //     "pageNumber": 1
  // },
  // total: 0,
  // invitationType: "all",
  // addressList: [],
  info: {},
  // downloadPasswordList: false,
  // passwordList: [],
  loading: false,
};
var reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_LOADING:
      return { ...state, loading: action.value };
    case types.GET_INFO:
      return { ...state, info: action.value };
    case types.SET_STATUS:
      return { ...state, info: action.value };
    default:
      return state;
  }
};
export default reducer;
