import { SModalEditAuthorizationList } from '@styled/admin/authorizationListPage/authorizationList';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Input from 'components/bases/shared/input';
import Button from 'components/bases/shared/button';
import inputIcons from 'components/bases/shared/icons/input';
import { useSelector, useDispatch } from 'react-redux';
import {
  getUserApi,
  setChooseList,
} from '@duck/pages/admin/authorizationListReducer/actions';
import { VALIDATE_REQUIRE } from '@constants/validate';
import ErrorMessage from '@components/input/ErrorMessage';
import { callApi } from '@state-common/';
import { URL_EDIT } from '@duck/pages/admin/authorizationListReducer/constants';
import PNotify from 'pnotify/dist/es/PNotify';
import { handleError } from '@configs/handleError';
import { useTranslation } from 'react-i18next';

export const EditModal = ({ setToggleModal }) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const { listChose, filter } = useSelector(
    (state) => state.admin.authorizationList,
  );
  const onSubmit = (data) => {
    const { name, phone, email, address, password } = data;
    const temp = listChose[0];
    callApi(`${URL_EDIT}/${temp._id}`, 'POST', {
      name,
      phone,
      email: email && email !== '' ? email : undefined,
      address: address && address !== '' ? address : undefined,
      password: password ? password : undefined,
    })
      .then(function (res) {
        setToggleModal(false);
        setTimeout(() => {
          dispatch(getUserApi(filter));
          dispatch(setChooseList([]));
        }, 500);
        PNotify.success({
          title: t('Thành công'),
          text: t('Sửa thành công'),
          destroy: true,
          delay: 2000,
        });
      })
      .catch(handleError);
    // dispatch(
    //   getEditApi({
    //     id: temp._id,
    //     body: {
    //       name,
    //       phone,
    //       email,
    //       address,
    //       password,
    //     },
    //     filter,
    //   }),
    // );
  };
  useEffect(() => {
    setValue('identityNumber', listChose[0] ? listChose[0].identityNumber : '');
    setValue('name', listChose[0] ? listChose[0].name : '');
    setValue('phone', listChose[0] ? listChose[0].phone : '');
    setValue('email', listChose[0] ? listChose[0].email : '');
    setValue('address', listChose[0] ? listChose[0].address : '');
    setValue('sharesNumber', listChose[0] ? listChose[0].sharesNumber : '');
  }, [listChose, setValue]);
  return (
    <SModalEditAuthorizationList>
      <div className="handle-wrapper">
        <div className="handle-item width-50">
          <Input.Text
            inputProps={{
              readOnly: true,
            }}
            register={register('identityNumber')}
            readOnly
            wrapperProps={{
              label: t('CMND/CCCD (Tài khoản)'),
              icon: inputIcons.code,
              //   required: true,
            }}
          />
          <ErrorMessage errors={errors} field="identityNumber" />
        </div>
        <div className="handle-item width-50">
          <Input.Text
            register={register('name', { required: t(VALIDATE_REQUIRE) })}
            wrapperProps={{
              label: t('Họ tên người được ủy quyền'),
              icon: inputIcons.account,
              //   required: true,
            }}
          />
          <ErrorMessage errors={errors} field="name" />
        </div>
        <div className="handle-item width-50">
          <Input.Text
            inputProps={{
              type: 'number',
            }}
            register={register('phone', { required: t(VALIDATE_REQUIRE) })}
            wrapperProps={{
              label: t('Số điện thoại'),
              icon: inputIcons.phone,
              //   required: true,
            }}
          />
          <ErrorMessage errors={errors} field="name" />
        </div>
        <div className="handle-item width-50">
          <Input.Text
            register={register('email')}
            wrapperProps={{
              label: 'Email',
              icon: inputIcons.email,
              //   required: true,
            }}
          />
        </div>
        <div className="handle-item width-100">
          <Input.Text
            register={register('address')}
            wrapperProps={{
              label: t('Địa chỉ'),
              icon: inputIcons.address,
              //   required: true,
            }}
          />
        </div>
        <div className="handle-item width-50">
          <Input.Text
            inputProps={{
              readOnly: true,
            }}
            register={register('sharesNumber', {
              required: t(VALIDATE_REQUIRE),
            })}
            readOnly
            wrapperProps={{
              label: t('Số CP được ủy quyền'),
              icon: inputIcons.file,
              //   required: true,
            }}
          />
          <ErrorMessage errors={errors} field="sharesNumber" />
        </div>
        <div className="handle-item width-50">
          <Input.Text
            register={register('password')}
            wrapperProps={{
              label: t('Đặt lại mật khẩu'),
              icon: inputIcons.code,
              //   required: true,
            }}
          />
          {/* <ErrorMessage errors={errors} field="password" /> */}
        </div>
      </div>
      <div className="button-group">
        <Button className="cancel-button" onClick={() => setToggleModal(false)}>
          {t('Hủy bỏ')}
        </Button>
        <Button onClick={handleSubmit(onSubmit)} className="confirm-button">
          {t('Cập nhật')}
        </Button>
      </div>
    </SModalEditAuthorizationList>
  );
};
