import React from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import {mixinsFlexCenter} from 'styles/mixins';

export default function LoadingScreen() {
  const {loading} = useSelector((state) => state.app);

  if (!loading) return null;

  return (
    <SLoadingScreen>
      <div className="loader"></div>
    </SLoadingScreen>
  );
}

const SLoadingScreen = styled.div`
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(6, 29, 66, 0.5);
  ${mixinsFlexCenter};
  color: #dfe9f5;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 2px;
  .loader {
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid #3498db;
    width: 30px;
    height: 30px;
    -webkit-animation: spin 0.7s linear infinite; /* Safari */
    animation: spin 0.7s linear infinite;
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
