import { combineReducers } from 'redux';
import initializeReport from './initializeReportReducer';
import createGroup from './createGroupReducer';
import shareholderList from './shareholderListReducer';
import shareholderStatus from './shareholderStatusReducer';
import timeVote from './timeVoteReducer';
import setting from './settingReducer';
import home from './homeReducer'; // home
import hotline from './hotlineReducer';
import initializeGuest from './initializeGuestReducer';
import shareholderOpinion from './shareholderOpinionReducer';
import authorizationList from './authorizationListReducer';
import declareDepartments from './declareDepartmentsReducer';
import adminAccount from './systemReducer/adminAccount';
import formSetup from './systemReducer/formSetUp';
import configSystem from './configSystemReducer';
import authorizationInfoList from './authorizationInfomationReducer';
import shareholderRepresentative from './shareholderListRepresent';
const rootReducer = combineReducers({
  home,
  initializeReport,
  createGroup,
  shareholderList,
  shareholderStatus,
  timeVote,
  setting,
  hotline,
  initializeGuest,
  shareholderOpinion,
  authorizationList,
  declareDepartments,
  adminAccount,
  formSetup,
  configSystem,
  authorizationInfoList,
  shareholderRepresentative,
});
export default rootReducer;
