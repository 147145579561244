import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getEditApi } from '../../../../../states/duck/pages/admin/shareholderListReducer/actions';
import { SEditModal } from '@styled/admin/shareholderListPage';
import { useForm } from 'react-hook-form';
import Input from '@components/input';
import Button from '@components/button';
import inputIcons from '@components/icons/input';
import ErrorMessage from '@components/input/ErrorMessage';
import { useTranslation } from 'react-i18next';

import {
  VALIDATE_REQUIRE,
  VALIDATE_PHONE,
  VALIDATE_PHONE_REGEX,
} from '@constants/validate';
import Modal from '@components/modal';
const EditModal = ({ isOpen, setToggleModal }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const listChose = useSelector(
    (state) => state.admin.shareholderList.listTopicChoose,
  );
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm();
  //   const [phone, setPhone] = useState(listChose[0] ? listChose[0].phone : '');
  const filter = useSelector((state) => state.admin.shareholderList.filter);
  const onSubmit = (data) => {
    const { phone, identityNumber } = data;
    dispatch(
      getEditApi(
        listChose[0] ? listChose[0]._id : '',
        {
          phone,
          identityNumber,
        },
        filter,
      ),
    );
    setToggleModal(false);
  };
  useEffect(() => {
    setValue('phone', listChose[0] ? listChose[0].phone : '');
    setValue('identityNumber', listChose[0] ? listChose[0].identityNumber : '');
  }, [listChose, setValue]);
  return (
    <Modal
      isOpen={isOpen}
      toggleModal={setToggleModal}
      title={t('Sửa thông tin Cổ đông')}
      overwriteModalStyle={{
        maxWidth: '560px',
      }}
    >
      <SEditModal>
        <div className="handle-wrapper">
          <div className="handle-item">
            <Input.Text
              register={register('identityNumber', {
                required: t(VALIDATE_REQUIRE),
              })}
              wrapperProps={{
                label: t('ĐKSK/CMND/CCCD'),
                icon: inputIcons.user,
                required: true,
              }}
            />
            <ErrorMessage errors={errors} field="identityNumber" />
          </div>
          <div className="handle-item">
            <Input.Text
              register={register('phone', {
                pattern: {
                  value: VALIDATE_PHONE_REGEX,
                  message: t(VALIDATE_PHONE),
                },
              })}
              wrapperProps={{
                label: t('Số điện thoại'),
                icon: inputIcons.phone,
                required: false,
              }}
            />
            <ErrorMessage errors={errors} field="phone" />
          </div>
        </div>
        <div className="button-group">
          <Button
            className="cancel-button"
            onClick={() => setToggleModal(false)}
          >
            {t('Hủy bỏ')}
          </Button>
          <Button className="confirm-button" onClick={handleSubmit(onSubmit)}>
            {t('Cập nhật')}
          </Button>
        </div>
      </SEditModal>
    </Modal>
  );
};
export default EditModal;
