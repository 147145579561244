import styled from 'styled-components';
import { SHotline } from '../hotlinePage';
export const SDeclareDepartmentsPage = styled(SHotline)`
  .search-wrapper {
    margin: 0 -12px;
    margin-bottom: -20px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .search-item {
      padding: 0 12px;
      min-width: 200px;
      width: 33.33%;
      button {
        width: 100%;
      }
      margin-bottom: 20px;
    }
    @media only screen and (max-width: 1380px){
      margin: 0 -30px -20px;
    }
    @media only screen and (max-width: 767px){
      margin: 0 -12px -20px;
    }
    /* @media only screen and (max-width: 1380px){
      margin: 0 -30px -20px;
    } */
  }
`;

export const STableTools = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 20px -8px 10px;
  button {
    margin: 0 8px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 179px;
    height: 32px;
    padding: 1px 3px;
    color: white;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    border-radius: 0px;
    &.green-btn {
      background: #00cb82;
    }
    &.blue-btn {
      background: #2f80ed;
    }
  }
`;

export const SForm = styled.div`

`;
