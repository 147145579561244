export const URL = "info/authority";
export const URL_SEARCH = "info/authority/search";




export const GET_LIST = "GET_SECRETARY_AUTHORITY_LIST_LIST";
export const SET_FILTER = "SET_SECRETARY_AUTHORITY_LIST_FILTER";
export const SET_TOTAL = "SET_SECRETARY_AUTHORITY_STATUS_TOTAL";






