import React, { useEffect } from 'react';
import TableContent from './table';
import Form from './form';
import { useDispatch } from 'react-redux';
// import CopyVoteToOnline from './copyVoteToOnline';
import {
  getTopic1Api,
  getTopic2Api,
  getTopic3Api,
  getTopic4Api,
  getTopic6Api,
} from './../../../../states/duck/pages/checkCoupon/checkCouponArtisanReducer/actions';
import { SCheckCouponManual } from '@styled/checkCoupon/checkCouponArtisanPage';
const CheckCouponArtisanPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTopic1Api(1));
    dispatch(getTopic2Api(2));
    dispatch(getTopic3Api(3));
    dispatch(getTopic4Api(4));
    dispatch(getTopic6Api(6));
  }, [dispatch]);
  return (
    <SCheckCouponManual>
      <Form />
      <TableContent />
    </SCheckCouponManual>
  );
};
export default CheckCouponArtisanPage;
