import Button from '@components/button';
import DropFile from '@components/dropzone/dropFile';
import inputIcons from '@components/icons/input';
import Input from '@components/input';
import ErrorMessage from '@components/input/ErrorMessage';
import {
  VALIDATE_ONLY_NUMBER,
  VALIDATE_PATTERN,
  VALIDATE_REQUIRE,
} from '@constants/validate';
import { SForm } from '@styled/admin/initializeReportPage';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  createAccountApi,
  createCumulativeAccountApi,
  editAccountApi,
  editCumulativeAccountApi,
} from 'states/duck/pages/admin/initializeReportReducer/actions';
import Selections from './selections';
import Method from './method';

const rateOptions = [
  { label: '51%', value: '51' },
  { label: '65%', value: '65' },
];

const defaultValue = {
  topic: undefined,
  topic_2: undefined,
  rate: rateOptions.filter((r) => r.value === '51')[0],
  selectNumber: 0,
};

export default function Form({
  isEdit,
  selectedData = null,
  handleCancel,
  tableType,
  kind,
  kindToSend,
  setKindToSend,
}) {
  const formMethods = useForm();
  const { t } = useTranslation();
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    setValue,
  } = formMethods;
  const [file, setFile] = useState(null);
  const [file2, setFile2] = useState(null);
  const [candidate, setCandidate] = useState(false);

  // register
  useEffect(() => {
    register('topic', { required: t(VALIDATE_REQUIRE) });
    register('topic_2', { required: t(VALIDATE_REQUIRE) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register, kind, setValue]);

  const dispatch = useDispatch();
  const { method } = useSelector((state) => state.admin.initializeReport);
  // submit
  const onSubmit = (data) => {
    delete data.selectionForm;
    const formData = new FormData();

    if (kind !== 4) {
      if (kindToSend === 4) {
        data.kind = 4;
        data.rate = defaultValue.rate.value;
      } else {
        data.kind = kind;
        data.rate = defaultValue.rate.value;
      }
    }

    if (selectedData) {
      delete data.kind;
      if (kindToSend === 4) {
        formData.append('kind', method === 0 ? 6 : 4);
      }
    }

    for (const key in data) {
      if (key === 'selections') {
        formData.append(key, JSON.stringify(data[key]));
      } else {
        data[key] && formData.append(key, data[key]);
      }
    }
    // dispatch edit
    if (selectedData) {
      dispatch(
        kindToSend === 4
          ? editCumulativeAccountApi(
              formData,
              selectedData._id,
              tableType,
              handleResetInputs,
            )
          : editAccountApi(
              formData,
              selectedData._id,
              tableType,
              kind,
              handleResetInputs,
            ),
      );
    }
    // dispatch create new
    else {
      dispatch(
        kindToSend === 4
          ? createCumulativeAccountApi(formData, () => {
              handleResetInputs();
              setCandidate((prev) => !prev);
            })
          : createAccountApi(formData, tableType, kind, () => {
              handleResetInputs();
              setCandidate((prev) => !prev);
            }),
      );
    }
  };

  // choose file
  const handleChooseFile = (e) => (name) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      name === 'topic' ? setFile(file.name) : setFile2(file.name);
      setValue(name, file);
    }
  };

  // handle reset
  const handleResetInputs = () => {
    reset();
    setFile(null);
    setFile2(null);
    register('topic', { required: t(VALIDATE_REQUIRE) });
    register('topic_2', { required: t(VALIDATE_REQUIRE) });
    setValue('topic', defaultValue.topic);
    setValue('topic_2', defaultValue.topic_2);
    handleCancel && handleCancel();
  };
  useEffect(() => {
    if (selectedData) {
      setValue('name', selectedData.name);
      setValue('name_2', selectedData.name_2);
      setValue('topic', selectedData.fileUrl);
      setValue('topic_2', selectedData.fileUrl_2);
      setFile(selectedData.fileUrl);
      setFile2(selectedData.fileUrl_2);
      if (selectedData.kind === 4) {
        setValue('selectNumber', selectedData.selectNumber || 0);
      }
    }
  }, [selectedData, setValue]);

  const setRessetFile = () => {
    setFile(null);
    setValue('topic', '');
  };

  const setRessetFile2 = () => {
    setFile2(null);
    setValue('topic_2', '');
  };

  return (
    <FormProvider {...formMethods}>
      {(kind === 4 || kind === 6) && !isEdit && (
        <Method setKindToSend={setKindToSend} />
      )}
      <SForm>
        {/* select number */}
        {kindToSend === 4 && method === 1 && (
          <>
            <div style={{ marginBottom: '20px' }}>
              <Input.Text
                wrapperProps={{
                  label: t('Số lượng bầu cử'),
                }}
                inputProps={{
                  type: 'tel',
                }}
                name=""
                register={register('selectNumber', {
                  required: t(VALIDATE_REQUIRE),
                  pattern: {
                    value: VALIDATE_ONLY_NUMBER,
                    message: t(VALIDATE_PATTERN),
                  },
                })}
              />
              <ErrorMessage errors={errors} field="selectNumber" />
            </div>
            <div style={{ marginBottom: '20px' }}></div>
          </>
        )}

        {/* name */}
        <div className="form__item item1">
          <Input.Text
            wrapperProps={{
              label: t('Tên nội dung'),
              icon: inputIcons.edit,
            }}
            register={register('name', { required: t(VALIDATE_REQUIRE) })}
          />
          <ErrorMessage errors={errors} field="name" />
        </div>

        {/* name 2 */}
        <div className="form__item item2">
          <Input.Text
            wrapperProps={{
              label: t('Tên nội dung (Tiếng Anh)'),
              icon: inputIcons.edit,
            }}
            register={register('name_2', { required: t(VALIDATE_REQUIRE) })}
          />
          <ErrorMessage errors={errors} field="name_2" />
        </div>

        {/* topic */}
        <div className="form__item item3">
          <DropFile
            file={file}
            label={t('Upload file nội dung')}
            onChange={(e) => handleChooseFile(e)('topic')}
            handleDelete={setRessetFile}
          />
          <ErrorMessage errors={errors} field="topic" />
        </div>
        {/* topic 2 */}
        <div className="form__item item4">
          <DropFile
            file={file2}
            label={t('Upload file nội dung (Tiếng Anh)')}
            onChange={(e) => handleChooseFile(e)('topic_2')}
            handleDelete={setRessetFile2}
          />
          <ErrorMessage errors={errors} field="topic_2" />
        </div>

        {/* button */}
        <div className={`group-button`}>
          {selectedData === null ? (
            <Button
              onClick={handleSubmit(onSubmit)}
              style={{ borderRadius: 2, width: 167 }}
            >
              + {t('Tạo nội dung')}
            </Button>
          ) : (
            <>
              <Button
                style={{ color: '#718098', backgroundColor: '#DFE9F5' }}
                onClick={handleCancel}
              >
                {t('Hủy bỏ')}
              </Button>
              <Button onClick={handleSubmit(onSubmit)}>{t('Cập nhật')}</Button>
            </>
          )}
        </div>
      </SForm>
      {kindToSend === 4 && method === 1 && (
        <Selections
          initSelections={selectedData ? selectedData.selections : null}
          candidate={candidate}
        />
      )}
    </FormProvider>
  );
}
