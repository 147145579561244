import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import SettingTools from './settingTools';
import {
  changePasswordApi,
  getInfoAdmin,
} from '../../../../states/duck/pages/admin/settingReducer/actions';
import Section from '@components/section';
import { useForm } from 'react-hook-form';
import Input from '@components/input';
import { VALIDATE_REQUIRE } from '@constants/validate';
import ErrorMessage from '@components/input/ErrorMessage';
import Button from '@components/button';
import { SSettingPage } from '@styled/admin/settingPage';
import { useTranslation } from 'react-i18next';

const SettingPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(getInfoAdmin());
  // }, [dispatch]);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const onSubmit = (values) => {
    const { passwordConfirmation, password, oldPassword } = values;
    dispatch(
      changePasswordApi({
        passwordConfirmation,
        password,
        oldPassword,
      }),
    );
  };
  return (
    <SSettingPage>
      <Section header={t('Thay đổi mật khẩu')}>
        <div className="module-content_first">
          <div className="input-item">
            <Input.Text
              register={register('oldPassword', {
                required: t(VALIDATE_REQUIRE),
              })}
              wrapperProps={{
                label: t('Mật khẩu cũ'),
                // icon: inputIcons.account,
                required: true,
              }}
            />
            <ErrorMessage errors={errors} field="oldPassword" />
          </div>
          <div className="input-item">
            <Input.Text
              register={register('password', { required: t(VALIDATE_REQUIRE) })}
              wrapperProps={{
                label: t('Mật khẩu mới'),
                // icon: inputIcons.account,
                required: true,
              }}
            />
            <ErrorMessage errors={errors} field="password" />
          </div>
          <div className="input-item">
            <Input.Text
              register={register('passwordConfirmation', {
                required: t(VALIDATE_REQUIRE),
              })}
              wrapperProps={{
                label: t('Xác nhận mật khẩu mới'),
                // icon: inputIcons.account,
                required: true,
              }}
            />
            <ErrorMessage errors={errors} field="passwordConfirmation" />
          </div>
          <div className="input-item" style={{ textAlign: 'center' }}>
            <Button type="submit" onClick={handleSubmit(onSubmit)}>
              {t('Lưu')}
            </Button>
          </div>
        </div>
      </Section>
      {/* <Section header={t('Mật khẩu cấp 2')}>
        <SettingTools />
      </Section> */}
    </SSettingPage>
  );
};
export default SettingPage;
