import React, { useEffect } from 'react';
import {
  getConferenceApi,
  getTopic1Api,
  getTopic2Api,
  getTopic3Api,
  getTopic4Api,
  getTopic6Api,
} from '../../../../states/duck/pages/checkCoupon/checkCouponListReducer/actions';
import { useDispatch } from 'react-redux';
import Form from './form';
import TableContent from './table';
import TableTools from './tableTools';
import { SCheckCouponListPage } from '@styled/checkCoupon/checkCouponListPage';

const ManageShareholderPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getConferenceApi());
    dispatch(getTopic1Api(1));
    dispatch(getTopic2Api(2));
    dispatch(getTopic3Api(3));
    dispatch(getTopic4Api(4));
    dispatch(getTopic6Api(6));
  }, [dispatch]);
  return (
    <SCheckCouponListPage>
      <Form />
      <TableTools />
      <TableContent />
    </SCheckCouponListPage>
  );
};
export default ManageShareholderPage;
