export const URL = 'info/authority';
export const URL_GET_BY_FILTER = 'info/authority-statistic'; 
export const URL_SEARCH = 'info/authority/search';
export const URL_HANDLE_AUTHORITY = 'authority/handler';
export const URL_CHANGE_PHONE_AUTHORITY = 'info/edit-authority';
export const URL_DOWLOAD_FILE = 'authority/import-template';
export const URL_DOWLOAD_FILE_COMMON = 'authority/download-authority-file';
export const URL_IMPORT_FILE = 'authority/import';

export const GET_LIST = 'GET_CHECKIN_AUTHORITY_LIST_LIST';
export const GET_LOADING = 'GET_CHECKIN_AUTHORITY_LIST_LOADING';
export const SET_CHOOSE_LIST = 'SET_CHECKIN_AUTHORITY_LIST_CHOOSE_LIST';
export const TOGGLE_MODAL = 'TOGGLE_CHECKIN_AUTHORITY_LIST_MODAL';
export const SET_PAGE_SIZE = 'SET_CHECKIN_AUTHORITY_LIST_PAGE_SIZE';
export const SET_PAGE_NUMBER = 'SET_CHECKIN_AUTHORITY_LIST_PAGE_NUMBER';
export const SET_FILTER = 'SET_CHECKIN_AUTHORITY_LIST_FILTER';
export const SET_TOTAL = 'SET_CHECKIN_AUTHORITY_STATUS_TOTAL';
export const SET_SENDING = 'SET_CHECKIN_AUTHORITY_SENDING';