import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ResultItemShow from './components/ResultItemShow';
import styled from 'styled-components';
import { Card } from './sectionInfo';
import { UITable } from 'components/bases/UI/Table';
import Pagination from '@components/pagination';
// import { pageSize } from '.';
import { t } from 'translate/store';
import { useVoteConfig } from './store';

const SectionOpeningResult = ({ dataShow, isDetail }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation();
  const topic1 = useSelector((state) => state.result.result.topic1);

  const config = useSelector(
    (state) => state.admin.configSystem.info.homeDisplaySetting,
  );
  const pageSize = config?.topic1Table || 5;

  dataShow.sort((a, b) => {
    if (a && b && a.topic && b.topic && a.topic.order > b.topic.order) {
      return 1;
    } else if (a && b && a.topic && b.topic && a.topic.order < b.topic.order) {
      return -1;
    }
    return 0;
  });
  if (topic1)
    topic1.sort((a, b) => {
      if (a && b && a.order > b.order) {
        return 1;
      } else if (a && b && a.order < b.order) {
        return -1;
      }
      return 0;
    });
  // topic1.sort((a, b) => {
  //   let createA = new Date(a.createdAt).getTime();
  //   let createB = new Date(b.createdAt).getTime();
  //   if (createA > createB) {
  //     return 1;
  //   } else if (createA < createB) {
  //     return -1;
  //   }
  //   return 0;
  // });

  if (isDetail)
    return (
      <Styles2 header={t('Thông qua thủ tục khai mạc')} isDetail>
        {(topic1 || []).map((item, index) => {
          return (
            <ResultItemShow
              key={index}
              index={index}
              topic={item}
              isDetail={isDetail}
            />
          );
        })}
      </Styles2>
    );

  return (
    <Styles
      header={t('Thông qua thủ tục khai mạc')}
      label={t('Kết quả biểu quyết tại Đại hội')}
    >
      <UITable
        items={topic1.slice(
          (currentPage - 1) * pageSize,
          currentPage * pageSize,
        )}
        customHeader={<ResultHeaders />}
      >
        {(item, ind) => {
          const index = currentPage * pageSize - pageSize + ind;
          return <ResultItemShow key={index} index={index} topic={item} />;
        }}
      </UITable>
      <div className="pagination-cover">
        <Pagination
          // handleChangePageSize={getPageSize}
          hideSelectSize={true}
          current={currentPage}
          total={topic1.length}
          pageSize={pageSize}
          onChange={(page) => {
            setCurrentPage(page);
          }}
        />
      </div>
    </Styles>
  );
};

export default SectionOpeningResult;

const Styles = styled(Card)`
  margin: 32px 0;
  p {
    color: ${(props) => props.theme.textColor || ''};
  }
`;

export const ResultHeaders = () => {
  const { getVoteLabel } = useVoteConfig();
  return (
    <thead>
      <tr>
        <th rowSpan={2}>{t('Stt')}</th>
        <th rowSpan={2}>{t('Nội dung biểu quyết')}</th>
        {getVoteLabel(1) && (
          <th colSpan={2} style={{ borderBottom: '1px solid white' }}>
            {getVoteLabel(1)}
          </th>
        )}
        {getVoteLabel(2) && (
          <th colSpan={2} style={{ borderBottom: '1px solid white' }}>
            {getVoteLabel(2)}
          </th>
        )}
        {getVoteLabel(3) && (
          <th colSpan={2} style={{ borderBottom: '1px solid white' }}>
            {getVoteLabel(3)}
          </th>
        )}
      </tr>
      <tr>
        {getVoteLabel(1) && (
          <>
            <th>{t('Số CP biểu quyết')}</th>
            <th>{t('Tỷ lệ')} %</th>
          </>
        )}
        {getVoteLabel(2) && (
          <>
            <th>{t('Số CP biểu quyết')}</th>
            <th>{t('Tỷ lệ')} %</th>
          </>
        )}
        {getVoteLabel(3) && (
          <>
            <th>{t('Số CP biểu quyết')}</th>
            <th>{t('Tỷ lệ')} %</th>
          </>
        )}
      </tr>
    </thead>
  );
};

const Styles2 = styled(Card)`
  margin: 32px 0;
  h2,
  p {
    color: #3f475e;
  }
  .card-content {
    .type2 {
      .head {
        .title {
          margin-bottom: 20px;
        }
      }
      .content {
        padding-top: 0px;
        background: none;
        border: none;
      }
    }
  }
`;
