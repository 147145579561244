export const URL = "admin/register-officer";
export const URL_TABLE = "info/table";
export const URL_EDIT = "admin/edit";
export const URL_DELETE = "admin/delete";


export const GET_LIST = "GET_CREATE_GROUP_LIST";
export const SET_CHOOSE_LIST = "SET_CREATE_GROUP_CHOOSE_LIST";
export const TOGGLE_MODAL = "TOGGLE_CREATE_GROUP_MODAL";




