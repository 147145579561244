import styled from 'styled-components';
import { SHotline, SModalEditHotline } from '../../hotlinePage';
import { SSection } from '@components/section';
export const SAuthorizationListPage = styled(SHotline)`
  .module-content {
    margin: 0;
  }
  .search-wrapper {
    margin: 0px;
    margin-bottom: -20px;
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
    .search-item {
      padding: 0px;
      width: 100%;
      .file-handle {
        display: block;
        /* justify-content: flex-end; */
        /* margin-right: -8px; */
        /* margin-left: -8px; */
        button {
          padding: 2px 4px;
          width: 100%;
          /* max-width: 33.33%; */
          height: 40px;
          display: flex;
          justify-content: center;
          box-sizing: border-box;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          color: #718098;
          margin: 0px;
          border-radius: 0;
          border: 1px solid #dfe9f5;
          svg {
            margin-right: 12px;
            path {
              fill: #00cb82;
            }
          }
          /* &.grey-button {
          background: #718098;
        }
        &.blue-button {
          background: #2f80ed;
        } */
          &.upload-btn {
            position: relative;
            background: #fff;
            input {
              opacity: 0;
              position: absolute;
              width: 100%;
              top: 0;
              left: 0;
              height: 100%;
              cursor: pointer;
            }
            .file {
              cursor: pointer;
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 1px solid #dfe9f5;
              text-align: center;
              color: #2f80ed;
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              background: #f5faff;

              svg {
                position: absolute;
                top: -10px;
                right: -20px;
                cursor: pointer;
              }
            }
          }
        }
      }
      button {
        width: 100%;
      }
      .search {
        background: #00cb82;
      }
      margin-bottom: 20px;
    }
  }

  @media only screen and (max-width: 991px) {
    .search-wrapper {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  @media only screen and (max-width: 767px) {
    .search-wrapper {
      grid-template-columns: 1fr;
      padding: 0px 60px 20px;
      .search-item {
        margin-bottom: 0;
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .search-wrapper {
      grid-template-columns: 1fr;
      padding: 0px 0px 20px;
      .search-item {
      }
    }
  }
`;
export const SModalEditAuthorizationList = styled(SModalEditHotline)`
  padding: 0 56px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 45px;

  .handle-wrapper {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    .handle-item {
      box-sizing: border-box;
      padding-left: 16px;
      padding-right: 16px;
      &.width-50 {
        width: 50%;
      }
      &.width-100 {
        width: 100%;
      }
    }
  }
  .button-group {
    padding-left: 16px;
    padding-right: 16px;
    justify-content: flex-end;
  }
`;

export const SSectionCustom = styled(SSection)`
  .module-header {
    margin-bottom: 21px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .title {
      margin: 0;
      font-size: 20px;
      line-height: 30px;
      color: #333b6a;
      font-weight: bold;
    }
  }
  @media only screen and (max-width: 1326px) {
    .module-header {
      .file-handle {
        margin-top: 10px;
        width: calc(100% + 16px);
        justify-content: space-between;
        button {
          width: 33.33%;
        }
      }
    }
  }
  @media only screen and (max-width: 1054px) {
    .module-header {
    }
  }
  @media only screen and (max-width: 780px) {
    .module-header {
    }
  }
  @media only screen and (max-width: 500px) {
    .module-header {
    }
  }
`;
