// import Pagination from '@components/pagination';
import Table from 'components/bases/shared/table';
import React, { useState, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import {
//   setFilter,
//   getUserApi,
//   setChooseList,
// } from '@duck/pages/admin/authorizationListReducer/actions';
// import { transformNumberPrinter } from '@configs/transformNumber';
import { useTranslation } from 'react-i18next';
// import { getFileCommonApi } from '@duck/pages/checkin/authorityListReducer/actions';
import Loading from '@components/loading';
import { formatDateTime } from '@configs/formatDate';
import styled from 'styled-components';

// var imgFileExtensions = [
//   '.jpeg',
//   '.jpg',
//   '.peg',
//   '.tiff',
//   '.png',
//   '.svg',
//   '.gif',
//   '.bmp',
// ];
// function isImage(sFileName) {
//   if (sFileName && sFileName.length > 0) {
//     var isImg = false;
//     for (var j = 0; j < imgFileExtensions.length; j++) {
//       var sCurExtension = imgFileExtensions[j];
//       if (
//         sFileName
//           .substr(sFileName.length - sCurExtension.length, sCurExtension.length)
//           .toLowerCase() === sCurExtension.toLowerCase()
//       ) {
//         isImg = true;
//         break;
//       }
//     }
//     return isImg;
//   }
// }

export const TableList = (dataList) => {
  const data = dataList.dataList;
  const { t } = useTranslation();
  // const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  // const [firstTime, setFirstTime] = useState(true);
  // const filter = useSelector((state) => state.admin.authorizationList.filter);
  // const total = useSelector((state) => state.admin.authorizationList.total);

  // useEffect(() => {
  //   if (firstTime) {
  //     setFirstTime(false);
  //     dispatch(
  //       setFilter({
  //         identityNumber: '',
  //         status: 'all',
  //         pageSize: 10,
  //         pageNumber: 1,
  //         type: 'all',
  //       }),
  //     );
  //     dispatch(setChooseList([]));
  //   }
  // }, [firstTime, dispatch]);
  // useEffect(() => {
  //   if (firstTime === false) {
  //     dispatch(setChooseList([]));
  //     dispatch(getUserApi(filter));
  //   }
  // }, [dispatch, firstTime, filter]);

  // const getPage = (pageNumber) => {
  //   dispatch(
  //     setFilter({
  //       ...filter,
  //       pageNumber,
  //     }),
  //   );
  // };
  // const getPageSize = (pageSize) => {
  //   dispatch(
  //     setFilter({
  //       ...filter,
  //       pageSize,
  //       pageNumber: 1,
  //     }),
  //   );
  // };
  const theads = [
    { name: 'Stt' },
    { name: 'Biểu mẫu' },
    { name: 'File mẫu' },
    { name: 'Thời gian' },
  ];

  return (
    <Styles>
      <div className="table-container">
        {loading && <Loading title={t('Đang xử lý. Vui lòng đợi')} />}
        <div className="table">
          <Table header={theads}>
            {data.length > 0 ? (
              <>
                {data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td> {t(item.data.templateType)}</td>
                      <td style={{ cursor: 'pointer' }}>
                        <a
                          href={item.data.fileUrl}
                          download={item.data.originalFileName}
                        >
                          <span
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                            onClick={() => {}}
                          >
                            <span style={{ color: '#2F80ED' }}>
                              {item.data.originalFileName}
                            </span>
                            <span>
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0_17_5576)">
                                  <path
                                    d="M8.993 7.007L8.969 0H7.062L7.014 7.014L4.5 4.5L3 6L8 11L13 6L11.5 4.5L8.993 7.007Z"
                                    fill="#00CB82"
                                  />
                                  <path
                                    d="M14 11V14H2V11H0V17H16V11H14Z"
                                    fill="#00CB82"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_17_5576">
                                    <rect width="16" height="16" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </span>
                          </span>
                        </a>
                      </td>
                      <td> {formatDateTime(item.updatedAt)}</td>
                    </tr>
                  );
                })}
              </>
            ) : (
              <tr>
                <td colSpan={100} className="center">
                  {' '}
                  {t('Chưa có dữ liệu')}{' '}
                </td>
              </tr>
            )}
          </Table>

          {/* {data.length > 0 && (
          <Pagination
            handleChangePageSize={getPageSize}
            current={filter.pageNumber}
            total={total}
            pageSize={filter.pageSize}
            onChange={getPage}
          />
        )} */}
        </div>
      </div>
    </Styles>
  );
};

const Styles = styled.div`
  .table {
    min-height: 300px;
  }
`;
