export const URL_GET_GUEST = "info/guest";
export const URL_CREATE_GUEST = "shareholder/create-guest";
export const URL_EDIT_GUEST = "shareholder/edit-guest";
export const URL_DELETE_GUEST = "shareholder/delete-guest"; 
export const URL_ADD_GUEST = "shareholder/add-guest-list";
export const URL_DOWNLOAD = "shareholder/download/sample-guest-file";
export const URL_EXPORT_GUEST = "info/guest/export";

export const GET_LIST_GUEST = "GET_LIST_GUEST";
export const SET_PAGE_SIZE = "SET_GUEST_PAGE_SIZE";
export const SET_PAGE_NUMBER = "SET_GUEST_PAGE_NUMBER";
export const SET_TOTAL = "SET_GUEST_TOTAL";
export const SET_FILTER = "SET_GUEST_FILTER";
export const SET_LIST_CHOSE = "SET_LIST_CHOSE";
export const SET_SENDING = "SET_SENDING";