import Input from '@components/input';
import { SModalDetail } from '@styled/checkCoupon/checkCouponListPage';
import React, { Fragment, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { transformNumberPrinter } from './../../../../bases/configs';
import { useTranslation, Trans } from 'react-i18next';
import { useVoteConfig } from '@pages/resultPage/store';

const findPositionIndex = (obj, arr) => {
  let index = 0;
  for (let i = 0; i < arr.length; i++) {
    if (obj.topic_id && obj.topic_id._id === arr[i]._id) {
      index = i;
    }
  }
  return index;
};
const setResultTopic = (topic, voteKind, kind, shareholderId) => {
  let topicMirror = [...topic];
  for (let i = 0; i < voteKind.length; i++) {
    let index = findPositionIndex(voteKind[i], topic);
    if (index >= 0) {
      topicMirror.splice(index, 1, voteKind[i]);
    }
    //kind =4 && invalid => votes result khong co list selections => add
    if (
      kind === 4 &&
      voteKind[i].invalidVoted &&
      voteKind[i].selections &&
      voteKind[i].selections.length === 0
    ) {
      const temp = {
        ...voteKind[i],
        selections: topic[index].selections,
      };
      topicMirror.splice(index, 1, temp);
    }
  }
  if (kind !== 2) {
    topicMirror.sort((a, b) => {
      let orderA = a.topic_id ? a.topic_id.order : a.order;
      let orderB = b.topic_id ? b.topic_id.order : b.order;
      if (orderA > orderB) {
        return 1;
      } else if (orderA < orderB) {
        return -1;
      }
      return 0;
    });
  } else {
    topicMirror = topicMirror
      .sort((a, b) => {
        let orderA = a.topic_id ? a.topic_id.order : a.order;
        let orderB = b.topic_id ? b.topic_id.order : b.order;
        if (orderA > orderB) {
          return 1;
        } else if (orderA < orderB) {
          return -1;
        }
        return 0;
      })
      .map((item, index) => {
        let unable = false;
        let unableShareholder = item.topic_id
          ? item.topic_id.unableShareholder
          : item.unableShareholder;
        if (unableShareholder.indexOf(shareholderId) >= 0) {
          unable = true;
        }
        return { ...item, unable };
      });
  }
  return topicMirror;
};

const ShowDetailsModal = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const {
    topic1,
    topic2,
    topic3,
    topic4,
    topic6,
    shareholderDetails,
    listAuthorities,
  } = useSelector((state) => state.checkCoupon.checkCouponList);
  const { watch, control } = useForm();
  const { getVoteLabel } = useVoteConfig();
  const info = shareholderDetails ? shareholderDetails.shareholder : {};
  const votes = shareholderDetails ? shareholderDetails.votes : {};
  const [voterId, setVoterId] = useState(null);
  useEffect(() => {
    if (watch('voterId')) setVoterId(watch('voterId').value);
    if (listAuthorities.length === 0) setVoterId(info._id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('voterId')]);
  const voteKind1 = votes
    ? votes.filter(
        (item) =>
          item.topic_id !== null && item.kind === 1 && item.voter === voterId,
      )
    : [];
  const voteKind2 = votes
    ? votes.filter(
        (item) =>
          item.topic_id !== null && item.kind === 2 && item.voter === voterId,
      )
    : [];
  const voteKind3 = votes
    ? votes.filter(
        (item) =>
          item.topic_id !== null && item.kind === 3 && item.voter === voterId,
      )
    : [];
  const voteKind4 = votes
    ? votes.filter(
        (item) =>
          item.topic_id !== null && item.kind === 4 && item.voter === voterId,
      )
    : [];
  const voteKind6 = votes
    ? votes.filter(
        (item) =>
          item.topic_id !== null && item.kind === 6 && item.voter === voterId,
      )
    : [];
  const topicResult1 = setResultTopic(topic1, voteKind1, 1, info._id);
  const topicResult2 = setResultTopic(topic2, voteKind2, 2, info._id);
  const topicResult3 = setResultTopic(topic3, voteKind3, 3, info._id);
  const topicResult4 = setResultTopic(topic4, voteKind4, 4, info._id);
  const topicResult6 = setResultTopic(topic6, voteKind6, 6, info._id);
  return (
    <SModalDetail>
      <div className="head">
        <p className="title">
          {/* {t("Phiếu biểu quyết của Cổ đông")} &nbsp;
          <span className="bold">{info.name}</span>{' '} */}
          <Trans
            i18nKey="Phiếu biểu quyết của Cổ đông A"
            values={{
              name: info.name,
            }}
            defaults=""
          />
        </p>
      </div>
      {listAuthorities.length > 0 && (
        <div className="select-wrapper">
          <Input.Select
            name="voterId"
            control={control}
            selectProps={{
              placeholder: t('Chọn Cổ đông được ủy quyền'),
              options: listAuthorities,
            }}
          />
        </div>
      )}

      {((voterId && voterId !== info._id) || listAuthorities.length === 0) && (
        <div className="content">
          <div className="vote-details">
            {shareholderDetails ? (
              <Fragment>
                {topicResult1.map((item, index) => {
                  return (
                    <div className="vote-item" key={index}>
                      <div className="item-head">
                        <p className="title">
                          <span>
                            {t('Nội dung')}{' '}
                            {(index + 1).toString().padStart(2, 0)}:{' '}
                          </span>
                          {item.topic_id
                            ? language === 'vi'
                              ? item.topic_id.name
                              : item.topic_id.name_2
                            : language === 'vi'
                            ? item.name
                            : item.name_2}
                        </p>
                        {item.point === 1 ? (
                          <span className="common__btn">{getVoteLabel(1)}</span>
                        ) : item.point === 2 ? (
                          <span className="common__btn red__btn">
                            {getVoteLabel(2)}
                          </span>
                        ) : item.point === 3 || item.point === 4 ? (
                          <span className="common__btn blue__btn">
                            {getVoteLabel(3)}
                          </span>
                        ) : item.point === 0 ? (
                          <span className="common__btn orange__btn">
                            {t('Không hợp lệ BKP')}
                          </span>
                        ) : (
                          <span className="common__btn orange__btn">
                            {t('Chưa biểu quyết')}
                          </span>
                        )}
                      </div>
                    </div>
                  );
                })}
                {topicResult2.map((item, index) => {
                  return (
                    <div className="vote-item" key={index}>
                      <div className="item-head">
                        <p className="title">
                          <span>
                            {t('Nội dung')}{' '}
                            {(index + 1 + topicResult1.length)
                              .toString()
                              .padStart(2, 0)}
                            :{' '}
                          </span>
                          {item.topic_id
                            ? language === 'vi'
                              ? item.topic_id.name
                              : item.topic_id.name_2
                            : language === 'vi'
                            ? item.name
                            : item.name_2}
                        </p>
                        {item.unable ? (
                          <p className="note">
                            {t('Cổ đông bị chặn biểu quyết cho nội dung này')}
                          </p>
                        ) : (
                          ''
                        )}
                        {item.point === 1 ? (
                          <span className="common__btn">{getVoteLabel(1)}</span>
                        ) : item.point === 2 ? (
                          <span className="common__btn red__btn">
                            {getVoteLabel(2)}
                          </span>
                        ) : item.point === 3 || item.point === 4 ? (
                          <span className="common__btn blue__btn">
                            {getVoteLabel(3)}
                          </span>
                        ) : item.point === 0 ? (
                          <span className="common__btn orange__btn">
                            {t('Không hợp lệ BKP')}
                          </span>
                        ) : (
                          <span className="common__btn orange__btn">
                            {t('Chưa biểu quyết')}
                          </span>
                        )}
                      </div>
                    </div>
                  );
                })}
                {topicResult6.map((item, index) => {
                  return (
                    <div className="vote-item" key={index}>
                      <div className="item-head">
                        <p className="title">
                          <span>
                            {t('Nội dung')}{' '}
                            {(
                              index +
                              1 +
                              topicResult1.length +
                              topicResult2.length
                            )
                              .toString()
                              .padStart(2, 0)}
                            :{' '}
                          </span>
                          {item.topic_id
                            ? language === 'vi'
                              ? item.topic_id.name
                              : item.topic_id.name_2
                            : language === 'vi'
                            ? item.name
                            : item.name_2}
                        </p>
                        {item.unable ? (
                          <p className="note">
                            {t('Cổ đông bị chặn biểu quyết cho nội dung này')}
                          </p>
                        ) : (
                          ''
                        )}
                        {item.point === 1 ? (
                          <span className="common__btn">{getVoteLabel(1)}</span>
                        ) : item.point === 2 ? (
                          <span className="common__btn red__btn">
                            {getVoteLabel(2)}
                          </span>
                        ) : item.point === 3 || item.point === 4 ? (
                          <span className="common__btn blue__btn">
                            {getVoteLabel(3)}
                          </span>
                        ) : item.point === 0 ? (
                          <span className="common__btn orange__btn">
                            {t('Không hợp lệ BKP')}
                          </span>
                        ) : (
                          <span className="common__btn orange__btn">
                            {t('Chưa biểu quyết')}
                          </span>
                        )}
                      </div>
                    </div>
                  );
                })}
                {topicResult4.map((item, index) => {
                  return (
                    <div key={index} className="item vote-item">
                      {item.invalidVoted ? (
                        <div
                          className="item-head"
                          style={{ marginBottom: '12px' }}
                        >
                          <p className="title">
                            <span>
                              {t('Nội dung')}{' '}
                              {(
                                index +
                                1 +
                                topicResult1.length +
                                topicResult2.length +
                                topicResult6.length
                              )
                                .toString()
                                .padStart(2, 0)}
                              :{' '}
                            </span>
                            {item.topic_id
                              ? language === 'vi'
                                ? item.topic_id.name
                                : item.topic_id.name_2
                              : language === 'vi'
                              ? item.name
                              : item.name_2}
                          </p>
                          {item.invalidVoted && (
                            <span className="common__btn orange__btn">
                              {t('Không hợp lệ BKP')}
                            </span>
                          )}
                        </div>
                      ) : (
                        <p className="title">
                          {t('Nội dung')}{' '}
                          {(
                            index +
                            1 +
                            topicResult1.length +
                            topicResult2.length +
                            topicResult6.length
                          )
                            .toString()
                            .padStart(2, 0)}
                          .{' '}
                          {item.topic_id
                            ? language === 'vi'
                              ? item.topic_id.name
                              : item.topic_id.name_2
                            : language === 'vi'
                            ? item.name
                            : item.name_2}
                        </p>
                      )}

                      <p className="desc">
                        {t('Tổng phiếu được quyền biểu quyết cho tờ trình')}:
                        <span>
                          {listAuthorities.length > 0
                            ? transformNumberPrinter(
                                Number(watch('voterId').sharesNumber) *
                                  Number(
                                    item.topic_id
                                      ? item.topic_id.selectNumber
                                      : item.selectNumber,
                                  ),
                              )
                            : transformNumberPrinter(
                                Number(
                                  item.topic_id
                                    ? item.topic_id.selectNumber
                                    : item.selectNumber,
                                ) * Number(info ? info.sharesNumber : 0),
                              )}
                          {/* {transformNumberPrinter(
                            Number(
                              item.topic_id
                                ? item.topic_id.selectNumber
                                : item.selectNumber,
                            ) * Number(info ? info.sharesNumber : 0),
                          )} */}
                        </span>
                      </p>
                      <div className="table-content">
                        <div className="table">
                          <div className="table-scroll">
                            <table>
                              <thead>
                                <tr>
                                  <th>{t('Stt')}</th>
                                  <th>{t('Họ và tên')}</th>
                                  <th>{t('Số phiếu bầu')} </th>
                                </tr>
                              </thead>
                              <tbody>
                                {item.selections.map(
                                  (itemSelections, indexSelections) => {
                                    return (
                                      <tr key={indexSelections}>
                                        <td>{indexSelections + 1}</td>
                                        <td>{itemSelections.selectName}</td>
                                        <td>
                                          {itemSelections.amount ||
                                          itemSelections.amount === 0
                                            ? transformNumberPrinter(
                                                itemSelections.amount,
                                              )
                                            : ''}
                                        </td>
                                      </tr>
                                    );
                                  },
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {topicResult3.map((item, index) => {
                  return (
                    <div className="vote-item" key={index}>
                      <div className="item-head">
                        <p className="title">
                          <span>
                            {t('Nội dung')}{' '}
                            {(
                              index +
                              1 +
                              topicResult1.length +
                              topicResult2.length +
                              topicResult4.length +
                              topicResult6.length
                            )
                              .toString()
                              .padStart(2, 0)}
                            :{' '}
                          </span>
                          {item.topic_id
                            ? language === 'vi'
                              ? item.topic_id.name
                              : item.topic_id.name_2
                            : language === 'vi'
                            ? item.name
                            : item.name_2}
                        </p>
                        {item.point === 1 ? (
                          <span className="common__btn">{getVoteLabel(1)}</span>
                        ) : item.point === 2 ? (
                          <span className="common__btn red__btn">
                            {getVoteLabel(2)}
                          </span>
                        ) : item.point === 3 || item.point === 4 ? (
                          <span className="common__btn blue__btn">
                            {getVoteLabel(3)}
                          </span>
                        ) : item.point === 0 ? (
                          <span className="common__btn orange__btn">
                            {t('Không hợp lệ BKP')}
                          </span>
                        ) : (
                          <span className="common__btn orange__btn">
                            {t('Chưa biểu quyết')}
                          </span>
                        )}
                      </div>
                    </div>
                  );
                })}
              </Fragment>
            ) : (
              <p
                className="desc"
                style={{ textAlign: 'center', fontSize: '20px', marginTop: 0 }}
              >
                {t('Cổ đông chưa tham gia biểu quyết')}
              </p>
            )}

            {
              // <div className="vote-item">
              //     <div className="item-head">
              //         <p className="title"><span>Nội dung 3:</span> Thông qua việc ủy quyền cho HĐQT lựa chọn đơn vị kiểm toán BCTC  cho năm tài chính 2020.</p>
              //         <span className="common__btn blue__btn">Ý kiến khác</span>
              //     </div>
              //     <div className="item-content">
              //         <p className="desc">Pianoforte solicitude so decisively unpleasing conviction is partiality he. Or particular so diminution entreaties oh do. Real he me fond show gave shot plan. Mirth blush linen small hoped way its along. Resolution frequently apartments off all discretion devonshire. </p>
              //     </div>
              // </div>
            }
          </div>
        </div>
      )}
    </SModalDetail>
  );
};
export default ShowDetailsModal;
