import * as types from './constants';
import * as common from "../../../../common/constants";

var initialState = {
    tableList: [],
    listTopicChoose: [],
    toggleModal: false,
    filter: {
        "identityNumber": "",
        "joinType": "all",
        "status": "all",
        "pageSize": common.PAGE_SIZE,
        "pageNumber": 1
    },
    total: 0,
    invitationType: "all",
    addressList: [],
    info: {},
    downloadPasswordList: false,
    passwordList: [],
    loading: false,
};
var reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_LIST:
            return {...state, tableList: action.value};
        case types.SET_LOADING:
            return {...state, loading: action.value};
        case types.SET_PASSWORD_LIST:
            return {...state, passwordList: action.value};
        case types.SET_DOWNLOAD_PASSWORD_LIST:
            return {...state, downloadPasswordList: action.value};
        case types.GET_TOGGLE_MODAL:
            return {...state, toggleModal: action.value};
        case types.GET_INFO:
            return {...state, info: action.value};
        case types.SET_FILTER:
            return {...state, filter: action.value};
        case types.SET_TOTAL:
            return {...state, total: action.value};
        case types.SET_TYPE:
            return {...state, invitationType: action.value};
        case types.SET_ADDRESS:
            return {...state, addressList: action.value};
        default:
            return state;
    }
}
export default reducer;