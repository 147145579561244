import * as types from './constants';
import * as common from "../../../../common/constants";

var initialState = {
  tableList: [],
  listTopicChoose: [],
  info: {
    totalOnline: 0,
    totalAuthority: 0,
    totalLive: 0
  },
  report: null,
  total: 0,
  filter: {
    "identityNumber": '',
    "status": "all",
    "authority": "all",
    "online": "all",
    "joinType": 'all',
    "pageSize": common.PAGE_SIZE,
    "pageNumber": 1
  },
  statistic: [],
  downloadUserList: [],
  downloadList: false,
  downloadReport: false,
  print: false
};

var reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_DOWNLOAD_LIST:
      return { ...state, downloadList: action.value };

    case types.GET_DOWNLOAD_USER_LIST:
      return { ...state, downloadUserList: action.value };

    case types.GET_STATISTIC:
      return { ...state, statistic: action.value };

    case types.GET_LIST:
      return { ...state, tableList: action.value };

    case types.SET_DOWNLOAD_REPORT:
      return { ...state, downloadReport: action.value };

    case types.SET_PRINT:
      return { ...state, print: action.value };

    case types.GET_INFO:
      return { ...state, info: action.value };

    case types.GET_REPORT:
      return { ...state, report: action.value };

    case types.SET_FILTER:
      return { ...state, filter: action.value };

    case types.SET_TOTAL:
      return { ...state, total: action.value };

    case types.SET_CHOOSE_LIST:
      return { ...state, listTopicChoose: action.value };

    default:
      return state;
  }
}
export default reducer;