import * as constants from './constants';
import { callApi, callDownloadApi } from './../../../../common';
import PNotify from 'pnotify/dist/es/PNotify';
import { handleError } from '@configs/handleError';
import * as common from './../../../../common/constants';
var FileSaver = require('file-saver');

export const getUserApi = (body) => {
  const {
    identityNumber,
    joinType,
    status,
    pageSize,
    pageNumber,
    scale,
    type,
    sortBy,
    order,
  } = body;
  return (dispatch) =>
    callApi(`${constants.URL}/${pageSize}/${pageNumber}`, 'POST', {
      identityNumber,
      joinType,
      status,
      authority: 'notAuthority',
      scale,
      type,
      sortBy,
      order,
    }).then(function (res) {
      dispatch(getListAccount(res.data.data.shareholders));
      dispatch(setTotal(res.data.data.total));
    });
};

export const getInfoConferenceApi = (body) => {
  return (dispatch) =>
    callApi(`${constants.URL_CONFERENCE}`, 'GET', null).then(function (res) {
      dispatch(setInfoConference(res.data.conference.conference));
    });
};
export const confirmShareholderListApi = () => {
  return (dispatch) =>
    callApi(`${constants.URL_CONFIRM}`, 'POST', null).then(function (res) {});
};
export const downloadFileAPI = () => {
  return (dispatch) =>
    callDownloadApi(`${constants.URL_DOWNLOAD}`, 'GET', null).then(function (
      res,
    ) {
      var file = new Blob([res.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
      });
      FileSaver.saveAs(file, 'Danhsachcodong');
    });
};
export const getEditApi = (id, body, filter) => {
  return (dispatch) =>
    callApi(`${constants.URL_EDIT}/${id}`, 'POST', body).then(function (res) {
      setTimeout(() => {
        dispatch(getUserApi(filter));
      }, 500);
      dispatch(setChooseList([]));
      dispatch(setToggleModal(false));
      PNotify.success({
        title: 'Thành công',
        text: 'Sửa thành công',
        destroy: true,
        delay: 2000,
      });
    });
};
export const getDeleteApi = (filter) => {
  return (dispatch) =>
    callApi(`${constants.URL_DELETE}`, 'POST', null)
      .then(function (res) {
        // setTimeout(() => {
        //   dispatch(getUserApi(filter));
        // }, 500);
        dispatch(setToggleModal(false));
        dispatch(
          setFilter({
            identityNumber: '',
            joinType: 'all',
            status: 'all',
            pageSize: common.PAGE_SIZE,
            pageNumber: 1,
          }),
        );
        PNotify.success({
          title: 'Thành công',
          text: 'Xóa danh sách thành công',
          destroy: true,
          delay: 2000,
        });
      })
      .catch(handleError);
};
export const getCreateApi = (body, filter) => {
  return (dispatch) =>
    callApi(`${constants.URL_CREATE}`, 'POST', body)
      .then(function (res) {
        dispatch(setSending(false));
        PNotify.success({
          title: 'Thành công',
          text: 'Tạo danh sách thành công',
          destroy: true,
          delay: 2000,
        });
        setTimeout(() => {
          dispatch(
            setFilter({
              identityNumber: '',
              joinType: 'all',
              status: 'all',
              pageSize: common.PAGE_SIZE,
              pageNumber: 1,
            }),
          );
          dispatch(
            getUserApi({
              identityNumber: '',
              joinType: 'all',
              status: 'all',
              pageSize: common.PAGE_SIZE,
              pageNumber: 1,
            }),
          );
        }, 500);
      })
      .catch(function (err) {
        dispatch(setSending(false));
        if (err) {
          if (err.response && err.response.data && err.response.data.message) {
            PNotify.error({
              title: 'Lỗi',
              text: err.response.data.message,
              destroy: true,
              delay: 2000,
            });
          } else if (err.response) {
            PNotify.error({
              title: 'Lỗi',
              text: err.response.statusText,
              destroy: true,
              delay: 2000,
            });
          }
        }
      });
};

export const setToggleModal = (value) => {
  return {
    type: constants.TOGGLE_MODAL,
    value,
  };
};
export const setInfoConference = (value) => {
  return {
    type: constants.SET_INFO_CONFERENCE,
    value,
  };
};
export const setDetails = (value) => {
  return {
    type: constants.SET_DETAILS,
    value,
  };
};
export const setSending = (value) => {
  return {
    type: constants.SET_SENDING,
    value,
  };
};
export const getListAccount = (value) => {
  return {
    type: constants.GET_LIST,
    value,
  };
};
export const setChooseList = (value) => {
  return {
    type: constants.SET_CHOOSE_LIST,
    value,
  };
};
export const setFilter = (value) => {
  return {
    type: constants.SET_FILTER,
    value,
  };
};
export const setTotal = (value) => {
  return {
    type: constants.SET_TOTAL,
    value,
  };
};
