import * as types from './constants';
// import * as common from "../../../../common/constants";

var initialState = {
    info: {}
};
var reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_INFO:
            return { ...state, info: action.value }
        default: 
            return state;
    }
}
export default reducer;