import React from 'react';
import styled from 'styled-components';
import Modal, { SModalContent } from './index';
import Button from '../button';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { deleteGuestApi } from '@duck/pages/admin/initializeGuestReducer/actions';

// interface  {
//   title: string;
//   isOpen: boolean;
//   toggleModal: React.Dispatch<React.SetStateAction<boolean>>;
//   subtitle?: string;
//   onCancel?: () => void;
//   onSubmit: () => void;
//   content?: string;
// }

export default function DeleteModal({
  title = 'Xóa nội dung',
  onCancel,
  isOpen,
  toggleModal,
  content = 'Bạn có chắc chắn xóa các nội dung đã chọn?',
  onSubmit,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      title={t(title)}
      {...{ isOpen, toggleModal }}
      overwriteModalStyle={{
        maxWidth: '716px',
      }}
    >
      <SDeleteModal>
        <img
          src="../images/deleteImg.png"
          alt="delete"
          width={200}
          height={200}
        />
        <p> {t(content)} </p>
        <div className="group-button">
          <Button
            className="cancel-button"
            onClick={() => {
              onCancel && onCancel();
              toggleModal(false);
            }}
          >
            {t('Hủy')}
          </Button>
          <Button
            className="delete-button"
            onClick={() => {
              onSubmit && onSubmit();
              toggleModal(false);
            }}
          >
            {t('Xoá')}
          </Button>
        </div>
      </SDeleteModal>
    </Modal>
  );
}

export const SDeleteModal = styled(SModalContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  & > p {
    color: #333b6a;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    margin-top: 44px;
    margin-bottom: 32px;
  }

  .group-button {
    button {
      &.cancel-button {
        color: #718098;
        background: #dfe9f5;
      }
      &.delete-button {
        background: #ff6c6c;
        color: white;
      }
    }

    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px -8px;
    ${Button} {
      margin: 0 8px 8px;
    }
  }
  @media only screen and (max-width: 500px) {
    & > p {
      font-size: 17px;
    }
  }
`;
