import * as types from './constants';
import * as common from "./../../../../common/constants";

var initialState = {
    tableList: [],
    filter: {
        "identityNumber": "",
        "status": "all",
        "pageSize": common.PAGE_SIZE,
        "pageNumber": 1
    },
    total: 0
};
var reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_LIST:
            return {...state, tableList: action.value};
        case types.SET_FILTER:
            return {...state, filter: action.value};
        case types.SET_TOTAL:
            return {...state, total: action.value};
        default:
            return state;
    }
}
export default reducer;