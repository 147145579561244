export const URL_REPORT = 'check-coupon/result-voted';
export const URL_TOPIC = 'topic/kind';
export const URL_EXPORT_RESULT = 'info/vote-report-file';
export const URL_EXPORT_SHAREHOLDER = 'info/voted-shareholder-report';
export const URL_REPORT_VOTE_RESULT = 'conference/result';
export const URL_REPORT_VOTE_DETAIL = 'vote/report-vote';
export const URL_REPORT_NOT_VOTE = 'vote/not-vote';
// POST /vote/report-vote { take, page, kind, point, invalidVoted, fixId}

export const GET_REPORT = 'GET_CHECK_COUPON_RESULT_REPORT';
export const GET_TOPIC1 = 'GET_CHECK_COUPON_TOPIC1';
export const GET_TOPIC2 = 'GET_CHECK_COUPON_TOPIC2';
export const GET_TOPIC3 = 'GET_CHECK_COUPON_TOPIC3';
export const GET_TOPIC4 = 'GET_CHECK_COUPON_TOPIC4';
export const GET_TOPIC6 = 'GET_CHECK_COUPON_TOPIC6';

export const TOGGLE_MODAL = 'TOGGLE_MODAL';
//modal
export const SET_FILTER_MODAL = 'SET_FILTER_MODAL';
export const GET_RESULT_DETAIL = 'GET_RESULT_DETAIL';
export const GET_RESULT_TOTAL = 'GET_RESULT_TOTAL';
