import * as constants from './constants';
import { callResultApi, callApi } from '../../../../common';
import PNotify from 'pnotify/dist/es/PNotify';
import { handleError } from '@configs/handleError';
import { defaultResult } from '.';

export const getDataApi = (body) => {
  return (dispatch) =>
    callResultApi(`${constants.URL_RESULT}`, 'GET', null, false)
      .then(function (res) {
        if (res.data) dispatch(setData(res.data.data));
        else dispatch(setData(defaultResult));
      })
      .catch(handleError);
};
export const getDataMemberApi = (body) => {
  return (dispatch) =>
    callResultApi(`${constants.URL_MEMBER}`, 'GET', null, false)
      .then(function (res) {
        console.log('res', res);
        if (res.data) dispatch(getDataMember(res.data.data));
        else dispatch(setData(defaultResult));
      })
      .catch(handleError);
};
export const getConferenceInfoApi = () => {
  return (dispatch) =>
    callApi(`${constants.URL_CONFERENCE}`, 'GET', null, false)
      .then(function (res) {
        // console.log(res);
        if (res.data && res.data.conference && res.data.conference.conference)
          dispatch(
            setConferenceInfo({
              nameMeeting: res.data.conference.conference.nameMeeting,
              nameMeeting_2: res.data.conference.conference.nameMeeting_2,
              nameCompany: res.data.conference.conference.nameCompany,
              nameCompany_2: res.data.conference.conference.nameCompany_2,
              bannerUrl: res.data.conference.conference.urlBanner,
              livestreamLink: res.data.conference.conference.livestreamLink,
              livestreamLink2: res.data.conference.conference.livestreamLink_2, // english
              logoUrl: res.data.conference.conference.urlLogo,
            }),
          );
      })
      .catch(handleError);
};
export const getTimeEndApi = (body) => {
  return (dispatch) =>
    callResultApi(`${constants.URL_CONFERENCE}`, 'GET', null, false)
      .then(function (res) {
        dispatch(setTimeEnd(new Date(res.data.conference.timeVoting.end)));
        dispatch(
          setConferenceInfo({
            nameMeeting: '',
            nameMeeting_2: '',
            nameCompany: '',
            nameCompany_2: '',
            bannerUrl: '',
            logoUrl: '',
          }),
        );
      })
      .catch(handleError);
};
export const getUserApiLoading = (filter) => {
  const { pageSize, pageNumber, status, identityNumber } = filter;
  return (dispatch) =>
    callApi(
      `${constants.URL_OPINION}/${status}/${pageSize}/${pageNumber}`,
      'POST',
      { identityNumber },
      true,
    )
      .then(function (res) {
        dispatch(getListAccount(res.data.data.opinion));
        dispatch(setTotal(res.data.data.total));
      })
      .catch(handleError);
};
export const getUserApi = (filter) => {
  const { pageSize, pageNumber, status, identityNumber } = filter;
  return (dispatch) =>
    callApi(
      `${constants.URL_OPINION}/${status}/${pageSize}/${pageNumber}`,
      'POST',
      { identityNumber },
      false,
    )
      .then(function (res) {
        dispatch(getListAccount(res.data.data.opinion));
        dispatch(setTotal(res.data.data.total));
      })
      .catch(handleError);
};
export const inviteSpeakApi = (body, t) => {
  return (dispatch) =>
    callApi(`${constants.URL_INVITE_SPEAK}`, 'POST', body, false)
      .then(function (res) {
        dispatch(setToggleModal(false));
        PNotify.success({
          title: t('Thành công'),
          text: t('Mời Cổ đông phát biểu thành công'),
          destroy: true,
          delay: 2000,
        });
      })
      .catch(handleError);
};
export const getTopic1Api = (body) => {
  return (dispatch) =>
    callApi(`${constants.URL_TOPIC}/${body}`, 'GET', null, false).then(
      function (res) {
        dispatch(getTopic1(res.data.topics));
      },
    );
};
export const getTopic2Api = (body) => {
  return (dispatch) =>
    callApi(`${constants.URL_TOPIC}/${body}`, 'GET', null, false).then(
      function (res) {
        dispatch(getTopic2(res.data.topics));
      },
    );
};
export const getTopic3Api = (body) => {
  return (dispatch) =>
    callApi(`${constants.URL_TOPIC}/${body}`, 'GET', null, false).then(
      function (res) {
        dispatch(getTopic3(res.data.topics));
      },
    );
};
export const getTopic4Api = (body) => {
  return (dispatch) =>
    callApi(`${constants.URL_TOPIC}/${body}`, 'GET', null, false).then(
      function (res) {
        dispatch(getTopic4(res.data.topics));
      },
    );
};
export const getTopic6Api = (body) => {
  return (dispatch) =>
    callApi(`${constants.URL_TOPIC}/${body}`, 'GET', null, false).then(
      function (res) {
        dispatch(getTopic6(res.data.topics));
      },
    );
};
export const getTopic1 = (value) => {
  return {
    type: constants.GET_TOPIC1,
    value,
  };
};

export const getTopic2 = (value) => {
  return {
    type: constants.GET_TOPIC2,
    value,
  };
};
export const getTopic3 = (value) => {
  return {
    type: constants.GET_TOPIC3,
    value,
  };
};
export const getTopic4 = (value) => {
  return {
    type: constants.GET_TOPIC4,
    value,
  };
};
export const getTopic6 = (value) => {
  return {
    type: constants.GET_TOPIC6,
    value,
  };
};
export const setToggleModal = (value) => {
  return {
    type: constants.GET_TOGGLE_MODAL,
    value,
  };
};
const setData = (value) => {
  return {
    type: constants.SET_DATA,
    value,
  };
};
const getDataMember = (value) => {
  return {
    type: constants.GET_DATA_MEMBER,
    value,
  };
};
const setTimeEnd = (value) => {
  return {
    type: constants.SET_TIME_END,
    value,
  };
};
export const getListAccount = (value) => {
  return {
    type: constants.GET_LIST,
    value,
  };
};
export const setTotal = (value) => {
  return {
    type: constants.SET_TOTAL,
    value,
  };
};
export const setFilter = (value) => {
  return {
    type: constants.SET_FILTER,
    value,
  };
};
export const setConferenceInfo = (value) => {
  return {
    type: constants.SET_CONFERENCE_INFO,
    value,
  };
};
