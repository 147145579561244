import * as constants from './constants';
import {callApi} from './../../../../common';
import PNotify from 'pnotify/dist/es/PNotify';
import {handleError} from '@configs/handleError'

export const getInfoApi = (body, signOutFn) => {
  return (dispatch) =>
    callApi(`${constants.URL}`, 'POST', body)
      .then(function (res) {
        if (res) {
          PNotify.success({
            title: 'Thành công',
            text: 'Update thành công',
            destroy: true,
            delay: 2000,
          });
        } else {
          signOutFn();
          PNotify.success({
            title: 'Thành công',
            text: 'Khai báo thành công, vui lòng đăng nhập lại',
            destroy: true,
            delay: 2000,
          });
        }
      })
      .catch(err => handleError(err));
};
export const getInfoConferenceApi = () => {
  return (dispatch) =>
    callApi(`${constants.URL_INFO}`, 'GET', null).then(function (res) {
      dispatch(getInfo(res.data.conference.conference));
    });
};
export const getInfo = (value) => {
  return {
    type: constants.GET_INFO,
    value,
  };
};
