import Modal from '@components/modal';
import React, { Children, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Input from '@components/input';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import Button from '@components/button';
import { inputs } from './data';
import ErrorMessage from '../../../../bases/shared/input/ErrorMessage';
import {
  checkInApi,
  downloadVoteTicketApi,
  exportLoginAccountApi,
  getQrCodeApi,
  getSearchApi,
  infoShareholderApi,
  setQrCode,
} from '@duck/pages/checkin/checkInHandReducer/actions';
import { useDispatch, useSelector } from 'react-redux';
import { PAGE_SIZE } from '@state-common/constants';

const ConfirmModal = ({ isOpen, toggleModal, item }) => {
  const { t } = useTranslation();
  const admin = JSON.parse(localStorage.getItem('adminId'));
  const processorId = admin && admin.adminId ? admin.adminId : '';
  const dispatch = useDispatch();
  const formMethods = useForm();
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
  } = formMethods;

  const qrCode = useSelector((state) => state.checkin.checkInHand.qrCode);
  const infoShareholder = useSelector(
    (state) =>
      state.checkin.checkInHand.infoShareholder &&
      state.checkin.checkInHand.infoShareholder.shareholder,
  );
  const { filter } = useSelector((state) => state.checkin.checkInHand);
  const info = useSelector((state) => state.admin.configSystem.info);
  useEffect(() => {
    if (isOpen) {
      dispatch(infoShareholderApi(item._id));
      dispatch(getQrCodeApi(item._id));
    }
  }, [isOpen, dispatch]);

  useEffect(() => {
    if (infoShareholder) {
      setValue('name', infoShareholder.name);
      setValue('identityNumber', infoShareholder.identityNumber);
      setValue('phone', infoShareholder.phone);
      setValue('identityDate', infoShareholder.identityDate);
      setValue('email', infoShareholder.email);
      setValue('identityAddress', infoShareholder.identityAddress);
      setValue('sharesNumber', infoShareholder.sharesNumber);
      setValue(
        'totalShareholder',
        infoShareholder.sharesJoinNumber +
          infoShareholder.sharesAuthorizedNumber,
      );
    }
  }, [infoShareholder]);

  const submit = async (value) => {
    await dispatch(
      checkInApi(infoShareholder._id, {
        processorId,
      }),
    );
    await dispatch(getQrCodeApi(item._id));
    await dispatch(infoShareholderApi(item._id));
    await dispatch(
      getSearchApi({
        identityNumber: filter.idCard,
        joinType: 'all',
        status: filter.status,
        pageSize: PAGE_SIZE,
        pageNumber: filter.pageNumber,
      }),
    );
  };

  return (
    <Modal
      title={t('Xác nhận tư cách tham gia')}
      {...{ isOpen, toggleModal }}
      overwriteModalStyle={{
        maxWidth: '716px',
      }}
      callbackWhenUnmounted={() => {
        dispatch(setQrCode(''));
      }}
    >
      <SModalContent>
        <form className="form">
          {Children.toArray(
            inputs.map((i) => {
              return (
                <div className="form-item">
                  {i.type === 'date' ? (
                    <Input.Date
                      name={i.name}
                      {...{ control, setValue, watch }}
                      wrapperProps={{
                        label: t(i.label),
                      }}
                      rules={{
                        required: t(i.require),
                        ...i.rules,
                      }}
                    />
                  ) : ['tel', 'text', 'number'].includes(i.type) ? (
                    <Input.Text
                      wrapperProps={{
                        label: t(i.label),
                        icon: i.icon,
                      }}
                      inputProps={{
                        type: i.type,
                      }}
                      register={register(i.name, {
                        required: i.require,
                        ...i.rules,
                      })}
                    />
                  ) : i.type === 'time' ? (
                    <Input.Time
                      {...{ control, setValue, watch }}
                      wrapperProps={{
                        label: t(i.label),
                      }}
                      rules={{
                        required: i.require,
                        ...i.rules,
                      }}
                    />
                  ) : null}
                  <ErrorMessage errors={errors} field={i.name} />
                </div>
              );
            }),
          )}
        </form>
        {info.qrCode && qrCode && (
          <div style={{ textAlign: 'center', marginTop: '32px' }}>
            <img src={qrCode} />
          </div>
        )}

        {infoShareholder && !infoShareholder.isCheckedIn ? (
          <div className={`group-button`}>
            <Button
              style={{ color: '#718098', backgroundColor: '#DFE9F5' }}
              onClick={() => {
                toggleModal(false);
                dispatch(setQrCode(''));
              }}
            >
              {t('Hủy bỏ')}
            </Button>
            <Button onClick={handleSubmit(submit)}>{t('Xác nhận')}</Button>
          </div>
        ) : (
          <div className={`group-button`}>
            <Button
              style={{
                color: '#718098',
                backgroundColor: '#FFF',
                border: '1px solid #00CB82',
              }}
              onClick={() => {
                dispatch(
                  downloadVoteTicketApi({
                    shareholderId: infoShareholder._id,
                  }),
                );
              }}
            >
              {t('In phiếu biểu quyết')}
            </Button>
            <Button
              onClick={() => {
                dispatch(exportLoginAccountApi(infoShareholder._id));
              }}
            >
              {t('In tài khoản đăng nhập')}
            </Button>
          </div>
        )}
      </SModalContent>
    </Modal>
  );
};

export default ConfirmModal;

const SModalContent = styled.div`
  padding: 0 32px;
  background-color: ${(props) => props.backGroundColor || 'white'};

  margin-top: 44px;

  @media (max-width: 1199px) {
    padding: 0 40px;
  }
  @media (max-width: 991px) {
    padding: 0 20px;
    margin-top: 20px;
  }
  @media (max-width: 767px) {
    padding: 0 15px;
  }

  .group-button {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;
    width: 100%;

    & > ${Button} {
      margin-left: 16px;
      margin-bottom: 8px;
      width: fit-content;
    }

    @media (max-width: 991px) {
      margin-top: 20px;
    }
  }
  .form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    .form-item {
      flex: calc(50% - 20px);
    }
  }
`;
