import React, { useState, useEffect } from 'react';
import { Styles } from 'styles/styled/admin/authorizationListPage/authorizationInformation';
import { useTranslation } from 'react-i18next';
import TabAccountSettings from './TabAccountSettings';
import TabContentSettings from './TabContentContent';

const AuthorizationInformation = () => {
  const { t } = useTranslation();
  const [tab, setTab] = useState('account');
  return (
    <>
      <Styles>
        <div className="item-tab">
          <li
            className={`tab-li ${tab === 'account' ? 'active' : ''} `}
            onClick={() => setTab('account')}
          >
            {t('Thiết lập tài khoản nhận ủy quyền')}
          </li>
          <li
            className={`tab-li ${tab === 'content' ? 'active' : ''} `}
            onClick={() => setTab('content')}
          >
            {t('Thiết lập nội dung ủy quyền')}
          </li>
        </div>
      </Styles>
      {tab === 'account' && <TabAccountSettings />}
      {tab === 'content' && <TabContentSettings />}
    </>
  );
};
export default AuthorizationInformation;
