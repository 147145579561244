import { createGlobalStyle } from 'styled-components';
import RobotoBold800 from './fonts/Roboto-Black.ttf';
import {
  default as RobotoBold700,
  default as RobotoSemiBold600,
} from './fonts/Roboto-Bold.ttf';
import RobotoLight300 from './fonts/Roboto-Light.ttf';
import RobotoMedium500 from './fonts/Roboto-Medium.ttf';
import RobotoRegular400 from './fonts/Roboto-Regular.ttf';

export const GlobalStyle = createGlobalStyle`

@font-face {
  font-family: Roboto;
  src: url(${RobotoLight300});
  font-weight: 300;
}

@font-face {
  font-family: Roboto;
  src: url(${RobotoRegular400});
  font-weight: 400;
}

@font-face {
  font-family: Roboto;
  src: url(${RobotoMedium500});
  font-weight: 500;
}

@font-face {
  font-family: Roboto;
  src: url(${RobotoSemiBold600});
  font-weight: 600;
}

@font-face {
  font-family: Roboto;
  src: url(${RobotoBold700});
  font-weight: 700;
}

@font-face {
  font-family: Roboto;
  src: url(${RobotoBold800});
  font-weight: 800;
}

*{
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
}

ul{
  list-style: none;
}

a{
  text-decoration: none;
  color: unset;
  :hover{
    color: #00cb82;
  }
}

html{
  display:block;
  height: 100%;
  margin:0;
  padding: 0;
  overflow-x: hidden;
}

body{
  /* background-color: #fafafa; */
  height: 100%;
  min-height: 100vh;
  margin: 0;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  color: #333B6A;
  overflow-x: hidden;
}

#root{
  height: 100%;
}

::-webkit-scrollbar-track{
	background-color: #fff;
}

::-webkit-scrollbar{
	width: 4px;
	background-color: #e5e5e5;
  height: 4px;
}

::-webkit-scrollbar-thumb{
	border-radius: 10px;
	background-color: #e5e5e5;
  width: 10px;
  height: 4px;
}

body::-webkit-scrollbar{
  width: 10px;
}

.ant-switch-checked{
  background-color: #00CB82;
}
.react-datepicker-popper{
  z-index: 99;
}



@media only screen and (max-width: 767px){
  body::-webkit-scrollbar{
    width: 0;
  }
}
`;

export const theme = {
  primary: '#00CB82',
  dark_primary: '#00796B',
  light_primary: '#B2DFDB',
  inverted_text: '#FFFFFF',
  accent: '#2F80ED',
  pink: '#E91E63',
  orange: '#FF9800',
  amber: '#ffc107',
  light_purple: '#ba68c8',
  primary_text: '#1D2E4B',
  secondary_text: '#718098',
  divider: '#BDBDBD',
  yellow: '#ffeb3b',
  teal: '#009688',
  purple: '#7E57C2',
  red: '#F44336',
  card: '#FFF',
  grey: '#e0e0e0',
  light_grey: '#eeeeee',
  light_red: '#EF9A9A',
  shadow_level_1: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
  shadow_level_2: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
  shadow_level_3: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
  shadow_level_4: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
  shadow_level_5: '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)',
  blue: '#2F6BFF',
  green: '#00BC3C',
};
