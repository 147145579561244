import PNotify from 'pnotify/dist/es/PNotify';

export const handleError = (err) => {
  // if (err) {
  //   if (err.response && err.response.data && err.response.data.message) {
  //     PNotify.error({
  //       title: 'Lỗi',
  //       text: err.response.data.message,
  //       destroy: true,
  //       delay: 2000,
  //     });
  //   } else if (err.response) {
  //     PNotify.error({
  //       title: 'Lỗi',
  //       text: err.response.statusText,
  //       destroy: true,
  //       delay: 2000,
  //     });
  //   }
  // }
};
