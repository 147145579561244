import { ReactComponent as DownArrow } from 'assets/images/down-arrow.svg';
import imgEN from 'assets/images/language-en.png';
import imgVI from 'assets/images/language-vi.png';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const Language = () => {
  const { i18n } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false);
  const isMultiLanguage = useSelector(
    (state) => state?.admin?.configSystem?.info?.adminMultiLanguage,
  );
  useEffect(() => {
    if (isMultiLanguage === false && i18n.language != 'vi') {
      i18n.changeLanguage('vi');
      localStorage.setItem('language', 'vi');
      window.location.reload();
    }
  }, [isMultiLanguage]);

  useEffect(() => {
    window.addEventListener('click', function (e) {
      if (
        document.getElementById('language') &&
        !document.getElementById('language').contains(e.target)
      ) {
        setShowDropdown(false);
      }
      window.addEventListener('scroll', function () {
        setShowDropdown(false);
      });
    });
  }, []);
  if (isMultiLanguage === false) return null;
  return (
    <Styles className="language" id="language">
      <div className="head" onClick={() => setShowDropdown(!showDropdown)}>
        <p className="desc">
          <img src={i18n.language == 'vi' ? imgVI : imgEN} />
          <span className="lang-label">
            {i18n.language == 'vi' ? 'VIE' : 'ENG'}
          </span>
          <DownArrow />
        </p>
      </div>
      <div className={`content ${showDropdown ? 'active' : ''}`}>
        <ul className="language-list">
          <li
            className="language-list__item"
            onClick={() => {
              i18n.changeLanguage('vi');
              localStorage.setItem('language', 'vi');
              window.location.reload();
              setShowDropdown(false);
            }}
          >
            <img src={imgVI} />
            <span className="lang-label">VIE</span>
          </li>
          <li
            className="language-list__item"
            onClick={() => {
              i18n.changeLanguage('en');
              localStorage.setItem('language', 'en');
              window.location.reload();
              setShowDropdown(false);
            }}
          >
            <img src={imgEN} />
            <span className="lang-label">ENG</span>
          </li>
        </ul>
      </div>
    </Styles>
  );
};

export default Language;

const Styles = styled.div`
  position: relative;
  margin-left: 20px;
  font-size: 12px;
  font-weight: 500;
  .head {
    cursor: pointer;
    padding: 4px 6px;
    background: white;
    border: 1px solid #dfe9f5;
    border-radius: 6px;

    .desc {
      display: flex;
      align-items: center;
      gap: 4px;
      margin: 0;
    }
  }

  .content {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    width: 100%;
    padding-top: 2px;
    transform: scale(0);
    transform-origin: top center;
    transition: all 0.3s;

    &.active {
      transform: scale(1);
    }

    .language-list {
      background: #fff;
      box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
      padding: 0 6px;
      border-radius: 6px;
      border: 1px solid #dfe9f5;
      .language-list__item {
        cursor: pointer;
        display: flex;
        gap: 6px;
        align-items: center;
        padding: 8px 0;
        border-bottom: 1px solid #dfe9f5;
        &:last-child {
          border-bottom: unset;
        }
        :hover {
          color: #00cb82;
        }
      }
    }
  }

  @media only screen and (max-width: 480px) {
    .language-list__item {
      justify-content: center;
    }
    .lang-label {
      display: none;
      color: red !important;
    }
  }
`;
