import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCreateApi,
  downloadFileAPI,
  setSending,
} from '../../../../states/duck/pages/admin/shareholderListReducer/actions';
import Loading from '../../../bases/shared/loading';
import { SSectionCustom } from '@styled/admin/authorizationListPage';
import Button from '@components/button';
import inputIcons from '@components/icons/input';
import PNotify from 'pnotify/dist/es/PNotify';
import { SForm } from '@styled/admin/shareholderListPage';
import TableSearch from '@pages/admin/shareholderListPage/tableSearch';
import { useTranslation } from 'react-i18next';

var _validFileExtensions = ['.xlsx', '.xls'];
function Validate(e) {
  var sFileName = e.target.value;
  if (sFileName.length > 0) {
    var blnValid = false;
    for (var j = 0; j < _validFileExtensions.length; j++) {
      var sCurExtension = _validFileExtensions[j];
      if (
        sFileName
          .substr(sFileName.length - sCurExtension.length, sCurExtension.length)
          .toLowerCase() === sCurExtension.toLowerCase()
      ) {
        blnValid = true;
        break;
      }
    }
    return blnValid;
  }
}

const Form = ({ isInvitationDone }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [listFile1, setListFile1] = useState('');
  const filter = useSelector((state) => state.admin.shareholderList.filter);
  const sending = useSelector((state) => state.admin.shareholderList.sending);
  const [success, setSuccess] = useState(false);
  const isFile = (event, handleFuncion) => {
    if (Validate(event)) {
      var files = event.target.files[0];
      handleFuncion(files);
      PNotify.success({
        title: t('Thành công'),
        text: t('Upload file thành công'),
        destroy: true,
        delay: 2000,
      });
    } else {
      PNotify.error({
        title: t('Lỗi'),
        text: t('Phải là file excel (xlsx, xls)'),
        destroy: true,
        delay: 2000,
      });
    }
  };
  useEffect(() => {
    if (success && !sending) {
      setListFile1('');
      setSuccess(false);
    }
  }, [success, dispatch, sending]);

  const onSubmit = () => {
    setSuccess(true);
    if (listFile1) {
      if (listFile1) {
        let formData1 = new FormData();
        formData1.append('file', listFile1);
        formData1.append('shareholderType', 'personal');
        dispatch(getCreateApi(formData1, filter));
      }
      dispatch(setSending(true));
    } else {
      PNotify.error({
        title: t('Lỗi'),
        text: t('Bạn phải upload danh sách Cổ đông'),
        destroy: true,
        delay: 2000,
      });
    }
  };
  const downloadFile = () => {
    dispatch(downloadFileAPI());
  };

  return (
    <SForm>
      {sending ? <Loading title={t('Đang tạo danh sách. Vui lòng đợi')} /> : ''}
      <SSectionCustom>
        <div className="module-header">
          <h3 className="title">{t('Danh sách Cổ đông')}</h3>
          {!isInvitationDone && (
            <div className="file-handle">
              <Button className="white-button" onClick={downloadFile}>
                {inputIcons.download}
                {t('Tải về file mẫu')}
              </Button>
              <Button className="white-button upload-btn">
                {inputIcons.upload}
                {t('Tải lên danh sách Cổ đông')}
                {!listFile1 && (
                  <input
                    type="file"
                    onChange={(e) => isFile(e, setListFile1)}
                  />
                )}
                {listFile1 && (
                  <div className="file">
                    {listFile1 ? listFile1.name : ''}
                    <svg
                      onClick={() => setListFile1('')}
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="12" cy="12" r="12" fill="#FF6C6C" />
                      <rect
                        x="16.0942"
                        y="6.66211"
                        width="2.66667"
                        height="13.3333"
                        transform="rotate(45 16.0942 6.66211)"
                        fill="white"
                      />
                      <rect
                        x="6.76172"
                        y="8.54883"
                        width="2.66667"
                        height="13.3333"
                        transform="rotate(-45 6.76172 8.54883)"
                        fill="white"
                      />
                    </svg>
                  </div>
                )}
              </Button>

              {sending ? (
                <Button
                  className="white-button"
                  style={{ pointerEvents: 'none' }}
                >
                  {inputIcons.plus}
                  {t('Tạo danh sách')}
                </Button>
              ) : (
                <Button className="white-button" onClick={onSubmit}>
                  {inputIcons.plus}
                  {t('Tạo danh sách')}
                </Button>
              )}
            </div>
          )}
        </div>
        <TableSearch />
      </SSectionCustom>
    </SForm>
  );
};
export default Form;
