const findIndex = (item, arr) => {
    let index = -1;
    for (let i = 0; i < arr.length; i++){
        if(item._id === arr[i]._id){
            index = i
        }
    }
    return index;
}
const convertArr = (arr) => {
    let newArr = [];
    for (let i = 0; i < arr.length; i++){
        newArr.push(arr[i]._id);
    }
    return newArr;
}

export {
    findIndex,
    convertArr
};