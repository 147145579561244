import React, {useEffect} from 'react';
import Section from '../../../../components/bases/shared/section';
import {
  statusOpinionOptions,
  statusOpinionOptionsEn,
  tabTypeOptions,
  tabTypeOptionsEn,
  blockStatusOption,
  blockStatusOptionEn,
} from './data';
import Input from '../../../../components/bases/shared/input';
import Button from '../../../../components/bases/shared/button';
import inputIcons from '../../../bases/shared/icons/input';
import {useForm} from 'react-hook-form';
import {SShareHolderOpinion} from '../../../../styles/styled/admin/shareholderOpinionPage';
import {useDispatch, useSelector} from 'react-redux';
import {TableList} from './table';
import {setFilter} from '@duck/pages/admin/shareholderOpinionReducer/actions';
import {useTranslation} from 'react-i18next';

const ShareholderOpinionPage = () => {
  const {t, i18n} = useTranslation();
  const {filter} = useSelector((state) => state.admin.shareholderOpinion);
  const dispatch = useDispatch();
  const {handleSubmit, register, control, getValues, setValue, watch} =
    useForm();
  useEffect(() => {
    setValue('status', {
      label: t('Tất cả'),
      value: 'all',
    });

    setValue('type', {
      label: t('Đã gửi ý kiến'),
      value: 'opinion',
    });

    setValue('blockStatus', {
      label: t('Tất cả'),
      value: 'all',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues, setValue]);
  const onSubmit = (data) => {
    console.log(data);
    const {identityNumber, status, type, blockStatus} = data;
    if (filter.typeList === type.value) {
      dispatch(
        setFilter({
          ...filter,
          identityNumber,
          blockStatus: blockStatus ? blockStatus.value : 'all',
          pageNumber: 1,
          status: status ? status.value : 'all', //status: all, boss, notBoss, public, notPublic
          // typeList: type ? type.value : 'all', //all - opinion
        }),
      );
    } else
      dispatch(
        setFilter({
          ...filter,
          identityNumber,
          blockStatus: blockStatus ? blockStatus.value : 'all',
          pageNumber: 1,
          status: status ? status.value : 'all', //status: all, boss, notBoss, public, notPublic
          typeList: type ? type.value : 'all', //all - opinion
        }),
      );
  };
  if (watch('type') && watch('type').value === 'all') {
    setValue('status', {
      label: t('Tất cả'),
      value: 'all',
    });
  }
  return (
    <SShareHolderOpinion>
      <Section header={t('Ý kiến Cổ đông')}>
        <div className="search-wrapper">
          <div className="search-item">
            <Input.Text
              register={register('identityNumber')}
              wrapperProps={{
                label: t('CMND/CCCD'),
                icon: inputIcons.account,
              }}
            />
          </div>
          {!(watch('type') && watch('type').value === 'all') && (
            <div className="search-item">
              <Input.Select
                name="blockStatus"
                control={control}
                selectProps={{
                  options:
                    i18n.language === 'vi'
                      ? blockStatusOption
                      : blockStatusOptionEn,
                }}
              />
            </div>
          )}
          <div className="search-item">
            <Input.Select
              name="type"
              control={control}
              selectProps={{
                options:
                  i18n.language === 'vi' ? tabTypeOptions : tabTypeOptionsEn,
              }}
            />
          </div>

          {!(watch('type') && watch('type').value === 'all') && (
            <div
              className="search-item"
              style={{
                pointerEvents:
                  watch('type') && watch('type').value === 'all'
                    ? 'none'
                    : 'unset',
              }}
            >
              <Input.Select
                name="status"
                control={control}
                selectProps={{
                  options:
                    i18n.language === 'vi'
                      ? statusOpinionOptions
                      : statusOpinionOptionsEn,
                }}
              />
            </div>
          )}

          <div className="search-button search-item">
            <Button onClick={handleSubmit(onSubmit)}> {t('Tìm kiếm')} </Button>
          </div>
        </div>
      </Section>
      <TableList />
    </SShareHolderOpinion>
  );
};
export default ShareholderOpinionPage;
