import styled from 'styled-components';

export const SHotline = styled.div `
  .search-wrapper {
    margin: 0 -12px;
    margin-bottom: -10px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap; 
    .search-item {
      padding: 0 12px;
      min-width: 200px;
      width: 33.33%;
      margin-bottom: 10px;
      button {
        width: 100%;
        border-radius: 0;
      }
    }
  }
  .table-container {
    .list-handle {
      display: flex;
      margin: 20px 0 0px;
    }
  }
  @media only screen and (max-width: 1199px) {
    .module-content {
      margin: 0 auto;
    }
  }
  @media only screen and (max-width: 780px) { 
    .search-wrapper {
      .search-item{
        width:50%;
      }
    }
  }
  @media only screen and (max-width: 500px) { 
    .search-wrapper {
      .search-item{
         width:100%;
      }     
    }
  }
`;

export const SModalEditHotline = styled.div `
  margin: 0 auto;
  width: 320px;
  max-width:100%;
  /* padding: 0 15px; */
  box-sizing:border-box;
  margin-top: 32px;
  .handle-wrapper {
    .handle-item {
      margin-bottom: 20px;
    }
  }
  .button-group {
    padding-top: 24px;
    display: flex;
    margin: 0 -8px;
    justify-content: space-between;
    button {
      max-width: 50%;
      width: 152px;
      margin: 0 8px;
      &.cancel-button {
        background: #dfe9f5;
        color: #718098;
      }
    }
  }
  @media only screen and (max-width: 500px){
    padding: 0 15px;
  }
`;