export const URL = 'info/shareholder';
export const URL_TOPIC = 'topic/kind';
export const URL_CREATE_VOTE = 'vote/create';
export const URL_CREATE_SELECT_VOTE = 'vote/select/create';
export const URL_VOTE_INFO = 'shareholder';
export const URL_SET_INVALID = 'vote/invalidVote';
export const URL_GET_LIST_AUTHORITY = 'shareholder/authority-for';

export const GET_LIST = 'GET_CHECK_COUPON_ARTISAN_LIST';
export const GET_TOPIC1 = 'GET_CHECK_COUPON_ARTISAN_TOPIC1';
export const GET_TOPIC2 = 'GET_CHECK_COUPON_ARTISAN_TOPIC2';
export const GET_TOPIC3 = 'GET_CHECK_COUPON_ARTISAN_TOPIC3';
export const GET_TOPIC4 = 'GET_CHECK_COUPON_ARTISAN_TOPIC4';
export const GET_TOPIC6 = 'GET_CHECK_COUPON_ARTISAN_TOPIC6';

export const TOGGLE_MODAL = 'TOGGLE_CHECK_COUPON_ARTISAN_MODAL';
export const GET_RESULT = 'GET_CHECK_COUPON_ARTISAN_RESULT';
export const GET_FILTER = 'GET_CHECK_COUPON_ARTISAN_FILTER';
export const SET_VOTED_RESULT = 'SET_VOTED_RESULT';
export const GET_TOTAL = 'GET_CHECK_COUPON_ARTISAN_TOTAL';
export const SET_CREATING_VOTE = 'SET_CHECK_COUPON_ARTISAN_CREATING_VOTE';
export const SET_CHOOSE_LIST = 'SET_CHOOSE_LIST';
export const SET_LIST_AUTHORITY = 'SET_LIST_AUTHORITY';
export const SET_VOTER = 'SET_VOTER';
export const SET_RESULT_KIND1 = 'SET_RESULT_KIND1';
export const SET_RESULT_KIND2 = 'SET_RESULT_KIND2';
export const SET_RESULT_KIND3 = 'SET_RESULT_KIND3';
export const SET_RESULT_KIND4 = 'SET_RESULT_KIND4';
export const SET_RESULT_KIND6 = 'SET_RESULT_KIND6';

export const SET_ALL_NOT_VOTE = 'SET_CHECK_COUPON_ALL_NOT_VOTE';
export const TOGGLE_CONFIRM_MODAL = 'TOGGLE_CONFIRM_MODAL';
export const SET_MARK_CONFIRM_MODAL = 'SET_MARK_CONFIRM_MODAL';
