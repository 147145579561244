import Button from '@components/button';
import inputIcons from '@components/icons/input';
import Input from '@components/input';
import { VALIDATE_REQUIRE } from '@constants/validate';
import {
  SSelectionItem,
  SSelections,
} from '@styled/admin/initializeReportPage';
import React, { Children, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
export default function Selections({ initSelections, candidate }) {
  const {
    setValue,
    register,
    watch,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();

  const [selections, setSelections] = useState([]);

  const watchName = watch('selectionForm.name');
  const watchName2 = watch('selectionForm.name_2');

  const nameRequired = watchName2 !== '' && watchName !== '';
  useEffect(() => {
    register('selections', { value: initSelections, shouldUnregister: true });
  }, [register, initSelections]);

  // init selections
  useEffect(() => {
    if (initSelections) {
      const mappedSelections =
        initSelections.length > 0
          ? initSelections.map((s) => s.selectName)
          : [];
      setSelections(mappedSelections);
    } else {
      setSelections([]);
      setValue('selections', []);
    }
  }, [initSelections, setValue, candidate]);
  // setValue for "selections" key
  useEffect(() => {
    setValue('selections', selections);
  }, [selections, setValue]);

  const onClick = () => {
    if (nameRequired) {
      console.log('nameRequired', nameRequired);
      const tmpSelections = [...selections, watchName + ' - ' + watchName2];
      setSelections(tmpSelections);
      setValue('selectionForm.name', '');
      setValue('selectionForm.name_2', '');
    }
  };

  const deleteSelected = (i) => {
    const tmpSelections = [...selections];
    tmpSelections.splice(i, 1);
    setSelections([...tmpSelections]);
  };

  return (
    <SSelections>
      <div className="selection__title">{t('Danh sách ứng viên')}:</div>
      <div className="selection__form">
        {/* name */}
        <div className="form__item">
          <Input.Text
            wrapperProps={{
              label: t('Họ tên'),
              icon: inputIcons.account,
            }}
            register={register('selectionForm.name', {
              required: nameRequired ? t(VALIDATE_REQUIRE) : false,
            })}
          />
          <Input.ErrorMessage errors={errors} field="selectionForm.name" />
        </div>

        {/* name 2 */}
        <div className="form__item">
          <Input.Text
            wrapperProps={{
              label: t('Họ tên (Tiếng Anh)'),
              icon: inputIcons.account,
            }}
            register={register('selectionForm.name_2', {
              required: nameRequired ? t(VALIDATE_REQUIRE) : false,
            })}
          />
          <Input.ErrorMessage errors={errors} field="selectionForm.name" />
        </div>

        {/* button */}
        <div className="form__item">
          <Button
            onClick={onClick}
            disabled={!nameRequired}
            style={{ width: '100%', height: '40px', borderRadius: 2 }}
          >
            {t('Tạo mới')}
          </Button>
        </div>

        {Children.toArray(
          selections.map((s, i) => (
            <SSelectionItem onClick={() => deleteSelected(i)}>
              <span className="label">{s}</span>
              <DeleteIcon />
            </SSelectionItem>
          )),
        )}
      </div>
    </SSelections>
  );
}

const DeleteIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="8" fill="#FF6C6C" />
      <rect
        x="10.7299"
        y="4.44141"
        width="1.77778"
        height="8.88889"
        transform="rotate(45 10.7299 4.44141)"
        fill="white"
      />
      <rect
        x="4.50781"
        y="5.69922"
        width="1.77778"
        height="8.88889"
        transform="rotate(-45 4.50781 5.69922)"
        fill="white"
      />
    </svg>
  );
};
