import React from 'react';
import styled from 'styled-components';

const LayoutContent = ({title, children}) => {
  return (
    <SLayoutContent>
      <h1 className="title">{title}</h1>
      {children}
    </SLayoutContent>
  );
};

export default LayoutContent;

const SLayoutContent = styled.div`
  .title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: #333b6a;
    margin: 0px 0px 32px 0;
  }
`;
