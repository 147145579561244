import * as constants from './constants';
import {callDownloadApi} from '../../../../common';
import PNotify from 'pnotify/dist/es/PNotify';
import {handleError} from '@configs/handleError';
var FileSaver = require('file-saver');

export const exportReportApi = () => {
  return (dispatch) =>
    callDownloadApi(`${constants.URL_EXPORT_REPORT}`, 'GET', null)
      .then(function (res) {
      console.log(res)
        var file = new Blob([res.data], {
          type:
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        });
        FileSaver.saveAs(file, 'BienBanDaiHoi');
        PNotify.success({
          title: 'Thành công',
          text: 'Xuất Biên bản Đại hội thành công!',
          destroy: true,
          delay: 2000,
        }); 
      })
      .catch(handleError);
};
export const exportResolutionApi = () => {
  return (dispatch) =>
    callDownloadApi(`${constants.URL_EXPORT_RESOLUTION}`, 'GET', null)
      .then(function (res) {
        var file = new Blob([res.data], {
          type:
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        });
        FileSaver.saveAs(file, 'NghiquyetDaihoi');

        PNotify.success({
          title: 'Thành công',
          text: 'Xuất Nghị quyết Đại hội thành công!',
          destroy: true,
          delay: 2000,
        });
      })
      .catch(handleError);
};
