import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SystemItem from '../systemItem';

const defaultValue = [
  {
    label: 'Nhập phiếu bầu',
    key: 'amountInput',
    value: false,
  },
  {
    label: 'Nhập tỷ lệ bầu cử',
    key: 'rateInput',
    value: false,
  },
];

export default function Voting() {
  const [list, setList] = useState(defaultValue);
  const info = useSelector((state) => state.admin.configSystem.info);
  const { t } = useTranslation();
  useEffect(() => {
    if (info) {
      let newList = [...list];
      let keys = Object.keys(info);
      for (let i = 0; i < newList.length; i++) {
        if (keys.includes(newList[i].key)) {
          newList[i].value = info[newList[i].key];
        }
      }
      setList(newList);
    }
  }, [info]);
  return (
    <>
      <h4 className="category">{t('Bầu cử')}</h4>
      <div className="system-list">
        {list.map((item, index) => {
          return (
            <SystemItem
              key={index}
              name={item.label}
              keyName={item.key}
              status={item.value}
            />
          );
        })}
      </div>
    </>
  );
}
