import React, { useEffect, useState } from 'react';
import { SwitchContent } from '@styled/admin/timeVotePage';
import { SItem } from '@styled/admin/configSystem';
import { setConfigSystemApi } from '@duck/pages/admin/configSystemReducer/actions';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PNotify from 'pnotify/dist/es/PNotify';

const SystemItem = ({ name, status, keyName, setting }) => {
  const { t } = useTranslation();
  return (
    <SItem style={setting ? { width: "100%" } : {}}>
      <div className="name">{t(name)}</div>
      <SwitchItem status={status} keyName={keyName} />
    </SItem>
  );
};

export default SystemItem;

const SwitchItem = ({ status, keyName }) => {
  console.log('status', status);
  const [switchValue, setSwitchValue] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    setSwitchValue(status);
  }, [status]);

  return (
    <SwitchContent
      onClick={async () => {
        setSwitchValue(!switchValue);
        await dispatch(
          setConfigSystemApi({
            settingKey: keyName,
            isOpen: !switchValue,
          }),
        );
        PNotify.success({
          title: t('Thành công'),
          text: t('Cập nhật thành công'),
          destroy: true,
          delay: 2000,
        });
      }}
    >
      <span className={`switch ${switchValue ? 'on' : ''}`}></span>
    </SwitchContent>
  );
};
