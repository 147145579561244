import * as types from './constants';
import * as common from '../../../../common/constants';

var initialState = {
  tableList: [],
  toggleModal: false,
  result: {},
  topic1: [],
  topic2: [],
  topic3: [],
  topic4: [],
  topic6: [],
  filter: {
    identityNumber: '',
    joinType: 'all',
    isManual: 'all',
    status: 'all',
    pageSize: common.PAGE_SIZE,
    pageNumber: 1,
  },
  listResultVote: [], //list vote for all [{shareholder, votes}]
  resultVote1: [],
  resultVote2: [],
  resultVote3: [],
  resultVote4: [],
  resultVote6: [],

  total: 0,
  creatingVote: false,
  listChose: [],
  listAuthority: [],
  voter: {
    voterId: '',
    voterShares: 0,
  },
  allNotVote: {
    kind1: [],
    kind2: [],
    kind3: [],
    kind4: {},
    kind6: [],
  },
  toggleConfirmModal: false,
  markModalConfirm: '', //1,2,3, id of cumulative vote topic
};
var reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_TOTAL:
      return { ...state, total: action.value };
    case types.SET_CREATING_VOTE:
      return { ...state, creatingVote: action.value };
    case types.GET_LIST:
      return { ...state, tableList: action.value };
    case types.SET_VOTED_RESULT:
      return { ...state, listResultVote: action.value };
    case types.GET_TOPIC1:
      return { ...state, topic1: action.value };
    case types.GET_TOPIC2:
      return { ...state, topic2: action.value };
    case types.GET_TOPIC3:
      return { ...state, topic3: action.value };
    case types.GET_TOPIC4:
      return { ...state, topic4: action.value };
    case types.GET_TOPIC6:
      return { ...state, topic6: action.value };
    case types.TOGGLE_CONFIRM_MODAL:
      return { ...state, toggleConfirmModal: action.value };
    case types.TOGGLE_MODAL: {
      if (action.value === true) return { ...state, toggleModal: action.value };
      else {
        return {
          ...state,
          toggleModal: action.value,
          listChose: [],
          allNotVote: {
            kind1: [],
            kind2: [],
            kind3: [],
            kind4: {},
            kind6: [],
          },
          listResultVote: [], //list vote for all [{shareholder, votes}]
          resultVote1: [],
          resultVote2: [],
          resultVote3: [],
          resultVote4: [],
          resultVote6: [],
          listAuthority: [],
          voter: {
            voterId: '',
            voterShares: 0,
          },
        };
      }
    }
    case types.GET_RESULT:
      return { ...state, result: action.value };
    case types.SET_MARK_CONFIRM_MODAL:
      return { ...state, markModalConfirm: action.value };
    case types.GET_FILTER:
      return { ...state, filter: action.value };
    case types.SET_CHOOSE_LIST:
      return { ...state, listChose: action.value };
    case types.SET_LIST_AUTHORITY:
      return { ...state, listAuthority: action.value };
    case types.SET_VOTER:
      return { ...state, voter: action.value };
    case types.SET_RESULT_KIND1:
      return { ...state, resultVote1: action.value };
    case types.SET_RESULT_KIND2:
      return { ...state, resultVote2: action.value };
    case types.SET_RESULT_KIND3:
      return { ...state, resultVote3: action.value };
    case types.SET_RESULT_KIND4:
      return { ...state, resultVote4: action.value };
    case types.SET_RESULT_KIND6:
      return { ...state, resultVote6: action.value };
    case types.SET_ALL_NOT_VOTE:
      return { ...state, allNotVote: action.value };
    default:
      return state;
  }
};
export default reducer;
