import React, { useState, useEffect, Fragment } from 'react';
import {
  getSearchApi,
  getListAccount,
  setFilter,
} from 'states/duck/pages/checkin/checkInHandReducer/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PAGE_SIZE } from 'states/common/constants';
// import PaginationTable from '../../../bases/shared/paginationTable';
import Table from '@components/table';
// import {formatDate} from '@configs/formatDate';
import ButtonCustom from '@components/buttonCustom';
import { STable } from '@styled/checkIn/checkInHandPage';
import Pagination from '@components/pagination';
import { useTranslation } from 'react-i18next';
import { formatNumber } from 'states/libs';

const theads = [
  { name: 'Mã CĐ' },
  { name: 'Họ và tên' },
  { name: 'CMND/CCCD' },
  { name: 'Ngày cấp' },
  { name: 'Số điện thoại' },
  { name: 'Cổ phần sở hữu(1)' },
  { name: 'Cổ phần ủy quyền (2)' },
  { name: 'Cổ phần nhận ủy quyền (3)' },
  { name: 'Tổng (4) = (1) + (3) - (2)' },
  { name: 'Xử lý' },
];

const TableCheckInHand = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  // getListAuthorized
  const { t } = useTranslation();
  const [firstTime, setFirstTime] = useState(true);

  const { filter, total, tableList } = useSelector(
    (state) => state.checkin.checkInHand,
  );

  useEffect(() => {
    if (!firstTime) {
      dispatch(
        getSearchApi({
          identityNumber: filter.idCard,
          joinType: 'all',
          status: filter.status,
          pageSize: PAGE_SIZE,
          pageNumber: filter.pageNumber,
        }),
      );
    } else {
      dispatch(
        getSearchApi({
          identityNumber: filter.idCard,
          joinType: 'all',
          status: filter.status,
          pageSize: PAGE_SIZE,
          pageNumber: 1,
        }),
      );
      setFirstTime(false);
    }
  }, [dispatch]);

  const getPage = (value) => {
    dispatch(
      getSearchApi({
        ...filter,
        pageNumber: value,
      }),
    );
  };

  // const getPageSize = (pageSize) => {
  //   dispatch(
  //     setFilter({
  //       ...filter,
  //       pageSize,
  //       pageNumber: 1,
  //     }),
  //   );
  // };

  useEffect(() => {
    return () => {
      dispatch(getListAccount([]));
    };
  }, [dispatch]);

  // useEffect(() => {
  //   dispatch(getListAuthorized(filter));
  // }, [dispatch, filter]);

  const checkin = (value, type) => {
    history.push({
      pathname: `/xu-ly-uy-quyen/${value._id}`,
      state: {
        item: value,
        type,
      },
    });
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Fragment>
      <STable>
        <Table header={theads}>
          {tableList.length > 0 ? (
            tableList.map((item, index) => {
              let authorization = item.acceptAuthorities.reduce(
                (total, i) => total + Number(i.sharesNumber || 0),
                0,
              );
              let sum = formatNumber(
                item.sharesNumber + item.sharesAuthorizedNumber - authorization,
              );
              return (
                <tr key={index}>
                  <td>{item.code}</td>
                  <td>{item.name}</td>
                  <td>{item.identityNumber}</td>
                  <td>{item.identityDate}</td>
                  <td>{item.phone}</td>
                  <td>{formatNumber(item.sharesNumber)}</td>
                  <td>{formatNumber(authorization)}</td>
                  <td>{formatNumber(item.sharesAuthorizedNumber)}</td>
                  <td>{sum}</td>
                  <td className="last_td_check">
                    {item.isCheckedIn &&
                    item.joinType !== 'authority' &&
                    item.joinType !== 'none' ? (
                      <span className="no_check">
                        <img src="images/ttcb/icon_nocheck.svg" alt="" />
                      </span>
                    ) : item.sharesNumber === 0 || item.isInAuthorizedList ? (
                      <span className="no_check">
                        <img src="images/ttcb/icon_nocheck.svg" alt="" />
                      </span>
                    ) : (
                      <ButtonCustom
                        status="orange"
                        type="button"
                        className="btn_check"
                        onClick={() => checkin(item, 'authority')}
                      />
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="8" style={{ textAlign: 'center' }}>
                {t('Không có dữ liệu')}
              </td>
            </tr>
          )}
        </Table>

        <Pagination
          // handleChangePageSize={getPageSize}
          hideSelectSize={true}
          current={filter.pageNumber}
          total={total}
          pageSize={PAGE_SIZE}
          onChange={getPage}
        />
      </STable>
    </Fragment>
  );
};

export default TableCheckInHand;
