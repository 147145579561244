import React from 'react';

const upload = (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="#00CB82"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="22" cy="22" r="22" fill="#00CB82" />
    <g clipPath="url(#clip0_2:841)">
      <path
        d="M20.9078 17.5925L20.9342 25.3002H23.0319L23.0847 17.5848L25.8501 20.3502L27.5001 18.7002L22.0001 13.2002L16.5001 18.7002L18.1501 20.3502L20.9078 17.5925Z"
        fill="white"
      />
      <path
        d="M28.6001 24.2002V27.5002H15.4001V24.2002H13.2001V30.8002H30.8001V24.2002H28.6001Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2:841">
        <rect
          width="17.6"
          height="17.6"
          fill="white"
          transform="translate(13.2001 13.2002)"
        />
      </clipPath>
    </defs>
  </svg>
);

const deleteBtn = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="12" fill="#FF6C6C" />
    <rect
      x="16.0947"
      y="6.66699"
      width="2.66667"
      height="13.3333"
      transform="rotate(45 16.0947 6.66699)"
      fill="white"
    />
    <rect
      x="6.76173"
      y="8.55298"
      width="2.66667"
      height="13.3333"
      transform="rotate(-45 6.76173 8.55298)"
      fill="white"
    />
  </svg>
);

const icons = {
  upload,
  deleteBtn,
};

export default icons;
