import HandleSection from '@components/handle';
import HandleButton from '@components/input/HandleButton';
import DeleteModal from '@components/modal/ModalDelete';
import {
  deleteAccountApi,
  setChooseList,
} from '@duck/pages/admin/initializeReportReducer/actions';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EditModal from '../modal/EditModal';
import SortModal from '../modal/SortModal';
import { useTranslation } from 'react-i18next';
export default function Handle({ kind, tableType, kindToSend }) {
  const { listTopicChoose, tableList } = useSelector(
    (state) => state.admin.initializeReport,
  );
  const dispatch = useDispatch();

  const selected =
    tableList.length === listTopicChoose.length && tableList.length > 0;

  const handleSelectAll = () => {
    if (selected) {
      dispatch(setChooseList([]));
    } else {
      dispatch(setChooseList(tableList.map((t) => t._id)));
    }
  };

  return (
    <>
      <HandleSection>
        {tableList.length > 0 && (
          <>
            <HandleButton
              select={selected}
              type="select"
              action={handleSelectAll}
            />
            <SortBtn
              tableType={tableType}
              kind={kind}
              kindToSend={kindToSend}
            />
          </>
        )}
        {listTopicChoose.length === 1 && (
          <EditBtn
            {...{ tableType, kind }}
            id={listTopicChoose[0]}
            kindToSend={kindToSend}
          />
        )}
        {listTopicChoose.length > 0 && (
          <DeleteBtn
            {...{ listTopicChoose, tableType, kind }}
            kindToSend={kindToSend}
          />
        )}
      </HandleSection>
    </>
  );
}

const DeleteBtn = ({ listTopicChoose, tableType, kind, kindToSend }) => {
  const [deleteModal, toggleDeleteModal] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(
      deleteAccountApi(
        { topicIds: listTopicChoose },
        tableType,
        () => {
          toggleDeleteModal(false);
        },
        kind,
        kindToSend,
      ),
    );
  };

  return (
    <>
      <HandleButton type="delete" action={() => toggleDeleteModal(true)} />
      <DeleteModal
        title={t('Xóa các nội dung')}
        onSubmit={handleDelete}
        isOpen={deleteModal}
        toggleModal={toggleDeleteModal}
      />
    </>
  );
};

const EditBtn = ({ id, tableType, kind, kindToSend }) => {
  const [editModal, toggleEdit] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <HandleButton type="edit" action={() => toggleEdit(true)} />
      <EditModal
        isEdit={true}
        kindToSend={kindToSend}
        {...{ id, tableType, kind }}
        isOpen={editModal}
        toggleModal={toggleEdit}
        title={t('Sửa thông tin nội dung')}
      />
    </>
  );
};

const SortBtn = ({ tableType, kind, kindToSend }) => {
  const [isOpen, toggleModal] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <HandleButton
        type="sort"
        action={() => {
          toggleModal(true);
        }}
      />
      {isOpen && (
        <SortModal
          kindToSend={kindToSend}
          {...{ isOpen, toggleModal, tableType, kind }}
          title={t('sắp xếp thứ tự hiển thị')}
        />
      )}
    </>
  );
};
