import * as types from './constants';
var initialState = {
    info:{},
    toggleModal: false
};
var reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_INFO:
            return { ...state,  info: action.value}
        case types.SET_TOGGLE_MODAL:
            return { ...state,  toggleModal: action.value}
        default:
            return state;
    }
}
export default reducer;