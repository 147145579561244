import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  signIn,
  getOtpApi,
  // setTimeRestart,
} from '../../../states/duck/pages/loginPage/actions';
import * as constants from '../../bases/constants';
// import {TIME_OTP_DESTROY} from '../../../states/common/constants';
import Input from '../../bases/shared/input';
import inputIcons from '../../bases/shared/icons/input';
import Button from '../../bases/shared/button';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const filterGroup = (label) => {
  switch (label) {
    case 'chu-tich':
      return '1';
    case 'quan-he-co-dong':
      return '2';
    case 'kiem-tra-tu-cach':
      return '3';
    case 'kiem-phieu':
      return '4';
    case 'thu-ky':
      return '5';
    default:
      return null;
  }
};
const LoginPage = ({ typeLogin }) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  // const otpForm = useSelector((state) => state.login.otpForm);
  // const timeRestart = useSelector((state) => state.login.timeRestart);
  // const [otp, setOTP] = useState('');
  // const [intervalId, setIntervalId] = useState(null);
  // const [time, setTime] = useState(TIME_OTP_DESTROY);
  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  const history = useHistory();
  const { t } = useTranslation();
  // useEffect(() => {
  //   if (timeRestart) {
  //     dispatch(setTimeRestart(false));
  //     setTime(TIME_OTP_DESTROY);
  //     let timeInterval = TIME_OTP_DESTROY;
  //     setIntervalId(
  //       setInterval(() => {
  //         if (timeInterval > 0) {
  //           timeInterval = timeInterval - 1;
  //           setTime(timeInterval);
  //         } else {
  //           clearInterval(intervalId);
  //         }
  //       }, 1000),
  //     );
  //   }
  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, [dispatch, intervalId, timeRestart]);
  const onSubmit = (values) => {
    dispatch(
      signIn(
        {
          account: values.account ? values.account.toLowerCase() : '',
          password: values.password,
          // otp: '',
        },
        true,
        t,
      ),
    );
  };
  return (
    <div className="form-login">
      <div className="desc">{t('Dành cho tổ chức phát hành')}</div>
      <form className="content" onSubmit={handleSubmit(onSubmit)}>
        <div className="login-item">
          <Input.Text
            register={register('account', {
              required: t(constants.VALIDATE_REQUIRE),
            })}
            wrapperProps={{
              label: t('Tài khoản'),
              icon: inputIcons.account,
              labelLow: '19px',
              labelHigh: '10px',
              className: 'custom-input',
            }}
          />
          <span className="error">
            {errors.account && errors.account.message}
          </span>
        </div>
        <div className="login-item">
          <Input.Text
            inputProps={{
              type: 'password',
            }}
            wrapperProps={{
              icon: inputIcons.code,
              label: t('Mật khẩu'),
              className: 'custom-input',
              labelLow: '19px',
              labelHigh: '10px',
            }}
            register={register('password', {
              required: t(constants.VALIDATE_REQUIRE),
            })}
          />
          <span className="error">
            {errors.password && errors.password.message}
          </span>
        </div>
        <div className="login-button">
          <Button type="submit">
            <svg
              width="19"
              height="24"
              viewBox="0 0 19 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.71427 13.5V10.5H-1.71661e-05V7.5H5.71427V4.5L9.99998 7.875V4.5L15.7143 1.5H4.2857V6H2.85713V0H18.5714V19.5L9.99998 24V19.5H2.85713V12H4.2857V18H9.99998V10.125L5.71427 13.5Z"
                fill="white"
              />
            </svg>
            {t('đăng nhập')}
          </Button>
        </div>
      </form>
    </div>
  );
};
export default LoginPage;
