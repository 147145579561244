import React from 'react';
import styled from 'styled-components';

export default function Section({ header, children }) {
  return (
    <SSection>
      <div className="module-header">
        <h3 className="title"> {header} </h3>
      </div>
      <div className="module-content"> {children} </div>
    </SSection>
  );
}

export const SSection = styled.div`
  background: rgba(255, 255, 255, 0.5);
  padding: 30px;
  border: 1px solid white;

  @media (max-width: 991px) {
    padding: 15px;
  }

  .module-header {
    margin-bottom: 32px;
    @media (max-width: 991px) {
      margin-bottom: 20px;
      .title {
        font-size: 18px;
      }
    }
    .title {
      margin: 0;
      font-size: 24px;
      line-height: 30px;
      color: #333b6a;
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
    }
  }
  .module-content {
    margin: 0 40px;
    @media (max-width: 991px) {
      margin: 0px;
    }
  }
`;
