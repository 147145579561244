import * as types from './constants';
var initialState = {
  tableList: [],
  listChose:[],
};
var reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_HOTLINE:
      return {...state, tableList: action.value};
    case types.SET_FILTER:
      return {...state, filter: action.value};
    case types.SET_TOTAL:
      return {...state, total: action.value};
    case types.SET_CHOOSE_LIST:
      return {...state, listChose: action.value};
    default:
      return state;
  }
};
export default reducer;
