import { format } from "date-fns";

export const formatDate = (date) => {
  if (date) {
    const d = new Date(date);
    const dtf = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit' });
    const [{ value: mo }, , { value: da }, , { value: ye }] = dtf.formatToParts(d);
    return `${da}/${mo}/${ye}`
  }
  return "";
}


export const formatDateTime = (date, formatStr = 'H:mm dd/MM/yyyy') =>
  date ? format(new Date(date), formatStr) : date;