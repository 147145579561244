//manage coupon
import { SForm } from '@styled/admin/manageShareholderPage';
import { SHotline } from '@styled/admin/hotlinePage';
import styled from 'styled-components';
export const SCheckCouponListPage = styled(SHotline)``;

export const SFormCheckCouponListPage = styled(SForm)``;

export const STableTools = styled.div`
  .table-tools {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-left: -8px;
    margin-right: -8px;
    .view-btn,
    .download_one-btn,
    .download_all-btn {
      border-radius: 0;
      margin: 8px;
      height: 32px;
      padding: 5px 10px;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        margin-right: 8px;
      }

      &.disabled-btn {
        cursor: not-allowed;
      }
    }
    .view-btn {
      width: 182px;
      background: #00cb82;
    }
    .download_one-btn {
      background: #fbac18;
      width: 259px;
    }

    .download_all-btn {
      width: 253px;
      background: #2f80ed;
    }
  }
`;

export const SModalDetail = styled.div`
  color: #718098;
  padding: 0 56px;
  .select-wrapper {
    padding-bottom: 10px;
    width: 300px;
    max-width: 100%;
  }
  .content {
    .vote-details {
      padding-top: 10px;
      /* max-width: 700px; */
      margin-left: auto;
      margin-right: auto;
      padding-bottom: 42px;

      .vote-item {
        background: #dee3f6;
        padding: 13px 15px;
        font-size: 15px;
        margin-bottom: 13px;

        .item-head {
          position: relative;

          .title {
            margin: 0;
            padding-right: 127px;
            padding-left: 100px;
            position: relative;
            line-height: 19px;

            span {
              position: absolute;
              top: 0;
              left: 0;
            }
          }

          .note {
            color: red;
            font-style: italic;
            display: table;
            font-size: 13px;
            margin-bottom: 0;
          }

          .common__btn {
            position: absolute;
            text-transform: unset;
            font-size: 12px;
            width: auto;
            min-width: auto;
            right: 0px;
            top: 0px;
            padding: 3px 10px;
            white-space: nowrap;
            text-align: center;
          }
        }

        .item-content {
          background: white;
          padding: 9px 26px;
          margin-top: 13px;
          font-size: 13px;
          line-height: 21px;
          position: relative;
          z-index: 1;

          &:after {
            content: '';
            display: block;
            position: absolute;
            width: 20px;
            height: 20px;
            transform: rotate(-70deg) skewX(-45deg);
            background: #fff;
            top: -1px;
            right: 39px;
            z-index: -1;
          }

          .desc {
            margin: 0;
          }
        }
      }

      .item {
        .title {
        }

        .desc {
          margin-bottom: 8px;
          margin-top: -12px;

          span {
          }
        }

        .table-content .table {
          background: #eff3fc;

          .table-scroll table tbody tr td {
            height: auto;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 991px) {
    padding: 0 20px;
  }
`;
