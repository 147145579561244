import Section from '@components/section';
import { SReportPage } from '@styled/admin/initializeReportPage';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DataTable from './dataTable';
import Form from './form';
import Handle from './handle';

export default function InitializeReportPage({ type, part, method }) {
  const [kindToSend, setKindToSend] = useState(6);

  const { t } = useTranslation();
  const header =
    part === 'part1'
      ? t('Khai báo thủ tục khai mạc')
      : part === 'part2'
      ? method === 0
        ? t('Khai báo báo cáo - tờ trình')
        : t('Bầu dồn phiếu')
      : part === 'part3'
      ? t('Khai báo Biên bản - Nghị quyết Đại hội')
      : t('Khai báo tài liệu Đại hội');
  const tableType =
    type === 'sheet-2' ? (method === 0 ? 'sheet-2' : 'sheet-4') : type;

  let kind =
    part === 'part1'
      ? 1
      : part === 'part3'
      ? 3
      : method === 0
      ? 2
      : tableType === 'sheet-4' && method === 1
      ? 6
      : 4;

  if (type === 'sheet-5') kind = 5;
  return (
    <SReportPage>
      <Section header={header}>
        <Form
          tableType={tableType}
          kind={kind}
          kindToSend={kindToSend}
          setKindToSend={setKindToSend}
        />
      </Section>
      <Handle kind={kind} tableType={tableType} kindToSend={kindToSend} />
      <DataTable tableType={tableType} kind={kind} />
    </SReportPage>
  );
}
