export const URL = 'info/shareholder';
export const URL_VOTE_INFO = 'shareholder';
export const URL_CHECK_COUPON_DOWLOAD =
  'check-coupon/download-shareholder-voted';
export const URL_ALL_VOTE_INFO = 'check-coupon/result-voted';
export const URL_CONFERENCE = 'conference';
export const URL_TOPIC = 'topic/kind';
export const URL_GET_LIST_AUTHORITY = 'info/authority/100/1';

export const GET_LIST = 'GET_COUPON_LIST_LIST';
export const GET_CONFERENCE = 'GET_COUPON_LIST_CONFERENCE';
export const SET_CHOOSE_LIST = 'SET_COUPON_LIST_LIST';
export const TOGGLE_MODAL = 'TOGGLE_COUPON_LIST_MODAL';
export const SET_PAGE_SIZE = 'SET_COUPON_LIST_PAGE_SIZE';
export const SET_PAGE_NUMBER = 'SET_COUPON_LIST_PAGE_NUMBER';
export const SET_FILTER = 'SET_COUPON_LIST_FILTER';
export const SET_DETAILS = 'SET_COUPON_LIST_DETAILS';
export const SET_TOTAL = 'SET_COUPON_LIST_TOTAL';
export const SET_DOWNLOAD = 'SET_COUPON_LIST_DOWNLOAD';
export const SET_DETAILS_LIST = 'SET_COUPON_LIST_DETAILS_LIST';
export const SET_LOADING = 'SET_COUPON_LIST_LOADING';
export const GET_TOPIC1 = 'GET_COUPON_LIST_TOPIC1';
export const GET_TOPIC2 = 'GET_COUPON_LIST_TOPIC2';
export const GET_TOPIC3 = 'GET_COUPON_LIST_TOPIC3';
export const GET_TOPIC4 = 'GET_COUPON_LIST_TOPIC4';
export const GET_TOPIC6 = 'GET_COUPON_LIST_TOPIC6';

export const GET_LIST_AUTHORITY = 'GET_LIST_AUTHORITY';
