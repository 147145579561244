import Button from '@components/button';
import inputIcons from '@components/icons/input';
import Modal from '@components/modal';
import { SCheckCouponResultPage } from '@styled/checkCoupon/checkCouponResultPage';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  exportResultApi,
  exportShareholderResultApi,
  getReportApi,
  getReportVoteApi,
  setToggleModal,
} from '../../../../states/duck/pages/checkCoupon/checkCouponResultReducer/actions';
import { ModalDetail } from './modal';
import { TableCustomType123, TableCustomType4 } from './tableCustom';

const CheckCouponResultPage = () => {
  const { t } = useTranslation();
  const [voteType, setVoteType] = useState('bau-cu'); // vote type: bieu-quyet / bau-cu
  const [valid, setValid] = useState(false); // valid type: true false
  const [kindTopic, setKindTopic] = useState(1); // kind type : 1 2 3 4
  const [topicName, setTopicName] = useState('');
  const dispatch = useDispatch();
  const { toggleModal, topic1, topic2, topic3, topic4, topic6 } = useSelector(
    (state) => state.checkCoupon.checkCouponResult,
  );
  useEffect(() => {
    // dispatch(getReportApi());
    dispatch(getReportVoteApi());
  }, [dispatch]);
  const closeModal = (value) => {
    dispatch(setToggleModal(value));
  };
  const onExport = (kind) => {
    exportResultApi(kind);
  };
  const onExportShareholder = (kind) => {
    exportShareholderResultApi(kind);
  };
  return (
    <SCheckCouponResultPage>
      {topic1 && (
        <div className="result-item">
          <div className="head">
            <div className="title">
              {t('Phần')} 1: {t('Thông qua thủ tục khai mạc Đại hội')}
            </div>
            <div className="group-btn">
              <div className="button">
                <ButtonExportResult kind={1} onExport={onExport} />
              </div>
              <div className="button">
                <ButtonExportShareholderResult
                  kind={1}
                  onExport={onExportShareholder}
                />
              </div>
            </div>
          </div>
          <TableCustomType123
            listTopic={topic1}
            kind={1}
            setVoteType={setVoteType}
            setKindTopic={setKindTopic}
            setValid={setValid}
            setTopicName={setTopicName}
          />
        </div>
      )}
      {topic2 && (
        <div className="result-item">
          <div className="head">
            <div className="title">
              {t('Phần')} 2: {t('Biểu quyết Báo cáo/Tờ trình')}
            </div>
            <div className="group-btn">
              <div className="button">
                <ButtonExportResult kind={2} onExport={onExport} />
              </div>
              <div className="button">
                <ButtonExportShareholderResult
                  kind={2}
                  onExport={onExportShareholder}
                />
              </div>
            </div>
          </div>
          <TableCustomType123
            listTopic={topic2}
            kind={2}
            setVoteType={setVoteType}
            setKindTopic={setKindTopic}
            setValid={setValid}
            setTopicName={setTopicName}
          />
        </div>
      )}
      <div className="title big">
        {t('Phần')} 3: {t('Bầu cử (Bầu dồn phiếu)')}
      </div>
      {topic6 && (
        <div className="result-item">
          <div className="head">
            <div className="title" style={{ marginTop: '12px' }}>
              {t('Phần')} 3.1: {t('Biểu quyết thông qua nội dung bầu cử')}
            </div>
            <div className="group-btn">
              <div className="button">
                <ButtonExportResult kind={6} onExport={onExport} />
              </div>
              <div className="button">
                <ButtonExportShareholderResult
                  kind={6}
                  onExport={onExportShareholder}
                />
              </div>
            </div>
          </div>
          <TableCustomType123
            listTopic={topic6}
            kind={6}
            setVoteType={setVoteType}
            setKindTopic={setKindTopic}
            setValid={setValid}
            setTopicName={setTopicName}
          />
        </div>
      )}
      {topic4 && (
        <div className="result-item">
          <div className="head">
            <div className="title">
              {t('Phần')} 3.2: {t('Bầu cử (Bầu dồn phiếu)')}
            </div>
            <div className="group-btn">
              <div className="button">
                <ButtonExportResult kind={4} onExport={onExport} />
              </div>
              <div className="button">
                <ButtonExportShareholderResult
                  kind={4}
                  onExport={onExportShareholder}
                />
              </div>
            </div>
          </div>
          <TableCustomType4
            listTopic={topic4}
            kind={4}
            setVoteType={setVoteType}
            setKindTopic={setKindTopic}
            setValid={setValid}
            setTopicName={setTopicName}
          />
        </div>
      )}

      {topic3 && (
        <div className="result-item">
          <div className="head">
            <div className="title">
              {t('Phần')} 4: {t('Thông qua Biên bản - Nghị quyết Đại hội')}
            </div>
            <div className="group-btn">
              <div className="button">
                <ButtonExportResult kind={3} onExport={onExport} />
              </div>
              <div className="button">
                <ButtonExportShareholderResult
                  kind={3}
                  onExport={onExportShareholder}
                />
              </div>
            </div>
          </div>
          <TableCustomType123
            listTopic={topic3}
            kind={3}
            setVoteType={setVoteType}
            setKindTopic={setKindTopic}
            setValid={setValid}
            setTopicName={setTopicName}
          />
        </div>
      )}

      <Modal
        title={
          voteType === 'bieu-quyet'
            ? valid === true
              ? t('Danh sách Cổ đông tham gia biểu quyết hợp lệ')
              : t('Danh sách Cổ đông tham gia biểu quyết không hợp lệ')
            : valid === true
            ? t('Danh sách Cổ đông tham gia bầu cử hợp lệ')
            : t('Danh sách Cổ đông tham gia bầu cử không hợp lệ')
        }
        isOpen={toggleModal}
        toggleModal={closeModal}
        overwriteModalStyle={{
          maxWidth:
            voteType === 'bieu-quyet'
              ? valid === true
                ? '1200px'
                : '1000px'
              : '800px',
        }} //width: bieu quyet valid: 1200 // bieu quyet invalid: 1000  // bau cu: 800
      >
        <ModalDetail topicName={topicName} kind={kindTopic} valid={valid} />
      </Modal>
    </SCheckCouponResultPage>
  );
};
export default CheckCouponResultPage;

const ButtonExportResult = ({ kind, onExport }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  return (
    <Button
      className="download_all-btn disable__btn"
      style={{ width: language === 'en' ? '230px' : '205px' }}
      onClick={() => onExport(kind)}
    >
      {inputIcons.export_result}
      {t('Xuất biên bản kiểm phiếu')}
    </Button>
  );
};
const ButtonExportShareholderResult = ({ kind, onExport }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  return (
    <Button
      className="download_all-btn disable__btn"
      style={{ width: language === 'en' ? '227px' : '205px' }}
      onClick={() => onExport(kind)}
    >
      {inputIcons.export_result}
      {t('Xuất danh sách cổ đông')}
    </Button>
  );
};
