import * as types from './constants';
import * as common from '../../../../common/constants';

var initialState = {
  tableList: [],
  listChose: [],
  filter: {
    search: '',
    pageSize: common.PAGE_SIZE,
    pageNumber: 1,
    // status: 'all',
    // joinType: 'all',
  },
  total: 0,
  sending: false,
  info: {},
};
var reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST:
      return { ...state, tableList: action.value };
    case types.SET_CHOOSE_LIST:
      return { ...state, listChose: action.value };
    case types.SET_FILTER:
      return { ...state, filter: action.value };
    case types.SET_TOTAL:
      return { ...state, total: action.value };
    case types.SET_SENDING:
      return { ...state, sending: action.value };
    case types.SET_INFO:
      return { ...state, info: action.value };
    default:
      return state;
  }
};
export default reducer;
