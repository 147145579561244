import {SecretaryExportReportPage} from '@styled/secretary/secretaryExportReportPage';
import React from 'react';
import {useDispatch} from 'react-redux';
import {
  exportReportApi,
  exportResolutionApi,
} from '@duck/pages/secretary/secretaryExportReportReducer/actions';
import {useTranslation} from 'react-i18next';

export default function ExportReportPage() {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  return (
    <SecretaryExportReportPage>
      <div className="left">
        <div className="title">{t('Xuất biên bản - Nghị quyết ĐH')}</div>
        <div className="btn-container">
          <div
            className="export-btn"
            onClick={() => {
              dispatch(exportReportApi());
            }}
          >
            <span>{t('Xuất Biên bản Đại hội')}</span>
            <img
              src="../images/login/login-btn-white.png"
              alt=""
              className="img nomal"
            />
            <img
              src="../images/login/login-btn-green.png"
              alt=""
              className="img active"
            />
          </div>

          <div
            className="export-btn"
            onClick={() => {
              dispatch(exportResolutionApi());
            }}
          >
            <span>{t('Xuất Nghị quyết Đại hội')}</span>
            <img
              src="../images/login/login-btn-white.png"
              alt=""
              className="img nomal"
            />
            <img
              src="../images/login/login-btn-green.png"
              alt=""
              className="img active"
            />
          </div>
        </div>
      </div>
      <div className="right">
        <img src="../images/exportReport.png" alt="" />
      </div>
    </SecretaryExportReportPage>
  );
}
