import Button from '@components/button';
import inputIcons from '@components/icons/input';
import Input from '@components/input';
import ErrorMessage from '@components/input/ErrorMessage';
import Modal from '@components/modal';
import { VALIDATE_PATTERN, VALIDATE_REQUIRE } from '@constants/validate';
import {
  getUserListApi,
  setChooseList,
} from '@duck/pages/admin/authorizationInfomationReducer/actions';
import {
  URL_CREATE,
  URL_EDIT,
} from '@duck/pages/admin/authorizationInfomationReducer/constants';
import { getUserApi } from '@duck/pages/admin/shareholderStatusReducer/actions';
import { callApi } from '@state-common/';
import { trimSpace } from 'components/layout';
import debounce from 'lodash.debounce';
import PNotify from 'pnotify/dist/es/PNotify';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

const EditModal = ({ toggleModal, setToggleModal, modalType }) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const listChose = useSelector(
    (state) => state.admin.authorizationInfoList.listChose,
  );
  const filter = useSelector(
    (state) => state.admin.authorizationInfoList.filter,
  );
  const tableData = useSelector(
    (state) => state.admin.shareholderStatus.tableList,
  );
  const filterList = useSelector(
    (state) => state.admin.shareholderStatus.filter,
  );

  useEffect(() => {
    dispatch(getUserApi(filterList));
  }, [dispatch, filterList]);
  const onSubmit = (data) => {
    console.log('datadsadsadas', data);
    const { name, phone, email, position, password, identityNumber } = data;
    const temp = listChose[0];
    if (modalType === 'edit') {
      callApi(`${URL_EDIT}/${temp._id}`, 'PUT', {
        name,
        phone,
        email: email && email !== '' ? email : undefined,
        position: position && position !== '' ? position : undefined,
        password: password ? password : undefined,
        identityNumber: identityNumber,
      }).then(function (res) {
        setToggleModal(false);
        setTimeout(() => {
          dispatch(getUserListApi(filter));
          dispatch(setChooseList([]));
        }, 500);
        PNotify.success({
          title: t('Thành công'),
          text: t('Sửa thành công'),
          destroy: true,
          delay: 2000,
        });
      });
      // .catch(handleError);
    } else {
      callApi(`${URL_CREATE}`, 'POST', {
        ...data,
        password: password ? password : '',
      }).then(function (res) {
        setToggleModal(false);
        setTimeout(() => {
          dispatch(getUserListApi(filter));
          dispatch(setChooseList([]));
        }, 500);
        PNotify.success({
          title: t('Thành công'),
          // text: t('Thêm thành công'),
          text: t('Thêm thành công'),
          destroy: true,
          delay: 2000,
        });
      });
      // .catch(handleError);
    }
  };
  useEffect(() => {
    if (modalType === 'edit') {
      setValue(
        'identityNumber',
        listChose[0] ? listChose[0].identityNumber : '',
      );
      setValue('name', listChose[0] ? listChose[0].name : '');
      setValue('phone', listChose[0] ? listChose[0].phone : '');
      setValue('email', listChose[0] ? listChose[0].email : '');
      setValue('position', listChose[0] ? listChose[0].position : '');
      setValue('password', '');
    } else {
      setValue('identityNumber', '');
      setValue('name', '');
      setValue('phone', '');
      setValue('email', '');
      setValue('position', '');
      setValue('password', '');
    }
  }, [listChose, setValue]);

  const onChangeIdentityNumber = debounce(async (value) => {
    if (modalType == 'add' && value) {
      const res = await callApi(`info/shareholder/10/1`, 'POST', {
        identityNumber: value,
        joinType: 'all',
        status: 'all',
        scale: 'all',
        type: 'all',
        sortBy: 'code',
        order: 'asc',
      });
      const temp = res?.data?.data?.shareholders?.[0];
      if (
        temp &&
        res?.data?.data?.shareholders.length === 1 &&
        temp.identityNumber == value
      ) {
        // setValue('identityNumber', temp.identityNumber || '');
        setValue('name', temp.name || '');
        setValue('phone', temp.phone || '');
        setValue('email', temp.email || '');
        setValue('position', temp.position || '');
      } else {
        setValue('name', '');
        setValue('phone', '');
        setValue('email', '');
        setValue('position', '');
      }
    }
  }, 1000);
  return (
    <Modal
      isOpen={toggleModal}
      toggleModal={setToggleModal}
      title={
        modalType === 'add' ? t('Thêm mới') : t('Sửa tài khoản nhận ủy quyền')
      }
      overwriteModalStyle={{
        maxWidth: '560px',
      }}
    >
      <SModalContent>
        <div className="handle-wrapper content">
          <div className="handle-item">
            <Input.Text
              inputProps={
                {
                  // readOnly: modalType === 'edit' ? true : false,
                  // type: 'number',
                }
              }
              register={register('identityNumber', {
                required: t(VALIDATE_REQUIRE),
                pattern: {
                  value: /^[A-Za-z0-9]+$/,
                  message: t(VALIDATE_PATTERN),
                },

                onChange: (e) => {
                  const value = trimSpace(e.target.value);
                  onChangeIdentityNumber(value);
                },
              })}
              wrapperProps={{
                label: t('CMND/CCCD'),
                icon: inputIcons.user,
                required: true,
              }}
            />
            <ErrorMessage errors={errors} field="identityNumber" />
          </div>
          <div className="handle-item">
            <Input.Text
              register={register('name', { required: t(VALIDATE_REQUIRE) })}
              wrapperProps={{
                label: t('Họ tên'),
                icon: inputIcons.account,
                required: true,
              }}
            />
            <ErrorMessage errors={errors} field="name" />
          </div>
          <div className="handle-item">
            <Input.Text
              inputProps={{
                type: 'number',
              }}
              register={register('phone')}
              wrapperProps={{
                label: t('Số điện thoại'),
                icon: inputIcons.phone,
                //   required: true,
              }}
            />
            {/* <ErrorMessage errors={errors} field="name" /> */}
          </div>
          <div className="handle-item">
            <Input.Text
              register={register('email')}
              wrapperProps={{
                label: 'Email',
                icon: inputIcons.email,
                //   required: true,
              }}
            />
            {/* <ErrorMessage errors={errors} field="email" /> */}
          </div>
          <div className="handle-item">
            <Input.Text
              register={register('password')}
              wrapperProps={{
                label: t('Mật khẩu (nếu có)'),
                icon: inputIcons.code,
                required: true,
              }}
            />
            <ErrorMessage errors={errors} field="password" />
          </div>
          <div className="handle-item">
            <Input.Text
              // inputProps={{
              //   readOnly: true,
              // }}
              register={register('position')}
              // readOnly
              wrapperProps={{
                label: t('Chức vụ'),
                icon: inputIcons.user,
                //   required: true,
              }}
            />
            {/* <ErrorMessage errors={errors} field="position" /> */}
          </div>
        </div>
        <div className="button-group">
          <Button
            className="cancel-button"
            onClick={() => setToggleModal(false)}
          >
            {t('Hủy bỏ')}
          </Button>
          <Button onClick={handleSubmit(onSubmit)} className="confirm-button">
            {modalType === 'add' ? t('Thêm') : t('Cập nhật')}
          </Button>
        </div>
      </SModalContent>
    </Modal>
  );
};

export default EditModal;

const SModalContent = styled.div`
  padding: 0 80px;
  background-color: ${(props) => props.backGroundColor || 'white'};
  margin-top: 44px;
  .handle-wrapper {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 20px;

    /* flex-wrap: wrap; */
    /* &.content {
      padding: 0 50px;
    } */
    .handle-item {
      box-sizing: border-box;

      &.width-50 {
        width: 50%;
      }
      &.width-100 {
        width: 100%;
      }
    }
  }
  .button-group {
    padding-top: 30px;
    display: flex;
    padding-left: 16px;
    padding-right: 16px;
    align-items: center;
    justify-content: center;
    gap: 20px;
    .confirm-button {
      background: #00cb82;
      color: #ffffff;
    }
    .cancel-button {
      color: #718098;
      background: #dfe9f5;
    }
  }

  @media (max-width: 1199px) {
    padding: 0 40px;
  }
  @media (max-width: 991px) {
    padding: 0 20px;
    margin-top: 20px;
  }
  @media (max-width: 767px) {
    padding: 0 15px;
  }

  .group-button {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;
    width: 100%;

    & > ${Button} {
      margin-left: 16px;
      margin-bottom: 8px;
    }

    @media (max-width: 991px) {
      margin-top: 20px;
    }
  }
  .form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;
