import React, { useState, useEffect } from 'react';
import PNotify from 'pnotify/dist/es/PNotify';
import { useSelector, useDispatch } from 'react-redux';
import { callApi } from 'states/common/index';
import {
  checkInAuthorityApi,
  getAuthorizedApi,
  setChooseList,
} from 'states/duck/pages/checkin/checkInHandReducer/actions';
import { findIndex, transformNumberPrinter } from 'components/bases/configs';
import { Loading } from 'components/bases/shared';
import Input from '@components/input';
import inputIcons from '@components/icons/input';
import ErrorMessage from '@components/input/ErrorMessage';
import { useForm } from 'react-hook-form';
import { SAddAuthorityModal } from '@styled/checkIn/checkInHandPage';
import debounce from 'lodash.debounce';
import Radio from '@components/input/Radio';
import {
  VALIDATE_ONLY_NUMBER,
  // VALIDATE_ONLY_NUMBER_INTEGER,
  VALIDATE_REQUIRE,
} from '@constants/validate';
import { useTranslation } from 'react-i18next';
import { trimSpace } from 'components/layout';

const AddAuthorityModalTest = ({
  reloadAfterAutho,
  setReloadAfterAutho,
  shareholder,
  onClick,
  authorityList,
  item,
}) => {
  const authorityDefault = {
    // checkInType: 'authority',
    phone: '',
    identityNumber: '',
    name: '',
    email: '',
    password: '',
    sharesNumber: 0,
  };
  const { t } = useTranslation();

  const [data, setData] = useState([authorityDefault]);
  const [period, setPeriod] = useState(false);
  const dispatch = useDispatch();
  const admin = JSON.parse(localStorage.getItem('adminId'));
  const processorId = admin && admin.adminId ? admin.adminId : '';
  // const [valueIdentityNumber, setValueIdentityNumber] = useState('');
  // const [valueName, setValueName] = useState('');
  // const [valuePhone, setValuePhone] = useState('');
  // const [valueEmail, setValueEmail] = useState('');
  // const [valueSharesNumber, setValueSharesNumber] = useState('');
  // const [valuePassword, setValuePassword] = useState('');

  const [unableShareholder, setUnableShareholder] = useState([]);
  const report2 = useSelector((state) => state.checkin.checkInHand.report2);

  const [submit, setSubmit] = useState(false);
  const [remainingState, setRemainingState] = useState(0);

  const deleteAuthorityItem = (index) => {
    let dataClone = [...data];
    dataClone.splice(index, 1);
    setData(dataClone);
  };

  const changeAuthorityItem = (position, authorityItem) => {
    let dataClone = [...data];
    dataClone.splice(position, 1, authorityItem);
    setData(dataClone);
    console.log('dataClone', dataClone);
  };

  useEffect(() => {
    let remaining = shareholder.sharesNumber;
    if (authorityList !== []) {
      for (let i = 0; i < authorityList.length; i++) {
        remaining = remaining - authorityList[i].sharesNumber;
      }
    }
    setRemainingState(remaining);
  }, [authorityList, shareholder.sharesNumber]);

  const onSubmit = async () => {
    setSubmit(true);

    let body = {
      shareholderId: item._id,
      processorId,
      data: [],
    };

    let checkInteger = 0;

    for (let i = 0; i < data.length; i++) {
      if (
        data[i].sharesNumber.toString().indexOf('.') !== -1 ||
        data[i].sharesNumber.toString().indexOf(',') !== -1
      ) {
        checkInteger += 1;
      }
    }
    // setValueIdentityNumber(body.data[0].identityNumber);
    if (checkInteger > 0) {
      body.data = [];
      PNotify.error({
        title: t('Lỗi'),
        text: t('Số cổ phần ủy quyền không được là số lẻ'),
        destroy: true,
        delay: 2000,
      });
    } else if (remainingState <= 0) {
      body.data = [];
      PNotify.error({
        title: t('Lỗi'),
        text: t('CĐ đã ủy quyền hết CP'),
        destroy: true,
        delay: 2000,
      });
    } else {
      body.data = [...data];
      dispatch(
        checkInAuthorityApi(
          reloadAfterAutho,
          setReloadAfterAutho,
          onClick,
          body,
        ),
      );
    }

    setSubmit(false);
  };
  // console.log('valueIdentityNumber', valueIdentityNumber);
  useEffect(() => {
    dispatch(getAuthorizedApi());
    dispatch(setChooseList([]));
  }, [dispatch]);

  useEffect(() => {
    let unableShareholderMirror = [];
    for (let i = 0; i < report2.length; i++) {
      for (let j = 0; j < report2[i].unableShareholder.length; j++) {
        if (
          unableShareholderMirror.indexOf(report2[i].unableShareholder[j]) < 0
        ) {
          unableShareholderMirror.push(report2[i].unableShareholder[j]);
        }
      }
    }
    setUnableShareholder(unableShareholderMirror);
  }, [report2]);

  return (
    <SAddAuthorityModal>
      {/* {submit && <Loading title={t('Đang xử lý. Vui lòng đợi')} />} */}
      {data.map((itemAuthority, index) => {
        return (
          <AuthorityItem
            dispatch={dispatch}
            shareholder={shareholder}
            authorityList={authorityList}
            itemAuthority={itemAuthority}
            key={index}
            period={period}
            setPeriod={setPeriod}
            position={index}
            deleteAuthorityItem={deleteAuthorityItem}
            changeAuthorityItem={changeAuthorityItem}
            unableShareholder={unableShareholder}
            dataLength={data.length}
            remainingState={remainingState}
          />
        );
      })}

      <div className="button_common">
        <button
          className="common__cancel"
          type="submit"
          onClick={() => onClick(false)}
        >
          {t('Hủy bỏ')}
        </button>
        <button className={`common__btn`} type="submit" onClick={onSubmit}>
          {t('Thêm')}
        </button>
      </div>
    </SAddAuthorityModal>
  );
};

export default AddAuthorityModalTest;

const AuthorityItem = ({
  shareholder,
  itemAuthority,
  authorityList,
  position,
  changeAuthorityItem,
  unableShareholder,
  dispatch,
  remainingState,
}) => {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const { t } = useTranslation();

  const data = watch([
    'identityNumber',
    'name',
    'phone',
    'email',
    'sharesNumber',
    'password',
  ]);

  console.log('remainingState', remainingState);
  // const [remainingState, setRemainingState] = useState(0);
  // const [method, setMethod] = useState(false);

  const { listChose, listAuthorized } = useSelector(
    (state) => state.checkin.checkInHand,
  );

  const identityNumber = data[0] ? data[0] : '';
  const name = data[1] ? data[1] : '';
  const phone = data[2] ? data[2] : '';
  const email = data[3] ? data[3] : '';
  const sharesNumber = data[4] ? data[4] : '';
  const password = data[5] ? data[5] : '';

  useEffect(() => {
    setValue('sharesNumber', remainingState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainingState]);

  const [nameAfterAuthority, setNameAfterAuthority] = useState('');
  // thay đổi tên cổ đông set lại CMND
  useEffect(() => {
    if (nameAfterAuthority !== '' && nameAfterAuthority !== name) {
      setValue('identityNumber', '');
      setValue('phone', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, setValue]);

  //Tổng số cổ phần ủy quyền không được lớn hơn số cổ phần khả dụng và > 0
  useEffect(() => {
    if (sharesNumber !== '') {
      let sumAuthorityShares = authorityList.reduce((a, b) => {
        return a + Number(b.sharesNumber);
      }, 0);
      let sharesNumberShareholder =
        shareholder.sharesNumberHistories[0].sharesNumber ||
        shareholder.sharesNumber;

      if (Number(sharesNumber) <= 0) {
        PNotify.error({
          title: t('Lỗi'),
          text: t('Số cổ phần ủy quyền phải lớn hơn 0'),
          destroy: true,
          delay: 2000,
        });
        setValue('sharesNumber', '');
      } else if (
        Number(sumAuthorityShares) + Number(sharesNumber) >
        sharesNumberShareholder
      ) {
        PNotify.error({
          title: t('Lỗi'),
          text: t(
            'Tổng số cổ phần ủy quyền không được lớn hơn số cổ phần khả dụng',
          ),
          destroy: true,
          delay: 2000,
        });
        setValue('sharesNumber', '');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    authorityList,
    shareholder.sharesNumber,
    shareholder.sharesNumberHistories,
    sharesNumber,
    setValue,
  ]);

  //Không thể ủy quyền cho Cổ đông bị chặn ở 1 trong các tờ trình biểu quyết
  useEffect(() => {
    if (identityNumber) {
      if (unableShareholder.indexOf(identityNumber) >= 0) {
        setValue('identityNumber', '');
        PNotify.error({
          title: t('Lỗi'),
          text: t(
            'Không thể ủy quyền cho Cổ đông bị chặn ở 1 trong các tờ trình biểu quyết',
          ),
          destroy: true,
          delay: 2000,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identityNumber, unableShareholder, setValue]);

  useEffect(() => {
    let authorityData = {
      ...itemAuthority.authorityData,
      identityNumber: identityNumber,
      name: name,
      phone: phone,
      email: email,
      sharesNumber: Number(sharesNumber),
      password: password,
    };

    // console.log('authorityData: ', authorityData);
    changeAuthorityItem(position, { ...itemAuthority, ...authorityData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identityNumber, name, phone, email, sharesNumber, password]);

  const onIdNumberChange = (e) => {
    handleGetShareholderByIDNum(e.target ? trimSpace(e.target.value) : '');
  };

  const handleGetShareholderByIDNum = React.useCallback(
    debounce((val) => {
      console.log('vallllll');
      if (val !== '') {
        callApi('info/shareholder/10/1', 'POST', {
          identityNumber: val,
          joinType: 'all',
          status: 'all',
        }).then((res) => {
          let shareHoderSearch = res?.data?.data?.shareholders || [];
          // console.log('shareHoderSearch: ', shareHoderSearch);
          // console.log(
          //   'shareHoderSearch[0].isVoted: ',
          //   shareHoderSearch[0].isVoted,
          // );
          // if (shareHoderSearch.length > 0 && shareHoderSearch[0].isVoted) {
          // PNotify.error({
          //   title: 'lỗi',
          //   text: 'Không thể ủy quyền cho cổ đông đã biểu quyết',
          //   destroy: true,
          //   delay: 2000,
          // });
          // setNameAfterAuthority('');
          // setValue('name', '');
          // setValue('phone', '');
          // setValue('email', '');
          // } else {
          if (
            shareHoderSearch.length == 1 &&
            shareHoderSearch[0].identityNumber == val
          ) {
            setNameAfterAuthority(shareHoderSearch[0].name);
            setValue('name', shareHoderSearch[0].name);
            setValue('phone', shareHoderSearch[0].phone);
            setValue('email', shareHoderSearch[0].email);
          } else {
            setNameAfterAuthority('');
            setValue('name', '');
            setValue('phone', '');
            setValue('email', '');
          }
          // }
        });
      }
    }, 1500),
    [],
  );

  const setCheckItem = (item) => {
    var newListChose = [...listChose];
    if (findIndex(item, newListChose) < 0) {
      newListChose = [];
      newListChose.push(item);
    } else {
      newListChose.splice(newListChose.indexOf(item), 1);
    }
    dispatch(setChooseList(newListChose));
    setValue('identityNumber', newListChose[0]?.identityNumber);
    setValue('name', newListChose[0]?.name);
    setValue('phone', newListChose[0]?.phone);
    setValue('email', newListChose[0]?.email);
  };

  return (
    <form className="form-content">
      <div className="list_input">
        <div className="bs-row row-sm-15">
          <div className="bs-col sm-50-15">
            <div className="authority-info-item">
              <Input.Text
                wrapperProps={{
                  label: t('CMND/CCCD (Tài khoản)'),
                  icon: inputIcons.account,
                }}
                inputProps={{
                  name: 'identityNumber',
                  readOnly: listChose.length > 0,
                }}
                register={register('identityNumber', {
                  required: t(VALIDATE_REQUIRE),
                  onChange: onIdNumberChange,
                })}
              />
              <ErrorMessage errors={errors} field="identityNumber" />
            </div>
          </div>

          <div className="bs-col sm-50-15">
            <div className="authority-info-item">
              <Input.Text
                wrapperProps={{
                  label: t('Họ tên người được ủy quyền'),
                  icon: inputIcons.men,
                }}
                inputProps={{
                  type: 'text',
                  readOnly: listChose.length > 0,
                }}
                register={register('name', {
                  required: t(VALIDATE_REQUIRE),
                })}
              />
              <ErrorMessage errors={errors} field="name" />
            </div>
          </div>

          <div className="bs-col sm-50-15">
            <div className="authority-info-item">
              <Input.Text
                wrapperProps={{
                  label: t('Số điện thoại'),
                  icon: inputIcons.phone,
                }}
                inputProps={{
                  type: 'number',
                  readOnly: listChose.length > 0,
                }}
                register={register('phone')}
              />
              <ErrorMessage errors={errors} field="phone" />
            </div>
          </div>

          <div className="bs-col sm-50-15">
            <div className="authority-info-item">
              <Input.Text
                wrapperProps={{
                  label: t('Email'),
                  icon: inputIcons.email,
                }}
                inputProps={{
                  type: 'text',
                  autoComplete: 'off',
                  readOnly: listChose.length > 0,
                }}
                register={register('email')}
              />
              <ErrorMessage errors={errors} field="email" />
            </div>
          </div>

          <div className="bs-col sm-50-15">
            <div className="authority-info-item">
              <Input.Text
                wrapperProps={{
                  label: `${t('Số cổ phần được ủy quyền')} (${t(
                    'còn lại',
                  )} ${remainingState}`,
                  icon: inputIcons.file,
                }}
                inputProps={{
                  type: 'number',
                  pattern: VALIDATE_ONLY_NUMBER,
                  readOnly: listChose.length > 0,
                }}
                register={register('sharesNumber', {
                  required: t(VALIDATE_REQUIRE),
                  // pattern: VALIDATE_ONLY_NUMBER,
                })}
              />
              <ErrorMessage errors={errors} field="sharesNumber" />
            </div>
          </div>

          <div className="bs-col sm-50-15">
            <div className="authority-info-item">
              <Input.Text
                wrapperProps={{
                  label: t('Mật khẩu'),
                  icon: inputIcons.code,
                }}
                inputProps={{
                  type: 'password',
                  // autoComplete: "off",
                  readOnly: listChose.length > 0,
                }}
                register={register('password')}
              />
              <ErrorMessage errors={errors} field="password" />
            </div>
          </div>
        </div>
      </div>
      {listAuthorized.length > 0 && (
        <div className="authe-default">
          <p className="text">
            {t('Hoặc tích chọn người nhận ủy quyền mặc định')}
          </p>
          <div className="item-default">
            {listAuthorized.map((item, index) => {
              return (
                <div className="item" key={index}>
                  <Radio
                    checked={findIndex(item, listChose) >= 0 ? true : false}
                    text={t(`${item.name} - ${item.position}`)}
                    onClick={() => setCheckItem(item)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </form>
  );
};

// const findIndex = (identityNumber, arr) => {
//   let index = -1;
//   for (let i = 0; i < arr.length; i++) {
//     if (identityNumber === arr[i].identityNumber) {
//       index = i;
//     }
//   }
//   return index;
// };
