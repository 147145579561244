import Text from './Text';
import Date from './Date';
import DateTime from './DateTime';
import Select from './Select';
import Time from './Time';
import Checkbox from './Checkbox';
import HandleButton from './HandleButton';
import ErrorMessage from './ErrorMessage';
import Radio from './Radio';
import TextArea from './TextArea';

const Input = {
  Text,
  Date,
  DateTime,
  Select,
  Time,
  Checkbox,
  HandleButton,
  Radio,
  ErrorMessage,
  TextArea,
};

export default Input;
