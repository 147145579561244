import React, { useState, useEffect, useMemo } from 'react';
import { SSelectionItem } from '@styled/checkCoupon/checkCouponArtisanPage';
import { useDispatch, useSelector } from 'react-redux';
import Input from '@components/input';
import Button from '@components/button';
import {
  numberToFormatText,
  textToNumber,
  transformNumberPrinter,
} from '@configs/transformNumber';
import {
  createSelectVoteApi,
  setCreatingVote,
  setAllNotVote,
  setToggleConfirmModal,
  setMarkConfirmModal,
} from './../../../../states/duck/pages/checkCoupon/checkCouponArtisanReducer/actions';
import PNotify from 'pnotify/dist/es/PNotify';
import { useTranslation } from 'react-i18next';
import Modal from '@components/modal';
import ModalConfirm from './modal/modalConfirm';

export default function CumulativeVoteItem({ topic }) {
  const {
    voter,
    resultVote4,
    listChose,
    allNotVote,
    toggleConfirmModal,
    markModalConfirm,
  } = useSelector((state) => state.checkCoupon.checkCouponArtisan);
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const dispatch = useDispatch();
  const [isVoted, setIsVoted] = useState(false);
  const [sharesAvailable, setSharesAvailable] = useState(0);
  const [sharesLeft, setSharesLeft] = useState(0);
  const [overShares, setOverShares] = useState(false);
  const [listCheck, setListCheck] = useState([]); // list check to share equal
  const [listVotes, setListVotes] = useState([]);
  // console.log(topic);
  useEffect(() => {
    if (
      voter.voterId === 'all' &&
      allNotVote.kind4[topic._id] &&
      allNotVote.kind4[topic._id].length > 0
    ) {
      let total = allNotVote.kind4[topic._id].reduce((a, b) => {
        return a + b.sharesNumber;
      }, 0);
      setSharesAvailable(total * topic.selectNumber);
    } else {
      if (topic.selectNumber)
        setSharesAvailable(Number(voter.voterShares) * topic.selectNumber);
      setSharesLeft(Number(voter.voterShares) * topic.selectNumber);
    }
  }, [voter, topic, allNotVote]);

  useEffect(() => {
    let totalShares = 0;
    for (let i in listVotes) {
      totalShares += Number(listVotes[i].amount);
    }
    setSharesLeft(sharesAvailable - totalShares);
    if (totalShares > Number(sharesAvailable)) {
      setOverShares(true);
    } else {
      setOverShares(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voter, listVotes, allNotVote, topic]);
  const closeModal = (value) => {
    dispatch(setToggleConfirmModal(false));
  };
  const findVotedAmount = (selectionId, topicIndex) => {
    //topicIndex: index of topic in result4
    if (topicIndex > -1) {
      for (let i in resultVote4[topicIndex].selections) {
        // console.log(resultVote4[topicIndex].selections);
        if (resultVote4[topicIndex].selections[i]._id === selectionId) {
          return resultVote4[topicIndex].selections[i].amount;
        }
      }
    }
    return 0;
  };
  useEffect(() => {
    const index = resultVote4.findIndex(
      (r) => r.topic_id && r.topic_id._id === topic._id,
    );
    //index: index of topic in resultVote4
    if (index > -1) {
      setIsVoted(true);
    } else {
      setIsVoted(false);
    }

    const tmpSelections = [];
    for (let i in topic.selections) {
      const temp = {
        _id: topic.selections[i]._id,
        fixId: topic.selections[i].fixId,
        selectName: topic.selections[i].selectName,
        amount:
          index > -1 ? findVotedAmount(topic.selections[i]._id, index) : 0,
      };
      tmpSelections.push(temp);
    }
    // console.log(index);
    setListVotes(tmpSelections);
    setListCheck([]);
    // console.log('tmpSelections', tmpSelections);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultVote4, topic]);

  const onCheck = (selectionId) => {
    if (!isVoted && !listChose[0].invalidVoted) {
      // check equal share
      const tmpListDevideEqually = [...listCheck]; //list select to devide equally
      const index = tmpListDevideEqually.indexOf(selectionId);
      if (index > -1) {
        tmpListDevideEqually.splice(index, 1);
        setListCheck(tmpListDevideEqually);
      } else {
        tmpListDevideEqually.push(selectionId);
        setListCheck(tmpListDevideEqually);
      }

      // ----setvalue for devide equally
      let equalShares; //equalShares la so phieu bầu đều
      if (
        voter.voterId === 'all' &&
        allNotVote.kind4[topic._id] &&
        allNotVote.kind4[topic._id].length > 0
      ) {
        equalShares = 0;
        for (let i = 0; i < allNotVote.kind4[topic._id].length; i++) {
          equalShares += Math.floor(
            (Number(allNotVote.kind4[topic._id][i].sharesNumber) *
              topic.selectNumber) /
              tmpListDevideEqually.length,
          );
        }
      } else {
        equalShares = voter
          ? Math.floor(sharesAvailable / tmpListDevideEqually.length)
          : 0;
      }
      const tmpListVotes = [...listVotes];
      for (let i in tmpListVotes) {
        if (tmpListDevideEqually.indexOf(tmpListVotes[i]._id) > -1) {
          tmpListVotes[i] = {
            ...tmpListVotes[i],
            amount: Number(equalShares),
          };
        } else {
          tmpListVotes[i] = {
            ...tmpListVotes[i],
            amount: 0,
          };
        }
      }
      setListVotes(tmpListVotes);
    } else if (isVoted) {
      PNotify.error({
        title: t('Lỗi'),
        text: t('Cổ đông đã bầu cử cho nội dung này'),
        destroy: true,
        delay: 2000,
      });
    } else {
      PNotify.error({
        title: t('Lỗi'),
        text: t('Không thể sao chép phiếu không hợp lệ'),
        destroy: true,
        delay: 2000,
      });
    }
  };

  const onSelectVote = (selectionId, amount) => {
    //change value in input
    if (Number(amount) < 0) {
      PNotify.error({
        title: t('Lỗi'),
        text: t('Số phiếu bầu phải lớn hơn 0'),
        destroy: true,
        delay: 2000,
      });
    } else {
      const tmpListVotes = [...listVotes];
      for (let i in tmpListVotes) {
        if (tmpListVotes[i]._id === selectionId) {
          tmpListVotes[i] = {
            ...tmpListVotes[i],
            amount: Number(amount),
          };
          break;
        }
      }
      setListVotes(tmpListVotes);
      setListCheck([]);
    }
  };

  const onSendSelectVote = () => {
    if (isVoted) {
      PNotify.error({
        title: t('Lỗi'),
        text: t('Cổ đông đã bầu cử cho nội dung này'),
        destroy: true,
        delay: 2000,
      });
    }
    // else if (topic.isOpen === false) {
    //   PNotify.error({
    //     title: t('Lỗi'),
    //     text: t('Ngoài thời gian bầu cử'),
    //     destroy: true,
    //     delay: 2000,
    //   });
    // }
    else {
      const tmp = [];
      let kind4 = { ...allNotVote.kind4 };
      if (voter.voterId === 'all') {
        kind4[topic._id] = [];

        //numberVoted: số người được cổ đông bỏ phiếu trong 1 topic
        const numberVoted = listVotes.filter((item) => item.amount !== 0);
        for (let i = 0; i < allNotVote.kind4[topic._id].length; i++) {
          //amount / available * ( allnotvote.sharesNumber * topic.selectNumber)
          //công thức tính vote for all:
          // allNotVote(item kind4).sharesNumber * topic.selectNumber / numberVoted
          // if (item.amount !== 0)

          let listVotesCalculator = [];
          for (let j in listVotes) {
            if (listVotes[j].amount >= 0) {
              // if (listVotes[j].amount !== 0) {
              listVotesCalculator.push({
                ...listVotes[j],
                amount:
                  listVotes[j].amount !== 0 && sharesAvailable !== 0
                    ? Math.floor(
                        (allNotVote.kind4[topic._id][i].sharesNumber *
                          topic.selectNumber) /
                          numberVoted.length,
                      )
                    : 0,
              });
            }
          }

          tmp.push({
            kind: 4,
            topicId: topic._id,
            selections: listVotesCalculator,
            shareholderId: allNotVote.kind4[topic._id][i].id,
            note: '',
          });
        }
      } else {
        let kind4CloneById = [...kind4[topic._id]];
        let index = findIndex(voter.voterId, kind4CloneById);
        kind4CloneById.splice(index, 1);
        kind4 = {
          ...kind4,
          [topic._id]: kind4CloneById,
        };

        // console.log('listVote', listVotes);
        for (let i = 0; i < listVotes.length; i++) {
          if (listVotes[i].amount < 0) {
            listVotes.splice(i, 1);
            i--;
          }
        }

        tmp.push({
          kind: 4,
          topicId: topic._id,
          selections: listVotes,
          shareholderId: voter.voterId,
          note: '',
        });
      }

      dispatch(setCreatingVote(true));
      console.log(tmp);
      dispatch(
        createSelectVoteApi({
          userId: listChose[0]._id,
          votes: tmp,
          reload: voter.voterId === 'all' ? false : true,
        }),
      );
      dispatch(
        setAllNotVote({
          ...allNotVote,
          kind4,
        }),
      );
    }
  };

  const allVoted = useMemo(() => {
    if (
      voter.voterId === 'all' &&
      allNotVote[`kind4`][topic._id] &&
      allNotVote[`kind4`][topic._id].length === 0
    ) {
      return true;
    }
    return false;
  }, [voter, allNotVote, topic._id]);

  // reset all when select "tất cả"
  useEffect(() => {
    if (voter.voterId === 'all') {
      let listVotesTmp = [];
      for (let i in listVotes) {
        listVotesTmp.push({
          ...listVotes[i],
          amount: 0,
        });
      }
      setListVotes(listVotesTmp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voter, allNotVote]);
  useEffect(() => {
    if (
      voter.voterId === 'all' &&
      allNotVote.kind4[topic._id] &&
      allNotVote.kind4[topic._id].length > 0
    ) {
      setIsVoted(false);
    }
  }, [allNotVote, voter, topic._id]);

  // console.log('listVotes', listVotes);

  return (
    <SSelectionItem>
      <div className="top">
        <div className="title">
          {language === 'vi' ? topic.name : topic.name_2}
        </div>
        <div className="vote-container">
          {topic.selections.map((selection, index) => {
            return (
              <RowItem
                onCheck={onCheck}
                onSelectVote={onSelectVote}
                {...{ selection, isVoted, listCheck, allVoted }}
                key={index}
                value={listVotes[index]}
              />
            );
          })}
          {overShares && !isVoted ? (
            <div
              className="error"
              style={{ textAlign: 'center', margin: '10px' }}
            >
              {t('Cổ đông đã bầu quá số phiếu sở hữu!')}
            </div>
          ) : (
            ''
          )}
          {voter.voterId === 'all' &&
          listCheck.length > 0 &&
          sharesLeft >= listCheck.length ? (
            <div
              className="error"
              style={{ textAlign: 'center', margin: '10px' }}
            >
              {t(
                'Tổng số phiếu bầu còn lại là số phiếu của các Cổ đông uỷ quyền không thoả mãn việc chia đều',
              )}
              <br />
              {t(
                'Nếu muốn thực hiện biểu quyết tất cả số phiếu bầu, Cổ đông vui lòng thực hiện biểu quyết cho từng Cổ đông trong danh sách nhận uỷ quyền',
              )}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className="middle">
        <div className="column">
          {t('Tổng số phiếu có thể bầu')}:{' '}
          {transformNumberPrinter(sharesAvailable)}
        </div>
        <div className="column">
          {/* {voter.voterId === 'all' ? (
            ''
          ) : ( */}
          <>
            {t('Tổng số phiếu còn lại')}: {transformNumberPrinter(sharesLeft)}
          </>
          {/* )} */}
        </div>
      </div>
      <div className="bottom">
        <Button
          className={
            listChose[0].invalidVoted
              ? 'disabled-btn'
              : isVoted || allVoted
              ? 'disabled-btn'
              : ''
          }
          // onClick={onSendSelectVote}
          onClick={() => {
            dispatch(setMarkConfirmModal(topic._id));
            dispatch(setToggleConfirmModal(true));
          }}
        >
          {isVoted || allVoted ? t('Đã biểu quyết') : t('Hoàn thành BKP')}
        </Button>
      </div>
      {markModalConfirm === topic._id && (
        <Modal
          isOpen={toggleConfirmModal}
          toggleModal={closeModal}
          title={t('Xác nhận gửi biểu quyết')}
          overwriteModalStyle={{
            maxWidth: '500px',
            zIndex: '200',
          }}
        >
          {toggleConfirmModal && (
            <ModalConfirm
              kind={4}
              onConfirm={onSendSelectVote}
              onRefuse={() => dispatch(setToggleConfirmModal(false))}
            />
          )}
        </Modal>
      )}
    </SSelectionItem>
  );
}

const RowItem = ({
  selection,
  value,
  isVoted,
  listCheck = [],
  onSelectVote,
  onCheck,
  allVoted,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { listChose, voter } = useSelector(
    (state) => state.checkCoupon.checkCouponArtisan,
  );
  const onChangeInput = (e) => {
    if (isVoted) {
      PNotify.error({
        title: t('Lỗi'),
        text: t('Cổ đông đã bầu cử cho nội dung này'),
        destroy: true,
        delay: 2000,
      });
    } else {
      onSelectVote(selection._id, textToNumber(e.target.value));
    }
  };
  return (
    <div className="elector-container">
      <div className="column">
        <span
          style={{ marginRight: '12px' }}
          onClick={() => (!allVoted ? onCheck(selection._id) : null)}
        >
          <Input.Checkbox
            checked={listCheck.indexOf(selection._id) > -1 ? true : false}
          />
        </span>
        <span>
          {selection && selection.selectName
            ? language === 'vi'
              ? selection.selectName.split(' - ')[0]
              : selection.selectName.split(' - ')[1]
            : ''}
        </span>
      </div>
      <div className="column">
        <input
          type="text"
          disabled={
            isVoted ||
            listChose[0].invalidVoted ||
            allVoted ||
            voter.voterId === 'all'
          }
          onChange={voter.voterId !== 'all' && !allVoted ? onChangeInput : null}
          value={
            isVoted === false
              ? value && Number(value.amount) > 0
                ? numberToFormatText(Number(value.amount), language)
                : ''
              : value && Number(value.amount) >= 0
              ? numberToFormatText(Number(value.amount), language)
              : 0
          }
          placeholder={t('Nhập số phiếu bầu')}
        />
      </div>
    </div>
  );
};

const findIndex = (id, arr) => {
  let index = -1;
  for (let i = 0; i < arr.length; i++) {
    if (id === arr[i].id) {
      index = i;
      break;
    }
  }
  return index;
};
