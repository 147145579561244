import Modal, { SModalContent } from '@components/modal';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Form from '../form';

export default function EditModal({
  isEdit,
  isOpen,
  toggleModal,
  title,
  id,
  tableType,
  kind,
  kindToSend,
}) {
  const { tableList } = useSelector((state) => state.admin.initializeReport);

  const [data, setData] = useState(null);

  useEffect(() => {
    if (id && tableList.length > 0 && isOpen) {
      const tmpData = tableList.filter((d) => d._id === id)[0];
      if (tmpData) {
        setData({ ...tmpData });
      }
    }
  }, [isOpen, id, tableList]);

  return (
    <Modal
      {...{ isOpen, toggleModal, title }}
      overwriteModalStyle={{ maxWidth: 900 }}
    >
      <SModalContent>
        <Form
          isEdit={isEdit}
          kindToSend={kindToSend}
          selectedData={data}
          handleCancel={() => toggleModal(false)}
          tableType={tableType}
          kind={kind}
        />
      </SModalContent>
    </Modal>
  );
}
