import styled from 'styled-components';
import { SHotline } from '../hotlinePage';
export const SManageShareholderPage = styled(SHotline)`
  .search-wrapper {
    margin: 0 12px;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .search-item {
      padding: 0 12px;
      button {
        width: 100%;
      }
      margin-bottom: 20px;
    }
  }
`;

export const STableTools = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  flex-wrap: wrap;
  .export-btn {
    width: 155px;
    height: 32px;
    border-radius: 0px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-top: 10px;
    margin-bottom: 5px;
    svg {
      margin-right: 12px;
    }
  }

  .tools-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    /* margin: 20px -8px 10px; */
    margin-top: 10px;
    margin-right: -8px;
    margin-left: -8px;
    button {
      margin: 0 8px 10px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      width: 179px;
      height: 32px;
      padding: 1px 3px;
      color: white;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      border-radius: 0px;
      &.green-btn {
        background: #00cb82;
      }
      &.blue-btn {
        background: #2f80ed;
      }
    }
  }
  @media only screen and(max-width: 850px) {
    display: block;
    .export-btn {
      float: right;
    }
    .tools-container {
      clear: both;
    }
  }
`;

export const SForm = styled.div``;

export const SModalDetail = styled.div`
  color: #718098;

  .head {
    padding: 0 56px;
  }
  .content {
    .vote-details {
      padding-top: 10px;
      max-width: 700px;
      margin-left: auto;
      margin-right: auto;
      padding-bottom: 42px;

      .vote-item {
        background: #dee3f6;
        padding: 13px 15px;
        font-size: 15px;
        margin-bottom: 13px;

        .item-head {
          position: relative;

          .title {
            margin: 0;
            padding-right: 127px;
            padding-left: 100px;
            position: relative;
            line-height: 19px;

            span {
              position: absolute;
              top: 0;
              left: 0;
            }
          }

          .note {
            color: red;
            font-style: italic;
            display: table;
            font-size: 13px;
            margin-bottom: 0;
          }

          .common__btn {
            position: absolute;
            text-transform: unset;
            font-size: 12px;
            width: 104px;
            min-width: unset;
            right: 0px;
            top: 0px;
            padding: 3px 3px;
            white-space: nowrap;
            text-align: center;
          }
        }

        .item-content {
          background: white;
          padding: 9px 26px;
          margin-top: 13px;
          font-size: 13px;
          line-height: 21px;
          position: relative;
          z-index: 1;

          &:after {
            content: '';
            display: block;
            position: absolute;
            width: 20px;
            height: 20px;
            transform: rotate(-70deg) skewX(-45deg);
            background: #fff;
            top: -1px;
            right: 39px;
            z-index: -1;
          }

          .desc {
            margin: 0;
          }
        }
      }

      .item {
        .title {
        }

        .desc {
          margin-bottom: 8px;
          margin-top: -12px;

          span {
          }
        }

        .table-content .table {
          background: #eff3fc;

          .table-scroll table tbody tr td {
            height: auto;
          }
        }
      }
    }
  }
`;

export const SModalVoteAgain = styled.div`
  color: #718098;
  text-align: center;
  padding: 20px 15px 0;
  .confirm-btn {
    margin-top: 30px;
  }
  .content {
    margin-top: 20px;
  }
`;
