import Button from '@components/button';
import ErrorMessage from '@components/input/ErrorMessage';
import Modal from '@components/modal';
import Table from '@components/table';
import {
  VALIDATE_ONLY_NUMBER,
  VALIDATE_PATTERN,
  VALIDATE_REQUIRE,
} from '@constants/validate';
import { SSortModal } from '@styled/admin/initializeReportPage';
import React, { Children, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { sortTableTheads } from '../data';
import {
  getAllReport,
  sortOrderApi,
} from '@duck/pages/admin/initializeReportReducer/actions';
import { useTranslation } from 'react-i18next';

export default function SortModal({
  isOpen,
  toggleModal,
  title,
  tableType,
  kind,
  kindToSend,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [tableList, setTableList] = useState([]);

  useEffect(() => {
    if (isOpen) {
      const fun = async () => {
        const temp = await getAllReport(
          tableType === 'sheet-4' && kindToSend === 6 ? 'sheet-6' : tableType,
        );
        setTableList(temp);
      };
      fun();
    }
  }, [isOpen]);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ shouldUnregister: true });

  const onSubmit = (data) => {
    const dataSort = Object.entries(data).map(([key, value]) => ({
      id: key,
      order: value,
    }));
    dispatch(
      sortOrderApi(
        { dataSort },
        tableType === 'sheet-4' && kindToSend === 6 ? 'sheet-6' : tableType,
        () => toggleModal(false),
        kind,
      ),
    );
  };

  return (
    <Modal {...{ isOpen, toggleModal, title }}>
      <SSortModal>
        <Table header={sortTableTheads} minWidth="0">
          {Children.toArray(
            tableList.map((d) => {
              return (
                <tr>
                  <td>{d && d.name}</td>
                  <td className="center">
                    <input
                      className="order"
                      type="number"
                      {...register(`${d._id}`, {
                        required: t(VALIDATE_REQUIRE),
                        pattern: {
                          value: VALIDATE_ONLY_NUMBER,
                          message: t(VALIDATE_PATTERN),
                        },
                        value: d.order.toString(),
                        validate: (value) =>
                          (!isNaN(Number(value)) && Number(value) > 0) ||
                          t('Thứ tự phải lớn hơn 0'),
                      })}
                    />
                    <ErrorMessage errors={errors} field={`${d._id}`} />
                  </td>
                </tr>
              );
            }),
          )}
        </Table>
        <div className="group-button">
          <Button
            onClick={() => {
              toggleModal(false);
            }}
            type="button"
            style={{ backgroundColor: '#DFE9F5', color: '#718098' }}
          >
            {t('Hủy bỏ')}
          </Button>
          <Button type="button" onClick={handleSubmit(onSubmit)}>
            {t('Lưu')}
          </Button>
        </div>
      </SSortModal>
    </Modal>
  );
}
