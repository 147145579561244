import * as types from './constants';
import * as common from './../../../../common/constants';

var initialState = {
  tableList: [],
  listAuthorized: [],
  checked: false,
  report1: [],
  report2: [],
  report3: [],
  report4: [],
  filter: {
    idCard: '',
    pageSize: common.PAGE_SIZE,
    pageNumber: 1,
    status: 'all',
    joinType: 'all',
    isCheckedIn: 'all',
  },
  conference: null,
  total: 0,
  authorityItem: null,
  infoShareholder: null,
  toggleModal: false,
  listChose: [],
  qrCode: null,
};
var reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_INFO_SHAREHOLDER:
      return { ...state, infoShareholder: action.value };

    case types.GET_LIST:
      return { ...state, tableList: action.value };

    case types.GET_LIST_AUTHORIZED:
      return { ...state, listAuthorized: action.value };

    case types.SET_TOGGLE_MODAL:
      return { ...state, toggleModal: action.value };

    case types.SET_CHOOSE_LIST:
      return { ...state, listChose: action.value };

    case types.GET_AUTHORITY_ITEM:
      return { ...state, authorityItem: action.value };

    case types.GET_TOTAL:
      return { ...state, total: action.value };

    case types.GET_CHECK:
      return { ...state, checked: action.value };

    case types.GET_REPORT1:
      return { ...state, report1: action.value };

    case types.GET_REPORT2:
      return { ...state, report2: action.value };

    case types.GET_REPORT3:
      return { ...state, report3: action.value };

    case types.GET_REPORT4:
      return { ...state, report4: action.value };

    case types.SET_FILTER:
      return { ...state, filter: action.value };

    // case types.GET_ID_CARD:
    //   return {...state, idCard: action.value};

    // case types.GET_STATUS:
    //   return {...state, status: action.value};

    case types.GET_CONFERENCE_INFO:
      return { ...state, conference: action.value };

    case types.GET_QR_CODE:
      return { ...state, qrCode: action.value };

    default:
      return state;
  }
};
export default reducer;
