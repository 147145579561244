
import { combineReducers } from "redux";
import checkCouponArtisan from "./checkCouponArtisanReducer";
import checkCouponResult from "./checkCouponResultReducer";
import checkCouponList from "./checkCouponListReducer";

const rootReducer = combineReducers({
    checkCouponArtisan,
    checkCouponResult,
    checkCouponList
});
export default rootReducer;
