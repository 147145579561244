import styled from 'styled-components';
export const SInvitationManagement = styled.div`
  .section-manage-invitations {
    .title {
      color: #333b6a;
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
      margin-top: 40px;
    }
    .module-manage-invitations {
      .module-content {
        padding: 0 15px;
        padding-top: 27px;

        .invitations-wrap {
          max-width: 1000px;
          margin: 0 auto;

          .button-style,
          .btn-dif {
            margin: 0 10px;
            min-width: 250px;
            height: 35px;
            height: 100%;
            position: relative;
            margin-bottom: 25px;
            font-weight: normal;
            padding: 7px 10px;

            input {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              opacity: 0;
              z-index: 1;
            }
          }

          .common__btn {
            margin: 0 10px;
            margin-bottom: 25px;
            height: 35px;
            padding: 7px 10px;
          }

          .btn-dif {
            height: 35px;

            a {
              all: unset;
              cursor: pointer;
            }

            background-color: white;
            min-width: 270px;
            color: @text;
            font-weight: normal;
            border-radius: 3px;
            border: 1px solid @green;
            position: relative;
            box-shadow: 5px 5px 25px 0 fade(black, 10%);
            line-height: 20px;
            padding: 7px 10px;

            &.disable__btn {
              cursor: not-allowed;
              opacity: 0.9;

              &:hover {
                box-shadow: 5px 5px 25px 0 fade(black, 10%);
              }
            }

            &:hover {
              box-shadow: 2px 2px 5px fade(black, 30%);
            }
          }

          .btn-download {
            display: flex;
            justify-content: center;
            height: 35px;

            .icon {
              margin-right: 15px;
              position: absolute;
              top: 50%;
              left: 59px;
              transform: translateY(-50%);
            }
          }

          .control-filter,
          .filter,
          .handle {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
          }

          .table-wrap {
            position: relative;
            overflow: auto;
            background: @bgr-3;
            color: @text;
            border-top: 3px solid @green;
            padding: 0 15px 15px;
            text-align: center;

            table {
              width: 100%;
              min-width: 590px;
              color: @text;
              text-align: center;

              thead {
                tr > td {
                  font-size: 16px;
                  text-transform: uppercase;
                  padding: 15px 0 10px;
                }
              }

              tbody {
                tr {
                  border: 5px solid @bgr-3;

                  td {
                    border: none;
                    padding: 12px 0 12px;
                    background: #fff;
                    font-size: 14px;
                    text-transform: capitalize;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1232px) {
    .section-manage-invitations {
      .module-manage-invitations {
        .module-content {
          .invitations-wrap {
            .control-filter,
            .filter,
            .handle {
              flex-wrap: wrap;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 991px) {
    .section-manage-invitations {
      .module-manage-invitations {
        .module-content {
          .invitations-wrap {
            .control-filter,
            .filter,
            .handle {
              flex-wrap: wrap;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 479px) {
    .section-manage-invitations {
      .module-manage-invitations {
        .module-content {
          padding: 0px;

          .invitations-wrap {
            .handle {
              display: block;
            }

            .btn-dif {
              min-width: unset;
              margin-left: 0;
              margin-right: 0;
            }

            .common__btn {
              margin-left: 0;
              margin-right: 0;
            }
          }
        }
      }
    }

    .section-conditions {
      .module-conditions {
        .module-content {
          .condition-wrap {
            .button-style {
              max-width: unset;
              width: 100%;
              min-width: unset;
            }
          }
        }
      }
    }
  }
`;

export const SModal = styled.div`
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #718098;
`;
