import i18n from 'i18n';
import { useSelector } from 'react-redux';

export const useVoteConfig = () => {
  const config = useSelector(
    (state) => state?.admin?.configSystem?.info.voteSelections || [],
  );

  const getVoteLabel = (point) => {
    const lang = i18n.language || 'vi';
    return config.find((c) => c.point == point && c.lang == lang)?.displayName;
  };
  return { getVoteLabel };
};
