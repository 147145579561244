import { combineReducers } from "redux";
import secretaryResolution from "./secretaryResolutionReducer";
import secretaryCommentList from "./secretaryCommentListReducer";
import secretaryAuthorityList from "./secretaryAuthorityListReducer";
import secretaryReport from "./secretaryReportReducer";



const rootReducer = combineReducers({
    secretaryResolution,
    secretaryCommentList,
    secretaryAuthorityList,
    secretaryReport
});
export default rootReducer;
