import styled from 'styled-components';
import { SHotline } from './hotlinePage';
import Button from '../../../components/bases/shared/button';
export const SShareHolderOpinion = styled(SHotline)`
  .module-content {
    margin: 0;
  }
  .search-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    justify-content: space-between;
    margin: 0px;
    gap: 10px 20px;
    .search-item {
      padding: 0px;
      width: 100%;
      &.search-button {
        width: 100%;
        min-width: 150px;
        button {
          border-radius: 0;
        }
      }
    }
  }
  .table-container {
    .handle-wrapper {
      display: flex;
      justify-content: left;
      flex-wrap: wrap;
      align-items: center;
      gap: 16px;
      padding: 20px 0 10px;
      .list-handle {
        margin: 4px 0 0 0;
        .btn__hand {
          margin: 0 0 0 5px;
        }
      }
      .list-export {
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 16px;
        button {
          justify-content: center;
          overflow: hidden;
          margin: 0;
          height: 32px;
          margin-top: 5px;
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
          padding: 0 5px;
          display: flex;
          align-items: center;
          max-width: 95%;
          width: 266px;
          border: 1px solid #00cb82;
          background: #ffffff;
          border-radius: 0px;
          color: #718098;
          svg {
            margin-right: 13px;
            path {
              fill: #00cb82;
            }
          }

          @media only screen and (max-width: 578px) {
            font-size: 13px;
            button {
              width: 255px;
            }
          }
        }
      }
      @media only screen and (max-width: 965px) {
        .list-handle {
          order: 2;
        }
        .list-export {
          order: 1;
        }
      }
    }
  }
  @media only screen and (max-width: 1500px) {
    .search-wrapper {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
  @media only screen and (max-width: 1250px) {
    .search-wrapper {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  @media only screen and (max-width: 991px) {
    .search-wrapper {
      grid-template-columns: 1fr 1fr;
    }
  }
  @media only screen and (max-width: 570px) {
    .search-wrapper {
      grid-template-columns: 1fr;
    }
  }
`;

export const SModalBlock = styled.div`
  margin: 20px 15px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > p {
    color: #333b6a;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    margin: 44px 0px 32px;
  }

  .group-button {
    button {
      &.cancel-button {
        color: #718098;
        background: #dfe9f5;
      }
      &.delete-button {
        background: #ff6c6c;
        color: white;
      }
    }

    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px -8px;
    ${Button} {
      margin: 0 8px 8px;
      flex-grow: 1;
    }
  }
  @media only screen and (max-width: 500px) {
    & > p {
      font-size: 17px;
    }
  }
`;

export const SChatbox = styled.div`
  z-index: 10;
  background-color: white;
  height: 446px;
  max-height: 90%;
  position: fixed;
  bottom: 0;
  right: 12px;
  width: 386px;
  max-width: 90%;
  border: 1px solid #9ab1cc;
  box-sizing: border-box;
  transition: all 0.7s;
  &.hidden {
    bottom: -400px;
  }

  .close {
    z-index: 6;
    width: 24px;
    height: 24px;
    background: #ff6c6c;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    border-radius: 50%;
    cursor: pointer;
  }
  .header {
    position: relative;
    z-index: 5;
    background: #00cb82;
    color: white;
    padding: 7px 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .body {
    max-width: 100%;
    position: absolute;
    bottom: 56px;
    left: 0;
    /* height: 100%; */
    height: calc(100% - 100px);
    padding: 5px 20px;
    overflow: auto;
    /* margin-bottom: 56px; */
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column; */
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #dfe9f5;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: transparent;
    }
  }
  .send-message {
    background: #eaf1fa;
    padding: 12px 16px;
    display: flex;
    /* clear:both; */
    display: absolute;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    .text-area {
      position: relative;
      width: 306px;
      /* max-width: 80%; */
      .send-button {
        box-shadow: none;
        position: absolute;
        cursor: pointer;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        background-color: transparent;
      }
      input {
        width: 100%;
        height: 32px;
        margin: 0;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        border: 1px solid #dfe9f5;
        &::placeholder {
          font-style: unset;
        }
      }
    }
  }
`;

export const SAvatar = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const SMessage = styled.div`
  /* width: 100%; */
  margin: 16px 0px;
  clear: both;
  /* float: ${(props) => (props.from === 'admin' ? 'right' : 'left')}; */
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: ${(props) =>
    props.from === 'admin' ? 'row-reverse' : 'row'};

  .text-wrapper {
    .text {
      float: ${(props) => (props.from === 'admin' ? 'right' : 'left')};
      text-align: ${(props) => (props.from === 'admin' ? 'right' : 'left')};
      text-align: ${(props) => (props.from === 'admin' ? 'right' : 'left')};

      border-radius: 8px;
      border-top-right-radius: ${(props) =>
        props.from === 'admin' ? '0' : '8px'};
      border-top-left-radius: ${(props) =>
        props.from === 'admin' ? '8px' : '0'};
      max-width: 100%;
      background: #eaf1fa;
      padding: 12px 15px;
      .time {
        margin-top: 2px;
        font-size: 10px;
        font-weight: 400;
        line-height: 16px;
        color: #a6b0cf;
      }
    }
    width: 260px;
    margin: 0 8px;
    max-width: 80%;

    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #333b6a;
  }
`;

export const SDetailOpinion = styled.div`
  width: 100%;
`;
