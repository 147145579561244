export const URL = 'info/authority'; // /info/authority/:pageSize/:pageNumber
export const URL_GET_BY_FILTER = 'info/authority-statistic';
export const URL_EDIT = 'authority/edit'; //{ name, phone, email, address, password }
export const URL_DELETE = 'authority/delete';
export const URL_CREATE = 'authority/import';
export const URL_HANDLE_AUTHORITY = 'authority/handler';
export const URL_DOWNLOAD = 'authority/import-template';
export const URL_EXPPORT_AUTHO = 'info/authority/export';

export const GET_LIST = 'GET_AUTHORITY_LIST_LIST';
export const SET_CHOOSE_LIST = 'SET_AUTHORITY_LIST_CHOOSE_LIST';
export const SET_FILTER = 'SET_AUTHORITY_LIST_FILTER';
export const SET_TOTAL = 'SET_AUTHORITY_TOTAL';
export const SET_SENDING = 'SET_SHAREHOLDER_STATUS_SENDING';
