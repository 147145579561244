import * as constants from './constants';
import { callApi, callExportApi, callDownloadApi } from '../../../../common';
import * as common from '../../../../common/constants';
import PNotify from 'pnotify/dist/es/PNotify';
import { handleError } from '@configs/handleError';

export const getUserListApi = (data) => {
  const { pageSize, pageNumber, search } = data;
  return (dispatch) => {
    if (search === '' || search === null || search === undefined)
      callApi(
        `${constants.URL_GET_SHARE_ACCOUNT}/${pageSize}/${pageNumber}`,
        'GET',
        {
          search,
        },
      )
        .then((res) => {
          dispatch(getListAccount(res.data.data.data));
          dispatch(setTotal(res.data.data.total));
        })
        .catch(handleError);
    else
      callApi(
        `${constants.URL_GET_SHARE_ACCOUNT}/${pageSize}/${pageNumber}`,
        'GET',
        {
          search,
        },
      )
        .then((res) => {
          dispatch(getListAccount(res.data.data.data));
          dispatch(setTotal(res.data.data.total));
        })
        .catch(handleError);
  };
};

export const getEditApi = ({ id, body, filter }) => {
  return (dispatch) =>
    callApi(`${constants.URL_EDIT}/${id}`, 'POST', body)
      .then(function (res) {
        setTimeout(() => {
          dispatch(getUserListApi(filter));
        }, 500);
        PNotify.success({
          title: 'Thành công',
          text: 'Sửa thành công',
          destroy: true,
          delay: 2000,
        });
      })
      .catch(handleError);
};

export const getDeleteApi = (ids, filter) => {
  const filterNew = {
    ...filter,
    pageNumber: 1,
  };
  return (dispatch) => {
    callApi(`${constants.URL_DELETE}`, 'DELETE', { ids })
      .then((res) => {
        setTimeout(() => {
          dispatch(setFilter(filterNew));
          dispatch(getUserListApi(filterNew));
        }, 200);
        PNotify.success({
          title: 'Thành công',
          text: 'Xóa tài khoản khách mời thành công',
          destroy: true,
          delay: 2000,
        });
      })
      .catch(handleError);
  };
};

export const getInfoSettingAuthorityApi = () => {
  return (dispatch) =>
    callApi(`${constants.URL_INFO}`, 'GET', null)
      .then(function (res) {
        dispatch(setInfo(res.data.data.data));
      })
      .catch(function (err) {
        if (err) {
          if (err.response && err.response.data && err.response.data.message) {
            PNotify.error({
              title: 'Lỗi',
              text: err.response.data.message,
              destroy: true,
              delay: 2000,
            });
          } else if (err.response) {
            PNotify.error({
              title: 'Lỗi',
              text: err.response.statusText,
              destroy: true,
              delay: 2000,
            });
          }
        }
      });
};

export const getHandleAuthorityApi = (id, action, filter) => {
  return (dispatch) =>
    callApi(`${constants.URL_HANDLE_AUTHORITY}`, 'POST', {
      action,
      authorityIds: [id],
    })
      .then(function (res) {
        setTimeout(() => {
          dispatch(getUserListApi(filter));
          dispatch(setChooseList([]));
        }, 200);
        PNotify.success({
          title: 'Thành công',
          text: 'Xử lý thành công',
          destroy: true,
          delay: 2000,
        });
      })
      .catch(handleError);
};

export const getListAccount = (value) => {
  return {
    type: constants.GET_LIST,
    value,
  };
};

export const settingAuthorityApi = (body, onSuccess) => {
  return () =>
    callApi(`${constants.URL_SETTING_AUTHORITY}`, 'POST', body)
      .then(function (res) {
        setTimeout(() => {
          onSuccess && onSuccess();
        }, 200);
        PNotify.success({
          title: 'Thành công',
          text: 'Xử lý thành công',
          destroy: true,
          delay: 2000,
        });
      })
      .catch(function (err) {
        if (err) {
          if (err.response && err.response.data && err.response.data.message) {
            PNotify.error({
              title: 'Lỗi',
              text: err.response.data.message,
              destroy: true,
              delay: 2000,
            });
          } else if (err.response) {
            PNotify.error({
              title: 'Lỗi',
              text: err.response.statusText,
              destroy: true,
              delay: 2000,
            });
          }
        }
      });
};

export const setSending = (value) => {
  return {
    type: constants.SET_SENDING,
    value,
  };
};

const setInfo = (value) => {
  return {
    type: constants.SET_INFO,
    value,
  };
};

export const setChooseList = (value) => {
  return {
    type: constants.SET_CHOOSE_LIST,
    value,
  };
};
export const setFilter = (value) => {
  return {
    type: constants.SET_FILTER,
    value,
  };
};
export const setTotal = (value) => {
  return {
    type: constants.SET_TOTAL,
    value,
  };
};
