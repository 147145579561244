import DatePicker from 'react-datepicker';
import React from 'react';
import { Controller } from 'react-hook-form';
import Wrapper from './Wrapper';
import inputIcons from '../icons/input';

const DateWithWrapper = (props) => {
  const {
    datepickerProps = {},
    wrapperProps,
    name,
    control,
    watch,
    rules,
    defaultValue = null,
  } = props;

  const watchValue = watch(`${name}`);
  const isLabelOnTop = watchValue;

  return (
    <Wrapper
      {...wrapperProps}
      icon={inputIcons.date}
      isLabelOnTop={isLabelOnTop}
      style={{ zIndex: '999' }}
    >
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={({ field: { onChange, value }, ...rest }) => (
          <DatePicker
            {...rest}
            {...datepickerProps}
            onChange={onChange}
            selected={value}
            placeholder=""
            dateFormat="dd/MM/yyyy"
          />
        )}
      />{' '}
    </Wrapper>
  );
};

export default DateWithWrapper;
