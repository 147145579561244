import React from 'react';
import styled from 'styled-components';
import { ErrorMessage } from '@hookform/error-message';

export default function ErrorMessageComponent({ errors, field }) {
  return (
    <ErrorMessage
      errors={errors}
      name={field}
      render={({ message }) => <SErrorMessage>{message}</SErrorMessage>}
    />
  );
}

export const SErrorMessage = styled.p`
  text-align: right;
  font-size: 12px;
  color: #ff4b4b;
  font-weight: 500;
  margin-top: 4px;
  margin-bottom: 0;
`;
