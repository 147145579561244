import {SDashboardView} from '@styled/checkIn/checkInManagerPage';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {transformNumberPrinter} from './../../../bases/configs';
import {useTranslation} from 'react-i18next';

const CheckInReport = () => {
  const statistic = useSelector(
    (state) => state.checkin.inspectionRecords.statistic,
  );
  const {t} = useTranslation();

  // console.log("statistic: ", statistic);
  const totalShares = statistic? statistic.totalShares : null;

  const [percent1, setPercent1] = useState(0);
  const [percent2, setPercent2] = useState(0);
  const [percent3, setPercent3] = useState(0);
  const [percent4, setPercent4] = useState(0);

  useEffect(() => {
    statistic && statistic.totalSharesCheckin && totalShares
      ? setPercent1((statistic.totalSharesCheckin / totalShares) * 100)
      : setPercent1(0);

    statistic && statistic.totalSharesJoinedVotedOnline && totalShares
      ? setPercent2(
          (statistic.totalSharesJoinedVotedOnline / totalShares) * 100,
        )
      : setPercent2(0);

    statistic && statistic.totalSharesJoinedVotedLive && totalShares
      ? setPercent3((statistic.totalSharesJoinedVotedLive / totalShares) * 100)
      : setPercent3(0);

    statistic && statistic.totalSharesJoinedVotedAuthority && totalShares
      ? setPercent4(
          (statistic.totalSharesJoinedVotedAuthority / totalShares) * 100,
        )
      : setPercent4(0);
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statistic]);

  return (
    <SDashboardView>
      <div className="bs-row row-md-10 row-sm-5 row-tn-10">
        <div className="bs-col md-25-10 sm-25-5 xs-50-10 tn-100-10">
          <div className="dashboard-item dashboard_item_blue">
            <div className="dashboard-item-lit">
            <p className="title">{t('Tổng số CĐ')}:</p>
              <p className="value">
                {transformNumberPrinter(
                  statistic
                    ? statistic.totalJoinedVotedOnline +
                        statistic.totalJoinedVotedAuthority +
                        statistic.totalJoinedVotedLive
                    : 0,
                )}{' '}
              </p>
            </div>
            <div className="dashboard-item-lit">
            <p className="title">{t('Tổng số CP')}:</p>
              <p className="value">
                {transformNumberPrinter(
                  statistic
                    ? statistic.totalSharesJoinedVotedOnline +
                        statistic.totalSharesJoinedVotedAuthority +
                        statistic.totalSharesJoinedVotedLive
                    : 0,
                )}
              </p>
            </div>
            <div className="dashboard-item-lit">
              <p className="title">{t('Tỷ lệ')}:</p>
              <p className="value">
                {parseFloat(percent1.toFixed(5).toString()) > 0
                  ? parseFloat(percent1.toFixed(5).toString())
                  : '0'}{' '}
                %
              </p>
            </div>
          </div>
        </div>

        <div className="bs-col md-25-10 sm-25-5 xs-50-10 tn-100-10">
          <div className="dashboard-item dashboard_item_orange">
            <div className="dashboard-item-lit">
            <p className="title">{t('CĐ tham dự online')}:</p>
              <p className="value">
                {transformNumberPrinter(
                  statistic && statistic.totalJoinedVotedOnline
                    ? statistic.totalJoinedVotedOnline
                    : 0,
                )}
              </p>
            </div>
            <div className="dashboard-item-lit">
              <p className="title">{t('Số lượng CP')}:</p>
              <p className="value">
                {transformNumberPrinter(
                  statistic && statistic.totalSharesJoinedVotedOnline
                    ? statistic.totalSharesJoinedVotedOnline
                    : 0,
                )}
              </p>
            </div>
            <div className="dashboard-item-lit">
              <p className="title">{t('Tỷ lệ')}:</p>
              <p className="value">
                {parseFloat(percent2.toFixed(5).toString()) > 0
                  ? parseFloat(percent2.toFixed(5).toString())
                  : '0'}{' '}
                %
              </p>
            </div>
          </div>
        </div>

        <div className="bs-col md-25-10 sm-25-5 xs-50-10 tn-100-10">
          <div className="dashboard-item dashboard_item_pink">
            <div className="dashboard-item-lit">
            <p className="title">{t('CĐ tham dự trực tiếp')}:</p>
              <p className="value">
                {transformNumberPrinter(
                  statistic && statistic.totalJoinedVotedLive
                    ? statistic.totalJoinedVotedLive
                    : 0,
                )}
              </p>
            </div>
            <div className="dashboard-item-lit">
              <p className="title">{t('Số lượng CP')}:</p>
              <p className="value">
                {transformNumberPrinter(
                  statistic && statistic.totalSharesJoinedVotedLive
                    ? statistic.totalSharesJoinedVotedLive
                    : 0,
                )}
              </p>
            </div>
            <div className="dashboard-item-lit">
              <p className="title">{t('Tỷ lệ')}:</p>
              <p className="value">
                {parseFloat(percent3.toFixed(5).toString()) > 0
                  ? parseFloat(percent3.toFixed(5).toString())
                  : '0'}{' '}
                %
              </p>
            </div>
          </div>
        </div>

        <div className="bs-col md-25-10 sm-25-5 xs-50-10 tn-100-10">
          <div className="dashboard-item dashboard_item_green">
            <div className="dashboard-item-lit">
            <p className="title">{t('CĐ ủy quyền')}:</p>
              <p className="value">
                {transformNumberPrinter(
                  statistic && statistic.totalJoinedVotedAuthority
                    ? statistic.totalJoinedVotedAuthority
                    : 0,
                )}
              </p>
            </div>
            <div className="dashboard-item-lit">
              <p className="title">{t('Số lượng CP')}:</p>
              <p className="value">
                {transformNumberPrinter(
                  statistic && statistic.totalSharesJoinedVotedAuthority
                    ? statistic.totalSharesJoinedVotedAuthority
                    : 0,
                )}
              </p>
            </div>
            <div className="dashboard-item-lit">
              <p className="title">{t('Tỷ lệ')}:</p>
              <p className="value">
                {parseFloat(percent4.toFixed(5).toString()) > 0
                  ? parseFloat(percent4.toFixed(5).toString())
                  : '0'}{' '}
                %
              </p>
            </div>
          </div>
        </div>
      </div>
    </SDashboardView>
  );
};

export default CheckInReport;
