import React, { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import { useSelector, useDispatch } from 'react-redux';
// import TimePicker from 'react-time-picker';
import vi from 'date-fns/locale/vi';
import { useForm } from 'react-hook-form';
import {
  setTimeApi,
  setToggleTopic2Api,
  getListTopic2Api,
  setToggleTopicApi,
  setToggleCommentApi,
  setTimeCommentApi,
  setTimeConferenceApi,
  getListTopic1Api,
  getListTopic3Api,
  getListTopic4Api,
  setToggleTopic4Api,
  setToggleTopic3Api,
  setToggleTopic1Api,
  setToggleTopic6Api,
  getListTopic6Api,
} from '@duck/pages/admin/timeVoteReducer/actions';
import { STable, STimeItem, SwitchContent } from '@styled/admin/timeVotePage';

import Table from '@components/table';
import Input from '@components/input';
import { useTranslation } from 'react-i18next';
// import {VALIDATE_REQUIRE} from '@constants/validate';

registerLocale('vi', vi);

const formatDate = (date) => {
  if (date) {
    const d = new Date(date);
    const dtf = new Intl.DateTimeFormat('en', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    const [{ value: mo }, , { value: da }, , { value: ye }] =
      dtf.formatToParts(d);
    return `${ye}-${mo}-${da}`;
  }
  return '';
};
const formatTime = (date) => {
  if (date) {
    const d = new Date(date);
    const dtf = new Intl.DateTimeFormat('en', {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false,
    });
    const [{ value: hour }, , { value: minute }] = dtf.formatToParts(d);
    return `${hour >= 24 ? hour - 24 : hour}:${minute}`;
  }
  return '';
};

const TimeItem = ({ type, title, isAdvanced }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  //   const info = useSelector((state) => state.admin.timeVote.info);
  const topic = useSelector((state) => state.admin.timeVote.topicPart);
  const { timeSetting } = useSelector((state) => state.admin.timeVote);
  // const { topic1, topic2, topic3, topic4} = useSelector(
  //   (state) => state.admin.timeVote,
  // );
  const timeSettingInfo = timeSetting.filter(
    (item) => item.kind === type && (item.startDate || item.endDate),
  )[0];

  // const [startDate, setStartDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(new Date());
  // const [startTime, setStartTime] = useState('00:00');
  // const [endTime, setEndTime] = useState('00:00');

  const [validateTime, setValidateTime] = useState(true);
  const [showTable, setShowTable] = useState(false);

  const { handleSubmit, control, setValue, watch } = useForm();

  const timeSubmitComment = useSelector(
    (state) => state.admin.timeVote.info.timeSubmitComment,
  );
  const timeConference = useSelector(
    (state) => state.admin.timeVote.timeConference,
  );

  useEffect(() => {
    if (timeSettingInfo && type !== 5 && type !== 0) {
      setValue('startDate', new Date(timeSettingInfo.startDate || new Date()));
      setValue('endDate', new Date(timeSettingInfo.endDate || new Date()));

      setValue(
        'startTime',
        formatTime(new Date(timeSettingInfo.startDate || Date.now())),
      );
      setValue(
        'endTime',
        formatTime(new Date(timeSettingInfo.endDate || Date.now())),
      );
      // setStartDate(new Date(timeSettingInfo.startDate || new Date()));
      // setEndDate(new Date(timeSettingInfo.endDate || new Date()));
      // setStartTime(formatTime(timeSettingInfo.startDate || new Date()));
      // setEndTime(formatTime(timeSettingInfo.endDate || new Date()));
      if (topic[`kind${type}`]) {
        let timeEnd = new Date(timeSettingInfo.endDate).getTime();
        let timeNow = new Date().getTime();
        let t = setInterval(() => {
          timeNow += 1000;
          if (timeNow >= timeEnd) {
            dispatch(
              setToggleTopicApi({
                kind: type.toString(),
                toggleType: 'close',
              }),
            );
            clearInterval(t);
          }
        }, 1000);
      }
    }
  }, [dispatch, timeSettingInfo, type, topic, setValue]);

  useEffect(() => {
    if (timeSubmitComment && type === 5) {
      setValue('startDate', new Date(timeSubmitComment.start || new Date()));
      setValue('endDate', new Date(timeSubmitComment.end || new Date()));
      setValue(
        'startTime',
        formatTime(new Date(timeSubmitComment.start || Date.now())),
      );
      setValue(
        'endTime',
        formatTime(new Date(timeSubmitComment.end || Date.now())),
      );
      // setStartDate(new Date(timeSubmitComment.start || new Date()));
      // setEndDate(new Date(timeSubmitComment.end || new Date()));
      // setStartTime(formatTime(timeSubmitComment.start || new Date()));
      // setEndTime(formatTime(timeSubmitComment.end || new Date()));
      if (timeSubmitComment && timeSubmitComment.isOpen) {
        let timeEnd = new Date(timeSubmitComment.end).getTime();
        let timeNow = new Date().getTime();
        let t = setInterval(() => {
          timeNow += 1000;
          if (timeNow >= timeEnd) {
            dispatch(setToggleCommentApi(false));
            clearInterval(t);
          }
        }, 1000);
      }
    }
  }, [dispatch, timeSubmitComment, type, setValue]);
  useEffect(() => {
    if (timeConference && type === 0) {
      setValue('startDate', new Date(timeConference.startDate || new Date()));
      setValue('endDate', new Date(timeConference.endDate || new Date()));
      setValue(
        'startTime',
        formatTime(new Date(timeConference.startDate || new Date())),
      );
      setValue(
        'endTime',
        formatTime(new Date(timeConference.endDate || new Date())),
      );
      // setStartDate(new Date(timeSubmitComment.start || new Date()));
      // setEndDate(new Date(timeSubmitComment.end || new Date()));
      // setStartTime(formatTime(timeConference.startDate || new Date()));
      // setEndTime(formatTime(timeConference.endDate || new Date()));
      // if (timeSubmitComment && timeSubmitComment.isOpen) {
      //   let timeEnd = new Date(timeSubmitComment.end).getTime();
      //   let timeNow = new Date().getTime();
      //   let t = setInterval(() => {
      //     timeNow += 1000;
      //     if (timeNow >= timeEnd) {
      //       dispatch(setToggleCommentApi(false));
      //       clearInterval(t);
      //     }
      //   }, 1000);
      // }
    }
  }, [dispatch, timeConference, type, setValue]);

  const onSubmit = (data) => {
    // dispatch(
    //   setTimeApi({
    //     kind: 3,
    //     startDate: '2021-11-22T01:44:00.000Z',
    //     endDate: '2021-11-25T01:44:00.000Z',
    //   }),
    // );
    // console.log(data);
    const { startTime, startDate, endTime, endDate } = data;
    if (
      new Date(formatDate(endDate) + ' ' + endTime).getTime() -
        new Date(formatDate(startDate) + ' ' + startTime).getTime() >=
      0
    ) {
      setValidateTime(true);
      if (type === 0) {
        dispatch(
          setTimeConferenceApi({
            startDate: new Date(
              formatDate(startDate) + ' ' + startTime,
            ).toISOString(),
            endDate: new Date(
              formatDate(endDate) + ' ' + endTime,
            ).toISOString(),
          }),
        );
      } else if (type !== 5) {
        dispatch(
          setTimeApi({
            kind: type,
            startDate: new Date(
              formatDate(startDate) + ' ' + startTime,
            ).toISOString(),
            endDate: new Date(
              formatDate(endDate) + ' ' + endTime,
            ).toISOString(),
          }),
        );
      } else {
        dispatch(
          setTimeCommentApi({
            startTime: new Date(
              formatDate(startDate) + ' ' + startTime,
            ).toISOString(),
            endTime: new Date(
              formatDate(endDate) + ' ' + endTime,
            ).toISOString(),
          }),
        );
      }
    } else {
      setValidateTime(false);
    }
  };

  const switchTogleTopic = async () => {
    if (Number(type) === 5) {
      if (timeSubmitComment !== null && timeSubmitComment !== undefined) {
        await dispatch(setToggleCommentApi(!timeSubmitComment.isOpen));
      } else await dispatch(setToggleCommentApi(true));
    } else {
      await dispatch(
        setToggleTopicApi({
          kind: type.toString(),
          toggleType: topic[`kind${type}`] ? 'close' : 'open',
        }),
      );
    }

    // if (type === 1) {
    //   for (let i = 0; i < topic1.length; i++) {
    //     if (topic1[i].isOpen === topic[`kind1`]) {
    //       await dispatch(setToggleTopic1Api(topic1[i]));
    //     }
    //   }
    // }
    // if (type === 2) {
    //   for (let i = 0; i < topic2.length; i++) {
    //     if (topic2[i].isOpen === topic[`kind2`]) {
    //       await dispatch(setToggleTopic2Api(topic2[i]));
    //     }
    //   }
    // }
    // if (type === 3) {
    //   for (let i = 0; i < topic3.length; i++) {
    //     if (topic3[i].isOpen === topic[`kind3`]) {
    //       await dispatch(setToggleTopic3Api(topic3[i]));
    //     }
    //   }
    // }
    // if (type === 4) {
    //   for (let i = 0; i < topic4.length; i++) {
    //     if (topic4[i].isOpen === topic[`kind4`]) {
    //       await dispatch(setToggleTopic4Api(topic4[i]));
    //     }
    //   }
    // }
  };

  useEffect(() => {
    if (type === 1) {
      dispatch(getListTopic1Api());
    }
    if (type === 2) {
      dispatch(getListTopic2Api());
    }
    if (type === 3) {
      dispatch(getListTopic3Api());
    }
    if (type === 4) {
      dispatch(getListTopic4Api());
    }
    if (type === 6) {
      dispatch(getListTopic6Api());
    }
  }, [dispatch, type]);

  return (
    <STimeItem>
      <div className="head">
        <p className="title">{title}</p>
        {type !== 0 && (
          // {type !== 0 && type !== 4 && (
          <SwitchItem
            onClick={switchTogleTopic}
            status={
              type === 5 && timeSubmitComment
                ? timeSubmitComment.isOpen
                : topic[`kind${type}`]
            }
          />
        )}
      </div>

      <div className="content">
        <div className="time-setting">
          <form className="form">
            <div className="bs-row row-xs-10">
              <div className="bs-col sm-50-10 xs-100-10">
                <div className="item">
                  <Input.Time
                    name="startTime"
                    {...{ control, setValue, watch }}
                    wrapperProps={{
                      label: t('Giờ bắt đầu'),
                    }}
                  />
                </div>
              </div>

              <div className="bs-col sm-50-10 xs-100-10">
                <div className="item">
                  <Input.Date
                    name={'startDate'}
                    {...{ control, setValue, watch }}
                    wrapperProps={{
                      label: t('Ngày bắt đầu'),
                    }}
                  />
                </div>
              </div>

              <div className="bs-col sm-50-10 xs-100-10">
                <div className="item">
                  <Input.Time
                    name="endTime"
                    {...{ control, setValue, watch }}
                    wrapperProps={{
                      label: t('Giờ kết thúc'),
                    }}
                  />
                </div>
              </div>

              <div className="bs-col sm-50-10 xs-100-10">
                <div className="item">
                  <Input.Date
                    name={'endDate'}
                    {...{ control, setValue, watch }}
                    wrapperProps={{
                      label: t('Ngày kết thúc'),
                    }}
                  />
                </div>
              </div>
            </div>
          </form>

          <div className="button-content">
            <button
              className="time__btn save__btn"
              onClick={handleSubmit(onSubmit)}
            >
              <i className="fas fa-save"></i>
              <div className="btn_label">
                <span>{t('Lưu')}</span>
              </div>
            </button>

            {isAdvanced === true && (
              <button
                className={`time__btn show__btn ${showTable ? 'active' : ''}`}
                onClick={() => setShowTable(!showTable)}
              >
                <div className="icon1">
                  <svg
                    width="14"
                    height="9"
                    viewBox="0 0 14 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M7 9L13.9282 0H0.0717969L7 9Z" fill="#9AB1CC" />
                  </svg>
                </div>
                <div className="icon2">
                  <svg
                    width="14"
                    height="9"
                    viewBox="0 0 14 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M7 9L13.9282 0H0.0717969L7 9Z" fill="#00CB82" />
                  </svg>
                </div>
                <div className="btn_label">
                  <span>{t('Nâng cao')}</span>
                </div>
              </button>
            )}
          </div>
        </div>
        {!validateTime ? (
          <span className="error">
            {t(
              'Thời gian bắt đầu phải trước thời gian kết thúc và phải sau thời gian hiện tại',
            )}
          </span>
        ) : (
          ''
        )}
        {showTable && (
          <div className="time-table">
            <TableItem type={type} />
          </div>
        )}
      </div>
    </STimeItem>
  );
};

export default TimeItem;

const SwitchItem = ({ onClick, status }) => {
  const [switchValue, setSwitchValue] = useState(false);

  useEffect(() => {
    setSwitchValue(status);
  }, [status]);

  return (
    <SwitchContent onClick={onClick}>
      <span
        className={`switch ${switchValue ? 'on' : ''}`}
        onClick={() => setSwitchValue(!switchValue)}
      ></span>
      {/* <span className="switch__title">{switchValue ? 'Đóng' : 'Mở'}</span> */}
    </SwitchContent>
  );
};

const TableItem = ({ type }) => {
  const theads = [
    { name: 'STT' },
    { name: 'Tên tờ trình' },
    { name: 'Thứ tự' },
    { name: 'Xử lý' },
  ];

  const { t, i18n } = useTranslation();

  const { topic6, topic4, topic2, topic3, topic1 } = useSelector(
    (state) => state.admin.timeVote,
  );

  const [listToShow, setListToShow] = useState({});

  useEffect(() => {
    if (type === 1) {
      setListToShow(topic1);
    } else if (type === 2) {
      setListToShow(topic2);
    } else if (type === 3) {
      setListToShow(topic3);
    } else if (type === 4) {
      setListToShow(topic4);
    } else {
      setListToShow(topic6);
    }
  }, [type, topic1, topic2, topic3, topic4, topic6]);

  const dispatch = useDispatch();

  const switchFn = (item) => {
    if (type === 1) {
      dispatch(setToggleTopic1Api(item));
    } else if (type === 2) {
      dispatch(setToggleTopic2Api(item));
    } else if (type === 3) {
      dispatch(setToggleTopic3Api(item));
    } else if (type === 4) {
      dispatch(setToggleTopic4Api(item));
    } else {
      dispatch(setToggleTopic6Api(item));
    }
  };

  return (
    <STable>
      <Table header={theads} minWidth="500px">
        {listToShow.length > 0 ? (
          listToShow.map((item, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{i18n.language === 'vi' ? item.name : item.name_2}</td>
                <td>{item.order}</td>
                <td className="last_td">
                  <SwitchItem
                    status={item.isOpen}
                    onClick={() => switchFn(item)}
                  />
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan="6">{t('Không có dữ liệu')}</td>
          </tr>
        )}
      </Table>
    </STable>
  );
};
