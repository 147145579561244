import * as constants from './constants';
import { callApi, callDownloadApi, callDownloadPdfApi } from '../../../../common';
import PNotify from "pnotify/dist/es/PNotify";
import { getInfoConferenceApi } from '@duck/pages/admin/shareholderListReducer/actions';
var FileSaver = require('file-saver');


export const getUserApi = (body) => {
    const {identityNumber, joinType, status, pageSize, pageNumber, internal} = body
    return dispatch =>  callApi(`${constants.URL}/${pageSize}/${pageNumber}`, "POST", 
    {
        identityNumber, joinType, status, internal
    }).then(function(res){
        dispatch(getListAccount(res.data.data.shareholders));
        dispatch(setTotal(res.data.data.total));
        dispatch(getAddressByIdApi());
    });
}
export const getInformationConferenceApi = () => {
    return dispatch =>  callApi(`${constants.URL_INFORMATION_CONFERENCE}`, "GET", null).then(function(res){
      dispatch(getInfo(res.data.conference))
    });
}
export const confirmInvitationApi = () => {
    return dispatch =>  callApi(`${constants.URL_CONFIRM_INVITATION}`, "POST", null).then(function(res){
        dispatch(getInformationConferenceApi());
        dispatch(setToggleModal(false));
        PNotify.success({
            title: "Thành công",
            text: "Đóng chức năng tải thư mời thành công",
            destroy: true,
            delay: 2000
          });
        dispatch(getInfoConferenceApi());
    }).catch(function(err) {
        if (err) {
          if (
            err.response &&
            err.response.data &&
            err.response.data.message
          ) {
            PNotify.error({
              title: "Lỗi",
              text: err.response.data.message,
              destroy: true,
              delay: 2000
            });
          } else if (err.response) {
            PNotify.error({
              title: "Lỗi",
              text: err.response.statusText,
              destroy: true,
              delay: 2000
            });
          }
        }
      });
}


export const getFilterUserApi = (filter) => {
    return dispatch =>  callApi(`${constants.URL_FILTER_INTERNAL}`, "POST", null).then(function(res){
        dispatch(setFilter({ ...filter, internal: "notInternal" }));
        dispatch(setInvitationType("notInternal"))
    });
}
export const getAddressByIdApi = () => {
    return dispatch =>  callApi(`${constants.URL_ADDRESS_BY_ID}`, "GET", null).then(function(res){
        dispatch(getAddressList(res.data.data));
    });
}
export const printShareholderApi = (body) => {
   PNotify.success({
            title: "Thông báo",
            text: "Đang xử lý",
            destroy: true,
            delay: 2000
          });
    return dispatch =>  callDownloadPdfApi(`${constants.URL_PRINT}`, "POST", body).then(function(res){
      dispatch(setLoading(false));
        var file = new Blob([res.data],{type: 'application/pdf'});
        FileSaver.saveAs(file, "giaymoicodong");
        PNotify.success({
            title: "Thành công",
            text: "Tải thành công",
            destroy: true,
            delay: 2000
          });

    }).catch(function(err) {
      dispatch(setLoading(false));
        if (err) {
          if (
            err.response &&
            err.response.data &&
            err.response.data.message
          ) {
            PNotify.error({
              title: "Lỗi",
              text: err.response.data.message,
              destroy: true,
              delay: 2000
            });
          } else if (err.response) {
            PNotify.error({
              title: "Lỗi",
              text: err.response.statusText,
              destroy: true,
              delay: 2000
            });
          }
        }
      });
}
export const getNoInternalShareholderApi = (body, filter) => {
    return dispatch => callApi(`${constants.URL_CREATE_INTERNAL}`, "POST", body).then(function(res){
        dispatch(getFilterUserApi(filter));
        PNotify.success({
            title: "Thành công",
            text: "Loại bỏ thành công",
            destroy: true,
            delay: 2000
          });
    });
}
export const downloadFileAPI = () => {
    return dispatch => callDownloadApi(`${constants.URL_DOWNLOAD}`, "GET", null).then(function(res){
        var file = new Blob([res.data],{type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"});
        FileSaver.saveAs(file, "danhsachcodongnoibo");
        PNotify.success({
            title: "Thành công",
            text: "Tải file thành công",
            destroy: true,
            delay: 2000
          });

    }).catch(function(err) {
        if (err) {
          if (
            err.response &&
            err.response.data &&
            err.response.data.message
          ) {
            PNotify.error({
              title: "Lỗi",
              text: err.response.data.message,
              destroy: true,
              delay: 2000
            });
          } else if (err.response) {
            PNotify.error({
              title: "Lỗi",
              text: err.response.statusText,
              destroy: true,
              delay: 2000
            });
          }
        }
      });
}
export const downloadPasswordListAPI = (body) => {
    return dispatch =>  callApi(`${constants.URL_DOWNLOAD_PASSWORD_LIST}/${body}`, "GET", null).then(function(res){
        dispatch(setPasswordList(res.data.data));
        dispatch(setDownloadPasswordList(true));
        dispatch(setLoading(false));
    }).catch(function(err) {
       dispatch(setLoading(false));
        if (err) {
          if (
            err.response &&
            err.response.data &&
            err.response.data.message
          ) {
            PNotify.error({
              title: "Lỗi",
              text: err.response.data.message,
              destroy: true,
              delay: 2000
            });
          } else if (err.response) {
            PNotify.error({
              title: "Lỗi",
              text: err.response.statusText,
              destroy: true,
              delay: 2000
            });
          }
        }
      });
}


export const getInfo = (value) => {
    return {
        type: constants.GET_INFO,
        value
    }
}
export const setLoading = (value) => {
    return {
        type: constants.SET_LOADING,
        value
    }
}
export const setToggleModal = (value) => {
    return {
        type: constants.GET_TOGGLE_MODAL,
        value
    }
}
export const getListAccount = (value) => {
    return {
        type: constants.GET_LIST,
        value
    }
}
export const setTotal = (value) => {
    return {
        type: constants.SET_TOTAL,
        value
    }
}
export const setFilter = (value) => {
    return {
        type: constants.SET_FILTER,
        value
    }
}
export const setPasswordList = (value) => {
    return {
        type: constants.SET_PASSWORD_LIST,
        value
    }
}
export const setInvitationType = (value) => {
    return {
        type: constants.SET_TYPE,
        value
    }
}
export const getAddressList = (value) => {
    return {
        type: constants.SET_ADDRESS,
        value
    }
}
export const setDownloadPasswordList = (value) => {
    return {
        type: constants.SET_DOWNLOAD_PASSWORD_LIST,
        value
    }
}





