import React, { useState, useEffect } from 'react';
import Section from '../../../../components/bases/shared/section';
import Table from '../../../bases/shared/table';
import Input from '../../../../components/bases/shared/input';
import Button from '../../../../components/bases/shared/button';
import Pagination from '../../../../components/bases/shared/pagination';
import inputIcons from '../../../bases/shared/icons/input';
import { useForm } from 'react-hook-form';
import { SGuestListPage } from '../../../../styles/styled/admin/guestListPage';
import DeleteModal from '../../../bases/shared/modal/ModalDelete';
import ErrorMessage from '@components/input/ErrorMessage';
import { useDispatch, useSelector } from 'react-redux';
import {
  addGuestListApi,
  deleteGuestApi,
  downloadFileAPI,
  exportGuestApi,
  getGuestApi,
  setFilter,
  setListChose,
  setSending,
} from '@duck/pages/admin/initializeGuestReducer/actions';
import PNotify from 'pnotify/dist/es/PNotify';
import { useTranslation } from 'react-i18next';
import EditModal from './modal/EditModal';
import Loading from '../../../bases/shared/loading';
// import {handleError} from '@configs/handleError';

var _validFileExtensions = ['.xlsx', '.xls'];
function Validate(e) {
  var sFileName = e.target.value;
  if (sFileName.length > 0) {
    var blnValid = false;
    for (var j = 0; j < _validFileExtensions.length; j++) {
      var sCurExtension = _validFileExtensions[j];
      if (
        sFileName
          .substr(sFileName.length - sCurExtension.length, sCurExtension.length)
          .toLowerCase() === sCurExtension.toLowerCase()
      ) {
        blnValid = true;
        break;
      }
    }
    return blnValid;
  }
}

const GuestListPage = () => {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    // watch,
    control,
  } = useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [listFile1, setListFile1] = useState('');
  const [toggleModal, setToggleModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [firstTime, setFirstTime] = useState(true);
  const [success, setSuccess] = useState(false);
  const statusOptions = [
    { value: 'all', label: t('Trạng thái') },
    { value: 'true', label: t('Đang hoạt động') },
    { value: 'false', label: t('Không hoạt động') },
  ];
  const theads = [
    { name: '#', colspan: 1 },
    { name: 'Stt', colspan: 1 },
    { name: 'Họ tên', colspan: 1 },
    { name: 'Thông tin', colspan: 1 },
    { name: 'Tài khoản', colspan: 1 },
    { name: 'Trạng thái', colspan: 1 },
  ];

  const { list, total, filter, listChose, sending } = useSelector(
    (state) => state.admin.initializeGuest,
  );
  const setVisiblePassword = () => {
    setVisible(!visible);
  };
  // const [chooseItem, setChooseItem] = useState(null);
  const onSubmit = (data) => {
    const { name, status } = data;
    dispatch(
      setFilter({
        ...filter,
        search: name,
        online: status.value !== '' ? status.value : undefined,
        pageNumber: 1,
      }),
    );
  };
  useEffect(() => {
    if (firstTime === false) dispatch(getGuestApi(filter));
  }, [dispatch, filter, firstTime]);
  useEffect(() => {
    if (firstTime) {
      setFirstTime(false);
      dispatch(setListChose([]));
      dispatch(
        setFilter({
          ...filter,
          search: '',
          online: 'all',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, firstTime]);
  useEffect(() => {
    if (success && !sending) {
      setListFile1('');
      setSuccess(false);
    }
  }, [success, dispatch, sending]);

  const findIndex = (item, list) => {
    for (let i in list) {
      if (list[i]._id === item._id) {
        return i;
      }
    }
    return -1;
  };
  const areSelectedAll = () => {
    let res = true;
    for (let i in list) {
      if (findIndex(list[i], listChose) === -1) {
        res = false;
      }
    }
    return res;
  };

  const getListIds = () => {
    const listIds = listChose.map((item) => item._id);
    return listIds;
  };

  const onSelectAll = () => {
    const listTmp = [...listChose];
    if (areSelectedAll()) {
      for (let i in list) {
        listTmp.splice(findIndex(list[i], listTmp), 1);
      }
    } else {
      for (let i in list) {
        if (findIndex(list[i], listChose) === -1) listTmp.push(list[i]);
      }
    }
    dispatch(setListChose(listTmp));
  };
  const onSelectEachItem = (item) => {
    let listTmp = [...listChose];
    if (findIndex(item, listChose) === -1) listTmp.push(item);
    else listTmp.splice(findIndex(item, listChose), 1);
    dispatch(setListChose(listTmp));
  };
  const isFile = (event, handleFunction) => {
    if (Validate(event)) {
      var files = event.target.files[0];
      handleFunction(files);
      PNotify.success({
        title: t('Thành công'),
        text: t('Upload file thành công'),
        destroy: true,
        delay: 2000,
      });
    } else {
      PNotify.error({
        title: t('Lỗi'),
        text: t('Phải là file excel (xlsx, xls)'),
        destroy: true,
        delay: 2000,
      });
    }
  };
  const uploadFile = () => {
    setSuccess(true);
    if (listFile1) {
      if (listFile1) {
        let formData1 = new FormData();
        formData1.append('file', listFile1);
        formData1.append('guestListType', 'personal');
        dispatch(addGuestListApi(formData1, filter));
      }
      dispatch(setSending(true));
    } else {
      PNotify.error({
        title: t('Lỗi'),
        text: t('Bạn phải upload danh sách khách mời'),
        destroy: true,
        delay: 2000,
      });
    }
  };
  const downloadFile = () => {
    dispatch(downloadFileAPI());
  };

  return (
    <SGuestListPage>
      {sending ? <Loading title={t('Đang tạo danh sách. Vui lòng đợi')} /> : ''}
      <Section header={t('Danh sách khách mời')}>
        <form className="search-wrapper">
          <div className="search-item">
            <Input.Text
              register={register('name')}
              wrapperProps={{
                label: t('Họ tên/ tài khoản'),
                icon: inputIcons.user,
                // required: true,
              }}
            />
            <ErrorMessage errors={errors} field="name" />
          </div>
          <div className="search-item">
            <Input.Select
              name="status"
              control={control}
              defaultValue={{ value: '', label: t('Trạng thái') }}
              selectProps={{
                placeholder: t('Trạng thái'),
                options: statusOptions,
              }}
            />
          </div>

          <div className="search-item">
            <Button type="submit" onClick={handleSubmit(onSubmit)}>
              {t('Tìm kiếm')}
            </Button>
          </div>
        </form>
      </Section>
      <div className="table-container">
        <div className="table-tools">
          <div className="list-handle" style={{ minHeight: '40px' }}>
            {list.length > 0 && (
              <Input.HandleButton
                type="select"
                select={areSelectedAll()}
                action={onSelectAll}
              />
            )}
            <Input.HandleButton
              type="add"
              action={() => {
                setToggleModal(true);
                setModalType('add');
              }}
            />
            {listChose.length === 1 && (
              <Input.HandleButton
                type="edit"
                action={() => {
                  setToggleModal(true);
                  setModalType('edit');
                }}
              />
            )}
            {listChose.length > 0 && (
              <Input.HandleButton
                type="delete"
                action={() => {
                  setToggleModal(true);
                  setModalType('delete');
                }}
              />
            )}
          </div>

          <div className="file-handle">
            <Button className="white-button" onClick={downloadFile}>
              {inputIcons.download}
              {t('Tải về file mẫu')}
            </Button>
            <Button className="white-button upload-btn">
              {inputIcons.upload}
              {t('Upload file')}
              {!listFile1 && (
                <input type="file" onChange={(e) => isFile(e, setListFile1)} />
              )}
              {listFile1 && (
                <div className="file">
                  {listFile1 ? listFile1.name : ''}
                  <svg
                    onClick={() => setListFile1('')}
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="12" cy="12" r="12" fill="#FF6C6C" />
                    <rect
                      x="16.0942"
                      y="6.66211"
                      width="2.66667"
                      height="13.3333"
                      transform="rotate(45 16.0942 6.66211)"
                      fill="white"
                    />
                    <rect
                      x="6.76172"
                      y="8.54883"
                      width="2.66667"
                      height="13.3333"
                      transform="rotate(-45 6.76172 8.54883)"
                      fill="white"
                    />
                  </svg>
                </div>
              )}
            </Button>
            {sending ? (
              <Button
                className="white-button"
                style={{ pointerEvents: 'none' }}
              >
                {inputIcons.import}
                {t('Import')}
              </Button>
            ) : (
              <Button className="white-button" onClick={uploadFile}>
                {inputIcons.import}
                {t('Import')}
              </Button>
            )}
            <Button
              className="green-button"
              onClick={() => {
                dispatch(exportGuestApi(filter));
              }}
            >
              {inputIcons.export}
              {t('Xuất file excel')}
            </Button>
          </div>
          {/* <div className="search">
            <input
              className="search-input"
              placeholder={t('Họ tên, tài khoảnn')}
              onChange={(e) => {
                setKeyword(e.target.value);
              }}
            />
            <svg
              onClick={() => {
                const filterNew = {
                  ...filter,
                  search: keyword,
                  pageNumber: 1,
                };
                dispatch(setFilter(filterNew));
              }}
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.8067 12.86L11.54 10.6C12.2713 9.6683 12.6681 8.51777 12.6667 7.33333C12.6667 6.2785 12.3539 5.24736 11.7678 4.37029C11.1818 3.49323 10.3489 2.80965 9.37431 2.40598C8.39977 2.00231 7.32742 1.89669 6.29285 2.10248C5.25829 2.30827 4.30798 2.81622 3.5621 3.5621C2.81622 4.30798 2.30827 5.25829 2.10248 6.29285C1.89669 7.32742 2.00231 8.39977 2.40598 9.37431C2.80965 10.3489 3.49323 11.1818 4.37029 11.7678C5.24736 12.3539 6.2785 12.6667 7.33333 12.6667C8.51777 12.6681 9.6683 12.2713 10.6 11.54L12.86 13.8067C12.922 13.8692 12.9957 13.9187 13.077 13.9526C13.1582 13.9864 13.2453 14.0039 13.3333 14.0039C13.4213 14.0039 13.5085 13.9864 13.5897 13.9526C13.671 13.9187 13.7447 13.8692 13.8067 13.8067C13.8692 13.7447 13.9187 13.671 13.9526 13.5897C13.9864 13.5085 14.0039 13.4213 14.0039 13.3333C14.0039 13.2453 13.9864 13.1582 13.9526 13.077C13.9187 12.9957 13.8692 12.922 13.8067 12.86ZM3.33334 7.33333C3.33334 6.54221 3.56793 5.76885 4.00746 5.11105C4.44698 4.45326 5.0717 3.94057 5.8026 3.63782C6.53351 3.33507 7.33777 3.25585 8.1137 3.41019C8.88962 3.56454 9.60235 3.9455 10.1618 4.50491C10.7212 5.06432 11.1021 5.77705 11.2565 6.55297C11.4108 7.3289 11.3316 8.13316 11.0289 8.86407C10.7261 9.59497 10.2134 10.2197 9.55562 10.6592C8.89782 11.0987 8.12446 11.3333 7.33333 11.3333C6.27247 11.3333 5.25505 10.9119 4.50491 10.1618C3.75476 9.41162 3.33334 8.3942 3.33334 7.33333Z"
                fill="#00CB82"
              />
            </svg>
          </div> */}
        </div>

        <div className="table-wrapper">
          <Table header={theads}>
            {list.length > 0 ? (
              <>
                {list.map((item, index) => {
                  return (
                    <tr
                      onClick={() => onSelectEachItem(item)}
                      className={
                        findIndex(item, listChose) >= 0 ? 'active' : ''
                      }
                      key={index}
                    >
                      <td className="center">
                        <Input.Checkbox
                          checked={
                            findIndex(item, listChose) >= 0 ? true : false
                          }
                        />
                      </td>
                      <td> {index + 1} </td>
                      <td> {item.name} </td>
                      <td> {item.guestInfo}</td>
                      <td> {item.identityNumber}</td>
                      <td>
                        <span
                          style={{ color: item.online ? '#00CB82' : '#FF6C6C' }}
                        >
                          {item.online
                            ? t('Đang hoạt động')
                            : t('Không hoạt động')}
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </>
            ) : (
              <tr>
                <td colSpan={100} className="center">
                  {' '}
                  {t('Chưa có dữ liệu')}{' '}
                </td>
              </tr>
            )}
          </Table>
          {list.length > 0 && (
            <Pagination
              handleChangePageSize={(size) => {
                const filterNew = {
                  ...filter,
                  pageSize: size,
                };
                dispatch(setFilter(filterNew));
              }}
              current={filter.pageNumber}
              total={total}
              pageSize={filter.pageSize}
              onChange={(page) => {
                const filterNew = {
                  ...filter,
                  pageNumber: page,
                };
                dispatch(setFilter(filterNew));
              }}
            />
          )}
        </div>
      </div>

      {modalType === 'add' && (
        <EditModal
          toggleModal={toggleModal}
          setToggleModal={setToggleModal}
          modalType="add"
        />
      )}
      {modalType === 'edit' && (
        <EditModal
          toggleModal={toggleModal}
          setToggleModal={setToggleModal}
          modalType="edit"
          item={listChose[0] && listChose[0]}
        />
      )}
      {modalType === 'delete' && (
        <DeleteModal
          isOpen={toggleModal}
          toggleModal={setToggleModal}
          title={t('Xóa khách mời')}
          onSubmit={() => {
            dispatch(
              deleteGuestApi(
                listChose.map((item) => {
                  return item._id;
                }),
              ),
              filter,
            );
          }}
        />
      )}
      {/* <Modal
        isOpen={toggleModal}
        toggleModal={setToggleModal}
        title={t('Sửa thông tin khách mời')}
        overwriteModalStyle={{
          maxWidth: '560px',
        }}
      >
        <ModalEdit setToggleModal={setToggleModal} item={listChose[0]} />
      </Modal>
      <DeleteModal
        title={t('Xóa các nội dung')}
        isOpen={toggleModalDelete}
        toggleModal={setToggleModalDelete}
        onSubmit={() => {
          dispatch(deleteGuestApi(getListIds(), filter));
        }}
      /> */}
    </SGuestListPage>
  );
};
export default GuestListPage;
