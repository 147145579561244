// import {
//   checkPermission,
//   useCheckPermission,
// } from '@pages/admin/declareDepartmentsPage/permission/data';
import { ReactComponent as DownArrow } from 'assets/icons/down-arrow.svg';
import { ReactComponent as LeftArrow } from 'assets/icons/left-arrow.svg';
import logoImg from 'assets/images/logo.png';
import shortLogoImg from 'assets/images/logo-short.png';
import { UIList } from 'components/bases/UI/List';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { menuList } from './router';
import menuImg from 'assets/images/menu-bottom.png';
import { useSelector } from 'react-redux';

const HeaderLeft = () => {
  const [isOpen, setOpen] = useState(() => window.innerWidth > 1199);
  const [isShowSubMenu, setShowSubMenu] = useState(0);
  useEffect(() => {
    const webContent = document.getElementById('web-content');
    // if (window.innerWidth > 480)
    if (isOpen) {
      webContent.classList.remove('hidden-menu');
    } else {
      webContent.classList.add('hidden-menu');
    }
  }, [isOpen]);

  useEffect(() => {
    const listener = () => {
      setOpen(window.innerWidth > 1199);
    };

    window.addEventListener('resize', listener);
    return () => window.addEventListener('resize', listener);
  }, []);

  return (
    <Styles
      isOpen={isOpen}
      onClick={() => {
        if (isOpen) setOpen(false);
      }}
    >
      <div className="close-icon" onClick={() => setOpen((prev) => !prev)}>
        <LeftArrow />
      </div>
      <div className="menu-content" onClick={(e) => e.stopPropagation()}>
        <div className="logo">
          <img src={isOpen ? logoImg : shortLogoImg} />
        </div>
        <UIList items={menuList}>
          {(item, ind) => {
            // if (!isHavePermission(item)) return <div />;
            return (
              <NavItem
                item={item}
                close={() => {
                  if (isOpen && window.innerWidth < 1199) setOpen(false);
                }}
                isOpenMenu={isOpen}
                isShowSubMenu={isShowSubMenu}
                setShowSubMenu={setShowSubMenu}
              />
            );
          }}
        </UIList>
        <div className="menu-bottom">
          <img src={menuImg} />
        </div>
      </div>
    </Styles>
  );
};

export default HeaderLeft;

const checkPermission = (item, permissions = []) => {
  if (item.per) return permissions?.includes(item.per);
  if (item.children)
    return item.children.some((c) => permissions?.includes(c.per));
  return false;
};

const checkOpen = (item) =>
  item.path == window.location.pathname ||
  item.children?.some((c) => c.path == window.location.pathname);

const NavItem = ({
  item,
  close,
  isOpenMenu,
  isShowSubMenu,
  setShowSubMenu,
}) => {
  const [isShow, setShow] = useState(() => checkOpen(item));
  const userInfo = useSelector((state) => state.login.userInfo);
  const isPermission = checkPermission(item, userInfo?.permissions);

  useEffect(() => {
    if (isShowSubMenu && isShowSubMenu != item.label) {
      setShow(false);
    }
  }, [isShowSubMenu]);

  if (!isPermission) return null;
  return (
    <SNav className="nav-item" isOpenMenu={isOpenMenu}>
      <NavLink
        to={item.path || item.children?.[0]?.path + 'xxx'}
        onClick={(e) => {
          if (item.children) {
            e.preventDefault();
            setShow((prev) => !prev);
            setShowSubMenu(item.label);
          } else close();
        }}
        exact
      >
        <div className={'nav-top' + (checkOpen(item) ? ' active' : '')}>
          {item.icon}
          <div
            className={
              'nav-text' + (!item.children && !isOpenMenu ? ' show-hint' : '')
            }
          >
            {item.label}
          </div>
          {item.children && <DownArrow className="down-arrow" />}
        </div>
      </NavLink>
      {item.children && (isShow || !isOpenMenu) && (
        <UIList items={item.children || []} className="sub-wrap">
          {(i, ind) => {
            if (!checkPermission(i, userInfo?.permissions)) return <div />;
            return (
              <NavLink to={i.path} exact onClick={() => close()}>
                {ind == 0 && !isOpenMenu && (
                  <div className="sub-label">{item.label}</div>
                )}

                <div className="sub-item">{i.label}</div>
              </NavLink>
            );
          }}
        </UIList>
      )}
    </SNav>
  );
};

const SNav = styled.div`
  margin-bottom: 12px;

  .nav-top {
    display: flex;
    align-items: center;
    padding: 8px 8px 8px 14px;
    border-radius: 2px;
    cursor: pointer;
    .nav-text {
      width: 100%;
      padding: 0 8px;
      color: white;
    }
  }

  .sub-wrap {
    padding: 4px 8px 4px 32px;

    .sub-item {
      padding: 6px;
      cursor: pointer;
      color: white;
      display: flex;
      align-items: center;
      ::before {
        content: '';
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 50px;
        margin-right: 6px;
        background: white;
      }
    }
  }

  a.active,
  a:hover {
    display: block;
    background: #01a66a;
    border-radius: 2px;
  }

  ${({ isOpenMenu }) =>
    !isOpenMenu &&
    css`
      position: relative;
      ::before {
        content: '';
        position: absolute;
        z-index: 99;
        top: 0;
        left: 100%;
        right: -20px;
        bottom: 0;
      }
      .sub-wrap {
        display: none;
      }

      .nav-top.active {
        background: #01a66a;
        border-radius: 2px;
      }

      :hover {
        .nav-top {
          background: #01a66a;
          border-radius: 2px;

          .show-hint {
            position: absolute;
            display: flex !important;
            align-items: center;
            justify-content: center;
            top: 0;
            left: 32px;
            bottom: 0;
            margin-left: 14px;
            background: white;
            border-radius: 2px;
            color: #718098;
            white-space: nowrap;
            width: unset;
            box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
          }
        }

        .sub-wrap {
          display: block;
          position: absolute;
          z-index: 1;
          top: 0;
          left: 32px;
          margin-left: 14px;
          background: white;
          white-space: nowrap;
          padding: 16px;
          border-radius: 2px;
          box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);

          .sub-label {
            color: #718098 !important;
          }

          a.active,
          a:hover {
            background: unset;
            .sub-item {
              color: #00cb82;
            }
          }
          .sub-item {
            color: #718098;
            padding: 6px 0;
            ::before {
              background: #00cb82;
            }
          }
        }
      }
    `}
`;

const Styles = styled.div`
  font-size: 14px;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 260px;
  z-index: 99;
  transition: 0.3s;
  .menu-content {
    overflow: auto;
    width: 100%;
    height: 100%;
    padding: 0 10px 22px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    z-index: 4;
    transition: 0.3s;
    background: linear-gradient(0deg, #3fd06b 0%, #00b775 117.14%);
    ::-webkit-scrollbar {
      width: 4px;
      background-color: #00b775;
      height: 4px;
    }
  }

  .close-icon {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #f47f20;
    border-radius: 50px 0 0 50px;
    position: absolute;
    right: 0;
    top: 18px;
    z-index: 1;
  }

  .logo {
    margin: 40px;
    display: flex;
    justify-content: center;
  }

  .menu-bottom {
    margin-top: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
    }
  }

  ${({ isOpen }) =>
    !isOpen &&
    css`
      width: 56px;
      .close-icon {
        transform: rotate(180deg);
        transform-origin: right center;
      }
      .menu-content {
        overflow: unset;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;

        .logo {
          margin: 30px 0 50px;
        }
        .nav-text {
          display: none;
        }
        .down-arrow {
          display: none;
        }
        .nav-top {
          padding: 8px;
        }
        .menu-bottom {
          display: none;
        }

        .nav-item {
          margin-bottom: 12px;
        }
      }
    `}

  @media only screen and (max-width: 991px) {
    ${({ isOpen }) =>
      isOpen &&
      css`
        ::before {
          content: '';
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: -1;
          background-color: rgba(0, 0, 0, 0.7);
        }
      `}
  }

  @media only screen and (max-width: 480px) {
    ${({ isOpen }) =>
      !isOpen &&
      css`
        width: 0;
        .menu-content {
          width: 0;
          overflow: hidden;
        }
      `}
  }
`;
