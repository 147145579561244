import React, { useState, useEffect } from 'react';
import Section from 'components/bases/shared/section';
import Table from 'components/bases/shared/table';
import Input from 'components/bases/shared/input';
import Button from 'components/bases/shared/button';
import Pagination from 'components/bases/shared/pagination';
import inputIcons from 'components/bases/shared/icons/input';
import { useForm } from 'react-hook-form';
import DeleteModal from 'components/bases/shared/modal/ModalDelete';
import ErrorMessage from '@components/input/ErrorMessage';
import { VALIDATE_REQUIRE } from '@constants/validate';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import EditModal from './modal/EditModal';
import { SAdminAccount } from '@styled/admin/systemPage/AdminAccount/adminAccount';
import AccountPermissionsModal from './modal/AccountPermissionsModal';
import {
  getAccountApi,
  setFilter,
  setListChose,
  getDeleteApi,
  downloadFileAPI,
  getCreateApi,
  setSending,
  exportListAccount,
} from '@duck/pages/admin/systemReducer/adminAccount/actions';
import PNotify from 'pnotify/dist/es/PNotify';

// import {handleError} from '@configs/handleError';

var _validFileExtensions = ['.xlsx', '.xls'];
function Validate(e) {
  var sFileName = e.target.value;
  if (sFileName.length > 0) {
    var blnValid = false;
    for (var j = 0; j < _validFileExtensions.length; j++) {
      var sCurExtension = _validFileExtensions[j];
      if (
        sFileName
          .substr(sFileName.length - sCurExtension.length, sCurExtension.length)
          .toLowerCase() === sCurExtension.toLowerCase()
      ) {
        blnValid = true;
        break;
      }
    }
    return blnValid;
  }
}

const AdminAccount = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [toggleModal, setToggleModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [firstTime, setFirstTime] = useState(true);
  const [listFile1, setListFile1] = useState('');
  const [success, setSuccess] = useState(false);

  const theads = [
    { name: '#', colspan: 1 },
    { name: 'Stt', colspan: 1 },
    { name: 'Họ tên', colspan: 1 },
    { name: 'Thông tin', colspan: 1 },
    { name: 'Tài khoản', colspan: 1 },
  ];

  const isFile = (event, handleFuncion) => {
    if (Validate(event)) {
      var files = event.target.files[0];
      handleFuncion(files);
      PNotify.success({
        title: t('Thành công'),
        text: t('Upload file thành công'),
        destroy: true,
        delay: 2000,
      });
    } else {
      PNotify.error({
        title: t('Lỗi'),
        text: t('Phải là file excel (xlsx, xls)'),
        destroy: true,
        delay: 2000,
      });
    }
  };
  useEffect(() => {
    if (success) {
      setListFile1('');
      setSuccess(false);
    }
  }, [success, dispatch]);

  const onSubmitFile = () => {
    setSuccess(true);
    if (listFile1) {
      if (listFile1) {
        let formData1 = new FormData();
        formData1.append('file', listFile1);
        formData1.append('shareholderType', 'personal');
        dispatch(getCreateApi(formData1, filter));
      }
      dispatch(setSending(true));
    } else {
      PNotify.error({
        title: t('Lỗi'),
        text: t('Vui lòng upload biểu mẫu'),
        destroy: true,
        delay: 2000,
      });
    }
  };
  const downloadFile = () => {
    dispatch(downloadFileAPI());
  };

  const exportListAdminAccount = () => {
    dispatch(exportListAccount(filter));
  };

  const { list, total, filter, listChose } = useSelector(
    (state) => state.admin.adminAccount,
  );

  const getListIds = () => {
    const listIds = listChose.map((item) => item._id);
    return listIds;
  };
  const onSubmit = (data) => {
    const { name } = data;
    dispatch(
      setFilter({
        ...filter,
        search: name,
      }),
    );
  };
  useEffect(() => {
    if (firstTime === false) dispatch(getAccountApi(filter));
  }, [dispatch, filter, firstTime]);
  useEffect(() => {
    if (firstTime) {
      setFirstTime(false);
      dispatch(setListChose([]));
      dispatch(
        setFilter({
          ...filter,
          search: '',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, firstTime]);
  const findIndex = (item, list) => {
    for (let i in list) {
      if (list[i]._id === item._id) {
        return i;
      }
    }
    return -1;
  };
  const areSelectedAll = () => {
    let res = true;
    for (let i in list) {
      if (findIndex(list[i], listChose) === -1) {
        res = false;
      }
    }
    return res;
  };

  const onSelectAll = () => {
    const listTmp = [...listChose];
    if (areSelectedAll()) {
      for (let i in list) {
        listTmp.splice(findIndex(list[i], listTmp), 1);
      }
    } else {
      for (let i in list) {
        if (findIndex(list[i], listChose) === -1) listTmp.push(list[i]);
      }
    }
    dispatch(setListChose(listTmp));
  };
  const onSelectEachItem = (item) => {
    let listTmp = [...listChose];
    if (findIndex(item, listChose) === -1) listTmp.push(item);
    else listTmp.splice(findIndex(item, listChose), 1);
    dispatch(setListChose(listTmp));
  };

  const getPageSize = (pageSize) => {
    // const {identityNumber, joinType, status, scale, type} = filter;
    dispatch(
      setFilter({
        ...filter,
        pageSize,
        pageNumber: 1,
      }),
    );
  };

  const getPage = (pageNumber) => {
    dispatch(
      setFilter({
        ...filter,
        pageNumber,
      }),
    );
  };
  return (
    <SAdminAccount>
      <Section header={t('Danh sách tài khoản quản trị')}>
        <form className="search-wrapper">
          <div className="search-item">
            <Input.Text
              register={register('name')}
              wrapperProps={{
                label: t('Họ tên/ tài khoản'),
                icon: inputIcons.user,
                // required: true,
              }}
            />
          </div>
          <div className="search-item">
            <Button type="submit" onClick={handleSubmit(onSubmit)}>
              {t('Tìm kiếm')}
            </Button>
          </div>
        </form>
      </Section>
      <div className="table-container">
        <div className="table-tools">
          <div className="list-handle" style={{ minHeight: '40px' }}>
            {list.length > 0 && (
              <Input.HandleButton
                type="select"
                select={areSelectedAll()}
                action={onSelectAll}
              />
            )}
            <Input.HandleButton
              type="add"
              action={() => {
                setToggleModal(true);
                setModalType('add');
              }}
            />
            {listChose.length === 1 && (
              <Input.HandleButton
                type="edit"
                action={() => {
                  setToggleModal(true);
                  setModalType('edit');
                }}
              />
            )}
            {listChose.length > 0 && (
              <Input.HandleButton
                type="delete"
                action={() => {
                  setToggleModal(true);
                  setModalType('delete');
                }}
              />
            )}
            {listChose.length > 0 && (
              <Input.HandleButton
                type="permissions"
                action={() => {
                  setIsOpen(true);
                  setModalType('permissions');
                }}
              />
            )}
          </div>

          <div className="file-handle">
            <Button className="white-button" onClick={downloadFile}>
              {inputIcons.download}
              {t('Tải về file mẫu')}
            </Button>
            <Button className="white-button  upload-btn">
              {inputIcons.upload}
              {t('Upload file')}
              {!listFile1 && (
                <input type="file" onChange={(e) => isFile(e, setListFile1)} />
              )}
              {listFile1 && (
                <div className="file">
                  {listFile1 ? listFile1.name : ''}
                  <svg
                    onClick={() => setListFile1('')}
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="12" cy="12" r="12" fill="#FF6C6C" />
                    <rect
                      x="16.0942"
                      y="6.66211"
                      width="2.66667"
                      height="13.3333"
                      transform="rotate(45 16.0942 6.66211)"
                      fill="white"
                    />
                    <rect
                      x="6.76172"
                      y="8.54883"
                      width="2.66667"
                      height="13.3333"
                      transform="rotate(-45 6.76172 8.54883)"
                      fill="white"
                    />
                  </svg>
                </div>
              )}
            </Button>
            <Button className="white-button" onClick={onSubmitFile}>
              {inputIcons.import}
              {t('Import')}
            </Button>

            <Button className="green-button" onClick={exportListAdminAccount}>
              {inputIcons.export}
              {t('Xuất file excel')}
            </Button>
          </div>
        </div>

        <div className="table-wrapper">
          <Table header={theads}>
            {list.length > 0 ? (
              <>
                {list.map((item, index) => {
                  return (
                    <tr
                      onClick={() => onSelectEachItem(item)}
                      className={
                        findIndex(item, listChose) >= 0 ? 'active' : ''
                      }
                      key={index}
                    >
                      <td className="center">
                        <Input.Checkbox
                          checked={
                            findIndex(item, listChose) >= 0 ? true : false
                          }
                        />
                      </td>
                      <td> {index + 1} </td>
                      <td> {item.name} </td>
                      <td> {item.description}</td>
                      <td> {item.account}</td>
                    </tr>
                  );
                })}
              </>
            ) : (
              <tr>
                <td colSpan={100} className="center">
                  {' '}
                  {t('Chưa có dữ liệu')}{' '}
                </td>
              </tr>
            )}
          </Table>
          {list.length > 0 && (
            <Pagination
              handleChangePageSize={getPageSize}
              current={filter.pageNumber}
              total={total}
              pageSize={filter.pageSize}
              onChange={getPage}
            />
          )}
        </div>
      </div>

      {modalType === 'add' && (
        <EditModal
          toggleModal={toggleModal}
          setToggleModal={setToggleModal}
          modalType="add"
        />
      )}
      {modalType === 'edit' && (
        <EditModal
          item={listChose[0]}
          toggleModal={toggleModal}
          setToggleModal={setToggleModal}
          modalType="edit"
        />
      )}
      {modalType === 'delete' && (
        <DeleteModal
          isOpen={toggleModal}
          toggleModal={setToggleModal}
          title="Xóa tài khoản quản trị"
          onSubmit={() => {
            dispatch(getDeleteApi(getListIds(), filter));
            dispatch(setListChose([]));
          }}
        />
      )}
      {listChose.length > 0 && (
        <AccountPermissionsModal
          accounts={listChose}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          modalType="permissions"
          refresh={() => {
            dispatch(setFilter({ ...filter }));
            dispatch(setListChose([]));
          }}
        />
      )}
    </SAdminAccount>
  );
};
export default AdminAccount;
