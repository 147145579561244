import * as types from './constants';
var initialState = {
  tableList: [],
  toggleModal: false,
  info: {},
  topic1: [],
  topic2: [],
  topic3: [],
  topic4: [],
  topic6: [],
  timeSetting: [],
  timeConference: {},
  topicPart: {
    kind1: false,
    kind2: false,
    kind3: false,
    kind4: false,
    king6: false,
  },
};
var reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST:
      return { ...state, tableList: action.value };
    case types.GET_LIST_TOPIC_1:
      return { ...state, topic1: action.value };
    case types.GET_LIST_TOPIC_2:
      return { ...state, topic2: action.value };
    case types.GET_LIST_TOPIC_3:
      return { ...state, topic3: action.value };
    case types.GET_LIST_TOPIC_4:
      return { ...state, topic4: action.value };
    case types.GET_LIST_TOPIC_6:
      return { ...state, topic6: action.value };
    case types.GET_TIME_SETTING:
      return { ...state, timeSetting: action.value };
    case types.GET_INFO:
      return { ...state, info: action.value };
    case types.GET_TOPIC_PART_INFO:
      return { ...state, topicPart: action.value };
    case types.TOGGLE_MODAL:
      return { ...state, toggleModal: action.value };
    case types.SET_TIME_CONFERENCE:
      return { ...state, timeConference: action.value };
    default:
      return state;
  }
};
export default reducer;
