import styled from 'styled-components';
import { SHotline } from './hotlinePage';
export const SGuestListPage = styled(SHotline)`
  .module-header {
    .title {
      text-align: left;
      text-transform: none;
    }
  }
  .module-content {
    margin: 0;
  }
  .search-wrapper {
    margin: 0 -12px;
    margin-bottom: -20px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .search-item {
      padding: 0 12px;
      /* min-width: 200px; */
      /* width: 33.33%; */
      button {
        width: 100%;
      }
      margin-bottom: 20px;
    }
  }
  .table-tools {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-bottom: 12px;
    .file-handle {
      display: flex;
      justify-content: flex-end;
      /* margin-right: -8px;
      margin-left: -8px; */
      flex-wrap: wrap;
      gap: 8px;
      button {
        padding: 2px 4px;
        /* max-width: 33.33%; */
        height: 40px;
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #718098;
        /* margin: 0 8px; */
        border-radius: 0;
        border: 1px solid #00cb82;
        svg {
          margin-right: 12px;
          path {
            fill: #00cb82;
          }
        }
        &.green-button {
          background: #00cb82;
          color: #fff;
          svg {
            path {
              fill: #fff;
            }
          }
        }
        &.grey-button {
          background: #718098;
        }
        &.white-button {
          background: #fff;
        }
        &.blue-button {
          background: #2f80ed;
        }
        &.upload-btn {
          position: relative;
          input {
            opacity: 0;
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            height: 100%;
          }
          .file {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #dfe9f5;
            text-align: center;
            color: #2f80ed;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            background: #f5faff;

            svg {
              position: absolute;
              top: -10px;
              right: -20px;
              cursor: pointer;
            }
          }
        }
      }
    }
    .search {
      height: 32px;
      width: 303px;
      position: relative;
      border-radius: 2px;
      overflow: hidden;
      margin-bottom: 8px;
      .search-input {
        font-size: 12px;
        border: none;

        font-weight: 400;
        line-height: 18px;
        color: #718098;
        padding: 7px 35px 7px 16px;
        &::placeholder {
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          color: #718098;
          font-style: unset;
        }
      }
      svg {
        position: absolute;
        right: 12px;
        top: 8px;
        cursor: pointer;
        transition: all 0.2s;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  @media screen and (max-width: 850px) {
    .table-tools {
      flex-direction: column;
      align-items: flex-start;
      .list-handle {
        order: 2;
      }
      .file-handle {
        order: 1;
        margin-top: 20px;
        justify-content: flex-start;
      }
    }
  }

  @media screen and (max-width: 575px) {
    .table-tools {
      .file-handle {
        button {
          flex: calc(50% - 8px);
        }
      }
    }
  }

  @media screen and (max-width: 479px) {
    .table-tools {
      .file-handle {
        button {
          flex: 100%;
        }
      }
    }
  }
`;
