export const URL = 'topic/create';
export const URL_SHAREHOLDER = 'info/shareholder';
export const URL_SHAREHOLDER_BLOCKED = 'topic/block-list';
export const URL_BLOCK = 'topic/block';
export const URL_CUMULATIVE = 'topic/cumulative/create';
export const URL_TABLE = 'info/table';
export const URL_EDIT = 'topic/edit';
export const URL_SORT = 'topic/sort';
export const URL_EDIT_CUMULATIVE = 'topic/cumulative/edit';
export const URL_DELETE = 'topic/delete';
export const URL_CLOSE_VOTE = 'topic/toggle';
export const URL_CONFIRM = 'topic/confirm';

export const GET_LIST = 'GET_TOPIC_LIST';
export const SET_CHOOSE_LIST = 'SET_TOPIC_LIST';
export const TOGGLE_MODAL = 'TOGGLE_TOPIC_MODAL';
export const SET_LIST_SHAREHOLDER = 'SET_TOPIC_LIST_SHAREHOLDER';
export const SET_TOTAL_LISTPAGE = 'SET_TOTAL_LISTPAGE';
export const SET_TOTAL = 'SET_TOPIC_TOTAL';
export const SET_FILTER = 'SET_TOPIC_FILTER';
export const SET_FILTER_LISTPAGE = 'SET_FILTER_LISTPAGE';
export const SET_SUCCESS = 'SET_SUCCESS_FILTER';
export const SET_ACTIVE_BUTTON_CONFIRM = 'SET_TOPIC_ACTIVE_BUTTON_CONFIRM';
export const SET_METHOD = 'SET_METHOD';
export const SET_BLOCK_LIST = 'SET_BLOCK_LIST';
export const REFETCH_SHARE_HOLDER = 'REFETCH_SHARE_HOLDER';
