import * as constants from './constants';
import { callApi, callDownloadApi } from '../../../../common';
import { handleError } from '@configs/handleError';
import PNotify from 'pnotify/dist/es/PNotify';
var FileSaver = require('file-saver');
Object.filter = (obj, predicate) =>
  Object.keys(obj)
    .filter((key) => predicate(obj[key]))
    .reduce((res, key) => Object.assign(res, { [key]: obj[key] }), {});

export const getReportApi = () => {
  return (dispatch) =>
    callApi(`${constants.URL_REPORT}`, 'GET', null)
      .then(function (res) {
        dispatch(getReport(res.data.data));
      })
      .catch(handleError);
};
export const getTopic2Api = (body) => {
  return (dispatch) =>
    callApi(`${constants.URL_TOPIC}/${body}`, 'GET', null).then(function (res) {
      //   dispatch(getTopic2(res.data.topics));
    });
};

export const getReportVoteApi = () => {
  return (dispatch) =>
    callApi(`${constants.URL_REPORT_VOTE_RESULT}`, 'GET', null)
      .then(function (res) {
        //   dispatch(getTopic2(res.data.topics));
        const listAll = res.data.data.topicKind123
          ? Object.values(res.data.data.topicKind123)
          : [];
        const topic6 = res.data.data.topicKind6
          ? Object.values(res.data.data.topicKind6)
          : [];
        const topic4 = res.data.data.topicKind4
          ? Object.values(res.data.data.topicKind4)
          : [];
        const topic1 = listAll
          ? listAll.filter((item) => item.topic.kind === 1)
          : [];
        const topic2 = listAll
          ? listAll.filter((item) => item.topic.kind === 2)
          : [];
        const topic3 = listAll
          ? listAll.filter((item) => item.topic.kind === 3)
          : [];
        dispatch(getResultTopic1(topic1));
        dispatch(getResultTopic2(topic2));
        dispatch(getResultTopic3(topic3));
        dispatch(getResultTopic4(topic4));
        dispatch(getResultTopic6(topic6));
      })
      .catch(handleError);
};

export const getListDetail = (body) => {
  return (dispatch) => {
    callApi(`${constants.URL_REPORT_VOTE_DETAIL}`, 'GET', body)
      .then(function (res) {
        // console.log(body);
        dispatch(getResultDetail(res.data.data.votes));
        dispatch(getResultTotal(res.data.data.total));
      })
      .catch(handleError);
  };
};

export const getNotVoteApi = (body) => {
  //{ take, page, kind, topicId, fixId, search }
  const { take, page, search, kind, topicId } = body;
  return (dispatch) =>
    callApi(`${constants.URL_REPORT_NOT_VOTE}`, 'GET', {
      take,
      page,
      search,
      kind,
      topicId,
    })
      .then(function (res) {
        // console.log(body);
        dispatch(getResultDetail(res.data.data.data));
        dispatch(getResultTotal(res.data.data.total));
      })
      .catch(handleError);
};
export const exportResultApi = (kind) => {
  // return (dispatch) =>
  callDownloadApi(`${constants.URL_EXPORT_RESULT}/${kind}`, 'GET', null)
    .then(function (res) {
      // console.log(res)
      var file = new Blob([res.data], {
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });
      const fileName =
        kind === 1
          ? 'KQKP_ThongQuaThuTucKhaiMacDaiHoi'
          : kind === 2
          ? 'KQKP_ThongQuaBaoCaoToTrinh'
          : kind === 3
          ? 'KQKP_ThongQuaBienBanNghiQuyet'
          : 'KQKP_ToTrinhBauCu';
      FileSaver.saveAs(file, fileName);
      PNotify.success({
        title: 'Thành công',
        text: 'Xuất kết quả kiểm phiếu thành công!',
        destroy: true,
        delay: 2000,
      });
    })
    .catch(handleError);
};
export const exportShareholderResultApi = (kind) => {
  // return (dispatch) =>
  callDownloadApi(`${constants.URL_EXPORT_SHAREHOLDER}/${kind}`, 'GET', null)
    .then(function (res) {
      // console.log(res)
      var file = new Blob([res.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const fileName =
        kind === 1
          ? 'KQKP_ThongQuaThuTucKhaiMacDaiHoi-danhsachcodong'
          : kind === 2
          ? 'KQKP_ThongQuaBaoCaoToTrinh-danhsachcodong'
          : kind === 3
          ? 'KQKP_ThongQuaBienBanNghiQuyet-danhsachcodong'
          : 'KQKP_BauCu-danhsachcodong';
      FileSaver.saveAs(file, fileName);
      PNotify.success({
        title: 'Thành công',
        text: 'Xuất kết quả kiểm phiếu thành công!',
        destroy: true,
        delay: 2000,
      });
    })
    .catch(handleError);
};
export const getResultTopic1 = (value) => {
  return {
    type: constants.GET_TOPIC1,
    value,
  };
};
export const getResultTopic2 = (value) => {
  return {
    type: constants.GET_TOPIC2,
    value,
  };
};
export const getResultTopic3 = (value) => {
  return {
    type: constants.GET_TOPIC3,
    value,
  };
};
export const getResultTopic4 = (value) => {
  return {
    type: constants.GET_TOPIC4,
    value,
  };
};
export const getResultTopic6 = (value) => {
  return {
    type: constants.GET_TOPIC6,
    value,
  };
};
export const getReport = (value) => {
  return {
    type: constants.GET_REPORT,
    value,
  };
};

export const setToggleModal = (value) => {
  return {
    type: constants.TOGGLE_MODAL,
    value,
  };
};

export const getResultDetail = (value) => {
  return {
    type: constants.GET_RESULT_DETAIL,
    value,
  };
};
export const getResultTotal = (value) => {
  return {
    type: constants.GET_RESULT_TOTAL,
    value,
  };
};

export const setFilterModal = (value) => {
  return {
    type: constants.SET_FILTER_MODAL,
    value,
  };
};
