import { SHotline } from '@styled/admin/hotlinePage';
import styled from 'styled-components';
// import { SHotline } from './hotlinePage';
export const SAdminAccount = styled(SHotline)`
  .module-header {
    margin-bottom: 21px;
    .title {
      text-align: left;
      text-transform: none;
    }
  }
  .module-content {
    margin: 0;
  }
  .search-wrapper {
    margin-bottom: -20px;
    padding: 0 13px;
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 20px;
    .search-item {
      padding: 0px;
      /* min-width: 200px; */
      /* width: 33.33%; */
      width: 100%;
      button {
        width: 100%;
      }
      margin-bottom: 20px;
    }
  }
  .table-tools {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 12px;
    gap: 0 30px;
    .file-handle {
      display: flex;
      justify-content: content;
      gap: 15px 20px;
      flex-wrap: wrap;
      button {
        padding: 2px 4px;
        /* max-width: 33.33%; */
        height: 40px;
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #718098;
        border-radius: 0;
        border: 1px solid #00cb82;
        width: 225px;
        svg {
          margin-right: 12px;
          path {
            fill: #00cb82;
          }
        }
        &.green-button {
          background: #00cb82;
          color: #fff;
          svg {
            path {
              fill: #fff;
            }
          }
        }
        &.grey-button {
          background: #718098;
        }
        &.white-button {
          background: #fff;
        }
        &.blue-button {
          background: #2f80ed;
        }
        &.upload-btn {
          position: relative;

          input {
            opacity: 0;
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            height: 100%;
          }
          .file {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #dfe9f5;
            text-align: center;
            color: #2f80ed;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            background: #f5faff;

            svg {
              position: absolute;
              top: -10px;
              right: -20px;
              cursor: pointer;
            }
          }
        }
      }
    }
    .search {
      height: 32px;
      width: 303px;
      position: relative;
      border-radius: 2px;
      overflow: hidden;
      margin-bottom: 8px;
      .search-input {
        font-size: 12px;
        border: none;

        font-weight: 400;
        line-height: 18px;
        color: #718098;
        padding: 7px 35px 7px 16px;
        &::placeholder {
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          color: #718098;
          font-style: unset;
        }
      }
      svg {
        position: absolute;
        right: 12px;
        top: 8px;
        cursor: pointer;
        transition: all 0.2s;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
  @media only screen and (max-width: 1396px) {
    .table-tools {
      display: grid;
      grid-template-columns: 1fr;
      margin-bottom: 4px;
    }
    .table-tools .list-handle:nth-of-type(1) {
      order: 2;
    }
    .table-tools .file-handle:nth-of-type(2) {
      order: 1;
      justify-content: center;
    }
  }
  @media only screen and (max-width: 541px) {
    .search-wrapper {
      grid-template-columns: 1fr;
      padding-bottom: 20px;
      .search-item {
        margin-bottom: 0px;
      }
    }
  }
`;
