import React from 'react';
import {setFilter} from '../../../../states/duck/pages/checkCoupon/checkCouponListReducer/actions';
import {useSelector, useDispatch} from 'react-redux';
import {useForm} from 'react-hook-form';
import Input from '@components/input';
import Section from '@components/section';
import {SFormCheckCouponListPage} from '@styled/checkCoupon/checkCouponListPage';
import inputIcons from '@components/icons/input';
import Button from '@components/button';
import {useTranslation} from 'react-i18next';

const optionStatus = [
  {value: 'all', label: 'Trạng thái'},
  {value: 'voted', label: 'Đã biểu quyết'},
  {value: 'notVote', label: 'Chưa biểu quyết'},
];
const optionStatusEn = [
  {value: 'all', label: 'Status'},
  {value: 'voted', label: 'Voted'},
  {value: 'notVote', label: 'Not vote'},
];
const optionJoinTypes = [
  {value: 'all', label: 'Hình thức'},
  {value: 'none', label: 'Chưa checkin'},
  {value: 'authority', label: 'Ủy quyền'},
  {value: 'live', label: 'Trực tiếp'},
  {value: 'online', label: 'Trực tuyến'},
];
const optionJoinTypesEn = [
  {value: 'all', label: 'Form'},
  {value: 'none', label: 'Have not checked in'},
  {value: 'authority', label: 'Authorization'},
  {value: 'live', label: 'Direct'},
  {value: 'online', label: 'Online'},
];

const optionsAuthorityTypes = [
  {value: 'all', label: 'Tất cả'},
  {value: 'notAuthority', label: 'Cổ đông'},
  {value: 'authority', label: 'Nhận ủy quyền'},
];
const optionsAuthorityTypesEn = [
  {value: 'all', label: 'All'},
  {value: 'notAuthority', label: 'Shareholder'},
  {value: 'authority', label: 'Get authorized'},
];

const optionsActiveTypes = [
  {value: 'all', label: 'Trạng thái hoạt động'},
  {value: 'false', label: 'Không hoạt động'},
  {value: 'true', label: 'Đang hoạt động'},
];
const optionsActiveTypesEn = [
  {value: 'all', label: 'Operation Status'},
  {value: 'false', label: 'Inactive'},
  {value: 'true', label: 'Active'},
];

const Form = () => {
  const dispatch = useDispatch();
  const {t, i18n} = useTranslation();
  const {handleSubmit, register, control} = useForm();

  const filter = useSelector((state) => state.admin.declareDepartments.filter);
  const onSubmit = (data) => {
    const {identityNumber, status, joinType, authority, online} = data;
    dispatch(
      setFilter({
        ...filter,
        identityNumber,
        joinType: joinType ? joinType.value : 'all',
        status: status ? status.value : 'all',
        pageNumber: 1,
        authority: authority ? authority.value : 'all',
        online: online ? online.value : 'all',
      }),
    );
  };

  return (
    <SFormCheckCouponListPage>
      <Section header={t('Quản lý phiếu biểu quyết')}>
        <div className="module-content">
          <div className="search-wrapper">
            <div className="search-item">
              <Input.Text
                register={register('identityNumber')}
                wrapperProps={{
                  label: t('CMND/CCCD'),
                  icon: inputIcons.account,
                  height: '40px',
                }}
              />
            </div>
            <div className="search-item">
              <Input.Select
                name="status"
                control={control}
                selectProps={{
                  placeholder: t('Trạng thái'),
                  options:
                    i18n.language === 'vi' ? optionStatus : optionStatusEn,
                }}
              />
            </div>
            <div className="search-item">
              <Input.Select
                name="joinType"
                control={control}
                selectProps={{
                  placeholder: t('Hình thức'),
                  options:
                    i18n.language === 'vi'
                      ? optionJoinTypes
                      : optionJoinTypesEn,
                }}
              />
            </div>
            <div className="search-item">
              <Input.Select
                name="authority"
                control={control}
                selectProps={{
                  placeholder: t('Tất cả'),
                  options:
                    i18n.language === 'vi'
                      ? optionsAuthorityTypes
                      : optionsAuthorityTypesEn,
                }}
              />
            </div>
            <div className="search-item">
              <Input.Select
                name="online"
                control={control}
                selectProps={{
                  placeholder: t('Trạng thái hoạt động'),
                  options:
                    i18n.language === 'vi'
                      ? optionsActiveTypes
                      : optionsActiveTypesEn,
                }}
              />
            </div>
            <div className="search-item">
              <Button onClick={handleSubmit(onSubmit)}>
                {' '}
                {t('Tìm kiếm')}{' '}
              </Button>
            </div>
          </div>
        </div>
      </Section>
    </SFormCheckCouponListPage>
  );
};
export default Form;
