import * as constants from '../../../bases/constants';
import inputIcons from '../../../bases/shared/icons/input';
import React from 'react';
export const inputs = [
  {
    name: 'conference.nameCompany',
    require: constants.VALIDATE_REQUIRE,
    rules: null,
    label: 'Tên công ty',
    icon: inputIcons.company,
    type: 'text',
    nested: false,
  },
  {
    name: 'conference.nameCompany_2',
    require: constants.VALIDATE_REQUIRE,
    rules: null,
    label: 'Tên công ty (Tiếng Anh)',
    icon: inputIcons.company,
    type: 'text',
    nested: false,
  },
  {
    name: 'conference.phone',
    require: false,
    rules: null,
    // rules: {
    //   pattern: {
    //     value: constants.VALIDATE_PHONE_REGEX,
    //     message: constants.VALIDATE_PHONE,
    //   },
    // },
    label: 'Số điện thoại',
    icon: inputIcons.phone,
    type: 'tel',
  },
  {
    name: 'conference.fax',
    require: false,
    rules: null,
    label: 'Số fax',
    icon: inputIcons.phone,
    type: 'text',
    nested: false,
  },
  {
    name: 'conference.stockCode',
    require: constants.VALIDATE_REQUIRE,
    rules: null,
    label: 'Mã chứng khoán',
    icon: inputIcons.code,
    type: 'text',
    nested: false,
  },
  {
    name: 'conference.businessCode',
    require: false,
    rules: null,
    label: 'Mã doanh nghiệp',
    icon: inputIcons.code,
    type: 'text',
    nested: false,
  },
  {
    name: 'conference.totalShareholder',
    require: constants.VALIDATE_REQUIRE,
    rules: {
      pattern: {
        value: constants.VALIDATE_ONLY_NUMBER,
        message: constants.VALIDATE_PATTERN,
      },
    },
    label: 'Tổng số Cổ đông',
    icon: inputIcons.shareholder,
    type: 'number',
    nested: false,
  },
  {
    name: 'conference.totalShares',
    require: constants.VALIDATE_REQUIRE,
    rules: {
      pattern: {
        value: constants.VALIDATE_ONLY_NUMBER,
        message: constants.VALIDATE_PATTERN,
      },
    },
    label: 'Tổng số Cổ phần',
    icon: inputIcons.file,
    type: 'number',
    nested: false,
  },
  {
    name: 'conference.address.details',
    require: false,
    rules: null,
    label: 'Trụ sở công ty',
    icon: inputIcons.company,
    type: 'text',
    nested: true,
  },
  {
    name: 'conference.address_2',
    require: false,
    rules: null,
    label: 'Trụ sở công ty (Tiếng Anh)',
    icon: inputIcons.company,
    type: 'text',
    nested: false,
  },
  {
    name: 'conference.addressMeeting.details',
    require: false,
    rules: null,
    label: 'Địa điểm điều hành Đại hội',
    icon: inputIcons.map,
    type: 'text',
    nested: true,
  },
  {
    name: 'conference.addressMeeting_2',
    require: false,
    rules: null,
    label: 'Địa điểm điều hành Đại hội (Tiếng Anh)',
    icon: inputIcons.map,
    type: 'text',
    nested: false,
  },
  // time
  {
    name: 'conference.timeMeeting.start',
    require: false,
    label: 'Thời gian họp',
    icon: inputIcons.time,
    rules: null,
    type: 'text',
    nested: true,
  },
  {
    name: 'conference.timeMeeting_2.start',
    require: false,
    label: 'Thời gian họp (Tiếng Anh)',
    icon: inputIcons.time,
    rules: null,
    type: 'text',
    nested: true,
  },
  // end time
  {
    name: 'conference.nameMeeting',
    require: false,
    rules: null,
    label: 'Tên cuộc họp',
    icon: inputIcons.edit,
    type: 'text',
    nested: false,
  },
  {
    name: 'conference.nameMeeting_2',
    require: false,
    rules: null,
    label: 'Tên cuộc họp (Tiếng Anh)',
    icon: inputIcons.edit,
    type: 'text',
    nested: false,
  },
  {
    name: 'conference.preside',
    require: false,
    rules: null,
    label: 'Chủ tọa Đại hội',
    icon: inputIcons.preside,
    type: 'text',
    nested: false,
  },
  {
    name: 'conference.preside_2',
    require: false,
    rules: null,
    label: 'Chủ tọa Đại hội (Tiếng Anh)',
    icon: inputIcons.preside,
    type: 'text',
    nested: false,
  },
  {
    name: 'conference.secretary',
    require: false,
    rules: null,
    label: 'Thư ký Đại hội',
    icon: inputIcons.women,
    type: 'text',
    nested: false,
  },
  {
    name: 'conference.secretary_2',
    require: false,
    rules: null,
    label: 'Thư ký Đại hội (Tiếng Anh)',
    icon: inputIcons.women,
    type: 'text',
    nested: false,
  },
  {
    name: 'conference.livestreamLink',
    require: false,
    rules: null,
    label: 'Link livestream',
    icon: inputIcons.link,
    type: 'text',
    nested: false,
  },
  {
    name: 'conference.livestreamLink_2',
    require: false,
    rules: null,
    label: 'Link livestream (Tiếng Anh)',
    icon: inputIcons.link,
    type: 'text',
    nested: false,
  },
  {
    name: 'conference.guideLink',
    require: false,
    rules: null,
    label: 'Link video HDSD',
    icon: inputIcons.link,
    type: 'text',
    nested: false,
  },
  {
    name: 'conference.guideLink_2',
    require: false,
    rules: null,
    label: 'Link video HDSD (Tiếng Anh)',
    icon: inputIcons.link,
    type: 'text',
    nested: false,
  },
  {
    name: 'conference.statedLink',
    require: false,
    rules: null,
    label: 'Link phát biểu',
    icon: inputIcons.link,
    type: 'text',
    nested: false,
  },
  {
    name: 'conference.confirmPermissionDate',
    require: false,
    rules: null,
    label: 'Ngày chốt quyền',
    icon: inputIcons.date,
    type: 'text',
  },
  {
    name: 'conference.hotline',
    require: false,
    rules: null,
    label: 'Hotline',
    icon: inputIcons.phone,
    type: 'tel',
  },
];

export const registeredFields = inputs.map((i) => !i.nested && i.name);

export const locationsKey = {
  province: {
    stateKey: 'district',
    apiKey: 'quan-huyen',
  },
  district: {
    stateKey: 'wards',
    apiKey: 'xa-phuong',
  },
  wards: {
    stateKey: null,
    apiKey: null,
  },
};

export const ActiveSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12 5C8.141 5 5 8.141 5 12C5 15.859 8.141 19 12 19C15.859 19 19 15.859 19 12C19 8.141 15.859 5 12 5ZM12 17C9.243 17 7 14.757 7 12C7 9.243 9.243 7 12 7C14.757 7 17 9.243 17 12C17 14.757 14.757 17 12 17Z"
      fill="#0FB77A"
    />
    <path
      d="M12 9C10.373 9 9 10.373 9 12C9 13.627 10.373 15 12 15C13.627 15 15 13.627 15 12C15 10.373 13.627 9 12 9Z"
      fill="#0FB77A"
    />
  </svg>
);

export const DeactiveSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M5 12C5 15.859 8.14 19 12 19C15.859 19 19 15.859 19 12C19 8.141 15.859 5 12 5C8.14 5 5 8.141 5 12ZM17 12C17 14.757 14.757 17 12 17C9.243 17 7 14.757 7 12C7 9.243 9.243 7 12 7C14.757 7 17 9.243 17 12Z"
      fill="#BBBCC1"
    />
  </svg>
);
