import styled from 'styled-components';

export const SAuthorListPage = styled.div``

export const SAuthorityHeader = styled.div`
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  padding: 30px 40px 20px;
  margin-bottom: 27px;

  .title_and_button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;

    .title_lit {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 30px;
      color: #333B6A;
    }

    .list_button {
      display: flex;
      align-items: center;
      margin: 0 -8px;

      button {
        display: flex;
        align-items: center;
        margin: 0 8px;
        min-width: max-content;

        span {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #FFFFFF;
        }

        .img {
          margin-right: 6px;
        }
      }
      
      .grey_button {
        background: #718098;
        position: relative;

        input {
          width: 100%;
          border-radius: 4px;
          height: 35px;
          padding-left: 10px;
          border: 1px solid #ccc;
          margin-bottom: 10px;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          opacity: 0;
        }
      }

      .blue_button {
        background: #2F80ED;
      }
    }
  }

  @media screen and (max-width: 1199px) {
    .title_and_button {
      display: block;
      
      .title_lit {
        margin-bottom: 10px;
      }
    }
  }

  @media screen and (max-width: 767px) {
   .title_and_button .list_button {
      display: grid;
      justify-content: center;
      
      button {
        margin-bottom: 10px;
      }
    }
  }

  @media screen and (max-width: 575px) {
    padding: 5px;

    .title_and_button .list_button {
      display: block;

      button {
        min-width: fit-content;
      }
    }
  }
`

export const SForm = styled.div`
  form {
    margin: 0 32px;
    .common__btn {
      width: 100%; 
    }
  }
`

export const STable = styled.div`
  table tbody tr { 
    user-select: text;
    td { 
      min-width: 130px;
    }

    td:first-child {
      min-width: unset;
    }
  }
`

export const STableTools = styled.div`

`

export const SToll = styled.div`
  display: flex;
`

export const SModalConfirm = styled.div`
  .content {
    text-align: center;
     p {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: #333B6A;
     }
  }
`

export const SModalEdit = styled.div`
    .form-content {
    .list_input {
      .authority-info-item {
        margin-bottom: 20px;
      }
    }

    .button_common {
      display: flex;
      justify-content: flex-end;
    }
  }
`