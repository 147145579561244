import Modal from '@components/modal';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Button from '@components/button';
import { useDispatch, useSelector } from 'react-redux';
import {
  exportLoginAccountApi,
  getQrCodeApi,
} from '@duck/pages/checkin/checkInHandReducer/actions';

const QrModal = ({ isOpen, toggleModal, item }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const qrCode = useSelector((state) => state.checkin.checkInHand.qrCode);

  useEffect(() => {
    if (isOpen) {
      dispatch(getQrCodeApi(item._id));
    }
  }, [isOpen, dispatch]);

  return (
    <Modal
      title={t('Qr code')}
      {...{ isOpen, toggleModal }}
      overwriteModalStyle={{
        maxWidth: '716px',
      }}
    >
      <SModalContent>
        {qrCode && (
          <div style={{ textAlign: 'center', marginTop: '32px' }}>
            <img src={qrCode} />
          </div>
        )}

        <p className="desc">
          {t('Quét mã QR để thực hiện đăng nhập cho Cổ đông')}
        </p>

        <div className={`group-button`}>
          <Button
            onClick={() => {
              dispatch(exportLoginAccountApi(item._id));
            }}
          >
            {t('In tài khoản đăng nhập')}
          </Button>
        </div>
      </SModalContent>
    </Modal>
  );
};

export default QrModal;

const SModalContent = styled.div`
  padding: 0 32px;
  background-color: ${(props) => props.backGroundColor || 'white'};

  margin-top: 44px;

  @media (max-width: 1199px) {
    padding: 0 40px;
  }
  @media (max-width: 991px) {
    padding: 0 20px;
    margin-top: 20px;
  }
  @media (max-width: 767px) {
    padding: 0 15px;
  }

  .desc {
    font-size: 20px;
    color: #718098;
    text-align: center;
  }

  .group-button {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;
    width: 100%;

    & > ${Button} {
      margin-left: 16px;
      margin-bottom: 8px;
      width: fit-content;
    }

    @media (max-width: 991px) {
      margin-top: 20px;
    }
  }
`;
