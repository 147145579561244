import Modal from '@components/modal';
import React, { useEffect, useState } from 'react';
import Input from '@components/input';
import Button from '@components/button';
import styled from 'styled-components';
import Table from '@components/table';
import { useTranslation } from 'react-i18next';
import { routers } from 'components/layout/router';
import { getUserInfo, postPermission } from '../../store';

const AccountPermissionsModal = ({ isOpen, setIsOpen, accounts, refresh }) => {
  const [permissions, setPermissions] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      if (accounts.length === 1) setPermissions(accounts[0].permissions);
      else setPermissions([]);
    }
  }, [isOpen]);

  const onUpdate = () => {
    postPermission(accounts, permissions, () => {
      setIsOpen(false);
      refresh();
    });
  };

  const isCheckedAll = permissions.length === routers.length;

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={setIsOpen}
      title={t('Phân quyền tài khoản')}
      overwriteModalStyle={{
        maxWidth: '900px',
      }}
    >
      <SModalContent>
        <div className="module-table">
          <Table
            customHeader={
              <thead>
                <tr>
                  <th>Stt</th>
                  <th>{t('Chức năng')}</th>
                  <th>
                    <div>
                      <div>{t('Cấp quyền')}</div>
                      <Input.Checkbox
                        className="check_all"
                        checked={isCheckedAll}
                        onChange={() => {
                          let temp = [];
                          if (!isCheckedAll) temp = routers.map((r) => r.per);
                          setPermissions(temp);
                        }}
                      />
                    </div>
                  </th>
                </tr>
              </thead>
            }
          >
            {routers.map((item, index) => {
              const isChecked = permissions?.includes(item.per);
              return (
                <tr key={index + 100}>
                  <td> {index + 1} </td>
                  <td>{item.label}</td>
                  <td className="center">
                    <Input.Checkbox
                      checked={isChecked}
                      onChange={() => {
                        let temp = permissions.slice(0);
                        if (isChecked)
                          temp = permissions.filter((p) => p !== item.per);
                        else temp.push(item.per);

                        setPermissions(temp);
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </Table>
        </div>
        <div className="group-button">
          <Button
            style={{ color: '#718098', backgroundColor: '#DFE9F5' }}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            {t('Hủy bỏ')}
          </Button>
          <Button onClick={onUpdate}>{t('Cập nhật')}</Button>
        </div>
      </SModalContent>
    </Modal>
  );
};

export default AccountPermissionsModal;

const SModalContent = styled.div`
  padding: 0 32px;
  background-color: ${(props) => props.backGroundColor || 'white'};
  margin-top: 25px;
  .module-table {
    .table-container {
      table {
        min-width: 100% !important;
        width: 100%;
        thead {
          tr {
            th {
              padding: 10px 20px 10px 35px;
              .check_all {
                cursor: pointer;
              }
            }
            th:first-child {
              padding: 10px;
            }
            th:last-child {
              padding: 10px;
              text-align: center;
            }
          }
        }
        tbody {
          tr {
            td {
              border: 1px solid #dfe9f5;
              padding: 10px 20px 10px 35px;
            }
            td:first-child {
              padding: 10px;
            }
            td:last-child {
              padding: 10px;
              text-align: center;
            }
          }
        }
      }
    }
  }
  .group-button {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;
    width: 100%;
    gap: 15px 20px;

    @media (max-width: 991px) {
      margin-top: 20px;
    }
  }

  @media (max-width: 1199px) {
    padding: 0 40px;
  }
  @media (max-width: 991px) {
    padding: 0 20px;
    margin-top: 20px;
  }
  @media (max-width: 767px) {
    padding: 0 15px;
  }

  .group-button {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;
    width: 100%;

    @media (max-width: 991px) {
      margin-top: 20px;
    }
  }
  .form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;
