//  MODAL BIEU QUYET - VALID
// TOPIC KIND 1 2 3

import Pagination from '@components/pagination';
import Table from '@components/table';
import { setFilterModal } from '@duck/pages/checkCoupon/checkCouponResultReducer/actions';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { transformNumberPrinter } from '@configs/transformNumber';
import inputIcons from '@components/icons/input';
import Input from '@components/input';
import Button from '@components/button';
import { useTranslation } from 'react-i18next';
import {
  pointOptions,
  pointOptionsEn,
  thead_bieu_quyet_common,
  thead_bieu_quyet_valid_all,
  voteStatusOptions,
  voteStatusOptionsEn,
} from '../data';

export const ModalVoteValid = ({ kind }) => {
  const { register, setValue, watch, control, handleSubmit } = useForm();
  const info = useSelector((state) => state.admin.configSystem.info);
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { filterModal, listResultModal, listResultTotal } = useSelector(
    (state) => state.checkCoupon.checkCouponResult,
  );
  const [options, setOptions] = useState([
    {
      label: 'Tất cả',
      value: 'all',
    },
  ]);
  const [optionsEn, setOptionsEn] = useState([
    {
      label: 'All',
      value: 'all',
    },
  ]);
  useEffect(() => {
    setValue(
      'voteStatus',
      language === 'vi' ? voteStatusOptions[0] : voteStatusOptionsEn[0],
    );
    setValue('point', language === 'vi' ? options[0] : optionsEn[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue]);

  useEffect(() => {
    if (info) {
      setOptions(
        [...options].concat(
          info?.voteSelections
            ?.filter((i) => {
              return i.lang === 'vi';
            })
            .map((v) => {
              return {
                label: v.displayName,
                value: v.point,
              };
            }),
        ),
      );
      setOptionsEn(
        [...optionsEn].concat(
          info?.voteSelections
            ?.filter((i) => {
              return i.lang === 'en';
            })
            .map((v) => {
              return {
                label: v.displayName,
                value: v.point,
              };
            }),
        ),
      );
    }
  }, [info]);


  const dispatch = useDispatch();
  const changeSize = (take) => {
    dispatch(
      setFilterModal({
        ...filterModal,
        take,
        page: 1,
      }),
    );
  };

  const changePage = (page) => {
    dispatch(
      setFilterModal({
        ...filterModal,
        page,
      }),
    );
  };
  useEffect(() => {
    setValue('tabType', 'all');
  }, [kind, setValue]);
  // useEffect(() => {
  //   dispatch(
  //     setFilterModal({
  //       ...filterModal,
  //       point: watch('tabType'),
  //     }),
  //   );
  // }, [watch('tabType'), dispatch]);

  const onSearch = (value) => {
    const { search, point, voteStatus } = value;
    dispatch(
      setFilterModal({
        ...filterModal,
        point: point ? point.value : 'all',
        voteStatus: voteStatus ? voteStatus.value : 'voted',
        search,
        page: 1,
        take: 10,
        kind,
      }),
    );
  };
  return (
    <>
      <div className="tab-container">
        <div className="form">
          <div className="form-item">
            <Input.Select
              name="voteStatus"
              control={control}
              selectProps={{
                options:
                  language === 'vi' ? voteStatusOptions : voteStatusOptionsEn,
              }}
            />
          </div>
          {watch('voteStatus') && watch('voteStatus').value === 'voted' && (
            <div className="form-item">
              <Input.Select
                name="point"
                control={control}
                selectProps={{
                  options: language === 'vi' ? options : optionsEn,
                }}
              />
            </div>
          )}
          <div className="form-item">
            <Input.Text
              register={register('search')}
              wrapperProps={{
                label: t('Họ tên/CMND/CCCD'),
                icon: inputIcons.account,
                required: true,
                height: '40px',
              }}
            />
          </div>
          <div className="form-item">
            <Button onClick={handleSubmit(onSearch)}> {t('Tìm kiếm')} </Button>
          </div>
        </div>

        <div className="table-container">
          <Table
            header={
              filterModal.voteStatus === 'voted' && filterModal.point === 'all'
                ? thead_bieu_quyet_valid_all
                : thead_bieu_quyet_common
            }
          >
            <>
              {listResultModal.length > 0 ? (
                <>
                  {listResultModal.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className="center">{index + 1}</td>
                        <td>
                          {filterModal.voteStatus === 'notVote'
                            ? item.name
                            : item.sh
                            ? item.sh.name
                            : ''}
                        </td>
                        <td>
                          {filterModal.voteStatus === 'notVote'
                            ? item.identityNumber
                            : item.sh
                            ? item.sh.identityNumber
                            : ''}
                        </td>
                        {kind !== 4 && (
                          <td>
                            {filterModal.voteStatus === 'notVote'
                              ? transformNumberPrinter(item.sharesNumber)
                              : item.sh
                              ? transformNumberPrinter(item.sh.sharesNumber)
                              : ''}
                          </td>
                        )}
                        {kind !== 4 && (
                          <td>
                            {
                              filterModal.voteStatus === 'notVote'
                                ? item.sharesNumber === 0
                                  ? transformNumberPrinter(
                                      item.sharesJoinNumber +
                                        item.sharesAuthorizedNumber,
                                    )
                                  : transformNumberPrinter(
                                      item.sharesJoinNumber,
                                    )
                                : transformNumberPrinter(item.sharesNumber)
                              // item.sh && item.sh.isCheckedIn
                              // ? item.sh.sharesNumber === 0
                              //   ? transformNumberPrinter(
                              //       item.sh.sharesJoinNumber +
                              //         item.sh.sharesAuthorizedNumber,
                              //     )
                              //   : transformNumberPrinter(
                              //       item.sh.sharesJoinNumber,
                              //     )
                              // : 0
                            }
                          </td>
                        )}
                        {filterModal.voteStatus === 'voted' &&
                          filterModal.point === 'all' && (
                            <td>
                              {item.point === 1
                                ? t('Tán thành BKP')
                                : item.point === 2
                                ? t('Không tán thành BKP')
                                : t('Không ý kiến BKP')}
                            </td>
                          )}
                      </tr>
                    );
                  })}
                  <tr>
                    <td colSpan={100} className="center">
                      <Pagination
                        handleChangePageSize={changeSize}
                        current={filterModal.page}
                        total={listResultTotal}
                        pageSize={filterModal.take}
                        onChange={changePage}
                      />
                    </td>
                  </tr>
                </>
              ) : (
                <tr>
                  <td colSpan={100} className="center">
                    {t('Không có dữ liệu')}
                  </td>
                </tr>
              )}
            </>
          </Table>
        </div>
      </div>
    </>
  );
};
