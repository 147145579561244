import React, { useEffect } from 'react';
import {
  getCreateApi,
  getUserApi,
} from '../../../../states/duck/pages/checkin/inspectionRecordsReducer/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  SExport,
  STableCheckinManagerment,
} from '@styled/checkIn/checkInManagerPage';
import Table from '@components/table';
import Button from '@components/button';
import { formatDateTime } from '@configs/formatDate';
// import {URL_RESULT} from '@state-common/constants';
// import FileSaver from 'file-saver';
// import { publicUrl } from '../../../bases/configs/publicUrl';
import { useTranslation } from 'react-i18next';

const theads = [
  { name: 'Stt' },
  { name: 'Biên bản kiểm tra tư cách' },
  { name: 'Danh sách Cổ đông' },
  { name: 'Thời gian tạo' },
];

const TableCheckinManagerment = () => {
  const dispatch = useDispatch();
  let table = useSelector((state) => state.checkin.inspectionRecords.tableList);
  const { t } = useTranslation();

  // const [arr, setArr] = useState([]);
  // useEffect(() => {
  //   setArr([...table].slice());
  // }, [table]);

  useEffect(() => {
    dispatch(getUserApi());
  }, [dispatch]);

  return (
    <>
      <SExport>
        <Button
          className="button_export"
          onClick={() => dispatch(getCreateApi())}
        >
          <div className="img">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.11111 10.8235V11.1765C5.42633 9.35671 6.91111 8.38612 8.77778 8.35294V10.4706C8.77778 10.8595 9.17522 11.1765 9.66678 11.1765C9.94989 11.1765 10.1918 11.0649 10.3536 10.9005C11.857 9.46824 15 6.58824 15 6.58824C15 6.58824 11.857 3.70682 10.3528 2.25624C10.1698 2.09267 9.92357 2.00068 9.66678 2C9.17522 2 8.77778 2.31553 8.77778 2.70588V4.82353C5.15333 4.82353 4.11111 8.26188 4.11111 10.8235ZM1.77778 14H12.6667C12.8729 14 13.0708 13.9256 13.2166 13.7933C13.3625 13.6609 13.4444 13.4813 13.4444 13.2941V9.02635C12.928 9.50353 12.3836 10.0096 11.8889 10.4713V12.5882H2.55556V4.11765H8V2.70588H1.77778C1.5715 2.70588 1.37367 2.78025 1.22781 2.91263C1.08194 3.04501 1 3.22455 1 3.41176V13.2941C1 13.4813 1.08194 13.6609 1.22781 13.7933C1.37367 13.9256 1.5715 14 1.77778 14Z"
                fill="white"
              />
            </svg>
          </div>
          <span>{t('Xuất biên bản kiểm tra tư cách')}</span>
        </Button>
      </SExport>

      <STableCheckinManagerment>
        <Table header={theads}>
          {table.length > 0 ? (
            table.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>

                  <td>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span>{`${item.condition}`}</span>
                      <span
                        className="dowload_file"
                        onClick={() => {
                          window.open(
                            `${process.env.REACT_APP_UPLOAD}/${item.condition}`,
                          );
                          // var file = new Blob([item.condition], {
                          //   type:
                          //     'application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,',
                          // });
                          // FileSaver.saveAs(file, 'File biên bản');
                        }}
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15.833 15H4.16634C3.7061 15 3.33301 15.3731 3.33301 15.8333C3.33301 16.2936 3.7061 16.6667 4.16634 16.6667H15.833C16.2932 16.6667 16.6663 16.2936 16.6663 15.8333C16.6663 15.3731 16.2932 15 15.833 15Z"
                            fill="#718098"
                          />
                          <path
                            d="M3.33301 14.1699V15.8366C3.33301 16.2968 3.7061 16.6699 4.16634 16.6699C4.62658 16.6699 4.99967 16.2968 4.99967 15.8366V14.1699C4.99967 13.7097 4.62658 13.3366 4.16634 13.3366C3.7061 13.3366 3.33301 13.7097 3.33301 14.1699Z"
                            fill="#718098"
                          />
                          <path
                            d="M15 14.1699V15.8366C15 16.2968 15.3731 16.6699 15.8333 16.6699C16.2936 16.6699 16.6667 16.2968 16.6667 15.8366V14.1699C16.6667 13.7097 16.2936 13.3366 15.8333 13.3366C15.3731 13.3366 15 13.7097 15 14.1699Z"
                            fill="#718098"
                          />
                          <path
                            d="M9.99988 12.4967C9.82711 12.4981 9.65821 12.4456 9.51654 12.3467L6.18321 9.99675C6.00355 9.8693 5.88166 9.67591 5.84419 9.45885C5.80671 9.24179 5.85669 9.01873 5.98321 8.83841C6.04637 8.74829 6.12675 8.67156 6.21972 8.61267C6.31268 8.55378 6.4164 8.51388 6.52486 8.49529C6.63333 8.47669 6.74441 8.47976 6.85169 8.50433C6.95896 8.5289 7.06031 8.57447 7.14988 8.63841L9.99988 10.6301L12.8332 8.49675C13.01 8.36414 13.2323 8.3072 13.4511 8.33846C13.6699 8.36971 13.8673 8.4866 13.9999 8.66341C14.1325 8.84023 14.1894 9.06247 14.1582 9.28127C14.1269 9.50006 14.01 9.69747 13.8332 9.83008L10.4999 12.3301C10.3556 12.4383 10.1802 12.4967 9.99988 12.4967Z"
                            fill="#718098"
                          />
                          <path
                            d="M10.0003 10.8333C9.77931 10.8333 9.56735 10.7455 9.41107 10.5893C9.25479 10.433 9.16699 10.221 9.16699 10V3.33333C9.16699 3.11232 9.25479 2.90036 9.41107 2.74408C9.56735 2.5878 9.77931 2.5 10.0003 2.5C10.2213 2.5 10.4333 2.5878 10.5896 2.74408C10.7459 2.90036 10.8337 3.11232 10.8337 3.33333V10C10.8337 10.221 10.7459 10.433 10.5896 10.5893C10.4333 10.7455 10.2213 10.8333 10.0003 10.8333Z"
                            fill="#718098"
                          />
                        </svg>
                      </span>
                    </div>
                  </td>

                  <td>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span>{item.checkin}</span>
                      <span
                        className="dowload_file"
                        onClick={() => {
                          window.open(
                            `${process.env.REACT_APP_UPLOAD}/${item.checkin}`,
                          );
                          // var file = new Blob([item.checkin], {
                          //   type:
                          //     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
                          // });
                          // FileSaver.saveAs(file, 'Danh sách CĐ checkin');
                        }}
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15.833 15H4.16634C3.7061 15 3.33301 15.3731 3.33301 15.8333C3.33301 16.2936 3.7061 16.6667 4.16634 16.6667H15.833C16.2932 16.6667 16.6663 16.2936 16.6663 15.8333C16.6663 15.3731 16.2932 15 15.833 15Z"
                            fill="#718098"
                          />
                          <path
                            d="M3.33301 14.1699V15.8366C3.33301 16.2968 3.7061 16.6699 4.16634 16.6699C4.62658 16.6699 4.99967 16.2968 4.99967 15.8366V14.1699C4.99967 13.7097 4.62658 13.3366 4.16634 13.3366C3.7061 13.3366 3.33301 13.7097 3.33301 14.1699Z"
                            fill="#718098"
                          />
                          <path
                            d="M15 14.1699V15.8366C15 16.2968 15.3731 16.6699 15.8333 16.6699C16.2936 16.6699 16.6667 16.2968 16.6667 15.8366V14.1699C16.6667 13.7097 16.2936 13.3366 15.8333 13.3366C15.3731 13.3366 15 13.7097 15 14.1699Z"
                            fill="#718098"
                          />
                          <path
                            d="M9.99988 12.4967C9.82711 12.4981 9.65821 12.4456 9.51654 12.3467L6.18321 9.99675C6.00355 9.8693 5.88166 9.67591 5.84419 9.45885C5.80671 9.24179 5.85669 9.01873 5.98321 8.83841C6.04637 8.74829 6.12675 8.67156 6.21972 8.61267C6.31268 8.55378 6.4164 8.51388 6.52486 8.49529C6.63333 8.47669 6.74441 8.47976 6.85169 8.50433C6.95896 8.5289 7.06031 8.57447 7.14988 8.63841L9.99988 10.6301L12.8332 8.49675C13.01 8.36414 13.2323 8.3072 13.4511 8.33846C13.6699 8.36971 13.8673 8.4866 13.9999 8.66341C14.1325 8.84023 14.1894 9.06247 14.1582 9.28127C14.1269 9.50006 14.01 9.69747 13.8332 9.83008L10.4999 12.3301C10.3556 12.4383 10.1802 12.4967 9.99988 12.4967Z"
                            fill="#718098"
                          />
                          <path
                            d="M10.0003 10.8333C9.77931 10.8333 9.56735 10.7455 9.41107 10.5893C9.25479 10.433 9.16699 10.221 9.16699 10V3.33333C9.16699 3.11232 9.25479 2.90036 9.41107 2.74408C9.56735 2.5878 9.77931 2.5 10.0003 2.5C10.2213 2.5 10.4333 2.5878 10.5896 2.74408C10.7459 2.90036 10.8337 3.11232 10.8337 3.33333V10C10.8337 10.221 10.7459 10.433 10.5896 10.5893C10.4333 10.7455 10.2213 10.8333 10.0003 10.8333Z"
                            fill="#718098"
                          />
                        </svg>
                      </span>
                    </div>
                  </td>

                  <td>{formatDateTime(item.createdAt)}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="5" className="center">
                {t('Không có dữ liệu')}
              </td>
            </tr>
          )}
        </Table>
      </STableCheckinManagerment>
    </>
  );
};

export default TableCheckinManagerment;
