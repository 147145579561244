import * as constants from './constants';
import {callApi, callExportApi} from '../../../../common';
import PNotify from 'pnotify/dist/es/PNotify';
import {handleError} from '@configs/handleError';
var FileSaver = require('file-saver');

export const getOpinionApi = (filter) => {
  const {pageSize, pageNumber, status, identityNumber, typeList, blockStatus} =
    filter;
  if (typeList === 'all') {
    return (dispatch) =>
      callApi(`${constants.URL_ALL}/${pageSize}/${pageNumber}`, 'POST', {
        identityNumber,
        status: 'all',
        joinType: 'all',
        authority: 'all',
        // blockStatus: blockStatus === 'all' ? undefined : blockStatus,
      })
        .then(function (res) {
          dispatch(getListAccount(res.data.data.shareholders));
          dispatch(setTotal(res.data.data.total));
        })
        .catch(handleError);
  } else {
    return (dispatch) =>
      callApi(`${constants.URL}/${status}/${pageSize}/${pageNumber}`, 'POST', {
        identityNumber,
        blockStatus: blockStatus === 'all' ? undefined : blockStatus,
      })
        .then(function (res) {
          dispatch(getListAccount(res.data.data.opinion));
          dispatch(setTotal(res.data.data.total));
        })
        .catch(handleError);
  }
};

export const getChatboxApi = (identityNumber) => {
  return (dispatch) =>
    callApi(`${constants.URL}/all/50/1`, 'POST', {
      identityNumber,
    })
      .then(function (res) {
        if (res.data.data.total > 50) {
          callApi(`${constants.URL}/all/${res.data.data.total}/1`, 'POST', {
            identityNumber,
          }).then(function (res2) {
            dispatch(setChatbox(res2.data.data.opinion));
          });
        } else {
          dispatch(setChatbox(res.data.data.opinion));
        }
      })
      .catch(handleError);
};

export const blockOpinionApi = (data, filter) => {
  const {isBlockOpinion, shareholderId} = data;
  return (dispatch) =>
    callApi(`${constants.URL_BLOCK_OPINION}`, 'POST', {
      isBlockOpinion,
      shareholderId,
    })
      .then(function (res) {
        dispatch(getOpinionApi(filter));
        dispatch(setToggleModal(false));
        dispatch(setChooseList([]));
        PNotify.success({
          title: 'Thành công',
          text: isBlockOpinion
            ? 'Chặn Cổ đông gửi ý kiến thành công!'
            : 'Bỏ chặn Cổ đông gửi ý kiến thành công!',
          destroy: true,
          delay: 2000,
        });
      })
      .catch(handleError);
};

export const inviteSpeakApi = (body) => {
  return (dispatch) =>
    callApi(`${constants.URL_INVITE_SPEAK}`, 'POST', body)
      .then(function (res) {
        PNotify.success({
          title: 'Thành công',
          text: 'Mời Cổ đông phát biểu thành công',
          destroy: true,
          delay: 2000,
        });
      })
      .catch(handleError);
};

export const setChatbox = (value) => {
  return {
    type: constants.SET_CHATBOX,
    value,
  };
};

export const exportOpinionListApi = () => {
  return (dispatch) =>
    callExportApi(`${constants.URL_EXPORT}`, 'POST', null)
      .then(function (res) {
        var file = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        FileSaver.saveAs(file, 'DanhsachYkienCodong');
        PNotify.success({
          title: 'Thành công',
          text: 'Xuất file ý kiến Cổ đông thành công!',
          destroy: true,
          delay: 2000,
        });
      })
      .catch(handleError);
};
export const sendBossOpinionApi = (body, filter) => {
  return (dispatch) =>
    callApi(`${constants.URL_SEND_BOSS}`, 'POST', body)
      .then(function (res) {
        dispatch(getOpinionApi(filter));
        dispatch(setChooseList([]));
        PNotify.success({
          title: 'Thành công',
          text: 'Chuyển ý kiến đến chủ chủ tịch thành công',
          destroy: true,
          delay: 2000,
        });
      })
      .catch(handleError);
};

export const replyOpinionApi = (body) => {
  const {id, message, time, identityNumber} = body;
  return (dispatch) => {
    callApi(`${constants.URL_REPLY_OPINION}/${id}`, 'POST', {
      message,
      time,
    })
      .then(function (res) {
        dispatch(getChatboxApi(identityNumber));
      })
      .catch(handleError);
  };
};

export const getListAccount = (value) => {
  return {
    type: constants.GET_LIST,
    value,
  };
};
export const setChooseList = (value) => {
  return {
    type: constants.SET_CHOOSE_LIST,
    value,
  };
};
export const setLockShareholer = (value) => {
  return {
    type: constants.SET_LOCK_SHAREHOLDER,
    value,
  };
};
export const setTotal = (value) => {
  return {
    type: constants.SET_TOTAL,
    value,
  };
};
export const setFilter = (value) => {
  return {
    type: constants.SET_FILTER,
    value,
  };
};

export const setToggleModal = (value) => {
  return {
    type: constants.SET_TOGGLE_MODAL,
    value,
  };
};
