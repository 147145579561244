export const URL = 'check-in/list';
export const URL_CHECKIN = 'check-in';
export const URL_AUTHORITY_CREATE = 'authority/create';
export const URL_SEARCH = 'info/shareholder';
export const URL_REPORT = 'topic/kind';
export const URL_CONFERENCE_INFO = 'conference';
export const URL_VOTE_INFO = 'shareholder';
export const URL_AUTHORIZED = 'authority/authorized-shareholder';
export const URL_EXPORT_LOGIN_ACCOUNT = 'check-in/export-login-account';
export const URL_QR_CODE = 'shareholder/generate-qrcode';
export const URL_DOWNLOAD_VOTE = 'vote/download-vote-ticket';
export const GET_CONFERENCE_INFO = 'GET_CHECKIN_HAND_CONFERENCE_INFO';
export const GET_REPORT1 = 'GET_CHECKIN_HAND_REPORT1';
export const GET_REPORT2 = 'GET_CHECKIN_HAND_REPORT2';
export const GET_REPORT3 = 'GET_CHECKIN_HAND_REPORT3';
export const GET_REPORT4 = 'GET_CHECKIN_HAND_REPORT4';
export const GET_REPORT6 = 'GET_CHECKIN_HAND_REPORT6';

export const GET_LIST = 'GET_CHECKIN_HAND_LIST';
export const GET_LIST_AUTHORIZED = 'GET_LIST_AUTHORIZED';
export const GET_CHECK = 'GET_CHECKIN_HAND_CHECK';
export const GET_ID_CARD = 'GET_CHECKIN_HAND_ID_CARD';
export const GET_STATUS = 'GET_CHECKIN_HAND_STATUS';
export const GET_TOTAL = 'GET_CHECKIN_HAND_TOTAL';
export const GET_AUTHORITY_ITEM = 'GET_CHECKIN_HAND_AUTHORITY_ITEM';
export const GET_INFO_SHAREHOLDER = 'GET_INFO_CHECKIN_HAND_SHAREHOLDER';
export const GET_QR_CODE = 'GET_QR_CODE';
export const SET_TOGGLE_MODAL = 'SET_CHECKIN_HAND_TOGGLE_MODAL';
export const SET_CHOOSE_LIST = 'SET_CHECKIN_HAND_CHOOSE_LIST';
export const SET_FILTER = 'SET_FILTER';
