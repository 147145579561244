import React, { Fragment, useState, useEffect } from 'react';
import TableCheckInHand from './table';
import Form from './form';
import Action from './action';
import { useDispatch } from 'react-redux';
import {
  getReport1Api,
  getReport2Api,
  getReport3Api,
  getReport4Api,
  getReport6Api,
  getConferenceInfoApi,
} from 'states/duck/pages/checkin/checkInHandReducer/actions';
import { SCheckInHandPage } from '@styled/checkIn/checkInHandPage';
import { useTranslation } from 'react-i18next';

const CheckInHandPage = ({ match }) => {
  // const [checkinItemReport, setCheckinItemReport] = useState([]);
  // console.log('checkinItemReport: ', checkinItemReport);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getReport1Api(1));
    dispatch(getReport2Api(2));
    dispatch(getReport3Api(3));
    dispatch(getReport4Api(4));
    dispatch(getReport6Api(6));

    dispatch(getConferenceInfoApi());
  }, [dispatch]);
  // const report1 = useSelector((state) => state.checkin.checkInHand.report1);
  // const report2 = useSelector((state) => state.checkin.checkInHand.report2);
  // const report3 = useSelector((state) => state.checkin.checkInHand.report3);
  // const report4 = useSelector((state) => state.checkin.checkInHand.report4);
  // const conference = useSelector(
  //   (state) => state.checkin.checkInHand.conference,
  // );

  return (
    <SCheckInHandPage>
      {!match?.params?.id || match?.params?.id === '' ? (
        <div className="module-checkin">
          <div className="module-header">
            <Form />
          </div>
          <div className="module-content">
            <Fragment>
              <div className="table-result">
                <TableCheckInHand />
              </div>
            </Fragment>
          </div>
        </div>
      ) : (
        <Action
          // setCheckinItemReport={setCheckinItemReport}
          id={match.params.id}
          type={match}
        />
      )}

      {/* <div className="vote-page">
        {checkinItemReport ? (
          <VotePage
            checkinItem={checkinItemReport}
            report4={report4}
            report1={report1}
            report2={report2}
            report3={report3}
            conference={conference}
          />
        ) : (
          ''
        )}
      </div> */}
    </SCheckInHandPage>
  );
};
export default CheckInHandPage;

// const VotePage = ({
//   report1,
//   report2,
//   report3,
//   report4,
//   checkinItem,
//   conference,
// }) => {
//   // console.log('report3: ', report3);
//   const now = new Date();
//   report1.sort((a, b) => {
//     let createA = new Date(a.createdAt).getTime();
//     let createB = new Date(b.createdAt).getTime();
//     if (createA > createB) {
//       return 1;
//     } else if (createA < createB) {
//       return -1;
//     }
//     return 0;
//   });
//   report2.sort((a, b) => {
//     if (a.order > b.order) {
//       return 1;
//     } else if (a.order < b.order) {
//       return -1;
//     }
//     return 0;
//   });
//   report3.sort((a, b) => {
//     if (a.order > b.order) {
//       return 1;
//     } else if (a.order < b.order) {
//       return -1;
//     }
//     return 0;
//   });
//   report4.sort((a, b) => {
//     // let createA = new Date(a.createdAt).getTime();
//     // let createB = new Date(b.createdAt).getTime();
//     // if (createA > createB) {
//     //   return 1;
//     // } else if (createA < createB) {
//     //   return -1;
//     // }
//     // return 0;
//     if (a.order > b.order) {
//       return 1;
//     } else if (a.order < b.order) {
//       return -1;
//     }
//     return 0;
//   });

//   // console.log('conference: ', conference);

//   if (conference) {
//     return (
//       <div id="print" style={{ display: 'none' }}>
//         <style
//           dangerouslySetInnerHTML={{
//             __html:
//               '\n@media print{html, body {border: 1px solid white;height: 99%;page-break-after: avoid !important;page-break-before: avoid !important;}} table { page-break-inside:auto } tr{ page-break-inside:avoid; page-break-after:auto }   .break-page{ page-break-before: always } \n',
//           }}
//         />
//         {checkinItem.map((checkinItemMap, checkinItemIndex) => {
//           // console.log('checkinItemMap', checkinItemMap);
//           return (
//             <Fragment key={checkinItemIndex}>
//               <div
//                 style={{
//                   width: '480pt',
//                   margin: '0 auto',
//                   pageBreakAfter: 'always',
//                 }}
//               >
//                 <div style={{ textAlign: 'center' }}>
//                   <table
//                     cellPadding={0}
//                     cellSpacing={0}
//                     style={{
//                       width: '106.28%',
//                       marginRight: 'auto',
//                       marginLeft: 'auto',
//                       borderCollapse: 'collapse',
//                     }}
//                   >
//                     <tbody>
//                       <tr>
//                         <td
//                           style={{
//                             width: '37.58%',
//                             paddingRight: '5.4pt',
//                             paddingLeft: '5.4pt',
//                             verticalAlign: 'top',
//                           }}
//                         >
//                           <p
//                             style={{
//                               marginTop: '4pt',
//                               marginBottom: '4pt',
//                               textAlign: 'center',
//                               pageBreakAfter: 'avoid',
//                               lineHeight: '14pt',
//                               widows: 0,
//                               orphans: 0,
//                             }}
//                           >
//                             <strong>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '10.5pt',
//                                   textTransform: 'uppercase',
//                                 }}
//                               >
//                                 {conference.conference.nameCompany}
//                               </span>
//                             </strong>
//                           </p>
//                           <p
//                             style={{
//                               marginTop: '4pt',
//                               marginBottom: '4pt',
//                               textAlign: 'center',
//                               pageBreakAfter: 'avoid',
//                               lineHeight: '14pt',
//                               widows: 0,
//                               orphans: 0,
//                             }}
//                           >
//                             <strong>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '10.5pt',
//                                 }}
//                               >
//                                 MS: {conference.conference.businessCode}
//                               </span>
//                             </strong>
//                           </p>
//                           <p
//                             style={{
//                               marginTop: '4pt',
//                               marginBottom: '4pt',
//                               textAlign: 'center',
//                               pageBreakAfter: 'avoid',
//                               lineHeight: '14pt',
//                               widows: 0,
//                               orphans: 0,
//                             }}
//                           >
//                             <strong>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '10.5pt',
//                                 }}
//                               >
//                                 ---o0o---
//                               </span>
//                             </strong>
//                           </p>
//                           <p
//                             style={{
//                               marginTop: '4pt',
//                               marginBottom: '4pt',
//                               textAlign: 'center',
//                               pageBreakAfter: 'avoid',
//                               lineHeight: '14pt',
//                               widows: 0,
//                               orphans: 0,
//                             }}
//                           >
//                             <em>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '10.5pt',
//                                 }}
//                               >
//                                 Số
//                               </span>
//                             </em>
//                             <em>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '10.5pt',
//                                 }}
//                               >
//                                 : 01/{now.getFullYear()}/PBQN1-ĐHĐCĐ-
//                                 {conference.conference.stockCode}
//                               </span>
//                             </em>
//                           </p>
//                         </td>
//                         <td
//                           style={{
//                             width: '62.42%',
//                             paddingRight: '5.4pt',
//                             paddingLeft: '5.4pt',
//                             verticalAlign: 'top',
//                           }}
//                         >
//                           <p
//                             style={{
//                               marginTop: '4pt',
//                               marginBottom: '4pt',
//                               textAlign: 'right',
//                               pageBreakAfter: 'avoid',
//                               lineHeight: '14pt',
//                               widows: 0,
//                               orphans: 0,
//                             }}
//                           >
//                             <strong>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '10.5pt',
//                                 }}
//                               >
//                                 CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM
//                               </span>
//                             </strong>
//                           </p>
//                           <p
//                             style={{
//                               marginTop: '4pt',
//                               marginBottom: '4pt',
//                               textAlign: 'center',
//                               pageBreakAfter: 'avoid',
//                               lineHeight: '14pt',
//                               widows: 0,
//                               orphans: 0,
//                             }}
//                           >
//                             <strong>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '10.5pt',
//                                 }}
//                               >
//                                 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                               </span>
//                             </strong>
//                             <strong>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '10.5pt',
//                                 }}
//                               >
//                                 Độc lập - Tự do - Hạnh phúc
//                               </span>
//                             </strong>
//                           </p>
//                           <p
//                             style={{
//                               marginTop: '4pt',
//                               marginBottom: '4pt',
//                               textAlign: 'center',
//                               pageBreakAfter: 'avoid',
//                               lineHeight: '14pt',
//                               widows: 0,
//                               orphans: 0,
//                             }}
//                           >
//                             <strong>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '10.5pt',
//                                 }}
//                               >
//                                 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                               </span>
//                             </strong>
//                             <strong>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '10.5pt',
//                                 }}
//                               >
//                                 -----o0o-----
//                               </span>
//                             </strong>
//                           </p>
//                           <p
//                             style={{
//                               marginTop: '4pt',
//                               marginBottom: '4pt',
//                               textAlign: 'center',
//                               pageBreakAfter: 'avoid',
//                               lineHeight: '14pt',
//                               widows: 0,
//                               orphans: 0,
//                             }}
//                           >
//                             <strong>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '10.5pt',
//                                 }}
//                               >
//                                 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                               </span>
//                             </strong>
//                             <strong>
//                               <em>
//                                 <span
//                                   style={{
//                                     fontFamily: '"times"',
//                                     fontSize: '12pt',
//                                   }}
//                                 >
//                                   &nbsp;&nbsp;&nbsp;&nbsp;
//                                 </span>
//                               </em>
//                             </strong>
//                             <em>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '12pt',
//                                 }}
//                               >
//                                 {conference.conference.addressMeeting.province}
//                               </span>
//                             </em>
//                             <em>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '12pt',
//                                 }}
//                               >
//                                 , ngày&nbsp;
//                               </span>
//                             </em>
//                             <em>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '12pt',
//                                 }}
//                               >
//                                 {now.getDate().toString().padStart(2, 0)}
//                               </span>
//                             </em>
//                             <em>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '12pt',
//                                 }}
//                               >
//                                 &nbsp; tháng&nbsp;
//                               </span>
//                             </em>
//                             <em>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '12pt',
//                                 }}
//                               >
//                                 {(now.getMonth() + 1).toString().padStart(2, 0)}
//                               </span>
//                             </em>
//                             <em>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '12pt',
//                                 }}
//                               >
//                                 &nbsp; năm&nbsp;
//                               </span>
//                             </em>
//                             <em>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '12pt',
//                                 }}
//                               >
//                                 {now.getFullYear()}
//                               </span>
//                             </em>
//                           </p>
//                         </td>
//                       </tr>
//                     </tbody>
//                   </table>
//                 </div>
//                 <p
//                   style={{
//                     marginTop: '0pt',
//                     marginBottom: '6pt',
//                     pageBreakAfter: 'avoid',
//                     lineHeight: '120%',
//                     widows: 0,
//                     orphans: 0,
//                     fontSize: '14pt',
//                   }}
//                 >
//                   <strong>
//                     <span style={{ fontFamily: '"times"' }}>&nbsp;</span>
//                   </strong>
//                 </p>
//                 <p
//                   style={{
//                     marginTop: '0pt',
//                     marginBottom: '6pt',
//                     textAlign: 'center',
//                     pageBreakAfter: 'avoid',
//                     lineHeight: '120%',
//                     widows: 0,
//                     orphans: 0,
//                     fontSize: '14pt',
//                   }}
//                 >
//                   <strong>
//                     <span style={{ fontFamily: '"times"' }}>
//                       PHIẾU BIỂU QUYẾT
//                     </span>
//                   </strong>
//                 </p>
//                 <p
//                   style={{
//                     marginTop: '0pt',
//                     marginBottom: '6pt',
//                     textAlign: 'center',
//                     pageBreakAfter: 'avoid',
//                     lineHeight: '120%',
//                     widows: 0,
//                     orphans: 0,
//                     fontSize: '14pt',
//                   }}
//                 >
//                   <strong>
//                     <span
//                       style={{
//                         fontFamily: '"times"',
//                         fontSize: '12pt',
//                         textTransform: 'uppercase',
//                       }}
//                     >
//                       ĐẠI HỘI ĐỒNG CỔ ĐÔNG THƯỜNG NIÊN NĂM {now.getFullYear()}{' '}
//                       {conference.conference.nameCompany}
//                     </span>
//                   </strong>
//                 </p>
//                 <p
//                   style={{
//                     marginTop: '6pt',
//                     marginBottom: '6pt',
//                     pageBreakInside: 'avoid',
//                     widows: 0,
//                     orphans: 0,
//                     fontSize: '11pt',
//                   }}
//                 >
//                   <strong>
//                     <span
//                       style={{
//                         fontFamily: '"times"',
//                         textTransform: 'uppercase',
//                       }}
//                     >
//                       Tên Cổ Đông: {checkinItemMap.name}
//                     </span>
//                   </strong>
//                 </p>
//                 <p
//                   style={{
//                     marginTop: '6pt',
//                     marginBottom: '6pt',
//                     pageBreakInside: 'avoid',
//                     widows: 0,
//                     orphans: 0,
//                     fontSize: '11pt',
//                   }}
//                 >
//                   <strong>
//                     <span style={{ fontFamily: '"times"' }}>
//                       CMTND/CCCD/DKKD:&nbsp;
//                     </span>
//                   </strong>
//                   <span style={{ fontFamily: '"times"' }}>&nbsp;</span>
//                   <span style={{ fontFamily: '"times"' }}>
//                     {checkinItemMap.identityNumber}
//                   </span>
//                 </p>
//                 <p
//                   style={{
//                     marginTop: '6pt',
//                     marginBottom: '6pt',
//                     textAlign: 'justify',
//                     pageBreakInside: 'avoid',
//                     widows: 0,
//                     orphans: 0,
//                     fontSize: '12pt',
//                   }}
//                 >
//                   <strong>
//                     <span style={{ fontFamily: '"times"', fontSize: '11pt' }}>
//                       Cổ phiếu biểu quyết:&nbsp;
//                     </span>
//                   </strong>
//                   <span style={{ fontFamily: '"times"' }}>
//                     {transformNumberPrinter(checkinItemMap.sharesNumber)}
//                   </span>
//                 </p>
//                 <p
//                   style={{
//                     marginTop: '6pt',
//                     marginBottom: '6pt',
//                     textAlign: 'justify',
//                     pageBreakInside: 'avoid',
//                     widows: 0,
//                     orphans: 0,
//                     fontSize: '11pt',
//                   }}
//                 >
//                   <strong>
//                     <span style={{ fontFamily: '"times"' }}>&nbsp;</span>
//                   </strong>
//                 </p>
//                 {report1.map((item, index) => {
//                   return (
//                     <Fragment key={index}>
//                       <ol
//                         start={index + 1}
//                         style={{ margin: '0pt', paddingLeft: '0pt' }}
//                         type={index + 1}
//                       >
//                         <li
//                           style={{
//                             textTransform: 'uppercase',
//                             marginTop: '6pt',
//                             marginLeft: '14pt',
//                             marginBottom: '6pt',
//                             textAlign: 'justify',
//                             pageBreakInside: 'avoid',
//                             widows: 0,
//                             orphans: 0,
//                             paddingLeft: '4pt',
//                             fontFamily: '"times"',
//                             fontSize: '12pt',
//                             fontWeight: 'bold',
//                           }}
//                         >
//                           <span>{item.name}.</span>
//                         </li>
//                       </ol>
//                       <p
//                         style={{
//                           marginTop: '6pt',
//                           marginLeft: '18pt',
//                           marginBottom: '6pt',
//                           textAlign: 'justify',
//                           pageBreakInside: 'avoid',
//                           widows: 0,
//                           orphans: 0,
//                           fontSize: '12pt',
//                         }}
//                       >
//                         <strong>
//                           <span style={{ fontFamily: '"times"' }}>&nbsp;</span>
//                         </strong>
//                       </p>
//                       <p
//                         style={{
//                           marginTop: '6pt',
//                           marginLeft: '18pt',
//                           marginBottom: '6pt',
//                           textAlign: 'justify',
//                           pageBreakInside: 'avoid',
//                           widows: 0,
//                           orphans: 0,
//                           fontSize: '12pt',
//                         }}
//                       >
//                         <span
//                           style={{ width: '10.35pt', display: 'inline-block' }}
//                         >
//                           &nbsp;
//                         </span>

//                         <span style={{ fontFamily: 'Wingdings' }}></span>
//                         <span style={{ fontFamily: '"times"' }}>
//                           &nbsp;Tán thành
//                         </span>

//                         <span
//                           style={{ width: '13.64pt', display: 'inline-block' }}
//                         >
//                           &nbsp;
//                         </span>

//                         <span style={{ fontFamily: '"times"' }}>
//                           &nbsp;&nbsp;
//                         </span>

//                         <span style={{ fontFamily: 'Wingdings' }}></span>
//                         <span style={{ fontFamily: '"times"' }}>
//                           &nbsp;Không tán thành
//                         </span>

//                         <span
//                           style={{ width: '13.64pt', display: 'inline-block' }}
//                         >
//                           &nbsp;
//                         </span>

//                         <span style={{ fontFamily: '"times"' }}>
//                           &nbsp;&nbsp;
//                         </span>

//                         <span style={{ fontFamily: 'Wingdings' }}></span>
//                         <span style={{ fontFamily: '"times"' }}>
//                           &nbsp;Không ý kiến
//                         </span>
//                       </p>

//                       <p
//                         style={{
//                           marginTop: '6pt',
//                           marginLeft: '18pt',
//                           marginBottom: '6pt',
//                           textAlign: 'justify',
//                           pageBreakInside: 'avoid',
//                           widows: 0,
//                           orphans: 0,
//                           fontSize: '12pt',
//                         }}
//                       >
//                         <strong>
//                           <span style={{ fontFamily: '"times"' }}>&nbsp;</span>
//                         </strong>
//                       </p>
//                     </Fragment>
//                   );
//                 })}
//                 <p
//                   style={{
//                     marginTop: '6pt',
//                     marginLeft: '18pt',
//                     marginBottom: '6pt',
//                     textAlign: 'justify',
//                     pageBreakInside: 'avoid',
//                     widows: 0,
//                     orphans: 0,
//                     fontSize: '12pt',
//                   }}
//                 >
//                   <strong>
//                     <span style={{ fontFamily: '"times"' }}>&nbsp;</span>
//                   </strong>
//                 </p>
//                 <p
//                   style={{
//                     marginTop: '6pt',
//                     marginBottom: '6pt',
//                     textAlign: 'center',
//                     lineHeight: '120%',
//                     widows: 0,
//                     orphans: 0,
//                     fontSize: '12pt',
//                   }}
//                 >
//                   <em>
//                     <span
//                       style={{ fontFamily: '"times"', letterSpacing: '-0.2pt' }}
//                     >
//                       &nbsp;
//                     </span>
//                   </em>
//                 </p>
//                 <p
//                   style={{
//                     marginTop: '0pt',
//                     marginBottom: '5pt',
//                     lineHeight: '12pt',
//                   }}
//                 >
//                   <strong>
//                     <em>
//                       <u>
//                         <span
//                           style={{ fontFamily: '"times"', fontSize: '12pt' }}
//                         >
//                           Ghi chú:&nbsp;
//                         </span>
//                       </u>
//                     </em>
//                   </strong>
//                 </p>
//                 <p
//                   style={{
//                     marginTop: '0pt',
//                     marginLeft: '13.5pt',
//                     marginBottom: '0pt',
//                     textIndent: '-13.5pt',
//                     lineHeight: '12pt',
//                   }}
//                 >
//                   <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
//                     -
//                   </span>
//                   <span style={{ font: '7pt "times"' }}>
//                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                   </span>
//                   <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
//                     01 cổ phần phổ thông tương đương với 01 quyền biểu quyết
//                   </span>
//                 </p>
//                 <p
//                   style={{
//                     marginTop: '0pt',
//                     marginLeft: '13.5pt',
//                     marginBottom: '0pt',
//                     textIndent: '-13.5pt',
//                     lineHeight: '12pt',
//                   }}
//                 >
//                   <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
//                     -
//                   </span>
//                   <span style={{ font: '7pt "times"' }}>
//                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                   </span>
//                   <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
//                     Tổng số lượng cổ phần sở hữu bằng tổng số lượng quyền biểu
//                     quyết (Không ủy quyền)
//                   </span>
//                 </p>
//                 <p
//                   style={{
//                     marginTop: '0pt',
//                     marginLeft: '13.5pt',
//                     marginBottom: '0pt',
//                     textIndent: '-13.5pt',
//                     lineHeight: '12pt',
//                   }}
//                 >
//                   <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
//                     -
//                   </span>
//                   <span style={{ font: '7pt "times"' }}>
//                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                   </span>
//                   <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
//                     Tổng số phiếu biểu quyết bằng tổng số lượng cổ phần được ủy
//                     quyền (Ủy quyền)
//                   </span>
//                 </p>
//               </div>

//               <div
//                 style={{
//                   width: '480pt',
//                   margin: '0 auto',
//                   pageBreakAfter: 'always',
//                 }}
//               >
//                 <div style={{ textAlign: 'center' }}>
//                   <table
//                     cellPadding={0}
//                     cellSpacing={0}
//                     style={{
//                       width: '106.28%',
//                       marginRight: 'auto',
//                       marginLeft: 'auto',
//                       borderCollapse: 'collapse',
//                     }}
//                   >
//                     <tbody>
//                       <tr>
//                         <td
//                           style={{
//                             width: '37.58%',
//                             paddingRight: '5.4pt',
//                             paddingLeft: '5.4pt',
//                             verticalAlign: 'top',
//                           }}
//                         >
//                           <p
//                             style={{
//                               marginTop: '4pt',
//                               marginBottom: '4pt',
//                               textAlign: 'center',
//                               pageBreakAfter: 'avoid',
//                               lineHeight: '14pt',
//                               widows: 0,
//                               orphans: 0,
//                             }}
//                           >
//                             <strong>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '10.5pt',
//                                   textTransform: 'uppercase',
//                                 }}
//                               >
//                                 {conference.conference.nameCompany}
//                               </span>
//                             </strong>
//                           </p>
//                           <p
//                             style={{
//                               marginTop: '4pt',
//                               marginBottom: '4pt',
//                               textAlign: 'center',
//                               pageBreakAfter: 'avoid',
//                               lineHeight: '14pt',
//                               widows: 0,
//                               orphans: 0,
//                             }}
//                           >
//                             <strong>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '10.5pt',
//                                 }}
//                               >
//                                 MS: {conference.conference.businessCode}
//                               </span>
//                             </strong>
//                           </p>
//                           <p
//                             style={{
//                               marginTop: '4pt',
//                               marginBottom: '4pt',
//                               textAlign: 'center',
//                               pageBreakAfter: 'avoid',
//                               lineHeight: '14pt',
//                               widows: 0,
//                               orphans: 0,
//                             }}
//                           >
//                             <strong>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '10.5pt',
//                                 }}
//                               >
//                                 ---o0o---
//                               </span>
//                             </strong>
//                           </p>
//                           <p
//                             style={{
//                               marginTop: '4pt',
//                               marginBottom: '4pt',
//                               textAlign: 'center',
//                               pageBreakAfter: 'avoid',
//                               lineHeight: '14pt',
//                               widows: 0,
//                               orphans: 0,
//                             }}
//                           >
//                             <em>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '10.5pt',
//                                 }}
//                               >
//                                 Số
//                               </span>
//                             </em>
//                             <em>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '10.5pt',
//                                 }}
//                               >
//                                 : 01/{now.getFullYear()}/PBQN2-ĐHĐCĐ-
//                                 {conference.conference.stockCode}
//                               </span>
//                             </em>
//                           </p>
//                         </td>
//                         <td
//                           style={{
//                             width: '62.42%',
//                             paddingRight: '5.4pt',
//                             paddingLeft: '5.4pt',
//                             verticalAlign: 'top',
//                           }}
//                         >
//                           <p
//                             style={{
//                               marginTop: '4pt',
//                               marginBottom: '4pt',
//                               textAlign: 'right',
//                               pageBreakAfter: 'avoid',
//                               lineHeight: '14pt',
//                               widows: 0,
//                               orphans: 0,
//                             }}
//                           >
//                             <strong>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '10.5pt',
//                                 }}
//                               >
//                                 CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM
//                               </span>
//                             </strong>
//                           </p>
//                           <p
//                             style={{
//                               marginTop: '4pt',
//                               marginBottom: '4pt',
//                               textAlign: 'center',
//                               pageBreakAfter: 'avoid',
//                               lineHeight: '14pt',
//                               widows: 0,
//                               orphans: 0,
//                             }}
//                           >
//                             <strong>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '10.5pt',
//                                 }}
//                               >
//                                 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                               </span>
//                             </strong>
//                             <strong>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '10.5pt',
//                                 }}
//                               >
//                                 Độc lập - Tự do - Hạnh phúc
//                               </span>
//                             </strong>
//                           </p>
//                           <p
//                             style={{
//                               marginTop: '4pt',
//                               marginBottom: '4pt',
//                               textAlign: 'center',
//                               pageBreakAfter: 'avoid',
//                               lineHeight: '14pt',
//                               widows: 0,
//                               orphans: 0,
//                             }}
//                           >
//                             <strong>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '10.5pt',
//                                 }}
//                               >
//                                 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                               </span>
//                             </strong>
//                             <strong>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '10.5pt',
//                                 }}
//                               >
//                                 -----o0o-----
//                               </span>
//                             </strong>
//                           </p>
//                           <p
//                             style={{
//                               marginTop: '4pt',
//                               marginBottom: '4pt',
//                               textAlign: 'center',
//                               pageBreakAfter: 'avoid',
//                               lineHeight: '14pt',
//                               widows: 0,
//                               orphans: 0,
//                             }}
//                           >
//                             <strong>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '10.5pt',
//                                 }}
//                               >
//                                 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                               </span>
//                             </strong>
//                             <strong>
//                               <em>
//                                 <span
//                                   style={{
//                                     fontFamily: '"times"',
//                                     fontSize: '12pt',
//                                   }}
//                                 >
//                                   &nbsp;&nbsp;&nbsp;&nbsp;
//                                 </span>
//                               </em>
//                             </strong>
//                             <em>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '12pt',
//                                 }}
//                               >
//                                 {conference.conference.addressMeeting.province}
//                               </span>
//                             </em>
//                             <em>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '12pt',
//                                 }}
//                               >
//                                 , ngày&nbsp;
//                               </span>
//                             </em>
//                             <em>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '12pt',
//                                 }}
//                               >
//                                 {now.getDate().toString().padStart(2, 0)}
//                               </span>
//                             </em>
//                             <em>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '12pt',
//                                 }}
//                               >
//                                 &nbsp; tháng&nbsp;
//                               </span>
//                             </em>
//                             <em>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '12pt',
//                                 }}
//                               >
//                                 {(now.getMonth() + 1).toString().padStart(2, 0)}
//                               </span>
//                             </em>
//                             <em>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '12pt',
//                                 }}
//                               >
//                                 &nbsp; năm&nbsp;
//                               </span>
//                             </em>
//                             <em>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '12pt',
//                                 }}
//                               >
//                                 {now.getFullYear()}
//                               </span>
//                             </em>
//                           </p>
//                         </td>
//                       </tr>
//                     </tbody>
//                   </table>
//                 </div>
//                 <p
//                   style={{
//                     marginTop: '0pt',
//                     marginBottom: '6pt',
//                     pageBreakAfter: 'avoid',
//                     lineHeight: '120%',
//                     widows: 0,
//                     orphans: 0,
//                     fontSize: '14pt',
//                   }}
//                 >
//                   <strong>
//                     <span style={{ fontFamily: '"times"' }}>&nbsp;</span>
//                   </strong>
//                 </p>
//                 <p
//                   style={{
//                     marginTop: '0pt',
//                     marginBottom: '6pt',
//                     textAlign: 'center',
//                     pageBreakAfter: 'avoid',
//                     lineHeight: '120%',
//                     widows: 0,
//                     orphans: 0,
//                     fontSize: '14pt',
//                   }}
//                 >
//                   <strong>
//                     <span style={{ fontFamily: '"times"' }}>
//                       PHIẾU BIỂU QUYẾT
//                     </span>
//                   </strong>
//                 </p>
//                 <p
//                   style={{
//                     marginTop: '0pt',
//                     marginBottom: '6pt',
//                     textAlign: 'center',
//                     pageBreakAfter: 'avoid',
//                     lineHeight: '120%',
//                     widows: 0,
//                     orphans: 0,
//                     fontSize: '14pt',
//                   }}
//                 >
//                   <strong>
//                     <span
//                       style={{
//                         fontFamily: '"times"',
//                         fontSize: '12pt',
//                         textTransform: 'uppercase',
//                       }}
//                     >
//                       ĐẠI HỘI ĐỒNG CỔ ĐÔNG THƯỜNG NIÊN NĂM {now.getFullYear()}{' '}
//                       {conference.conference.nameCompany}
//                     </span>
//                   </strong>
//                 </p>
//                 <p
//                   style={{
//                     marginTop: '6pt',
//                     marginBottom: '6pt',
//                     pageBreakInside: 'avoid',
//                     widows: 0,
//                     orphans: 0,
//                     fontSize: '11pt',
//                   }}
//                 >
//                   <strong>
//                     <span
//                       style={{
//                         fontFamily: '"times"',
//                         textTransform: 'uppercase',
//                       }}
//                     >
//                       Tên Cổ Đông: {checkinItemMap.name}
//                     </span>
//                   </strong>
//                 </p>
//                 <p
//                   style={{
//                     marginTop: '6pt',
//                     marginBottom: '6pt',
//                     pageBreakInside: 'avoid',
//                     widows: 0,
//                     orphans: 0,
//                     fontSize: '11pt',
//                   }}
//                 >
//                   <strong>
//                     <span style={{ fontFamily: '"times"' }}>
//                       CMTND/CCCD/DKKD:&nbsp;
//                     </span>
//                   </strong>
//                   <span style={{ fontFamily: '"times"' }}>&nbsp;</span>
//                   <span style={{ fontFamily: '"times"' }}>
//                     {checkinItemMap.identityNumber}
//                   </span>
//                 </p>
//                 <p
//                   style={{
//                     marginTop: '6pt',
//                     marginBottom: '6pt',
//                     textAlign: 'justify',
//                     pageBreakInside: 'avoid',
//                     widows: 0,
//                     orphans: 0,
//                     fontSize: '12pt',
//                   }}
//                 >
//                   <strong>
//                     <span style={{ fontFamily: '"times"', fontSize: '11pt' }}>
//                       Cổ phiếu biểu quyết:&nbsp;
//                     </span>
//                   </strong>
//                   <span style={{ fontFamily: '"times"' }}>
//                     {transformNumberPrinter(checkinItemMap.sharesNumber)}
//                   </span>
//                 </p>
//                 <p
//                   style={{
//                     marginTop: '6pt',
//                     marginBottom: '6pt',
//                     textAlign: 'justify',
//                     pageBreakInside: 'avoid',
//                     widows: 0,
//                     orphans: 0,
//                     fontSize: '11pt',
//                   }}
//                 >
//                   <strong>
//                     <span style={{ fontFamily: '"times"' }}>&nbsp;</span>
//                   </strong>
//                 </p>
//                 {report2.map((item, index) => {
//                   return (
//                     <Fragment key={index}>
//                       <ol
//                         start={index + 1}
//                         style={{ margin: '0pt', paddingLeft: '0pt' }}
//                         type={index + 1}
//                       >
//                         <li
//                           style={{
//                             textTransform: 'uppercase',
//                             marginTop: '6pt',
//                             marginLeft: '14pt',
//                             marginBottom: '6pt',
//                             textAlign: 'justify',
//                             pageBreakInside: 'avoid',
//                             widows: 0,
//                             orphans: 0,
//                             paddingLeft: '4pt',
//                             fontFamily: '"times"',
//                             fontSize: '12pt',
//                             fontWeight: 'bold',
//                           }}
//                         >
//                           <span>{item.name}.</span>
//                         </li>
//                       </ol>
//                       {item.unableShareholder.indexOf(checkinItemMap._id) >=
//                       0 ? (
//                         <Fragment>
//                           <p
//                             style={{
//                               marginTop: '6pt',
//                               marginLeft: '14pt',
//                               marginBottom: '6pt',
//                               textAlign: 'justify',
//                               pageBreakInside: 'avoid',
//                               widows: 0,
//                               orphans: 0,
//                               fontSize: '12pt',
//                               color: 'red',
//                               fontStyle: 'italic',
//                             }}
//                           >
//                             <span
//                               style={{
//                                 width: '10.35pt',
//                                 display: 'inline-block',
//                               }}
//                             ></span>
//                             <span style={{ fontFamily: '"times"' }}>
//                               (Cổ đông bị chặn biểu quyết cho nội dung này)
//                             </span>
//                           </p>
//                         </Fragment>
//                       ) : (
//                         ''
//                       )}
//                       <p
//                         style={{
//                           marginTop: '6pt',
//                           marginLeft: '18pt',
//                           marginBottom: '6pt',
//                           textAlign: 'justify',
//                           pageBreakInside: 'avoid',
//                           widows: 0,
//                           orphans: 0,
//                           fontSize: '12pt',
//                         }}
//                       >
//                         <strong>
//                           <span style={{ fontFamily: '"times"' }}>&nbsp;</span>
//                         </strong>
//                       </p>
//                       <p
//                         style={{
//                           marginTop: '6pt',
//                           marginLeft: '18pt',
//                           marginBottom: '6pt',
//                           textAlign: 'justify',
//                           pageBreakInside: 'avoid',
//                           widows: 0,
//                           orphans: 0,
//                           fontSize: '12pt',
//                         }}
//                       >
//                         <span
//                           style={{ width: '10.35pt', display: 'inline-block' }}
//                         >
//                           &nbsp;
//                         </span>
//                         <span style={{ fontFamily: 'Wingdings' }}></span>
//                         <span style={{ fontFamily: '"times"' }}>
//                           &nbsp;Tán thành
//                         </span>
//                         <span
//                           style={{ width: '13.64pt', display: 'inline-block' }}
//                         >
//                           &nbsp;
//                         </span>
//                         <span style={{ fontFamily: '"times"' }}>
//                           &nbsp;&nbsp;
//                         </span>
//                         <span style={{ fontFamily: 'Wingdings' }}></span>
//                         <span style={{ fontFamily: '"times"' }}>
//                           &nbsp;Không tán thành
//                         </span>
//                         <span
//                           style={{ width: '8.32pt', display: 'inline-block' }}
//                         >
//                           &nbsp;
//                         </span>
//                         <span
//                           style={{ width: '36pt', display: 'inline-block' }}
//                         >
//                           &nbsp;
//                         </span>
//                         <span style={{ fontFamily: '"times"' }}>
//                           &nbsp;&nbsp;
//                         </span>
//                         <span style={{ fontFamily: 'Wingdings' }}></span>
//                         <span style={{ fontFamily: '"times"' }}>
//                           &nbsp;Không ý kiến
//                         </span>
//                       </p>
//                       <p
//                         style={{
//                           marginTop: '6pt',
//                           marginLeft: '18pt',
//                           marginBottom: '6pt',
//                           textAlign: 'justify',
//                           pageBreakInside: 'avoid',
//                           widows: 0,
//                           orphans: 0,
//                           fontSize: '12pt',
//                         }}
//                       >
//                         <strong>
//                           <span style={{ fontFamily: '"times"' }}>&nbsp;</span>
//                         </strong>
//                       </p>
//                     </Fragment>
//                   );
//                 })}
//                 {report4.map((item, index) => {
//                   // console.log('report4: ', report4);
//                   return (
//                     <Fragment key={index}>
//                       <ol
//                         start={index + 1 + report2.length}
//                         style={{ margin: '0pt', paddingLeft: '0pt' }}
//                         type={index + 1}
//                       >
//                         <li
//                           style={{
//                             textTransform: 'uppercase',
//                             marginTop: '6pt',
//                             marginLeft: '14pt',
//                             marginBottom: '6pt',
//                             textAlign: 'justify',
//                             pageBreakInside: 'avoid',
//                             widows: 0,
//                             orphans: 0,
//                             paddingLeft: '4pt',
//                             fontFamily: '"times"',
//                             fontSize: '12pt',
//                             fontWeight: 'bold',
//                           }}
//                         >
//                           <span>{item.name}.</span>
//                         </li>
//                       </ol>
//                       <p
//                         style={{
//                           marginTop: '0pt',
//                           marginBottom: '10pt',
//                           lineHeight: '12pt',
//                         }}
//                       >
//                         <span
//                           style={{ fontFamily: '"times"', fontSize: '12pt' }}
//                         >
//                           Số phiếu biểu quyết:{' '}
//                           {transformNumberPrinter(
//                             Number(item.selectNumber) *
//                               checkinItemMap.sharesNumber,
//                           )}{' '}
//                           phiếu
//                         </span>
//                       </p>
//                       <table
//                         cellPadding={0}
//                         cellSpacing={0}
//                         style={{
//                           width: '462.5pt',
//                           marginLeft: '6.25pt',
//                           border: '0.75pt solid #000000',
//                           borderCollapse: 'collapse',
//                         }}
//                       >
//                         <tbody>
//                           <tr>
//                             <td
//                               style={{
//                                 borderRightStyle: 'solid',
//                                 borderWidth: '0.75pt',
//                                 borderStyle: 'solid',
//                                 verticalAlign: 'middle',
//                                 paddingTop: '4pt',
//                                 paddingBottom: '4pt',
//                               }}
//                             >
//                               <p
//                                 style={{
//                                   marginTop: '0pt',
//                                   marginBottom: '0pt',
//                                   lineHeight: '12pt',
//                                 }}
//                               >
//                                 <strong>
//                                   <span
//                                     style={{
//                                       fontFamily: '"times"',
//                                       fontSize: '12pt',
//                                     }}
//                                   >
//                                     STT
//                                   </span>
//                                 </strong>
//                               </p>
//                             </td>
//                             <td
//                               style={{
//                                 width: '212.8pt',
//                                 borderStyle: 'solid',
//                                 borderWidth: '0.75pt',
//                                 borderLeftStyle: 'solid',
//                                 borderLeftWidth: '0.75pt',
//                                 borderBottomStyle: 'solid',
//                                 borderBottomWidth: '0.75pt',
//                                 verticalAlign: 'middle',
//                                 paddingTop: '4pt',
//                                 paddingBottom: '4pt',
//                               }}
//                             >
//                               <p
//                                 style={{
//                                   marginTop: '0pt',
//                                   marginBottom: '0pt',
//                                   textAlign: 'center',
//                                   lineHeight: '12pt',
//                                 }}
//                               >
//                                 <strong>
//                                   <span
//                                     style={{
//                                       fontFamily: '"times"',
//                                       fontSize: '12pt',
//                                     }}
//                                   >
//                                     Ứng viên
//                                   </span>
//                                 </strong>
//                               </p>
//                             </td>
//                             <td
//                               style={{
//                                 width: '224.25pt',
//                                 borderStyle: 'solid',
//                                 borderWidth: '0.75pt',
//                                 borderBottomStyle: 'solid',
//                                 borderBottomWidth: '0.75pt',
//                                 verticalAlign: 'middle',
//                                 paddingTop: '4pt',
//                                 paddingBottom: '4pt',
//                               }}
//                             >
//                               <p
//                                 style={{
//                                   marginTop: '0pt',
//                                   marginBottom: '0pt',
//                                   textAlign: 'center',
//                                   lineHeight: '12pt',
//                                 }}
//                               >
//                                 <strong>
//                                   <span
//                                     style={{
//                                       fontFamily: '"times"',
//                                       fontSize: '12pt',
//                                     }}
//                                   >
//                                     Số phiếu biểu quyết
//                                   </span>
//                                 </strong>
//                               </p>
//                             </td>
//                           </tr>
//                           {item.selections.map(
//                             (itemSelections, indexSelections) => {
//                               const arr = itemSelections.selectName.split('-');
//                               return (
//                                 <tr key={indexSelections}>
//                                   <td
//                                     style={{
//                                       borderTopStyle: 'solid',
//                                       borderTopWidth: '0.75pt',
//                                       borderRightStyle: 'solid',
//                                       borderRightWidth: '0.75pt',
//                                       borderBottomStyle: 'solid',
//                                       borderBottomWidth: '0.75pt',
//                                       verticalAlign: 'middle',
//                                       padding: '4pt',
//                                     }}
//                                   >
//                                     <p
//                                       style={{
//                                         marginTop: '0pt',
//                                         marginBottom: '0pt',
//                                         textAlign: 'center',
//                                         lineHeight: '12pt',
//                                       }}
//                                     >
//                                       <span
//                                         style={{
//                                           fontFamily: '"times"',
//                                           fontSize: '12pt',
//                                         }}
//                                       >
//                                         {indexSelections + 1}
//                                       </span>
//                                     </p>
//                                   </td>
//                                   <td
//                                     style={{
//                                       width: '212.8pt',
//                                       borderStyle: 'solid',
//                                       borderWidth: '0.75pt',
//                                       verticalAlign: 'middle',
//                                       padding: '4pt',
//                                     }}
//                                   >
//                                     <p
//                                       style={{
//                                         marginTop: '0pt',
//                                         marginBottom: '0pt',
//                                         lineHeight: '12pt',
//                                       }}
//                                     >
//                                       <span
//                                         style={{
//                                           fontFamily: '"times"',
//                                           fontSize: '12pt',
//                                         }}
//                                       >
//                                         {arr[0]}
//                                       </span>
//                                     </p>
//                                   </td>
//                                   <td
//                                     style={{
//                                       width: '224.25pt',
//                                       borderTopStyle: 'solid',
//                                       borderTopWidth: '0.75pt',
//                                       borderLeftStyle: 'solid',
//                                       borderLeftWidth: '0.75pt',
//                                       borderBottomStyle: 'solid',
//                                       borderBottomWidth: '0.75pt',
//                                       verticalAlign: 'middle',
//                                       padding: '4pt',
//                                     }}
//                                   >
//                                     <p
//                                       style={{
//                                         paddingLeft: '40pt',
//                                         marginTop: '0pt',
//                                         marginBottom: '0pt',
//                                         lineHeight: '12pt',
//                                       }}
//                                     >
//                                       <span
//                                         style={{
//                                           fontFamily: '"times"',
//                                           fontSize: '12pt',
//                                         }}
//                                       ></span>
//                                     </p>
//                                   </td>
//                                 </tr>
//                               );
//                             },
//                           )}
//                         </tbody>
//                       </table>
//                       <p
//                         style={{
//                           marginTop: '0pt',
//                           marginBottom: '6pt',
//                           pageBreakAfter: 'avoid',
//                           lineHeight: '120%',
//                           widows: 0,
//                           orphans: 0,
//                           fontSize: '14pt',
//                         }}
//                       >
//                         <strong>
//                           <span style={{ fontFamily: '"times"' }}>&nbsp;</span>
//                         </strong>
//                       </p>
//                     </Fragment>
//                   );
//                 })}
//                 <p
//                   style={{
//                     marginTop: '0pt',
//                     marginBottom: '0pt',
//                     fontSize: '12pt',
//                   }}
//                 >
//                   <span style={{ fontFamily: '"times"' }}>&nbsp;</span>
//                 </p>
//                 <p
//                   style={{
//                     marginTop: '6pt',
//                     marginLeft: '18pt',
//                     marginBottom: '6pt',
//                     textAlign: 'justify',
//                     pageBreakInside: 'avoid',
//                     widows: 0,
//                     orphans: 0,
//                     fontSize: '12pt',
//                   }}
//                 >
//                   <strong>
//                     <span style={{ fontFamily: '"times"' }}>&nbsp;</span>
//                   </strong>
//                 </p>
//                 <p
//                   style={{
//                     marginTop: '6pt',
//                     marginBottom: '6pt',
//                     textAlign: 'center',
//                     lineHeight: '120%',
//                     widows: 0,
//                     orphans: 0,
//                     fontSize: '12pt',
//                   }}
//                 >
//                   <em>
//                     <span
//                       style={{ fontFamily: '"times"', letterSpacing: '-0.2pt' }}
//                     >
//                       &nbsp;
//                     </span>
//                   </em>
//                 </p>

//                 <p
//                   style={{
//                     marginTop: '0pt',
//                     marginBottom: '5pt',
//                     lineHeight: '12pt',
//                   }}
//                 >
//                   <strong>
//                     <em>
//                       <u>
//                         <span
//                           style={{ fontFamily: '"times"', fontSize: '12pt' }}
//                         >
//                           Ghi chú:&nbsp;
//                         </span>
//                       </u>
//                     </em>
//                   </strong>
//                 </p>
//                 <p
//                   style={{
//                     marginTop: '0pt',
//                     marginLeft: '13.5pt',
//                     marginBottom: '0pt',
//                     textIndent: '-13.5pt',
//                     lineHeight: '12pt',
//                   }}
//                 >
//                   <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
//                     -
//                   </span>
//                   <span style={{ font: '7pt "times"' }}>
//                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                   </span>
//                   <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
//                     01 cổ phần phổ thông tương đương với 01 quyền biểu quyết
//                   </span>
//                 </p>
//                 <p
//                   style={{
//                     marginTop: '0pt',
//                     marginLeft: '13.5pt',
//                     marginBottom: '0pt',
//                     textIndent: '-13.5pt',
//                     lineHeight: '12pt',
//                   }}
//                 >
//                   <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
//                     -
//                   </span>
//                   <span style={{ font: '7pt "times"' }}>
//                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                   </span>
//                   <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
//                     Tổng số lượng cổ phần sở hữu bằng tổng số lượng quyền biểu
//                     quyết (Không ủy quyền)
//                   </span>
//                 </p>
//                 <p
//                   style={{
//                     marginTop: '0pt',
//                     marginLeft: '13.5pt',
//                     marginBottom: '0pt',
//                     textIndent: '-13.5pt',
//                     lineHeight: '12pt',
//                   }}
//                 >
//                   <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
//                     -
//                   </span>
//                   <span style={{ font: '7pt "times"' }}>
//                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                   </span>
//                   <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
//                     Tổng số phiếu biểu quyết bằng tổng số lượng cổ phần được ủy
//                     quyền (Ủy quyền)
//                   </span>
//                 </p>
//               </div>

//               <div
//                 style={{
//                   width: '480pt',
//                   margin: '0 auto',
//                   pageBreakAfter: 'always',
//                 }}
//               >
//                 <div style={{ textAlign: 'center' }}>
//                   <table
//                     cellPadding={0}
//                     cellSpacing={0}
//                     style={{
//                       width: '106.28%',
//                       marginRight: 'auto',
//                       marginLeft: 'auto',
//                       borderCollapse: 'collapse',
//                     }}
//                   >
//                     <tbody>
//                       <tr>
//                         <td
//                           style={{
//                             width: '37.58%',
//                             paddingRight: '5.4pt',
//                             paddingLeft: '5.4pt',
//                             verticalAlign: 'top',
//                           }}
//                         >
//                           <p
//                             style={{
//                               marginTop: '4pt',
//                               marginBottom: '4pt',
//                               textAlign: 'center',
//                               pageBreakAfter: 'avoid',
//                               lineHeight: '14pt',
//                               widows: 0,
//                               orphans: 0,
//                             }}
//                           >
//                             <strong>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '10.5pt',
//                                   textTransform: 'uppercase',
//                                 }}
//                               >
//                                 {conference.conference.nameCompany}
//                               </span>
//                             </strong>
//                           </p>
//                           <p
//                             style={{
//                               marginTop: '4pt',
//                               marginBottom: '4pt',
//                               textAlign: 'center',
//                               pageBreakAfter: 'avoid',
//                               lineHeight: '14pt',
//                               widows: 0,
//                               orphans: 0,
//                             }}
//                           >
//                             <strong>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '10.5pt',
//                                 }}
//                               >
//                                 MS: {conference.conference.businessCode}
//                               </span>
//                             </strong>
//                           </p>
//                           <p
//                             style={{
//                               marginTop: '4pt',
//                               marginBottom: '4pt',
//                               textAlign: 'center',
//                               pageBreakAfter: 'avoid',
//                               lineHeight: '14pt',
//                               widows: 0,
//                               orphans: 0,
//                             }}
//                           >
//                             <strong>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '10.5pt',
//                                 }}
//                               >
//                                 ---o0o---
//                               </span>
//                             </strong>
//                           </p>
//                           <p
//                             style={{
//                               marginTop: '4pt',
//                               marginBottom: '4pt',
//                               textAlign: 'center',
//                               pageBreakAfter: 'avoid',
//                               lineHeight: '14pt',
//                               widows: 0,
//                               orphans: 0,
//                             }}
//                           >
//                             <em>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '10.5pt',
//                                 }}
//                               >
//                                 Số
//                               </span>
//                             </em>
//                             <em>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '10.5pt',
//                                 }}
//                               >
//                                 : 01/{now.getFullYear()}/PBQN3-ĐHĐCĐ-
//                                 {conference.conference.stockCode}
//                               </span>
//                             </em>
//                           </p>
//                         </td>
//                         <td
//                           style={{
//                             width: '62.42%',
//                             paddingRight: '5.4pt',
//                             paddingLeft: '5.4pt',
//                             verticalAlign: 'top',
//                           }}
//                         >
//                           <p
//                             style={{
//                               marginTop: '4pt',
//                               marginBottom: '4pt',
//                               textAlign: 'right',
//                               pageBreakAfter: 'avoid',
//                               lineHeight: '14pt',
//                               widows: 0,
//                               orphans: 0,
//                             }}
//                           >
//                             <strong>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '10.5pt',
//                                 }}
//                               >
//                                 CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM
//                               </span>
//                             </strong>
//                           </p>
//                           <p
//                             style={{
//                               marginTop: '4pt',
//                               marginBottom: '4pt',
//                               textAlign: 'center',
//                               pageBreakAfter: 'avoid',
//                               lineHeight: '14pt',
//                               widows: 0,
//                               orphans: 0,
//                             }}
//                           >
//                             <strong>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '10.5pt',
//                                 }}
//                               >
//                                 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                               </span>
//                             </strong>
//                             <strong>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '10.5pt',
//                                 }}
//                               >
//                                 Độc lập - Tự do - Hạnh phúc
//                               </span>
//                             </strong>
//                           </p>
//                           <p
//                             style={{
//                               marginTop: '4pt',
//                               marginBottom: '4pt',
//                               textAlign: 'center',
//                               pageBreakAfter: 'avoid',
//                               lineHeight: '14pt',
//                               widows: 0,
//                               orphans: 0,
//                             }}
//                           >
//                             <strong>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '10.5pt',
//                                 }}
//                               >
//                                 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                               </span>
//                             </strong>
//                             <strong>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '10.5pt',
//                                 }}
//                               >
//                                 -----o0o-----
//                               </span>
//                             </strong>
//                           </p>
//                           <p
//                             style={{
//                               marginTop: '4pt',
//                               marginBottom: '4pt',
//                               textAlign: 'center',
//                               pageBreakAfter: 'avoid',
//                               lineHeight: '14pt',
//                               widows: 0,
//                               orphans: 0,
//                             }}
//                           >
//                             <strong>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '10.5pt',
//                                 }}
//                               >
//                                 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                               </span>
//                             </strong>
//                             <strong>
//                               <em>
//                                 <span
//                                   style={{
//                                     fontFamily: '"times"',
//                                     fontSize: '12pt',
//                                   }}
//                                 >
//                                   &nbsp;&nbsp;&nbsp;&nbsp;
//                                 </span>
//                               </em>
//                             </strong>
//                             <em>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '12pt',
//                                 }}
//                               >
//                                 {conference.conference.addressMeeting.province}
//                               </span>
//                             </em>
//                             <em>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '12pt',
//                                 }}
//                               >
//                                 , ngày&nbsp;
//                               </span>
//                             </em>
//                             <em>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '12pt',
//                                 }}
//                               >
//                                 {now.getDate().toString().padStart(2, 0)}
//                               </span>
//                             </em>
//                             <em>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '12pt',
//                                 }}
//                               >
//                                 &nbsp; tháng&nbsp;
//                               </span>
//                             </em>
//                             <em>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '12pt',
//                                 }}
//                               >
//                                 {(now.getMonth() + 1).toString().padStart(2, 0)}
//                               </span>
//                             </em>
//                             <em>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '12pt',
//                                 }}
//                               >
//                                 &nbsp; năm&nbsp;
//                               </span>
//                             </em>
//                             <em>
//                               <span
//                                 style={{
//                                   fontFamily: '"times"',
//                                   fontSize: '12pt',
//                                 }}
//                               >
//                                 {now.getFullYear()}
//                               </span>
//                             </em>
//                           </p>
//                         </td>
//                       </tr>
//                     </tbody>
//                   </table>
//                 </div>
//                 <p
//                   style={{
//                     marginTop: '0pt',
//                     marginBottom: '6pt',
//                     pageBreakAfter: 'avoid',
//                     lineHeight: '120%',
//                     widows: 0,
//                     orphans: 0,
//                     fontSize: '14pt',
//                   }}
//                 >
//                   <strong>
//                     <span style={{ fontFamily: '"times"' }}>&nbsp;</span>
//                   </strong>
//                 </p>
//                 <p
//                   style={{
//                     marginTop: '0pt',
//                     marginBottom: '6pt',
//                     textAlign: 'center',
//                     pageBreakAfter: 'avoid',
//                     lineHeight: '120%',
//                     widows: 0,
//                     orphans: 0,
//                     fontSize: '14pt',
//                   }}
//                 >
//                   <strong>
//                     <span style={{ fontFamily: '"times"' }}>
//                       PHIẾU BIỂU QUYẾT
//                     </span>
//                   </strong>
//                 </p>
//                 <p
//                   style={{
//                     marginTop: '0pt',
//                     marginBottom: '6pt',
//                     textAlign: 'center',
//                     pageBreakAfter: 'avoid',
//                     lineHeight: '120%',
//                     widows: 0,
//                     orphans: 0,
//                     fontSize: '14pt',
//                   }}
//                 >
//                   <strong>
//                     <span
//                       style={{
//                         fontFamily: '"times"',
//                         fontSize: '12pt',
//                         textTransform: 'uppercase',
//                       }}
//                     >
//                       ĐẠI HỘI ĐỒNG CỔ ĐÔNG THƯỜNG NIÊN NĂM {now.getFullYear()}{' '}
//                       {conference.conference.nameCompany}
//                     </span>
//                   </strong>
//                 </p>
//                 <p
//                   style={{
//                     marginTop: '6pt',
//                     marginBottom: '6pt',
//                     pageBreakInside: 'avoid',
//                     widows: 0,
//                     orphans: 0,
//                     fontSize: '11pt',
//                   }}
//                 >
//                   <strong>
//                     <span
//                       style={{
//                         fontFamily: '"times"',
//                         textTransform: 'uppercase',
//                       }}
//                     >
//                       Tên Cổ Đông: {checkinItemMap.name}
//                     </span>
//                   </strong>
//                 </p>
//                 <p
//                   style={{
//                     marginTop: '6pt',
//                     marginBottom: '6pt',
//                     pageBreakInside: 'avoid',
//                     widows: 0,
//                     orphans: 0,
//                     fontSize: '11pt',
//                   }}
//                 >
//                   <strong>
//                     <span style={{ fontFamily: '"times"' }}>
//                       CMTND/CCCD/DKKD:&nbsp;
//                     </span>
//                   </strong>
//                   <span style={{ fontFamily: '"times"' }}>&nbsp;</span>
//                   <span style={{ fontFamily: '"times"' }}>
//                     {checkinItemMap.identityNumber}
//                   </span>
//                 </p>
//                 <p
//                   style={{
//                     marginTop: '6pt',
//                     marginBottom: '6pt',
//                     textAlign: 'justify',
//                     pageBreakInside: 'avoid',
//                     widows: 0,
//                     orphans: 0,
//                     fontSize: '12pt',
//                   }}
//                 >
//                   <strong>
//                     <span style={{ fontFamily: '"times"', fontSize: '11pt' }}>
//                       Cổ phiếu biểu quyết:&nbsp;
//                     </span>
//                   </strong>
//                   <span style={{ fontFamily: '"times"' }}>
//                     {transformNumberPrinter(checkinItemMap.sharesNumber)}
//                   </span>
//                 </p>
//                 <p
//                   style={{
//                     marginTop: '6pt',
//                     marginBottom: '6pt',
//                     textAlign: 'justify',
//                     pageBreakInside: 'avoid',
//                     widows: 0,
//                     orphans: 0,
//                     fontSize: '11pt',
//                   }}
//                 >
//                   <strong>
//                     <span style={{ fontFamily: '"times"' }}>&nbsp;</span>
//                   </strong>
//                 </p>

//                 {report3.map((item, index) => {
//                   return (
//                     <Fragment key={index}>
//                       <ol
//                         start={index + 1}
//                         style={{ margin: '0pt', paddingLeft: '0pt' }}
//                         type={1}
//                       >
//                         <li
//                           style={{
//                             textTransform: 'uppercase',
//                             marginTop: '6pt',
//                             marginLeft: '14pt',
//                             marginBottom: '6pt',
//                             textAlign: 'justify',
//                             pageBreakInside: 'avoid',
//                             widows: 0,
//                             orphans: 0,
//                             paddingLeft: '4pt',
//                             fontFamily: '"times"',
//                             fontSize: '12pt',
//                             fontWeight: 'bold',
//                           }}
//                         >
//                           <span>{item.name}</span>
//                         </li>
//                       </ol>
//                       <p
//                         style={{
//                           marginTop: '6pt',
//                           marginLeft: '18pt',
//                           marginBottom: '6pt',
//                           textAlign: 'justify',
//                           pageBreakInside: 'avoid',
//                           widows: 0,
//                           orphans: 0,
//                           fontSize: '12pt',
//                         }}
//                       >
//                         <strong>
//                           <span style={{ fontFamily: '"times"' }}>&nbsp;</span>
//                         </strong>
//                       </p>
//                       <p
//                         style={{
//                           marginTop: '6pt',
//                           marginLeft: '18pt',
//                           marginBottom: '6pt',
//                           textAlign: 'justify',
//                           pageBreakInside: 'avoid',
//                           widows: 0,
//                           orphans: 0,
//                           fontSize: '12pt',
//                         }}
//                       >
//                         <span
//                           style={{ width: '10.35pt', display: 'inline-block' }}
//                         >
//                           &nbsp;
//                         </span>
//                         <span style={{ fontFamily: 'Wingdings' }}></span>
//                         <span style={{ fontFamily: '"times"' }}>
//                           &nbsp;Tán thành
//                         </span>
//                         <span
//                           style={{ width: '13.64pt', display: 'inline-block' }}
//                         >
//                           &nbsp;
//                         </span>
//                         <span style={{ fontFamily: '"times"' }}>
//                           &nbsp;&nbsp;
//                         </span>
//                         <span style={{ fontFamily: 'Wingdings' }}></span>
//                         <span style={{ fontFamily: '"times"' }}>
//                           &nbsp;Không tán thành
//                         </span>
//                       </p>
//                       <p
//                         style={{
//                           marginTop: '6pt',
//                           marginLeft: '18pt',
//                           marginBottom: '6pt',
//                           textAlign: 'justify',
//                           pageBreakInside: 'avoid',
//                           widows: 0,
//                           orphans: 0,
//                           fontSize: '12pt',
//                         }}
//                       >
//                         <strong>
//                           <span style={{ fontFamily: '"times"' }}>&nbsp;</span>
//                         </strong>
//                       </p>
//                     </Fragment>
//                   );
//                 })}

//                 {/* <ol
//                   start={1}
//                   style={{margin: '0pt', paddingLeft: '0pt'}}
//                   type={1}
//                 >
//                   <li
//                     style={{
//                       textTransform: 'uppercase',
//                       marginTop: '6pt',
//                       marginLeft: '14pt',
//                       marginBottom: '6pt',
//                       textAlign: 'justify',
//                       pageBreakInside: 'avoid',
//                       widows: 0,
//                       orphans: 0,
//                       paddingLeft: '4pt',
//                       fontFamily: '"times"',
//                       fontSize: '12pt',
//                       fontWeight: 'bold',
//                     }}
//                   >
//                     <span>Thông qua biên bản cuộc họp.</span>
//                   </li>
//                 </ol>
//                 <p
//                   style={{
//                     marginTop: '6pt',
//                     marginLeft: '18pt',
//                     marginBottom: '6pt',
//                     textAlign: 'justify',
//                     pageBreakInside: 'avoid',
//                     widows: 0,
//                     orphans: 0,
//                     fontSize: '12pt',
//                   }}
//                 >
//                   <strong>
//                     <span style={{fontFamily: '"times"'}}>&nbsp;</span>
//                   </strong>
//                 </p>
//                 <p
//                   style={{
//                     marginTop: '6pt',
//                     marginLeft: '18pt',
//                     marginBottom: '6pt',
//                     textAlign: 'justify',
//                     pageBreakInside: 'avoid',
//                     widows: 0,
//                     orphans: 0,
//                     fontSize: '12pt',
//                   }}
//                 >
//                   <span style={{width: '10.35pt', display: 'inline-block'}}>
//                     &nbsp;
//                   </span>
//                   <span style={{fontFamily: 'Wingdings'}}></span>
//                   <span style={{fontFamily: '"times"'}}>&nbsp;Tán thành</span>
//                   <span style={{width: '13.64pt', display: 'inline-block'}}>
//                     &nbsp;
//                   </span>
//                   <span style={{fontFamily: '"times"'}}>&nbsp;&nbsp;</span>
//                   <span style={{fontFamily: 'Wingdings'}}></span>
//                   <span style={{fontFamily: '"times"'}}>
//                     &nbsp;Không tán thành
//                   </span>
//                 </p>
//                 <p
//                   style={{
//                     marginTop: '6pt',
//                     marginLeft: '18pt',
//                     marginBottom: '6pt',
//                     textAlign: 'justify',
//                     pageBreakInside: 'avoid',
//                     widows: 0,
//                     orphans: 0,
//                     fontSize: '12pt',
//                   }}
//                 >
//                   <strong>
//                     <span style={{fontFamily: '"times"'}}>&nbsp;</span>
//                   </strong>
//                 </p>

//                 <ol
//                   start={2}
//                   style={{margin: '0pt', paddingLeft: '0pt'}}
//                   type={1}
//                 >
//                   <li
//                     style={{
//                       textTransform: 'uppercase',
//                       marginTop: '6pt',
//                       marginLeft: '14pt',
//                       marginBottom: '6pt',
//                       textAlign: 'justify',
//                       pageBreakInside: 'avoid',
//                       widows: 0,
//                       orphans: 0,
//                       paddingLeft: '4pt',
//                       fontFamily: '"times"',
//                       fontSize: '12pt',
//                       fontWeight: 'bold',
//                     }}
//                   >
//                     <span>Thông qua nghị quyết cuộc họp.</span>
//                   </li>
//                 </ol>
//                 <p
//                   style={{
//                     marginTop: '6pt',
//                     marginLeft: '18pt',
//                     marginBottom: '6pt',
//                     textAlign: 'justify',
//                     pageBreakInside: 'avoid',
//                     widows: 0,
//                     orphans: 0,
//                     fontSize: '12pt',
//                   }}
//                 >
//                   <strong>
//                     <span style={{fontFamily: '"times"'}}>&nbsp;</span>
//                   </strong>
//                 </p>
//                 <p
//                   style={{
//                     marginTop: '6pt',
//                     marginLeft: '18pt',
//                     marginBottom: '6pt',
//                     textAlign: 'justify',
//                     pageBreakInside: 'avoid',
//                     widows: 0,
//                     orphans: 0,
//                     fontSize: '12pt',
//                   }}
//                 >
//                   <span style={{width: '10.35pt', display: 'inline-block'}}>
//                     &nbsp;
//                   </span>
//                   <span style={{fontFamily: 'Wingdings'}}></span>
//                   <span style={{fontFamily: '"times"'}}>&nbsp;Tán thành</span>
//                   <span style={{width: '13.64pt', display: 'inline-block'}}>
//                     &nbsp;
//                   </span>
//                   <span style={{fontFamily: '"times"'}}>&nbsp;&nbsp;</span>
//                   <span style={{fontFamily: 'Wingdings'}}></span>
//                   <span style={{fontFamily: '"times"'}}>
//                     &nbsp;Không tán thành
//                   </span>
//                 </p> */}

//                 <p
//                   style={{
//                     marginTop: '6pt',
//                     marginLeft: '18pt',
//                     marginBottom: '6pt',
//                     textAlign: 'justify',
//                     pageBreakInside: 'avoid',
//                     widows: 0,
//                     orphans: 0,
//                     fontSize: '12pt',
//                   }}
//                 >
//                   <strong>
//                     <span style={{ fontFamily: '"times"' }}>&nbsp;</span>
//                   </strong>
//                 </p>
//                 <p
//                   style={{
//                     marginTop: '6pt',
//                     marginLeft: '18pt',
//                     marginBottom: '6pt',
//                     textAlign: 'justify',
//                     pageBreakInside: 'avoid',
//                     widows: 0,
//                     orphans: 0,
//                     fontSize: '12pt',
//                   }}
//                 >
//                   <strong>
//                     <span style={{ fontFamily: '"times"' }}>&nbsp;</span>
//                   </strong>
//                 </p>
//                 <p
//                   style={{
//                     marginTop: '6pt',
//                     marginBottom: '6pt',
//                     textAlign: 'center',
//                     lineHeight: '120%',
//                     widows: 0,
//                     orphans: 0,
//                     fontSize: '12pt',
//                   }}
//                 >
//                   <em>
//                     <span
//                       style={{ fontFamily: '"times"', letterSpacing: '-0.2pt' }}
//                     >
//                       &nbsp;
//                     </span>
//                   </em>
//                 </p>

//                 <p
//                   style={{
//                     marginTop: '0pt',
//                     marginBottom: '5pt',
//                     lineHeight: '12pt',
//                   }}
//                 >
//                   <strong>
//                     <em>
//                       <u>
//                         <span
//                           style={{ fontFamily: '"times"', fontSize: '12pt' }}
//                         >
//                           Ghi chú:&nbsp;
//                         </span>
//                       </u>
//                     </em>
//                   </strong>
//                 </p>
//                 <p
//                   style={{
//                     marginTop: '0pt',
//                     marginLeft: '13.5pt',
//                     marginBottom: '0pt',
//                     textIndent: '-13.5pt',
//                     lineHeight: '12pt',
//                   }}
//                 >
//                   <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
//                     -
//                   </span>
//                   <span style={{ font: '7pt "times"' }}>
//                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                   </span>
//                   <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
//                     01 cổ phần phổ thông tương đương với 01 quyền biểu quyết
//                   </span>
//                 </p>
//                 <p
//                   style={{
//                     marginTop: '0pt',
//                     marginLeft: '13.5pt',
//                     marginBottom: '0pt',
//                     textIndent: '-13.5pt',
//                     lineHeight: '12pt',
//                   }}
//                 >
//                   <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
//                     -
//                   </span>
//                   <span style={{ font: '7pt "times"' }}>
//                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                   </span>
//                   <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
//                     Tổng số lượng cổ phần sở hữu bằng tổng số lượng quyền biểu
//                     quyết (Không ủy quyền)
//                   </span>
//                 </p>
//                 <p
//                   style={{
//                     marginTop: '0pt',
//                     marginLeft: '13.5pt',
//                     marginBottom: '0pt',
//                     textIndent: '-13.5pt',
//                     lineHeight: '12pt',
//                   }}
//                 >
//                   <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
//                     -
//                   </span>
//                   <span style={{ font: '7pt "times"' }}>
//                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                   </span>
//                   <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
//                     Tổng số phiếu biểu quyết bằng tổng số lượng cổ phần được ủy
//                     quyền (Ủy quyền)
//                   </span>
//                 </p>
//               </div>
//             </Fragment>
//           );
//         })}
//       </div>
//     );
//   } else {
//     return <div></div>;
//   }
// };
