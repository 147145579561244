const transformNumber = (number) => {
  if (number !== undefined && number !== null) {
    return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  } else {
    return '0';
  }
};
export const transformNumberPrinter = (num, decimal) => {
  return Number(num || 0).toLocaleString('de-DE', {
    maximumFractionDigits: decimal || 0,
  });
};

export const transformNumberComma = (number) => {
  if (number !== undefined && number !== null) {
    return number.toString().replace('.', ',');
  } else {
    return '0';
  }
};

export const transformPercentNumber = (number, decimal) => {
  const num = Number(number || 0);
  let decimalNumber = decimal || 4;

  return num.toLocaleString('de-DE', {
    maximumFractionDigits: decimalNumber,
  });

  // // vd: 0.0001234 => 0,0001
  // if (Number.isInteger(number)) return transformNumberComma(number.toFixed(0));
  // // else if (number.toFixed(4) === 1.0000 && decimal === 4) {
  // //   return transformNumberComma(0.9999);
  // // }
  // else if (
  //   number !== 0 &&
  //   parseFloat(number).toFixed(decimal) !== parseFloat(0).toFixed(decimal)
  // ) {
  //   if (
  //     transformNumberComma(parseFloat(number).toFixed(decimal)) === '100,0000'
  //   )
  //     return '99,9999';
  //   return transformNumberComma(parseFloat(number).toFixed(decimal));
  // } else {
  //   let result = [];
  //   const tmp = Array.from(number.toString().replace('.', ','));

  //   for (let i in tmp) {
  //     result.push(tmp[i]);
  //     if (tmp[i] !== '0' && tmp[i] !== ',') break;
  //   }
  //   return result.join('');
  // }
};

export default transformNumber;

export function format(n, decimal = 0, groupLength = 3) {
  if (!n) {
    return n;
  }

  var re = '\\d(?=(\\d{' + groupLength + '})+' + (n > 0 ? '\\.' : '$') + ')';
  const result = n.toFixed(decimal || 1).replace(new RegExp(re, 'g'), '$&,');

  if (decimal === 0) {
    return result.slice(0, -2);
  }
  return result;
}

export const textToNumber = (text) => {
  // 1,000,000 => 1000000
  let result = '';
  for (let i in text) {
    if (text[i] >= '0' && text[i] <= '9') result += text[i];
  }
  return Number(result);
};

export const numberToFormatText = (number, lang) => {
  // 1000000 => 1.000.000
  if (number !== undefined && number !== null) {
    // if (lang === 'vi')
    return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  } else {
    return '0';
  }
};
