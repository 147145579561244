export const tabTypeOptions = [
  {
    label: 'Tất cả',
    value: 'all',
  },
  {
    label: 'Đã gửi ý kiến',
    value: 'opinion',
  },
];
export const tabTypeOptionsEn = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Posted comments',
    value: 'opinion',
  },
];

export const blockStatusOption = [
  {
    label: 'Tất cả',
    value: 'all',
  },
  {
    label: 'Đã chặn',
    value: true,
  },
  {
    label: 'Chưa chặn',
    value: false,
  },
];
export const blockStatusOptionEn = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Blocked',
    value: true,
  },
  {
    label: 'Not blocked',
    value: false,
  },
];

export const statusOpinionOptions = [

  {
    label: 'Tất cả',
    value: 'all',
  },
  {
    label: 'Đã chuyển chủ tịch',
    value: 'boss',
  },
  {
    label: 'Chưa chuyển chủ tịch',
    value: 'notBoss',
  },
];
export const statusOpinionOptionsEn = [

  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Sent to Presidium',
    value: 'boss',
  },
  {
    label: 'Have not send to Presidium',
    value: 'notBoss',
  },
];
