import React from 'react';
import styled from 'styled-components';
import icons from './icons';

export default function DropImage({
  path = null,
  mini,
  handleChooseFile,
  onChange,
  label,
}) {
  return (
    <SDropImage style={mini ? { height: '100px' } : { height: '100%' }}>
      <input
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={onChange}
      />
      <div className="placeholder">
        <div>{icons.upload}</div>
        <span>{label}</span>
      </div>
      {path && (
        <div className="preview">
          <img src={path} className="preview" alt="preview" />
        </div>
      )}
    </SDropImage>
  );
}

const SDropImage = styled.label`
  width: 100%;
  height: 260px;
  display: inline-block;
  max-width: 465px;
  min-width: 200px;
  border: 1px solid #dfe9f5;
  background-color: white;
  position: relative;
  cursor: pointer;
  /* margin-bottom: 22px; */

  .placeholder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    & > span {
      font-size: 14px;
      line-height: 16px;
      color: #718098;
      margin-top: 12px;
    }
  }

  .preview {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    overflow: hidden;
    background-color: white;

    & > img {
      object-fit: contain;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }

  .upload-icon {
    position: absolute;
    bottom: -30px;
    left: calc(50% - 22px);
    z-index: 3;
    &.active {
      & > svg > circle {
        fill: #00cb82;
      }
    }
  }
`;
