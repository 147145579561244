import {SModalConfirm} from '@styled/checkIn/authorityListPage';
import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
  getHandleAuthorityApi,
  getLoading,
} from '../../../../../states/duck/pages/checkin/authorityListReducer/actions';
import {Loading} from './../../../../bases/shared';
import {useTranslation} from 'react-i18next';

const ConfirmModal = ({modalType}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const listChose = useSelector(
    (state) => state.checkin.authorityList.listTopicChoose,
  );
  const loading = useSelector((state) => state.checkin.authorityList.loading);
  const filter = useSelector((state) => state.checkin.authorityList.filter);

  const onSubmit = () => {
    dispatch(getLoading(true));
    dispatch(
      getHandleAuthorityApi(
        listChose[0] ? listChose[0]._id : '',
        modalType,
        filter,
      ),
    );
  };

  return (
    <SModalConfirm>
      {loading && <Loading title={t('Đang xử lý. Vui lòng đợi')} />}

      <div className="content">
        <p className="desc">
          {modalType === 'accept'
            ? t('Bạn đang xác nhận ủy quyền biểu quyết của')
            : t('Bạn đang từ chối ủy quyền biểu quyết của')}{' '}
          {listChose[0] ? listChose[0].name : ''}
        </p>
        <p className="desc">{t('Bạn có chắc chắn với hành động này?')}</p>
        <button
          className={`common__btn ${modalType === 'accept' ? '' : 'red__btn'}`}
          onClick={onSubmit}
        >
          {modalType === 'accept' ? t('Xác nhận') : t('Từ chối')}
        </button>
      </div>
    </SModalConfirm>
  );
};
export default ConfirmModal;
