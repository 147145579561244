import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  // transformNumberComma,
  transformNumberPrinter,
  transformPercentNumber,
} from '../../../bases/configs';
import ResultTable from './ResultTable';
import { useSelector } from 'react-redux';
import { UITable } from 'components/bases/UI/Table';
import { Card } from '../sectionInfo';

const ResultComulativeShow = ({ topic, index, lengthSum, isDetail }) => {
  const { t, i18n } = useTranslation();
  let thead = [t('Stt'), t('Họ và tên'), t('Số phiếu bầu'), t('Tỷ lệ %')];
  const data = useSelector((state) => state.result.result.data);
  const topicKind4 = data && data.topicKind4;
  const [topicRes, setTopicRes] = useState(null);
  const [listToShow, setListToShow] = useState([]);
  useEffect(() => {
    if (topic && topic._id && topicKind4) {
      setTopicRes(topicKind4[topic._id]);
      if (
        topicKind4 &&
        topicKind4[topic._id] &&
        topicKind4[topic._id].selections
      ) {
        setListToShow(Object.values(topicKind4[topic._id].selections));
      }
    }
  }, [topic, topicKind4]);
  if (isDetail)
    return (
      <Styles2>
        <div className="head">
          <p className="title">
            <span>{`${t('Nội dung')} ${
              index + 1 + lengthSum > 0
                ? lengthSum + index + 1
                : lengthSum + index + 1
            }:`}</span>{' '}
            {i18n.language === 'vi' && topic ? topic.name : topic.name_2}
          </p>
        </div>
        <div className="content">
          <div className="info">
            <div className="info-row">
              <div className="info-col">
                <div className="info-item left">
                  <p className="value ">
                    {transformNumberPrinter(
                      topicRes && topicRes.validShares
                        ? topicRes.validShares
                        : 0,
                    )}
                  </p>
                  <p className="desc">{t('Tổng số phiếu hợp lệ là')}</p>
                </div>
              </div>
              <div className="info-col">
                <div className="info-item right">
                  <p className="value ">
                    {transformNumberPrinter(
                      topicRes && topicRes.invalidShares
                        ? topicRes.invalidShares
                        : 0,
                    )}
                  </p>
                  <p className="desc">{t('Tổng số phiếu không hợp lệ là')}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="table-container">
            <div className="table-wrap">
              <ResultTable thead={thead}>
                {listToShow &&
                  listToShow.map((itemSelections, indexSelections) => {
                    return (
                      <tr key={indexSelections}>
                        <td style={{ width: '10%' }}>{indexSelections + 1}</td>
                        <td>
                          {itemSelections && itemSelections.selectName
                            ? i18n.language === 'vi'
                              ? itemSelections.selectName.split(' - ')[0]
                              : itemSelections.selectName.split(' - ')[1]
                            : ''}
                        </td>
                        <td className="right" style={{ width: '20%' }}>
                          {transformNumberPrinter(
                            itemSelections &&
                              parseFloat(itemSelections.validAmount).toFixed(0),
                          )}
                        </td>
                        <td style={{ width: '20%', textAlign: 'center' }}>
                          {transformPercentNumber(
                            itemSelections && itemSelections.percentPerValid
                              ? itemSelections.percentPerValid * 100
                              : 0,
                          )}
                          %
                        </td>
                      </tr>
                    );
                  })}
              </ResultTable>
            </div>
          </div>
        </div>
      </Styles2>
    );
  return (
    // <Styles
    //   header="Bầu cử"
    //   label={i18n.language === 'vi' && topic ? topic.name : topic.name_2}
    // >
    <UITable
      items={listToShow}
      headers={[
        t('Stt'),
        t('Họ và tên'),
        t('Số phiếu bầu'),
        `${t('Tỷ lệ')} (%)`,
      ]}
    >
      {(itemSelections, index) => {
        return (
          <tr>
            <td style={{ textAlign: 'center' }}>{index + 1}</td>
            <td>
              {itemSelections && itemSelections.selectName
                ? i18n.language === 'vi'
                  ? itemSelections.selectName.split(' - ')[0]
                  : itemSelections.selectName.split(' - ')[1]
                : ''}
            </td>
            <td style={{ textAlign: 'right' }}>
              {transformNumberPrinter(
                itemSelections &&
                  parseFloat(itemSelections.validAmount).toFixed(0),
              )}
            </td>
            <td style={{ textAlign: 'center' }}>
              {transformPercentNumber(
                itemSelections && itemSelections.percentPerValid
                  ? itemSelections.percentPerValid * 100
                  : 0,
              )}
              %
            </td>
          </tr>
        );
      }}
    </UITable>
    // </Styles>
  );
};

export default ResultComulativeShow;

const Styles = styled(Card)`
  margin: 32px 0;
`;

const Styles2 = styled.div`
  width: 768px;
  max-width: 100%;
  margin: 0 auto;
  padding-bottom: 20px;
  .title {
    font-size: 20px;
    font-family: Roboto !important;
    padding: 40px 0 0;
    font-weight: 400;
    span {
      font-weight: 500;
    }
  }

  .content .info .info-item.left {
    background: linear-gradient(135deg, #07ec9a 0%, #02ab6e 100%) !important;
    border-radius: 4px !important;
    text-align: center;
    height: 100%;
    padding: 15px;
  }

  .content .info .info-item.right {
    background: linear-gradient(135deg, #feb692 0%, #ea5455 100%) !important;
    border-radius: 4px !important;
    text-align: center;
    height: 100%;
    padding: 15px;
  }
`;
