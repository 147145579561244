export const URL = "hotline";
export const URL_GET_HOTLINE = "hotline/list";
export const URL_CREATE_HOTLINE = "hotline/create";
export const URL_EDIT_HOTLINE = "hotline/edit";
export const URL_DELETE_HOTLINE = "hotline/delete"; 

export const GET_LIST_HOTLINE = "GET_LIST_HOTLINE";
export const SET_PAGE_SIZE = "SET_HOTLINE_PAGE_SIZE";
export const SET_PAGE_NUMBER = "SET_HOTLINE_PAGE_NUMBER";
export const SET_TOTAL = "SET_HOTLINE_TOTAL";
export const SET_FILTER = "SET_HOTLINE_FILTER";
export const SET_CHOOSE_LIST = "SET_SHAREHOLDER_STATUS_LIST";


// export const GET_LIST = "GET_SHAREHOLDER_STATUS_LIST";
// export const TOGGLE_MODAL = "TOGGLE_SHAREHOLDER_STATUS_MODAL";
// export const SET_PAGE_SIZE = "SET_SHAREHOLDER_STATUS_PAGE_SIZE";
// export const SET_PAGE_NUMBER = "SET_SHAREHOLDER_STATUS_PAGE_NUMBER";
// export const SET_FILTER = "SET_SHAREHOLDER_STATUS_FILTER";
// export const SET_DETAILS = "SET_SHAREHOLDER_STATUS_DETAILS";
// export const SET_TOTAL = "SET_SHAREHOLDER_STATUS_TOTAL";