import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  setToggleModal,
  checkInApi,
} from './../../../../states/duck/pages/checkin/checkInHandReducer/actions';
import { transformNumberPrinter } from './../../../bases/configs';
import { callApi } from '../../../../states/common/index';
import { AddAuthorityModalTest } from './modal';
import {
  SAuthorityList,
  SFromAuthority,
  STable,
} from '@styled/checkIn/checkInHandPage';
import Input from '@components/input';
import inputIcons from '@components/icons/input';
import Button from '@components/button';
import ButtonCustom from '@components/buttonCustom';
import Table from '@components/table';
import Modal, { SModalContent } from '@components/modal';
import { useTranslation } from 'react-i18next';

const Action = ({ setCheckinItemReport }) => {
  const { t } = useTranslation();

  const admin = JSON.parse(localStorage.getItem('adminId'));
  const processorId = admin && admin.adminId ? admin.adminId : '';
  const dispatch = useDispatch();
  const history = useHistory();
  const [shareholder, setShareholder] = useState('');
  const checked = useSelector((state) => state.checkin.checkInHand.checked);
  const [type, setType] = useState('live');
  const [authorityList, setAuthorityList] = useState([]);

  const finishCheckIn = async () => {
    if (type === 'live') {
      await dispatch(
        checkInApi(shareholder._id, {
          processorId,
          checkInType: type,
        }),
      );
      setCheckinItemReport([shareholder]);
    }
  };

  useEffect(() => {
    setType(history.location.state.type);
  }, [history.location.state.type]);

  useEffect(() => {
    setShareholder(history.location.state.item);
  }, [history.location.state.item]);

  const [remainingState, setRemainingState] = useState(0);
  // tính số uỷ quyền còn lại
  useEffect(() => {
    let remaining = shareholder.sharesNumber;
    if (authorityList.length > 0) {
      for (let i = 0; i < authorityList.length; i++) {
        remaining = remaining - authorityList[i].sharesNumber;
      }
    }
    setRemainingState(remaining);
  }, [authorityList, shareholder.sharesNumber]);

  return (
    <>
      <SFromAuthority>
        <h1 className="title">
          {type === 'authority' ? t('Xử lý uỷ quyền') : t('Checkin thủ công')}

          <Button
            onClick={() => history.push('/kiem-tra-tu-cach/thu-cong')}
            style={{ width: '90px', height: '40px', marginLeft: '20px' }}
          >
            {t('Quay lại')}
          </Button>
        </h1>

        <form className="form">
          <div className="bs-row row-lg-10 row-sm-10">
            <div className="bs-col lg-33-10 sm-50-10">
              <div className="item">
                <Input.Text
                  wrapperProps={{
                    label: t('Họ tên'),
                    icon: inputIcons.men,
                  }}
                  inputProps={{
                    type: 'text',
                    defaultValue: `${shareholder.name ? shareholder.name : ''}`,
                    disabled: true,
                  }}
                />
              </div>
            </div>

            <div className="bs-col lg-33-10 sm-50-10">
              <div className="item">
                <Input.Text
                  wrapperProps={{
                    label: t('Số điện thoại'),
                    icon: inputIcons.phone,
                  }}
                  inputProps={{
                    type: 'text',
                    defaultValue: `${
                      shareholder.phone ? shareholder.phone : ''
                    }`,
                    disabled: true,
                  }}
                />
              </div>
            </div>

            <div className="bs-col lg-33-10 sm-50-10">
              <div className="item">
                <Input.Text
                  wrapperProps={{
                    label: t('CMND/CCCD'),
                    icon: inputIcons.men,
                  }}
                  inputProps={{
                    type: 'text',
                    defaultValue: `${
                      shareholder.identityNumber
                        ? shareholder.identityNumber
                        : ''
                    }`,
                    disabled: true,
                  }}
                />
              </div>
            </div>

            <div className="bs-col lg-33-10 sm-50-10">
              <div className="item">
                <Input.Text
                  wrapperProps={{
                    label: t('Ngày cấp'),
                    icon: inputIcons.date,
                  }}
                  inputProps={{
                    type: 'text',
                    defaultValue: `${
                      shareholder.identityDate ? shareholder.identityDate : ''
                    }`,
                    disabled: true,
                  }}
                />
              </div>
            </div>

            <div className="bs-col lg-33-10 sm-50-10">
              <div className="item">
                <Input.Text
                  wrapperProps={{
                    label: t('Nơi cấp'),
                    icon: inputIcons.address,
                  }}
                  inputProps={{
                    type: 'text',
                    defaultValue: `${
                      shareholder.identityAddress
                        ? shareholder.identityAddress
                        : ''
                    }`,
                    disabled: true,
                  }}
                />
              </div>
            </div>

            <div className="bs-col lg-33-10 sm-50-10">
              <div className="item">
                <Input.Text
                  wrapperProps={{
                    label: t('Cổ phần'),
                    icon: inputIcons.file,
                  }}
                  inputProps={{
                    type: 'text',
                    value: `${remainingState}`,
                    disabled: true,
                  }}
                />
              </div>
            </div>
          </div>
        </form>

        {type === 'authority' && (
          <AuthorityList
            shareholder={shareholder}
            setShareholder={setShareholder}
            checked={checked}
            setCheckinItemReport={setCheckinItemReport}
            authorityList={authorityList}
            setAuthorityList={setAuthorityList}
          />
        )}

        {type === 'live' && (
          <div className="form-handle">
            {checked ? (
              <button
                className="common__btn disable__btn"
                type="button"
                style={{ marginRight: '10px' }}
              >
                {t('Hoàn thành')}
              </button>
            ) : (
              <button
                className="common__btn"
                onClick={finishCheckIn}
                type="button"
                style={{ marginRight: '10px' }}
              >
                {t('Hoàn thành')}
              </button>
            )}
            {checked ? (
              <button
                type="button"
                className="common__btn default__btn"
                onClick={() => {
                  setTimeout(() => {
                    var w = window.open();
                    w.document.write(
                      document.getElementById('print').innerHTML,
                    );
                    w.print();
                    w.close();
                  }, 200);
                }}
              >
                {t('In Phiếu biểu quyết')}
              </button>
            ) : (
              <button
                type="button"
                className="common__btn default__btn disable__btn"
              >
                {t('In Phiếu biểu quyết')}
              </button>
            )}
          </div>
        )}
      </SFromAuthority>
    </>
  );
};
export default Action;

const theads = [
  { name: 'Stt' },
  { name: 'Họ tên' },
  { name: 'CMND/CCCD' },
  { name: 'Số điện thoại' },
  { name: 'Email' },
  { name: 'CP nhận uỷ quyền' },
  { name: 'Chức năng' },
];

const AuthorityList = ({
  shareholder,
  setCheckinItemReport,
  setShareholder,
  authorityList,
  setAuthorityList,
}) => {
  const { t } = useTranslation();

  const history = useHistory();
  const item = history.location.state ? history.location.state.item : '';
  const dispatch = useDispatch();
  const toggleModal = useSelector(
    (state) => state.checkin.checkInHand.toggleModal,
  );

  const openModal = (value) => {
    // setModalTye(value);
    dispatch(setToggleModal(true));
  };

  const [reloadAfterAutho, setReloadAfterAutho] = useState(false);
  // console.log('reloadAfterAutho: ', reloadAfterAutho);

  useEffect(() => {
    callApi('info/authority/100/1', 'POST', {
      shareholderId: shareholder._id,
      status: 'accept',
    }).then((res) => {
      const dataResponse = res.data.data.data || [];
      // console.log('dataResponse: ', dataResponse);

      const dataResponseFilter = dataResponse.filter(
        (item) => item.shareholder,
      );

      let dataFilterCollection = [];

      for (let i = 0; i < dataResponseFilter.length; i++) {
        let index = findIndex(
          dataResponseFilter[i].identityNumber,
          dataFilterCollection,
        );

        if (index < 0) {
          dataFilterCollection.push(dataResponseFilter[i]);
        } else {
          let item = {
            ...dataFilterCollection[index],
            sharesNumber:
              dataFilterCollection[index].sharesNumber +
              dataResponseFilter[i].sharesNumber,
          };
          dataFilterCollection.splice(index, 1, item);
        }
      }

      setAuthorityList(dataFilterCollection);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareholder.identityNumber, reloadAfterAutho]);

  return (
    <SAuthorityList>
      <div className="button-content" style={{ textAlign: 'right' }}>
        <Button className="button_add" onClick={() => openModal('add')}>
          <img src="../images/icon_add.png" alt="" />{' '}
          {t('Thêm người được ủy quyền')}
        </Button>

        {/* {authorityList.length > 0 && (
          <ButtonCustom
            status="blue"
            type="button"
            content={t('In tất cả phiếu vote')}
            onClick={() => {
              let authorityListMap = authorityList.map((item) => ({
                ...item,
                sharesNumber: item.totalSharesNumber,
              }));
              setCheckinItemReport(authorityListMap);
              // console.log('authorityList', authorityList);
              setTimeout(() => {
                var w = window.open();
                w.document.write(document.getElementById('print').innerHTML);
                w.print();
                w.close();
              }, 200);
            }}
          />
        )} */}
      </div>

      <STable>
        <Table header={theads}>
          {authorityList.length > 0 ? (
            authorityList.map((item, index) => {
              return (
                <tr key={index}>
                  <td className="center">{index + 1}</td>
                  <td>{item.name}</td>
                  <td>{item.identityNumber}</td>
                  <td>{item.phone}</td>
                  <td>{item.email || ''}</td>
                  <td>{transformNumberPrinter(item.sharesNumber)}</td>
                  <td className="last_td center">
                    <ButtonCustom
                      status="blue"
                      type="button"
                      onClick={() => {
                        setCheckinItemReport([
                          { ...item, sharesNumber: item.totalSharesNumber },
                        ]);
                        setTimeout(() => {
                          var w = window.open();
                          w.document.write(
                            document.getElementById('print').innerHTML,
                          );
                          w.print();
                          w.close();
                        }, 200);
                      }}
                    />
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="8" style={{ textAlign: 'center' }}>
                Không có dữ liệu
              </td>
            </tr>
          )}
        </Table>
      </STable>

      <Modal
        isOpen={toggleModal}
        toggleModal={() => dispatch(setToggleModal(false))}
        title={t('Người được ủy quyền')}
        overwriteModalStyle={{
          maxWidth: '848px',
        }}
      >
        <SModalContent>
          {toggleModal && (
            <AddAuthorityModalTest
              reloadAfterAutho={reloadAfterAutho}
              setReloadAfterAutho={setReloadAfterAutho}
              shareholder={shareholder}
              onClick={() => dispatch(setToggleModal(false))}
              setShareholder={setShareholder}
              authorityList={authorityList}
              item={item}
              setAuthorityList={setAuthorityList}
            />
          )}
        </SModalContent>
      </Modal>
    </SAuthorityList>
  );
};

const findIndex = (identityNumber, arr) => {
  let index = -1;
  for (let i = 0; i < arr.length; i++) {
    if (identityNumber === arr[i].identityNumber) {
      index = i;
    }
  }
  return index;
};
