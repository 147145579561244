import styled from 'styled-components';

export const SSettingPage = styled.div`
  .module-header {
    margin-bottom: 25px;
  }
  .module-content_first {
    margin: 0 auto;
    width: 500px;
    max-width: 100%;
    .input-item {
      margin-top: 15px;
    }
  }
  .setting-tools {
    display: flex;
    align-items: center;
    .column {
      &.left {
        width: 50%;
        .phone {
          border: 1px dashed #00cb82;
          float: left;
          background: #eaf1fa;
          padding: 12px 30px;
          display: flex;
          justify-content: center;
          font-weight: 600;
          margin-bottom: 20px;
        }
        .note {
          clear: both;
          font-size: 14px;
          color: red;
        }
        .btn-container {
          margin-top: 30px;
          display: flex;
          justify-content: space-around;
          button {
            &.btn-cancel {
              background: #ff6c6c;
            }
            &.btn-disabled {
              background: #718098;
              pointer-events: none;
              cursor: not-allowed;
            }
          }
        }
      }
      &.right {
        width: 50%;
        display: flex;
        justify-content: center;
        img {
          max-width: 60%;
        }
      }
    }
  }
  @media only screen and (max-width: 850px) {
    .setting-tools {
      .column {
        &.left {
          width: 65%;
          .btn-container {
            justify-content: space-between;
            button{
                width:130px;
            }
          }
        }
        &.right {
          width: 35%;
          img {
            max-width: 90%;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 515px) {
    .setting-tools {
      .column {
        &.left {
          width: 100%;
          .btn-container {
            justify-content: space-around;
            button{
                width:130px;
            }
          }
        }
        &.right {
          display:none
        }
      }
    }
  }
`;

export const SSAuth2Step = styled.div`
  padding: 30px 56px 0;
  label {
    color: #718098;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }
  input {
    margin: 10px 0 30px;
  }
  button {
    margin: 0 auto;
    display: block;
  }
  @media only screen and (max-width: 550px) {
    padding: 30px 15px 10px;
  }
`;
