import styled from 'styled-components';

export const SecretaryExportReportPage = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 40px;
  background: #ffffff80;

  .left {
    width: 40%;
    position: relative;

    .title {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
      color: #333b6a;
    }
    .btn-container {
      width: 85%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-wrap: wrap;
      .export-btn {
        text-transform: capitalize;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
        cursor: pointer;
        height: 55px;
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
        color: #333b6a;
        span {
          z-index: 1;
        }
        img {
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          transition: all 0.2s;
          &.active {
            opacity: 0;
          }
        }
        &:hover {
          color: white;
          img {
            &.active {
              opacity: 1;
            }
            &.nomal {
              opacity: 0;
            }
          }
        }
      }
    }
  }
  .right {
    width: 60%;
    display: flex;
    img {
      width: 85%;
    }
  }
  @media only screen and (max-width: 1110px) {
    .left {
      width: 55%;
      .btn-container {
        padding-top: 40px;
        .export-btn {
          height: 47px;
        }
      }
    }
    .right {
      width: 45%;
    }
  }
  @media only screen and (max-width: 850px) {
    padding: 20px;
    .left {
      width: 55%;
      .title {
        font-size: 18px;
      }
      .btn-container {
        padding-top: 20px;
        .export-btn {
          margin: 7px 0;
          height: 43px;
          font-size: 16px;
        }
      }
    }
    .right {
      width: 45%;
    }
  }
  @media only screen and (max-width: 566px) {
    padding: 20px;
    justify-content: center;
    .left {
      width: 100%;
      .title {
        position: unset;
        margin-bottom: 10px;
      }
      .btn-container {
        margin: 0 auto;
        padding-top: 0px;
        justify-content: flex-start;
        .export-btn {
          margin: 7px 0;
          height: 43px;
          font-size: 16px;
        }
      }
    }
    .right {
      margin-top: 15px;
      width: 70%;
    }
  }
  @media only screen and (max-width: 400px) {
    .left {
      .title {
        font-size: 15.5px;
      }
      .btn-container {
        .export-btn {
          font-size: 14px;
        }
      }
    }
    .right {
      width: 90%;
    }
  }
`;
