import * as constants from './constants';
import { callApi, callExportApi, callDownloadApi } from 'states/common';
// import { callApi } from '@state-common/index';
import * as common from 'states/common/constants';
import PNotify from 'pnotify/dist/es/PNotify';
import { handleError } from '@configs/handleError';
var FileSaver = require('file-saver');

export const getAccountApi = (data) => {
  const { pageSize, pageNumber, search } = data;
  return (dispatch) => {
    if (search === '' || search === null || search === undefined)
      callApi(`${constants.URL_GET_ACCOUNT}/${pageSize}/${pageNumber}`, 'GET', {
        search,
      })
        .then((res) => {
          dispatch(getListAccount(res.data.data.admins));
          dispatch(setTotal(res.data.data.total));
        })
        .catch(handleError);
    else
      callApi(`${constants.URL_GET_ACCOUNT}/${pageSize}/${pageNumber}`, 'GET', {
        search,
      })
        .then((res) => {
          dispatch(getListAccount(res.data.data.admins));
          dispatch(setTotal(res.data.data.total));
        })
        .catch(handleError);
  };
};

export const downloadFileAPI = () => {
  return (dispatch) =>
    callDownloadApi(`${constants.URL_DOWNLOAD}`, 'GET', null).then(function (
      res,
    ) {
      var file = new Blob([res.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
      });
      FileSaver.saveAs(file, 'Danhsachtaikhoanquantri');
    });
};

export const exportListAccount = (body) => {
  const { name, description, account } = body;
  return (dispatch) =>
    callExportApi(`${constants.URL_EXPPORT_ACCOUNT}`, 'POST', {
      name: name,
      description: description,
      account: account,
    })
      .then(function (res) {
        PNotify.success({
          title: 'Thành công',
          text: 'Xuất danh sách danh sách tài khoản quản trị thành công!',
          destroy: true,
          delay: 2000,
        });
        var file = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        FileSaver.saveAs(file, 'DStaikhoanquantri');
      })
      .catch(handleError);
};

export const editAccountApi = (id, data, filter, setToggleModal) => {
  return (dispatch) => {
    callApi(`${constants.URL_EDIT}/${id}`, 'POST', data)
      .then((res) => {
        setTimeout(() => {
          dispatch(getAccountApi(filter));
        }, 200);
        setToggleModal(false);
        dispatch(setListChose([]));
        PNotify.success({
          title: 'Thành công',
          text: 'Sửa tài khoản quản trị thành công',
          destroy: true,
          delay: 2000,
        });
      })
      .catch((err) => {
        // let message =
        //   err && err.response && err.response.data && err.response.data.message
        //     ? err.response.data.message
        //     : err.response.statusText;
        // if (
        //   err &&
        //   err.response &&
        //   err.response.data &&
        //   err.response.data.message === 'CMND/CCCD đã tồn tại.'
        // ) {
        //   message = 'Tài khoản đã tồn tại!';
        // }
        // PNotify.error({
        //   title: 'Lỗi',
        //   text: message,
        //   destroy: true,
        //   delay: 2000,
        // });
      });
  };
};

export const getDeleteApi = (officerIds, filter) => {
  const filterNew = {
    ...filter,
    pageNumber: 1,
  };
  return (dispatch) => {
    callApi(`${constants.URL_DELETE}`, 'POST', { officerIds })
      .then((res) => {
        setTimeout(() => {
          dispatch(setFilter(filterNew));
          dispatch(getAccountApi(filterNew));
        }, 200);
        PNotify.success({
          title: 'Thành công',
          text: 'Xóa tài khoản thành công',
          destroy: true,
          delay: 2000,
        });
      })
      .catch(handleError);
  };
};

export const getCreateApi = (body, filter) => {
  return (dispatch) =>
    callApi(`${constants.URL_CREATE}`, 'POST', body)
      .then(function (res) {
        dispatch(setSending(false));
        PNotify.success({
          title: 'Thành công',
          text: 'Tạo danh sách thành công',
          destroy: true,
          delay: 2000,
        });
        setTimeout(() => {
          dispatch(
            setFilter({
              pageSize: common.PAGE_SIZE,
              pageNumber: 1,
            }),
          );
          dispatch(
            getAccountApi({
              pageSize: common.PAGE_SIZE,
              pageNumber: 1,
            }),
          );
        }, 500);
      })
      .catch(function (err) {
        // console.log('err', err);
        // dispatch(setSending(false));
        // if (err) {
        //   if (err.response && err.response.data && err.response.data.message) {
        //     PNotify.error({
        //       title: 'Lỗi',
        //       text: err.response.data.message,
        //       destroy: true,
        //       delay: 2000,
        //     });
        //   } else if (err.response) {
        //     PNotify.error({
        //       title: 'Lỗi',
        //       text: err.response.statusText,
        //       destroy: true,
        //       delay: 2000,
        //     });
        //   }
        // }
      });
};

export const getListAccount = (value) => {
  return {
    type: constants.GET_LIST,
    value,
  };
};
export const setSending = (value) => {
  return {
    type: constants.SET_SENDING,
    value,
  };
};
export const setChooseList = (value) => {
  return {
    type: constants.SET_CHOOSE_LIST,
    value,
  };
};
export const setFilter = (value) => {
  return {
    type: constants.SET_FILTER,
    value,
  };
};
export const setTotal = (value) => {
  return {
    type: constants.SET_TOTAL,
    value,
  };
};

export const setListChose = (value) => {
  return {
    type: constants.SET_LIST_CHOSE,
    value,
  };
};
