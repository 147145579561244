import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
// import Pagination from './pagination';
// thead =[{
// name,
// colspan
// sortUp
// sortDown
// }, ]
const Table = ({ header, children, minWidth = '850px', customHeader }) => {
  const { t } = useTranslation();
  return (
    <StylesTable>
      <div className="table-container">
        <table style={{ minWidth }}>
          {customHeader ? (
            customHeader
          ) : (
            <thead>
              <tr className="bold">
                {header.map((theadItem, index) => {
                  return (
                    <th
                      key={index}
                      colSpan={theadItem.colspan}
                      {...theadItem.props}
                    >
                      {theadItem.sortUp && theadItem.sortDown ? (
                        <div className="thead-container">
                          <span>{t(theadItem.name)}</span>
                          <div className="sort-container">
                            <div
                              className="sort-item"
                              onClick={theadItem.sortUp}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="16"
                                viewBox="0 0 10 16"
                                fill="none"
                              >
                                <path
                                  d="M1 5L5 1M5 1L9 5M5 1V15"
                                  stroke="#DFE9F5"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                            <div
                              className="sort-item"
                              onClick={theadItem.sortDown}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="16"
                                viewBox="0 0 10 16"
                                fill="none"
                              >
                                <path
                                  d="M9 11L5 15M5 15L1 11M5 15V1"
                                  stroke="#DFE9F5"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      ) : (
                        t(theadItem.name)
                      )}
                    </th>
                  );
                })}
              </tr>
            </thead>
          )}
          <tbody>{children}</tbody>
        </table>
      </div>
    </StylesTable>
  );
};

export const StylesTable = styled.div`
  /* user-select: none; */
  .table-container {
    overflow: auto;
    width: 100%;
    /* min-height: 300px; */
    ::-webkit-scrollbar {
      width: 6px;
      height: 7px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #afc1da;
      transition: all 0.2s;
      cursor: pointer;
      border-radius: 2px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #95a5be;
    }
  }
  table {
    border-collapse: initial;
    width: 100%;
    border-spacing: 2px;
    thead tr th {
      text-align: left;
      background: #718098;
      color: #fff;
      padding: 10px 12px 10px 20px;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      border: none;
      .thead-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
        }
        .sort-container {
          margin-left: 3px;
          display: flex;
          flex-wrap: wrap;
          .sort-item {
            width: 18px;
            height: 16px;
            border: 1px solid #718098;
            border-radius: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover {
              cursor: pointer;
              svg {
                path {
                  fill: #333b6a;
                }
              }
            }
          }
        }
      }
    }
    thead tr th:first-child {
      text-align: center;
      padding: 10px 8px;
    }
    tbody {
      tr {
        position: relative;
        background: #ffffff80;
        transition: all 0.1s ease;
        &:hover {
          cursor: pointer;
          background: #ffffff;
          td {
            color: rgba(51, 59, 106, 1);
          }
          &::after {
            opacity: 1;
          }
        }
        &.active {
          cursor: pointer;
          background: #ffffff;
          td {
            color: rgba(51, 59, 106, 1);
          }
        }
        td {
          text-align: center;
          padding: 10px 15px 9px 20px;
          color: #718098;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          border: 1px solid white;
          margin-left: 5px;
          text-align: left;
          &.left {
            text-align: left;
          }
          &.center {
            text-align: center;
            text-decoration-line: underline;
            color: #2f80ed;
          }
          &.success {
            color: #00cb82;
          }
          &.fail {
            color: #ff6c6c;
          }
        }
        td:first-child {
          text-align: center;
          padding: 10px 5px;
        }
      }
    }
  }
  .pagination-container {
    width: 100%;
    color: #718098;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    border: 1px solid white;
    background: #ffffff80;
    padding: 5px;
  }
`;

export default Table;
