export const vi4 = {
  'Check thủ công': 'Check thủ công',
  'Cổ phần sở hữu(1)': 'Cổ phần sở hữu(1)',
  'Cổ phần ủy quyền (2)': 'Cổ phần ủy quyền (2)',
  'Cổ phần nhận ủy quyền (3)': 'Cổ phần nhận ủy quyền (3)',
  'Tổng (4) = (1) + (3) - (2)': 'Tổng (4) = (1) + (3) - (2)',
  'Ủy quyền thủ công': 'Ủy quyền thủ công',
  'Họ tên/CMND/CCCD/SĐT': 'Họ tên/CMND/CCCD/SĐT',
  'Tên cổ đông': 'Tên cổ đông',
  'Không có dữ liệu': 'Không có dữ liệu',
  'Nhập thông tin người nhận ủy quyền': 'Nhập thông tin người nhận ủy quyền',
  'Hoặc tích chọn người nhận ủy quyền mặc định':
    'Hoặc tích chọn người nhận ủy quyền mặc định',
  'Thông tin người ủy quyền': 'Thông tin người ủy quyền',
  'Đại diện nhận ủy quyền': 'Đại diện nhận ủy quyền',
  'Thiết lập tài khoản nhận ủy quyền': 'Thiết lập tài khoản nhận ủy quyền',
  'Thiết lập nội dung ủy quyền': 'Thiết lập nội dung ủy quyền',
  'Thêm mới': 'Thêm mới',
  'Mật khẩu (nếu có)': 'Mật khẩu (nếu có)',
  'Chức vụ': 'Chức vụ',
  'Bạn có chắc chắn muốn khóa tài khoản đã chọn?':
    'Bạn có chắc chắn muốn khóa tài khoản đã chọn?',
  'Bạn có chắc chắn muốn mở khóa tài khoản đã chọn?':
    'Bạn có chắc chắn muốn mở khóa tài khoản đã chọn?',
  'Biểu mẫu': 'Biểu mẫu',
  'Upload biểu mẫu': 'Upload biểu mẫu',
  'Loại biểu mẫu': 'Loại biểu mẫu',
  'Danh sách tài khoản quản trị': 'Danh sách tài khoản quản trị',
  'Họ tên/ tài khoản': 'Họ tên/ tài khoản',
  'Xóa tài khoản quản trị': 'Xóa tài khoản quản trị',
  'Phân quyền tài khoản': 'Phân quyền tài khoản',
  'Chức năng': 'Chức năng',
  'Phân quyền': 'Phân quyền',
  'Cấp quyền': 'Cấp quyền',
  'Thông tin người nhận ủy quyền': 'Thông tin người nhận ủy quyền',
  'CP ủy quyền': 'CP ủy quyền',
  'Địa chỉ nhận giấy ủy quyền (tiếng Anh)':
    'Địa chỉ nhận giấy ủy quyền (tiếng Anh)',
  'Thời gian (tiếng Anh)': 'Thời gian (tiếng Anh)',
  'Tải lên mẫu ủy quyền (tiếng Việt)': 'Tải lên mẫu ủy quyền (tiếng Việt)',
  'Tải lên mẫu ủy quyền (tiếng Anh)': 'Tải lên mẫu ủy quyền (tiếng Anh)',
  EligibilityTestReport: 'Biên bản kiểm tra tư cách',
  OpenningConferenceReport: 'Biên bản khai mạc đại hội',
  DocumentVoteReport: 'Biên bản kiểm phiếu Tờ trình',
  CumulativeVoteReport: 'Biên bản kiểm phiếu bầu cử',
  ClosingConferenceReport: 'Biên bản bế mạc đại hội',
  MinutesOfAGM: 'Biên bản đại hội',
  ResolutionsOfAGM: 'Nghị quyết đại hội',
  PrintLoginAccount: 'Mẫu in tài khoản đăng nhập',
  AuthorityTemplate: 'Mẫu giấy ủy quyền',
  PrintVotingTicket: 'Mẫu in phiếu biểu quyết',
  VotingResultTemplate: 'Mẫu file kết quả biểu quyết',
  'Sửa tài khoản nhận ủy quyền': 'Sửa tài khoản nhận ủy quyền',
};
export const en4 = {
  'Check thủ công': 'Manual check',
  'Cổ phần sở hữu(1)': 'Ownership shares',
  'Cổ phần ủy quyền (2)': 'Authorized Shares (2)',
  'Cổ phần nhận ủy quyền (3)': 'Authorized Shares (3)',
  'Tổng (4) = (1) + (3) - (2)': 'Total (4) = (1) + (3) - (2)',
  'Ủy quyền thủ công': 'Manual Authorization',
  'Họ tên/CMND/CCCD/SĐT': 'Name/CMND/CCCD/SĐT',
  'Tên cổ đông': 'Name of shareholder',
  'Không có dữ liệu': 'No data',
  'Phân quyền': 'Decentralization',
  'Nhập thông tin người nhận ủy quyền':
    'Enter authorized recipient information',
  'Hoặc tích chọn người nhận ủy quyền mặc định':
    'Or check the default authorized recipient',
  'Thông tin người ủy quyền': 'Authorization information',
  'Đại diện nhận ủy quyền': 'Authorized Representative',
  'Thiết lập tài khoản nhận ủy quyền': 'Set up an authorized account',
  'Thiết lập nội dung ủy quyền': 'Set up authorization content',
  'Thêm mới': ' Add new',
  'Mật khẩu (nếu có)': 'Password (if any)',
  'Chức vụ': 'Position',
  'Bạn có chắc chắn muốn khóa tài khoản đã chọn?':
    'Are you sure you want to lock the selected account?',
  'Bạn có chắc chắn muốn mở khóa tài khoản đã chọn?':
    'Are you sure you want to unlock the selected account?',
  'Biểu mẫu': 'Form',
  'Upload biểu mẫu': 'Upload form',
  'Loại biểu mẫu': 'Form Type',
  'Danh sách tài khoản quản trị': 'List of admin accounts',
  'Họ tên/ tài khoản': 'Name/ account',
  'Xóa tài khoản quản trị': 'Delete admin account',
  'Phân quyền tài khoản': 'Account Authorization',
  'Chức năng': 'Function',
  'Cấp quyền': 'Grant permission',
  'Thông tin người nhận ủy quyền': 'Information of authorized recipients',
  'CP ủy quyền': 'Authorized CP',
  'Địa chỉ nhận giấy ủy quyền (tiếng Anh)':
    'Address to receive power of attorney (English)',
  'Thời gian (tiếng Anh)': 'Time (English)',
  'Tải lên mẫu ủy quyền (tiếng Việt)': 'Upload authorization form (Vietnamese)',
  'Tải lên mẫu ủy quyền (tiếng Anh)': 'Upload authorization form (English)',
  'Sửa tài khoản nhận ủy quyền': 'Edit authorized account',
};
