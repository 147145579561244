export const vi1 = {
  'Xuất danh sách ủy quyền thành công': 'Xuất danh sách ủy quyền thành công',
  'Số cổ phần ủy quyền không được là số lẻ':
    'Số cổ phần ủy quyền không được là số lẻ',
  'Không thể ủy quyền cho Cổ đông bị chặn ở 1 trong các tờ trình biểu quyết':
    'Không thể ủy quyền cho Cổ đông bị chặn ở 1 trong các tờ trình biểu quyết',
  'Tổng số cổ phần ủy quyền không được lớn hơn số cổ phần khả dụng':
    'Tổng số cổ phần ủy quyền không được lớn hơn số cổ phần khả dụng',
  'Không thể ủy quyền chính mình': 'Không thể ủy quyền chính mình',
  'Số cổ phần ủy quyền phải lớn hơn 0': 'Số cổ phần ủy quyền phải lớn hơn 0',
  'Không thể ủy quyền cho cổ đông đã biểu quyết':
    'Không thể ủy quyền cho cổ đông đã biểu quyết',
  'Phải là file pdf': 'Phải là file pdf',
  // 'Bạn phải upload danh sách ủy quyền':
  //   'Bạn phải upload danh sách ủy quyền',
  DocumentCumulativeVoteReport: 'Biên bản kiểm phiếu Tờ trình - bầu cử',
  'Thông báo': 'Thông báo',
  'Đang xử lý, vui lòng đợi': 'Đang xử lý, vui lòng đợi',
  'Cổ đông đã hoàn thành việc biểu quyết thông qua thủ tục khai mạc Đại hội':
    'Cổ đông đã hoàn thành việc biểu quyết thông qua thủ tục khai mạc Đại hội',
  'Cổ đông đã hoàn thành biểu quyết báo cáo/tờ trình':
    'Cổ đông đã hoàn thành biểu quyết báo cáo/tờ trình',
  'Cổ đông đã hoàn thành biểu quyết thông qua biên bản/nghị quyết Đại hội':
    'Cổ đông đã hoàn thành biểu quyết thông qua biên bản/nghị quyết Đại hội',
  'Tiện ích cho Doanh nghiệp': 'Tiện ích cho Doanh nghiệp',
  'Lợi ích cho Cổ đông': 'Lợi ích cho Cổ đông',
  'Thiết lập danh sách Cổ đông bị chặn biểu quyết':
    'Thiết lập danh sách Cổ đông bị chặn biểu quyết',
  'Trở về trang chủ': 'Trở về trang chủ',
  'Trở về': 'Trở về',
  'Nội dung': 'Nội dung',
  'Lựa chọn đăng nhập': 'Lựa chọn đăng nhập',
  'Đăng nhập quyền admin': 'Đăng nhập quyền admin',
  'Đăng nhập dành cho đoàn chủ tịch': 'Đăng nhập dành cho đoàn chủ tịch',
  'Đăng nhập dành cho ban quan hệ Cổ đông':
    'Đăng nhập dành cho ban quan hệ Cổ đông',
  'Đăng nhập dành cho ban kiểm tra tư cách Cổ đông':
    'Đăng nhập dành cho ban kiểm tra tư cách Cổ đông',
  'Đăng nhập dành cho ban kiểm phiếu': 'Đăng nhập dành cho ban kiểm phiếu',
  'Đăng nhập dành cho thư ký': 'Đăng nhập dành cho thư ký',
  'Đăng nhập admin': 'Đăng nhập admin',
  'Tài khoản': 'Tài khoản',
  'Mật khẩu': 'Mật khẩu',
  'Tiếp tục': 'Tiếp tục',
  'Nhập OTP': 'Nhập OTP',
  'Tờ trình': 'Tờ trình',
  'Đã đóng biểu quyết': 'Đã đóng biểu quyết',
  'Không nhận được mã OTP?': 'Không nhận được mã OTP?',
  'Gửi lại OTP': 'Gửi lại OTP',
  'Đăng nhập': 'Đăng nhập',
  'đoàn chủ tịch': 'đoàn chủ tịch',
  'ban quan hệ Cổ đông': 'ban quan hệ Cổ đông',
  'Ban quan hệ Cổ đông': 'Ban quan hệ Cổ đông',
  'ban kiểm phiếu': 'ban kiểm phiếu',
  'ban kiểm tra tư cách Cổ đông': 'ban kiểm tra tư cách Cổ đông',
  'Ban kiểm tra tư cách Cổ đông': 'Ban kiểm tra tư cách Cổ đông',
  'thư ký': 'thư ký',
  'Vui lòng nhập số điện thoại đã cung cấp cho admin':
    'Vui lòng nhập số điện thoại đã cung cấp cho admin',
  'Vui lòng nhập mã chứng khoán của công ty':
    'Vui lòng nhập mã chứng khoán của công ty',
  'Đại hội Cổ đông': 'Đại hội Cổ đông',
  'thường niên': 'thường niên',
  'Tỉ lệ': 'Tỉ lệ',
  'Tổng số Cổ đông của Công ty': 'Tổng số Cổ đông của Công ty',
  'Tổng số Cổ phần của Công ty': 'Tổng số Cổ phần của Công ty',
  'Tìm kiếm': 'Tìm kiếm',
  'Nhập tên/CMND/CCCD': 'Nhập tên/CMND/CCCD',
  'Tên Cổ đông': 'Tên Cổ đông',
  'Cmnd/cccd': 'Cmnd/cccd',
  'Nội dung ý kiến': 'Nội dung ý kiến',
  'Thời gian': 'Thời gian',
  'Số cổ phần': 'Số cổ phần',
  'Mời phát biểu': 'Mời phát biểu',
  'Không có dữ liệu': 'Không có dữ liệu',
  'Bạn đang mời Cổ đông': 'Bạn đang mời Cổ đông',
  'phát biểu ý kiến': 'phát biểu ý kiến',
  'Bạn có chắc chắn với hành động này?': 'Bạn có chắc chắn với hành động này?',
  'Chọn Cổ đông chặn': 'Chọn Cổ đông chặn',
  'Chắc chắn': 'Chắc chắn',
  'Ý kiến Cổ đông': 'Ý kiến Cổ đông',
  'Thông qua biên bản': 'Thông qua biên bản',
  'Nghị quyết Đại hội': 'Nghị quyết Đại hội',
  Duyệt: 'Duyệt',
  'Từ chối': 'Từ chối',
  'Tán thành':
    '{{label}} {{acceptanceNumbers}} Người, tương ứng {{acceptanceShares}} Cổ phần, Chiếm {{acceptanceSharesPercent}}% Cổ phần tham gia biểu quyết',
  'Không Tán thành':
    '{{label}} {{notAcceptanceNumbers}} Người, tương ứng {{notAcceptanceShares}} Cổ phần, Chiếm {{notAcceptanceSharesPercent}}% Cổ phần tham gia biểu quyết',
  'Không ý kiến':
    '{{label}} {{noIdeaNumbers}} Người, tương ứng {{noIdeaShares}} Cổ phần, Chiếm {{noIdeaSharesPercent}}% Cổ phần tham gia biểu quyết',
  'Hợp lệ':
    'Hợp lệ {{validNumbers}} Người, tương ứng {{validShares}} Cổ phần, Chiếm {{validSharesPercent}}% Cổ phần tham gia biểu quyết',
  'Không hợp lệ':
    'Không hợp lệ {{invalidNumbers}} Người, tương ứng {{invalidShares}} Cổ phần, Chiếm {{invalidSharesPercent}}% Cổ phần tham gia biểu quyết',
  'Tổng số Cổ đông tham gia': 'Tổng số Cổ đông tham dự',
  'Tổng số cổ phần tham gia': 'Tổng số Cổ phần tham dự',
  'Tổng số cổ phần': 'Tổng số Cổ phần',
  'Cổ đông tham gia online': 'Tổng số Cổ đông tham dự trực tuyến',
  'Cổ đông tham gia trực tiếp': 'Tổng số Cổ đông tham dự trực tiếp',
  'Cổ đông ủy quyền tham dự': 'Tổng số Cổ đông ủy quyền tham dự',
  'Cổ đông đang online': 'Tổng số Cổ đông - Khách mời đang online',
  'Thông qua thủ tục': 'Thông qua thủ tục',
  'Khai mạc Đại hội': 'Khai mạc Đại hội',
  'kết quả kiểm phiếu': 'kết quả kiểm phiếu',
  'Cổ đông không thể thay đổi biểu quyết đã gửi':
    'Cổ đông không thể thay đổi biểu quyết đã gửi',
  'Tổng số phiếu hợp lệ là': 'Tổng số phiếu hợp lệ',
  'Tổng số phiếu hợp lệ': 'Tổng số phiếu hợp lệ',
  'Tổng số phiếu không hợp lệ là': 'Tổng số phiếu không hợp lệ',
  'Tổng số phiếu không hợp lệ': 'Tổng số phiếu không hợp lệ',
  'Chọn ý kiến để in': 'Chọn ý kiến để in',
  'Danh sách Cổ đông tham gia biểu quyết hợp lệ':
    'Danh sách Cổ đông tham gia biểu quyết hợp lệ',
  'Danh sách Cổ đông tham gia biểu quyết không hợp lệ':
    'Danh sách Cổ đông tham gia biểu quyết không hợp lệ',
  'Danh sách Cổ đông tham gia bầu cử hợp lệ':
    'Danh sách Cổ đông tham gia bầu cử hợp lệ',
  'Danh sách Cổ đông tham gia bầu cử không hợp lệ':
    'Danh sách Cổ đông tham gia bầu cử không hợp lệ',

  phiếu: 'phiếu',
  'sắp xếp thứ tự hiển thị': 'sắp xếp thứ tự hiển thị',
  Stt: 'Stt',
  'Họ và tên': 'Họ và tên',
  'Số phiếu bầu': 'Số phiếu bầu',
  'Chưa chặn': 'Chưa chặn',
  'Đã chặn': 'Đã chặn',
  'Tỷ lệ %': 'Tỷ lệ %',
  'TỶ LỆ THÔNG QUA': 'TỶ LỆ THÔNG QUA',
  'Không được để trống': 'Không được để trống',
  'Số điện thoại không đúng': 'Số điện thoại không đúng',
  'Số fax không đúng': 'Số fax không đúng',
  'Email không đúng': 'Email không đúng',
  'Thành công': 'Thành công',
  'Mời Cổ đông phát biểu thành công': 'Mời Cổ đông phát biểu thành công',
  'Chức năng': 'Chức năng',

  'Chặn CĐ biểu quyết': 'Chặn CĐ biểu quyết',
  'Chặn cổ đông biểu quyết': 'Chặn Cổ đông biểu quyết',
  //
  //ADMIN
  //

  //đăng nhập
  'Vui lòng lựa chọn quyền đăng nhập': 'Vui lòng lựa chọn quyền đăng nhập',
  'Đăng nhập quyền Admin': 'Đăng nhập quyền Admin',
  'Đăng nhập dành cho Đoàn Chủ tịch': 'Đăng nhập dành cho Đoàn Chủ tịch',
  'Đăng nhập dành cho ban quan hệ cổ đông':
    'Đăng nhập dành cho ban quan hệ cổ đông',
  'Đăng nhập dành cho ban kiểm tra tư cách cổ đông':
    'Đăng nhập dành cho ban kiểm tra tư cách cổ đông',
  'Đăng nhập dành cho Ban kiểm phiếu': 'Đăng nhập dành cho Ban kiểm phiếu',
  'Đăng nhập dành cho Ban thư ký': 'Đăng nhập dành cho Ban thư ký',

  //khai báo thông tin cơ bản
  'Khai báo thông tin cơ bản': 'Khai báo thông tin cơ bản',
  'Logo công ty': 'Logo công ty',
  'Banner công ty': 'Banner công ty',
  'Tên công ty': 'Tên công ty',
  'Tên công ty (Tiếng Anh)': 'Tên công ty (Tiếng Anh)',
  'Số điện thoại': 'Số điện thoại',
  'Số fax': 'Số fax',
  'Mã chứng khoán': 'Mã chứng khoán',
  'Mã CK': 'Mã CK',
  'Mã doanh nghiệp': 'Mã doanh nghiệp',
  'Tổng số Cổ đông': 'Tổng số Cổ đông',
  'Tổng số Cổ phần': 'Tổng số Cổ phần',
  'Trụ sở công ty': 'Trụ sở công ty',
  'Trụ sở công ty (Tiếng Anh)': 'Trụ sở công ty (Tiếng Anh)',
  'Địa điểm điều hành Đại hội': 'Địa điểm điều hành Đại hội',
  'Địa điểm điều hành Đại hội (Tiếng Anh)':
    'Địa điểm điều hành Đại hội (Tiếng Anh)',
  'Thời gian họp': 'Thời gian họp',
  'Thời gian họp (Tiếng Anh)': 'Thời gian họp (Tiếng Anh)',
  'Tên cuộc họp': 'Tên cuộc họp',
  'Tên cuộc họp (Tiếng Anh)': 'Tên cuộc họp (Tiếng Anh)',
  'Chủ toạ Đại hội': 'Chủ toạ Đại hội',
  'Chủ toạ Đại hội (Tiếng Anh)': 'Chủ toạ Đại hội (Tiếng Anh)',
  'Thư ký Đại hội': 'Thư ký Đại hội',
  'Thư ký Đại hội (Tiếng Anh)': 'Thư ký Đại hội (Tiếng Anh)',
  'Ngày chốt quyền': 'Ngày chốt quyền',
  'Link video HDSD': 'Link video HDSD',
  'Link video HDSD (Tiếng Anh)': 'Link video HDSD (Tiếng Anh)',
  'Thời gian bắt đầu phải trước thời gian kết thúc và phải sau thời gian hiện tại':
    'Thời gian bắt đầu phải trước thời gian kết thúc và phải sau thời gian hiện tại',
  'Link phát biểu': 'Link phát biểu',
  'Ngoài thời gian bầu cử': 'Ngoài thời gian bầu cử',
  Hủy: 'Hủy',
  Lưu: 'Lưu',
  'Tạo mới': 'Thêm',
  'Xem kết quả biểu quyết': 'Xem kết quả biểu quyết',
  'Xem kết quả Đại hội': 'Xem kết quả Đại hội',
  'Xóa nội dung': 'Xóa nội dung',
  'Xóa các nội dung': 'Xóa các nội dung',

  //Khai báo thủ tục khai mạc Đại hội
  'Khai báo thủ tục khai mạc Đại hội': 'Khai báo thủ tục khai mạc Đại hội',
  'Khai báo thủ tục khai mạc': 'Khai báo thủ tục khai mạc',
  'Bạn có chắc chắn xóa các nội dung đã chọn?':
    'Bạn có chắc chắn xóa các nội dung đã chọn ?',
  'Tên nội dung': 'Tên nội dung',
  'Tên nội dung (Tiếng Anh)': 'Tên nội dung (Tiếng Anh)',
  'Upload file nội dung': 'Upload file nội dung',
  'Upload file nội dung (Tiếng Anh)': 'Upload file nội dung (Tiếng Anh)',
  'Tạo nội dung': 'Tạo nội dung',
  STT: 'STT',
  'Thứ tự hiển thị': 'Thứ tự hiển thị',
  'Thứ tự': 'Thứ tự',
  'Sửa nội dung': 'Sửa nội dung',
  'Hủy bỏ': 'Hủy bỏ',
  'Cập nhật': 'Cập nhật',
  'Sắp xếp': 'Sắp xếp',
  Sửa: 'Sửa',
  Xoá: 'Xoá',
  'Đồng ý': 'Đồng ý',
  'Số thứ tự': 'Số thứ tự',
  'Tạo Hotline thành công': 'Tạo Hotline thành công',

  //Khai báo báo cáo - tờ trình
  'Khai báo báo cáo - tờ trình': 'Khai báo báo cáo - tờ trình',
  'Mặc định': 'Mặc định',
  'Bầu dồn phiếu': 'Bầu dồn phiếu',
  'Số lượng bầu cử': 'Số lượng bầu cử',
  'Danh sách ứng viên': 'Danh sách ứng viên',
  'Họ tên': 'Họ tên',
  'Họ tên Cổ đông': 'Họ tên Cổ đông',
  'Họ tên (Tiếng Anh)': 'Họ tên (Tiếng Anh)',
  Thêm: 'Thêm',
  'còn lại': 'còn lại',

  //Khai báo Biên bản - Nghị quyết Đại hội
  'Khai báo Biên bản - Nghị quyết Đại hội':
    'Khai báo Biên bản - Nghị quyết Đại hội',
  'Tạo thành viên thành công': 'Tạo thành viên thành công',
  'Đăng nhập thất bại': 'Đăng nhập thất bại!',
  'Không thể xóa Admin tổng': 'Không thể xóa Admin tổng',
  //Khai báo các ban
  'Khai báo các ban': 'Khai báo các ban',
  'Chọn ban': 'Chọn ban',
  Chọn: 'Chọn',
  'Họ tên, tài khoảnn': 'Họ tên, tài khoản...',
  'Đoàn chủ tịch': 'Đoàn chủ tịch',
  'Ban quan hệ CĐ': 'Ban quan hệ CĐ',
  'Ban kiểm tra tư cách CĐ': 'Ban kiểm tra tư cách CĐ',
  'Ban kiểm phiếu': 'Ban kiểm phiếu',
  'Ban thư ký': 'Ban thư ký',
  'Vai trò': 'Vai trò',
  'Trưởng ban': 'Trưởng ban',
  'Thành viên': 'Thành viên',
  'đăng nhập': 'Đăng nhập',
  'Kết quả phiếu theo từng nội dung': 'K.quả kiểm phiếu theo từng n.dung',
  'Tên đăng nhập': 'Tên đăng nhập',
  'Tên đăng nhập/SĐT': 'Tên đăng nhập/SĐT',
  Tạo: 'Tạo',
  'Ban trực thuộc': 'Ban trực thuộc',
  'Sửa thông tin': 'Sửa thông tin',
  'Chưa có dữ liệu': 'Chưa có dữ liệu',
  //Danh sách Cổ đông
  'Danh sách Cổ đông': 'Danh sách Cổ đông',
  'Tải về file mẫu': 'Tải về file mẫu',
  'Tải lên file mẫu': 'Tải lên file mẫu',
  'Tải lên danh sách Cổ đông': 'Tải lên danh sách Cổ đông',
  'Họ tên/CMND/CCCD': 'Họ tên/CMND/CCCD...',
  'Tải lên danh sách ủy quyền': 'Tải lên danh sách ủy quyền',
  'Tạo danh sách': 'Tạo danh sách',
  'Tất cả': 'Tất cả',
  'Trong nước': 'Trong nước',
  'Nước ngoài': 'Nước ngoài',
  'Tài khoản đã tồn tại!': 'Tài khoản đã tồn tại!',
  'Tạo tài khoản khách mời thành công': 'Tạo tài khoản khách mời thành công',
  'Tổ chức': 'Tổ chức',
  /// vi up here
  'Cá nhân': 'Cá nhân',
  'Mã CĐ': 'Mã CĐ',
  'Bạn có chắc chắn muốn hủy chặn CĐ này gửi ý kiến đến Đại hội?':
    'Bạn có chắc chắn muốn hủy chặn CĐ này gửi ý kiến đến Đại hội?',
  'Họ và tên CĐ': 'Họ và tên CĐ',
  'CMND/CCCD': 'CMND/CCCD',
  'Bạn có chắc chắn muốn xóa toàn bộ danh sách Cổ đông?':
    'Bạn có chắc chắn muốn xóa toàn bộ danh sách Cổ đông?',
  'Ngày cấp': 'Ngày cấp',
  'Xóa tất cả': 'Xóa tất cả',
  'Số phiếu bầu phải lớn hơn 0': 'Số phiếu bầu phải lớn hơn 0!',
  'Không thể sao chép phiếu không hợp lệ':
    'Không thể sao chép phiếu không hợp lệ',
  'Nơi cấp': 'Nơi cấp',
  'Số lượng Cổ phần': 'Số lượng Cổ phần',
  Email: 'Email',
  'Địa chỉ': 'Địa chỉ',
  'Sửa Số điện thoại': 'Sửa Số điện thoại',
  'Sửa số điện thoại Cổ đông': 'Sửa số điện thoại Cổ đông',
  'Xoá tất cả': 'Xoá tất cả',
  'Ứng viên': 'Ứng viên',
  'Lựa chọn': 'Lựa chọn',

  //Quản lý Cổ đông
  'Quản lý Cổ đông': 'Quản lý Cổ đông',
  'Trạng thái': 'Trạng thái',
  'Đã biểu quyết': 'Đã biểu quyết',
  'Chưa biểu quyết': 'Chưa biểu quyết',
  'Chưa biểu quyết2': 'Chưa biểu quyết',
  'Hình thức': 'Hình thức',
  'Chưa checkin': 'Chưa checkin',
  'Ủy quyền': 'Uỷ quyền',
  'Trực tiếp': 'Trực tiếp',
  'Trực tuyến': 'Trực tuyến',
  'Cổ đông': 'Cổ đông',
  'Nhận ủy quyền': 'Nhận ủy quyền',
  'Trạng thái hoạt động': 'Trạng thái hoạt động',
  'Không hoạt động': 'Không hoạt động',
  'Đang hoạt động': 'Đang hoạt động',
  SDT: 'SDT',
  'Cổ phần sở hữu': 'Cổ phần sở hữu',
  'Cổ phần tham dự': 'Cổ phần tham dự',
  'Xuất file excel': 'Xuất file excel',
  'Cho phép biểu quyết lại': 'Cho phép biểu quyết lại',
  'Bạn đang cho phép Cổ đông': 'Bạn đang cho phép Cổ đông ',
  'biểu quyết lại': ' biểu quyết lại',
  'Cổ đông bị chặn biểu quyết cho nội dung này':
    'Cổ đông bị chặn biểu quyết cho nội dung này',
  'Chọn Cổ đông được ủy quyền': 'Chọn Cổ đông được ủy quyền',

  //Danh sách ủy quyền
  'Đã tải lên': 'Đã tải lên',
  'Email không hợp lệ': 'Email không hợp lệ',
  'Sửa sdt': 'Sửa sdt',
  'Tải xuống tất cả phiếu biểu quyết': 'Tải xuống tất cả phiếu biểu quyết',
  'Quản lý ủy quyền': 'Quản lý ủy quyền',
  'Địa chỉ nhận giấy ủy quyền': 'Địa chỉ nhận giấy ủy quyền',
  'Bạn đang xác nhận ủy quyền biểu quyết của':
    'Bạn đang xác nhận ủy quyền biểu quyết của',
  'Bạn đang từ chối ủy quyền biểu quyết của':
    'Bạn đang từ chối ủy quyền biểu quyết của',
  'Từ chối uỷ quyền': 'Từ chối uỷ quyền',
  'Xác nhận uỷ quyền': 'Xác nhận uỷ quyền',
  'Sửa thông tin người được ủy quyền': 'Sửa thông tin người được ủy quyền',

  'Cổ đông ủy quyền': 'Cổ đông ủy quyền',
  'Người nhận ủy quyền': 'Người nhận ủy quyền',
  'Danh sách ủy quyền': 'Danh sách ủy quyền',
  'Cổ đông đã bầu quá số phiếu sở hữu!': 'Cổ đông đã bầu quá số phiếu sở hữu!',
  'Chờ xử lý': 'Chờ xử lý',
  'Đã ủy quyền': 'Đã ủy quyền',
  'Đã xử lý': 'Đã xử lý',
  'Thất bại': 'Thất bại',
  'Người ủy quyền': 'Người ủy quyền',
  'CMND/CCCD người ủy quyền': 'CMND/CCCD người ủy quyền',
  'Người được ủy quyền': 'Người được ủy quyền',
  'CMND/CCCD người được ủy quyền': 'CMND/CCCD người được ủy quyền',
  'Số CP ủy quyền': 'Số CP ủy quyền',
  'File đính kèm': 'File đính kèm',
  'CMND/CCCD (Tài khoản)': 'CMND/CCCD (Tài khoản)',
  'Họ và tên người được ủy quyền': 'Họ và tên người được ủy quyền',
  'Số CP được ủy quyền': 'Số CP được ủy quyền',
  'Đặt lại mật khẩu': 'Đặt lại mật khẩu',
  'Hiển thị từ': 'Hiển thị từ',
  đến: 'đến',
  'Đóng/mở phần bầu cử (Bầu dồn phiếu)': 'Đóng/mở phần bầu cử (Bầu dồn phiếu)',
  'trong số': 'trong số',
  'bản ghi': 'bản ghi',
  //Ý kiến cổ đông
  'Ý kiến cổ đông': 'Ý kiến Cổ đông',
  'Đã gửi ý kiến': 'Đã gửi ý kiến',
  // 'Tất cả': 'Tất cả',
  'Đã chuyển Chủ tịch': 'Đã chuyển Chủ tịch',
  'Đã chuyển': 'Đã chuyển',
  'Chưa chuyển Chủ tịch': 'Chưa chuyển Chủ tịch',
  'Chưa chuyển': 'Chưa chuyển',
  'In danh sách ý kiến được chọn': 'In danh sách ý kiến được chọn',
  'Xuất file tất cả ý kiến': 'Xuất file tất cả ý kiến',
  'Xuất file ý kiến đã chọn': 'Xuất file ý kiến đã chọn',
  'Xuất file ý kiến thành công!': 'Xuất file ý kiến thành công!',
  Chặn: 'Chặn',
  Xóa: 'Xóa',
  'Chặn Cổ đông': 'Chặn Cổ đông',
  'Bỏ chặn Cổ đông': 'Bỏ chặn Cổ đông',
  'CP nhận ủy quyền': 'CP nhận ủy quyền',
  'Tổng số CP đại diện': 'Tổng số CP đại diện',
  'CP ủy quyền đã xử lý': 'CP ủy quyền đã xử lý',
  'CP chờ xử lý': 'CP chờ xử lý',
  'CP còn lại': 'CP còn lại',
  'Loại cổ đông': 'Loại cổ đông',
  'Bạn có chắc chắn muốn chặn CĐ này gửi ý kiến đến Đại hội?':
    'Bạn có chắc chắn muốn chặn CĐ này gửi ý kiến đến Đại hội?',
  'Xác nhận': 'Xác nhận',
  'Bỏ chặn': 'Bỏ chặn',
  // "Mời phát biểu" : "Mời phát biểu",
  'Trạng thái chuyển': 'Trạng thái chuyển',
  'Viết tin nhắn...': 'Viết tin nhắn...',

  //Khởi tạo tài khoản khách mời
  'Khởi tạo tài khoản khách mời': 'Khởi tạo tài khoản khách mời',
  'Thông tin': 'Thông tin',
  'Sửa thông tin khách mời': 'Sửa thông tin khách mời',
  'Upload file thành công': 'Upload file thành công!',
  'Bạn phải upload danh sách ủy quyền': 'Bạn phải upload danh sách ủy quyền',
  'Phải là file excel (xlsx, xls)': 'Phải là file excel (.xlsx, .xls)',
  // 'Bạn phải upload danh sách ủy quyền':
  //   'Bạn phải upload danh sách ủy quyền',
  'Phải là file excel (.xlsx, .xls)': 'Phải là file excel (.xlsx, .xls)',
  Lỗi: 'Lỗi!',
  'Lỗi!': 'Lỗi!',
  'Hoàn thành BKP': 'Hoàn thành',
  'Nếu muốn thực hiện biểu quyết tất cả số phiếu bầu, Cổ đông vui lòng thực hiện biểu quyết cho từng Cổ đông trong danh sách nhận uỷ quyền':
    'Nếu muốn thực hiện biểu quyết tất cả số phiếu bầu, Cổ đông vui lòng thực hiện biểu quyết cho từng Cổ đông trong danh sách nhận uỷ quyền.',
  'Tổng số phiếu bầu còn lại là số phiếu của các Cổ đông uỷ quyền không thoả mãn việc chia đều':
    'Tổng số phiếu bầu còn lại là số phiếu của các Cổ đông uỷ quyền không thoả mãn việc chia đều.',
  'Bạn phải upload danh sách Cổ đông': 'Bạn phải upload danh sách Cổ đông',
  'Đang tạo danh sách. Vui lòng đợi': 'Đang tạo danh sách. Vui lòng đợi',
  //Thời gian biểu quyết
  'Thời gian biểu quyết': 'Thời gian biểu quyết',
  'Cấu hình thời gian biểu quyết': 'Cấu hình thời gian biểu quyết',
  'Cấu hình thời gian diễn ra Đại hội': 'Cấu hình thời gian diễn ra Đại hội',
  'Giờ bắt đầu': 'Giờ bắt đầu',
  'Giờ kết thúc': 'Giờ kết thúc',
  'Ngày bắt đầu': 'Ngày bắt đầu',
  'Ngày kết thúc': 'Ngày kết thúc',
  'Đóng/Mở phần thông qua quy chế tổ chức':
    'Đóng/Mở phần thông qua quy chế tổ chức',
  'Nâng cao': 'Nâng cao',
  'Cổ đông đã bầu cử cho nội dung này': 'Cổ đông đã bầu cử cho nội dung này!',
  'Tên tờ trình': 'Tên tờ trình',
  'Chọn ý kiến để chuyển đến đoàn Chủ tịch!':
    'Chọn ý kiến để chuyển đến đoàn Chủ tịch!',
  'Ý kiến đã chuyển đến đoàn Chủ tịch!': 'Ý kiến đã chuyển đến đoàn Chủ tịch!',
  'Tải xuống chi tiết phiếu biểu quyết': 'Tải xuống chi tiết phiếu biểu quyết',
  'Xử lý': 'Xử lý',
  'Không thể đánh dấu Không hợp lệ cho phiếu bầu đã được sao chép':
    'Không thể đánh dấu Không hợp lệ cho phiếu bầu đã được sao chép!',
  'Send comments to the Presidium': 'Send comments to the Presidium',
  'Đóng/mở phần thông qua các tờ trình': 'Đóng/mở phần thông qua các tờ trình',
  'Đóng/mở phần thông qua Biên bản - Nghị quyết Đại hội':
    'Đóng/mở phần thông qua Biên bản - Nghị quyết Đại hội',
  'Đóng/Mở thời gian Cổ đông gửi ý kiến':
    'Đóng/Mở thời gian Cổ đông gửi ý kiến',
  'Đang lấy kết quả phiếu biểu quyết. Vui lòng đợi':
    'Đang lấy kết quả phiếu biểu quyết. Vui lòng đợi',

  //hotline
  'Hotline hỗ trợ Cổ đông': 'Hotline hỗ trợ Cổ đông',

  //Đổi mật khẩu
  'Thay đổi mật khẩu': 'Thay đổi mật khẩu',
  'Đổi mật khẩu': 'Đổi mật khẩu',
  'Nhập mã OTP được gửi tới điện thoại của bạn':
    'Nhập mã OTP được gửi tới điện thoại của bạn',
  'Mật khẩu cũ': 'Mật khẩu cũ',
  'Mật khẩu mới': 'Mật khẩu mới',
  'Xác nhận mật khẩu mới': 'Xác nhận mật khẩu mới',
  'Mật khẩu cấp 2': 'Mật khẩu cấp 2',
  'Bật mật khẩu cấp 2': 'Bật mật khẩu cấp 2',
  'Tắt mật khẩu cấp 2': 'Tắt mật khẩu cấp 2',
  'SĐT nhận OTP': 'SĐT nhận OTP',
  'Khi bật mật khẩu cấp 2 lần đầu, hệ thống sẽ yêu cầu admin cung cấp số điện thoại nhận OTP, số điện thoại này sẽ được ấn định nhận OTP cho các lần bật tắt sau':
    'Khi bật mật khẩu cấp 2 lần đầu, hệ thống sẽ yêu cầu admin cung cấp số điện thoại nhận OTP, số điện thoại này sẽ được ấn định nhận OTP cho các lần bật tắt sau',
  Bật: 'Bật',
  Tắt: 'Tắt',
  'Đăng xuất': 'Đăng xuất',

  //Ban quan hệ Cổ đông
  'Quản lý thư mời': 'Quản lý thư mời',
  'Loại khỏi danh sách cổ đông nội bộ': 'Loại khỏi danh sách Cổ đông nội bộ',
  'Hiển thị tất cả Cổ đông': 'Hiển thị tất cả Cổ đông',
  'Import danh sách Cổ đông nội bộ': 'Import danh sách Cổ đông nội bộ',
  'Tải file mẫu': 'Tải file mẫu',
  Lọc: 'Lọc',
  'In danh sách địa chỉ theo mã Cổ đông':
    'In danh sách địa chỉ theo mã Cổ đông',
  'Tải danh sách địa chỉ theo mã Cổ đông':
    'Tải danh sách địa chỉ theo mã Cổ đông',
  'Lấy danh sách mật khẩu của Cổ đông': 'Lấy danh sách mật khẩu của Cổ đông',
  'Xác nhận đã gửi giấy mời cho tất cả cổ đông trong danh sách':
    'Xác nhận đã gửi giấy mời cho tất cả Cổ đông trong danh sách',
  'Mã Cổ đông': 'Mã Cổ đông',
  'Cổ phần': 'Cổ phần',

  //Ban kiểm tra tư cách
  'Checkin thủ công': 'Checkin thủ công',
  'CP nhận uỷ quyền': 'CP nhận uỷ quyền',
  'In tất cả phiếu vote': 'In tất cả phiếu vote',
  'Xử lý checkin thủ công tại điểm họp': 'Xử lý checkin thủ công tại điểm họp',
  'Xử lý uỷ quyền': 'Xử lý uỷ quyền',
  'Đã checkin': 'Đã checkin',
  'Hoàn thành': 'Hoàn thành',
  'In phiếu biểu quyết': 'In phiếu biểu quyết',
  'Thêm người được ủy quyền': 'Thêm người được ủy quyền',
  'Họ tên người được ủy quyền': 'Họ tên người được ủy quyền',
  'Số cổ phần được ủy quyền': 'Số cổ phần được ủy quyền',
  // eslint-disable-next-line no-dupe-keys
  Thêm: 'Thêm',
  Tên: 'Tên',
  'In Phiếu biểu quyết': 'In Phiếu biểu quyết',

  //Quản lý checkin
  'Quản lý checkin': 'Quản lý checkin',
  'Tổng số CĐ': 'Tổng số CĐ',
  'Tổng số CP': 'Tổng số CP',
  'Tỷ lệ': 'Tỷ lệ',
  'CĐ tham dự online': 'CĐ tham dự online',
  'Số lượng CP': 'Số lượng CP',
  'CĐ tham dự trực tiếp': 'CĐ tham dự trực tiếp',
  'CĐ ủy quyền': 'CĐ ủy quyền',
  'Nhập Tên/CMND/CCCD': 'Nhập Tên/CMND/CCCD',
  'Tham dự': 'Tham dự',
  'Loại CĐ': 'Loại CĐ',
  'Trạng thái biểu quyết': 'Trạng thái biểu quyết',
  'Loại hình tham dự': 'Loại hình tham dự',
  'Loại hình CĐ': 'Loại hình CĐ',
  'Người xử lý checkin': 'Người xử lý checkin',
  'Cổ đông chưa biểu quyết': 'Cổ đông chưa biểu quyết',

  //Biên bản kiểm tra tư cách Cổ đông
  'Biên bản kiểm tra tư cách Cổ đông': 'Biên bản kiểm tra tư cách Cổ đông',
  'Biên bản kiểm tra tư cách': 'Biên bản kiểm tra tư cách',
  'Thời gian tạo': 'Thời gian tạo',
  'Xuất biên bản kiểm tra tư cách': 'Xuất biên bản kiểm tra tư cách',

  //Danh sách ủy quyền

  'Upload file danh sách ủy quyền': 'Upload file danh sách ủy quyền',
  'SĐT người được ủy quyền': 'SĐT người được ủy quyền',

  //Cài đặt nội dung ủy quyền
  'Cài đặt nội dung ủy quyền': 'Cài đặt nội dung ủy quyền',
  'Địa chỉ nhận ủy quyền': 'Địa chỉ nhận ủy quyền',
  'Tải lên biểu mẫu ủy quyền': 'Tải lên biểu mẫu ủy quyền',
  'Chưa tải file biểu mẫu': 'Chưa tải file biểu mẫu',

  'thủ tục khai mạc Đại hội': 'thủ tục khai mạc Đại hội',
  'các Báo cáo/ Tờ trình': 'các Báo cáo/ Tờ trình',
  'Biên bản và Nghị quyết Đại hội': 'Biên bản và Nghị quyết Đại hội',
  'Cổ đông chắc chắn muốn gửi biểu quyết này?':
    'Cổ đông chắc chắn muốn gửi biểu quyết này?',
  'Biểu quyết thông qua': 'Biểu quyết thông qua',
  'Link livestream (Tiếng Anh)': 'Link livestream (Tiếng Anh)',
  'Tổng số nội dung tán thành': 'Tổng số nội dung tán thành: ',
  'Tổng số nội dung không tán thành': 'Tổng số nội dung không tán thành: ',
  'Tổng số nội dung không ý kiến': 'Tổng số nội dung không ý kiến: ',
  'Tổng số nội dung chưa biểu quyết/không hợp lệ':
    'Tổng số nội dung chưa biểu quyết/không hợp lệ: ',
  'Xác nhận gửi biểu quyết': 'Xác nhận gửi biểu quyết',

  //Ban kiểm phiếu
  'Kiểm phiếu thủ công': 'Kiểm phiếu thủ công',
  'Kiểm phiếu cho Cổ đông biểu quyết bằng phiếu giấy':
    'Kiểm phiếu cho Cổ đông biểu quyết bằng phiếu giấy',
  'Chưa tham gia': 'Chưa tham gia',
  'Chưa sao chép': 'Chưa sao chép',
  'Đã sao chép': 'Đã sao chép',
  'CP sở hữu': 'CP sở hữu',
  'CP tham dự': 'CP tham dự',
  'Kiểm tra bỏ phiếu': 'Kiểm tra bỏ phiếu',
  'Chức năng BKP': 'Chức năng',
  'Sao chép kết quả': 'Sao chép kết quả',
  'Sao chép kết quả biểu quyết': 'Sao chép kết quả biểu quyết',
  'Họ tên CĐ/Người được ủy quyền': 'Họ tên CĐ/Người được ủy quyền',
  'Phiếu không hợp lệ': 'Phiếu không hợp lệ',
  'Biểu quyết thông qua thủ tục khai mạc Đại hội':
    'Biểu quyết thông qua thủ tục khai mạc Đại hội',
  'Tán thành BKP': 'Tán thành',
  'Không tán thành BKP': 'Không tán thành',
  'Không ý kiến BKP': 'Không ý kiến',
  'Biểu quyết Báo cáo/Tờ trình': 'Biểu quyết Báo cáo/Tờ trình',
  'Bầu cử (Bầu dồn phiếu)': 'Bầu cử (Bầu dồn phiếu)',
  'Tổng số phiếu có thể bầu': 'Tổng số phiếu có thể bầu',
  'Tổng số phiếu còn lại': 'Tổng số phiếu còn lại',
  'Nhập số phiếu bầu': 'Nhập số phiếu bầu',
  'Thông qua Biên bản - Nghị quyết Đại hội':
    'Thông qua Biên bản - Nghị quyết Đại hội',
  'Thực hiện thành công': 'Thực hiện thành công!',

  //Quản lý phiếu biểu quyết
  'Quản lý phiếu biểu quyết': 'Quản lý phiếu biểu quyết',
  'Chưa biểu quyết QLPBQ': 'Chưa biểu quyết',
  'Xem phiếu biểu quyết': 'Xem phiếu biểu quyết',
  'Phiếu biểu quyết của Cổ đông A': 'Phiếu biểu quyết của Cổ đông {{name}}',
  'Không hợp lệ BKP': 'Không hợp lệ',
  'Tổng phiếu được quyền biểu quyết cho tờ trình':
    'Tổng phiếu được quyền biểu quyết cho tờ trình',
  'Kết quả kiểm phiếu theo từng nội dung':
    'Kết quả kiểm phiếu theo từng nội dung',
  Phần: 'Phần',
  'Thông qua thủ tục khai mạc Đại hội': 'Thông qua thủ tục khai mạc Đại hội',
  'Xuất biên bản kiểm phiếu': 'Xuất biên bản kiểm phiếu',
  'Tổng số phiếu biểu quyết': 'Tổng số phiếu biểu quyết',
  'Tổng số phiếu tán thành': 'Tổng số phiếu tán thành',
  'Tổng số phiếu không tán thành': 'Tổng số phiếu không tán thành',
  'Tổng số phiếu không ý kiến': 'Tổng số phiếu không ý kiến',
  'Phiếu bầu': 'Phiếu bầu',

  //Xuất Biên bản - Nghị quyết ĐH
  'Xuất biên bản - Nghị quyết ĐH': 'Xuất Biên bản - Nghị quyết ĐH',
  'Xuất Biên bản Đại hội': 'Xuất Biên bản Đại hội',
  'Xuất Nghị quyết Đại hội': 'Xuất Nghị quyết Đại hội',
  'Quay lại': 'Quay lại',
  'Khai báo biên bản - Nghị quyết': 'Khai báo b.ban - Nghị quyết',
  'Khởi tạo TK khách mời': 'Khởi tạo TK khách mời',
  'Xin chào': 'Xin chào',
  'Tải xuống danh sách mật khẩu của Cổ đông':
    'Tải xuống danh sách mật khẩu của Cổ đông',
  'Sửa thành công': 'Sửa thành công',

  // 'Không được để trống': 'Không được để trống',
  // 'Email không đúng': 'Email không đúng',
  'Không đúng định dạng': 'Không đúng định dạng',
  'Không được chứa khoảng trắng và kí tự có dấu':
    'Không được chứa khoảng trắng và kí tự có dấu',
  'Số điện thoại không đúng định dạng': 'Số điện thoại không đúng định dạng',
  // 'Số fax không đúng': 'Số fax không đúng',
  'Đa ngôn ngữ': 'Đa ngôn ngữ',
};
export const en1 = {
  'Trở về trang chủ': 'Return to homepage',
  'Quay lại': 'Back',
  'Nội dung': 'Content',
  'Trở về': 'Back',
  'Nếu muốn thực hiện biểu quyết tất cả số phiếu bầu, Cổ đông vui lòng thực hiện biểu quyết cho từng Cổ đông trong danh sách nhận uỷ quyền':
    'If you want to vote for all votes, please vote for each shareholder in the authorized list.',
  'Tổng số phiếu bầu còn lại là số phiếu của các Cổ đông uỷ quyền không thoả mãn việc chia đều':
    'The total number of remaining votes is the number of votes of authorized shareholders who do not satisfy the even distribution.',
  'Lựa chọn đăng nhập': 'sign in options',
  'Đăng nhập quyền admin': 'Sign in as administrations',
  'Đăng nhập dành cho đoàn chủ tịch': 'Sign in as BOM',
  'Đăng nhập dành cho ban quan hệ Cổ đông':
    'Login for shareholder relations department',
  'Đăng nhập dành cho ban kiểm tra tư cách Cổ đông':
    'Login for Shareholder eligibility review department',
  'Đăng nhập dành cho ban kiểm phiếu': 'Sign in as vote counting department',
  'Đăng nhập dành cho thư ký': 'Sign in as secretary',
  'Đăng nhập admin': 'Sign in as administrations',
  'Tài khoản': 'Account',
  'Mật khẩu': 'Password',
  'Tiếp tục': 'Next',
  'Nhập OTP': 'Enter OTP',
  'Không nhận được mã OTP?': 'Cannot receive OTP?',
  'Gửi lại OTP': 'Resend OTP',
  'Đăng nhập': 'Sign in as',
  'đăng nhập': 'Log in',
  'đoàn chủ tịch': 'BOM',
  'ban quan hệ Cổ đông': 'shareholder relations department',
  'Ban quan hệ Cổ đông': 'Shareholder relations department',
  'ban kiểm phiếu': 'vote counting department',
  'ban kiểm tra tư cách Cổ đông': 'shareholder verification department',
  'Ban kiểm tra tư cách Cổ đông': 'Shareholder verification department',
  'Hiển thị từ': 'Show from',
  đến: 'to',
  'trong số': 'in',
  'bản ghi': 'records',
  'thư ký': 'secretary',
  'Vui lòng nhập số điện thoại đã cung cấp cho admin':
    'Please enter your phone number',
  'Vui lòng nhập mã chứng khoán của công ty':
    'Please enter the stock symbol code',
  'Đại hội Cổ đông': 'Annual general',
  'thường niên': 'Shareholders meeting in',
  'Tỉ lệ': 'Rate',
  'Tổng số Cổ đông của Công ty': 'Total Shareholders of the Company',
  'Tổng số Cổ phần của Công ty': 'Total Shares of the Company',
  'Nhập tên/CMND/CCCD': 'ID number/Passport',
  'Cmnd/cccd': 'ID number/Citizen number',
  // 'Nội dung ý kiến': 'Comment',
  'Thời gian': 'Time',
  'Số cổ phần': 'Number of Shares',
  // 'Mời phát biểu': 'Invite to speak',
  'Không có dữ liệu': 'No data',
  'Bạn đang mời Cổ đông': 'You are inviting shareholder',
  'phát biểu ý kiến': 'to present a speech',
  'Bạn có chắc chắn với hành động này?': 'Are you sure with this action',
  'Chắc chắn': 'Confirm',
  'Ý kiến Cổ đông': "Shareholder's opinion",
  'Thông qua biên bản': 'Approving the Minutes',
  'Nghị quyết Đại hội': 'Resolution of the General Meeting',
  //Affirmative 452 people, equivalent to 452 shares, accounting for 100.00% of the shares participating in voting
  'Tán thành':
    '{{label}} {{acceptanceNumbers}} people, equivalent to {{acceptanceShares}} shares, accounting for {{acceptanceSharesPercent}}% of the shares participating in voting',
  //Negative 0 people, corresponding to 0 shares, accounted for 0.00% of shares participating in voting
  'Không Tán thành':
    '{{label}} {{notAcceptanceNumbers}} people, equivalent to {{notAcceptanceShares}} shares, accounting for {{notAcceptanceSharesPercent}}% of the total number of voting shares',
  //Abstention 0 people, corresponding to 0 shares, accounting for 0.00% of shares participating in voting
  'Không ý kiến':
    '{{label}} {{noIdeaNumbers}} people, equivalent to {{noIdeaShares}} shares, accounting for {{noIdeaSharesPercent}}% of the total number of voting shares',
  //Valid 452 people, equivalent to 452 shares, accounting for 100.00% of shares participating in voting
  'Hợp lệ':
    'Valid {{validNumbers}} people, equivalent to {{validShares}} shares, accounting for {{validSharesPercent}}% of shares participating in voting',
  //Invalid 0 people, corresponding to 0 shares, accounting for 0.00% of shares participating in voting
  'Không hợp lệ':
    'Invalid {{invalidNumbers}} people, equivalent to {{invalidShares}} shares, accounting for {{invalidSharesPercent}}% of total voting shares',
  'Tổng số Cổ đông tham gia': 'Total number of Shareholders attended',
  'Tổng số cổ phần tham gia': 'Total number of shares attended',
  'Tổng số cổ phần': 'Total number of shares',
  'Cổ đông tham gia online': 'Total number of Shareholders attending online',
  'Cổ đông tham gia trực tiếp':
    'Total number of Shareholders attending directly',
  'Cổ đông ủy quyền tham dự':
    'Total number of Authorized Shareholders attending',
  'Cổ đông đang online': 'Total Shareholders - Guests are online',
  'Thông qua thủ tục': 'Approving the procedures',
  'Khai mạc Đại hội': 'for opening the congress',
  'kết quả kiểm phiếu': 'Vote counting results',
  'Tổng số phiếu hợp lệ là': 'Total valid votes',
  'Tổng số phiếu hợp lệ': 'Total valid votes',
  'Tổng số phiếu không hợp lệ là': 'Total invalid votes',
  'Tổng số phiếu không hợp lệ': 'Total invalid votes',
  phiếu: 'vote',
  Stt: 'No.',
  'Họ và tên': 'Full name',
  'Số phiếu bầu': 'Number of votes',
  'Tỷ lệ %': 'Ratio %',
  'TỶ LỆ THÔNG QUA': 'The ratio of agreement',
  'Không được để trống': 'Can not be empty',
  'Số điện thoại không đúng': 'Invalid phone number',
  'Số fax không đúng': 'Invalid fax number',
  'Email không đúng': 'Invalid email',
  'Thành công': 'Successfully',
  'Mời Cổ đông phát biểu thành công':
    'Successfully invite shareholder to present a speech',
  'Chức năng': 'Action',

  'thủ tục khai mạc Đại hội': 'Opening Ceremony',
  'các Báo cáo/ Tờ trình': 'documents',
  'Biên bản và Nghị quyết Đại hội': 'Minutes and Resolutions',
  'Cổ đông chắc chắn muốn gửi biểu quyết này?':
    'Are you sure to submit these votes?',
  'Biểu quyết thông qua': 'Voting to approve',
  'Tổng số nội dung tán thành': 'Total Affirmative: ',
  'Tổng số nội dung không tán thành': 'Total Nagative: ',
  'Tổng số nội dung không ý kiến': 'Total Abstention: ',
  'Tổng số nội dung chưa biểu quyết/không hợp lệ': 'Total Not vote/Invalid: ',
  //
  //ADMIN
  //

  //đăng nhập
  'Vui lòng lựa chọn quyền đăng nhập': 'Please select login permission',
  'Đăng nhập quyền Admin': 'Login with Admin',
  'Đăng nhập dành cho Đoàn Chủ tịch': 'Login for Presidium',
  'Đăng nhập dành cho ban quan hệ cổ đông':
    'Login for Shareholder relations department',
  'Đăng nhập dành cho ban kiểm tra tư cách cổ đông':
    'Login for Shareholder eligibility review department',
  'Đăng nhập dành cho Ban kiểm phiếu': 'Login for Vote Counting department',
  'Đăng nhập dành cho Ban thư ký': 'Login for Secretariat department',

  'Cổ đông đã hoàn thành việc biểu quyết thông qua thủ tục khai mạc Đại hội':
    'This section was voted',
  'Cổ đông đã hoàn thành biểu quyết báo cáo/tờ trình': 'This section was voted',
  'Cổ đông đã hoàn thành biểu quyết thông qua biên bản/nghị quyết Đại hội':
    'This section was voted',
  //khai báo thông tin cơ bản
  'Khai báo thông tin cơ bản': 'Upload default data',
  'Logo công ty': "Company's logo",
  'Banner công ty': 'Company banner',
  'Tên công ty': 'Company name',
  'Link livestream (Tiếng Anh)': 'Link livestream (English)',
  'Tên công ty (Tiếng Anh)': 'Company name (English)',
  'Số điện thoại': 'Phone number',
  'Số fax': 'Fax number',
  'Mã chứng khoán': 'Stock code',
  'Mã CK': 'Stock code',
  'Mã doanh nghiệp': 'Business Code',
  'Tổng số Cổ đông': 'Total Shareholders',
  'Tổng số Cổ phần': 'Total Shares',
  'Trụ sở công ty': 'Company headquarters',
  'Trụ sở công ty (Tiếng Anh)': 'Company headquarters (English)',
  'Địa điểm điều hành Đại hội': 'Meeting venue',
  'Địa điểm điều hành Đại hội (Tiếng Anh)': 'Meeting venue (English)',
  'Thời gian họp': 'Meeting time',
  'Thời gian họp (Tiếng Anh)': 'Meeting time (English)',
  'Tên cuộc họp': 'Meeting name',
  'Tên cuộc họp (Tiếng Anh)': 'Meeting name (English)',
  'Chủ tọa Đại hội': 'Chairman of the General Meeting',
  'Chủ tọa Đại hội (Tiếng Anh)': 'Chairman of the General Meeting (English)',
  'Thư ký Đại hội': 'Secretary of the General Meeting',
  'Thư ký Đại hội (Tiếng Anh)': 'Secretary of the General Meeting (English)',
  'Ngày chốt quyền': 'Record date',
  'Link video HDSD': 'User manual video link',
  'Link video HDSD (Tiếng Anh)': 'User manual video link (English)',
  'Link phát biểu': 'Link to speak',
  'Tải xuống tất cả phiếu biểu quyết': 'Download all votes',
  Hủy: 'Cancel',
  Lưu: 'Save',
  'Xem kết quả Đại hội': 'Meeting results',
  'Xem kết quả biểu quyết': 'Vote results',
  'Chặn CĐ biểu quyết': 'Block shareholder',
  'Chặn cổ đông biểu quyết': 'Block shareholder from voting',
  'Xóa nội dung': 'Delete',
  'Xóa các nội dung': 'Delete',
  'Đồng ý': 'Confirm',

  //Khai báo thủ tục khai mạc Đại hội
  'Khai báo thủ tục khai mạc Đại hội': 'Upload data for opening ceremony',
  'Khai báo thủ tục khai mạc': 'Upload data for opening',
  'Tên nội dung': 'Content name',
  'Tên nội dung (Tiếng Anh)': 'Content name (English)',
  'Upload file nội dung': 'Upload content files',
  'Upload file nội dung (Tiếng Anh)': 'Upload content file (English)',
  'Tạo nội dung': 'Create content',
  STT: 'No.',
  'Thứ tự hiển thị': 'Order of display',
  'Thứ tự': 'Order',
  'Sửa nội dung': 'Edit content',
  'Hủy bỏ': 'Cancel',
  'Cập nhật': 'Update',
  'Sắp xếp': 'Sort',
  Duyệt: 'Accept',
  Sửa: 'Edit',
  Xoá: 'Delete',

  //Khai báo báo cáo - tờ trình
  'Khai báo báo cáo - tờ trình': 'Upload document',
  'Mặc định': 'Default',
  'Tờ trình': 'Report',
  'Ngoài thời gian bầu cử': 'Voting time is closed',
  'Bầu dồn phiếu': 'Cumulative voting',
  'Số lượng bầu cử': 'Number of elections',
  'Danh sách ứng viên': 'List of candidates',
  'Họ tên': 'Full name',
  'Họ tên Cổ đông': 'Full name',
  'Họ tên (Tiếng Anh)': 'Full name (English)',
  'Số phiếu bầu phải lớn hơn 0': 'Vote amount must be more than 0!',
  Thêm: 'Create',
  Tên: 'Name',
  'Hoàn thành BKP': 'Submit',
  'In Phiếu biểu quyết': 'Print Voting Cards',
  'Nhập mã OTP được gửi tới điện thoại của bạn': 'Enter the OTP',
  'Bạn có chắc chắn muốn hủy chặn CĐ này gửi ý kiến đến Đại hội?':
    'Are you sure you want this Shareholder to submit comments to the General Meeting?',
  'Không thể đánh dấu Không hợp lệ cho phiếu bầu đã được sao chép':
    'Votes were copied',
  'Cổ đông đã bầu cử cho nội dung này': 'Shareholders voted for this content',
  //Khai báo Biên bản - Nghị quyết Đại hội
  'Khai báo Biên bản - Nghị quyết Đại hội': 'Upload data for closing ceremony',
  'Cổ đông bị chặn biểu quyết cho nội dung này':
    'Shareholder is blocked for this content',
  'Khai báo biên bản - Nghị quyết': 'Upload data for closing ceremony',
  'Không thể sao chép phiếu không hợp lệ': 'Cannot copy invalid vote',
  //Khai báo các ban
  'Khai báo các ban': 'Declare the departments',
  'Chọn ban': 'Choose the departments',
  'Đoàn chủ tịch': 'Presidium',
  'Ban quan hệ CĐ': 'Shareholder relations department',
  'Ban kiểm tra tư cách CĐ': 'Shareholder eligibility review department',
  'Ban kiểm phiếu': 'Vote Counting department',
  'Ban thư ký': 'Secretariat department',
  'Vai trò': 'Role',
  'Cổ đông không thể thay đổi biểu quyết đã gửi': 'This content was voted',
  'Trưởng ban': 'Chairman',
  'Họ tên/CMND/CCCD': 'Name, Identity number...',
  'Thành viên': 'Member',
  'Tên đăng nhập': 'User name',
  'Tên đăng nhập/SĐT': 'User name',
  Tạo: 'Create',
  'Ban trực thuộc': 'Sub-divisions',
  'Sửa thông tin': 'Edit information',
  'Ứng viên': 'Candidate',
  'Chưa có dữ liệu': 'No data',

  'Danh sách Cổ đông tham gia biểu quyết hợp lệ':
    'List of Shareholders voted validly',
  'Danh sách Cổ đông tham gia biểu quyết không hợp lệ':
    'List of Shareholders voted invalidly',
  'Danh sách Cổ đông tham gia bầu cử hợp lệ':
    'List of Shareholders voted validly',
  'Danh sách Cổ đông tham gia bầu cử không hợp lệ':
    'List of Shareholders voted invalidly',

  //Danh sách Cổ đông
  'Danh sách Cổ đông': 'List of shareholders',
  'Tải về file mẫu': 'Download the sample file',
  'Tải lên file mẫu': 'Upload sample file',
  'Tải lên danh sách Cổ đông': 'Upload list of shareholders',
  'Tải lên danh sách ủy quyền': 'Upload list of authorization',
  'Tạo danh sách': 'Create a list',
  'Tất cả': 'All',
  'Lựa chọn': 'Select',
  'Trong nước': 'Domestic',
  'Xác nhận gửi biểu quyết': 'Confirm votes',
  'Nước ngoài': 'International',
  // 'Tất cả': 'All',
  'Tổ chức': 'Organization',
  'Cá nhân': 'Individual',
  // 'Mã CĐ': 'ID',
  'Họ và tên CĐ': 'Full name',
  'CMND/CCCD': 'ID number/Passport',
  'Ngày cấp': 'Issued Date',
  'Nơi cấp': 'Issued by',
  'Số lượng Cổ phần': 'Number of shares',
  Email: 'Email',
  'Địa chỉ': 'Address',
  'Sửa Số điện thoại': 'Edit Phone Number',
  'Sửa số điện thoại Cổ đông': "Edit shareholder's phone number",
  'Xoá tất cả': 'Delete all',

  'Loại cổ đông': 'Shareholder types',
  'CP còn lại': 'Remaining shares',
  'CP chờ xử lý': 'Pending shares',
  'CP ủy quyền đã xử lý': 'Processed shares',
  'Tổng số CP đại diện': 'Total Representative Shares',
  'CP nhận ủy quyền': 'Shares received authorization',
  //Quản lý Cổ đông
  'Quản lý Cổ đông': 'Shareholder Management',
  'Đã biểu quyết': 'Voted',
  'Chưa biểu quyết': 'Unvoted',
  'Chưa biểu quyết2': 'Not voted yet',
  'Hình thức': 'Form',
  'Chưa checkin': "Haven't checked in",
  'Ủy quyền': 'Authorization',
  'Trực tiếp': 'Direct',
  'Trực tuyến': 'Online',
  'Cổ đông': 'Shareholder',
  'Nhận ủy quyền': 'Get authorization',
  'Trạng thái hoạt động': 'Operation Status',
  'Không hoạt động': 'Inactive',
  'Đang hoạt động': 'Active',
  'Mã CĐ': 'Shareholder code',
  SDT: 'Phone number',
  'Cổ phần sở hữu': 'Ownership shares',
  'Cổ phần tham dự': 'Participating shares',
  'Xuất file excel': 'Export excel file',
  'Cho phép biểu quyết lại': 'Re-voting allowed',
  'Bạn đang cho phép Cổ đông': 'You are allowing Shareholder ',
  'biểu quyết lại': ' re-vote',
  'Người nhận ủy quyền': 'Authorized Recipient',
  'Cổ đông ủy quyền': 'Authorized Shareholder',

  //Danh sách ủy quyền
  'Đã tải lên': 'Uploaded',
  'Danh sách ủy quyền': 'Authorization List',
  'Chờ xử lý': 'Waiting for progressing',
  'Đã xử lý': 'Processed',
  'Thất bại': 'Failure',
  'Tìm kiếm': 'Search',
  'Người ủy quyền': 'Authorizer',
  'CMND/CCCD người ủy quyền': 'ID number/Passport of the authorized person',
  'Người được ủy quyền': 'Authorized person',
  'CMND/CCCD người được ủy quyền': 'ID number/Passport authorized person',
  'Số CP ủy quyền': 'Authorized Number of shares',
  'File đính kèm': 'Attached files',
  'Trạng thái': 'Status',
  'CMND/CCCD (Tài khoản)': 'ID number/Passport (Account)',
  'Họ và tên người được ủy quyền': 'Full name of authorized person',
  'Số CP được ủy quyền': 'Authorized shares',
  'Đặt lại mật khẩu': 'Reset Password',
  'Tài khoản đã tồn tại': 'Account has already existed!',
  'Họ tên, tài khoảnn': 'Name, account...',
  'Thời gian bắt đầu phải trước thời gian kết thúc và phải sau thời gian hiện tại':
    'Time is not reasonable',
  //Ý kiến cổ đông
  'Ý kiến cổ đông': "Shareholder's opinion",
  'Viết tin nhắn...': 'Type messages ...',
  'Đã gửi ý kiến': 'Posted comments',
  // 'Tất cả': 'All',
  'Đã chuyển chủ tịch': 'Sent to Presidium',
  'Đã chuyển': 'Sent to Presidium',
  'Chọn ý kiến để chuyển đến đoàn Chủ tịch!':
    'Select opinions to send to Presidium!',
  'Chưa chuyển chủ tịch': 'Have not send to Presidium',
  'Chưa chuyển': 'Have not send to Presidium',
  'In danh sách ý kiến được chọn': 'Print a list of selected comments',
  'Xuất file tất cả ý kiến': 'Export all comments',
  'Xuất file ý kiến đã chọn': 'Export selected comments',
  'Bạn có chắc chắn muốn xóa toàn bộ danh sách Cổ đông?':
    'Are you sure to delete list of shareholders?',
  'Tải xuống danh sách mật khẩu của Cổ đông':
    "Download list of Shareholders' passwords",
  'Tạo tài khoản khách mời thành công': 'Created guest account successfully!',
  Chặn: 'Block',
  Chọn: 'Select',
  'Xóa tất cả': 'Delete all',
  'Tạo mới': 'Create',
  'Sửa sdt': 'Edit phone number',
  'Từ chối': 'Reject',
  'Chặn Cổ đông': 'Block Shareholder',
  'Bỏ chặn Cổ đông': 'Unblock Shareholder',
  'Bạn có chắc chắn muốn chặn CĐ này gửi ý kiến đến Đại hội?':
    'Are you sure you want to block this Shareholder from submitting comments to the General Meeting?',
  'Xác nhận': 'Confirm',
  'Bỏ chặn': 'Unblock',
  'Điều này có nghĩa là bạn sẽ đóng chức năng tạo mật khẩu và tải giấy mời cho tất cả Cổ đông':
    'This means you will close creating passwords and sending shareholders invitations functions',
  'Mời phát biểu': 'Invite to speak',
  'Tên Cổ đông': 'Name of shareholder',
  'Nội dung ý kiến': 'Content of comments',
  'Trạng thái chuyển': 'Transition state',
  'Chuyển ý kiến đến đoàn Chủ tịch': 'Send comments to the Presidium',
  //Khởi tạo tài khoản khách mời
  'Khởi tạo TK khách mời': 'Create a guest account',
  'Khởi tạo tài khoản khách mời': 'Create a guest account',
  'Thông tin': 'Information',
  'Sửa thông tin khách mời': 'Edit guest information',
  'Xin chào': 'Hello',

  // 'Không được để trống': 'Cannot be empty',
  // 'Email không đúng': 'Email address is invalid',
  'Không đúng định dạng': 'Data is invalid',
  'Không được chứa khoảng trắng và kí tự có dấu':
    'Cannot contain spaces and accented characters',
  'Số điện thoại không đúng định dạng': 'Phone number is invalid',
  // 'Số fax không đúng': 'Fax is invalid',

  //Thời gian biểu quyết
  'Thời gian biểu quyết': 'Voting time',
  'Cấu hình thời gian biểu quyết': 'Voting time configuration',
  'Cấu hình thời gian diễn ra Đại hội': 'Configure the General Meeting time',
  'Tạo Hotline thành công': 'Created hotline successfully!',
  'Giờ bắt đầu': 'Start time',
  'Giờ kết thúc': 'End time',
  'Ngày bắt đầu': 'Start day',
  'Ngày kết thúc': 'End day',
  Xóa: 'Delete',
  'Đóng/Mở phần thông qua quy chế tổ chức':
    'Close/Open the section through the opening ceremony',
  'Nâng cao': 'Advanced',
  'Tên tờ trình': 'Name of the report',
  'Xử lý': 'Action',
  'Đóng/mở phần thông qua các tờ trình': 'Close/open section through reports',
  'Đóng/mở phần bầu cử (Bầu dồn phiếu)': 'Close/open section Cumulative voting',
  'Đóng/mở phần thông qua Biên bản - Nghị quyết Đại hội':
    'Close/Open the section through the closing ceremony',
  'Đóng/Mở thời gian Cổ đông gửi ý kiến':
    'Close/Open time Shareholders submit comments',
  'Upload file thành công': 'Upload file successfully!',
  'Phải là file excel (xlsx, xls)': 'Must be excel (.xlsx, .xls)',
  'Bạn phải upload danh sách ủy quyền':
    'You must upload the authorization list',
  'Phải là file excel (.xlsx, .xls)': 'Must be excel (.xlsx, .xls)',
  Lỗi: 'Error!',
  'Chọn ý kiến để in': 'No opinions are selected',
  'Lỗi!': 'Error!',
  'Bạn phải upload danh sách Cổ đông':
    'You have to upload list of shareholders!',
  'Đang tạo danh sách. Vui lòng đợi': 'List is being created, please wait!',
  //hotline
  'Hotline hỗ trợ Cổ đông': 'Hotline to support shareholders',
  'Ý kiến đã chuyển đến đoàn Chủ tịch!':
    'These opinions have been send to Presidium yet.',
  'Thực hiện thành công': 'Successful!',
  'Bạn có chắc chắn xóa các nội dung đã chọn?':
    'Are you sure to delete all chosen contents?',
  'Chọn Cổ đông chặn': 'Select sharesholder',
  //Đổi mật khẩu
  'Thay đổi mật khẩu': 'Change the password',
  'Đổi mật khẩu': 'Password',
  'Mật khẩu cũ': 'Old password',
  'Mật khẩu mới': 'New password',
  'Xác nhận mật khẩu mới': 'Confirm new password',
  'Mật khẩu cấp 2': 'Password Level 2',
  'Bật mật khẩu cấp 2': 'Turn on password Level 2',
  'Tắt mật khẩu cấp 2': 'Turn off password Level 2',
  'SĐT nhận OTP': 'OTP receiving phone number',
  'Khi bật mật khẩu cấp 2 lần đầu, hệ thống sẽ yêu cầu admin cung cấp số điện thoại nhận OTP, số điện thoại này sẽ được ấn định nhận OTP cho các lần bật tắt sau':
    'When turning on the level 2 password for the first time, the system will ask the admin to provide a phone number to receive OTP, this phone number will be assigned to receive OTP for the following times.',
  Bật: 'Turn on',
  Tắt: 'Turn off',
  'Đăng xuất': 'Log out',
  'Số thứ tự': 'Order of display',
  'sắp xếp thứ tự hiển thị': 'Sort order of display',
  'Tạo thành viên thành công': 'Create successfully',
  'Đăng nhập thất bại': 'Login failed!',
  'Email không hợp lệ': 'Invalid email!',
  //Ban quan hệ Cổ đông
  'Quản lý thư mời': 'Manage invitations letter',
  'Loại khỏi danh sách cổ đông nội bộ':
    'Remove from the list of internal shareholders',
  'Hiển thị tất cả Cổ đông': 'Show all Shareholders',
  'Import danh sách Cổ đông nội bộ': 'Import the list of Internal shareholders',
  'Tải file mẫu': 'Download sample files',
  Lọc: 'Filter',
  'Xuất file ý kiến thành công!': 'Export list of opinions successfully!',
  'In danh sách địa chỉ theo mã Cổ đông':
    'Print address list by Shareholder code',
  'Tải danh sách địa chỉ theo mã Cổ đông':
    'Download address list by Shareholder code',
  'Lấy danh sách mật khẩu của Cổ đông': "Get a list of Shareholders' passwords",
  'Xác nhận đã gửi giấy mời cho tất cả cổ đông trong danh sách':
    'Confirmation of sending invitations letter to all shareholders on the list',
  'Mã Cổ đông': 'ID',
  'Cổ phần': 'Share',

  //Ban kiểm tra tư cách
  'Checkin thủ công': 'Manual checkin',
  'CP nhận uỷ quyền': 'Authorized shares',
  'In tất cả phiếu vote': 'Print all votes',
  'Xử lý checkin thủ công tại điểm họp': 'Manual check-in at the meeting point',
  'Xử lý uỷ quyền': 'Authorization handling',
  'Đã checkin': 'Checked in',
  'Hoàn thành': 'Complete',
  'In phiếu biểu quyết': 'Print votes',
  'Tải xuống chi tiết phiếu biểu quyết': 'Download detail vote result',
  'Thêm người được ủy quyền': 'Add authorized person',
  'Họ tên người được ủy quyền': 'Full name of authorized person',
  'Số cổ phần được ủy quyền': 'Number of authorized shares',

  // eslint-disable-next-line no-dupe-keys
  Thêm: 'More',
  'còn lại': 'remaining',

  //Quản lý checkin
  'Quản lý checkin': 'Check-in management',
  'Tổng số CĐ': 'Total Shareholders',
  'Tổng số CP': 'Total Shares',
  'Tỷ lệ': 'Ratio',
  'CĐ tham dự online': 'Shareholders participates online',
  'Số lượng CP': 'Number of shares',
  'CĐ tham dự trực tiếp': 'Shareholders attended directly',
  'CĐ ủy quyền': 'Authorized Shareholders',
  'Nhập Tên/CMND/CCCD': 'Enter Name/ID number/Passport',
  'Tham dự': 'Attend',
  'Loại CĐ': 'Type of shareholder',
  'Trạng thái biểu quyết': 'Voting Status',
  'Loại hình tham dự': 'Type of participation',
  'Loại hình CĐ': 'Type of Shareholders',
  'Người xử lý checkin': 'Checkin handler',
  'Chưa chặn': 'Have not blocked',
  'Đã chặn': 'Blocked',
  Phần: 'Part',
  'Cổ đông chưa biểu quyết': 'Shareholder has not voted!',

  //Biên bản kiểm tra tư cách Cổ đông
  'Biên bản kiểm tra tư cách Cổ đông': 'Minutes of checking shareholder status',
  'Biên bản kiểm tra tư cách': 'Record of eligibility check',
  'Thời gian tạo': 'Creation time',
  'Xuất biên bản kiểm tra tư cách': 'Exporting a record of eligibility check',

  //Danh sách ủy quyền
  'Từ chối uỷ quyền': 'Refusing authorization',
  'Xác nhận uỷ quyền': 'Authorization Confirmation',
  'Sửa thông tin người được ủy quyền': 'Edit authorized person information',
  'Quản lý ủy quyền': 'Authorization management',
  'Địa chỉ nhận giấy ủy quyền': 'Address',
  'Bạn đang xác nhận ủy quyền biểu quyết của':
    'You are confirming the voting authorization of',
  'Bạn đang từ chối ủy quyền biểu quyết của':
    'You are denying the voting authorization of',
  'Upload file danh sách ủy quyền': 'Upload file of authorization list',
  'SĐT người được ủy quyền': 'Phone number of authorized person',

  //Cài đặt nội dung ủy quyền
  'Cài đặt nội dung ủy quyền': 'Authorization content settings',
  'Địa chỉ nhận ủy quyền': 'Address',
  'Tải lên biểu mẫu ủy quyền': 'Upload authorization form',
  'Chưa tải file biểu mẫu': 'The form file has not been downloaded yet',

  //Ban kiểm phiếu
  'Kiểm phiếu thủ công': 'Manual counting of votes',
  'Sửa thành công': 'Edit successfully',
  'Kiểm phiếu cho Cổ đông biểu quyết bằng phiếu giấy':
    'Counting votes for Shareholders voting by paper ballots',
  'Chưa tham gia': 'Not participate',
  'Chưa sao chép': 'Not copied yet',
  'Đã sao chép': 'Copied',
  'Đã ủy quyền': 'Authorized',
  'CP sở hữu': 'Ownership shares',
  'CP tham dự': 'Participating shares',
  'Kiểm tra bỏ phiếu': 'Check Voting',
  'Chức năng BKP': 'Function',
  'Sao chép kết quả': 'Copy results',
  'Sao chép kết quả biểu quyết': 'Copy voting results',
  'Họ tên CĐ/Người được ủy quyền': 'Name of Shareholder/ Authoried person',
  'Phiếu không hợp lệ': 'Vote letter is not valid',
  'Biểu quyết thông qua thủ tục khai mạc Đại hội':
    'Voting to approve the procedure for opening the General Meeting',
  'Tán thành BKP': 'Affirmative votes',
  'Không tán thành BKP': 'Negative votes',
  'Không ý kiến BKP': 'Abstention',
  'Biểu quyết Báo cáo/Tờ trình': 'Voting Report/Delivery',
  'Bầu cử (Bầu dồn phiếu)': 'Election (Cumulative voting)',
  'Tổng số phiếu có thể bầu': 'Total number of possible votes',
  'Tổng số phiếu còn lại': 'Total number of votes left',
  'Nhập số phiếu bầu': 'Enter the number of votes',
  'Chọn Cổ đông được ủy quyền': 'Select a shareholder authorized',
  'Thông qua Biên bản - Nghị quyết Đại hội':
    'Approving the Minutes - Resolution of the General Meeting',
  'Đang lấy kết quả phiếu biểu quyết. Vui lòng đợi':
    'System is getting vote result, please wait!',
  'Cổ đông đã bầu quá số phiếu sở hữu!': 'Over the number of shares available!',

  //Quản lý phiếu biểu quyết
  'Quản lý phiếu biểu quyết': 'Management of votes',
  'Chưa biểu quyết QLPBQ': 'Not voted yet',
  'Xem phiếu biểu quyết': 'View votes',
  'Phiếu biểu quyết của Cổ đông A': "Shareholder {{name}}'s vote",
  'Không hợp lệ BKP': 'Illegal',
  'Tổng phiếu được quyền biểu quyết cho tờ trình':
    'Total votes entitled to vote on the proposal',
  'Kết quả phiếu theo từng nội dung': 'Vote counting results by content',
  'Kết quả kiểm phiếu theo từng nội dung': 'Vote counting results by content',
  'Kết quả kiểm phiếu': 'Vote counting results',
  'Không thể xóa Admin tổng': 'Cannot delete main admin',
  'Thông qua thủ tục khai mạc Đại hội':
    'Approving the procedure for opening the General Assembly',
  'Xuất biên bản kiểm phiếu': 'Export vote counting minutes',
  'Tổng số phiếu biểu quyết': 'Total number of votes',
  'Tổng số phiếu tán thành': 'Total Affirmative votes',
  'Tổng số phiếu không tán thành': 'Total Negative votes',
  'Tổng số phiếu không ý kiến': 'Total Abstention',
  'Phiếu bầu': 'Votes',

  //Xuất Biên bản - Nghị quyết ĐH
  'Xuất biên bản - Nghị quyết ĐH':
    'Issue the Minutes - Resolution of the General Meeting',
  'Xuất Biên bản Đại hội': 'Issue the Minutes of the General Meeting',
  'Xuất Nghị quyết Đại hội': 'Issue the Resolution of the General Meeting',
  'Đã đóng biểu quyết': 'Voting is close',
  'Thiết lập danh sách Cổ đông bị chặn biểu quyết':
    'Set up list of Shareholders blocked from voting',
  'Tiện ích cho Doanh nghiệp': 'Utilities for Business',
  'Lợi ích cho Cổ đông': 'Benefits for Shareholders',
  'Thông báo': 'Notice!',
  'Đang xử lý, vui lòng đợi': 'Please wait!',
  // 'Bạn phải upload danh sách ủy quyền':
  //   'You have to upload list of authorizations',
  'Phải là file pdf': 'Must be pdf',
  'Không thể ủy quyền cho cổ đông đã biểu quyết':
    'Cannot authorize for shareholder who voted',
  'Số cổ phần ủy quyền phải lớn hơn 0': 'Sharesnumber must be more than 0',
  'Tổng số cổ phần ủy quyền không được lớn hơn số cổ phần khả dụng':
    'Not enough shares to authorize',
  'Không thể ủy quyền chính mình': 'Cannot authorize for yourself',
  'Không thể ủy quyền cho Cổ đông bị chặn ở 1 trong các tờ trình biểu quyết':
    'Cannot authorize for shareholder who is blocked from some content',
  'Số cổ phần ủy quyền không được là số lẻ': 'Share number must be integer',
  'Xuất danh sách ủy quyền thành công':
    'Export authorization list successfully',
  'Đa ngôn ngữ': 'Multi language',
  'Tài liệu': 'Document',
  'tài liệu': 'Document',
  'trực tuyến': 'Online',
};
