import inputIcons from '../../../bases/shared/icons/input';
import Modal from '../../../bases/shared/modal';
import CommunicateButton from '../../../bases/shared/communicateButton';
import Chatbox from './chat';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../../../components/bases/shared/pagination';
import Button from '../../../../components/bases/shared/button';
import Input from '../../../../components/bases/shared/input';
import Table from '../../../bases/shared/table';
import React, { useState, useEffect } from 'react';
import { SModalBlock } from '../../../../styles/styled/admin/shareholderOpinionPage';
import PNotify from 'pnotify/dist/es/PNotify';
import {
  blockOpinionApi,
  getOpinionApi,
  setChooseList,
  sendBossOpinionApi,
  setFilter,
  setToggleModal,
  inviteSpeakApi,
  exportOpinionListApi,
  setLockShareholer,
} from '@duck/pages/admin/shareholderOpinionReducer/actions';
import { findIndex } from '@configs/findIndex';
import XLSX from 'xlsx';
import { ExportSheet } from 'react-xlsx-sheet';
import { transformNumberPrinter } from '@configs/transformNumber';
import { useTranslation } from 'react-i18next';

export const TableList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [modalType, setModalType] = useState('lock'); //lock unlock
  const [showChat, setShowChat] = useState(false);
  const [chatConversation, setChatConversation] = useState(null);
  const [firstTime, setFirstTime] = useState(true);
  const theadsSendOpinion = [
    { name: '#', colspan: 1 },
    { name: '', colspan: 1 },
    { name: '', colspan: 1 },
    { name: '', colspan: 1 },
    { name: 'Mã cổ đông', colSpan: 1 },
    { name: 'Tên Cổ đông', colspan: 1 },
    { name: 'CMND/CCCD', colspan: 1 },
    { name: 'Nội dung ý kiến', colspan: 1 },
    { name: 'Thời gian', colspan: 1 },
    { name: 'Số cổ phần', colspan: 1 },
    { name: 'Trạng thái chuyển', colspan: 1 },
  ];
  const theadsSendOpinionDup = [
    { name: 'STT', colspan: 1 },
    { name: 'Mã cổ đông', colSpan: 1 },
    { name: 'Tên Cổ đông', colspan: 1 },
    { name: 'CMND/CCCD', colspan: 1 },
    { name: 'Nội dung ý kiến', colspan: 1 },
    { name: 'Thời gian', colspan: 1 },
    { name: 'Số Cổ phần', colspan: 1 },
    // {name: 'Trạng thái chuyển', colspan: 1},
  ];
  const [selectAll, setSelectAll] = useState(false);

  const theadsAll = [
    { name: '', colspan: 1 },
    { name: 'Stt', colspan: 1 },
    { name: 'Mã cổ đông', colSpan: 1 },
    { name: 'Tên Cổ đông', colspan: 1 },
    { name: 'CMND/CCCD', colspan: 1 },
    { name: 'Số điện thoại', colspan: 1 },
    { name: 'Số cổ phần', colspan: 1 },
  ];

  const { tableList, total, toggleModal, filter, lockShareholder } =
    useSelector((state) => state.admin.shareholderOpinion);
  const listChose = useSelector(
    (state) => state.admin.shareholderOpinion.listTopicChoose,
  );

  const choseCheckBox = (item) => {
    var newListChose = [...listChose];
    const index = findIndex(item, newListChose);
    if (index < 0) {
      newListChose.push(item);
    } else {
      newListChose.splice(index, 1);
    }
    dispatch(setChooseList(newListChose));
  };
  const setPage = (pageNumber) => {
    dispatch(setFilter({ ...filter, pageNumber }));
  };
  const setPageSize = (pageSize) => {
    dispatch(setFilter({ ...filter, pageSize, pageNumber: 1 }));
  };
  const setToggleModalCustom = (value) => {
    dispatch(setToggleModal(value));
  };

  const getShareholderId = (item) => {
    if (
      filter.typeList === 'opinion' &&
      item &&
      item.data &&
      item.data.shareholder
    ) {
      return item.data.shareholder._id;
    } else return item._id;
  };

  const setBlockOpinion = () => {
    if (modalType === 'lock') {
      dispatch(
        blockOpinionApi(
          {
            isBlockOpinion: true,
            shareholderId: getShareholderId(lockShareholder),
          },
          filter,
        ),
      );
    } else if (modalType === 'unlock') {
      dispatch(
        blockOpinionApi(
          {
            isBlockOpinion: false,
            shareholderId: getShareholderId(lockShareholder),
          },
          filter,
        ),
      );
    }
  };

  const exportFile = () => {
    dispatch(exportOpinionListApi());
  };

  useEffect(() => {
    if (firstTime) {
      setFirstTime(false);
      dispatch(setChooseList([]));
      dispatch(
        setFilter({
          identityNumber: '',
          status: 'all',
          pageSize: 10,
          pageNumber: 1,
          typeList: 'opinion',
        }),
      );
    }
  }, [firstTime, dispatch]);

  useEffect(() => {
    let isSelectAll = true;
    let isChose = false;
    for (let i in tableList) {
      isChose = false;
      for (let j in listChose) {
        if (tableList[i]._id === listChose[j]._id) {
          isChose = true;
          continue;
        }
      }
      if (isChose === false) {
        setSelectAll(false);
        isSelectAll = false;
        break;
      }
    }
    if (isSelectAll) setSelectAll(true);
  }, [listChose, tableList]);
  const onClickSelectAll = () => {
    if (selectAll) {
      //delete All
      setSelectAll(false);
      const tmp = [...listChose];
      for (let i in tableList) {
        for (let j in tmp) {
          if (tableList[i]._id === tmp[j]._id) {
            tmp.splice(j, 1);
            break;
          }
        }
      }
      dispatch(setChooseList(tmp));
    } else {
      //select All
      setSelectAll(true);
      const tmp = [...listChose];
      for (let i in tableList) {
        let isChose = false;
        for (let j in listChose) {
          if (tableList[i]._id === listChose[j]._id) {
            isChose = true;
            break;
          }
        }
        if (!isChose) tmp.push(tableList[i]);
      }
      dispatch(setChooseList(tmp));
    }
  };
  useEffect(() => {
    if (!firstTime) {
      // dispatch(setChooseList([]));
      dispatch(getOpinionApi(filter));
    }
  }, [dispatch, filter, firstTime]);

  return (
    <>
      <div className="table-container">
        {filter.typeList !== 'all' && (
          <div className="handle-wrapper">
            <div className="list-handle">
              {tableList.length > 0 && (
                <Input.HandleButton
                  select={selectAll}
                  type="select"
                  action={onClickSelectAll}
                  className="btn__hand"
                />
              )}
            </div>
            <div className="list-export">
              <Button
                onClick={() => {
                  // tableToExcel("print-content", "danhsachykien")
                  if (listChose.length === 0) {
                    PNotify.error({
                      title: t('Lỗi'),
                      text: t('Chọn ý kiến để in'),
                      destroy: true,
                      delay: 2000,
                    });
                  } else
                    setTimeout(() => {
                      var w = window.open();
                      w.document.write(
                        document.getElementById('print-content').innerHTML,
                      );
                      w.print();
                      w.close();
                    }, 100);
                }}
              >
                {inputIcons.print}
                {t('In danh sách ý kiến được chọn')}
              </Button>
              {listChose.length === 0 ? (
                <Button onClick={exportFile}>
                  {inputIcons.export}
                  {t('Xuất file tất cả ý kiến')}
                </Button>
              ) : (
                <ExportSheet
                  dataType="Table-Node-Element"
                  fileName={`DS_YkienCD`}
                  tableElement={document.querySelector('#print-content-table')}
                  xlsx={XLSX}
                >
                  <Button
                    onClick={() => {
                      PNotify.success({
                        title: t('Thành công'),
                        text: t('Xuất file ý kiến thành công!'),
                        destroy: true,
                        delay: 2000,
                      });
                      dispatch(setChooseList([]));
                    }}
                  >
                    {inputIcons.export}
                    {t('Xuất file ý kiến đã chọn')}
                  </Button>
                </ExportSheet>
              )}
              <Button
                onClick={() => {
                  const opinionIds = [];
                  //check if list opinions are all send boss
                  let availableToSendBoss = false;
                  listChose.map((item) => {
                    if (item.data && item.data.sendBoss === false) {
                      //just push opinions not sent
                      opinionIds.push(item._id);
                      availableToSendBoss = true;
                    }
                    return null;
                  });
                  if (listChose.length > 0) {
                    if (availableToSendBoss)
                      dispatch(sendBossOpinionApi({ opinionIds }, filter));
                    else
                      PNotify.error({
                        title: t('Lỗi'),
                        text: t('Ý kiến đã chuyển đến đoàn Chủ tịch!'),
                        destroy: true,
                        delay: 2000,
                      });
                  } else
                    PNotify.error({
                      title: t('Lỗi'),
                      text: t('Chọn ý kiến để chuyển đến đoàn Chủ tịch!'),
                      destroy: true,
                      delay: 2000,
                    });
                }}
              >
                {inputIcons.transfer} {t('Chuyển ý kiến đến đoàn Chủ tịch')}
              </Button>
            </div>
          </div>
        )}
        <div className="table-wrapper">
          <Table
            header={filter.typeList === 'all' ? theadsAll : theadsSendOpinion}
          >
            {tableList.length > 0 ? (
              <>
                {tableList.map((item, index) => {
                  if (filter.typeList === 'all')
                    return (
                      <tr onClick={() => choseCheckBox(item)}>
                        <td className="center">
                          <CommunicateButton
                            type="mic"
                            action={(event) => {
                              event.stopPropagation();
                              dispatch(
                                inviteSpeakApi({
                                  shareholderId: getShareholderId(item),
                                }),
                              );
                            }}
                          />
                        </td>
                        <td> {index + 1} </td>
                        <td> {item.code}</td>
                        <td> {item.name} </td>
                        <td> {item.identityNumber} </td>
                        <td> {item.phone} </td>
                        <td>
                          {item.sharesNumber === 0
                            ? transformNumberPrinter(
                                item.sharesJoinNumber +
                                  item.sharesAuthorizedNumber,
                              )
                            : transformNumberPrinter(item.sharesNumber)}
                          {/* {item.isCheckedIn
                            ? item.sharesNumber === 0
                              ? transformNumberPrinter(
                                  item.sharesJoinNumber +
                                    item.sharesAuthorizedNumber,
                                )
                              : transformNumberPrinter(item.sharesJoinNumber)
                            : 0} */}
                        </td>
                      </tr>
                    );
                  else
                    return (
                      <tr onClick={() => choseCheckBox(item)}>
                        <td className="center">
                          <Input.Checkbox
                            checked={
                              findIndex(item, listChose) >= 0 ? true : false
                            }
                          />
                        </td>
                        <td className="center">
                          {item.data && item.data.isBlockOpinion ? (
                            <CommunicateButton
                              className="lock"
                              type="unlock"
                              action={(event) => {
                                event.stopPropagation();
                                dispatch(setLockShareholer(item));
                                setModalType('unlock');
                                dispatch(setToggleModal(true));
                              }}
                            />
                          ) : (
                            <CommunicateButton
                              className="lock"
                              type="lock"
                              action={(event) => {
                                event.stopPropagation();
                                dispatch(setLockShareholer(item));
                                setModalType('lock');
                                dispatch(setToggleModal(true));
                              }}
                            />
                          )}
                        </td>
                        <td className="center">
                          <CommunicateButton
                            type="mic"
                            action={(event) => {
                              event.stopPropagation();
                              dispatch(
                                inviteSpeakApi({
                                  shareholderId: getShareholderId(item),
                                }),
                              );
                            }}
                          />
                        </td>
                        <td className="center">
                          <CommunicateButton
                            type="chat"
                            action={(event) => {
                              setShowChat(true);
                              event.stopPropagation();
                              setChatConversation(item);
                            }}
                          />
                        </td>
                        <td>
                          {item.data && item.data.shareholder
                            ? item.data.shareholder.code
                            : ''}{' '}
                        </td>
                        <td>
                          {item.data && item.data.shareholder
                            ? item.data.shareholder.name
                            : ''}{' '}
                        </td>
                        <td>
                          {item.data && item.data.shareholder
                            ? item.data.shareholder.identityNumber
                            : ''}{' '}
                        </td>
                        <td className="left">
                          {item.data ? item.data.opinion : ''}{' '}
                        </td>
                        <td>
                          {item.data
                            ? `${new Date(
                                item.createdAt,
                              ).toLocaleDateString()} ${new Date(
                                item.createdAt,
                              ).toLocaleTimeString('it-IT')}`
                            : ''}{' '}
                        </td>
                        <td>
                          {item.data && item.data.shareholder
                            ? item.data.shareholder.sharesNumber === 0
                              ? transformNumberPrinter(
                                  item.data.shareholder.sharesJoinNumber +
                                    item.data.shareholder
                                      .sharesAuthorizedNumber,
                                )
                              : transformNumberPrinter(
                                  item.data.shareholder.sharesJoinNumber,
                                )
                            : 0}
                          {/* {item.data.shareholder.sharesAuthorizedNumber} */}
                        </td>
                        <td>
                          {item.data && item.data.sendBoss
                            ? t('Đã chuyển')
                            : t('Chưa chuyển')}{' '}
                        </td>
                      </tr>
                    );
                })}
                {/* <tr>
                <td colSpan={100}>
                  <Pagination
                    handleChangePageSize={(size) => {
                      setPageSize(size);
                    }}
                    current={filter.pageNumber}
                    total={total}
                    pageSize={filter.pageSize}
                    onChange={(page) => {
                      setPage(page);
                    }}
                  />
                </td>
              </tr> */}
              </>
            ) : (
              <tr>
                <td colSpan={100} className="center">
                  {' '}
                  {t('Chưa có dữ liệu')}{' '}
                </td>
              </tr>
            )}
          </Table>
          {tableList.length > 0 && (
            <Pagination
              handleChangePageSize={(size) => {
                setPageSize(size);
              }}
              current={filter.pageNumber}
              total={total}
              pageSize={filter.pageSize}
              onChange={(page) => {
                setPage(page);
              }}
            />
          )}
        </div>
      </div>
      <Modal
        isOpen={toggleModal}
        toggleModal={setToggleModalCustom}
        title={modalType === 'lock' ? t('Chặn Cổ đông') : t('Bỏ chặn Cổ đông')}
        overwriteModalStyle={{
          maxWidth: '721px',
        }}
      >
        <SModalBlock>
          <img
            src={
              modalType === 'lock'
                ? '../images/lock.png'
                : '../images/unlock.png'
            }
            alt="delete"
            width={200}
            height={200}
          />
          <p>
            {modalType === 'lock'
              ? t('Bạn có chắc chắn muốn chặn CĐ này gửi ý kiến đến Đại hội?')
              : t(
                  'Bạn có chắc chắn muốn hủy chặn CĐ này gửi ý kiến đến Đại hội?',
                )}
          </p>
          <div className="group-button">
            <Button
              className="cancel-button"
              onClick={() => {
                setToggleModalCustom(false);
              }}
            >
              {t('Hủy bỏ')}
            </Button>
            <Button
              className="delete-button"
              onClick={() => {
                setBlockOpinion();
              }}
            >
              {t('Xác nhận')}
            </Button>
          </div>
        </SModalBlock>
      </Modal>
      <div
        // className="checkin-list"
        id="print-content"
        style={{ display: 'none' }}
      >
        <div className="table" id="print">
          <style
            dangerouslySetInnerHTML={{
              __html:
                '\n                  #print table{width: 100%;    border-spacing: 0; border-collapse: collapse;}  #print th,#print td, #print table{border: 1px solid #000;text-align: center;}#print {font-family: times} table { page-break-inside:auto } tr{ page-break-inside:avoid; page-break-after:auto }  #print th,#print td{padding: 5px 10px;}#print th {white-space: no-wrap}\n',
            }}
          />
          {listChose.length > 0 && listChose[0].data ? (
            <Table header={theadsSendOpinionDup}>
              <>
                {listChose.map((item, index) => {
                  return (
                    <tr>
                      <td className="center">{index + 1}</td>
                      <td>{item.data ? item.data.shareholder.code : ''} </td>
                      <td>{item.data ? item.data.shareholder.name : ''} </td>
                      <td>
                        {item.data ? item.data.shareholder.identityNumber : ''}{' '}
                      </td>
                      <td className="left">
                        {item.data ? item.data.opinion : ''}{' '}
                      </td>
                      <td>
                        {item.data
                          ? `${new Date(
                              item.createdAt,
                            ).toLocaleDateString()} ${new Date(
                              item.createdAt,
                            ).toLocaleTimeString('it-IT')}`
                          : ''}{' '}
                      </td>
                      <td>
                        {item.data.shareholder.sharesAuthorizedNumber > 0
                          ? item.data.shareholder.sharesAuthorizedNumber
                          : item.data.shareholder.sharesNumber}{' '}
                        {/* {item.data
                          ? item.data.shareholder.sharesAuthorizedNumber
                          : ''}{' '} */}
                      </td>
                      {/* <td>
                        {item.data && item.data.sendBoss
                          ? 'Đã chuyển'
                          : 'Chưa chuyển'}{' '}
                      </td> */}
                    </tr>
                  );
                })}
              </>
            </Table>
          ) : (
            ''
          )}
        </div>
      </div>
      <div
        className="export-excel"
        id="print-content2"
        style={{ display: 'none' }}
      >
        {/* <style
          dangerouslySetInnerHTML={{
            __html:
              //d
              '\n                   #print table{width: 100%;    border-spacing: 0; border-collapse: collapse;}  #print th,#print td, #print table{border: 1px solid #000;text-align: center;} table { page-break-inside:auto } tr{ page-break-inside:avoid; page-break-after:auto }  #print {font-family: times}#print th,#print td{padding: 5px 10px;}#print th {white-space: no-wrap}\n',
          }}
        /> */}
        <div className="table" id="print">
          <table id="print-content-table">
            <thead>
              <tr>
                <th>Stt</th>
                <td>Mã cổ đông</td>
                <th>Tên Cổ đông</th>
                <th>CMND</th>
                <th>Nội dung ý kiến</th>
                <th>Thời gian</th>
                <th>Cổ phần</th>
                {/* <th>Trạng thái chuyển</th> */}
              </tr>
            </thead>
            <tbody>
              {listChose.map((item, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>
                      &#8203;{item.data ? item.data.shareholder.code : ''}{' '}
                    </td>
                    <td>
                      &#8203;{item.data ? item.data.shareholder.name : ''}{' '}
                    </td>
                    <td>
                      &#8203;
                      {item.data
                        ? `${item.data.shareholder.identityNumber.toString()}`
                        : ''}{' '}
                    </td>
                    <td>&#8203;{item.data ? item.data.opinion : ''} </td>
                    <td>
                      {new Date(item.createdAt).toLocaleDateString()}
                      &#8203;{' '}
                      {new Date(item.createdAt).toLocaleTimeString('it-IT')}
                    </td>
                    <td>
                      &#8203;
                      {/* {item.data
                        ? item.data.shareholder.sharesAuthorizedNumber
                        : ''}{' '} */}
                      {item.data.shareholder.sharesAuthorizedNumber > 0
                        ? item.data.shareholder.sharesAuthorizedNumber
                        : item.data.shareholder.sharesNumber}{' '}
                    </td>
                    {/* <td>
                      {item.data && item.data.sendBoss
                        ? 'Đã chuyển'
                        : 'Chưa chuyển'}{' '}
                    </td> */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {showChat === true && (
        <Chatbox chat={chatConversation} onClose={() => setShowChat(false)} />
      )}
    </>
  );
};
