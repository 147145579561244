import React from 'react';
import { SManageShareholderPage } from '../../../../styles/styled/admin/manageShareholderPage';
import Form from './form';
import TableContent from './table';
import TableTools from './tableTools';
const ManageShareholderRepresentPage = () => {
  return (
    <SManageShareholderPage>
      <Form />
      {/* <TableTools /> */}
      <TableContent />
    </SManageShareholderPage>
  );
};
export default ManageShareholderRepresentPage;
