import ErrorMessage from '@components/input/ErrorMessage';
import { VALIDATE_REQUIRE } from '@constants/validate';
import {
  getInfoSettingAuthorityApi,
  settingAuthorityApi,
} from '@duck/pages/admin/authorizationInfomationReducer/actions';
import axios from 'axios';
import Button from 'components/bases/shared/button';
import DropFile from 'components/bases/shared/dropzone/dropFile';
import inputIcons from 'components/bases/shared/icons/input';
import Input from 'components/bases/shared/input';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { StylesContent } from 'styles/styled/admin/authorizationListPage/authorizationInformation';

var _validFileExtensions = ['.pdf', '.xlsx', '.xls', '.doc', '.docx'];

function Validate(e) {
  var sFileName = e.target.value;
  if (sFileName.length > 0) {
    var blnValid = false;
    for (var j = 0; j < _validFileExtensions.length; j++) {
      var sCurExtension = _validFileExtensions[j];
      if (
        sFileName
          .substr(sFileName.length - sCurExtension.length, sCurExtension.length)
          .toLowerCase() === sCurExtension.toLowerCase()
      ) {
        blnValid = true;
        break;
      }
    }
    return blnValid;
  }
}

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

const getPDF = (url, func, id, name) => {
  return axios({
    method: 'get',
    url: url,
    responseType: 'blob',
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
    crossdomain: true,
  }).then(function (response) {
    var reader = new FileReader();
    reader.readAsDataURL(response.data);
    reader.onloadend = function () {
      var file = dataURLtoFile(reader.result, name);
      func(file);
      setTimeout(() => {
        if (document.getElementById(id) !== null) {
          document.getElementById(id).setAttribute('src', reader.result);
        }
      }, 100);
    };
  });
};

const TabContentSettings = () => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    setValue,
    control,
    setError,
    watch,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [file2, setFile2] = useState(null);

  const { info } = useSelector((state) => state.admin.authorizationInfoList);

  useEffect(() => {
    register('topic', { required: t(VALIDATE_REQUIRE) });
    register('topic_2', { required: t(VALIDATE_REQUIRE) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register, setValue]);

  // const handleChooseFile = (e) => (name) => {
  //   if (e.target.files.length > 0) {
  //     const file = e.target.files[0];
  //     name === 'topic' ? setFile(file.name) : setFile2(file.name);
  //     setValue(name, file);
  //   }
  // };

  // const [listFile, setListFile] = useState('');
  const [submit, setSubmit] = useState(false);
  // const [uploadActive, setUploadActive] = useState(false);

  // const readURL = (input, ID) => {
  //   if (input.files && input.files[0]) {
  //     var reader = new FileReader();
  //     reader.onload = function (event) {
  //       if (document.getElementById(ID)) {
  //         document.getElementById(ID).setAttribute('src', event.target.result);
  //       }
  //     };
  //     reader.readAsDataURL(input.files[0]);
  //   }
  // };

  const handleChooseFile = (e) => (name) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      name === 'topic' ? setFile(file.name) : setFile2(file.name);
      setValue(name, file);
    }
  };

  // useEffect(() => {
  //   dispatch(getInfoSettingAuthorityApi());
  // }, [dispatch]);

  // const isFile = (event) => {
  //   if (Validate(event)) {
  //     var files = event.target.files[0];
  //     readURL(event.target, 'blah1');
  //     setListFile(files);
  //     setUploadActive(true);
  //     PNotify.success({
  //       title: t('Thành công'),
  //       text: t('Upload file thành công'),
  //       destroy: true,
  //       delay: 2000,
  //     });
  //   } else {
  //     PNotify.error({
  //       title: t('Lỗi'),
  //       text: t('Phải là file pdf'),
  //       destroy: true,
  //       delay: 2000,
  //     });
  //   }
  // };

  useEffect(() => {
    dispatch(getInfoSettingAuthorityApi());
  }, [dispatch]);

  useEffect(() => {
    console.log('info', info);

    if (info?.address) {
      // const viTime = info?.time ? new Date(info?.time) : '';
      // const enTime = info?.time ? new Date(info?.time_en) : '';
      setValue('address', info.address);
      setValue('address_en', info.address_en);
      setValue('time', info?.time);
      setValue('time_en', info?.time_en);
      setValue('topic', info.fileUrl);
      setValue('topic_2', info.fileUrl2);
      setFile(info.fileUrl);
      setFile2(info.fileUrl2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info, setValue]);

  const onSubmit = (values) => {
    setSubmit(true);
    // const viTime = values.time ? new Date(values.time).toISOString() : '';
    // const enTime = values.time_en ? new Date(values.time_en).toISOString() : '';

    let formData = new FormData();
    formData.append('address', values.address);
    formData.append('time', values.time);
    formData.append('address_en', values.address_en);
    formData.append('time_en', values.time_en);
    if (values.topic) formData.append('file', values.topic);
    if (values.topic_2) formData.append('file_en', values.topic_2);
    dispatch(settingAuthorityApi(formData));
    // console.log("formData: ", formData);
    // } else {
    //   let formData = new FormData();
    //   formData.append('address', values.address);
    //   formData.append('time', formatDateTime(values.time));
    //   dispatch(settingAuthorityApi(formData));
    // }
  };

  const handleResetInputs = () => {
    setFile(null);
    setFile2(null);
    setValue('address', '');
    setValue('time', '');
    setValue('address_en', '');
    setValue('time_en', '');
    setValue('file', '');
    setValue('file_en', '');
  };

  useEffect(() => {
    setValue('address', '');
    setValue('time', '');
    setValue('address_en', '');
    setValue('time', '');
    setFile(null);
    setFile2(null);
  }, []);

  const setRessetFile = () => {
    setFile(null);
    setValue('topic', '');
  };

  const setRessetFile2 = () => {
    setFile2(null);
    setValue('topic_2', '');
  };

  return (
    <StylesContent>
      <div className="module-content">
        <div className="form">
          <div className="form-item">
            <Input.Text
              wrapperProps={{
                label: t('Địa chỉ nhận giấy ủy quyền'),
                icon: inputIcons.address,
              }}
              register={register('address', { required: t(VALIDATE_REQUIRE) })}
            />
            <ErrorMessage errors={errors} field="address" />
          </div>
          <div className="form-item">
            <Input.Text
              name={'time'}
              {...{ control, setValue, watch }}
              wrapperProps={{
                label: t('Thời gian'),
                icon: inputIcons.date,
              }}
              register={register('time', { required: t(VALIDATE_REQUIRE) })}
            />
            <ErrorMessage errors={errors} field="time" />
          </div>
          <div className="form-item">
            <Input.Text
              wrapperProps={{
                label: t('Địa chỉ nhận giấy ủy quyền (tiếng Anh)'),
                icon: inputIcons.address,
              }}
              register={register('address_en', {
                required: t(VALIDATE_REQUIRE),
              })}
            />
            <ErrorMessage errors={errors} field="address_en" />
          </div>
          <div className="form-item">
            <Input.Text
              name={'time_en'}
              // {...{ control, setValue, watch }}
              wrapperProps={{
                label: t('Thời gian (tiếng Anh)'),
                icon: inputIcons.date,
              }}
              register={register('time_en', { required: t(VALIDATE_REQUIRE) })}
            />
            <ErrorMessage errors={errors} field="time_en" />
          </div>
          <div className="form-item">
            <DropFile
              file={file}
              label={t('Tải lên mẫu ủy quyền (tiếng Việt)')}
              onChange={(e) => handleChooseFile(e)('topic')}
              handleDelete={setRessetFile}
            />
            <ErrorMessage errors={errors} field="topic" />
          </div>
          <div className="form-item">
            <DropFile
              file={file2}
              label={t('Tải lên mẫu ủy quyền (tiếng Anh)')}
              onChange={(e) => handleChooseFile(e)('topic_2')}
              handleDelete={setRessetFile2}
            />
            <ErrorMessage errors={errors} field="topic_2" />
          </div>
        </div>
        <div className="form-button">
          <Button className="item-button" onClick={handleSubmit(onSubmit)}>
            {t('Lưu')}
          </Button>
        </div>
      </div>
    </StylesContent>
  );
};

export default TabContentSettings;
