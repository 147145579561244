import * as types from './constants';
// import * as common from './../../../../common/constants';
export const defaultResult = {
  totalShareholder: 0,
  totalShares: 0,
  totalShareholderJoined: 0,
  totalSharesJoined: 0,
  totalJoinedVotedLive: 0,
  totalJoinedVotedOnline: 0,
  totalJoinedVotedAuthority: 0,
  questions: {},
};

const initialState = {
  time_end: new Date(),
  data: defaultResult,
  conferenceInfo: {
    nameMeeting: '',
    nameMeeting_2: '',
    nameCompany: '',
    nameCompany_2: '',
    bannerUrl: '',
    logoUrl: '',
    livestreamLink: '',
    livestreamLink2: '',
  },
  tableList: [],
  listTopicChoose: [],
  filter: {
    identityNumber: '',
    status: 'boss',
    pageSize: 10,
    pageNumber: 1,
  },
  toggleModal: false,
  total: 0,
  topic1: [],
  topic2: [],
  topic3: [],
  topic4: [],
  topic6: [],
  member: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_DATA:
      return { ...state, data: action.value };
    case types.GET_DATA_MEMBER:
      return { ...state, data: action.value };
    case types.GET_TOPIC1:
      return { ...state, topic1: action.value };
    case types.GET_TOPIC2:
      return { ...state, topic2: action.value };
    case types.GET_TOPIC3:
      return { ...state, topic3: action.value };
    case types.GET_TOPIC4:
      return { ...state, topic4: action.value };
    case types.GET_TOPIC6:
      return { ...state, topic6: action.value };
    case types.SET_TIME_END:
      return { ...state, time_end: action.value };
    case types.GET_LIST:
      return { ...state, tableList: action.value };
    case types.GET_TOGGLE_MODAL:
      return { ...state, toggleModal: action.value };
    case types.SET_CHOOSE_LIST:
      return { ...state, listTopicChoose: action.value };
    case types.SET_FILTER:
      return { ...state, filter: action.value };
    case types.SET_TOTAL:
      return { ...state, total: action.value };
    case types.SET_CONFERENCE_INFO:
      return { ...state, conferenceInfo: action.value };
    default:
      return state;
  }
};

export default reducer;
