import * as types from './constants';

var initialState = {
  report: null,
  topic2: [],
  topic1: [],
  topic3: [],
  topic4: [],
  topic6: [],
  toggleModal: false,

  // ----modal
  pageSizeModal: 10,
  pageNumberModal: 1,
  filterModal: {
    page: 1,
    take: 10,
    kind: 1,
    topicId: '',
    point: 'all',
    invalidVoted: true,
    fixId: '',
    selectName: '',
    voteStatus: 'voted', // voted - notVote
    search: '',
  },
  listResultModal: [],
  listResultTotal: 0,
  // ----end modal
};
var reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_MODAL:
      return {
        ...state,
        toggleModal: action.value,
      };
    case types.GET_REPORT:
      return { ...state, report: action.value };
    case types.GET_TOPIC1:
      return { ...state, topic1: action.value };
    case types.GET_TOPIC2:
      return { ...state, topic2: action.value };
    case types.GET_TOPIC3:
      return { ...state, topic3: action.value };
    case types.GET_TOPIC4:
      return { ...state, topic4: action.value };
    case types.GET_TOPIC6:
      return { ...state, topic6: action.value };
    case types.GET_RESULT_DETAIL:
      return { ...state, listResultModal: action.value };
    case types.GET_RESULT_TOTAL:
      return { ...state, listResultTotal: action.value };
    case types.SET_FILTER_MODAL:
      return { ...state, filterModal: action.value };
    default:
      return state;
  }
};
export default reducer;
