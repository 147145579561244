import Table from '@components/table';
import React, { Children, useEffect, useRef, useState } from 'react';
import { defaultTheads, sheet4Theads } from '../data';
import { useSelector, useDispatch } from 'react-redux';
import {
  getUserApi,
  setChooseList,
  setBlockList,
  setFilterListPage,
} from '@duck/pages/admin/initializeReportReducer/actions';
import Checkbox from '@components/input/Checkbox';
import Button from '@components/button';
import DisableHolderModal from '../modal/DisableHolderModal';
import { useTranslation } from 'react-i18next';
import Pagination from '@components/pagination';

export default function DataTable({ tableType, kind }) {
  const { tableList, filterListPage, totalListPage } = useSelector(
    (state) => state.admin.initializeReport,
  );
  const { method } = useSelector((state) => state.admin.initializeReport);
  const [isFirst, setIsFirst] = useState(true);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (!isFirst)
      dispatch(
        getUserApi(
          tableType === 'sheet-4'
            ? method === 1
              ? 'sheet-4'
              : 'sheet-6'
            : tableType,
          filterListPage,
        ),
      );
  }, [dispatch, tableType, filterListPage, method, isFirst]);

  // const firstIndex =
  //   (filterListPage.pageNumber - 1) * filterListPage.pageSize + 1;

  useEffect(() => {
    dispatch(
      setFilterListPage({
        ...filterListPage,
        pageNumber: 1,
        pageSize: 10,
      }),
    );
    setIsFirst(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const getPageSize = (pageSize) => {
    dispatch(
      setFilterListPage({
        ...filterListPage,
        pageSize,
        pageNumber: 1,
      }),
    );
  };

  const getPage = (pageNumber) => {
    dispatch(
      setFilterListPage({
        ...filterListPage,
        pageNumber,
      }),
    );
  };

  return (
    <>
      <Table
        header={
          tableType === 'sheet-3'
            ? defaultTheads
            : tableType === 'sheet-2' && tableType !== 'sheet-1'
            ? sheet4Theads
            : method === 0 && tableType != 'sheet-5' && tableType !== 'sheet-1'
            ? sheet4Theads
            : defaultTheads
        }
      >
        {Children.toArray(
          tableList.map((d, i) => (
            <TableRow
              {...{ d, tableType }}
              i={i + 1}
              kind={kind}
              method={method}
            />
          )),
        )}
        {tableList.length === 0 && (
          <tr>
            <td colSpan={100} className="center">
              {t('Chưa có dữ liệu')}
            </td>
          </tr>
        )}
      </Table>
      {tableList.length > 0 && (
        <Pagination
          handleChangePageSize={(size) => {
            getPageSize(size);
          }}
          current={filterListPage.pageNumber}
          total={totalListPage}
          pageSize={filterListPage.pageSize}
          onChange={(page) => {
            getPage(page);
          }}
        />
      )}
    </>
  );
}

const TableRow = ({ d, i, tableType, kind, method }) => {
  const { listTopicChoose } = useSelector(
    (state) => state.admin.initializeReport,
  );
  const { t } = useTranslation();

  const [isOpen, toggleModal] = useState(false);

  const refBan = useRef(null);

  const dispatch = useDispatch();

  if (!d) return null;

  const active = listTopicChoose.length > 0 && listTopicChoose.includes(d._id);

  const handleChoose = () => {
    var newListChose = [...listTopicChoose];

    if (newListChose.indexOf(d._id) < 0) {
      newListChose.push(d._id);
    } else {
      newListChose.splice(newListChose.indexOf(d._id), 1);
    }
    dispatch(setChooseList(newListChose));
  };

  // const onCloseVoteTopic = () => {
  //   dispatch(closeVoteTopicApi({toggleType: 'close'}, d._id, type));
  // };
  return (
    <>
      <tr onClick={handleChoose}>
        <td>
          <Checkbox checked={active} />
        </td>
        <td>{i}</td>
        <td>{d.name}</td>
        <td>{d.name_2}</td>
        <td>{d.order}</td>
        {(tableType === 'sheet-2' || method === 0) &&
          tableType != 'sheet-5' &&
          tableType !== 'sheet-1' &&
          tableType !== 'sheet-3' && (
            <td>
              {/* {d.isOpen ? (
              <> */}
              <Button
                ref={refBan}
                className="ban"
                onClick={() => {
                  toggleModal(true);
                  dispatch(
                    setBlockList(
                      d.unableShareholder ? d.unableShareholder : [],
                    ),
                  );
                }}
              >
                {t('Chặn CĐ biểu quyết')}
              </Button>
              {/* <Button
                  className="close"
                  onClick={onCloseVoteTopic}
                >
                  Đóng biểu quyết
                </Button> */}
              <DisableHolderModal
                title={t('Chặn cổ đông biểu quyết')}
                {...{ isOpen, toggleModal }}
                id={d._id}
                subtitle={[
                  `${t('Tờ trình')}: ${d.name}`,
                  t('Thiết lập danh sách Cổ đông bị chặn biểu quyết'),
                ]}
                kind={kind}
                tableType={tableType}
                method={method}
              />
              {/* </>
            ) : (
              <Button className="closed" sdisabled>
                Đã đóng biểu quyết
              </Button>
            )} */}
            </td>
          )}
      </tr>
    </>
  );
};
