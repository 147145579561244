import * as types from './constants';
import * as common from './../../../../common/constants';
import { defaultOption } from '@configs/defaultOption';
var initialState = {
  tableList: [],
  listTopicChoose: [],
  total: 0,
  shareholderList: [],
  filter: {
    identityNumber: '',
    joinType: 'all',
    status: 'all',
    pageSize: common.PAGE_SIZE,
    pageNumber: 1,
    pageSize: 10,
    shareholderType: defaultOption.value,
  },
  filterListPage: {
    identityNumber: '',
    joinType: 'all',
    status: 'all',
    pageSize: common.PAGE_SIZE,
    pageNumber: 1,
    pageSize: 10,
    shareholderType: defaultOption.value,
  },
  total: 0,
  totalListPage: 0,
  success: false,
  activeButtonConfirm: true,
  method: 0,
  shareholderRefetch: false,
  blockList: [],
};

var reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_LIST_SHAREHOLDER:
      return { ...state, shareholderList: action.value };
    case types.SET_ACTIVE_BUTTON_CONFIRM:
      return { ...state, activeButtonConfirm: action.value };
    case types.SET_SUCCESS:
      return { ...state, success: action.value };
    case types.SET_FILTER:
      return { ...state, filter: { ...state.filter, ...action.value } };
    case types.SET_FILTER_LISTPAGE:
      return {
        ...state,
        filterListPage: { ...state.filterListPage, ...action.value },
      };
    case types.SET_TOTAL_LISTPAGE:
      return { ...state, totalListPage: action.value };
    case types.SET_TOTAL:
      return { ...state, total: action.value };
    case types.GET_LIST:
      return { ...state, tableList: action.value };
    case types.SET_CHOOSE_LIST:
      return { ...state, listTopicChoose: action.value };
    case types.SET_BLOCK_LIST:
      return { ...state, blockList: action.value };
    case types.SET_METHOD:
      return { ...state, method: action.value };
    case types.REFETCH_SHARE_HOLDER:
      return { ...state, shareholderRefetch: !state.shareholderRefetch };
    default:
      return state;
  }
};
export default reducer;
