import React from "react";
const Loading = ({title}) => {
    return <div className="loading">
        <section className="wrapper">
            <div className="spinner">
                <i /><i /><i /><i /><i /><i /><i />
            </div>
            <p className="desc">{title} <span>.</span><span>.</span><span>.</span></p>
        </section>
    </div>
}
export default Loading;