import React, { Fragment, useEffect, useState } from 'react';
import { ShowDetailsModal } from './modal';
import { useSelector, useDispatch } from 'react-redux';
import {
  infoShareholderApi,
  setToggleModal,
  infoShareholderDownloadApi,
  setDownload,
  infoShareholderDownloadAllApi,
  setLoading,
} from '../../../../states/duck/pages/checkCoupon/checkCouponListReducer/actions';
import { transformNumberPrinter } from '../../../bases/configs';
import Loading from '../../../bases/shared/loading';
import Button from '@components/button';
import { STableTools } from '@styled/checkCoupon/checkCouponListPage';
import Modal from '@components/modal';
import { useTranslation } from 'react-i18next';
import { getListAuthority } from '@duck/pages/admin/shareholderStatusReducer/actions';

const TableTools = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const listChose = useSelector(
    (state) => state.checkCoupon.checkCouponList.listTopicChoose,
  );
  const { shareholderDetails, detailsList, loading, download, toggleModal } =
    useSelector((state) => state.checkCoupon.checkCouponList);
  // const table = useSelector(
  //   (state) => state.checkCoupon.checkCouponList.tableList,
  // );
  const [fileName, setFileName] = useState('');
  const [title, setTitle] = useState('');
  useEffect(() => {
    if (download) {
      setTimeout(() => {
        Export2Doc('print', fileName);
        dispatch(setDownload(false));
      }, 500);
    }
  }, [download, dispatch, fileName]);
  const closeModal = (s) => {
    dispatch(setToggleModal(s));
  };
  return (
    <STableTools>
      {loading ? <Loading title={title} /> : ''}
      <div className="table-tools">
        {listChose.length > 0 ? (
          <>
            {listChose[0].isVoted ? (
              <>
                <Button
                  className="view-btn"
                  onClick={() =>
                    dispatch(
                      infoShareholderApi(
                        listChose[0]._id,
                        listChose[0].identityNumber,
                      ),
                    )
                  }
                >
                  {iconWatch}
                  {t('Xem phiếu biểu quyết')}
                </Button>
                <Button
                  className="download_one-btn"
                  onClick={() => {
                    dispatch(
                      infoShareholderDownloadApi(
                        listChose[0] ? listChose[0]._id : '',
                      ),
                    );
                    dispatch(
                      getListAuthority(
                        listChose[0]._id,
                        listChose[0].identityNumber,
                      ),
                    );
                    setFileName('chitietphieubau');
                    setTitle(
                      t('Đang lấy kết quả phiếu biểu quyết. Vui lòng đợi'),
                    );
                    // dispatch(setLoading(true));
                  }}
                >
                  {iconDownload}
                  {t('Tải xuống chi tiết phiếu biểu quyết')}
                </Button>
              </>
            ) : (
              <>
                <Button
                  className="view-btn disabled-btn"
                  // onClick={() =>
                  //   dispatch(
                  //     infoShareholderApi(listChose[0] ? listChose[0]._id : ''),
                  //   )
                  // }
                >
                  {iconWatch}
                  {t('Xem phiếu biểu quyết')}
                </Button>
                <Button className="download_one-btn disabled-btn">
                  {iconDownload}
                  {t('Tải xuống chi tiết phiếu biểu quyết')}
                </Button>
              </>
            )}
          </>
        ) : (
          ''
        )}
        {/* {table.filter((item) => item.isVoted).length > 0 ? ( */}
        <Button
          className="download_all-btn disable__btn"
          onClick={() => {
            dispatch(
              infoShareholderDownloadAllApi(
                listChose[0] ? listChose[0]._id : '',
              ),
            );
            setFileName('chitietphieubautatca');
            setTitle(
              'Đang lấy kết quả và tạo danh sách phiếu biểu quyết. Vui lòng đợi',
            );
            // dispatch(setLoading(true));
          }}
        >
          {iconDownload}
          {t('Tải xuống tất cả phiếu biểu quyết')}
        </Button>
      </div>
      <div
        className="print-content"
        style={{ display: 'block', height: 0, opacity: 0, overflow: 'hidden' }}
      >
        <div
          className="result"
          id="print"
          style={{
            background: '#fff',
            padding: '50px 40px',
            width: '555pt',
            marginLeft: '0',
            marginRight: 'auto',
          }}
        >
          {fileName === 'chitietphieubau' ? (
            <Report shareholderDetails={shareholderDetails} />
          ) : fileName === 'chitietphieubautatca' ? (
            <Fragment>
              {detailsList.map((item, index) => {
                return <ReportForAll shareholderDetails={item} key={index} />;
              })}
            </Fragment>
          ) : (
            ''
          )}
        </div>
      </div>
      {/* <BsModal
        closeIcon='<i class="far fa-window-close"></i>'
        toggleModal={toggleModal}
        onClick={() => dispatch(setToggleModal(false))}
        className={`modal-status`}
      >
        {toggleModal ? <ShowDetailsModal /> : ''}
      </BsModal> */}
      <Modal
        isOpen={toggleModal}
        toggleModal={closeModal}
        title={'Xem phiếu biểu quyết'}
        overwriteModalStyle={{
          maxWidth: '800px',
        }}
      >
        {toggleModal ? <ShowDetailsModal /> : ''}{' '}
      </Modal>
    </STableTools>
  );
};
export default TableTools;
const findPositionIndex = (obj, arr) => {
  let index = 0;
  for (let i = 0; i < arr.length; i++) {
    if (obj.topic_id && obj.topic_id._id === arr[i]._id) {
      index = i;
    }
  }
  return index;
};
const setResultTopic = (topic, voteKind, kind, shareholderId) => {
  let topicMirror = [...topic];
  for (let i = 0; i < voteKind.length; i++) {
    let index = findPositionIndex(voteKind[i], topic);
    if (index >= 0) {
      topicMirror.splice(index, 1, voteKind[i]);
    }
  }
  if (kind !== 2) {
    topicMirror.sort((a, b) => {
      let orderA = a.topic_id ? a.topic_id.order : a.order;
      let orderB = b.topic_id ? b.topic_id.order : b.order;
      if (orderA > orderB) {
        return 1;
      } else if (orderA < orderB) {
        return -1;
      }
      return 0;
    });
  } else {
    topicMirror = topicMirror
      .sort((a, b) => {
        let orderA = a.topic_id ? a.topic_id.order : a.order;
        let orderB = b.topic_id ? b.topic_id.order : b.order;
        if (orderA > orderB) {
          return 1;
        } else if (orderA < orderB) {
          return -1;
        }
        return 0;
      })
      .map((item, index) => {
        let unable = false;
        let unableShareholder = item.topic_id
          ? item.topic_id.unableShareholder
          : item.unableShareholder;
        if (unableShareholder.indexOf(shareholderId) >= 0) {
          unable = true;
        }
        return { ...item, unable };
      });
  }
  return topicMirror;
};
const Report = ({ shareholderDetails, listAutho }) => {
  const conference = useSelector(
    (state) => state.checkCoupon.checkCouponList.conference,
  );
  const info = shareholderDetails ? shareholderDetails.shareholder : {};
  const { topic1, listAuthorities, topic2, topic3, topic4 } = useSelector(
    (state) => state.checkCoupon.checkCouponList,
  );
  const votes = shareholderDetails ? shareholderDetails.votes : {};
  const now = new Date();

  return (
    <Fragment>
      <div style={{ position: 'relative' }}>
        <style
          dangerouslySetInnerHTML={{
            __html:
              '\n \n\n @media print{html, body {border: 1px solid white;height: 99%;page-break-after: avoid !important;page-break-before: avoid !important;}}  .break-page{ page-break-before: always }  table { page-break-inside:auto } tr{ page-break-inside:avoid; page-break-after:auto }           #print {\n  line-height: 20px;               font-family: times;color: #000\n            }\n\n        ',
          }}
        />
        <p
          style={{
            textAlign: 'center',
            marginTop: '0pt',
            marginBottom: '0pt',
            lineHeight: '12pt',
          }}
        >
          <span
            style={{
              left: '40pt',
              top: '0pt',
              height: '60pt',
              display: 'block',
              position: 'absolute',
            }}
          ></span>
          <strong>
            <span
              style={{
                fontFamily: '"times"',
                fontSize: '12pt',
                textTransform: 'uppercase',
              }}
            >
              {conference.nameCompany}
            </span>
          </strong>
        </p>
        <p
          style={{
            textAlign: 'center',
            marginTop: '0pt',
            marginBottom: '0pt',
            lineHeight: '1.8pt',
          }}
        >
          <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
            &nbsp;
          </span>
        </p>
        <p
          style={{
            textAlign: 'center',
            marginTop: '0pt',
            marginBottom: '0pt',
            lineHeight: '12pt',
          }}
        >
          <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
            Địa chỉ: {conference.address ? conference.address.details : ''},{' '}
            {conference.address ? conference.address.wards : ''},{' '}
            {conference.address ? conference.address.district : ''},{' '}
            {conference.address ? conference.address.province : ''}
          </span>
        </p>
        <p
          style={{
            textAlign: 'center',
            marginTop: '0pt',
            marginBottom: '0pt',
            lineHeight: '1.95pt',
          }}
        >
          <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
            &nbsp;
          </span>
        </p>
        <p
          style={{
            textAlign: 'center',
            marginTop: '0pt',
            marginBottom: '0pt',
            lineHeight: '12.5pt',
          }}
        >
          <span style={{ fontFamily: '"times"', fontSize: '12.5pt' }}>
            ĐT: {conference.phone} - Fax: {conference.fax}
          </span>
        </p>
        <p
          style={{
            marginTop: '0pt',
            marginBottom: '0pt',
            lineHeight: '11.15pt',
          }}
        >
          <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
            &nbsp;
          </span>
        </p>
        <hr style={{ borderBottom: '1pt solid rgb(204, 0, 0)' }} />
        <p
          style={{
            marginTop: '0pt',
            marginBottom: '0pt',
            lineHeight: '11.15pt',
          }}
        >
          <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0pt',
            marginLeft: '0pt',
            marginBottom: '0pt',
            lineHeight: '12pt',
            textAlign: 'right',
          }}
        >
          <em>
            <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
              {conference.addressMeeting
                ? conference.addressMeeting.province
                : ''}
              , ngày {now.getDate().toString().padStart(2, 0)} tháng{' '}
              {(now.getMonth() + 1).toString().padStart(2, 0)} năm{' '}
              {now.getFullYear()}
            </span>
          </em>
        </p>
        <p
          style={{
            marginTop: '0pt',
            marginBottom: '0pt',
            lineHeight: '12.35pt',
          }}
        >
          <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0pt',
            marginRight: '6pt',
            marginBottom: '0pt',
            textAlign: 'center',
            lineHeight: '14pt',
          }}
        >
          <strong>
            <span style={{ fontFamily: '"times"', fontSize: '14pt' }}>
              PHIẾU BIỂU QUYẾT
            </span>
          </strong>
        </p>
        <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: '6pt' }}>
          <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0pt',
            marginRight: '5pt',
            marginBottom: '0pt',
            textAlign: 'center',
            lineHeight: '14pt',
          }}
        >
          <strong>
            <span
              style={{
                fontFamily: '"times"',
                fontSize: '14pt',
                textTransform: 'uppercase',
              }}
            >
              ĐẠI HỘI ĐỒNG CỔ ĐôNG THƯỜNG NIêN NĂM {now.getFullYear()}
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: '0pt',
            marginBottom: '0pt',
            lineHeight: '11.75pt',
          }}
        >
          <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0pt',
            marginLeft: '1pt',
            marginBottom: '0pt',
            lineHeight: '12pt',
          }}
        >
          <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
            Họ và tên Cổ đông: {info ? info.name : ''}
          </span>
        </p>
        <p
          style={{
            marginTop: '0pt',
            marginLeft: '1pt',
            marginBottom: '0pt',
            lineHeight: '12pt',
          }}
        >
          <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
            Mã Cổ đông: {info ? info.code : ''}
          </span>
        </p>
        <p
          style={{
            marginTop: '0pt',
            marginLeft: '1pt',
            marginBottom: '0pt',
            lineHeight: '12pt',
          }}
        >
          <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
            Tổng số lượng cổ phần:{' '}
            {transformNumberPrinter(
              info && info.sharesNumber ? info.sharesNumber : 0,
            )}{' '}
            cổ phần
          </span>
        </p>
        <p
          style={{
            marginTop: '0pt',
            marginBottom: '0pt',
            lineHeight: '8.05pt',
          }}
        >
          <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0pt',
            marginRight: '6pt',
            marginBottom: '0pt',
            textAlign: 'center',
            lineHeight: '12pt',
          }}
        >
          <em>
            <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
              (Quý Đại biểu đánh dấu vào ô ý kiến lựa chọn theo từng Nội dung
              biểu quyết)
            </span>
          </em>
        </p>
        <p
          style={{
            marginTop: '0pt',
            marginBottom: '0pt',
            lineHeight: '12.2pt',
          }}
        >
          <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
            &nbsp;
          </span>
        </p>

        {(!listAuthorities ||
          (!listAutho && listAuthorities.length === 0) ||
          (listAutho && listAutho.length === 0)) && (
          <ReportEachPerson
            topicResult1={setResultTopic(
              topic1,
              votes
                ? votes.filter(
                    (item) => item.topic_id !== null && item.kind === 1,
                    // &&
                    // item.voter === author.value,
                  )
                : [],
              1,
              info && info._id,
            )}
            topicResult2={setResultTopic(
              topic2,
              votes
                ? votes.filter(
                    (item) => item.topic_id !== null && item.kind === 2,
                    // &&
                    // item.voter === author.value,
                  )
                : [],
              2,
              info && info._id,
            )}
            topicResult3={setResultTopic(
              topic3,
              votes
                ? votes.filter(
                    (item) => item.topic_id !== null && item.kind === 3,
                    // &&
                    // item.voter === author.value,
                  )
                : [],
              3,
              info && info._id,
            )}
            topicResult4={setResultTopic(
              topic4,
              votes
                ? votes.filter(
                    (item) => item.topic_id !== null && item.kind === 4,
                    // &&
                    // item.voter === author.value,
                  )
                : [],
              4,
              info && info._id,
            )}
            info={info}
            authorityReceiver={undefined}
          />
        )}

        {listAuthorities &&
          info &&
          info._id &&
          !listAutho &&
          listAuthorities.map((author, index) => {
            return (
              <ReportEachPerson
                key={index}
                topicResult1={setResultTopic(
                  topic1,
                  votes
                    ? votes.filter(
                        (item) =>
                          item.topic_id !== null &&
                          item.kind === 1 &&
                          item.voter === author.value,
                      )
                    : [],
                  1,
                  info._id,
                )}
                topicResult2={setResultTopic(
                  topic2,
                  votes
                    ? votes.filter(
                        (item) =>
                          item.topic_id !== null &&
                          item.kind === 2 &&
                          item.voter === author.value,
                      )
                    : [],
                  2,
                  info._id,
                )}
                topicResult3={setResultTopic(
                  topic3,
                  votes
                    ? votes.filter(
                        (item) =>
                          item.topic_id !== null &&
                          item.kind === 3 &&
                          item.voter === author.value,
                      )
                    : [],
                  3,
                  info._id,
                )}
                topicResult4={setResultTopic(
                  topic4,
                  votes
                    ? votes.filter(
                        (item) =>
                          item.topic_id !== null &&
                          item.kind === 4 &&
                          item.voter === author.value,
                      )
                    : [],
                  4,
                  info._id,
                )}
                info={info}
                authorityReceiver={author}
              />
            );
          })}
        {listAuthorities &&
          info &&
          info._id &&
          listAutho &&
          listAutho.map((author, index) => {
            return (
              <ReportEachPerson
                key={index}
                topicResult1={setResultTopic(
                  topic1,
                  votes
                    ? votes.filter(
                        (item) =>
                          item.topic_id !== null &&
                          item.kind === 1 &&
                          item.voter === author.value,
                      )
                    : [],
                  1,
                  info._id,
                )}
                topicResult2={setResultTopic(
                  topic2,
                  votes
                    ? votes.filter(
                        (item) =>
                          item.topic_id !== null &&
                          item.kind === 2 &&
                          item.voter === author.value,
                      )
                    : [],
                  2,
                  info._id,
                )}
                topicResult3={setResultTopic(
                  topic3,
                  votes
                    ? votes.filter(
                        (item) =>
                          item.topic_id !== null &&
                          item.kind === 3 &&
                          item.voter === author.value,
                      )
                    : [],
                  3,
                  info._id,
                )}
                topicResult4={setResultTopic(
                  topic4,
                  votes
                    ? votes.filter(
                        (item) =>
                          item.topic_id !== null &&
                          item.kind === 4 &&
                          item.voter === author.value,
                      )
                    : [],
                  4,
                  info._id,
                )}
                info={info}
                authorityReceiver={author}
              />
            );
          })}
        <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '12pt' }}>
          <span style={{ fontFamily: '"times"' }}>&nbsp;</span>
        </p>
        <p
          style={{
            marginTop: '0pt',
            marginRight: '24pt',
            marginBottom: '0pt',
            textAlign: 'right',
            fontSize: '12pt',
          }}
        >
          <strong>
            <span style={{ fontFamily: '"times"' }}>Cổ đông</span>
          </strong>
        </p>
        <p
          style={{
            marginTop: '0pt',
            marginBottom: '0pt',
            textAlign: 'right',
            fontSize: '12pt',
          }}
        >
          <span style={{ fontFamily: '"times"' }}>(Ký, ghi rõ họ tên)</span>
        </p>
        <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '12pt' }}>
          <span style={{ fontFamily: '"times"' }}>&nbsp;</span>
        </p>
        <pre>
          <br clear="all" className="break-page" />
        </pre>
      </div>
    </Fragment>
  );
};
function Export2Doc(element, filename = '') {
  var preHtml =
    "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
  var postHtml = '</body></html>';
  var html = preHtml + document.getElementById(element).innerHTML + postHtml;
  var blob = new Blob(['\ufeff', html], {
    type: 'application/msword',
  });
  // Specify link url
  var url =
    'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);
  // Specify file name
  filename = filename ? filename + '.doc' : 'document.doc';
  // Create download link element
  var downloadLink = document.createElement('a');
  document.body.appendChild(downloadLink);
  if (navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    // Create a link to the file
    downloadLink.href = url;
    // Setting the file name
    downloadLink.download = filename;
    //triggering the function
    downloadLink.click();
  }
  document.body.removeChild(downloadLink);
}

const iconDownload = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_145_13553)">
      <path
        d="M8.993 7.007L8.969 0H7.062L7.014 7.014L4.5 4.5L3 6L8 11L13 6L11.5 4.5L8.993 7.007Z"
        fill="white"
      />
      <path d="M14 11V14H2V11H0V17H16V11H14Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_145_13553">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const iconWatch = (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9033 5.59453C14.3969 2.65536 11.4147 0.666748 7.99998 0.666748C4.58526 0.666748 1.60221 2.65675 0.0966515 5.5948C0.0331076 5.7205 0 5.85937 0 6.00022C0 6.14107 0.0331076 6.27994 0.0966515 6.40564C1.60304 9.3448 4.58526 11.3334 7.99998 11.3334C11.4147 11.3334 14.3978 9.34341 15.9033 6.40536C15.9669 6.27966 16 6.14079 16 5.99994C16 5.8591 15.9669 5.72022 15.9033 5.59453ZM7.99998 10.0001C7.20886 10.0001 6.4355 9.76549 5.7777 9.32596C5.11991 8.88643 4.60722 8.26172 4.30447 7.53082C4.00172 6.79991 3.9225 5.99564 4.07684 5.21972C4.23118 4.4438 4.61215 3.73106 5.17156 3.17165C5.73097 2.61224 6.4437 2.23128 7.21962 2.07694C7.99555 1.9226 8.79981 2.00181 9.53072 2.30456C10.2616 2.60731 10.8863 3.12 11.3259 3.7778C11.7654 4.4356 12 5.20896 12 6.00008C12.0002 6.52544 11.897 7.0457 11.696 7.53112C11.4951 8.01654 11.2005 8.45759 10.829 8.82908C10.4575 9.20056 10.0164 9.49519 9.53102 9.69612C9.0456 9.89705 8.52534 10.0003 7.99998 10.0001ZM7.99998 3.33341C7.76197 3.33674 7.52548 3.37215 7.29693 3.43869C7.48532 3.69471 7.57573 4.00977 7.55175 4.32673C7.52777 4.64369 7.391 4.94157 7.16623 5.16633C6.94147 5.3911 6.6436 5.52787 6.32664 5.55185C6.00968 5.57583 5.69462 5.48542 5.4386 5.29703C5.29281 5.83413 5.31913 6.40344 5.51384 6.9248C5.70856 7.44617 6.06187 7.89335 6.52404 8.2034C6.98622 8.51345 7.53399 8.67076 8.09025 8.65319C8.64652 8.63562 9.18327 8.44405 9.62495 8.10544C10.0666 7.76684 10.391 7.29825 10.5524 6.76563C10.7139 6.23302 10.7042 5.66319 10.5248 5.13635C10.3454 4.60952 10.0053 4.1522 9.55238 3.82878C9.09946 3.50535 8.55653 3.3321 7.99998 3.33341Z"
      fill="white"
    />
  </svg>
);

const ReportEachPerson = ({
  topicResult1,
  topicResult2,
  topicResult3,
  topicResult4,
  info,
  authorityReceiver,
}) => {
  return (
    <>
      {authorityReceiver && (
        <>
          <p
            style={{
              marginTop: '10pt',
              marginLeft: '1pt',
              marginBottom: '0pt',
              lineHeight: '12pt',
            }}
          >
            <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
              Phiếu biểu quyết của Cổ đông:{' '}
              {authorityReceiver ? authorityReceiver.label : ''}
            </span>
          </p>
          <p
            style={{
              marginTop: '0pt',
              marginLeft: '1pt',
              marginBottom: '5pt',
              lineHeight: '12pt',
            }}
          >
            <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
              Số cổ phần nhận ủy quyền:{' '}
              {transformNumberPrinter(
                authorityReceiver ? authorityReceiver.sharesNumber : 0,
              )}
            </span>
          </p>
        </>
      )}
      <table
        cellPadding={0}
        cellSpacing={0}
        style={{ borderCollapse: 'collapse', border: '1pt solid #000' }}
      >
        <tbody>
          <tr style={{ height: '16.45pt', border: '1pt solid #000' }}>
            <td
              style={{
                width: '346.2pt',
                borderTopStyle: 'solid',
                borderTopWidth: '1pt',
                borderRightStyle: 'solid',
                borderRightWidth: '1pt',
              }}
            >
              <p
                style={{
                  margin: '0pt',
                  marginLeft: '87pt',
                  marginBottom: '0pt',
                  lineHeight: '12pt',
                }}
              >
                <strong>
                  <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
                    NỘI DUNG
                  </span>
                </strong>
              </p>
            </td>
            <td
              style={{
                width: '45.7pt',
                borderTopStyle: 'solid',
                borderTopWidth: '1pt',
                borderRightStyle: 'solid',
                borderRightWidth: '1pt',
              }}
            >
              <p
                style={{
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  textAlign: 'center',
                  lineHeight: '12pt',
                }}
              >
                <strong>
                  <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
                    Tán <br /> thành
                  </span>
                </strong>
              </p>
            </td>
            <td
              style={{
                width: '57.45pt',
                borderTopStyle: 'solid',
                borderTopWidth: '1pt',
                borderRightStyle: 'solid',
                borderRightWidth: '1pt',
              }}
            >
              <p
                style={{
                  marginTop: '5pt',
                  marginBottom: '5pt',
                  textAlign: 'center',
                  lineHeight: '12pt',
                }}
              >
                <strong>
                  <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
                    Không <br />
                    tán <br />
                    thành
                  </span>
                </strong>
              </p>
            </td>
            <td
              style={{
                width: '57.45pt',
                borderTopStyle: 'solid',
                borderTopWidth: '1pt',
                borderRightStyle: 'solid',
                borderRightWidth: '1pt',
              }}
            >
              <p
                style={{
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  textAlign: 'center',
                  lineHeight: '12pt',
                }}
              >
                <strong>
                  <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
                    Không có <br /> ý kiến
                  </span>
                </strong>
              </p>
            </td>
          </tr>

          {topicResult1.map((item, index) => {
            return (
              <tr
                style={{ height: '18.45pt', border: '1pt solid #000' }}
                key={index}
              >
                <td
                  style={{
                    width: '366.9pt',
                    borderStyle: 'solid',
                    borderWidth: '1pt',
                  }}
                >
                  <p
                    style={{
                      marginTop: '5pt',
                      marginBottom: '5pt',
                      marginLeft: '5pt',
                      lineHeight: '12pt',
                    }}
                  >
                    {item.topic_id ? item.topic_id.name : item.name}
                  </p>
                </td>
                <td
                  style={{
                    width: '45.7pt',
                    borderStyle: 'solid',
                    borderWidth: '1pt',
                  }}
                >
                  <p
                    style={{
                      marginTop: '0pt',
                      marginBottom: '0pt',
                      textAlign: 'center',
                      lineHeight: '12pt',
                    }}
                  >
                    <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
                      {item.point === 1 ? 'x' : ' '}
                    </span>
                  </p>
                </td>
                <td
                  style={{
                    width: '57.45pt',
                    borderStyle: 'solid',
                    borderWidth: '1pt',
                  }}
                >
                  <p
                    style={{
                      marginTop: '0pt',
                      marginBottom: '0pt',
                      textAlign: 'center',
                      lineHeight: '12pt',
                    }}
                  >
                    <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
                      {item.point === 2 ? 'x' : ' '}
                    </span>
                  </p>
                </td>
                <td
                  style={{
                    width: '57.45pt',
                    borderStyle: 'solid',
                    borderWidth: '1pt',
                  }}
                >
                  <p
                    style={{
                      marginTop: '0pt',
                      marginBottom: '0pt',
                      textAlign: 'center',
                      lineHeight: '12pt',
                    }}
                  >
                    <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
                      {item.point === 3 ? 'x' : ' '}
                    </span>
                  </p>
                </td>
              </tr>
            );
          })}
          {topicResult2.map((item, index) => {
            return (
              <tr
                style={{ height: '18.45pt', border: '1pt solid #000' }}
                key={index}
              >
                <td
                  style={{
                    width: '366.9pt',
                    borderStyle: 'solid',
                    borderWidth: '1pt',
                  }}
                >
                  <p
                    style={{
                      marginTop: '5pt',
                      marginBottom: '5pt',
                      marginLeft: '5pt',
                      lineHeight: '12pt',
                    }}
                  >
                    {item.topic_id ? item.topic_id.name : item.name}
                  </p>
                  {item.unable ? (
                    <p
                      style={{
                        marginTop: '5pt',
                        marginBottom: '5pt',
                        marginLeft: '5pt',
                        lineHeight: '12pt',
                        color: 'red',
                        fontStyle: 'italic',
                      }}
                    >
                      (Cổ đông bị chặn biểu quyết cho nội dung này)
                    </p>
                  ) : (
                    ''
                  )}
                </td>
                <td
                  style={{
                    width: '45.7pt',
                    borderStyle: 'solid',
                    borderWidth: '1pt',
                  }}
                >
                  <p
                    style={{
                      marginTop: '0pt',
                      marginBottom: '0pt',
                      textAlign: 'center',
                      lineHeight: '12pt',
                    }}
                  >
                    <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
                      {item.point === 1 ? 'x' : ' '}
                    </span>
                  </p>
                </td>
                <td
                  style={{
                    width: '57.45pt',
                    borderStyle: 'solid',
                    borderWidth: '1pt',
                  }}
                >
                  <p
                    style={{
                      marginTop: '0pt',
                      marginBottom: '0pt',
                      textAlign: 'center',
                      lineHeight: '12pt',
                    }}
                  >
                    <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
                      {item.point === 2 ? 'x' : ' '}
                    </span>
                  </p>
                </td>
                <td
                  style={{
                    width: '57.45pt',
                    borderStyle: 'solid',
                    borderWidth: '1pt',
                  }}
                >
                  <p
                    style={{
                      marginTop: '0pt',
                      marginBottom: '0pt',
                      textAlign: 'center',
                      lineHeight: '12pt',
                    }}
                  >
                    <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
                      {item.point === 3 ? 'x' : ' '}
                    </span>
                  </p>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {topicResult4.length > 0 &&
        topicResult4.map((item, index) => {
          return (
            <Fragment key={index}>
              <p
                style={{
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  lineHeight: '12.2pt',
                }}
              >
                <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
                  &nbsp;
                </span>
              </p>
              <p
                style={{
                  marginTop: '0pt',
                  marginBottom: '10pt',
                  fontSize: '12pt',
                }}
              >
                <span style={{ fontFamily: '"times"' }}></span>
              </p>
              <p
                style={{
                  marginTop: '0pt',
                  marginBottom: '0pt',
                  lineHeight: '12pt',
                }}
              >
                <strong>
                  <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
                    {item.topic_id ? item.topic_id.name : item.name}
                  </span>
                </strong>
              </p>
              <p
                style={{
                  marginTop: '0pt',
                  marginBottom: '10pt',
                  lineHeight: '12pt',
                }}
              >
                <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
                  Số phiếu biểu quyết:{' '}
                  {authorityReceiver
                    ? transformNumberPrinter(
                        Number(
                          item.topic_id
                            ? item.topic_id.selectNumber
                            : item.selectNumber,
                        ) * authorityReceiver.sharesNumber,
                      )
                    : transformNumberPrinter(
                        Number(
                          item.topic_id
                            ? item.topic_id.selectNumber
                            : item.selectNumber,
                        ) * (info && info.sharesNumber ? info.sharesNumber : 0),
                      )}
                  {} phiếu
                </span>
              </p>
              <table
                className="break-page"
                cellPadding={0}
                cellSpacing={0}
                style={{
                  marginLeft: '0',
                  border: '0.75pt solid #000000',
                  borderCollapse: 'collapse',
                }}
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        borderRightStyle: 'solid',
                        borderWidth: '0.75pt',
                        borderStyle: 'solid',
                        verticalAlign: 'top',
                      }}
                    >
                      <p style={{ margin: '5pt', lineHeight: '12pt' }}>
                        <strong>
                          <span
                            style={{ fontFamily: '"times"', fontSize: '12pt' }}
                          >
                            STT
                          </span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        width: '235.8pt',
                        borderStyle: 'solid',
                        borderWidth: '0.75pt',
                        borderLeftStyle: 'solid',
                        borderLeftWidth: '0.75pt',
                        borderBottomStyle: 'solid',
                        borderBottomWidth: '0.75pt',
                        verticalAlign: 'top',
                      }}
                    >
                      <p
                        style={{
                          margin: '5pt',
                          textAlign: 'center',
                          lineHeight: '12pt',
                        }}
                      >
                        <strong>
                          <span
                            style={{ fontFamily: '"times"', fontSize: '12pt' }}
                          >
                            Ứng viên
                          </span>
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                        width: '224.25pt',
                        borderStyle: 'solid',
                        borderWidth: '0.75pt',
                        borderBottomStyle: 'solid',
                        borderBottomWidth: '0.75pt',
                        verticalAlign: 'top',
                      }}
                    >
                      <p
                        style={{
                          margin: '5pt',
                          textAlign: 'center',
                          lineHeight: '12pt',
                        }}
                      >
                        <strong>
                          <span
                            style={{ fontFamily: '"times"', fontSize: '12pt' }}
                          >
                            Số phiếu biểu quyết
                          </span>
                        </strong>
                      </p>
                    </td>
                  </tr>
                  {item.selections.map((itemSelections, indexSelections) => {
                    return (
                      <tr key={indexSelections}>
                        <td
                          style={{
                            borderTopStyle: 'solid',
                            borderTopWidth: '0.75pt',
                            borderRightStyle: 'solid',
                            borderRightWidth: '0.75pt',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '0.75pt',
                            verticalAlign: 'top',
                          }}
                        >
                          <p
                            style={{
                              margin: '5pt',
                              textAlign: 'center',
                              lineHeight: '12pt',
                            }}
                          >
                            <span
                              style={{
                                fontFamily: '"times"',
                                fontSize: '12pt',
                              }}
                            >
                              {indexSelections + 1}
                            </span>
                          </p>
                        </td>
                        <td
                          style={{
                            width: '212.8pt',
                            borderStyle: 'solid',
                            borderWidth: '0.75pt',
                            verticalAlign: 'top',
                          }}
                        >
                          <p style={{ margin: '5pt', lineHeight: '12pt' }}>
                            <span
                              style={{
                                fontFamily: '"times"',
                                fontSize: '12pt',
                              }}
                            >
                              {itemSelections.selectName.split(' - ')[0]}
                            </span>
                          </p>
                        </td>
                        <td
                          style={{
                            width: '224.25pt',
                            borderTopStyle: 'solid',
                            borderTopWidth: '0.75pt',
                            borderLeftStyle: 'solid',
                            borderLeftWidth: '0.75pt',
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '0.75pt',
                            verticalAlign: 'top',
                          }}
                        >
                          <p
                            style={{
                              margin: '5pt',
                              lineHeight: '12pt',
                              textAlign: 'center',
                            }}
                          >
                            <span
                              style={{
                                fontFamily: '"times"',
                                fontSize: '12pt',
                              }}
                            >
                              {itemSelections.amount ||
                              itemSelections.amount === 0
                                ? transformNumberPrinter(itemSelections.amount)
                                : ''}
                            </span>
                          </p>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Fragment>
          );
        })}
      {topicResult3.length > 0 ? (
        <Fragment>
          <p
            style={{
              marginTop: '0pt',
              marginBottom: '0pt',
              lineHeight: '12.2pt',
            }}
          >
            <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
              &nbsp;
            </span>
          </p>
          <p
            style={{ marginTop: '0pt', marginBottom: '10pt', fontSize: '12pt' }}
          >
            <span style={{ fontFamily: '"times"' }}></span>
          </p>
          <table
            cellPadding={0}
            cellSpacing={0}
            style={{
              borderCollapse: 'collapse',
              border: '1pt solid #000',
              marginBottom: '100pt',
            }}
          >
            <tbody>
              <tr style={{ height: '16.45pt', border: '1pt solid #000' }}>
                <td
                  style={{
                    width: '346.2pt',
                    borderTopStyle: 'solid',
                    borderTopWidth: '1pt',
                    borderRightStyle: 'solid',
                    borderRightWidth: '1pt',
                  }}
                >
                  <p
                    style={{
                      margin: '0pt',
                      marginLeft: '87pt',
                      marginBottom: '0pt',
                      lineHeight: '12pt',
                    }}
                  >
                    <strong>
                      <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
                        NỘI DUNG
                      </span>
                    </strong>
                  </p>
                </td>
                <td
                  style={{
                    width: '45.7pt',
                    borderTopStyle: 'solid',
                    borderTopWidth: '1pt',
                    borderRightStyle: 'solid',
                    borderRightWidth: '1pt',
                  }}
                >
                  <p
                    style={{
                      marginTop: '0pt',
                      marginBottom: '0pt',
                      textAlign: 'center',
                      lineHeight: '12pt',
                    }}
                  >
                    <strong>
                      <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
                        Tán <br /> thành
                      </span>
                    </strong>
                  </p>
                </td>
                <td
                  style={{
                    width: '57.45pt',
                    borderTopStyle: 'solid',
                    borderTopWidth: '1pt',
                    borderRightStyle: 'solid',
                    borderRightWidth: '1pt',
                  }}
                >
                  <p
                    style={{
                      marginTop: '5pt',
                      marginBottom: '5pt',
                      textAlign: 'center',
                      lineHeight: '12pt',
                    }}
                  >
                    <strong>
                      <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
                        Không <br />
                        tán <br />
                        thành
                      </span>
                    </strong>
                  </p>
                </td>
                <td
                  style={{
                    width: '57.45pt',
                    borderTopStyle: 'solid',
                    borderTopWidth: '1pt',
                    borderRightStyle: 'solid',
                    borderRightWidth: '1pt',
                  }}
                >
                  <p
                    style={{
                      marginTop: '0pt',
                      marginBottom: '0pt',
                      textAlign: 'center',
                      lineHeight: '12pt',
                    }}
                  >
                    <strong>
                      <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
                        Không có <br /> ý kiến
                      </span>
                    </strong>
                  </p>
                </td>
              </tr>

              {topicResult3.map((item, index) => {
                return (
                  <tr
                    style={{ height: '18.45pt', border: '1pt solid #000' }}
                    key={index}
                  >
                    <td
                      style={{
                        width: '366.9pt',
                        borderStyle: 'solid',
                        borderWidth: '1pt',
                      }}
                    >
                      <p
                        style={{
                          marginTop: '5pt',
                          marginBottom: '5pt',
                          marginLeft: '5pt',
                          lineHeight: '12pt',
                        }}
                      >
                        {item.topic_id ? item.topic_id.name : item.name}
                      </p>
                    </td>
                    <td
                      style={{
                        width: '45.7pt',
                        borderStyle: 'solid',
                        borderWidth: '1pt',
                      }}
                    >
                      <p
                        style={{
                          marginTop: '0pt',
                          marginBottom: '0pt',
                          textAlign: 'center',
                          lineHeight: '12pt',
                        }}
                      >
                        <span
                          style={{ fontFamily: '"times"', fontSize: '12pt' }}
                        >
                          {item.point === 1 ? 'x' : ' '}
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        width: '57.45pt',
                        borderStyle: 'solid',
                        borderWidth: '1pt',
                      }}
                    >
                      <p
                        style={{
                          marginTop: '0pt',
                          marginBottom: '0pt',
                          textAlign: 'center',
                          lineHeight: '12pt',
                        }}
                      >
                        <span
                          style={{ fontFamily: '"times"', fontSize: '12pt' }}
                        >
                          {item.point === 2 ? 'x' : ' '}
                        </span>
                      </p>
                    </td>
                    <td
                      style={{
                        width: '57.45pt',
                        borderStyle: 'solid',
                        borderWidth: '1pt',
                      }}
                    >
                      <p
                        style={{
                          marginTop: '0pt',
                          marginBottom: '0pt',
                          textAlign: 'center',
                          lineHeight: '12pt',
                        }}
                      >
                        <span
                          style={{ fontFamily: '"times"', fontSize: '12pt' }}
                        >
                          {item.point === 3 ? 'x' : ' '}
                        </span>
                      </p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Fragment>
      ) : (
        ''
      )}
      <p
        style={{
          opacity: 0,
          paddingTop: '20pt',
          paddingBottom: '20pt',
          color: 'white',
        }}
      >
        .
      </p>
    </>
  );
};

const ReportForAll = ({ shareholderDetails }) => {
  const conference = useSelector(
    (state) => state.checkCoupon.checkCouponList.conference,
  );
  const info = shareholderDetails ? shareholderDetails.shareholder : {};
  const topic1 = useSelector(
    (state) => state.checkCoupon.checkCouponList.topic1,
  );
  const topic2 = useSelector(
    (state) => state.checkCoupon.checkCouponList.topic2,
  );
  const topic3 = useSelector(
    (state) => state.checkCoupon.checkCouponList.topic3,
  );
  const topic4 = useSelector(
    (state) => state.checkCoupon.checkCouponList.topic4,
  );
  const votes = shareholderDetails ? shareholderDetails.votes : {};
  // const voteKind1 = votes
  //   ? votes.filter((item) => item.topic_id !== null && item.kind === 1)
  //   : [];
  // const voteKind2 = votes
  //   ? votes.filter((item) => item.topic_id !== null && item.kind === 2)
  //   : [];
  // const voteKind3 = votes
  //   ? votes.filter((item) => item.topic_id !== null && item.kind === 3)
  //   : [];
  // const voteKind4 = votes
  //   ? votes.filter((item) => item.topic_id !== null && item.kind === 4)
  //   : [];
  const now = new Date();
  // const topicResult1 = info
  //   ? setResultTopic(topic1, voteKind1, 1, info._id)
  //   : [];
  // const topicResult2 = info
  //   ? setResultTopic(topic2, voteKind2, 2, info._id)
  //   : [];
  // const topicResult3 = info
  //   ? setResultTopic(topic3, voteKind3, 3, info._id)
  //   : [];
  // const topicResult4 = info
  //   ? setResultTopic(topic4, voteKind4, 4, info._id)
  //   : [];

  return (
    <Fragment>
      <div style={{ position: 'relative' }}>
        <style
          dangerouslySetInnerHTML={{
            __html:
              '\n \n\n @media print{html, body {border: 1px solid white;height: 99%;page-break-after: avoid !important;page-break-before: avoid !important;}}  .break-page{ page-break-before: always }  table { page-break-inside:auto } tr{ page-break-inside:avoid; page-break-after:auto }           #print {\n  line-height: 20px;               font-family: times;color: #000\n            }\n\n        ',
          }}
        />
        <p
          style={{
            textAlign: 'center',
            marginTop: '0pt',
            marginBottom: '0pt',
            lineHeight: '12pt',
          }}
        >
          <span
            style={{
              left: '40pt',
              top: '0pt',
              height: '60pt',
              display: 'block',
              position: 'absolute',
            }}
          ></span>
          <strong>
            <span
              style={{
                fontFamily: '"times"',
                fontSize: '12pt',
                textTransform: 'uppercase',
              }}
            >
              {conference.nameCompany}
            </span>
          </strong>
        </p>
        <p
          style={{
            textAlign: 'center',
            marginTop: '0pt',
            marginBottom: '0pt',
            lineHeight: '1.8pt',
          }}
        >
          <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
            &nbsp;
          </span>
        </p>
        <p
          style={{
            textAlign: 'center',
            marginTop: '0pt',
            marginBottom: '0pt',
            lineHeight: '12pt',
          }}
        >
          <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
            Địa chỉ: {conference.address ? conference.address.details : ''},{' '}
            {conference.address ? conference.address.wards : ''},{' '}
            {conference.address ? conference.address.district : ''},{' '}
            {conference.address ? conference.address.province : ''}
          </span>
        </p>
        <p
          style={{
            textAlign: 'center',
            marginTop: '0pt',
            marginBottom: '0pt',
            lineHeight: '1.95pt',
          }}
        >
          <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
            &nbsp;
          </span>
        </p>
        <p
          style={{
            textAlign: 'center',
            marginTop: '0pt',
            marginBottom: '0pt',
            lineHeight: '12.5pt',
          }}
        >
          <span style={{ fontFamily: '"times"', fontSize: '12.5pt' }}>
            ĐT: {conference.phone} - Fax: {conference.fax}
          </span>
        </p>
        <p
          style={{
            marginTop: '0pt',
            marginBottom: '0pt',
            lineHeight: '11.15pt',
          }}
        >
          <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
            &nbsp;
          </span>
        </p>
        <hr style={{ borderBottom: '1pt solid rgb(204, 0, 0)' }} />
        <p
          style={{
            marginTop: '0pt',
            marginBottom: '0pt',
            lineHeight: '11.15pt',
          }}
        >
          <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0pt',
            marginLeft: '0pt',
            marginBottom: '0pt',
            lineHeight: '12pt',
            textAlign: 'right',
          }}
        >
          <em>
            <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
              {conference.addressMeeting
                ? conference.addressMeeting.province
                : ''}
              , ngày {now.getDate().toString().padStart(2, 0)} tháng{' '}
              {(now.getMonth() + 1).toString().padStart(2, 0)} năm{' '}
              {now.getFullYear()}
            </span>
          </em>
        </p>
        <p
          style={{
            marginTop: '0pt',
            marginBottom: '0pt',
            lineHeight: '12.35pt',
          }}
        >
          <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0pt',
            marginRight: '6pt',
            marginBottom: '0pt',
            textAlign: 'center',
            lineHeight: '14pt',
          }}
        >
          <strong>
            <span style={{ fontFamily: '"times"', fontSize: '14pt' }}>
              PHIẾU BIỂU QUYẾT
            </span>
          </strong>
        </p>
        <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: '6pt' }}>
          <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0pt',
            marginRight: '5pt',
            marginBottom: '0pt',
            textAlign: 'center',
            lineHeight: '14pt',
          }}
        >
          <strong>
            <span
              style={{
                fontFamily: '"times"',
                fontSize: '14pt',
                textTransform: 'uppercase',
              }}
            >
              ĐẠI HỘI ĐỒNG CỔ ĐôNG THƯỜNG NIêN NĂM {now.getFullYear()}
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: '0pt',
            marginBottom: '0pt',
            lineHeight: '11.75pt',
          }}
        >
          <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0pt',
            marginLeft: '1pt',
            marginBottom: '0pt',
            lineHeight: '12pt',
          }}
        >
          <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
            Họ và tên Cổ đông: {info ? info.name : ''}
          </span>
        </p>
        <p
          style={{
            marginTop: '0pt',
            marginLeft: '1pt',
            marginBottom: '0pt',
            lineHeight: '12pt',
          }}
        >
          <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
            Mã Cổ đông: {info ? info.code : ''}
          </span>
        </p>
        <p
          style={{
            marginTop: '0pt',
            marginLeft: '1pt',
            marginBottom: '0pt',
            lineHeight: '12pt',
          }}
        >
          <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
            Tổng số lượng cổ phần:{' '}
            {transformNumberPrinter(
              info && info.sharesNumber ? info.sharesNumber : 0,
            )}{' '}
            cổ phần
          </span>
        </p>
        <p
          style={{
            marginTop: '0pt',
            marginBottom: '0pt',
            lineHeight: '8.05pt',
          }}
        >
          <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0pt',
            marginRight: '6pt',
            marginBottom: '0pt',
            textAlign: 'center',
            lineHeight: '12pt',
          }}
        >
          <em>
            <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
              (Quý Đại biểu đánh dấu vào ô ý kiến lựa chọn theo từng Nội dung
              biểu quyết)
            </span>
          </em>
        </p>
        <p
          style={{
            marginTop: '0pt',
            marginBottom: '0pt',
            lineHeight: '12.2pt',
          }}
        >
          <span style={{ fontFamily: '"times"', fontSize: '12pt' }}>
            &nbsp;
          </span>
        </p>
        {shareholderDetails.authorities &&
          shareholderDetails.authorities.length === 0 && (
            <ReportEachPerson
              topicResult1={setResultTopic(
                topic1,
                votes
                  ? votes.filter(
                      (item) => item.topic_id !== null && item.kind === 1,
                      // &&
                      // item.voter === author.value,
                    )
                  : [],
                1,
                info._id,
              )}
              topicResult2={setResultTopic(
                topic2,
                votes
                  ? votes.filter(
                      (item) => item.topic_id !== null && item.kind === 2,
                      // &&
                      // item.voter === author.value,
                    )
                  : [],
                2,
                info._id,
              )}
              topicResult3={setResultTopic(
                topic3,
                votes
                  ? votes.filter(
                      (item) => item.topic_id !== null && item.kind === 3,
                      // &&
                      // item.voter === author.value,
                    )
                  : [],
                3,
                info._id,
              )}
              topicResult4={setResultTopic(
                topic4,
                votes
                  ? votes.filter(
                      (item) => item.topic_id !== null && item.kind === 4,
                      // &&
                      // item.voter === author.value,
                    )
                  : [],
                4,
                info._id,
              )}
              info={info}
              authorityReceiver={undefined}
            />
          )}

        {shareholderDetails.authorities &&
          shareholderDetails.authorities.length > 0 &&
          shareholderDetails.authorities.map((author, index) => {
            return (
              <ReportEachPerson
                key={index}
                topicResult1={setResultTopic(
                  topic1,
                  votes
                    ? votes.filter(
                        (item) =>
                          item.topic_id !== null &&
                          item.kind === 1 &&
                          item.voter === author.authorityFor,
                      )
                    : [],
                  1,
                  info._id,
                )}
                topicResult2={setResultTopic(
                  topic2,
                  votes
                    ? votes.filter(
                        (item) =>
                          item.topic_id !== null &&
                          item.kind === 2 &&
                          item.voter === author.authorityFor,
                      )
                    : [],
                  2,
                  info._id,
                )}
                topicResult3={setResultTopic(
                  topic3,
                  votes
                    ? votes.filter(
                        (item) =>
                          item.topic_id !== null &&
                          item.kind === 3 &&
                          item.voter === author.authorityFor,
                      )
                    : [],
                  3,
                  info._id,
                )}
                topicResult4={setResultTopic(
                  topic4,
                  votes
                    ? votes.filter(
                        (item) =>
                          item.topic_id !== null &&
                          item.kind === 4 &&
                          item.voter === author.authorityFor,
                      )
                    : [],
                  4,
                  info._id,
                )}
                info={info}
                authorityReceiver={{
                  value: author.authorityFor,
                  label: author.name,
                  sharesNumber: author.sharesNumber,
                }}
              />
            );
          })}
        <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '12pt' }}>
          <span style={{ fontFamily: '"times"' }}>&nbsp;</span>
        </p>
        <p
          style={{
            marginTop: '0pt',
            marginRight: '24pt',
            marginBottom: '0pt',
            textAlign: 'right',
            fontSize: '12pt',
          }}
        >
          <strong>
            <span style={{ fontFamily: '"times"' }}>Cổ đông</span>
          </strong>
        </p>
        <p
          style={{
            marginTop: '0pt',
            marginBottom: '0pt',
            textAlign: 'right',
            fontSize: '12pt',
          }}
        >
          <span style={{ fontFamily: '"times"' }}>(Ký, ghi rõ họ tên)</span>
        </p>
        <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '12pt' }}>
          <span style={{ fontFamily: '"times"' }}>&nbsp;</span>
        </p>
        <pre>
          <br clear="all" className="break-page" />
        </pre>
      </div>
    </Fragment>
  );
};
