export const thead_bieu_quyet_valid_all = [
  {name: 'Stt'},
  {name: 'Họ tên'},
  {name: 'CMND/CCCD'},
  {name: 'Cổ phần sở hữu'},
  {name: 'Cổ phần tham dự'},
  {name: 'Lựa chọn'},
];
export const thead_bieu_quyet_common = [
  {name: 'Stt'},
  {name: 'Họ tên'},
  {name: 'CMND/CCCD'},
  {name: 'Cổ phần sở hữu'},
  {name: 'Cổ phần tham dự'},
];
export const thead_bau_cu = [
  {name: 'Stt'},
  {name: 'Họ tên'},
  {name: 'CMND/CCCD'},
  {name: 'Phiếu bầu'},
];
export const thead_bau_cu_notvote = [
  {name: 'Stt'},
  {name: 'Họ tên'},
  {name: 'CMND/CCCD'},
];
export const voteStatusOptions = [
  {
    label: 'Đã biểu quyết',
    value: 'voted',
  },
  {
    label: 'Chưa biểu quyết',
    value: 'notVote',
  },
];
export const voteStatusOptionsEn = [
  {
    label: 'Voted',
    value: 'voted',
  },
  {
    label: 'Not vote',
    value: 'notVote',
  },
];

export const pointOptions = [
  {
    label: 'Tất cả',
    value: 'all',
  },
  {
    label: 'Tán thành',
    value: '1',
  },
  {
    label: 'Không tán thành',
    value: '2',
  },
  {
    label: 'Không ý kiến',
    value: '3',
  },
];
export const pointOptionsEn = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Affirmative',
    value: '1',
  },
  {
    label: 'Nagative',
    value: '2',
  },
  {
    label: 'Abstention',
    value: '3',
  },
];
