import {th} from 'date-fns/locale';
import React from 'react';
import styled from 'styled-components';

const TableResult = ({thead, children}) => {
  return (
    <Styles>
      <div className="table-content">
        <table>
          <thead>
            <tr>
              {thead.map((item, index) => (
                <th key={index} dangerouslySetInnerHTML={{__html: item}}></th>
              ))}
            </tr>
          </thead>
          <tbody>{children}</tbody>
        </table>
      </div>
    </Styles>
  );
};
export default TableResult;
const Styles = styled.div`
  .table-content {
    overflow: auto;
    max-height: 500px;
    ::-webkit-scrollbar {
      width: 6px;
      height: 7px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #afc1da;
      transition: all 0.2s;
      cursor: pointer;
      border-radius: 2px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #95a5be;
    }
    table {
      width: 100%;
      border-collapse: unset;
      border-spacing: 2px;
      thead {
        tr {
          th {
            background: #718098;
            color: #fff;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            color: #ffffff;
            padding: 16px;
            position: relative;
            white-space: nowrap;
            &:last-child {
              &:after {
                display: none;
              }
            }
            &:after {
              content: '';
              display: block;
              position: absolute;
              top: 0;
              left: 100%;
              background: #718098;
              height: 100%;
              width: 2px;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            &.right {
              text-align: right;
            }
            background: #ffffff;
            border: 1px solid #e3e8f5;
            padding: 7px 16px;
            font-size: 14px;
            line-height: 21px;
            color: #333b6a;
            &:first-child {
              text-align: center;
            }
            .common__btn {
              background: #ffffff;
              border: 1px solid #00cb82;
              white-space: nowrap;
              min-width: unset;
              font-size: 14px;
              line-height: 21px;
              color: #00cb82;
              border-radius: 0;
              display: table;
              margin-left: auto;
              margin-right: auto;
              cursor: pointer;
              padding-top: 0%;
              padding-bottom: 0%;
              &:hover {
                color: #fff;
                background: #00cb82;
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .table-content {
      table {
        tbody {
          tr {
            td {
              padding-left: 5px;
              padding-right: 5px;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 479px) {
    .table-content {
      table {
        thead {
          tr {
            th {
              padding-left: 8px;
              padding-right: 8px;
            }
          }
        }
      }
    }
  }
`;
