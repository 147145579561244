import React, { useEffect } from 'react';
import Form from './form';
import TableComponent from './table';
import TableTools from './tableTools';
import { useDispatch, useSelector } from 'react-redux';
import { getInfoConferenceApi } from '../../../../states/duck/pages/admin/shareholderListReducer/actions';

const ShareholderListPage = () => {
  const dispatch = useDispatch();
  const info = useSelector((state) => state.admin.shareholderList.info);
  useEffect(() => {
    dispatch(getInfoConferenceApi());
  }, [dispatch]);
  return (
    <>
      <Form isInvitationDone={info?.isInvitationDone} />
      <TableTools isInvitationDone={info?.isInvitationDone} />
      <TableComponent />
    </>
  );
};
export default ShareholderListPage;
