import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const optionStyle = {
  blue: {
    backgroundHover: '#2F80ED',
    color: '#2F80ED',
    content: 'In phiếu biểu quyết',
    marginRight: '0px',
  },
  orange: {
    backgroundHover: '#F8AD18',
    color: '#F8AD18',
    content: 'Ủy quyền',
    marginRight: '0px',
  },
  green: {
    backgroundHover: '#00CB82',
    color: '#00CB82',
    content: 'Check in',
    marginRight: '10px',
    backgroundColor: 'rgba(0, 203, 130, 0.1)',
  },
  lightGreen: {
    backgroundHover: '#00CB82',
    color: '#00CB82',
    content: 'QR code',
    marginRight: '10px',
    backgroundColor: 'rgba(0, 203, 130, 0.1)',
  },
};

const ButtonCustom = ({ status, onClick, className, content }) => {
  var styleFinal = optionStyle[status];
  const { t } = useTranslation();

  return (
    <SButtonCustom {...styleFinal} onClick={onClick} className={className}>
      {content ? t(content) : t(styleFinal.content)}
    </SButtonCustom>
  );
};
export default ButtonCustom;

const SButtonCustom = styled.button`
  /* width: 100%; */
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : 'white'};
  color: ${(props) => props.color};
  transition: all 0.3s;
  box-shadow: none;
  border-radius: unset;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

  margin-right: ${(props) => props.marginRight};

  &:hover {
    background-color: ${(props) => props.backgroundHover};
    /* box-shadow: 3px 4px 6px 0px rgba(0, 0, 0, 0.3); */
    color: white;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: #9ab1cc !important;
    border: none !important;
    color: white !important;
    box-shadow: unset;
  }
`;
