import styled from 'styled-components';
import { SHotline, SModalEditHotline } from '../hotlinePage';
export const SForm = styled(SHotline)`
  .module-content {
    margin: 0;
  }
  .search-wrapper {
    justify-content: space-between;
    margin: 0;
    gap: 16px;
    .search-item {
      width: 25%;
      padding: 0;
      &.search-button {
        /* width: 174px; */
        width: 225px;
        button {
          border-radius: 0;
        }
      }
    }
  }
  @media only screen and (max-width: 1320px) {
    .search-wrapper {
      justify-content: center;
      .search-item {
        width: 33.33%;
        &.search-button {
          width: 33.33%;
        }
      }
    }
  }
  @media only screen and (max-width: 780px) {
    .search-wrapper {
      .search-item {
        width: 50%;
        &.search-button {
          width: 50%;
        }
      }
    }
  }
  @media only screen and (max-width: 480px) {
    .search-wrapper {
      .search-item {
        width: 100%;
        &.search-button {
          width: 100%;
        }
      }
    }
  }
`;

export const STableTools = styled.div`
  .table-tools {
    /* margin-left: 13px; */
    margin-top: 25px;
    margin-bottom: 8px;
    min-height: 40px;
    display: flex;
    justify-content: space-between;
    .left {
      display: flex;
    }
    .right {
      display: flex;
    }
    button {
      padding: 2px 4px;
      width: 225px;
      /* max-width: 33.33%; */
      height: 40px;
      display: flex;
      justify-content: center;
      box-sizing: border-box;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #718098;
      margin: 0 8px;
      border-radius: 0;
      border: 1px solid #00cb82;
      svg {
        margin-right: 12px;
        path {
          fill: #00cb82;
        }
      }
      &.white-button {
        background: #fff;
      }
      &.upload-btn {
        position: relative;
        input {
          opacity: 0;
          position: absolute;
          width: 100%;
          top: 0;
          left: 0;
          height: 100%;
        }
        .file {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #dfe9f5;
          text-align: center;
          color: #2f80ed;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          background: #f5faff;

          svg {
            position: absolute;
            top: -10px;
            right: -20px;
            cursor: pointer;
          }
        }
      }
    }
  }

  @media screen and (max-width: 605px) {
    .table-tools {
      flex-direction: column;
      margin-left: 0;
      .left {
        order: 2;
        margin-top: 20px;
      }
      .right {
        order: 1;
        gap: 8px;
      }
      button {
        width: 100%;
        font-size: 13px;
        min-height: 40px;
        margin: 0;
      }
    }
  }

  @media screen and (max-width: 479px) {
    .table-tools {
      .right {
        flex-direction: column;
        gap: 8px;
      }
      button {
        flex: 100%;
      }
    }
  }
`;

export const SEditModal = styled(SModalEditHotline)`
  width: 320px;
  .desc {
    color: #333b6a;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    span {
      font-weight: 600;
    }
  }
`;
