import Button from '@components/button';
import { callApi } from '@state-common/index';
import { SwitchContent } from '@styled/admin/timeVotePage';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import styled from 'styled-components';
import ColorComponent from './ColorComponent';
import PNotify from 'pnotify/dist/es/PNotify';
import { getInfoConfigSystemApi } from '@duck/pages/admin/configSystemReducer/actions';

const optionStatus = [
  {
    label: '5',
    value: 5,
  },
  {
    label: '10',
    value: 10,
  },
  {
    label: '15',
    value: 15,
  },
  {
    label: '20',
    value: 20,
  },
  {
    label: '50',
    value: 50,
  },
];
const defaultColor = [
  '#FFF',
  '#5584F1',
  '#E04E3C',
  '#F4BE28',
  '#45A655',
  '#F57420',
  '#5BBBC5',
];
const SettingModal = ({ toggleModal }) => {
  const dispatch = useDispatch();
  const [isDefault, setDefault] = useState(true);
  const [page1, setPage1] = useState(5);
  const [page2, setPage2] = useState(5);
  const [page3, setPage3] = useState(5);
  const [color1, setColor1] = useState('');
  const [colorArray1, setColorAarray1] = useState(defaultColor);
  const [color2, setColor2] = useState('');
  const [colorArray2, setColorAarray2] = useState(defaultColor);
  const [color3, setColor3] = useState('');
  const [colorArray3, setColorAarray3] = useState(defaultColor);
  const [toggleColor, setToggleColor] = useState({ active: false, type: 0 });
  const config = useSelector(
    (state) => state.admin.configSystem.info.homeDisplaySetting,
  );
  useEffect(() => {
    setDefault(config?.isDefaultStyle);
    setPage1(config?.topic1Table);
    setPage2(config?.topic2Table);
    setPage3(config?.topic4Table);
    setColor1(config?.pickedHeaderColor);
    setColor2(config?.pickedBackgroundColor);
    setColor3(config?.pickedTextColor);
    setColorAarray1(config?.headerColors);
    setColorAarray2(config?.backgroundColors);
    setColorAarray3(config?.textColors);
  }, [config]);
  const { t } = useTranslation();
  const close = () => {
    toggleModal(false);
    setPage1(config.topic1Table);
    setPage2(config.topic2Table);
    setPage3(config.topic4Table);
    setColor1(config?.pickedHeaderColor);
    setColor2(config?.pickedBackgroundColor);
    setColor3(config?.pickedTextColor);
    setColorAarray1(config.headerColors);
    setColorAarray2(config.backgroundColors);
    setColorAarray3(config.textColors);
  };
  useEffect(() => {
    if (isDefault) {
      setPage1(config.topic1Table);
      setPage2(config.topic2Table);
      setPage3(config.topic4Table);
      setColorAarray1(config.headerColors);
      setColorAarray2(config.backgroundColors);
      setColorAarray3(config.textColors);
    }
  }, [isDefault]);
  const submitColor = async () => {
    await callApi(
      `system-setting/set-home-styles`,
      'PUT',
      {
        settings: {
          isDefaultStyle: isDefault,
          topic1Table: page1,
          topic2Table: page2,
          topic4Table: page3,
          headerColors: colorArray1,
          pickedHeaderColor: color1,
          backgroundColors: colorArray2,
          pickedBackgroundColor: color2,
          textColors: colorArray3,
          pickedTextColor: color3,
        },
      },
      false,
    )
      .then((res) => {
        PNotify.success({
          title: t('Thành công'),
          text: res.data.message,
          destroy: true,
          delay: 2000,
        });
        setToggleColor(false);
        toggleModal(false);
        dispatch(getInfoConfigSystemApi());
      })
      .catch((err) => {
        PNotify.error({
          title: 'Lỗi',
          text: err.message,
          destroy: true,
          delay: 3000,
        });
      });
  };
  return (
    <Styles>
      <div className="setting-container">
        <div className="toggle-container">
          <SItem>
            <div className="name">{t('Chế độ mặc định')}</div>
            <SwitchContent
              onClick={() => {
                setDefault(true);
              }}
            >
              <span className={`switch ${isDefault ? 'on' : ''}`}></span>
            </SwitchContent>
          </SItem>
        </div>
        <div className="toggle-container">
          <SItem>
            <div className="name">{t('Chế độ tùy chỉnh')}</div>
            <SwitchContent
              onClick={() => {
                setDefault(false);
              }}
            >
              <span className={`switch ${!isDefault ? 'on' : ''}`}></span>
            </SwitchContent>
          </SItem>
          <div className={`custom-container ${isDefault ? '' : 'active'}`}>
            <div className="top">
              <p className="title">
                {t('Số bản ghi hiển thị của từng nội dung')}
              </p>
              <div className="item">
                <p className="desc">{t('Thông qua thủ tục khai mạc')}</p>
                <div className="selection">
                  <Select
                    options={optionStatus}
                    name="role"
                    value={optionStatus.filter(
                      (option) => option.value === page1,
                    )}
                    onChange={(newValue) => setPage1(newValue.value)}
                  />
                </div>
              </div>
              <div className="item">
                <p className="desc">{t('Kết quả kiểm phiếu')}</p>
                <div className="selection">
                  <Select
                    options={optionStatus}
                    name="role"
                    value={optionStatus.filter(
                      (option) => option.value === page2,
                    )}
                    onChange={(newValue) => setPage2(newValue.value)}
                  />
                </div>
              </div>
              <div className="item">
                <p className="desc">{t('Bầu cử')}</p>
                <div className="selection">
                  <Select
                    options={optionStatus}
                    name="role"
                    value={optionStatus.filter(
                      (option) => option.value === page3,
                    )}
                    onChange={(newValue) => setPage3(newValue.value)}
                  />
                </div>
              </div>
            </div>
            <div className="bottom">
              <p className="title">{t('Màu sắc hiển thị')}</p>
              <div className="item-container">
                <p className="desc">{t('Cài đặt màu header bảng')}</p>
                <div className="items">
                  {colorArray1.map((i, v) => {
                    return (
                      <div
                        className={`${
                          colorArray1[v] === color1 ? 'active' : ''
                        }`}
                      >
                        <div
                          className={`color-block`}
                          style={{ background: i }}
                          onClick={() => setColor1(i)}
                        ></div>
                      </div>
                    );
                  })}
                  <button
                    onClick={() => setToggleColor({ active: true, type: 1 })}
                    className="btn-color"
                  >
                    <ColorSVG />
                    <span>{t('Bảng màu')}</span>
                  </button>
                </div>
              </div>
              <div className="item-container">
                <p className="desc">{t('Cài đặt màu nền bảng')}</p>
                <div className="items">
                  {colorArray2.map((i, v) => {
                    return (
                      <div
                        className={`${
                          colorArray2[v] === color2 ? 'active' : ''
                        }`}
                      >
                        <div
                          className={`color-block `}
                          style={{ background: i }}
                          onClick={() => setColor2(i)}
                        ></div>
                      </div>
                    );
                  })}
                  <button
                    onClick={() => setToggleColor({ active: true, type: 2 })}
                    className="btn-color"
                  >
                    <ColorSVG />
                    <span>{t('Bảng màu')}</span>
                  </button>
                </div>
              </div>
              <div className="item-container">
                <p className="desc">{t('Cài đặt màu chữ hiển thị')}</p>
                <div className="items">
                  {colorArray3.map((i, v) => {
                    return (
                      <div
                        className={`${
                          colorArray3[v] === color3 ? 'active' : ''
                        }`}
                      >
                        <div
                          className={`color-block `}
                          style={{ background: i }}
                          onClick={() => setColor3(i)}
                        ></div>
                      </div>
                    );
                  })}
                  <button
                    onClick={() => setToggleColor({ active: true, type: 3 })}
                    className="btn-color"
                  >
                    <ColorSVG />
                    <span>{t('Bảng màu')}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="btn-group">
          <Button
            style={{ background: '#DFE9F5', color: '#718098' }}
            onClick={() => close()}
          >
            {t('Hủy bỏ')}
          </Button>
          <Button
            onClick={() => {
              submitColor();
            }}
          >
            {t('Cập nhật')}
          </Button>
        </div>
      </div>

      {toggleColor.active === true && (
        <ColorComponent
          setColor={
            toggleColor.type === 1
              ? setColor1
              : toggleColor.type === 2
              ? setColor2
              : setColor3
          }
          array={
            toggleColor.type === 1
              ? colorArray1
              : toggleColor.type === 2
              ? colorArray2
              : colorArray3
          }
          action={
            toggleColor.type === 1
              ? setColorAarray1
              : toggleColor.type === 2
              ? setColorAarray2
              : setColorAarray3
          }
          setToggleColor={setToggleColor}
        />
      )}
    </Styles>
  );
};
export default SettingModal;

const Styles = styled.div`
  position: relative;
  .active {
    border: 1px solid #000;
  }
  p {
    margin: 0;
  }
  .btn-group {
    width: 100%;
    display: flex;
    gap: 16px;
    margin-top: 24px;
    align-items: center;
    justify-content: center;
  }
  .title {
    color: var(--content-neutral-1, #333b6a);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    margin: 0;
  }
  .desc {
    width: 100%;
    color: var(--content-neutral-1, #333b6a);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 187.5% */
  }
  .color-block {
    cursor: pointer;
    width: 12px;
    height: 12px;
    border-radius: 2px;
    border: 1px solid var(--00-cb-82, #00cb82);
    background: #fff;
  }
  .setting-container {
    width: 100%;
    padding: 16px 16px 0 16px;
    .toggle-container {
      width: 100%;
      padding: 8px 16px;
      .name {
        color: var(--content-neutral-1, #333b6a);
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px; /* 187.5% */
      }
    }
  }

  .custom-container {
    display: none;
    margin-top: 16px;
    padding-top: 16px;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 8px;
    border: 1px solid #dfe9f5 !important;
    .top {
      .item {
        margin-top: 8px;
        margin-bottom: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: all 0.25s linear;
        .selection {
          width: 65px;
        }
      }
    }
    .bottom {
      margin-top: 20px;
      display: flex;
      gap: 8px;
      flex-direction: column;
      .item-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .items {
          width: 100%;
          display: flex;
          gap: 8px;
          align-items: center;
          .btn-color {
            /* width: 91px; */
            white-space: nowrap;
            border-radius: 4px;
            background: var(--dfe-9-f-5, #dfe9f5);
            padding: 2px 8px;
            display: flex;
            gap: 4px;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            span {
              color: var(--content-neutral-1, #333b6a);
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%; /* 18px */
            }
          }
        }
      }
    }
  }
  .active {
    display: block;
  }
`;
const SItem = styled.div`
  background-color: #fff;
  width: 100%;
  padding: 8px 16px;
  border: 1px solid #dfe9f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .name {
    font-size: 14px;
    line-height: 24px;
    color: #333b6a;
  }

  @media screen and (max-width: 767px) {
    width: calc(100% / 2 - 20px);
  }
`;

const ColorSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M2.66676 10.5067L2.00009 13.1734C1.9763 13.2605 1.97047 13.3516 1.98297 13.4411C1.99547 13.5306 2.02603 13.6166 2.07279 13.6939C2.11955 13.7712 2.18154 13.8383 2.255 13.8909C2.32845 13.9435 2.41184 13.9806 2.50009 14C2.60729 14.0271 2.71955 14.0271 2.82676 14L5.49342 13.3334C5.61172 13.3052 5.72007 13.2453 5.80676 13.16L11.3334 7.60669L12.1934 8.47336L13.1401 7.5267L12.2734 6.6667L14.0001 4.94003C14.2484 4.69021 14.3878 4.35228 14.3878 4.00003C14.3878 3.64778 14.2484 3.30985 14.0001 3.06003L12.9401 2.00003C12.6903 1.75169 12.3523 1.6123 12.0001 1.6123C11.6478 1.6123 11.3099 1.75169 11.0601 2.00003L9.33342 3.7267L8.46676 2.86003L7.52009 3.80669L8.38676 4.6667L2.86009 10.1934C2.76751 10.2776 2.70051 10.3862 2.66676 10.5067ZM3.91342 11.0067L9.33342 5.61336L10.3868 6.6667L4.99342 12.0667L3.58009 12.42L3.91342 11.0067Z"
      fill="#718098"
    />
  </svg>
);
