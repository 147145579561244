import * as constants from './constants';
import { callApi, callDownloadApi, callExportApi } from '../../../../common';
import PNotify from 'pnotify/dist/es/PNotify';
import { handleError } from '@configs/handleError';
var FileSaver = require('file-saver');

export const getUserApi = () => {
  return (dispatch) =>
    callApi(`${constants.URL}`, 'GET', null).then(function (res) {
      dispatch(getListAccount(res.data.data));
      dispatch(getTotal(res.data.total));
      dispatch(checkCheckin(false));
    });
};

export const getAuthorizedApi = () => {
  return (dispatch) =>
    callApi(`${constants.URL_AUTHORIZED}`, 'GET', null).then(function (res) {
      dispatch(getListAuthorized(res.data.data));
      dispatch(setChooseList([]));
      // dispatch(checkCheckin(false));
    });
};
export const getSearchApi = (body) => {
  const {
    identityNumber,
    joinType,
    status,
    pageSize,
    pageNumber,
    isCheckedIn,
    order,
    sortBy,
  } = body;
  return (dispatch) =>
    callApi(`${constants.URL_SEARCH}/${pageSize}/${pageNumber}`, 'POST', {
      identityNumber,
      joinType,
      status,
      isCheckedIn,
      order,
      sortBy,
    }).then(function (res) {
      dispatch(getListAccount(res.data.data.shareholders));
      dispatch(getTotal(res.data.data.total));
      dispatch(checkCheckin(false));
      dispatch(setFilter(body));
    });
};

export const getAuthorityItemApi = (identityNumber) => {
  return (dispatch) =>
    callApi(`${constants.URL_SEARCH}/${1}/${1}`, 'POST', {
      identityNumber,
      joinType: 'all',
      status: 'all',
    }).then(function (res) {
      dispatch(getAuthorityItem(res.data.data.shareholders));
    });
};

export const checkInApi = (id, body) => {
  return (dispatch) =>
    callApi(`${constants.URL_CHECKIN}/${id}`, 'POST', body)
      .then(function (res) {
        dispatch(checkCheckin(true));

        PNotify.success({
          title: 'Thành công',
          text: 'Checkin thành công',
          destroy: true,
          delay: 2000,
        });
      })
      .catch(function (err) {
        if (err) {
          if (err.response && err.response.data && err.response.data.message) {
            PNotify.error({
              title: 'Lỗi',
              text: err.response.data.message,
              destroy: true,
              delay: 2000,
            });
          } else if (err.response) {
            PNotify.error({
              title: 'Lỗi',
              text: err.response.statusText,
              destroy: true,
              delay: 2000,
            });
          }
        }
      });
};

export const checkInAuthorityApi = (
  reloadAfterAutho,
  setReloadAfterAutho,
  onClick,
  body,
) => {
  return (dispatch) =>
    callApi(`${constants.URL_AUTHORITY_CREATE}`, 'POST', body)
      .then(function (res) {
        dispatch(checkCheckin(true));
        dispatch(getAuthorityItemApi(body.identityNumber));
        setReloadAfterAutho(!reloadAfterAutho);
        onClick();

        PNotify.success({
          title: 'Thành công',
          text: 'Uỷ quyền thành công',
          destroy: true,
          delay: 2000,
        });
      })
      .catch(function (err) {
        console.log('err', err);
        // if (err) {
        //   if (err.response && err.response.data && err.response.data.message) {
        //     PNotify.error({
        //       title: 'Lỗi',
        //       text: err.response.data.message,
        //       destroy: true,
        //       delay: 2000,
        //     });
        //   } else if (err.response) {
        //     PNotify.error({
        //       title: 'Lỗi',
        //       text: err.response.statusText,
        //       destroy: true,
        //       delay: 2000,
        //     });
        //   }
        // }
      });
};

export const getReport1Api = (body) => {
  return (dispatch) =>
    callApi(`${constants.URL_REPORT}/${body}`, 'GET', null).then(function (
      res,
    ) {
      dispatch(getReport1(res.data.topics));
    });
};

export const getReport2Api = (body) => {
  return (dispatch) =>
    callApi(`${constants.URL_REPORT}/${body}`, 'GET', null).then(function (
      res,
    ) {
      dispatch(getReport2(res.data.topics));
    });
};

export const getReport3Api = (body) => {
  return (dispatch) =>
    callApi(`${constants.URL_REPORT}/${body}`, 'GET', null).then(function (
      res,
    ) {
      dispatch(getReport3(res.data.topics));
    });
};

export const getReport4Api = (body) => {
  return (dispatch) =>
    callApi(`${constants.URL_REPORT}/${body}`, 'GET', null).then(function (
      res,
    ) {
      dispatch(getReport4(res.data.topics));
    });
};
export const getReport6Api = (body) => {
  return (dispatch) =>
    callApi(`${constants.URL_REPORT}/${body}`, 'GET', null).then(function (
      res,
    ) {
      dispatch(getReport6(res.data.topics));
    });
};
export const getConferenceInfoApi = () => {
  return (dispatch) =>
    callApi(`${constants.URL_CONFERENCE_INFO}`, 'GET', null).then(function (
      res,
    ) {
      dispatch(getConferenceInfo(res.data.conference));
    });
};

export const infoShareholderApi = (body) => {
  return (dispatch) =>
    callApi(`${constants.URL_VOTE_INFO}/${body}`, 'GET', null)
      .then(function (res) {
        dispatch(getInfoShareholder(res.data.data));
      })
      .catch(function (err) {
        if (err) {
          if (err.response && err.response.data && err.response.data.message) {
            PNotify.error({
              title: 'Lỗi',
              text: err.response.data.message,
              destroy: true,
              delay: 2000,
            });
          } else if (err.response) {
            PNotify.error({
              title: 'Lỗi',
              text: err.response.statusText,
              destroy: true,
              delay: 2000,
            });
          }
        }
      });
};

export const getQrCodeApi = (body) => {
  return (dispatch) =>
    callApi(`${constants.URL_QR_CODE}/${body}`, 'GET', null)
      .then(function (res) {
        dispatch(setQrCode(res.data.data));
      })
      .catch(function (err) {
        if (err) {
          if (err.response && err.response.data && err.response.data.message) {
            PNotify.error({
              title: 'Lỗi',
              text: err.response.data.message,
              destroy: true,
              delay: 2000,
            });
          } else if (err.response) {
            PNotify.error({
              title: 'Lỗi',
              text: err.response.statusText,
              destroy: true,
              delay: 2000,
            });
          }
        }
      });
};

export const exportLoginAccountApi = (body) => {
  return (dispatch) =>
    callExportApi(
      `${constants.URL_EXPORT_LOGIN_ACCOUNT}/${body}`,
      'GET',
      null,
    ).then(function (res) {
      let fileURL = window.URL.createObjectURL(res.data);
      let tab = window.open();
      tab.location.href = fileURL;
    });
};

export const downloadVoteTicketApi = (body) => {
  return (dispatch) =>
    callExportApi(`${constants.URL_DOWNLOAD_VOTE}`, 'POST', body)
      .then(function (res) {
        let fileURL = window.URL.createObjectURL(res.data);
        let tab = window.open();
        tab.location.href = fileURL;
      })
      .catch(handleError);
};

export const getReport1 = (value) => {
  return {
    type: constants.GET_REPORT1,
    value,
  };
};
export const getReport2 = (value) => {
  return {
    type: constants.GET_REPORT2,
    value,
  };
};
export const getReport3 = (value) => {
  return {
    type: constants.GET_REPORT3,
    value,
  };
};
export const getReport4 = (value) => {
  return {
    type: constants.GET_REPORT4,
    value,
  };
};
export const getReport6 = (value) => {
  return {
    type: constants.GET_REPORT6,
    value,
  };
};
export const getConferenceInfo = (value) => {
  return {
    type: constants.GET_CONFERENCE_INFO,
    value,
  };
};
export const getInfoShareholder = (value) => {
  return {
    type: constants.GET_INFO_SHAREHOLDER,
    value,
  };
};
export const getAuthorityItem = (value) => {
  return {
    type: constants.GET_AUTHORITY_ITEM,
    value,
  };
};

export const getListAccount = (value) => {
  return {
    type: constants.GET_LIST,
    value,
  };
};

export const getListAuthorized = (value) => {
  return {
    type: constants.GET_LIST_AUTHORIZED,
    value,
  };
};

export const getTotal = (value) => {
  return {
    type: constants.GET_TOTAL,
    value,
  };
};

export const checkCheckin = (value) => {
  return {
    type: constants.GET_CHECK,
    value,
  };
};

// export const getIdCard = (value) => {
//   return {
//     type: constants.GET_ID_CARD,
//     value,
//   };
// };

// export const getStatus = (value) => {
//   return {
//     type: constants.GET_STATUS,
//     value,
//   };
// };
export const setFilter = (value) => {
  return {
    type: constants.SET_FILTER,
    value,
  };
};

export const setToggleModal = (value) => {
  return {
    type: constants.SET_TOGGLE_MODAL,
    value,
  };
};

export const setChooseList = (value) => {
  return {
    type: constants.SET_CHOOSE_LIST,
    value,
  };
};

export const setQrCode = (value) => {
  return {
    type: constants.GET_QR_CODE,
    value,
  };
};
