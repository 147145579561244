import Button from '@components/button';
import { SRadio } from '@components/input/Radio';
import { SModalContent } from '@components/modal';
import { StylesTable } from '@components/table';
import styled from 'styled-components';

export const SReportPage = styled.div`
  .methods {
    display: flex;
    align-items: center;
    margin-bottom: -10px;
    padding-bottom: 32px;
    flex-wrap: wrap;
    justify-content: center;
    ${SRadio} {
      margin-right: 80px;
      margin-bottom: 10px;
    }
  }

  ${StylesTable} {
    tr {
      td {
        ${Button} {
          width: auto;
          margin: 0 4px;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          background-color: white;
          padding: 3px 12px;
          transition: 0s;
          white-space: nowrap;
          height: auto;
          border-radius: 0;
          border: 1px solid;

          &.ban {
            border-color: #ff6c6c;
            color: #ff6c6c;
            &:not(:disabled):hover {
              color: white;
              background-color: #ff6c6c;
            }
          }

          &.close {
            color: #718098;
            border-color: #718098;
            &:not(:disabled):hover {
              color: white;
              background-color: #718098;
            }
          }
        }
      }
    }
  }
`;

export const SForm = styled.form`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 1fr);
  gap: 0 32px;
  @media (max-width: 991px) {
    gap: 0 15px;
  }
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
  .group-button {
    grid-area: 3 / 1 / 4 / 3;
    text-align: center;
    padding-top: 22px;
    & > button {
      margin-left: 16px;
      margin-bottom: 8px;
    }
  }
  .item1 {
    grid-area: 1 / 1 / 2 / 2;
  }
  .item2 {
    grid-area: 1 / 2 / 2 / 3;
  }
  .item3 {
    grid-area: 2 / 1 / 3 / 2;
  }
  .item4 {
    grid-area: 2 / 2 / 3 / 3;
  }
  .form__item {
    text-align: center;
    margin-bottom: 20px;
  }
`;

export const SSelections = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
  padding: 20px;

  background-color: white;
  border: 1px solid #dfe9f5;
  box-sizing: border-box;
  border-radius: 2px;

  @media (max-width: 575px) {
    flex-direction: column;
  }

  .selection {
    &__title {
      font-size: 16px;
      line-height: 24px;
      color: #333b6a;
      margin-right: 12px;
      margin-bottom: 12px;
      min-width: 144px;
      margin-top: 8px;
    }
    &__form {
      flex-grow: 1;
      display: grid;
      gap: 0 16px;
      grid-template-columns: 1fr 1fr 1fr;
      @media (max-width: 1199px) {
        grid-template-columns: 1fr;
      }
    }
  }
`;

export const SSelectionItem = styled.div`
  background: #718098;
  border-radius: 2px;
  height: 32px;
  width: 100%;
  position: relative;
  padding: 6px 36px 6px 12px;
  overflow: hidden;
  margin-bottom: 4px;

  & > .label {
    display: inline-block;
    overflow: hidden;
    font-size: 14px;
    line-height: 21px;
    color: white;
    text-overflow: ellipsis;
    max-width: calc(100%);
    white-space: nowrap;
  }

  & > svg {
    position: absolute;
    right: 12px;
    top: 8px;
    cursor: pointer;
  }
`;

export const SSortModal = styled(SModalContent)`
  ${StylesTable} {
    tr {
      th {
        &:first-child {
          text-align: left;
        }
      }
      td {
        font-size: 14px;
        line-height: 21px;
        border-color: #dfe9f5;
        text-align: left;
        &:first-child {
          background: #f5faff;
        }
        &:nth-child(2) {
          padding-top: 0;
          padding-bottom: 0;
          input {
            height: 38px;
          }
        }
        input {
          border: none;
          text-align: center;
          margin: 0;
        }
      }
    }
  }
`;

export const SDisableHolderModal = styled(SModalContent)`
  .form {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 31px 71px;
    padding-bottom: 11px;
    border: 1px solid #dfe9f5;
    gap: 0 24px;
    margin-bottom: 32px;
    @media (max-width: 991px) {
      padding: 20px;
      padding-bottom: 0;
    }
    @media (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;
