export const URL_SETTING = 'topic/setting-time';
export const URL_INFO = 'conference';
export const URL_TOGGLE_TOPIC = 'topic/part/toggle';
export const URL_TOGGLE_TOPIC2 = 'topic/toggle';
export const URL_TOPIC_INFO = 'topic/part';
export const URL_TOPIC1_LIST = 'info/table/sheet-1?take=50';
export const URL_TOPIC2_LIST = 'info/table/sheet-2?take=50';
export const URL_TOPIC3_LIST = 'info/table/sheet-3?take=50';
export const URL_TOPIC4_LIST = 'info/table/sheet-4?take=50';
export const URL_TOPIC6_LIST = 'info/table/sheet-6?take=50';

export const URL_GET_TIME_SETTING = 'topic/list-kind-time';
export const URL_SETTING_TIME_COMMENT =
  'conference/setting-time-submit-comment';
export const URL_TOGGLE_COMMENT = 'conference/toggle-submit-comment';
// -----
export const URL_GET_TIME_CONFERENCE = 'topic/setting-time-conference'; //GET
export const URL_SETTING_TIME_CONFERENCE = 'topic/setting-time-conference'; //POST

export const GET_LIST = 'GET_TIME_VOTE_LIST';
export const GET_TIME_SETTING = 'GET_TIME_VOTE_TIME_SETTING';
export const SET_TIME_CONFERENCE = 'SET_TIME_CONFERENCE';

export const GET_LIST_TOPIC_1 = 'GET_TIME_VOTE_LIST_TOPIC_1';
export const GET_LIST_TOPIC_2 = 'GET_TIME_VOTE_LIST_TOPIC_2';
export const GET_LIST_TOPIC_3 = 'GET_TIME_VOTE_LIST_TOPIC_3';
export const GET_LIST_TOPIC_4 = 'GET_TIME_VOTE_LIST_TOPIC_4';
export const GET_LIST_TOPIC_6 = 'GET_TIME_VOTE_LIST_TOPIC_6';

export const GET_INFO = 'GET_TIME_VOTE_INFO';
export const GET_TOPIC_PART_INFO = 'GET_TIME_VOTE_TOPIC_PART_INFO';
export const TOGGLE_MODAL = 'TOGGLE_TIME_VOTE_MODAL';
