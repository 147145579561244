//check coupon manual

import styled from 'styled-components';
import {SHotline} from '@styled/admin/hotlinePage';
import Button from '@components/button';
export const SCheckCouponManual = styled(SHotline)`
  padding-bottom: 10px;
`;

export const SButtonCopy = styled(Button)`
  margin: 0 auto;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #00cb82;
  background: white;
  border: 1px solid #00cb82;
  border-radius: 0;
  height: 28px;
  width: 140px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  &:hover {
    color: white;
    background: #00cb82;
  }
  &.have_copied {
    width: 140px;
    color: #718098;
    border: 1px solid #718098;
    cursor: not-allowed;
    box-shadow: none;
    &:hover {
      color: #718098;
      background: white;
    }
  }
`;

export const SModalCopyCoupon = styled.div`
  padding: 44px 56px;
  padding-bottom: 0;
  .info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 -8px;
    margin-bottom: 32px;
    .info-item {
      width: 25%;
      padding: 0 8px;
      .invalid-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background: #eaf1fa;
        border: 1px dashed #ff6c6c;
        color: #ff6c6c;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        height: 40px;
        &.active {
          background: #ff6c6c;
          color: #eaf1fa;
          cursor: no-drop;
          pointer-events: none;
        }
      }
    }
  }
  .copy_vote-container {
    border: 1px solid #dfe9f5;
    background: #eaf1fa;
    padding: 0 68px;
    padding-top: 32px;
  }
  .disabled-btn {
    cursor: no-drop;
    pointer-events: none;
    background: #718098;
  }
  @media only screen and (max-width: 991px) {
    padding: 44px 20px;
    padding-bottom: 0;
    .info {
      .info-item {
        width: 50%;
        margin-bottom: 10px;
      }
    }
    .copy_vote-container {
      padding: 0 20px;
      padding-top: 30px;
    }
  }
  @media only screen and (max-width: 479px) {
    padding: 30px 15px;
    padding-bottom: 0;
    .info {
      .info-item {
        width: 100%;
      }
    }
    .copy_vote-container {
      padding-top: 20px;
    }
  }
`;

export const SItemCopyCoupon = styled.div`
  margin-bottom: 32px;
  .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    color: #333b6a;
    margin-bottom: 10px;
  }
  .content {
  }
  @media only screen and (max-width: 768px) {
    .title {
      font-size: 17px;
    }
  }
`;
export const SSelectionItem = styled.div`
  margin-bottom: 20px;
  background: #ffffff80;
  padding: 32px 32px 0;
  border: 1px solid #dfe9f5;
  .top {
    .title {
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
    }
    .elector-container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 0 60px;

      .column {
        display: flex;
        justify-content: flex-start;
        padding: 0 50px;
        width: 50%;
        align-items: center;
        input {
          border: 1px solid #dfe9f5;
          background: white;
          border-radius: 0;
          height: 40px;
          width: 260px;
          max-width: 100%;
          color: #333b6a;
          font-size: 14px;
          padding: 10px 16px;
          &::placeholder {
            font-size: 13px;
          }
        }
      }
    }
  }
  .middle {
    padding: 16px 60px;
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #dfe9f5;
    border-bottom: 1px solid #dfe9f5;
    .column {
      padding: 0 50px;
      width: 50%;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #333b6a;
    }
  }
  .bottom {
    padding: 10px;
    text-align: center;
  }
  @media only screen and (max-width: 991px) {
    .top {
      .title {
        font-size: 16px;
      }
      .elector-container {
        padding: 0 20px;
        .column {
          padding: 0 20px;
          flex-wrap: wrap;
        }
      }
    }
    .middle {
      padding: 10px 20px;
      .column {
        padding: 0 20px;
        width: 50%;
        font-size: 15px;
      }
    }
  }
  @media only screen and (max-width: 550px) {
    .top {
      .title {
        font-size: 16px;
      }
      .elector-container {
        padding: 0;
        .column {
          width: 100%;
          input {
            margin-top: 10px;
          }
        }
      }
    }
    .middle {
      padding: 10px 0;
      .column {
        width: 100%;
      }
    }
  }
`;
export const SModalConfirm = styled.div`
  color: #718098;
  padding: 0 44px;
  .confirm { 
    margin-top: 20px; 
  }
  .header {
    font-size: 18px; 
    margin: 30px auto 15px;
    font-weight: 500;
  }
  .list {
    div {
      margin-top: 7px;
    }
  }
  .button-container {
    display: flex;
    justify-content: center;
    button {
      margin: 30px 10px 0;
    }
  }
`;
