// import {URL_RESULT} from '@state-common/constants';
import { publicUrl } from '@configs/publicUrl';
import React, { useRef } from 'react';
import styled from 'styled-components';
import icons from './icons';

export default function DropFile({
  file = null,
  onChange,
  label,
  accept = ['.pdf'],
  handleDelete,
}) {
  const refFileInput = useRef(null);

  const disableInput = () => {
    if (refFileInput && refFileInput.current) {
      refFileInput.current.disabled = true;
      setTimeout(() => {
        refFileInput.current.disabled = false;
      }, 100);
    }
  };

  const href = file && file.includes('uploads/') ? publicUrl(file) : file;

  const onPreview = () => {
    if (href) {
      disableInput();
      window.open(href, '_blank').focus();
    }
  };

  return (
    <SDropFile>
      <input
        type="file"
        ref={refFileInput}
        accept={accept.join(', ')}
        style={{ display: 'none' }}
        onChange={onChange}
      />
      <div className="placeholder">
        {!file && (
          <>
            <img
              src="/images/upload-placeholder.png"
              alt="file"
              width={46}
              height={46}
            />
            <span>{label}</span>
          </>
        )}
      </div>

      {file && (
        <div className="preview">
          <div className="preview__item">
            <div
              className="preview__delete"
              onClick={() => {
                disableInput();
                handleDelete();
                refFileInput.current.value = '';
              }}
            >
              {icons.deleteBtn}
            </div>
            <img
              src="/images/pdf-placeholder.png"
              alt="file"
              width="40"
              height="40"
            />
            <span className="file" onClick={onPreview}>
              {file}
            </span>
          </div>
        </div>
      )}

      <div className={`upload-icon ${file ? 'active' : ''}`}>
        {icons.upload}
      </div>
    </SDropFile>
  );
}

const SDropFile = styled.label`
  width: 100%;
  height: 140px;
  display: inline-block;
  /* max-width: 465px; */
  min-width: 200px;
  border: 1px solid #dfe9f5;
  background-color: white;
  position: relative;
  cursor: pointer;
  margin-bottom: 22px;

  .placeholder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    & > span {
      font-size: 14px;
      line-height: 16px;
      color: #718098;
      margin-top: 12px;
    }
  }

  .preview {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    overflow: hidden;

    padding: 28px 20px;

    &__item {
      width: 100%;
      position: relative;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f5faff;
      padding: 20px;
      .file {
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
        overflow: hidden;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #2f80ed;
        margin-left: 20px;
      }
      &:hover {
        .file {
          text-decoration: underline;
        }
      }
      .preview__delete {
        position: absolute;
        top: -12px;
        right: -12px;
      }
    }
  }

  .upload-icon {
    position: absolute;
    bottom: -28px;
    left: calc(50% - 22px);
    z-index: 3;
    &:hover {
      & > svg > circle {
        fill: #00cb82;
      }
    }
    &.active {
      & > svg > circle {
        fill: #00cb82;
      }
    }
  }
`;
