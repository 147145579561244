import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LoginAdmin from './loginAdmin';

import Language from '@components/language';
import styled from 'styled-components';

const LoginPage = ({ match }) => {
  const { t } = useTranslation();
  const token = useSelector((state) => state.login.token);
  const params = match.params.id ? match.params.id : '';
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (token) history.push('/');

    document.getElementById('root').classList.add('login');
    return () => {
      document.getElementById('root').classList.remove('login');
    };
  }, [token]);

  return (
    <Styles>
      <div className="login-left">
        <div>
          <img src="/images/bvote_logo.png" />
        </div>
        <div className="login-form">
          <div className="header"> {t('đăng nhập')} </div>
          <LoginAdmin typeLogin={params} />
        </div>
        <div className="login-footer">
          Copyright© 2024 <span> BVOTE </span>. All rights reserved.
        </div>
      </div>
      <div className="login-right">
        <div className="header">
          <div className="header-1">{t('GIẢI PHÁP TỔ CHỨC')}</div>
          <div className="header-solution">{t('ĐẠI HỘI CỔ ĐÔNG')}</div>
        </div>
        <div className="banner-desc">{t('login_desc_message')}</div>
      </div>
    </Styles>
  );
};
export default LoginPage;

const Styles = styled.div`
  line-height: 1.5;
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 100vh;

  .login-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 32px;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 0px 32px rgba(166, 176, 207, 0.2);
    padding: 56px 20px 20px;
  }

  .login-form {
    width: 460px;
    max-width: 100%;
    .header {
      font-size: 32px;
      font-weight: 700;
    }

    input {
      top: 22px;
    }
  }

  .login-footer {
    color: #718098;
    span {
      color: #00cb82;
    }
  }

  .login-right {
    background: url('/images/login/login_banner.png');
    background-size: cover;
    /* background-position: bottom right; */
    padding: 72px 140px;
    text-align: center;
    color: white;
    .header {
      .header-1 {
        font-size: 32px;
        line-height: 36px;
      }

      .header-solution {
        font-size: 44px;
        font-weight: 700;
        margin-bottom: 12px;
      }
    }

    .banner-desc {
      font-weight: 500;
      font-size: 16px;
    }
  }

  @media only screen and (max-width: 1400px) {
    .login-right {
      padding: 72px;
    }
  }

  @media only screen and (max-width: 1199px) {
    .login-right {
      padding: 40px;
      .header {
        .header-1 {
          font-size: 28px;
        }

        .header-solution {
          font-size: 32px;
        }
      }
    }
  }

  @media only screen and (max-width: 991px) {
    grid-template-columns: 1fr;

    .login-right {
      display: none;
    }
  }

  @media only screen and (max-width: 480px) {
    .login-left {
      margin: 15px;

      .login-form {
        width: 100%;
      }
    }
  }
`;
