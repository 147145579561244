import styled from 'styled-components';
import inputIcons from '../icons/input';
import React from 'react';
import Checkbox from './Checkbox';
import { useTranslation } from 'react-i18next';

//type: edit, lock, unlock, delete, sort, select, approve, refuse

export default function HandleButton({
  type,
  action,
  className = '',
  select = false,
  tooltip = '',
}) {
  const { t } = useTranslation();
  return (
    <SHandle
      onClick={action}
      className={
        type === 'lock' ||
        type === 'unlock' ||
        type === 'lock_modal' ||
        type === 'un_lock' ||
        type === 'delete' ||
        type === 'refuse'
          ? ` ${className} warning`
          : className
      }
    >
      {type === 'add' ? (
        inputIcons.addModal
      ) : type === 'edit' ? (
        inputIcons.edit
      ) : type === 'lock_modal' ? (
        inputIcons.lock
      ) : type === 'un_lock' ? (
        inputIcons.unlock
      ) : type === 'delete' ? (
        inputIcons.delete
      ) : type === 'sort' ? (
        inputIcons.sort
      ) : type === 'permissions' ? (
        inputIcons.accountpermiss
      ) : type === 'select' ? (
        <Checkbox checked={select} className="handle-select" />
      ) : type === 'approve' ? (
        inputIcons.approve
      ) : type === 'refuse' ? (
        inputIcons.refuse
      ) : undefined}
      {type !== 'select' && (
        <div
          className={
            tooltip === 'Edit phone number' ? 'wide tooltip' : 'tooltip'
          }
        >
          {tooltip !== ''
            ? tooltip
            : type === 'add'
            ? t('Thêm')
            : type === 'edit'
            ? t('Sửa')
            : type === 'lock'
            ? t('Chặn')
            : type === 'unlock'
            ? t('Bỏ chặn')
            : type === 'lock_modal'
            ? t('Khóa')
            : type === 'un_lock'
            ? t('Mở Khóa')
            : type === 'delete'
            ? t('Xóa')
            : type === 'sort'
            ? t('Sắp xếp')
            : type === 'approve'
            ? t('Duyệt')
            : type === 'refuse'
            ? t('Từ chối')
            : type === 'permissions'
            ? t('Phân quyền')
            : t('Chọn')}
        </div>
      )}{' '}
    </SHandle>
  );
}

const SHandle = styled.div`
  cursor: pointer;
  margin: 0 8px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 2px;
  border: 1px solid #dfe9f5;
  background-color: white;
  transition: all 0.2s;
  position: relative;
  svg > path {
    fill: #9ab1cc;
  }
  .handle-select {
    svg > path {
      fill: white;
    }
  }
  &.warning {
    &:hover {
      border: 1px solid #ff6c6c;
    }
  }

  .tooltip {
    display: none;
    transition: all 0.2s;
  }

  &:hover {
    border-color: #00cb82;
    svg > path {
      fill: #333b6a;
    }
    .tooltip {
      display: block;
      position: absolute;
      top: -30px;
      color: #333b6a;
      font-size: 12px;
      line-height: 21px;
      z-index: 2;
      padding: 1px 12px 0;
      max-width: 80px;
      text-align: center;
      background-color: white;
      border-radius: 2px;
      white-space: nowrap;
      border: 1px solid #dfe9f5;
      &.wide {
        max-width: 120px;
        left: -22px;
      }
    }
  }
`;
