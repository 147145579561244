import React, { useState, useEffect } from 'react';
import Input from 'components/bases/shared/input';
import Button from 'components/bases/shared/button';
import inputIcons from 'components/bases/shared/icons/input';
import { useForm } from 'react-hook-form';
import {
  SAuthorizationListPage,
  SSectionCustom,
} from 'styles/styled/admin/systemPage/formSetup';
import { TableList } from './table.js';
import { useDispatch, useSelector } from 'react-redux';
import PNotify from 'pnotify/dist/es/PNotify';
import { VALIDATE_REQUIRE } from '@constants/validate';
import ErrorMessage from '@components/input/ErrorMessage';
import { useTranslation } from 'react-i18next';
import {
  getUploadApi,
  getFormApi,
  setSending,
} from '@duck/pages/admin/systemReducer/formSetUp/actions.js';

var _validFileExtensions = ['.docx'];
function Validate(e) {
  var sFileName = e.target.value;
  if (sFileName.length > 0) {
    var blnValid = false;
    for (var j = 0; j < _validFileExtensions.length; j++) {
      var sCurExtension = _validFileExtensions[j];
      if (
        sFileName
          .substr(sFileName.length - sCurExtension.length, sCurExtension.length)
          .toLowerCase() === sCurExtension.toLowerCase()
      ) {
        blnValid = true;
        break;
      }
    }
    return blnValid;
  }
}

const FormSetup = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [listFile1, setListFile1] = useState('');
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const [success, setSuccess] = useState(false);

  const optionForm = [
    { value: 'EligibilityTestReport', label: 'Biên bản kiểm tra tư cách' },
    { value: 'OpenningConferenceReport', label: 'Biên bản khai mạc đại hội' },
    { value: 'DocumentVoteReport', label: 'Biên bản kiểm phiếu Tờ trình' },
    {
      value: 'DocumentCumulativeVoteReport',
      label: 'Biên bản kiểm phiếu Tờ trình - bầu cử',
    },
    { value: 'CumulativeVoteReport', label: 'Biên bản kiểm phiếu bầu cử' },
    { value: 'ClosingConferenceReport', label: 'Biên bản bế mạc đại hội' },
    { value: 'MinutesOfAGM', label: 'Biên bản đại hội' },
    { value: 'ResolutionsOfAGM', label: 'Nghị quyết đại hội' },
    { value: 'PrintVotingTicket', label: 'Mẫu in phiếu biểu quyết' },
    { value: 'PrintLoginAccount', label: 'Mẫu in tài khoản đăng nhập' },
    { value: 'VotingResultTemplate', label: 'Mẫu file kết quả biểu quyết' },
    { value: 'AuthorityTemplate', label: 'Mẫu giấy ủy quyền' },
  ];
  const optionFormEn = [
    { value: 'EligibilityTestReport', label: 'Eligibility testReport' },
    {
      value: 'OpenningConferenceReport',
      label: 'Openning conference report',
    },
    { value: 'DocumentVoteReport', label: 'Document vote report' },
    {
      value: 'DocumentCumulativeVoteReport',
      label: 'Document vote report (Cumulative Vote)',
    },
    { value: 'CumulativeVoteReport', label: 'Cumulative vote report' },
    { value: 'ClosingConferenceReport', label: 'Closing conference report' },
    { value: 'MinutesOfAGM', label: 'Minutes of AGM' },
    { value: 'ResolutionsOfAGM', label: 'Resolutions of AGM' },
    { value: 'PrintVotingTicket', label: 'Print voting ticket' },
    { value: 'PrintLoginAccount', label: 'Print login account' },
    { value: 'VotingResultTemplate', label: 'Voting result template' },
    { value: 'AuthorityTemplate', label: 'Authority template' },
  ];

  const { list, filter } = useSelector((state) => state.admin.formSetup);
  useEffect(() => {
    dispatch(getFormApi(filter));
  }, [dispatch, filter]);

  const isFile = (event, handleFuncion) => {
    if (Validate(event)) {
      var files = event.target.files[0];
      handleFuncion(files);
      PNotify.success({
        title: t('Thành công'),
        text: t('Upload file thành công'),
        destroy: true,
        delay: 2000,
      });
    } else {
      PNotify.error({
        title: t('Lỗi'),
        text: t('Vui lòng upload file với định dạng word'),
        destroy: true,
        delay: 2000,
      });
    }
  };

  useEffect(() => {
    if (success) {
      setListFile1('');
      setSuccess(false);
    }
  }, [success, dispatch]);

  const onSubmit = (data) => {
    const { form } = data;
    setSuccess(true);
    if (listFile1) {
      if (listFile1) {
        let formData1 = new FormData();
        formData1.append('file', listFile1);
        formData1.append('type', form.value);
        dispatch(getUploadApi(formData1, filter));
      }
      dispatch(setSending(true));
    } else {
      PNotify.error({
        title: t('Lỗi'),
        text: t('Vui lòng upload biểu mẫu'),
        destroy: true,
        delay: 2000,
      });
    }
  };
  // const downloadFile = () => {
  //   dispatch(downloadFileAPI());
  // };

  return (
    <SAuthorizationListPage>
      <SSectionCustom>
        <div className="module-header">
          <h3 className="title"> {t('Thiết lập biểu mẫu')} </h3>
        </div>
        <div className="module-content">
          <div className="search-wrapper">
            <div className="search-item">
              <div className="file-handle">
                <Button className="grey-button  upload-btn">
                  {inputIcons.upload}
                  {t('Upload biểu mẫu')}
                  {!listFile1 && (
                    <input
                      type="file"
                      onChange={(e) => isFile(e, setListFile1)}
                    />
                  )}
                  {listFile1 && (
                    <div className="file">
                      {listFile1 ? listFile1.name : ''}
                      <svg
                        onClick={() => setListFile1('')}
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="12" cy="12" r="12" fill="#FF6C6C" />
                        <rect
                          x="16.0942"
                          y="6.66211"
                          width="2.66667"
                          height="13.3333"
                          transform="rotate(45 16.0942 6.66211)"
                          fill="white"
                        />
                        <rect
                          x="6.76172"
                          y="8.54883"
                          width="2.66667"
                          height="13.3333"
                          transform="rotate(-45 6.76172 8.54883)"
                          fill="white"
                        />
                      </svg>
                    </div>
                  )}
                </Button>
              </div>
            </div>
            <div className="search-item">
              <Input.Select
                name="form"
                control={control}
                selectProps={{
                  placeholder: t('Loại biểu mẫu'),
                  options: i18n.language === 'vi' ? optionForm : optionFormEn,
                }}
                rules={{
                  required: t(VALIDATE_REQUIRE),
                }}
              />
              <ErrorMessage errors={errors} field="form" />
            </div>

            <div className="search-item">
              <Button className="search" onClick={handleSubmit(onSubmit)}>
                {t('Upload')}
              </Button>
            </div>
          </div>
        </div>
      </SSectionCustom>
      <TableList dataList={list} />
    </SAuthorizationListPage>
  );
};
export default FormSetup;
