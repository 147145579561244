import React from 'react';
import { setFilter } from '../../../../states/duck/pages/admin/shareholderStatusReducer/actions';
import { useSelector, useDispatch } from 'react-redux';
import { SForm } from '@styled/admin/manageShareholderPage';
import Button from '@components/button';
import Input from '@components/input';
import { useForm } from 'react-hook-form';
import inputIcons from '@components/icons/input';
import Section from '@components/section';
import { t } from 'translate';

const optionType = [
  { label: t('Tất cả'), value: '' },
  { label: t('Trong nước'), value: 'domestic' },
  { label: t('Nước ngoài'), value: 'foreign' },
];
const Form = () => {
  const dispatch = useDispatch();
  const filter = useSelector((state) => state.admin.shareholderStatus.filter);
  const { handleSubmit, register, control } = useForm();
  const onSubmit = (data) => {
    const { identityNumber, type } = data;
    dispatch(
      setFilter({
        ...filter,
        identityNumber,
        type: type?.value || '',
      }),
    );
  };
  return (
    <SForm>
      <Section header={t('Danh sách cổ đông đại diện tổ chức')}>
        <div className="module-content">
          <div className="search-wrapper">
            <div className="search-item">
              <Input.Text
                register={register('identityNumber')}
                wrapperProps={{
                  label: t('Họ tên/CMND/CCCD'),
                  icon: inputIcons.account,
                  height: '40px',
                }}
              />
            </div>
            <div className="search-item">
              <Input.Select
                name="type"
                control={control}
                selectProps={{
                  placeholder: t('Loại tổ chức'),
                  options: optionType,
                }}
              />
            </div>
            <div className="search-item">
              <Button onClick={handleSubmit(onSubmit)}>
                {' '}
                {t('Tìm kiếm')}{' '}
              </Button>
            </div>
          </div>
        </div>
      </Section>
    </SForm>
  );
};
export default Form;
