import * as constants from './constants';
import { callApi, callExportApi } from './../../../../common';
import PNotify from 'pnotify/dist/es/PNotify';
import { handleError } from '@configs/handleError';
var FileSaver = require('file-saver');

export const getUserApi = (body) => {
  //jointype : chua checkin uy quyen truc tiep truc tuyen
  //status: da bieu quyet chua bieu quyet
  //authority Cổ đông uq nhận uq
  //online: true false
  // /info/shareholder/:pageSize/:pageNumber thêm sortBy (code, sharesNumber, sharesJoinNumber) order (asc, desc)

  const {
    identityNumber,
    joinType,
    status,
    pageSize,
    pageNumber,
    authority,
    sortBy,
    order,
    online,
  } = body;
  const bodySend = {
    identityNumber,
    joinType,
    status,
    authority,
    online,
    sortBy,
    order,
  };
  return (dispatch) =>
    callApi(
      `${constants.URL}/${pageSize}/${pageNumber}`,
      'POST',
      bodySend,
    ).then(function (res) {
      dispatch(getListAccount(res.data.data.shareholders));
      dispatch(setTotal(res.data.data.total));
      // if (
      //   res.data.data.total % pageSize === 0 &&
      //   (pageNumber - 1) * pageSize >= res.data.data.total
      // ) {
      //   dispatch(
      //     setFilter({
      //       ...body,
      //       pageNumber: 1,
      //     }),
      //   );
      // }
    });
};

export const exportShareholderApi = (body) => {
  const { identityNumber, joinType, status, authority, sortBy, order, online } =
    body;
  const bodySend = {
    identityNumber,
    joinType,
    status,
    authority,
    online,
    sortBy,
    order,
  };
  return (dispatch) =>
    callExportApi(`${constants.URL_EXPORT}`, 'POST', bodySend)
      .then(function (res) {
        PNotify.success({
          title: 'Thành công',
          text: 'Xuất danh sách Cổ đông thành công!',
          destroy: true,
          delay: 2000,
        });
        var file = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        FileSaver.saveAs(file, 'DSCoDong');
      })
      .catch(handleError);
};
export const getTopic1Api = (body) => {
  return (dispatch) =>
    callApi(`${constants.URL_TOPIC}/${body}`, 'GET', null).then(function (res) {
      dispatch(getTopic1(res.data.topics));
    });
};
export const getTopic2Api = (body) => {
  return (dispatch) =>
    callApi(`${constants.URL_TOPIC}/${body}`, 'GET', null).then(function (res) {
      dispatch(getTopic2(res.data.topics));
    });
};
export const getTopic3Api = (body) => {
  return (dispatch) =>
    callApi(`${constants.URL_TOPIC}/${body}`, 'GET', null).then(function (res) {
      dispatch(getTopic3(res.data.topics));
    });
};
export const getTopic4Api = (body) => {
  return (dispatch) =>
    callApi(`${constants.URL_TOPIC}/${body}`, 'GET', null).then(function (res) {
      dispatch(getTopic4(res.data.topics));
    });
};
export const getTopic6Api = (body) => {
  return (dispatch) =>
    callApi(`${constants.URL_TOPIC}/${body}`, 'GET', null).then(function (res) {
      dispatch(getTopic6(res.data.topics));
    });
};
export const voteAgainApi = (body, filter) => {
  return (dispatch) =>
    callApi(`${constants.URL_VOTE_AGAIN}`, 'POST', body)
      .then(function (res) {
        setTimeout(() => {
          dispatch(getUserApi(filter));
        }, 200);
        dispatch(setToggleModal(false));
        PNotify.success({
          title: 'Thành công',
          text: 'Cho phép biểu quyết lại thành công',
          destroy: true,
          delay: 2000,
        });
      })
      .catch(function (err) {
        if (err) {
          if (err.response && err.response.data && err.response.data.message) {
            PNotify.error({
              title: 'Lỗi',
              text: err.response.data.message,
              destroy: true,
              delay: 2000,
            });
          } else if (err.response) {
            PNotify.error({
              title: 'Lỗi',
              text: err.response.statusText,
              destroy: true,
              delay: 2000,
            });
          }
        }
      });
};

export const getListAuthority = (id, identityNumber) => {
  return (dispatch) =>
    callApi('info/authority/100/1', 'POST', {
      shareholderId: id,
      status: 'accept',
    })
      .then(function (res) {
        if (res && res.data && res.data.data && res.data.data.data) {
          const optionSelectAutho = [];
          const listAutho = res.data.data.data;
          for (let i in listAutho) {
            if (listAutho[i].identityNumber !== identityNumber) {
              let exist = optionSelectAutho.findIndex(
                (item) =>
                  listAutho[i].authorityFor &&
                  item.value === listAutho[i].authorityFor,
              );
              if (exist === -1) {
                const temp = {
                  value:
                    listAutho[i] && listAutho[i].authorityFor
                      ? listAutho[i].authorityFor
                      : '',
                  label:
                    listAutho[i] && listAutho[i].name ? listAutho[i].name : '',
                  sharesNumber:
                    listAutho[i] && listAutho[i].sharesNumber
                      ? listAutho[i].sharesNumber
                      : 0,
                };
                optionSelectAutho.push(temp);
              } else {
                //calculate sharesNumber authorize in case holder authorized more than once

                let tmp = optionSelectAutho[exist];
                tmp = {
                  ...tmp,
                  sharesNumber: tmp.sharesNumber + listAutho[i].sharesNumber,
                };
              }
            }
          }
          dispatch(setListAuthority(optionSelectAutho));
        } else dispatch(setListAuthority([]));
      })
      .catch(handleError);
};

export const infoShareholderApi = (id, identityNumber) => {
  return (dispatch) =>
    callApi(`${constants.URL_VOTE_INFO}/${id}`, 'GET', null)
      .then(function (res) {
        dispatch(setDetails(res.data.data));
        dispatch(getListAuthority(id, identityNumber));
        dispatch(setToggleModal(true));
      })
      .catch(handleError);
};
export const getTopic1 = (value) => {
  return {
    type: constants.GET_TOPIC1,
    value,
  };
};
export const getTopic2 = (value) => {
  return {
    type: constants.GET_TOPIC2,
    value,
  };
};
export const getTopic3 = (value) => {
  return {
    type: constants.GET_TOPIC3,
    value,
  };
};
export const getTopic4 = (value) => {
  return {
    type: constants.GET_TOPIC4,
    value,
  };
};
export const getTopic6 = (value) => {
  return {
    type: constants.GET_TOPIC6,
    value,
  };
};
export const setToggleModal = (value) => {
  return {
    type: constants.TOGGLE_MODAL,
    value,
  };
};
export const setDetails = (value) => {
  return {
    type: constants.SET_DETAILS,
    value,
  };
};
export const getListAccount = (value) => {
  return {
    type: constants.GET_LIST,
    value,
  };
};
export const setChooseList = (value) => {
  return {
    type: constants.SET_CHOOSE_LIST,
    value,
  };
};
export const setFilter = (value) => {
  return {
    type: constants.SET_FILTER,
    value,
  };
};
export const setTotal = (value) => {
  return {
    type: constants.SET_TOTAL,
    value,
  };
};
export const setListAuthority = (value) => {
  return {
    type: constants.GET_LIST_AUTHORITY,
    value,
  };
};
