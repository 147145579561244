import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translate from "./translate";
const language = localStorage.getItem("language");


i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: translate,
    lng: language || "vi",
    fallbackLng: language || "vi",

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
