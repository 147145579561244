import { ReactComponent as HomeIcon } from 'assets/icons/home.svg';
import { ReactComponent as UserIcon } from 'assets/icons/user.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as ShareholderIcon } from 'assets/icons/shareholder.svg';
import { ReactComponent as AuthorityIcon } from 'assets/icons/authority.svg';
import { ReactComponent as CheckShareholderIcon } from 'assets/icons/check-shareholder.svg';
import { ReactComponent as ResultIcon } from 'assets/icons/result.svg';
import { ReactComponent as OpinionIcon } from 'assets/icons/opinion.svg';
import { ReactComponent as GuestIcon } from 'assets/icons/guest.svg';
import { ReactComponent as SystemIcon } from 'assets/icons/system.svg';
import React from 'react';
import {
  HomePage,
  ShareholderListPage,
  InitializeReportPage,
  ConfigSystem,
  GuestListPage,
  ShareholderOpinionPage,
  ManageShareholderPage,
} from '@pages/admin';
import ManualAuthorization from '@pages/admin/authorizationListPage/authorizationHandling';
import ResultPage from 'components/pages/resultPage';
import AuthorizationList from '@pages/admin/authorizationListPage/authorizationList';
import AuthorizationInformation from '@pages/admin/authorizationListPage/authorizationInformation';
import { CheckInHandPage, InspectionRecordsPage } from '@pages/checkIn';
import FormSetup from '@pages/admin/systemPage/formSetup';
import TimeVote from '@pages/admin/systemPage/timeVote';
import {
  CheckCouponArtisanPage,
  CheckCouponListPage,
  CheckCouponResultPage,
} from '@pages/checkCoupon';
import AdminAccount from '@pages/admin/systemPage/adminAccount';
import { t } from 'translate/store';
import { SecretaryExportReportPage } from '@pages/secretary';
import ManageShareholderRepresentPage from '@pages/admin/shareholderListRepresent';

export const menuList = [
  {
    label: t('Trang chủ'),
    icon: <HomeIcon />,
    path: '/',
    component: () => <ResultPage />,
    per: 'Home',
  },
  {
    label: t('Khai báo thông tin cơ bản'),
    icon: <UserIcon />,
    path: '/khai-bao-thong-tin-co-ban',
    component: () => <HomePage />,
    per: 'BasicInfo',
  },
  {
    label: t('Khai báo nội dung Đại hội'),
    icon: <EditIcon />,
    children: [
      {
        label: t('Khai báo thủ tục khai mạc'),
        path: '/khai-bao-thu-tuc-khai-mac',
        component: () => <InitializeReportPage part="part1" type="sheet-1" />,
        per: 'OpenningCeremonyDocuments',
      },
      {
        label: t('Khai báo BC - Tờ trình'),
        path: '/khai-bao-to-trinh',
        component: () => (
          <InitializeReportPage part="part2" type="sheet-2" method={0} />
        ),
        per: 'ReportsAndDocuments',
      },
      {
        label: t('Bầu dồn phiếu'),
        path: '/bau-don-phieu',
        component: () => (
          <InitializeReportPage part="part2" type="sheet-2" method={1} />
        ),
        per: 'CumulativeVotes',
      },
      {
        label: t('Khai báo thủ tục bế mạc'),
        path: '/khai-bao-thu-tuc-be-mac',
        component: () => <InitializeReportPage part="part3" type="sheet-3" />,
        per: 'ClosingCeremonyDocuments',
      },
      {
        label: t('Khai báo tài liệu Đại hội'),
        path: '/khai-bao-tai-lieu-dai-hoi',
        component: () => <InitializeReportPage part="part4" type="sheet-5" />,
        per: 'ReferenceDocuments',
      },
    ],
  },
  {
    label: t('Quản lý Cổ đông'),
    icon: <ShareholderIcon />,
    children: [
      {
        label: t('Danh sách Cổ đông'),
        path: '/danh-sach-co-dong',
        component: () => <ShareholderListPage />,
        per: 'ShareholderList',
      },
      {
        label: t('Cổ đông đại diện tổ chức'),
        path: '/danh-sach-co-dong-dai-dien',
        component: () => <ManageShareholderRepresentPage />,
        per: 'EnterpriseRepresentative',
      },
      {
        label: t('Quản lý Cổ đông'),
        path: '/quan-ly-co-dong',
        component: () => <ManageShareholderPage />,
        per: 'ShareholderManagement',
      },
    ],
  },
  {
    label: t('Quản lý ủy quyền'),
    icon: <AuthorityIcon />,
    children: [
      {
        label: t('Xử lý ủy quyền'),
        path: '/xu-ly-uy-quyen',
        component: () => <ManualAuthorization />,
        per: 'AuthorityProcessing',
      },
      {
        label: t('Danh sách ủy quyền'),
        path: '/danh-sach-uy-quyen',
        component: () => <AuthorizationList />,
        per: 'AuthorityList',
      },
      {
        label: t('Thiết lập thông tin ủy quyền'),
        path: '/thiet-lap-thong-tin-uy-quyen',
        component: () => <AuthorizationInformation />,
        per: 'SetupAuthority',
      },
    ],
  },
  {
    label: t('Kiểm tra tư cách Cổ đông'),
    icon: <CheckShareholderIcon />,
    children: [
      {
        label: t('Check in thủ công'),
        path: '/checkin-thu-cong',
        component: () => <CheckInHandPage match="thu-cong" />,
        per: 'ManualCheckin',
      },
      {
        label: t('Xuất biên bản KTTC CĐ'),
        path: '/xuat-bien-ban-kttc',
        component: () => <InspectionRecordsPage />,
        per: 'ExportCheckinHistory',
      },
    ],
  },
  {
    label: t('Quản lý kết quả biểu quyết'),
    icon: <ResultIcon />,
    children: [
      {
        label: t('Kiểm phiếu thủ công'),
        path: '/kiem-phieu-thu-cong',
        component: () => <CheckCouponArtisanPage />,
        per: 'ManualVoteChecking',
      },
      {
        label: t('Quản lý phiếu biểu quyết'),
        path: '/quan-ly-phieu-bieu-quyet',
        component: () => <CheckCouponListPage />,
        per: 'VotingManagement',
      },
      {
        label: t('Kết quả kiểm phiếu'),
        path: '/ket-qua-kiem-phieu',
        component: () => <CheckCouponResultPage />,
        per: 'VoteCheckingResult',
      },
      {
        label: t('Biên bản - Nghị quyết ĐH'),
        path: '/bien-ban-nghi-quyet',
        component: () => <SecretaryExportReportPage />,
        // exact: true,
        per: 'ExportedConferenceDocuments',
      },
    ],
  },
  {
    label: t('Quản lý ý kiến Cổ đông'),
    icon: <OpinionIcon />,
    path: '/quan-ly-y-kien',
    component: () => <ShareholderOpinionPage />,
    per: 'ShareholderOpinion',
  },
  {
    label: t('Quản lý khách mời'),
    icon: <GuestIcon />,
    path: '/quan-ly-khach-moi',
    component: () => <GuestListPage />,
    per: 'GuestManagement',
  },
  {
    label: t('Hệ thống'),
    icon: <SystemIcon />,
    children: [
      {
        label: t('Thời gian biểu quyết'),
        path: '/thoi-gian-bieu-quyet',
        component: () => <TimeVote />,
        per: 'VoteTime',
      },
      {
        label: t('Thiết lập biểu mẫu'),
        path: '/thiet-lap-bieu-mau',
        component: () => <FormSetup />,
        per: 'TemplateSetup',
      },
      {
        label: t('Tài khoản quản trị'),
        path: '/tai-khoan-quan-tri',
        component: () => <AdminAccount />,
        per: 'AdminAccount',
      },
      {
        label: t('Cấu hình hệ thống'),
        path: '/cau-hinh-he-thong',
        component: () => <ConfigSystem />,
        per: 'SystemConfig',
      },
    ],
  },
];

const convertToArray = (menuList) => {
  let result = [];
  menuList.map((r) => {
    if (r.children) result = result.concat(r.children);
    else result = result.concat(r);
  });
  return result;
};

export const routers = convertToArray(menuList);
