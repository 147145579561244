import { defaultOption } from "@configs/defaultOption";

export const defaultTheads = [
  {
    name: '#',
  },
  {
    name: 'STT',
  },
  {
    name: 'Tên nội dung',
  },
  {
    name: 'Tên nội dung (Tiếng Anh)',
  },
  {
    name: 'Thứ tự hiển thị',
  },
];

export const sheet4Theads = [
  {
    name: '#',
  },
  {
    name: 'STT',
  },
  {
    name: 'Tên nội dung',
  },
  {
    name: 'Tên nội dung (Tiếng Anh)',
  },
  {
    name: 'Thứ tự hiển thị',
  },
  {
    name: 'Xử lý',
  },
];


export const disableHolderTheads = [
  {name: "Họ tên"},
  {name: "CMND/CCCD"},
  {name: "Số điện thoại"},
  {name: "Cổ phần sở hữu"},
  {name: "Xử lý"},
  {name: "Trạng thái"},
]


export const holderTypeOptions = [
  {...defaultOption},
  {label: "Bị chặn", value: "blocked"}
]

export const sortTableTheads = [{name: 'Tên tờ trình'}, {name: 'Số thứ tự'}];
