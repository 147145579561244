import React, { useEffect } from 'react';
import {
  getUserApi,
  getStatisticApi,
} from '../../../../states/duck/pages/checkin/inspectionRecordsReducer/actions';
import { useDispatch, useSelector } from 'react-redux';
import CheckInInfo from './checkInInfo';
import LayoutContent from 'components/layout/layoutContent';
import TableCheckinManagerment from './table';
import { useTranslation } from 'react-i18next';

const InspectionRecordsPage = () => {
  const statistic = useSelector(
    (state) => state.checkin.inspectionRecords.statistic,
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getStatisticApi());
    dispatch(getUserApi());
  }, [dispatch]);

  useEffect(() => {
    let time = new Date(statistic?.updatedAt).getTime();
    let now = new Date().getTime();
    let timeRequest = 60000 - (now - time);

    let t = setTimeout(() => {
      // dispatch(getStatisticApi());
      dispatch(getUserApi());
    }, timeRequest);
    return () => {
      clearTimeout(t);
    };
  }, [dispatch, statistic]);

  return (
    <LayoutContent
      title={t('Biên bản kiểm tra tư cách Cổ đông')}
      children={
        <>
          <div className="module-content">
            <CheckInInfo />
            <div className="dashboard-list">
              {/* <TableTools /> */}
              <TableCheckinManagerment />
            </div>
          </div>
        </>
      }
    ></LayoutContent>
  );
};

export default InspectionRecordsPage;

// const CountDown = ({ activeCount, setActiveCount }) => {
//   const [count, setCount] = useState(REQUEST_AGAIN);
//   const [intervalID, setIntervalID] = useState(null);
//   useEffect(() => {
//     if (activeCount) {
//       setActiveCount(false);
//       setCount(REQUEST_AGAIN);
//       let timeInterval = REQUEST_AGAIN;
//       setIntervalID(
//         setInterval(() => {
//           if (timeInterval > 0) {
//             timeInterval = timeInterval - 1000;
//             setCount(timeInterval);
//           } else {
//             clearInterval(intervalID);
//           }
//         }, 1000),
//       );
//     }
//     return () => {
//       clearInterval(intervalID);
//     };
//   }, [activeCount, intervalID, setActiveCount]);
//   return <div className="countdown-show">{count / 1000}s</div>;
// };
