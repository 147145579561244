export const vi5 = {};
export const en5 = {
  'Cài đặt hiển thị bảng kết quả trang chủ':
    'Settings to display the result table',
  'Chế độ mặc định': 'Default mode',
  'Chế độ tùy chỉnh': 'Custom mode',
  'Số bản ghi hiển thị của từng nội dung':
    'Number of displayed records for each content',
  'Màu sắc hiển thị': 'Color displayed',
  'Cài đặt màu header bảng': 'Set the table header color',
  'Bảng màu': 'Color board',
  'Cài đặt màu nền bảng': 'Set the table background color',
  'Cài đặt màu chữ hiển thị': 'Set display text color',
  'Chọn màu': 'Choose color',
  'Mã Nhà đầu tư': 'ID',
  'Cài đặt kích thước logo hiển thị': 'Set the logo display size',
  'Tùy chỉnh': 'Custom',
  'Chiều rộng': 'Width',
  'Chiều cao': 'Height',
  'Loại ủy quyền': 'Authorization type',
  'Thời gian ủy quyền': 'Authorization Time',
  'Thời gian duyệt ủy quyền': 'Authorization approval time',
  'Người duyệt': 'Approved by',
  'Đại diện nhận uỷ quyền': 'Authorized representative',
  'Thứ tự phải lớn hơn 0': 'Order must be greater than 0',
  'Loại hình tham dự': 'Type of attendance',
  'Xuất danh sách Nhà đầu tư': 'Export list of Investors',
  'Biểu quyết thông qua nội dung bầu cử':
    'Voting to approve the election content',
  'Đăng nhập dành cho tổ chức': 'Sign in for organizations',
  'Đóng/mở phần biểu quyết nội dung bầu cử':
    'Close/open the voting section on election content',
  'nội dung bầu cử (Bầu dồn phiếu)': 'Election content (cumulative voting)',
  'Danh sách Nhà đầu tư đại diện tổ chức':
    'List of Investors representing organization',
  'Nhà đầu tư đại diện tổ chức': 'Investors representing organization',
  'Loại tổ chức': 'Organization type',
  'Tên tổ chức': 'Organization Name',
  'Họ tên đại diện tổ chức': 'Representative Name',
  'File mẫu': 'Sample file',
  'Đã duyệt': 'Approved',
};
