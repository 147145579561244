import React, { useEffect, useState } from 'react';
import Input from '@components/input';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@components/button';
import {
  setRepliedMessagesApi,
  setVoteSelectionsApi,
} from '@duck/pages/admin/configSystemReducer/actions';
import PNotify from 'pnotify/dist/es/PNotify';

const defaultVoteValue = [
  { displayName: 'Tán thành', point: 1, lang: 'vi' },
  { displayName: 'Không tán thành', point: 2, lang: 'vi' },
  { displayName: 'Không ý kiến', point: 3, lang: 'vi' },
  { displayName: 'Affirmative votes', point: 1, lang: 'en' },
  { displayName: 'Negative votes', point: 2, lang: 'en' },
  { displayName: 'Abstention', point: 3, lang: 'en' },
];

export default function AutoMessage() {
  const [messageOptions, setMessageOptions] = useState([]);
  const [voteOptions, setVoteOptions] = useState({
    label: 'Phương án biểu quyết',
    value: '',
  });
  const [values, setValues] = useState([]);
  const [refresh, setRefresh] = useState(1);
  const info = useSelector((state) => state.admin.configSystem.info);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { handleSubmit, register, control, setValue } = useForm();
  const {
    handleSubmit: handleSubmit2,
    register: register2,
    control: control2,
    setValue: setValue2,
    getValues,
  } = useForm();

  const submitAutoReplyMessage = async (value) => {
    const { messageContent, messageSelect } = value;
    let result = info.autoReplyMessages.map((item, index) => {
      if (messageSelect.index === index) {
        return {
          message: messageContent,
          lang: item.lang,
        };
      } else {
        return {
          message: item.message,
          lang: item.lang,
        };
      }
    });
    await dispatch(
      setRepliedMessagesApi({
        messages: result,
      }),
    );
    PNotify.success({
      title: t('Thành công'),
      text: t('Cập nhật thành công'),
      destroy: true,
      delay: 2000,
    });
    setValue('messageContent', '');
    setValue('messageSelect', '');
  };

  const submitVoteSelection = async (value) => {
    let newValues = [...values];
    for (let i = 0; i < newValues.length; i++) {
      if (!newValues[i].value) {
        for (let j = 0; j < newValues.length; j++) {
          if (newValues[i].point === newValues[j].point) {
            newValues[j] = { ...newValues[i], value: newValues[i].value };
          }
        }
      }

      if (newValues[i].value) {
        for (let j = 0; j < newValues.length; j++) {
          if (newValues[i].point === newValues[j].point) {
            if (!newValues[j].value) {
              PNotify.error({
                title: t('Lỗi'),
                text: t(
                  'Cần thêm phương án biểu quyết theo cặp tiếng Anh và tiếng Việt',
                ),
                destroy: true,
                delay: 2000,
              });
              return;
            }
          }
        }
      }
    }
    const temp = newValues
      .map((v) => ({ displayName: v.value, point: v.point, lang: v.lang }))
      .filter((v) => v.displayName);
    await dispatch(
      setVoteSelectionsApi({
        options: temp,
      }),
    );
    PNotify.success({
      title: t('Thành công'),
      text: t('Cập nhật thành công'),
      destroy: true,
      delay: 2000,
    });
    setValue2('voteContent', '');
    setValue2('voteSelect', '');
  };
  useEffect(() => {
    if (info && info.autoReplyMessages) {
      let voteArr = info.voteSelections;
      for (let i = 0; i < defaultVoteValue.length; i++) {
        if (!voteArr[i]) {
          voteArr.splice(i, 0, {
            displayName: '',
            point: defaultVoteValue[i].point,
            lang: defaultVoteValue[i].lang,
          });
        }
        if (
          defaultVoteValue[i].point !== voteArr[i].point ||
          defaultVoteValue[i].lang !== voteArr[i].lang
        ) {
          voteArr.splice(i, 0, {
            displayName: '',
            point: defaultVoteValue[i].point,
            lang: defaultVoteValue[i].lang,
          });
        }
      }
      setMessageOptions(
        info.autoReplyMessages.map((item, index) => {
          return {
            label:
              item.lang === 'vi' ? `VN ${item.message}` : `EN ${item.message}`,
            value: item.message,
            index: index,
          };
        }),
      );
      setValues(
        voteArr.map((item, index) => {
          return {
            label:
              item.lang === 'vi'
                ? `VN ${item.displayName}`
                : `EN ${item.displayName}`,
            value:
              item.lang === 'vi'
                ? `${item.displayName}`
                : `${item.displayName}`,
            index: index,
            point: item.point,
            lang: item.lang,
          };
        }),
      );

      setVoteOptions(
        voteArr.map((item, index) => {
          return {
            label:
              item.lang === 'vi'
                ? `VN ${item.displayName}`
                : `EN ${item.displayName}`,
            value:
              item.lang === 'vi'
                ? `VN ${item.displayName}`
                : `EN ${item.displayName}`,
            index: index,
          };
        }),
      );
    }
  }, [info]);
  return (
    <SAutoMessage>
      <div className="wrapper">
        <h4 className="category">{t('Thiết lập tin nhắn tự động')}</h4>
        <form key={1} className="config">
          <Input.Select
            name="messageSelect"
            control={control}
            defaultValue={null}
            selectProps={{
              placeholder: t('Tin nhắn tự động'),
              options: messageOptions,
            }}
            afterChange={(item) => {
              setValue('messageContent', item.value);
            }}
          />
          <Input.TextArea register={register('messageContent')} />
        </form>
        <Button onClick={handleSubmit(submitAutoReplyMessage)}>
          {t('Lưu')}
        </Button>
      </div>

      <div className="wrapper">
        <h4 className="category">{t('Thiết lập phương án biểu quyết')}</h4>
        <form key={2} className="config">
          <Input.Select
            name="voteSelect"
            control={control2}
            defaultValue={null}
            selectProps={{
              placeholder: t('Phương án biểu quyết'),
              options: voteOptions,
            }}
            afterChange={(item) => {
              setRefresh((prev) => prev + 1);
            }}
          />

          <Input.TextArea
            // register={register2('voteContent')}
            value={
              values?.find((t) => t.label == getValues('voteSelect')?.value)
                ?.value
            }
            onChange={(e) => {
              const temp = values.slice(0);
              const selectValue = getValues('voteSelect')?.value;
              const ind = temp.findIndex((t) => t.label == selectValue);
              temp[ind] = { ...temp[ind], value: e.target.value };
              setValues(temp);
            }}
          />
        </form>
        <Button onClick={handleSubmit2(submitVoteSelection)}>{t('Lưu')}</Button>
      </div>
    </SAutoMessage>
  );
}

const SAutoMessage = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  .wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    button {
      margin-top: 16px;
      align-self: center;
    }
  }
  .config {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    .wrapper {
      button {
        width: 100%;
      }
    }
  }
`;
