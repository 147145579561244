import Button from '@components/button';
import {SModalVoteAgain} from '@styled/admin/manageShareholderPage';
import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {voteAgainApi} from '../../../../../states/duck/pages/admin/shareholderStatusReducer/actions';
import {useTranslation} from 'react-i18next';

const VoteAgainModal = ({onClick}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const listChoose = useSelector(
    (state) => state.admin.shareholderStatus.listTopicChoose,
  );
  const filter = useSelector((state) => state.admin.shareholderStatus.filter);
  const onSubmit = () => {
    dispatch(
      voteAgainApi(
        {
          id: listChoose[0] ? listChoose[0]._id : '',
        },
        filter,
      ),
    );
  };
  return (
    <SModalVoteAgain>
      {listChoose[0].isVoted ? (
        <>
          <div className="content">
            {t("Bạn đang cho phép Cổ đông")} {listChoose[0] ? listChoose[0].name : ''}{' '}
            {t("biểu quyết lại")}
            <br />
            {t("Bạn có chắc chắn với hành động này?")}
          </div>
          <div >
            <Button onClick={onSubmit} className="confirm-btn">
              {t("Đồng ý")}
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className="content">
            Cổ đông {listChoose[0] ? listChoose[0].name : ''} chưa biểu quyết
          </div>
          <div>
            <Button onClick={onClick} className="confirm-btn">
              {t("Đóng")}
            </Button>
          </div>
        </>
      )}
    </SModalVoteAgain>
  );
};
export default VoteAgainModal;
