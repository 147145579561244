export const URL = "info/shareholder";
export const URL_EDIT = "shareholder/edit";
export const URL_DELETE = "shareholder/delete";
export const URL_CREATE = "shareholder/create";
export const URL_DOWNLOAD = "shareholder/download/sample-file";
export const URL_CONFIRM = "shareholder/confirm";
export const URL_CONFERENCE = "conference";



export const GET_LIST = "GET_SHAREHOLDER_LIST_LIST";
export const SET_CHOOSE_LIST = "SET_SHAREHOLDER_LIST_CHOOSE_LIST";
export const TOGGLE_MODAL = "TOGGLE_SHAREHOLDER_LIST_MODAL";
export const SET_PAGE_SIZE = "SET_SHAREHOLDER_LIST_PAGE_SIZE";
export const SET_PAGE_NUMBER = "SET_SHAREHOLDER_LIST_PAGE_NUMBER";
export const SET_FILTER = "SET_SHAREHOLDER_LIST_FILTER";
export const SET_DETAILS = "SET_SHAREHOLDER_LIST_DETAILS";
export const SET_TOTAL = "SET_SHAREHOLDER_STATUS_TOTAL";
export const SET_SENDING = "SET_SHAREHOLDER_STATUS_SENDING";
export const SET_INFO_CONFERENCE= "SET_SHAREHOLDER_STATUS_INFO_CONFERENCE";






