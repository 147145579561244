import React from 'react';
import styled from 'styled-components';

export const SortOrder = ({ onSort }) => {
  return (
    <Styles className="sort-container" onClick={onSort}>
      <div className="sort-item">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="16"
          viewBox="0 0 10 16"
          fill="none"
        >
          <path
            d="M1 5L5 1M5 1L9 5M5 1V15"
            stroke="#DFE9F5"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div className="sort-item">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="16"
          viewBox="0 0 10 16"
          fill="none"
        >
          <path
            d="M9 11L5 15M5 15L1 11M5 15V1"
            stroke="#DFE9F5"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </Styles>
  );
};

const Styles = styled.div`
  display: flex;
  gap: 4px;
  cursor: pointer;
`;
