export const URL_CHANGE_PASSWORD = "auth/admin/change-password";
export const URL_INFO_ADMIN = "info/admin";
export const URL_SET_PHONE_AUTH = "admin/update-main-admin";
export const URL_TOGGLE_AUTH = "auth/admin/toggle-two-auth";
export const URL_SET_PHONE_TO_OTP = "auth/get-otp";



export const GET_INFO = "GET_SETTING_ADMIN_INFO";
export const SET_TOGGLE_MODAL = "SET_SETTING_ADMIN_TOGGLE_MODAL";





