import * as constants from './constants';
import { callApi } from '../../../../common';
import PNotify from 'pnotify/dist/es/PNotify';
import { handleError } from '@configs/handleError';
import { callDownloadApi } from '../../../../common';
var FileSaver = require('file-saver');
export const getUserApi = (body) => {
  const {
    identityNumber,
    joinType,
    status,
    pageSize,
    pageNumber,
    authority,
    online,
    sortBy,
    order,
  } = body;
  return (dispatch) =>
    callApi(`${constants.URL}/${pageSize}/${pageNumber}`, 'POST', {
      identityNumber,
      joinType,
      status,
      authority,
      online,
      sortBy,
      order,
    }).then(function (res) {
      dispatch(getListAccount(res.data.data.shareholders));
      dispatch(setTotal(res.data.data.total));
      // if (
      //   res.data.data.total % pageSize === 0 &&
      //   (pageNumber - 1) * pageSize >= res.data.data.total
      // ) {
      //   dispatch(
      //     setFilter({
      //       ...body,
      //       pageNumber: 1,
      //     }),
      //   );
      // }
    });
};
export const getConferenceApi = () => {
  return (dispatch) =>
    callApi(`${constants.URL_CONFERENCE}`, 'GET', null).then(function (res) {
      dispatch(getConference(res.data.conference.conference));
    });
};
export const getTopic1Api = (body) => {
  return (dispatch) =>
    callApi(`${constants.URL_TOPIC}/${body}`, 'GET', null).then(function (res) {
      dispatch(getTopic1(res.data.topics));
    });
};
export const getTopic2Api = (body) => {
  return (dispatch) =>
    callApi(`${constants.URL_TOPIC}/${body}`, 'GET', null).then(function (res) {
      dispatch(getTopic2(res.data.topics));
    });
};
export const getTopic3Api = (body) => {
  return (dispatch) =>
    callApi(`${constants.URL_TOPIC}/${body}`, 'GET', null).then(function (res) {
      dispatch(getTopic3(res.data.topics));
    });
};
export const getTopic4Api = (body) => {
  return (dispatch) =>
    callApi(`${constants.URL_TOPIC}/${body}`, 'GET', null).then(function (res) {
      dispatch(getTopic4(res.data.topics));
    });
};
export const getTopic6Api = (body) => {
  return (dispatch) =>
    callApi(`${constants.URL_TOPIC}/${body}`, 'GET', null).then(function (res) {
      dispatch(getTopic6(res.data.topics));
    });
};
export const getListAuthority = (id, identityNumber) => {
  return (dispatch) =>
    callApi('info/authority/100/1', 'POST', {
      shareholderId: id,
      status: 'accept',
      form: 'all',
    })
      .then(function (res) {
        if (res && res.data && res.data.data && res.data.data.data) {
          const optionSelectAutho = [];
          const listAutho = res.data.data.data;
          for (let i in listAutho) {
            if (listAutho[i].identityNumber !== identityNumber) {
              let exist = optionSelectAutho.findIndex(
                (item) =>
                  listAutho[i].authorityFor &&
                  item.value === listAutho[i].authorityFor,
              );
              if (exist === -1) {
                const temp = {
                  value:
                    listAutho[i] && listAutho[i].authorityFor
                      ? listAutho[i].authorityFor
                      : '',
                  label:
                    listAutho[i] && listAutho[i].name ? listAutho[i].name : '',
                  sharesNumber:
                    listAutho[i] && listAutho[i].sharesNumber
                      ? listAutho[i].sharesNumber
                      : 0,
                };
                optionSelectAutho.push(temp);
              } else {
                //calculate sharesNumber authorize in case holder authorized more than once
                let tmp = optionSelectAutho[exist];
                tmp = {
                  ...tmp,
                  sharesNumber: tmp.sharesNumber + listAutho[i].sharesNumber,
                };
              }
            }
          }
          dispatch(setListAuthority(optionSelectAutho));
        } else dispatch(setListAuthority([]));
      })
      .catch(handleError);
};

export const infoShareholderApi = (id, identityNumber) => {
  return (dispatch) =>
    callApi(`${constants.URL_VOTE_INFO}/${id}`, 'GET', null)
      .then(function (res) {
        dispatch(setDetails(res.data.data));
        dispatch(getListAuthority(id, identityNumber));
        dispatch(setToggleModal(true));
      })
      .catch(handleError);
};
export const infoShareholderDownloadApi = (body) => {
  return (dispatch) =>
    callDownloadApi(
      `${constants.URL_CHECK_COUPON_DOWLOAD}/${body}`,
      'GET',
      null,
    )
      .then(function (res) {
        // console.log('res', res);
        if (res.data) {
          var file = new Blob([res.data], {
            type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          });
          FileSaver.saveAs(file, 'Chitietphieubieuquyet');
          // dispatch(setDetailsList(res.data.data));
          // dispatch(setDownload(true));
          // dispatch(setLoading(false));
        } else
          PNotify.error({
            title: 'Lỗi',
            text: 'Chưa có cổ đông biểu quyết.',
            destroy: true,
            delay: 2000,
          });
      })
      .catch(function (err) {
        dispatch(setLoading(false));
        // if (err) {
        //   if (err.response && err.response.data && err.response.data.message) {
        //     PNotify.error({
        //       title: 'Lỗi',
        //       text: err.response.data.message,
        //       destroy: true,
        //       delay: 2000,
        //     });
        //   } else if (err.response) {
        //     PNotify.error({
        //       title: 'Lỗi',
        //       text: err.response.statusText,
        //       destroy: true,
        //       delay: 2000,
        //     });
        //   }
        // }
      });
};
export const infoShareholderDownloadAllApi = () => {
  return (dispatch) =>
    callDownloadApi(`${constants.URL_ALL_VOTE_INFO}`, 'GET', null)
      .then(function (res) {
        console.log('res', res);
        if (res.data) {
          var file = new Blob([res.data], {
            type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          });
          FileSaver.saveAs(file, 'Tatcaphieubieuquyet');
          // dispatch(setDetailsList(res.data.data));
          // dispatch(setDownload(true));
          // dispatch(setLoading(false));
        } else
          PNotify.error({
            title: 'Lỗi',
            text: 'Chưa có cổ đông biểu quyết.',
            destroy: true,
            delay: 2000,
          });
      })
      .catch(function (err) {
        dispatch(setLoading(false));
        // if (err) {
        //   if (err.response && err.response.data && err.response.data.message) {
        //     PNotify.error({
        //       title: 'Lỗi',
        //       text: err.response.data.message,
        //       destroy: true,
        //       delay: 2000,
        //     });
        //   } else if (err.response) {
        //     PNotify.error({
        //       title: 'Lỗi',
        //       text: err.response.statusText,
        //       destroy: true,
        //       delay: 2000,
        //     });
        //   }
        // }
      });
};
export const getTopic1 = (value) => {
  return {
    type: constants.GET_TOPIC1,
    value,
  };
};
export const getTopic2 = (value) => {
  return {
    type: constants.GET_TOPIC2,
    value,
  };
};
export const getTopic3 = (value) => {
  return {
    type: constants.GET_TOPIC3,
    value,
  };
};
export const getTopic4 = (value) => {
  return {
    type: constants.GET_TOPIC4,
    value,
  };
};
export const getTopic6 = (value) => {
  return {
    type: constants.GET_TOPIC6,
    value,
  };
};
export const setLoading = (value) => {
  return {
    type: constants.SET_LOADING,
    value,
  };
};
export const setToggleModal = (value) => {
  return {
    type: constants.TOGGLE_MODAL,
    value,
  };
};
export const setDetailsList = (value) => {
  return {
    type: constants.SET_DETAILS_LIST,
    value,
  };
};
export const getConference = (value) => {
  return {
    type: constants.GET_CONFERENCE,
    value,
  };
};
export const setDetails = (value) => {
  return {
    type: constants.SET_DETAILS,
    value,
  };
};
export const getListAccount = (value) => {
  return {
    type: constants.GET_LIST,
    value,
  };
};
export const setChooseList = (value) => {
  return {
    type: constants.SET_CHOOSE_LIST,
    value,
  };
};
export const setFilter = (value) => {
  return {
    type: constants.SET_FILTER,
    value,
  };
};
export const setTotal = (value) => {
  return {
    type: constants.SET_TOTAL,
    value,
  };
};
export const setListAuthority = (value) => {
  return {
    type: constants.GET_LIST_AUTHORITY,
    value,
  };
};
export const setDownload = (value) => {
  return {
    type: constants.SET_DOWNLOAD,
    value,
  };
};
