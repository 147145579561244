import React, {useState} from 'react';
import {VoteAgainModal, ShowDetailsModal} from './modal';
import {useSelector, useDispatch} from 'react-redux';
import {
  infoShareholderApi,
  setToggleModal,
  setChooseList,
  exportShareholderApi,
} from '../../../../states/duck/pages/admin/shareholderStatusReducer/actions';
import Button from '@components/button';
import {STableTools} from '@styled/admin/manageShareholderPage';
import Modal from '@components/modal';
import PNotify from 'pnotify/dist/es/PNotify';
import {useTranslation} from 'react-i18next';

const TableTools = () => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [modalType, setModalType] = useState('');
  const listChose = useSelector(
    (state) => state.admin.shareholderStatus.listTopicChoose,
  );
  const filter = useSelector((state) => state.admin.shareholderStatus.filter);
  const toggleModal = useSelector(
    (state) => state.admin.shareholderStatus.toggleModal,
  );
  const toggleModalCustom = (value) => {
    dispatch(setToggleModal(value));
  };
  const exportListAuthority = () => {
    dispatch(exportShareholderApi(filter));
  };
  return (
    <STableTools>
      <Button className="export-btn" onClick={exportListAuthority}>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.11111 10.8235V11.1765C5.42633 9.35671 6.91111 8.38612 8.77778 8.35294V10.4706C8.77778 10.8595 9.17522 11.1765 9.66678 11.1765C9.94989 11.1765 10.1918 11.0649 10.3536 10.9005C11.857 9.46824 15 6.58824 15 6.58824C15 6.58824 11.857 3.70682 10.3528 2.25624C10.1698 2.09267 9.92357 2.00068 9.66678 2C9.17522 2 8.77778 2.31553 8.77778 2.70588V4.82353C5.15333 4.82353 4.11111 8.26188 4.11111 10.8235ZM1.77778 14H12.6667C12.8729 14 13.0708 13.9256 13.2166 13.7933C13.3625 13.6609 13.4444 13.4813 13.4444 13.2941V9.02635C12.928 9.50353 12.3836 10.0096 11.8889 10.4713V12.5882H2.55556V4.11765H8V2.70588H1.77778C1.5715 2.70588 1.37367 2.78025 1.22781 2.91263C1.08194 3.04501 1 3.22455 1 3.41176V13.2941C1 13.4813 1.08194 13.6609 1.22781 13.7933C1.37367 13.9256 1.5715 14 1.77778 14Z"
            fill="white"
          />
        </svg>
        {t("Xuất file excel")}
      </Button>
      {listChose.length > 0 && (
        <div className="tools-container">
          <Button
            className="green-btn"
            onClick={() => {
              if (!listChose[0].isVoted) {
                PNotify.error({
                  title: t('Lỗi'),
                  text: t('Cổ đông chưa biểu quyết'),
                  destroy: true,
                  delay: 2000,
                });
              } else {
                setModalType('show-details');
                dispatch(
                  infoShareholderApi(
                    listChose[0]._id,
                    listChose[0].identityNumber,
                  ),
                );
              }

              // if (listChose[0] && listChose[0].isVoted) {
              //   setModalType('show-details');
              //   dispatch(
              //     infoShareholderApi(listChose[0] ? listChose[0]._id : ''),
              //   );
              // } else {
              //   PNotify.error({
              //     title: 'Lỗi',
              //     text: 'Cổ đông chưa biểu quyết',
              //     destroy: true,
              //     delay: 2000,
              //   });
              // }
            }}
          >
            {t("Xem kết quả biểu quyết")}
          </Button>

          <Button
            className="blue-btn"
            onClick={() => {
              if (!listChose[0].isVoted) {
                PNotify.error({
                  title: t('Lỗi'),
                  text: t('Cổ đông chưa biểu quyết'),
                  destroy: true,
                  delay: 2000,
                });
              } else {
                setModalType('vote-again');
                dispatch(setToggleModal(true));
              }
            }}
          >
            {t("Cho phép biểu quyết lại")}
          </Button>
        </div>
      )}
      <Modal
        isOpen={toggleModal}
        toggleModal={toggleModalCustom}
        title={
          modalType === 'vote-again'
            ? t('Cho phép biểu quyết lại')
            : t('Xem kết quả biểu quyết')
        }
        overwriteModalStyle={{
          maxWidth: modalType === 'vote-again' ? '560px' : '800px',
        }}
      >
        {modalType === 'vote-again' ? (
          <VoteAgainModal
            onClick={() => {
              dispatch(setToggleModal(false));
              dispatch(setChooseList([]));
            }}
          />
        ) : modalType === 'show-details' ? (
          <ShowDetailsModal />
        ) : (
          ''
        )}
      </Modal>
    </STableTools>
  );
};
export default TableTools;
