import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  setToggleModal,
  setFilterModal,
} from '@duck/pages/checkCoupon/checkCouponResultReducer/actions';
import {
  transformNumberComma,
  transformNumberPrinter,
  transformPercentNumber,
} from '@configs/transformNumber';
import { useTranslation } from 'react-i18next';
export const CumulativeVoteItem = ({
  item,
  kind,
  setVoteType,
  setValid,
  setKindTopic,
  setTopicName,
  index,
}) => {
  // console.log(item);
  // const topic = item.topic ? item.topic : {};
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [viewDetail, setViewDetail] = useState(false);
  const dispatch = useDispatch();
  const detailRows = item.selections ? (
    Object.values(item.selections).map((selection, key) => {
      return (
        <tr key={key}>
          <td className="td_sticky center">#</td>
          <td className="td_sticky left">{selection.selectName}</td>
          <td>{transformNumberPrinter(selection.shareholderVoted)}</td>
          <td>
            {
              // topic.selectNumber === 0
              //   ?
              transformNumberPrinter(parseFloat(selection.amount).toFixed(0))
              // : transformNumberPrinter(
              //     parseFloat(
              //       selection.amount
              //       * Number(topic.selectNumber),
              //     ).toFixed(0),
              //   )
            }
          </td>

          {/* <td>{transformNumberPrinter(selection.validShareholderVoted)}</td> */}
          <td>
            <span
              className="text-link"
              onClick={() => {
                setVoteType('bau-cu');
                setValid(true);
                setKindTopic(kind);
                dispatch(setToggleModal(true));
                setTopicName(
                  item.topic
                    ? language === 'vi'
                      ? item.topic.name
                      : item.topic.name_2
                    : '',
                );
                dispatch(
                  setFilterModal({
                    take: 10,
                    page: 1,
                    kind: kind,
                    topicId: item.topic ? item.topic._id : '',
                    point: 'all',
                    invalidVoted: false,
                    fixId: selection.fixId,
                    selectName: selection.selectName,
                    search: '',
                    voteStatus: 'voted',
                  }),
                );
              }}
            >
              {transformNumberPrinter(selection.validShareholderVoted)}
            </span>
          </td>
          <td>
            {transformNumberPrinter(
              parseFloat(selection.validAmount).toFixed(0),
            )}
          </td>
          {/* <td>
            {topic.selectNumber === 0
              ? parseFloat(selection.percentPerValid * 100).toFixed(2)
              : parseFloat(
                  (selection.percentPerValid * 100) /
                    Number(topic.selectNumber),
                ).toFixed(2)}
            %
          </td> */}
          <td>{transformPercentNumber(selection.percentPerValid * 100)}%</td>
          {/* <td>{transformNumberPrinter(selection.invalidShareholderVoted)}</td> // invalid
          <td>{selection.invalidAmount}</td> */}
          <td></td>
          <td></td>
          <td></td>
          {/* <td>{transformNumberPrinter(item.invalidNumbers)}</td>
          <td>{selection.invalidAmount}</td>
          <td>{selection.invalidAmount ===0 ? 0 : parseFloat(selection.invalidAmount/(selection.invalidAmount+selection.validAmount)).toFixed(2)}%</td> */}
        </tr>
      );
    })
  ) : (
    <tr>
      <td colSpan={10} className="center">
        {t('Không có dữ liệu')}
      </td>
    </tr>
  );
  // console.log(item);

  return (
    <>
      <tr>
        <td className="td_sticky">{index + 1}</td>
        <td className="td_sticky" style={{ maxWidth: '300px' }}>
          <span onClick={() => setViewDetail(!viewDetail)}>
            <span className="text-link">
              {item.topic
                ? language === 'vi'
                  ? item.topic.name
                  : item.topic.name_2
                : ''}
            </span>
          </span>
        </td>
        <td>
          {transformNumberPrinter(item.validNumbers + item.invalidNumbers)}
        </td>
        <td>
          {transformNumberPrinter(
            item.validShares + item.invalidShares,
            //  *
            //   Number(topic.selectNumber),
          )}
        </td>

        <td>
          <span
            className="text-link"
            onClick={() => {
              if (kind === 4) setVoteType('bau-cu');
              else setVoteType('bieu-quyet');
              setValid(true);
              setKindTopic(kind);
              dispatch(setToggleModal(true));
              setTopicName(
                item.topic
                  ? language === 'vi'
                    ? item.topic.name
                    : item.topic.name_2
                  : '',
              );
              dispatch(
                setFilterModal({
                  take: 10,
                  page: 1,
                  kind: kind,
                  topicId: item.topic ? item.topic._id : '',
                  point: 'all',
                  invalidVoted: false,
                  fixId: '',
                  selectName: '',
                  search: '',
                  voteStatus: 'voted',
                }),
              );
            }}
          >
            {transformNumberPrinter(item.validNumbers)}
          </span>
        </td>
        <td>
          {
            // topic.selectNumber === 0
            //   ?
            transformNumberPrinter(parseFloat(item.validShares).toFixed(0))
            // : transformNumberPrinter(
            //     parseFloat(
            //       item.validShares * Number(topic.selectNumber),
            //     ).toFixed(0),
            //   )
          }
        </td>
        <td>
          {Number.isInteger(item.validSharesPercentPerVoted * 100)
            ? transformNumberComma(item.validSharesPercentPerVoted * 100)
            : transformPercentNumber(item.validSharesPercentPerVoted * 100)}
          %
        </td>

        <td>
          <span
            className="text-link"
            onClick={() => {
              if (kind === 4) setVoteType('bau-cu');
              else setVoteType('bieu-quyet');
              setValid(false);
              setKindTopic(kind);
              dispatch(setToggleModal(true));
              setTopicName(
                item.topic
                  ? language === 'vi'
                    ? item.topic.name
                    : item.topic.name_2
                  : '',
              );
              dispatch(
                setFilterModal({
                  take: 10,
                  page: 1,
                  kind: kind,
                  topicId: item.topic ? item.topic._id : '',
                  point: 'all',
                  invalidVoted: true,
                  fixId: '',
                  selectName: '',
                  search: '',
                  voteStatus: 'voted',
                }),
              );
            }}
          >
            {transformNumberPrinter(item.invalidNumbers)}
          </span>
        </td>
        <td>
          {transformNumberPrinter(
            item.invalidShares,
            // * Number(topic.selectNumber),
          )}
        </td>
        <td>
          {Number.isInteger(item.invalidSharesPercentPerVoted * 100)
            ? transformNumberComma(item.invalidSharesPercentPerVoted * 100)
            : transformPercentNumber(item.invalidSharesPercentPerVoted * 100)}
          %
        </td>
      </tr>
      {viewDetail ? detailRows : ''}
    </>
  );
};
