import {SModal} from '@styled/checkCoupon/checkCouponResultPage';
// import {ModalCoupon} from './modalCoupon';
import React, {useEffect} from 'react';
import {ModalVoteValid} from './modalVoteValid';
import {useDispatch, useSelector} from 'react-redux';
import {
  getListDetail,
  setFilterModal,
  getNotVoteApi,
} from '@duck/pages/checkCoupon/checkCouponResultReducer/actions';
import Table from '@components/table';
import Pagination from '@components/pagination';
import {transformNumberPrinter} from '@configs/transformNumber';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import Input from '@components/input';
import inputIcons from '@components/icons/input';
import Button from '@components/button';
import {
  thead_bau_cu,
  thead_bau_cu_notvote,
  thead_bieu_quyet_common,
  voteStatusOptions,
  voteStatusOptionsEn,
} from '../data';

export const ModalDetail = ({valid, kind, topicName}) => {
  const {register, setValue, control, handleSubmit} = useForm();
  const {filterModal, listResultModal, listResultTotal, topic4} = useSelector(
    (state) => state.checkCoupon.checkCouponResult,
  );
  const {
    t,
    i18n: {language},
  } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (filterModal.voteStatus === 'notVote') {
      dispatch(getNotVoteApi(filterModal));
    } else if (filterModal.topicId !== '') {
      const {take, page, kind, topicId, invalidVoted, fixId, search} =
        filterModal;
      if (fixId !== '') {
        dispatch(
          getListDetail({take, page, fixId, topicId, invalidVoted, search}),
        );
      } else if (filterModal.point === 'all') {
        dispatch(
          getListDetail({
            take,
            page,
            kind,
            topicId,
            invalidVoted,
            fixId,
            search,
          }),
        );
      } else dispatch(getListDetail(filterModal));
    }
  }, [filterModal, dispatch]);
  useEffect(() => {
    setValue(
      'voteStatus',
      language === 'vi' ? voteStatusOptions[0] : voteStatusOptionsEn[0],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue]);
  const changeSize = (take) => {
    dispatch(
      setFilterModal({
        ...filterModal,
        take,
        page: 1,
      }),
    );
  };

  const changePage = (page) => {
    dispatch(
      setFilterModal({
        ...filterModal,
        page,
      }),
    );
  };

  const findVoteAmount = (item) => {
    if (filterModal.voteStatus === 'notVote') return 0;
    else {
      let temp = 0;
      if (filterModal.fixId === '') {
        const topic = topic4.filter(
          (topic) => topic.topic && topic.topic._id === item.topic_id,
        );
        return topic && topic[0] && topic[0].topic
          ? Number(item.sharesNumber) * Number(topic[0].topic.selectNumber)
          : Number(item.sharesNumber);

        // for (let i in item.selections) {
        //   if (item.selections && item.selections[i])
        //     temp += Number(item.selections[i].amount);
        // }
      } else {
        for (let i in item.selections) {
          if (
            item.selections &&
            item.selections[i] &&
            item.selections[i].fixId === filterModal.fixId
          ) {
            temp = Number(item.selections[i].amount);
            break;
          }
        }
      }
      // if (temp === 0 && item.selections && item.selections.length === 0) {
      //   const topic = topic4.filter(
      //     (topic) => topic.topic && topic.topic._id === item.topic_id,
      //   );
      //   return topic && topic[0] && topic[0].topic
      //     ? Number(item.sharesNumber) * Number(topic[0].topic.selectNumber)
      //     : Number(item.sharesNumber);
      // }
      return temp;
    }
  };

  const onSearch = (value) => {
    const {search, voteStatus} = value;
    dispatch(
      setFilterModal({
        ...filterModal,
        voteStatus: voteStatus ? voteStatus.value : 'voted',
        search,
        page: 1,
        take: 10,
        kind,
      }),
    );
  };
  return (
    <SModal>
      <div className="desc">
        {t('Nội dung')}
        {': '}
        {topicName}
      </div>
      {kind === 4 && filterModal.fixId && filterModal.fixId !== '' ? (
        <div className="desc" style={{marginTop: '-44px'}}>
          {t('Ứng viên')}
          {': '}
          {filterModal.selectName
            ? language === 'vi'
              ? filterModal.selectName.split(' - ')[0]
              : filterModal.selectName.split(' - ')[1]
            : ''}
        </div>
      ) : (
        ''
      )}
      {kind !== 4 && valid === true ? (
        <ModalVoteValid kind={kind} /> //modal vote valid kind 1 2 3
      ) : (
        <>
          {kind === 4 && valid === true ? (
            <div className="form">
              {filterModal.fixId === '' && (
                <div className="form-item" style={{width: '33%'}}>
                  <Input.Select
                    name="voteStatus"
                    control={control}
                    selectProps={{
                      options:
                        language === 'vi'
                          ? voteStatusOptions
                          : voteStatusOptionsEn,
                    }}
                  />
                </div>
              )}
              <div className="form-item" style={{width: '33%'}}>
                <Input.Text
                  register={register('search')}
                  wrapperProps={{
                    label: t('Họ tên/CMND/CCCD'),
                    icon: inputIcons.account,
                    required: true,
                    height: '40px',
                  }}
                />
              </div>
              <div className="form-item" style={{width: '33%'}}>
                <Button onClick={handleSubmit(onSearch)}>
                  {' '}
                  {t('Tìm kiếm')}{' '}
                </Button>
              </div>
            </div>
          ) : (
            ''
          )}
          <Table
            header={
              kind === 4
                ? filterModal.voteStatus === 'voted'
                  ? thead_bau_cu
                  : thead_bau_cu_notvote
                : thead_bieu_quyet_common
            }
            minWidth={kind === 4 ? '600px' : '800px'}
          >
            <>
              {listResultModal.length > 0 ? (
                <>
                  {listResultModal.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className="center">{index + 1}</td>
                        <td>{item.sh ? item.sh.name : item.name}</td>
                        <td>
                          {item.sh
                            ? item.sh.identityNumber
                            : item.identityNumber}
                        </td>
                        {kind !== 4 && (
                          <td>{item.sh ? item.sh.sharesNumber : ''}</td>
                        )}
                        {kind !== 4 && (
                          <td>
                            {item.sh && item.sh.isCheckedIn
                              ? item.sh.sharesNumber === 0
                                ? transformNumberPrinter(
                                    item.sh.sharesJoinNumber +
                                      item.sh.sharesAuthorizedNumber,
                                  )
                                : transformNumberPrinter(
                                    item.sh.sharesJoinNumber,
                                  )
                              : 0}
                          </td>
                        )}
                        {kind === 4 && filterModal.voteStatus === 'voted' && (
                          <td>
                            {transformNumberPrinter(findVoteAmount(item))}
                          </td>
                        )}
                      </tr>
                    );
                  })}
                  <tr>
                    <td colSpan={100}>
                      <Pagination
                        handleChangePageSize={changeSize}
                        current={filterModal.page}
                        total={listResultTotal}
                        pageSize={filterModal.take}
                        onChange={changePage}
                      />
                    </td>
                  </tr>
                </>
              ) : (
                <tr>
                  <td colSpan={100} className="center">
                    {t('Không có dữ liệu')}
                  </td>
                </tr>
              )}
            </>
          </Table>
        </>
      )}
    </SModal>
  );
};
