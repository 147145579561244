import * as types from './constants';
import * as common from './../../../../common/constants';

var initialState = {
  tableList: [],
  listTopicChoose: [], 
  lockShareholder: null, // store shareholder to lock/unlock
  filter: {
    identityNumber: '',
    status: 'all', //status: all, boss, notBoss, public, notPublic
    pageSize: common.PAGE_SIZE,
    pageNumber: 1,
    typeList: 'opinion', //all -opinion
    blockStatus:'all'
  },
  total: 0,
  toggleModal:false,
  chatbox:[],
};

var reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST:
      return {...state, tableList: action.value}; 
    case types.SET_CHOOSE_LIST:
      return {...state, listTopicChoose: action.value};
    case types.SET_FILTER:
      return {...state, filter: action.value};
    case types.SET_TOTAL:
      return {...state, total: action.value};
    case types.SET_TOGGLE_MODAL:
      return {...state, toggleModal: action.value};
    case types.SET_CHATBOX:
      return {...state, chatbox: action.value};
    case types.SET_LOCK_SHAREHOLDER:
      return {...state, lockShareholder: action.value};
    default:
      return state;
  }
};
export default reducer;
