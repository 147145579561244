export const URL_TOPIC = "check-coupon/data-report";
export const URL_REPORT = "check-in/report";





export const GET_TOPIC = "GET_SECRETARY_RESOLUTION_TOPIC";
export const GET_REPORT = "GET_SECRETARY_RESOLUTION_REPORT";





