import React, { useEffect } from 'react';
import {
  getUserApi,
  setChooseList,
  setFilter,
  getListAccount,
  setTotal,
} from '../../../../states/duck/pages/admin/shareholderStatusReducer/actions';
import Table from '@components/table';
import Input from '@components/input';
import { useDispatch, useSelector } from 'react-redux';
import { findIndex, transformNumberPrinter } from '../../../bases/configs';
import { PAGE_SIZE } from '../../../../states/common/constants';
import Pagination from '@components/pagination';
import { useTranslation } from 'react-i18next';

const TableContent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const table = useSelector((state) => state.admin.shareholderStatus.tableList);
  const total = useSelector((state) => state.admin.shareholderStatus.total);
  const listChose = useSelector(
    (state) => state.admin.shareholderStatus.listTopicChoose,
  );
  const filter = useSelector((state) => state.admin.shareholderStatus.filter);
  const theads = [
    { name: '#' },
    { name: 'Mã CĐ' },
    { name: 'Họ tên Cổ đông' },
    { name: 'CMND/CCCD' },
    { name: 'Số điện thoại' },
    {
      name: 'CP sở hữu',
      sortUp: () => onSort('sharesNumber', 'asc'),
      sortDown: () => onSort('sharesNumber', 'desc'),
    },
    {
      name: 'CP tham dự',
      sortUp: () => onSort('sharesJoinNumber', 'asc'),
      sortDown: () => onSort('sharesJoinNumber', 'desc'),
    },
    { name: 'Email' },
    { name: 'Loại hình tham dự' },
    { name: 'Loại hình CĐ' },
    { name: 'Trạng thái biểu quyết' },
    { name: 'Trạng thái hoạt động' },
  ];
  const onSort = (sortBy, order) => {
    // sortBy : code , sharesNumber, sharesJoinNumber
    // order: asc, desc
    dispatch(
      setFilter({
        ...filter,
        sortBy,
        order,
        pageSize: PAGE_SIZE,
        pageNumber: 1,
      }),
    );
  };
  useEffect(() => {
    dispatch(setChooseList([]));
    dispatch(getUserApi(filter));
  }, [dispatch, filter]);
  const choseCheckBox = (item) => {
    var newListChose = [...listChose];
    if (findIndex(item, newListChose) < 0) {
      newListChose = [];
      newListChose.push(item);
    } else {
      newListChose.splice(newListChose.indexOf(item), 1);
    }
    dispatch(setChooseList(newListChose));
  };
  useEffect(() => {
    return () => {
      dispatch(
        setFilter({
          identityNumber: '',
          joinType: 'all',
          status: 'all',
          pageSize: PAGE_SIZE,
          pageNumber: 1,
        }),
      );
      dispatch(setChooseList([]));
      dispatch(getListAccount([]));
      dispatch(setTotal(0));
    };
  }, [dispatch]);
  const getPage = (pageNumber) => {
    dispatch(
      setFilter({
        ...filter,
        pageNumber,
      }),
    );
  };
  const getPageSize = (pageSize) => {
    dispatch(
      setFilter({
        ...filter,
        pageSize,
        pageNumber: 1,
      }),
    );
  };

  return (
    <>
      <Table header={theads} minWidth="1250px">
        {table.length > 0 ? (
          <>
            {table.map((item, index) => {
              console.log('item', item.joinType);
              return (
                <tr
                  key={index}
                  onClick={() => choseCheckBox(item)}
                  className={findIndex(item, listChose) >= 0 ? 'active' : ''}
                >
                  <td>
                    <Input.Checkbox
                      checked={findIndex(item, listChose) >= 0 ? true : false}
                    />
                  </td>
                  <td>{item.code}</td>
                  <td>{item.name}</td>
                  <td>{item.identityNumber}</td>
                  <td>{item.phone}</td>
                  <td>{transformNumberPrinter(item.sharesNumber)}</td>
                  <td>
                    {item.isCheckedIn
                      ? item.sharesNumber === 0
                        ? transformNumberPrinter(
                            item.sharesJoinNumber + item.sharesAuthorizedNumber,
                          )
                        : transformNumberPrinter(item.sharesJoinNumber)
                      : 0}
                  </td>
                  <td>{item.email}</td>
                  <td>
                    {item.joinType === 'authority'
                      ? t('Ủy quyền')
                      : item.joinType === 'live'
                      ? t('Trực tiếp')
                      : item.joinType === 'online'
                      ? t('Trực tuyến')
                      : item.joinType === 'none'
                      ? t('Chưa checkin')
                      : ''}
                  </td>
                  <td>
                    {item.isAuthority && item.sharesNumber === 0
                      ? t('Nhận ủy quyền')
                      : t('Cổ đông')}
                  </td>
                  <td>
                    {item.invalidVoted === true
                      ? t('Đã biểu quyết')
                      : item.isVoted
                      ? t('Đã biểu quyết')
                      : t('Chưa biểu quyết')}
                  </td>
                  <td>
                    {item.online ? t('Đang hoạt động') : t('Không hoạt động')}
                  </td>
                </tr>
              );
            })}
          </>
        ) : (
          <tr>
            <td colSpan={100} className="center">
              {' '}
              {t('Chưa có dữ liệu')}{' '}
            </td>
          </tr>
        )}
      </Table>
      {table.length > 0 && (
        <Pagination
          handleChangePageSize={(size) => {
            getPageSize(size);
          }}
          current={filter.pageNumber}
          total={total}
          pageSize={filter.pageSize}
          onChange={(page) => {
            getPage(page);
          }}
        />
      )}
    </>
  );
};
export default TableContent;
