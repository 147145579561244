export const URL = "info/shareholder";
export const URL_ADDRESS_BY_ID = "info/address";
export const URL_PRINT = "invitation/generate-pdf";
export const URL_DOWNLOAD = "shareholder/internal/download/sample-file";
export const URL_CREATE_INTERNAL = "shareholder/internal/create";
export const URL_FILTER_INTERNAL = "shareholder/internal/filter";
export const URL_CONFIRM_INVITATION = "invitation/confirm-done";
export const URL_INFORMATION_CONFERENCE = "conference";
export const URL_DOWNLOAD_PASSWORD_LIST = "invitation/data";




export const GET_LIST = "GET_SHAREHOLDER_INVITATION_MANAGER_LIST";
export const SET_PASSWORD_LIST = "SET_SHAREHOLDER_INVITATION_MANAGER_PASSWORD_LIST";

export const SET_PAGE_SIZE = "SET_SHAREHOLDER_INVITATION_MANAGER_SIZE";
export const SET_PAGE_NUMBER = "SET_SHAREHOLDER_INVITATION_MANAGER_NUMBER";
export const SET_FILTER = "SET_SHAREHOLDER_INVITATION_MANAGER_FILTER";
export const SET_TOTAL = "SET_SHAREHOLDER_INVITATION_MANAGER_TOTAL";
export const SET_TYPE = "SET_SHAREHOLDER_INVITATION_MANAGER_TYPE";
export const SET_ADDRESS = "SET_SHAREHOLDER_INVITATION_MANAGER_ADDRESS";
export const GET_INFO = "GET_SHAREHOLDER_INVITATION_MANAGER_INFO";
export const GET_TOGGLE_MODAL = "GET_SHAREHOLDER_INVITATION_MANAGER_TOGGLE_MODAL";
export const SET_DOWNLOAD_PASSWORD_LIST = "SET_SHAREHOLDER_INVITATION_MANAGER_DOWNLOAD_PASSWORD_LIST";
export const SET_LOADING = "SET_SHAREHOLDER_INVITATION_MANAGER_LOADING";






