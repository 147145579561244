import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { getSearchApi } from 'states/duck/pages/checkin/checkInHandReducer/actions';
import { SFrom } from '@styled/checkIn/checkInHandPage';
import inputIcons from '@components/icons/input';
import Input from '@components/input';
import ErrorMessageComponent from '@components/input/ErrorMessage';
import Button from '@components/button';
import { useTranslation } from 'react-i18next';

const Form = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm();
  const dispatch = useDispatch();
  const { filter } = useSelector((state) => state.checkin.checkInHand);
  const { t } = useTranslation();

  const searchFn = (values) => {
    dispatch(
      getSearchApi({
        ...filter,
        identityNumber: values.idCard,
        pageNumber: 1,
      }),
    );
  };

  return (
    <SFrom>
      <h1 className="title">{t('Ủy quyền thủ công')}</h1>
      <form className="form-input" onSubmit={handleSubmit(searchFn)}>
        <div className="item">
          <Input.Text
            wrapperProps={{
              label: `${t('Họ tên/CMND/CCCD/SĐT')}`,
              icon: inputIcons.code,
            }}
            inputProps={{
              type: 'text',
            }}
            register={register('idCard', {
              required: false,
            })}
          />
          <ErrorMessageComponent errors={errors} field="idCard" />
        </div>

        <div className="item">
          <Button className="common__btn" type="submit">
            {t('Tìm kiếm')}
          </Button>
        </div>
      </form>
    </SFrom>
  );
};

export default Form;
