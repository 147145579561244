import * as types from './constants';
import * as common from './../../../../common/constants';
var initialState = {
  tableList: [],
  listTopicChoose: [],
  toggleModal: false,
  filter: {
    identityNumber: '',
    joinType: 'all',
    status: 'all',
    pageSize: common.PAGE_SIZE,
    pageNumber: 1,
    online: 'all',
    sortBy: 'code',
    order: 'asc',
    authority: 'all',
  },
  total: 0,
  shareholderDetails: {},
  topic1: [],
  topic2: [],
  topic3: [],
  topic4: [],
  topic6: [],
  listAuthorities: [],
};
var reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_TOPIC1:
      return { ...state, topic1: action.value };
    case types.GET_TOPIC2:
      return { ...state, topic2: action.value };
    case types.GET_TOPIC3:
      return { ...state, topic3: action.value };
    case types.GET_TOPIC4:
      return { ...state, topic4: action.value };
    case types.GET_TOPIC6:
      return { ...state, topic6: action.value };
    case types.GET_LIST:
      return { ...state, tableList: action.value };
    case types.SET_DETAILS:
      return { ...state, shareholderDetails: action.value };
    case types.TOGGLE_MODAL:
      return { ...state, toggleModal: action.value };
    case types.SET_CHOOSE_LIST:
      return { ...state, listTopicChoose: action.value };
    case types.SET_FILTER:
      return { ...state, filter: action.value };
    case types.SET_TOTAL:
      return { ...state, total: action.value };
    case types.GET_LIST_AUTHORITY:
      return { ...state, listAuthorities: action.value };
    default:
      return state;
  }
};
export default reducer;
