import React from 'react';
import { getInitialProps } from 'react-i18next';
import styled from 'styled-components';

export default function TextArea({ register, ...props }) {
  return <STextArea {...register} {...props} rows={7}></STextArea>;
}

const STextArea = styled.textarea`
  background-color: #fff;
  border: 1px solid #dfe9f5;
  box-shadow: none;
  border-radius: 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #718098;
  padding: 10px 16px;
  height: 120px;
`;
