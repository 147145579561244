import React from 'react';
import Section from '@components/section';
import { useTranslation } from 'react-i18next';
import { ConfigPage } from '@styled/admin/configSystem';
import ShareHolderSystem from './shareholderSystem';
import Voting from './voting';
import AdminSystem from './adminSystem';
import AutoMessage from './configAutoMessage';

export default function ConfigSystem() {
  const { t } = useTranslation();
  return (
    <ConfigPage>
      <Section header={t('Cấu hình hệ thống')}>
        <ShareHolderSystem />
        <Voting />
        <AutoMessage />
        <AdminSystem /> 
      </Section>
    </ConfigPage>
  );
}
