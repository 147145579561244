import * as constants from '../../../../bases/constants';
import inputIcons from '../../../../bases/shared/icons/input';

export const inputs = [
  {
    name: 'name',
    require: constants.VALIDATE_REQUIRE,
    rules: null,
    label: 'Cổ đông/ người đại diện',
    icon: inputIcons.user,
    type: 'text',
    nested: false,
  },
  {
    name: 'identityNumber',
    require: constants.VALIDATE_REQUIRE,
    rules: null,
    label: 'ĐKSH/CMND/CCCD',
    icon: inputIcons.user,
    type: 'text',
    nested: false,
  },
  {
    name: 'phone',
    require: false,
    rules: null,
    label: 'Số điện thoại',
    icon: inputIcons.phone,
    type: 'tel',
  },
  {
    name: 'identityDate',
    require: false,
    rules: null,
    label: 'Ngày cấp',
    icon: inputIcons.email,
    type: 'text',
  },
  {
    name: 'email',
    require: false,
    rules: null,
    label: 'Email',
    icon: inputIcons.email,
    type: 'text',
  },
  {
    name: 'identityAddress',
    require: false,
    rules: null,
    label: 'Nơi cấp',
    icon: inputIcons.map,
    type: 'text',
  },
  {
    name: 'sharesNumber',
    require: false,
    rules: null,
    label: 'Cổ phần sở hữu',
    icon: inputIcons.company,
    type: 'number',
  },
  {
    name: 'totalShareholder',
    require: false,
    rules: null,
    label: 'Tổng số cổ phần đại diện',
    icon: inputIcons.company,
    type: 'number',
  },
];
