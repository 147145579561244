import { setUserInfo } from '@duck/pages/loginPage/actions';
import { callApi } from '@state-common/index';
import { UINotify } from '@state-common/notify';
import store from 'states/store';

export const postPermission = async (accounts, permissions, callback) => {
  const temp = accounts.map((account) =>
    callApi('admin/set-permission/' + account._id, 'POST', {
      permissions,
    }),
  );
  const res = await Promise.all(temp);
  if (res.every((r) => r?.data?.success)) {
    UINotify.success('Phân quyền tài khoản thành công');
    callback && callback();
  }
};

export const getUserInfo = async () => {
  const res = await callApi('auth/me', 'GET');
  const userInfo = res?.data?.data || {};
  store.dispatch(setUserInfo(userInfo));
};
