import * as constants from './constants';
import { callApi, callExportApi } from './../../../../common';
import PNotify from 'pnotify/dist/es/PNotify';
import { handleError } from '@configs/handleError';
var FileSaver = require('file-saver');

export const getUserApi = (body) => {
  //jointype : chua checkin uy quyen truc tiep truc tuyen
  //status: da bieu quyet chua bieu quyet
  //authority Cổ đông uq nhận uq
  //online: true false
  // /info/shareholder/:pageSize/:pageNumber thêm sortBy (code, sharesNumber, sharesJoinNumber) order (asc, desc)

  const { identityNumber, pageSize, pageNumber, type } = body;
  const bodySend = {
    identityNumber,
    type,
  };
  return (dispatch) =>
    callApi(
      `${constants.URL}/${pageSize}/${pageNumber}`,
      'POST',
      bodySend,
    ).then(function (res) {
      console.log('res', res);
      dispatch(getListAccount(res.data.data.shareholders));
      dispatch(setTotal(res.data.data.total));
    });
};

export const exportShareholderApi = (body) => {
  const { identityNumber, joinType, status, authority, sortBy, order, online } =
    body;
  const bodySend = {
    identityNumber,
    joinType,
    status,
    authority,
    online,
    sortBy,
    order,
  };
  return (dispatch) =>
    callExportApi(`${constants.URL_EXPORT}`, 'POST', bodySend)
      .then(function (res) {
        PNotify.success({
          title: 'Thành công',
          text: 'Xuất danh sách Cổ đông thành công!',
          destroy: true,
          delay: 2000,
        });
        var file = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        FileSaver.saveAs(file, 'DSCoDong');
      })
      .catch(handleError);
};

export const getListAccount = (value) => {
  return {
    type: constants.GET_LIST,
    value,
  };
};

export const setFilter = (value) => {
  return {
    type: constants.SET_FILTER,
    value,
  };
};
export const setTotal = (value) => {
  return {
    type: constants.SET_TOTAL,
    value,
  };
};
