import * as types from './constants';
var initialState = {
  token: localStorage.getItem('token'),
  otpForm: false,
  phone: '',
  role: '0',
  timeRestart: false,
  userInfo: {},
};
var reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_AUTH_TOKEN:
      return { ...state, token: action.value };
    case types.SET_OTP_FORM:
      return { ...state, otpForm: action.value };
    case types.LOGOUT:
      return { ...state, token: '' };
    case types.SET_OTP_PHONE:
      return { ...state, phone: action.value };
    case types.SET_ROLE:
      return { ...state, role: action.value };
    case types.SET_TIME_RESTART:
      return { ...state, timeRestart: action.value };
    case types.SET_USER_INFO:
      return { ...state, userInfo: action.value };
    default:
      return state;
  }
};
export default reducer;
