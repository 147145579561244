import React, { useState, useEffect } from 'react';
import PNotify from 'pnotify/dist/es/PNotify';
import { useSelector, useDispatch } from 'react-redux';
import { callApi } from '../../../../../states/common/index';
import {
  checkInAuthorityApi,
  getListAuthorized,
} from '../../../../../states/duck/pages/checkin/checkInHandReducer/actions';
import { Loading } from '../../../../bases/shared';
import Input from '@components/input';
import inputIcons from '@components/icons/input';
import ErrorMessageComponent from '@components/input/ErrorMessage';
import { useForm } from 'react-hook-form';
import { SAddAuthorityModal } from '@styled/checkIn/checkInHandPage';
import debounce from 'lodash.debounce';
import {
  VALIDATE_ONLY_NUMBER,
  // VALIDATE_ONLY_NUMBER_INTEGER,
  VALIDATE_REQUIRE,
} from '@constants/validate';
import { useTranslation } from 'react-i18next';

const AddAuthorityModalTest = ({
  reloadAfterAutho,
  setReloadAfterAutho,
  shareholder,
  onClick,
  authorityList,
  item,
  setShareholder,
}) => {
  const authorityDefault = {
    // checkInType: 'authority',
    phone: '',
    identityNumber: '',
    name: '',
    email: '',
    password: '',
    sharesNumber: 0,
  };
  const { t } = useTranslation();
  const [data, setData] = useState([authorityDefault]);
  // console.log('data: ', data);
  const [period, setPeriod] = useState(false);
  const dispatch = useDispatch();

  const admin = JSON.parse(localStorage.getItem('adminId'));
  const processorId = admin && admin.adminId ? admin.adminId : '';

  const [unableShareholder, setUnableShareholder] = useState([]);
  const report2 = useSelector((state) => state.checkin.checkInHand.report2);

  const listAuthorized = useSelector(
    (state) => state.checkin.checkInHand.listAuthorized,
  );

  useEffect(() => {
    dispatch(getListAuthorized([]));
  }, [dispatch]);

  console.log('listAuthorized', listAuthorized);

  const [submit, setSubmit] = useState(false);

  const addAuthorutyItem = () => {
    const dataMirror = [...data];
    dataMirror.push(authorityDefault);
    setData(dataMirror);
    setPeriod(false);
  };

  const deleteAuthorityItem = (index) => {
    let dataClone = [...data];
    dataClone.splice(index, 1);
    setData(dataClone);
  };

  const changeAuthorityItem = (position, authorityItem) => {
    let dataClone = [...data];
    dataClone.splice(position, 1, authorityItem);
    setData(dataClone);
  };

  const onSubmit = async () => {
    setSubmit(true);

    let body = {
      shareholderId: item._id,
      processorId,
      data: [],
    };

    let checkInteger = 0;

    for (let i = 0; i < data.length; i++) {
      if (
        data[i].sharesNumber.toString().indexOf('.') !== -1 ||
        data[i].sharesNumber.toString().indexOf(',') !== -1
      ) {
        checkInteger += 1;
      }
    }

    if (checkInteger > 0) {
      body.data = [];
      PNotify.error({
        title: t('Lỗi'),
        text: t('Số cổ phần ủy quyền không được là số lẻ'),
        destroy: true,
        delay: 2000,
      });
    } else {
      body.data = [...data];
      dispatch(
        checkInAuthorityApi(
          reloadAfterAutho,
          setReloadAfterAutho,
          onClick,
          body,
        ),
      );
    }

    setSubmit(false);
  };

  useEffect(() => {
    let unableShareholderMirror = [];
    for (let i = 0; i < report2.length; i++) {
      for (let j = 0; j < report2[i].unableShareholder.length; j++) {
        if (
          unableShareholderMirror.indexOf(report2[i].unableShareholder[j]) < 0
        ) {
          unableShareholderMirror.push(report2[i].unableShareholder[j]);
        }
      }
    }
    setUnableShareholder(unableShareholderMirror);
  }, [report2]);

  return (
    <SAddAuthorityModal>
      {submit && <Loading title={t('Đang xử lý. Vui lòng đợi')} />}
      {data.map((itemAuthority, index) => {
        return (
          <AuthorityItem
            shareholder={shareholder}
            authorityList={authorityList}
            itemAuthority={itemAuthority}
            key={index}
            period={period}
            setPeriod={setPeriod}
            position={index}
            deleteAuthorityItem={deleteAuthorityItem}
            changeAuthorityItem={changeAuthorityItem}
            unableShareholder={unableShareholder}
            dataLength={data.length}
          />
        );
      })}

      <div className="button_add">
        <button className="add_btn" type="button" onClick={addAuthorutyItem}>
          + {t('Thêm người được ủy quyền')}
        </button>
      </div>

      <div className="button_common">
        <button className={`common__btn`} type="submit" onClick={onSubmit}>
          {t('Thêm')}
        </button>
      </div>
    </SAddAuthorityModal>
  );
};

export default AddAuthorityModalTest;

const AuthorityItem = ({
  shareholder,
  itemAuthority,
  authorityList,
  position,
  setPeriod,
  period,
  deleteAuthorityItem,
  changeAuthorityItem,
  unableShareholder,
  dataLength,
}) => {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const { t } = useTranslation();

  const data = watch([
    'identityNumber',
    'name',
    'phone',
    'email',
    'sharesNumber',
    'password',
  ]);
  const [remainingState, setRemainingState] = useState(0);

  const identityNumber = data[0] ? data[0] : '';
  const name = data[1] ? data[1] : '';
  const phone = data[2] ? data[2] : '';
  const email = data[3] ? data[3] : '';
  const sharesNumber = data[4] ? data[4] : '';
  const password = data[5] ? data[5] : '';

  // const [remainingState, setRemainingState] = useState(0);
  // tính số uỷ quyền còn lại
  useEffect(() => {
    let remaining = shareholder.sharesNumber;
    if (authorityList !== []) {
      for (let i = 0; i < authorityList.length; i++) {
        remaining = remaining - authorityList[i].sharesNumber;
      }
    }
    setRemainingState(remaining);
  }, [authorityList, shareholder.sharesNumber]);

  useEffect(() => {
    setValue('sharesNumber', remainingState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainingState]);

  const [nameAfterAuthority, setNameAfterAuthority] = useState('');
  // thay đổi tên cổ đông set lại CMND
  useEffect(() => {
    if (nameAfterAuthority !== '' && nameAfterAuthority !== name) {
      setValue('identityNumber', '');
      setValue('phone', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, setValue]);

  //Tổng số cổ phần ủy quyền không được lớn hơn số cổ phần khả dụng và > 0
  useEffect(() => {
    if (sharesNumber !== '') {
      let sumAuthorityShares = authorityList.reduce((a, b) => {
        return a + Number(b.sharesNumber);
      }, 0);
      let sharesNumberShareholder =
        shareholder.sharesNumberHistories[0].sharesNumber ||
        shareholder.sharesNumber;

      if (Number(sharesNumber) <= 0) {
        PNotify.error({
          title: t('Lỗi'),
          text: t('Số cổ phần ủy quyền phải lớn hơn 0'),
          destroy: true,
          delay: 2000,
        });
        setValue('sharesNumber', '');
      } else if (
        Number(sumAuthorityShares) + Number(sharesNumber) >
        sharesNumberShareholder
      ) {
        PNotify.error({
          title: t('Lỗi'),
          text: t(
            'Tổng số cổ phần ủy quyền không được lớn hơn số cổ phần khả dụng',
          ),
          destroy: true,
          delay: 2000,
        });
        setValue('sharesNumber', '');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    authorityList,
    shareholder.sharesNumber,
    shareholder.sharesNumberHistories,
    sharesNumber,
    setValue,
  ]);

  //Không thể ủy quyền cho Cổ đông bị chặn ở 1 trong các tờ trình biểu quyết
  useEffect(() => {
    if (identityNumber) {
      if (unableShareholder.indexOf(identityNumber) >= 0) {
        setValue('identityNumber', '');
        PNotify.error({
          title: t('Lỗi'),
          text: t(
            'Không thể ủy quyền cho Cổ đông bị chặn ở 1 trong các tờ trình biểu quyết',
          ),
          destroy: true,
          delay: 2000,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identityNumber, unableShareholder, setValue]);

  useEffect(() => {
    let authorityData = {
      ...itemAuthority.authorityData,
      identityNumber: identityNumber,
      name: name,
      phone: phone,
      email: email,
      sharesNumber: Number(sharesNumber),
      password: password,
    };

    // console.log('authorityData: ', authorityData);
    changeAuthorityItem(position, { ...itemAuthority, ...authorityData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identityNumber, name, phone, email, sharesNumber, password]);

  const onIdNumberChange = (e) => {
    handleGetShareholderByIDNum(e.target ? e.target.value : '');
  };

  const handleGetShareholderByIDNum = React.useCallback(
    debounce((val) => {
      if (val !== '') {
        callApi('info/shareholder/10/1', 'POST', {
          identityNumber: val,
          joinType: 'all',
          status: 'all',
        }).then((res) => {
          let shareHoderSearch = res.data.data.shareholders;
          // console.log('shareHoderSearch: ', shareHoderSearch);
          // console.log(
          //   'shareHoderSearch[0].isVoted: ',
          //   shareHoderSearch[0].isVoted,
          // );
          // if (shareHoderSearch.length > 0 && shareHoderSearch[0].isVoted) {
          // PNotify.error({
          //   title: 'lỗi',
          //   text: 'Không thể ủy quyền cho cổ đông đã biểu quyết',
          //   destroy: true,
          //   delay: 2000,
          // });
          // setNameAfterAuthority('');
          // setValue('name', '');
          // setValue('phone', '');
          // setValue('email', '');
          // } else {
          if (shareHoderSearch.length > 0) {
            setNameAfterAuthority(shareHoderSearch[0].name);
            setValue('name', shareHoderSearch[0].name);
            setValue('phone', shareHoderSearch[0].phone);
            setValue('email', shareHoderSearch[0].email);
          } else {
            setNameAfterAuthority('');
            setValue('name', '');
            setValue('phone', '');
            setValue('email', '');
          }
          // }
        });
      }
    }, 600),
    [],
  );

  return (
    <form className="form-content">
      <button
        className="clear_btn"
        type="button"
        onClick={
          dataLength !== 1 ? () => deleteAuthorityItem(position) : () => {}
        }
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 10C0 4.47 4.47 0 10 0C15.53 0 20 4.47 20 10C20 15.53 15.53 20 10 20C4.47 20 0 15.53 0 10ZM12.89 14.3C13.28 14.69 13.91 14.69 14.3 14.3C14.68 13.91 14.68 13.27 14.3 12.89L11.41 10L14.3 7.11C14.69 6.72 14.69 6.09 14.3 5.7C13.91 5.31 13.28 5.31 12.89 5.7L9.99998 8.59L7.10998 5.7C6.71998 5.31 6.08998 5.31 5.69998 5.7C5.51273 5.88683 5.4075 6.14048 5.4075 6.405C5.4075 6.66952 5.51273 6.92317 5.69998 7.11L8.58998 10L5.69998 12.89C5.51273 13.0768 5.4075 13.3305 5.4075 13.595C5.4075 13.8595 5.51273 14.1132 5.69998 14.3C6.08998 14.69 6.71998 14.69 7.10998 14.3L9.99998 11.41L12.89 14.3Z"
            fill="#FF0000"
          />
        </svg>
      </button>

      <div className="list_input">
        <div className="bs-row row-sm-15">
          <div className="bs-col sm-50-15">
            <div className="authority-info-item">
              <Input.Text
                wrapperProps={{
                  label: t('CMND/CCCD (Tài khoản)'),
                  icon: inputIcons.code,
                }}
                inputProps={{
                  name: 'identityNumber',
                }}
                register={register('identityNumber', {
                  required: t(VALIDATE_REQUIRE),
                  onChange: onIdNumberChange,
                })}
              />
              <ErrorMessageComponent errors={errors} field="identityNumber" />
            </div>
          </div>

          <div className="bs-col sm-50-15">
            <div className="authority-info-item">
              <Input.Text
                wrapperProps={{
                  label: t('Họ tên người được ủy quyền'),
                  icon: inputIcons.men,
                }}
                inputProps={{
                  type: 'text',
                }}
                register={register('name', {
                  required: t(VALIDATE_REQUIRE),
                })}
              />
              <ErrorMessageComponent errors={errors} field="name" />
            </div>
          </div>

          <div className="bs-col sm-50-15">
            <div className="authority-info-item">
              <Input.Text
                wrapperProps={{
                  label: t('Số điện thoại'),
                  icon: inputIcons.phone,
                }}
                inputProps={{
                  type: 'number',
                }}
                register={register('phone')}
              />
              <ErrorMessageComponent errors={errors} field="phone" />
            </div>
          </div>

          <div className="bs-col sm-50-15">
            <div className="authority-info-item">
              <Input.Text
                wrapperProps={{
                  label: t('Email'),
                  icon: inputIcons.email,
                }}
                inputProps={{
                  type: 'text',
                  autoComplete: 'off',
                }}
                register={register('email')}
              />
              <ErrorMessageComponent errors={errors} field="email" />
            </div>
          </div>

          <div className="bs-col sm-50-15">
            <div className="authority-info-item">
              <Input.Text
                wrapperProps={{
                  label: `${t('Số cổ phần được ủy quyền')} (${t(
                    'còn lại',
                  )} ${remainingState})`,
                  icon: inputIcons.file,
                }}
                inputProps={{
                  type: 'number',
                  pattern: VALIDATE_ONLY_NUMBER,
                }}
                register={register('sharesNumber', {
                  required: t(VALIDATE_REQUIRE),
                  // pattern: VALIDATE_ONLY_NUMBER,
                })}
              />
              <ErrorMessageComponent errors={errors} field="sharesNumber" />
            </div>
          </div>

          <div className="bs-col sm-50-15">
            <div className="authority-info-item">
              <Input.Text
                wrapperProps={{
                  label: t('Mật khẩu'),
                  icon: inputIcons.code,
                }}
                inputProps={{
                  type: 'password',
                  // autoComplete: "off",
                }}
                register={register('password')}
              />
              <ErrorMessageComponent errors={errors} field="password" />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

// const findIndex = (identityNumber, arr) => {
//   let index = -1;
//   for (let i = 0; i < arr.length; i++) {
//     if (identityNumber === arr[i].identityNumber) {
//       index = i;
//     }
//   }
//   return index;
// };
