import * as constants from './constants';
import { callApi } from './../../../../common';
import PNotify from 'pnotify/dist/es/PNotify';
import { handleError } from '@configs/handleError';

export const setTimeApi = (body) => {
  return (dispatch) =>
    callApi(`${constants.URL_SETTING}`, 'POST', body)
      .then(function (res) {
        // dispatch(getInfoConferenceApi());
        dispatch(getTimeSettingApi());
        PNotify.success({
          title: 'Hoàn thành',
          text: 'Cập nhật thời gian thành công',
          destroy: true,
          delay: 2000,
        });
      })
      .catch(handleError);
};
export const setTimeCommentApi = (body) => {
  return (dispatch) =>
    callApi(`${constants.URL_SETTING_TIME_COMMENT}`, 'POST', body)
      .then(function (res) {
        dispatch(getInfoConferenceApi());
        PNotify.success({
          title: 'Hoàn thành',
          text: 'Cập nhật thời gian phiên thảo luận thành công',
          destroy: true,
          delay: 2000,
        });
      })
      .catch(handleError);
};

export const setToggleCommentApi = (body) => {
  return (dispatch) =>
    callApi(`${constants.URL_TOGGLE_COMMENT}/${body}`, 'POST', null)
      .then(function (res) {
        dispatch(getInfoConferenceApi());
        PNotify.success({
          title: 'Hoàn thành',
          text: `${!body ? 'Đóng' : 'Mở'} thành công`,
          destroy: true,
          delay: 2000,
        });
      })
      .catch(handleError);
};

export const getInfoConferenceApi = () => {
  return (dispatch) =>
    callApi(`${constants.URL_INFO}`, 'GET', null).then(function (res) {
      dispatch(getInfo(res.data.conference.conference));
    });
};

//-------start
//-------
export const getListTopic1Api = () => {
  return (dispatch) =>
    callApi(`${constants.URL_TOPIC1_LIST}`, 'GET', null).then(function (res) {
      dispatch(getListTopic1(res.data.data.data));
    });
};
export const getListTopic2Api = () => {
  return (dispatch) =>
    callApi(`${constants.URL_TOPIC2_LIST}`, 'GET', null).then(function (res) {
      dispatch(getListTopic2(res.data.data.data));
    });
};
export const getListTopic3Api = () => {
  return (dispatch) =>
    callApi(`${constants.URL_TOPIC3_LIST}`, 'GET', null).then(function (res) {
      dispatch(getListTopic3(res.data.data.data));
    });
};
export const getListTopic4Api = () => {
  return (dispatch) =>
    callApi(`${constants.URL_TOPIC4_LIST}`, 'GET', null).then(function (res) {
      dispatch(getListTopic4(res.data.data.data));
    });
};
export const getListTopic6Api = () => {
  return (dispatch) =>
    callApi(`${constants.URL_TOPIC6_LIST}`, 'GET', null).then(function (res) {
      dispatch(getListTopic6(res.data.data.data));
    });
};
//------end
// -----
export const getInfoTopicPartApi = () => {
  return (dispatch) =>
    callApi(`${constants.URL_TOPIC_INFO}`, 'GET', null)
      .then(function (res) {
        dispatch(getTopicPart(res.data.topic.data));
      })
      .catch(handleError);
};
export const getTimeSettingApi = () => {
  return (dispatch) =>
    callApi(`${constants.URL_GET_TIME_SETTING}`, 'GET', null)
      .then(function (res) {
        dispatch(getTimeSetting(res.data.data));
      })
      .catch(handleError);
};
export const setToggleTopicApi = (body) => {
  return (dispatch) =>
    callApi(`${constants.URL_TOGGLE_TOPIC}`, 'POST', body)
      .then(function (res) {
        dispatch(getInfoTopicPartApi());
        switch (body.kind) {
          case '1':
            dispatch(getListTopic1Api());
            break;
          case '2':
            dispatch(getListTopic2Api());
            break;
          case '3':
            dispatch(getListTopic3Api());
            break;
          case '4':
            dispatch(getListTopic4Api());
            break;
          case '6':
            dispatch(getListTopic6Api());
            break;
          default:
            break;
        }
        PNotify.success({
          title: 'Hoàn thành',
          text: `${body.toggleType === 'close' ? 'Đóng' : 'Mở'} thành công`,
          destroy: true,
          delay: 2000,
        });
      })
      .catch(handleError);
};

///-----start
//--------
export const setToggleTopic1Api = (item) => {
  return (dispatch) =>
    callApi(`${constants.URL_TOGGLE_TOPIC2}/${item._id}`, 'POST', {
      toggleType: item.isOpen ? 'close' : 'open',
    })
      .then(function () {
        dispatch(getListTopic1Api());
        PNotify.success({
          title: 'Hoàn thành',
          text: `${item.isOpen ? 'Đóng' : 'Mở'} thành công`,
          destroy: true,
          delay: 2000,
        });
      })
      .catch(handleError);
};
export const setToggleTopic2Api = (item) => {
  return (dispatch) =>
    callApi(`${constants.URL_TOGGLE_TOPIC2}/${item._id}`, 'POST', {
      toggleType: item.isOpen ? 'close' : 'open',
    })
      .then(function () {
        dispatch(getListTopic2Api());
        PNotify.success({
          title: 'Hoàn thành',
          text: `${item.isOpen ? 'Đóng' : 'Mở'} thành công`,
          destroy: true,
          delay: 2000,
        });
      })
      .catch(handleError);
};
export const setToggleTopic3Api = (item) => {
  return (dispatch) =>
    callApi(`${constants.URL_TOGGLE_TOPIC2}/${item._id}`, 'POST', {
      toggleType: item.isOpen ? 'close' : 'open',
    })
      .then(function () {
        dispatch(getListTopic3Api());
        PNotify.success({
          title: 'Hoàn thành',
          text: `${item.isOpen ? 'Đóng' : 'Mở'} thành công`,
          destroy: true,
          delay: 2000,
        });
      })
      .catch(handleError);
};
export const setToggleTopic4Api = (item) => {
  return (dispatch) =>
    callApi(`${constants.URL_TOGGLE_TOPIC2}/${item._id}`, 'POST', {
      toggleType: item.isOpen ? 'close' : 'open',
    })
      .then(function () {
        dispatch(getListTopic4Api());
        PNotify.success({
          title: 'Hoàn thành',
          text: `${item.isOpen ? 'Đóng' : 'Mở'} thành công`,
          destroy: true,
          delay: 2000,
        });
      })
      .catch(handleError);
};
export const setToggleTopic6Api = (item) => {
  return (dispatch) =>
    callApi(`${constants.URL_TOGGLE_TOPIC2}/${item._id}`, 'POST', {
      toggleType: item.isOpen ? 'close' : 'open',
    })
      .then(function () {
        dispatch(getListTopic6Api());
        PNotify.success({
          title: 'Hoàn thành',
          text: `${item.isOpen ? 'Đóng' : 'Mở'} thành công`,
          destroy: true,
          delay: 2000,
        });
      })
      .catch(handleError);
};

///----end
//---------

export const getTimeConferenceApi = () => {
  return (dispatch) =>
    callApi(`${constants.URL_GET_TIME_CONFERENCE}`, 'GET', null)
      .then(function (res) {
        dispatch(getTimeConference(res.data.data.data));
      })
      .catch(handleError);
};

export const setTimeConferenceApi = (body) => {
  //body: startDate, endDate
  return (dispatch) =>
    callApi(`${constants.URL_SETTING_TIME_CONFERENCE}`, 'POST', body)
      .then(function (res) {
        // dispatch(getTimeConferenceApi());
        PNotify.success({
          title: 'Hoàn thành',
          text: 'Cấu hình thời gian diễn ra Đại hội thành công!',
          destroy: true,
          delay: 2000,
        });
      })
      .catch(handleError);
};

export const setToggleModal = (value) => {
  return {
    type: constants.TOGGLE_MODAL,
    value,
  };
};
export const getTimeSetting = (value) => {
  return {
    type: constants.GET_TIME_SETTING,
    value,
  };
};
export const getInfo = (value) => {
  return {
    type: constants.GET_INFO,
    value,
  };
};
export const getTopicPart = (value) => {
  return {
    type: constants.GET_TOPIC_PART_INFO,
    value,
  };
};
export const getListAccount = (value) => {
  return {
    type: constants.GET_LIST,
    value,
  };
};

///-----start
//----------
export const getListTopic1 = (value) => {
  return {
    type: constants.GET_LIST_TOPIC_1,
    value,
  };
};
export const getListTopic2 = (value) => {
  return {
    type: constants.GET_LIST_TOPIC_2,
    value,
  };
};
export const getListTopic3 = (value) => {
  return {
    type: constants.GET_LIST_TOPIC_3,
    value,
  };
};
export const getListTopic4 = (value) => {
  return {
    type: constants.GET_LIST_TOPIC_4,
    value,
  };
};
export const getListTopic6 = (value) => {
  return {
    type: constants.GET_LIST_TOPIC_6,
    value,
  };
};

///-----end
//-------
export const getTimeConference = (value) => {
  return {
    type: constants.SET_TIME_CONFERENCE,
    value,
  };
};
