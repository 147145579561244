export const VALIDATE_REQUIRE = 'Không được để trống';
export const VALIDATE_EMAIL = 'Email không đúng';
export const VALIDATE_PATTERN = 'Không đúng định dạng';
export const VALIDATE_NO_WHITESPACE =
  'Mật khẩu có tối thiểu 6 ký tự, không được chứa khoảng trắng và kí tự có dấu';
// export const VALIDATE_NO_VETNAMESE = "Không được chứa kí tự có dấu";

// eslint-disable-next-line no-useless-escape
export const VALIDATE_EMAIL_REGEX = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
export const VALIDATE_PHONE_REGEX = /((09|03|07|08|05|01|02|04|)+([0-9]{8})\b)/;
export const VALIDATE_ONLY_NUMBER = /^[0-9]*$/;
export const VALIDATE_ONLY_NUMBER_INTEGER = /^[1-9]+[0-9]*$/;
export const VALIDATE_NO_WHITESPACE_REGEX = /^[^ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\s]*$/;
// export const VALIDATE_NO_VETNAMESE_REGEX = /^[^ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ]*$/;
export const VALIDATE_PHONE = 'Số điện thoại không đúng định dạng';
export const VALIDATE_FAX = 'Số fax không đúng';
// eslint-disable-next-line no-useless-escape
export const VALIDATE_FAX_REGEX = /^(\+?\d{1,}(\s?|\-?)\d*(\s?|\-?)\(?\d{2,}\)?(\s?|\-?)\d{3,}\s?\d{3,})$/;
export const VALIDATE_CLOCK = /^(2[0-3]|[0-1]?[\d]):[0-5][\d]$/;

export const VALIDATE_PASSWORD = /^[A-Za-z0-9!@#$%^&*()_+-=]{6,}$/;