export const URL = 'auth/admin/login';
export const URL_SET_PHONE_TO_OTP = 'auth/get-otp';
export const URL_GET_OTP = 'auth/officer/login';
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const LOGOUT = 'LOGOUT';
export const SET_OTP_FORM = 'SET_OTP_FORM';
export const SET_OTP_PHONE = 'SET_OTP_PHONE';
export const SET_ROLE = 'SET_ROLE';
export const SET_TIME_RESTART = 'SET_LOGIN_TIME_RESTART';
export const SET_USER_INFO = 'SET_USER_INFO';
