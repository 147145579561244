import styled from 'styled-components';

export const SAuthoritySettingPage = styled.div`
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  padding: 30px 40px 20px;

  .setting_form {
    .item {

    }

    .item_upload {
      .upload_content {
        position: relative;
        background: #6f87c7;
        border-radius: 3px;
        cursor: pointer;
        padding: 0;
        height: 40px;
        width: 100%;
        display: flex;

        input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          z-index: 1;
          cursor: pointer;
          margin-bottom: 2px;
        }

        .base_content {
          display: flex;
          text-align: center;
          font-size: 13px;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          
          img {
            margin-right: 15px;
          }

          .text {
            font-size: 14px;
            color: white;
          }
        }
      }

      .error_notifi {
        color: #ed262f;
        font-size: 12px;
        font-style: italic;
        display: flex;
        justify-content: flex-end;
      }
    }

    .item_button {
      display: flex;
      justify-content: center;
      margin-top: 30px;
    }
  }
`