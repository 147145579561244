import * as constants from './constants';
import {callApi, callDownloadApi} from '../../../../common';
import PNotify from 'pnotify/dist/es/PNotify';
import {handleError} from '@configs/handleError';
import FileSaver from 'file-saver';

export const getUserApi = (body) => {
  const {search, status, pageSize, pageNumber, type} = body;
  if (type === 'all' || type === undefined || type === null)
    return (dispatch) =>
      callApi(`${constants.URL}/${pageSize}/${pageNumber}`, 'POST', {
        search,
        status,
      }).then(function (res) {
        dispatch(getListAccount(res.data.data.data));
        dispatch(setTotal(res.data.data.total));
      });
  else
    return (dispatch) =>
      callApi(
        `${constants.URL_GET_BY_FILTER}/${pageSize}/${pageNumber}`,
        'POST',
        {
          search,
          status,
          type,
        },
      ).then(function (res) {
        dispatch(getListAccount(res.data.data.data));
        dispatch(setTotal(res.data.data.total));
      });
};

export const getFileApi = () => {
  window.open(
    `${process.env.REACT_APP_UPLOAD}/api/${constants.URL_DOWLOAD_FILE}`,
  );
};

export const getFileCommonApi = (authorityId, setLoading) => {
  return (dispatch) =>
    callDownloadApi(
      `${constants.URL_DOWLOAD_FILE_COMMON}/${authorityId}`,
      'GET',
      null,
    ).then(function (res) {
      console.log('res: ', res);
      var file = new Blob([res.data], {
        type: 'application/pdf',
      });
      setTimeout(() => {
        FileSaver.saveAs(file, 'filedinhkem');
        setLoading(false);
      }, 200);
    });
};

export const getCreateApi = (body, filter, setListFile1) => {
  return (dispatch) =>
    callApi(`${constants.URL_IMPORT_FILE}`, 'POST', body)
      .then(function (res) {
        dispatch(setSending(false));
        PNotify.success({
          title: 'Thành công',
          text: 'Import danh sách ủy quyền thành công',
          destroy: true,
          delay: 2000,
        });
        // setListFile1('');
        setTimeout(() => {
          dispatch(getUserApi(filter));
        }, 500);
      })
      .catch(handleError);
};

export const changePhoneAuthorityApi = (id, body, filter) => {
  return (dispatch) =>
    callApi(`${constants.URL_CHANGE_PHONE_AUTHORITY}/${id}`, 'POST', body)
      .then(function (res) {
        setTimeout(() => {
          dispatch(getUserApi(filter));
        }, 200);
        dispatch(setToggleModal(false));
        PNotify.success({
          title: 'Thành công',
          text: 'Cập nhật thành công',
          destroy: true,
          delay: 2000,
        });

        let value = [];
        setChooseList(value);
      })
      .catch(function (err) {
        if (err) {
          if (err.response && err.response.data && err.response.data.message) {
            PNotify.error({
              title: 'Lỗi',
              text: err.response.data.message,
              destroy: true,
              delay: 2000,
            });
          } else if (err.response) {
            PNotify.error({
              title: 'Lỗi',
              text: err.response.statusText,
              destroy: true,
              delay: 2000,
            });
          }
        }
      });
};

export const getHandleAuthorityApi = (id, action, filter) => {
  return (dispatch) =>
    callApi(`${constants.URL_HANDLE_AUTHORITY}`, 'POST', {
      action,
      authorityIds: [id],
    })
      .then(function (res) {
        setTimeout(() => {
          dispatch(getUserApi(filter));
          dispatch(getLoading(false));
          dispatch(setChooseList([]));
        }, 200);
        dispatch(setToggleModal(false));
        PNotify.success({
          title: 'Thành công',
          text: 'Xử lý thành công',
          destroy: true,
          delay: 2000,
        });
      })
      .catch(function (err) {
        dispatch(getLoading(false));
        if (err) {
          if (err.response && err.response.data && err.response.data.message) {
            PNotify.error({
              title: 'Lỗi',
              text: err.response.data.message,
              destroy: true,
              delay: 2000,
            });
          } else if (err.response) {
            PNotify.error({
              title: 'Lỗi',
              text: err.response.statusText,
              destroy: true,
              delay: 2000,
            });
          }
        }
      });
};

export const setToggleModal = (value) => {
  return {
    type: constants.TOGGLE_MODAL,
    value,
  };
};

export const getListAccount = (value) => {
  return {
    type: constants.GET_LIST,
    value,
  };
};
export const getLoading = (value) => {
  return {
    type: constants.GET_LOADING,
    value,
  };
};

export const setSending = (value) => {
  return {
    type: constants.SET_SENDING,
    value,
  };
};

export const setChooseList = (value) => {
  return {
    type: constants.SET_CHOOSE_LIST,
    value,
  };
};
export const setFilter = (value) => {
  return {
    type: constants.SET_FILTER,
    value,
  };
};
export const setTotal = (value) => {
  return {
    type: constants.SET_TOTAL,
    value,
  };
};
