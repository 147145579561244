import React, { useState, useEffect } from 'react';
import Input from 'components/bases/shared/input';
import Button from 'components/bases/shared/button';
import inputIcons from 'components/bases/shared/icons/input';
import { useForm } from 'react-hook-form';
import {
  SAuthorizationListPage,
  SSectionCustom,
} from 'styles/styled/admin/authorizationListPage/authorizationList';
import { TableList } from './table.js';
import { useDispatch, useSelector } from 'react-redux';
import PNotify from 'pnotify/dist/es/PNotify';
import {
  getCreateApi,
  downloadFileAPI,
  setSending,
  setFilter,
} from '@duck/pages/admin/authorizationListReducer/actions';
import { useTranslation } from 'react-i18next';

var _validFileExtensions = ['.xlsx', '.xls'];
function Validate(e) {
  var sFileName = e.target.value;
  if (sFileName.length > 0) {
    var blnValid = false;
    for (var j = 0; j < _validFileExtensions.length; j++) {
      var sCurExtension = _validFileExtensions[j];
      if (
        sFileName
          .substr(sFileName.length - sCurExtension.length, sCurExtension.length)
          .toLowerCase() === sCurExtension.toLowerCase()
      ) {
        blnValid = true;
        break;
      }
    }
    return blnValid;
  }
}

const AuthorizationList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [listFile1, setListFile1] = useState('');
  const { handleSubmit, register, control } = useForm();
  const [success, setSuccess] = useState(false);
  const filter = useSelector((state) => state.admin.authorizationList.filter);
  const statusOptions = [
    { value: 'all', label: t('Trạng thái') },
    { value: 'pending', label: t('Chờ xử lý') },
    { value: 'accept', label: t('Đã xử lý') },
    { value: 'deny', label: t('Thất bại') },
  ];
  const typeOption = [
    { value: 'all', label: t('Tất cả') },
    { value: 'shareholder', label: t('Cổ đông ủy quyền') },
    { value: 'authorized', label: t('Người nhận ủy quyền') },
  ];
  const typeAutho = [
    { value: 'all', label: t('Tất cả') },
    { value: 'document', label: t('Tài liệu') },
    { value: 'online', label: t('Trực tuyến') },
  ];
  const isFile = (event, handleFuncion) => {
    if (Validate(event)) {
      var files = event.target.files[0];
      handleFuncion(files);
      PNotify.success({
        title: t('Thành công'),
        text: t('Upload file thành công'),
        destroy: true,
        delay: 2000,
      });
    } else {
      PNotify.error({
        title: t('Lỗi'),
        text: t('Phải là file excel (xlsx, xls)'),
        destroy: true,
        delay: 2000,
      });
    }
  };
  useEffect(() => {
    if (success) {
      setListFile1('');
      setSuccess(false);
    }
  }, [success, dispatch]);

  const onSubmit = () => {
    if (listFile1) {
      let formData1 = new FormData();
      formData1.append('file', listFile1);
      // formData1.append('shareholderType', 'personal');
      dispatch(getCreateApi(formData1, filter));
      dispatch(setSending(true));
    } else {
      PNotify.error({
        title: t('Lỗi'),
        text: t('Bạn phải tải lên danh sách ủy quyền'),
        destroy: true,
        delay: 2000,
      });
    }
  };
  const downloadFile = () => {
    dispatch(downloadFileAPI());
  };

  const onSearch = (data) => {
    const { identityNumber, status, type, typeAutho } = data;
    console.log('typeAutho', typeAutho);
    dispatch(
      setFilter({
        ...filter,
        identityNumber,
        status: status ? status.value : 'all',
        pageNumber: 1,
        type: type ? type.value : 'all',
        form: typeAutho ? typeAutho.value : 'all',
      }),
    );
  };
  return (
    <SAuthorizationListPage>
      <SSectionCustom>
        <div className="module-header">
          <h3 className="title"> {t('Danh sách ủy quyền')} </h3>
          <div className="file-handle">
            <Button className="grey-button" onClick={downloadFile}>
              {inputIcons.download}
              {t('Tải về file mẫu')}
            </Button>
            <Button className="grey-button  upload-btn">
              {inputIcons.upload}
              {t('Tải lên danh sách ủy quyền')}
              {!listFile1 && (
                <input type="file" onChange={(e) => isFile(e, setListFile1)} />
              )}
              {listFile1 && (
                <div className="file">
                  {listFile1 ? listFile1.name : ''}
                  <svg
                    onClick={() => setListFile1('')}
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="12" cy="12" r="12" fill="#FF6C6C" />
                    <rect
                      x="16.0942"
                      y="6.66211"
                      width="2.66667"
                      height="13.3333"
                      transform="rotate(45 16.0942 6.66211)"
                      fill="white"
                    />
                    <rect
                      x="6.76172"
                      y="8.54883"
                      width="2.66667"
                      height="13.3333"
                      transform="rotate(-45 6.76172 8.54883)"
                      fill="white"
                    />
                  </svg>
                </div>
              )}
            </Button>
            <Button className="blue-button" onClick={onSubmit}>
              {inputIcons.plus}
              {t('Tạo danh sách')}
            </Button>
          </div>
        </div>
        <div className="module-content">
          <div className="search-wrapper">
            <div className="search-item">
              <Input.Text
                register={register('identityNumber')}
                wrapperProps={{
                  label: t('CMND/CCCD'),
                  icon: inputIcons.account,
                  required: true,
                  height: '40px',
                }}
              />
            </div>
            <div className="search-item">
              <Input.Select
                name="status"
                control={control}
                selectProps={{
                  placeholder: t('Trạng thái'),
                  options: statusOptions,
                }}
              />
            </div>
            <div className="search-item">
              <Input.Select
                name="type"
                control={control}
                selectProps={{
                  placeholder: t('Loại cổ đông'),
                  options: typeOption,
                }}
              />
            </div>
            <div className="search-item">
              <Input.Select
                name="typeAutho"
                control={control}
                selectProps={{
                  placeholder: t('Loại ủy quyền'),
                  options: typeAutho,
                }}
              />
            </div>
            <div className="search-item">
              <Button className="search" onClick={handleSubmit(onSearch)}>
                {t('Tìm kiếm')}{' '}
              </Button>
            </div>
          </div>
        </div>
      </SSectionCustom>
      <TableList />
    </SAuthorizationListPage>
  );
};
export default AuthorizationList;
