import styled from 'styled-components';

const Button = styled.button`
  width: 152px;
  height: 40px;
  border-radius: 4px;
  background-color: #00cb82;
  color: white;
  font-weight: 700;
  transition: all 0.3s;
  box-shadow: none;
  &:hover {
    box-shadow: 3px 4px 6px 0px rgba(0, 0, 0, 0.3);
  }
  &:disabled {
    cursor: not-allowed;
    background-color: #9ab1cc !important;
    border: none !important;
    color: white !important;
    box-shadow: unset;
  }
`;

export default Button;
