import React, { useEffect } from 'react';
import TimeItem from './timeItem';
import {
  getInfoConferenceApi,
  getInfoTopicPartApi,
  getTimeConferenceApi,
  getTimeSettingApi,
} from '@duck/pages/admin/timeVoteReducer/actions';
import { useDispatch } from 'react-redux';
import { STimeVotePage } from '@styled/admin/timeVotePage';
import { useTranslation } from 'react-i18next';

const data = [
  {
    title: 'Cấu hình thời gian diễn ra Đại hội',
    isAdvanced: false,
    type: 0,
  },
  {
    title: 'Đóng/Mở phần thông qua quy chế tổ chức',
    isAdvanced: true,
    type: 1,
    // - type equal to api
  },
  {
    title: 'Đóng/mở phần thông qua các tờ trình',
    isAdvanced: true,
    type: 2,
  },
  {
    title: 'Đóng/mở phần biểu quyết nội dung bầu cử',
    isAdvanced: true,
    type: 6,
  },
  {
    title: 'Đóng/mở phần bầu cử (Bầu dồn phiếu)',
    isAdvanced: true,
    type: 4,
  },
  {
    title: 'Đóng/mở phần thông qua Biên bản - Nghị quyết Đại hội',
    isAdvanced: true,
    type: 3,
  },
  {
    title: 'Đóng/Mở thời gian Cổ đông gửi ý kiến',
    isAdvanced: false,
    type: 5,
  },
];

const TimeVote = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(getTimeConferenceApi());
    dispatch(getInfoConferenceApi());
    dispatch(getInfoTopicPartApi());
    dispatch(getTimeSettingApi());
  }, [dispatch]);
  return (
    <STimeVotePage>
      <h1 className="title">{t('Cấu hình thời gian biểu quyết')}</h1>
      <div className="module-content">
        {data.map((d, index) => {
          return (
            <TimeItem
              key={index}
              type={d.type}
              title={t(d.title)}
              isAdvanced={d.isAdvanced}
            />
          );
        })}
      </div>
    </STimeVotePage>
  );
};
export default TimeVote;
