import styled from 'styled-components';
import inputIcons from './icons/input';
import React from 'react';
import { useTranslation } from 'react-i18next';

//type: mic, chat, lock, unlock

export default function CommunicateButton({type, action, className = ''}) {
  const {t} = useTranslation();
  return (
    <SHandle className={className} onClick={action}>
      {type === 'mic'
        ? inputIcons.mic
        : type === 'lock'
        ? inputIcons.lock
        : type === 'unlock'
        ? inputIcons.unlock
        : inputIcons.chat}

      <div className="tooltip">
        {type === 'mic'
          ? t('Mời phát biểu')
          : type === 'lock'
          ? t('Chặn')
          : type === 'unlock'
          ? t('Bỏ chặn')
          : 'Chat'}
      </div>
    </SHandle>
  );
}

const SHandle = styled.div`
  cursor: pointer;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  transition: all 0.2s;
  position: relative;
  &.lock {
    &:hover {
      svg > path {
        fill: red;
      }
    }
  }
  .tooltip {
    display: none;
  }
  svg > path {
    fill: #9ab1cc;
  }
  &:hover {
    svg > path {
      fill: #00cb82;
    }
    .tooltip {
      display: block;
      position: absolute;
      top: -30px;
      color: #333b6a;
      font-size: 12px;
      line-height: 21px;
      z-index: 2;
      padding: 1px 2px 0;
      max-width: 82px;
      text-align: center;
      background-color: white;
      border-radius: 2px;
      white-space: nowrap;
      border: 1px solid #dfe9f5;
    }
  }
`;
