import React from 'react';
import styled, { keyframes, css } from 'styled-components';

export const SmallLoading = () => {
  return <SStyles></SStyles>;
};

const spinLoading = keyframes`
   from {
       transform: rotate(0deg);
   }
   to{
       transform: rotate(360deg);
   }
`;

const SStyles = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid white;
  border-right-color: transparent;
  animation: 1s ${spinLoading} linear infinite;
`;

export const UIButton = ({ children, ...props }) => {
  return (
    <SButton {...props}>
      {props.isLoading ? <SmallLoading /> : children}
    </SButton>
  );
};

const SButton = styled.button`
  border: unset;
  /* font-family: Oswald, sans-serif; */
  height: 40px;
  min-width: 100px;
  /* font-size: 16px; */
  font-weight: 500;
  border-radius: 8px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.primary};
  color: white;
  /* box-shadow: ${({ theme }) => theme.shadow_level_1}; */
  cursor: pointer;

  :disabled {
    opacity: 0.5;
  }

  ${({ isLoading }) =>
    isLoading &&
    css`
      opacity: 0.8;
      pointer-events: none;
    `}

  ${({ outline, theme, color }) =>
    outline &&
    css`
      color: ${color || theme.primary};
      background: white;
      border: 1px solid ${color || theme.primary};
      :hover {
        background: ${theme.primary};
        color: white;
      }
      svg {
        * {
          stroke: ${color};
        }
      }
    `}

  ${({ accent, theme }) =>
    accent &&
    css`
      background: ${theme.accent};
      :hover {
        background: ${theme.accent};
      }
    `}

  ${({ secondary, theme }) =>
    secondary &&
    css`
      background: #e3eaf6;
      color: #718098;
      :hover {
        background: #e3eaf6;
      }
    `}
`;

export const CustomButton = ({ children, ...props }) => {
  return <Styles {...props}>{children}</Styles>;
};

const Styles = styled.button`
  width: 200px;
  height: 44px;
  font-family: Oswald, sans-serif;
  min-width: 100px;
  font-size: 16px;
  font-weight: 500;
  padding: 0 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.primary};
  color: white;
  border: unset;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  ::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background: #f47f20;
    transform: rotate(-45deg) translate(-50%, 50%);
    transform-origin: bottom left;
    border: 2px solid rgba(0, 0, 0, 0.32);
  }
  ::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 20px;
    width: 20px;
    background: #f47f20;
    transform: rotate(45deg) translate(50%, -50%);
    transform-origin: top right;
    border: 2px solid rgba(0, 0, 0, 0.32);
  }
`;
