import React, {useEffect} from 'react';
import {setFilter} from '../../../../states/duck/pages/admin/shareholderListReducer/actions';
import {useSelector, useDispatch} from 'react-redux';
import Input from '@components/input';
import inputIcons from '@components/icons/input';
import Button from '@components/button';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
const optionType = [
  {value: 'all', label: 'Tất cả'},
  {value: 'domestic', label: 'Trong nước'},
  {value: 'foreign', label: 'Nước ngoài'},
];
const optionTypeEn = [
  {value: 'all', label: 'All'},
  {value: 'domestic', label: 'Domestic'},
  {value: 'foreign', label: 'International'},
];
const optionScale = [
  {value: 'all', label: 'Tất cả'},
  {value: 'personal', label: 'Cá nhân'},
  {value: 'enterprise', label: 'Tổ chức'},
];
const optionScaleEn = [
  {value: 'all', label: 'All'},
  {value: 'personal', label: 'Indivisual'},
  {value: 'enterprise', label: 'Organization'},
];

const TableSearch = () => {
  const {
    t,
    i18n: {language},
  } = useTranslation();
  const dispatch = useDispatch();
  const {handleSubmit, register, control, setValue} = useForm();
  const filter = useSelector((state) => state.admin.shareholderList.filter);

  const onSubmit = (data) => {
    const {identityNumber, scale, type} = data;
    dispatch(
      setFilter({
        ...filter,
        identityNumber,
        pageNumber: 1,
        type: type.value || 'all',
        scale: scale.value || 'all',
        sortBy: 'code',
        order: 'asc',
      }),
    );
  };
  useEffect(() => {
    setValue('identityNumber', filter.identityNumber);
    for (let i in optionType) {
      if (filter.type === optionType[i].value) {
        setValue('type', language === 'vi' ? optionType[i] : optionTypeEn[i]);
        break;
      }
    }
    for (let i in optionScale) {
      if (filter.scale === optionScale[i].value) {
        setValue(
          'scale',
          language === 'vi' ? optionScale[i] : optionScaleEn[i],
        );
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, setValue]);
  return (
    <div className="module-content">
      <div className="search-wrapper">
        <div className="search-item">
          <Input.Text
            register={register('identityNumber')}
            wrapperProps={{
              label: t('Họ tên/CMND/CCCD'),
              icon: inputIcons.code,
              height: '40px',
            }}
          />
        </div>
        <div className="search-item">
          <Input.Select
            name="type"
            control={control}
            defaultValue={{value: 'all', label: t('Tất cả')}}
            selectProps={{
              placeholder: t('Tất cả'),
              options: language === 'vi' ? optionType : optionTypeEn,
            }}
          />
        </div>
        <div className="search-item">
          <Input.Select
            name="scale"
            control={control}
            defaultValue={{value: 'all', label: t('Tất cả')}}
            selectProps={{
              placeholder: t('Tất cả'),
              options: language === 'vi' ? optionScale : optionScaleEn,
            }}
          />
        </div>
        <div className="search-item search-button">
          <Button onClick={handleSubmit(onSubmit)}> {t('Tìm kiếm')} </Button>
        </div>
      </div>
    </div>
  );
};
export default TableSearch;
