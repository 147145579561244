import Modal from '@components/modal';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useForm } from 'react-hook-form';
import Button from '@components/button';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { callApi } from '@state-common/';
import { URL_TOGGLE_STATUS } from '@duck/pages/admin/authorizationInfomationReducer/constants';
import {
  getUserListApi,
  setChooseList,
} from '@duck/pages/admin/authorizationInfomationReducer/actions';
import PNotify from 'pnotify/dist/es/PNotify';
import { handleError } from '@configs/handleError';

const EditModal = ({ isOpen, setIsOpen, modalType }) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();

  const listChose = useSelector(
    (state) => state.admin.authorizationInfoList.listChose,
  );
  const filter = useSelector(
    (state) => state.admin.authorizationInfoList.filter,
  );

  console.log('listChose', listChose);
  const onSubmit = () => {
    if (modalType === 'lock') {
      callApi(`${URL_TOGGLE_STATUS}`, 'PATCH', {
        ids: [listChose[0]._id],
        isBlocked: true,
      })
        .then(function (res) {
          setIsOpen(false);
          setTimeout(() => {
            dispatch(getUserListApi(filter));
            dispatch(setChooseList([]));
          }, 500);
          PNotify.success({
            title: t('Thành công'),
            text: t('Sửa thành công'),
            destroy: true,
            delay: 2000,
          });
        })
        .catch(handleError);
    } else {
      callApi(`${URL_TOGGLE_STATUS}`, 'PATCH', {
        ids: [listChose[0]._id],
        isBlocked: false,
      })
        .then(function (res) {
          setIsOpen(false);
          setTimeout(() => {
            dispatch(getUserListApi(filter));
            dispatch(setChooseList([]));
          }, 500);
          PNotify.success({
            title: t('Thành công'),
            text: t('Sửa thành công'),
            destroy: true,
            delay: 2000,
          });
        })
        .catch(handleError);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={setIsOpen}
      title={
        modalType === 'lock'
          ? t('Bạn có chắc chắn muốn khóa tài khoản đã chọn?')
          : t('Bạn có chắc chắn muốn mở khóa tài khoản đã chọn?')
      }
      overwriteModalStyle={{
        maxWidth: '560px',
      }}
    >
      <SModalContent>
        <div className="module-modal">
          <img
            src={`images/${modalType === 'lock' ? 'lock' : 'unlock'}.png`}
            alt=""
          />
        </div>
        <div className="button-group">
          <Button className="cancel-button" onClick={() => setIsOpen(false)}>
            {t('Hủy bỏ')}
          </Button>
          <Button onClick={handleSubmit(onSubmit)} className="confirm-button">
            {t('Xác nhận')}
          </Button>
        </div>
      </SModalContent>
    </Modal>
  );
};

export default EditModal;

const SModalContent = styled.div`
  padding: 0 10px;
  background-color: ${(props) => props.backGroundColor || 'white'};
  margin-top: 30px;
  .module-modal {
    text-align: center;
    img {
      max-width: 100%;
    }
  }
  .button-group {
    padding-top: 30px;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    .confirm-button {
      background: #00cb82;
      color: #ffffff;
    }
    .cancel-button {
      color: #718098;
      background: #dfe9f5;
    }
  }

  @media (max-width: 1199px) {
    padding: 0 40px;
  }
  @media (max-width: 991px) {
    padding: 0 20px;
    margin-top: 20px;
  }
  @media (max-width: 767px) {
    padding: 0 15px;
  }

  .group-button {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;
    width: 100%;

    & > ${Button} {
      margin-left: 16px;
      margin-bottom: 8px;
    }

    @media (max-width: 991px) {
      margin-top: 20px;
    }
  }
  .form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;
