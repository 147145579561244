import Button from '@components/button';
import { Sketch } from '@uiw/react-color';
import React, { useState } from 'react';
import styled from 'styled-components';
import { t } from 'translate';
const ColorComponent = ({ setColor, array, action, setToggleColor }) => {
  const [hex, setHex] = useState('');
  const onChangeColor = (e) => {
    setHex(e.hex);
  };
  const handleColorChange = (value) => {
    array = [value, ...array];
    array.pop();
    action(array);
  };
  const close = () => {
    setToggleColor({ active: false, type: 0 });
  };
  return (
    <Styles>
      <div className="header">
        <p>{t('Chọn màu')}</p>
        <div onClick={() => close()}>
          <CloseSVG />
        </div>
      </div>
      <div className="body">
        <Sketch
          style={{ width: '100%' }}
          color={hex}
          onChange={(color) => onChangeColor(color)}
        />
        <div className="btn-group">
          <Button
            style={{ background: '#DFE9F5', color: '#718098' }}
            onClick={() => close()}
          >
            {t('Hủy bỏ')}
          </Button>
          <Button
            onClick={() => {
              const temp = array.filter((i) => i === hex);
              if (temp.length > 0) close();
              else {
                setColor(hex);
                handleColorChange(hex);
                close();
              }
            }}
          >
            {t('Chọn màu')}
          </Button>
        </div>
      </div>
    </Styles>
  );
};
export default ColorComponent;
const Styles = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(105%, 10%);
  width: 352px;
  background: #fff;
  .header {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dfe9f5;
    p {
      color: var(--333-b-6-a, #333b6a);
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px; /* 166.667% */
      text-transform: uppercase;
    }
  }
  .body {
    padding: 16px 16px 32px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .btn-group {
      width: 100%;
      display: flex;
      gap: 16px;
      margin-top: 24px;
    }
  }
`;

const CloseSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <rect
      width="2.057"
      height="20.57"
      transform="matrix(0.707099 -0.707115 0.707099 0.707115 0.000488281 1.45459)"
      fill="#FF5576"
    />
    <rect
      width="2.057"
      height="20.57"
      transform="matrix(0.707099 0.707115 -0.707099 0.707115 14.5454 0)"
      fill="#FF5576"
    />
  </svg>
);
