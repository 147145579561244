export const URL = 'info/representative';
export const URL_EXPORT = 'info/shareholder/export';

export const GET_LIST = 'GET_SHAREHOLDER_STATUS_LIST';
export const SET_CHOOSE_LIST = 'SET_SHAREHOLDER_STATUS_LIST';
export const TOGGLE_MODAL = 'TOGGLE_SHAREHOLDER_STATUS_MODAL';
export const SET_PAGE_SIZE = 'SET_SHAREHOLDER_STATUS_PAGE_SIZE';
export const SET_PAGE_NUMBER = 'SET_SHAREHOLDER_STATUS_PAGE_NUMBER';
export const SET_FILTER = 'SET_SHAREHOLDER_STATUS_FILTER';
export const SET_DETAILS = 'SET_SHAREHOLDER_STATUS_DETAILS';
export const SET_TOTAL = 'SET_SHAREHOLDER_STATUS_TOTAL';
export const GET_TOPIC1 = 'GET_SHAREHOLDER_STATUS_TOPIC1';
export const GET_TOPIC2 = 'GET_SHAREHOLDER_STATUS_TOPIC2';
export const GET_TOPIC3 = 'GET_SHAREHOLDER_STATUS_TOPIC3';
export const GET_TOPIC4 = 'GET_SHAREHOLDER_STATUS_TOPIC4';
export const GET_LIST_AUTHORITY = 'GET_LIST_AUTHORITY';
