import { handleError } from '@configs/handleError';
import PNotify from 'pnotify/dist/es/PNotify';
import { callApi } from '../../../../common';
import * as constants from './constants';
import { getUserListApi } from '../authorizationInfomationReducer/actions';

export const getAllReport = async (tableType) => {
  const res = await callApi(
    `${constants.URL_TABLE}/${tableType}?take=${99}&page=1`,
    'GET',
    null,
  );
  console.log('resssss', res, res?.data?.data?.data);
  return res?.data?.data?.data || [];
};

export const getUserApi = (tableType, filter) => {
  return (dispatch) =>
    callApi(
      `${constants.URL_TABLE}/${tableType}?take=${
        filter?.pageSize || 10
      }&page=${filter?.pageNumber || 1}`,
      'GET',
      null,
    ).then(function (res) {
      dispatch(setTotalListPage(res.data.data.total));
      dispatch(getListAccount(res.data.data.data));
      dispatch(setChooseList([]));
    });
};

export const confirmTopicApi = (id, body, tableType) => {
  return (dispatch) =>
    callApi(`${constants.URL_CONFIRM}/${id}`, 'POST', body)
      .then(function (res) {
        setTimeout(() => {
          dispatch(getUserApi(tableType));
        }, 200);

        dispatch(setActiveButtonConfirm(true));
        PNotify.success({
          title: 'Thành công',
          text: `Lưu ${
            tableType === 'sheet-1'
              ? 'nội dung'
              : tableType === 'sheet-3'
              ? 'biên bản'
              : 'tờ trình'
          } lên blockchain thành công`,
          destroy: true,
          delay: 2000,
        });
      })
      .catch(function (err) {
        dispatch(setActiveButtonConfirm(true));
        handleError(err);
      });
};

// used
export const createCumulativeAccountApi = (body, onSuccess) => {
  return (dispatch) =>
    callApi(`${constants.URL_CUMULATIVE}`, 'POST', body)
      .then(function (res) {
        setTimeout(() => {
          dispatch(setFilterListPage({ pageSize: 10, pageNumber: 1 }));
          //dispatch(getUserApi(tableType));
          //dispatch(setSuccess(true));
          onSuccess && onSuccess();
        }, 200);

        PNotify.success({
          title: 'Thành công',
          text: `Khởi tạo tờ trình thành công`,
          destroy: true,
          delay: 2000,
        });
      })
      .catch(handleError);
};

// used
export const createAccountApi = (body, tableType, kind, onSuccess) => {
  return (dispatch) =>
    callApi(`${constants.URL}/${kind}`, 'POST', body)
      .then(function (res) {
        setTimeout(() => {
          dispatch(setFilterListPage({ pageSize: 10, pageNumber: 1 }));
          // dispatch(getUserApi(tableType));
          // dispatch(setSuccess(true));
          onSuccess && onSuccess();
        }, 200);

        PNotify.success({
          title: 'Thành công',
          text: `Khởi tạo ${
            tableType === 'sheet-1'
              ? 'nội dung'
              : tableType === 'sheet-3'
              ? 'biên bản'
              : 'tờ trình'
          } thành công`,
          destroy: true,
          delay: 2000,
        });
      })
      .catch(handleError);
};

export const editCumulativeAccountApi = (body, id, tableType, onSuccess) => {
  return (dispatch) =>
    callApi(`${constants.URL_EDIT_CUMULATIVE}/${id}`, 'POST', body)
      .then(function (res) {
        setTimeout(() => {
          dispatch(setFilterListPage({ pageSize: 10, pageNumber: 1 }));
          // dispatch(getUserApi(tableType));
          onSuccess && onSuccess();
        }, 200);

        PNotify.success({
          title: 'Thành công',
          text: `Sửa ${
            tableType === 'sheet-1'
              ? 'nội dung'
              : tableType === 'sheet-3'
              ? 'biên bản'
              : 'tờ trình'
          } thành công`,
          destroy: true,
          delay: 2000,
        });
      })
      .catch(handleError);
};

export const editAccountApi = (body, id, tableType, kind, onSuccess) => {
  return (dispatch) =>
    callApi(`${constants.URL_EDIT}/${kind}/${id}`, 'POST', body).then(function (
      res,
    ) {
      setTimeout(() => {
        dispatch(setFilterListPage());
        // dispatch(getUserApi(tableType));
        onSuccess && onSuccess();
      }, 200);

      PNotify.success({
        title: 'Thành công',
        text: `Sửa ${
          tableType === 'sheet-1'
            ? 'nội dung'
            : tableType === 'sheet-3'
            ? 'biên bản'
            : 'tờ trình'
        } thành công`,
        destroy: true,
        delay: 2000,
      });
    });
  // .catch(handleError);
};

export const deleteAccountApi = (
  body,
  tableType,
  handleSuccess,
  kind,
  kindToSend,
) => {
  return (dispatch) =>
    callApi(`${constants.URL_DELETE}/${kind}`, 'POST', body)
      .then(function () {
        dispatch(
          getUserApi(
            tableType === 'sheet-4' && kindToSend === 6 ? 'sheet-6' : tableType,
          ),
        );
        handleSuccess();
        PNotify.success({
          title: 'Thành công',
          text: `Xóa ${
            tableType === 'sheet-1'
              ? 'nội dung'
              : tableType === 'sheet-3'
              ? 'biên bản'
              : 'tờ trình'
          } thành công`,
          destroy: true,
          delay: 2000,
        });
      })
      .catch(handleError);
};

export const closeVoteTopicApi = (body, id, tableType) => {
  return (dispatch) =>
    callApi(`${constants.URL_CLOSE_VOTE}/${id}`, 'POST', body)
      .then(function (res) {
        setTimeout(() => {
          dispatch(getUserApi(tableType));
        }, 200);

        PNotify.success({
          title: 'Thành công',
          text: `Đóng ${
            tableType === 'sheet-1'
              ? 'nội dung'
              : tableType === 'sheet-3'
              ? 'biên bản'
              : 'tờ trình'
          } thành công`,
          destroy: true,
          delay: 2000,
        });
      })
      .catch(handleError);
};

export const blockShareholderApi = (body, tableType, kind) => {
  return (dispatch) =>
    callApi(`${constants.URL_BLOCK}/${kind}`, 'POST', body)
      .then(function (res) {
        setTimeout(() => {
          dispatch(getUserApi(tableType));
          dispatch(setBlockList(body.shareholderIds));
          dispatch(onShareHolderRefetch());
        }, 200);

        PNotify.success({
          title: 'Thành công',
          text: `Thực hiện thành công`,
          destroy: true,
          delay: 2000,
        });
      })
      .catch(handleError);
};

export const sortOrderApi = (body, tableType, handleSuccess, kind) => {
  return (dispatch) =>
    callApi(`${constants.URL_SORT}/${kind}`, 'POST', body)
      .then(function (res) {
        setTimeout(() => {
          dispatch(getUserApi(tableType));
          // dispatch(
          //   setFilter({
          //     pageSize: common.PAGE_SIZE,
          //     pageNumber: 1,
          //   }),
          // );
        }, 200);
        handleSuccess && handleSuccess();
        PNotify.success({
          title: 'Thành công',
          text: `Sắp xếp thứ tự tờ trình thành công`,
          destroy: true,
          delay: 2000,
        });
      })
      .catch(handleError);
};
export const sortOrderApiPeople = (body, filter, handleSuccess) => {
  return (dispatch) =>
    callApi(`authority/authorized-shareholder/sort`, 'PUT', body)
      .then(function (res) {
        setTimeout(() => {
          dispatch(getUserListApi(filter));
          // dispatch(
          //   setFilter({
          //     pageSize: common.PAGE_SIZE,
          //     pageNumber: 1,
          //   }),
          // );
        }, 200);
        handleSuccess && handleSuccess();
        PNotify.success({
          title: 'Thành công',
          text: `Sắp xếp thứ tự tờ trình thành công`,
          destroy: true,
          delay: 2000,
        });
      })
      .catch(handleError);
};
export const getShareholderApi = (body) => {
  const { identityNumber, joinType, status, pageSize, pageNumber } = body;
  return (dispatch) =>
    callApi(`${constants.URL_SHAREHOLDER}/${pageSize}/${pageNumber}`, 'POST', {
      identityNumber,
      joinType,
      status,
    }).then(function (res) {
      dispatch(getListShareholder(res.data.data.shareholders));
      dispatch(setTotal(res.data.data.total));
    });
};
export const getShareholderBlockApi = (body, topicId, kind) => {
  const { identityNumber, pageSize, pageNumber } = body;

  return (dispatch) =>
    callApi(
      `${constants.URL_SHAREHOLDER_BLOCKED}/${kind}/${topicId}/${pageSize}/${pageNumber}`,
      'POST',
      {
        search: identityNumber,
      },
    ).then(function (res) {
      dispatch(getListShareholder(res.data.data.blocks));
      dispatch(setTotal(res.data.data.total));
    });
};

export const getListShareholder = (value) => {
  return {
    type: constants.SET_LIST_SHAREHOLDER,
    value,
  };
};
export const setActiveButtonConfirm = (value) => {
  return {
    type: constants.SET_ACTIVE_BUTTON_CONFIRM,
    value,
  };
};
export const setTotal = (value) => {
  return {
    type: constants.SET_TOTAL,
    value,
  };
};
export const setTotalListPage = (value) => {
  return {
    type: constants.SET_TOTAL_LISTPAGE,
    value,
  };
};
export const setFilter = (value) => {
  return {
    type: constants.SET_FILTER,
    value,
  };
};
export const setFilterListPage = (value) => {
  return {
    type: constants.SET_FILTER_LISTPAGE,
    value,
  };
};
export const setSuccess = (value) => {
  return {
    type: constants.SET_SUCCESS,
    value,
  };
};

export const setToggleModal = (value) => {
  return {
    type: constants.TOGGLE_MODAL,
    value,
  };
};
export const getListAccount = (value) => {
  return {
    type: constants.GET_LIST,
    value,
  };
};
export const setChooseList = (value) => {
  return {
    type: constants.SET_CHOOSE_LIST,
    value,
  };
};
export const setBlockList = (value) => {
  return {
    type: constants.SET_BLOCK_LIST,
    value,
  };
};

export const setMethod = (value) => {
  return {
    type: constants.SET_METHOD,
    value,
  };
};

export const onShareHolderRefetch = () => ({
  type: constants.REFETCH_SHARE_HOLDER,
});
