import * as constants from './constants';
import { callApi, callExportApi, callDownloadApi } from 'states/common';
import * as common from 'states/common/constants';
import PNotify from 'pnotify/dist/es/PNotify';
import { handleError } from '@configs/handleError';
var FileSaver = require('file-saver');

export const getFormApi = () => {
  return (dispatch) =>
    callApi(`${constants.URL_GET_FORM}`, 'GET')
      .then(function (res) {
        dispatch(getListForm(res.data.data));
        // dispatch(setTotal(res.data.data.total));
      })
      .catch(handleError);
};

export const downloadFileAPI = () => {
  return (dispatch) =>
    callDownloadApi(`${constants.URL_DOWNLOAD}`, 'GET', null).then(function (
      res,
    ) {
      var file = new Blob([res.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
      });
      FileSaver.saveAs(file, 'Danhsachtaikhoanquantri');
    });
};

export const exportForm = (body) => {
  const { name, description, account } = body;
  return (dispatch) =>
    callExportApi(`${constants.URL_EXPPORT_ACCOUNT}`, 'POST', {
      name: name,
      description: description,
      account: account,
    })
      .then(function (res) {
        PNotify.success({
          title: 'Thành công',
          text: 'Xuất danh sách danh sách tài khoản quản trị thành công!',
          destroy: true,
          delay: 2000,
        });
        var file = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        FileSaver.saveAs(file, 'DStaikhoanquantri');
      })
      .catch(handleError);
};

export const getUploadApi = (body, filter) => {
  console.log('body', body);
  return (dispatch) =>
    callApi(`${constants.URL_UPLOAD}`, 'POST', body)
      .then(function (res) {
        dispatch(setSending(false));
        PNotify.success({
          title: 'Thành công',
          text: 'Upload file thành công',
          destroy: true,
          delay: 2000,
        });
        setTimeout(() => {
          dispatch(
            setFilter({
              pageSize: common.PAGE_SIZE,
              pageNumber: 1,
            }),
          );
          dispatch(
            getFormApi({
              pageSize: common.PAGE_SIZE,
              pageNumber: 1,
            }),
          );
        }, 500);
      })
      .catch(function (err) {
        dispatch(setSending(false));
        if (err) {
          if (err.response && err.response.data && err.response.data.message) {
            PNotify.error({
              title: 'Lỗi',
              text: err.response.data.message,
              destroy: true,
              delay: 2000,
            });
          } else if (err.response) {
            PNotify.error({
              title: 'Lỗi',
              text: err.response.statusText,
              destroy: true,
              delay: 2000,
            });
          }
        }
      });
};

export const getListForm = (value) => {
  return {
    type: constants.GET_LIST,
    value,
  };
};
export const setSending = (value) => {
  return {
    type: constants.SET_SENDING,
    value,
  };
};

export const setFilter = (value) => {
  return {
    type: constants.SET_FILTER,
    value,
  };
};
// export const setTotal = (value) => {
//   return {
//     type: constants.SET_TOTAL,
//     value,
//   };
// };

// export const setListChose = (value) => {
//   return {
//     type: constants.SET_LIST_CHOSE,
//     value,
//   };
// };
