import LoadingScreen from 'components/layout/loading';
import 'pnotify/dist/PNotifyBrightTheme.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider, useSelector } from 'react-redux';
import { GlobalStyle, theme } from 'styles/GlobalStyles';
import 'video.js/dist/video-js.css';
import '../src/styles/pages.less';
import '../src/styles/tools.less';
import Layout from './components/layout';
import './i18n';
import * as serviceWorker from './serviceWorker';
import store from './states/store';
import { ThemeProvider } from 'styled-components';

const ThemeContext = () => {
  const config = useSelector(
    (state) => state.admin.configSystem.info.homeDisplaySetting,
  );
  return (
    <ThemeProvider
      theme={{
        ...theme,
        textColor: config?.pickedTextColor,
        headerColor: config?.pickedHeaderColor,
        backgroundColor: config?.pickedBackgroundColor,
      }}
    >
      <LoadingScreen />
      <Layout />
      <GlobalStyle />
    </ThemeProvider>
  );
};

ReactDOM.render(
  <Provider store={store}>
    <ThemeContext />
  </Provider>,
  document.getElementById('root'),
);
serviceWorker.unregister();
