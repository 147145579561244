import * as types from './constants';
var initialState = {
    tableList: [],
    listUserChoose: [],
    toggleModal: false
};
var reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_LIST:
            return {...state, tableList: action.value};
        case types.TOGGLE_MODAL:
            return {...state, toggleModal: action.value};
        case types.SET_CHOOSE_LIST:
            return {...state, listUserChoose: action.value};
        default:
            return state;
    }
}
export default reducer;