import React from 'react';
import P from 'rc-pagination';
import styled from 'styled-components';
import './styles.css';
import { useTranslation } from 'react-i18next';

// interface Props extends PaginationProps {
//   handleChangePageSize: (pageSize: number) => void;
// }

const arrayPageSize = [5, 10, 20, 50];

export default function Pagination({ handleChangePageSize, ...rest }) {
  const { pageSize = 1, total = 0, current = 1, hideSelectSize = false } = rest;
  const { t } = useTranslation();
  const onChange = (e) => {
    handleChangePageSize(parseInt(e.target.value));
  };

  const from = (current - 1) * pageSize + 1;
  const to = current * pageSize <= total ? current * pageSize : total;

  return (
    <SPagination>
      <div className="page-size">
        <span>
          {t('Hiển thị từ')} {from} {t('đến')} {to} {t('trong số')} {total}{' '}
          {t('bản ghi')}
          {total > 0 && !hideSelectSize && (
            <select
              className="page-size__select"
              onChange={onChange}
              value={pageSize}
            >
              {React.Children.toArray(
                arrayPageSize.map((ps) => <option value={ps}>{ps}</option>),
              )}
            </select>
          )}
        </span>
      </div>
      <P
        {...rest}
        hideOnSinglePage={true}
        showLessItems={true}
        showTitle={false}
      />
    </SPagination>
  );
}

export const SPagination = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  color: #718098;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  border: 1px solid white;
  background: #ffffff80;
  padding: 15px 20px;
  .page-size {
    /* font-size: 12px;
    line-height: 18px;
    color: #6e7b97; */
    &__select {
      cursor: pointer;
      margin-left: 16px;
      padding: 1px 0px 1px 4px;
      color: #6e7b97;
      background-color: white;
      border: 1px solid #dfe9f5;
      border-radius: 2px;
      outline: none;
      height: 20px;
    }
  }
  .rc-pagination {
    margin-left: auto;
    margin-top: 0;
    margin-bottom: 0;
  }
`;
