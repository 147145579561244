export const URL = "info/shareholder";
export const URL_INFO = "conference/result";
export const URL_REPORT = "check-in/report";
export const URL_DOWNLOAD_LIST = "check-in/list";

export const GET_LIST = "GET_CHECKIN_MANAGER_LIST";
export const SET_FILTER = "SET_CHECKIN_MANAGER_FILTER";
export const SET_TOTAL = "SET_CHECKIN_MANAGER_TOTAL";
export const GET_INFO = "GET_CHECKIN_MANAGER_INFO";
export const GET_REPORT = "GET_CHECKIN_MANAGER_REPORT";
export const SET_DOWNLOAD_LIST = "GET_CHECKIN_MANAGER_DOWNLOAD_LIST";
export const SET_DOWNLOAD_REPORT = "GET_CHECKIN_MANAGER_DOWNLOAD_REPORT";
export const SET_PRINT = "GET_CHECKIN_MANAGER_PRINT";
export const GET_DOWNLOAD_USER_LIST = "GET_CHECKIN_MANAGER_DOWNLOAD_USER_LIST";
export const GET_STATISTIC = "GET_CHECKIN_MANAGER_STATISTIC";
export const SET_CHOOSE_LIST = 'SET_CHECKIN_MANAGER_LIST_CHOOSE_LIST';
