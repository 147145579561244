import styled from 'styled-components';
import {SHotline} from './hotlinePage';
export const SInitiateGuestPage = styled(SHotline)`
  .search-wrapper {
    margin: 0 -12px;
    margin-bottom: -20px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .search-item {
      padding: 0 12px;
      /* min-width: 200px; */
      /* width: 33.33%; */
      button {
        width: 100%;
      }
      margin-bottom: 20px;
    }
  }
  .table-tools {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    .search {
      height: 32px;
      width: 303px;
      position: relative;
      border-radius: 2px;
      overflow: hidden;
      margin-bottom: 8px;
      .search-input {
        font-size: 12px;
        border:none;

        font-weight: 400;
        line-height: 18px;
        color: #718098;
        padding: 7px 35px 7px 16px;
        &::placeholder {
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          color: #718098;
          font-style:unset;
        }
      }
      svg{
        position: absolute;
        right:12px;
        top:8px;
        cursor: pointer;
        transition: all .2s;
        &:hover{
          transform: scale(1.1);
        }
      }
    }
  }
`;
