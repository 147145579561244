import styled from 'styled-components';

export const SCheckInManagementPage = styled.div``

export const SDashboardView = styled.div`
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  padding: 32px 40px 20px;
  margin-bottom: 20px;

  .dashboard-item {
    padding: 16px 16px 8px 16px;
    border-radius: 4px;
    background-repeat: no-repeat;
    background-size: cover;

    .dashboard-item-lit {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;
      p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #FFFFFF;
        margin: 0;
      }
    }
  }

  .dashboard_item_blue {
    background-image: url(/images/checkin_management1.png);
  }
  .dashboard_item_orange {
    background-image: url(/images/checkin_management2.png);
  }
  .dashboard_item_pink {
    background-image: url(/images/checkin_management3.png);
  }
  .dashboard_item_green {
    background-image: url(/images/checkin_management4.png);
  }

  @media screen and (max-width: 767px) {
    .dashboard-item {
      margin-bottom: 10px;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 5px;
  }
`

export const STableCheckinManagerment = styled.div`
  table tbody tr { 
    user-select: text;
    td { 
      min-width: 130px;
    }

    td:first-child  {
      min-width: 50px;
    }

    .dowload_file {
      margin-left: 10px;
    }
  }
`

export const SFrom = styled.div`
  box-sizing: border-box;
  padding: 32px 32px 0 32px;

  form {
    .item {
      .common__btn {
        width: 100%;
      }
    }

    .item_top {
      margin-bottom: 20px;
    }
  }

  @media screen and (max-width: 575px) {
    padding: 0;
  }
`

export const SExport = styled.div`
  margin-bottom: 8px;
  display: flex;
  justify-content: flex-end;

  .button_export {
    width: 274px;
    display: flex;
    align-items: center;

    span {
      margin-left: 6px;
    }
  }
`