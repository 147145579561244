import {
  SChatbox,
  SAvatar,
  SMessage,
  SDetailOpinion,
} from '../../../../styles/styled/admin/shareholderOpinionPage';
import inputIcons from '../../../bases/shared/icons/input';
import React, {useState, useRef, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  getChatboxApi,
  replyOpinionApi,
} from '@duck/pages/admin/shareholderOpinionReducer/actions';
import { useTranslation } from 'react-i18next';
// import Input from '@components/input';
const Avatar = ({type}) => {
  // type = admin - shareholder
  return (
    <SAvatar>
      <img
        src={
          type === 'shareholder'
            ? '../images/avatar.png'
            : '../images/chat-avt.png'
        }
        alt=""
      />
    </SAvatar>
  );
};

const Chatbox = ({onClose, chat}) => {
  const {t} = useTranslation();
  const {chatbox} = useSelector((state) => state.admin.shareholderOpinion);
  const [message, setMessage] = useState('');
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getChatboxApi(chat.data.shareholder.identityNumber));
  }, [chat, dispatch]);
  const chatboxReverse = [];
  for (var i = chatbox.length - 1; i >= 0; i--) {
    chatboxReverse.push(chatbox[i]);
  }
  const bodyChatbox =
    chatbox && chatbox.length > 0
      ? chatboxReverse.map((opinion, index) => {
          return <DetailOpinion opinion={opinion} key={index} />;
        })
      : '';
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({block: 'end', inline: 'nearest'});
  };

  useEffect(() => {
    scrollToBottom();
  });
  return (
    <SChatbox className="">
      <div
        className="close"
        onClick={() => {
          onClose();
        }}
      >
        {inputIcons.close}
      </div>
      <div className="header">
        <Avatar type="shareholder" />
        {chat.data.shareholder.name}
      </div>
      <div className="body">
        {bodyChatbox}
        <div ref={messagesEndRef} />
      </div>
      <div className="send-message">
        <Avatar type="admin" />
        <form
          className="text-area"
          onSubmit={(event) => {
            event.preventDefault();
            dispatch(
              replyOpinionApi({
                id: chatbox[0]._id,
                message,
                time: new Date(Date.now()),
                identityNumber: chat.data.shareholder.identityNumber,
              }),
            );
            setMessage('');
          }}
        >
          <input
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            placeholder={t("Viết tin nhắn...")}
          />
          <button type="submit" className="send-button">
            <svg
              className="send-button"
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              // onClick={() => {
              //   dispatch(
              //     replyOpinionApi({
              //       id: chat._id,
              //       message,
              //       time: new Date(Date.now()),
              //       identityNumber: chat.data.shareholder.identityNumber,
              //     }),
              //   );
              //   setMessage('');
              // }}
            >
              <path
                d="M1.83317 15.0004L16.3748 8.76709C17.0498 8.47542 17.0498 7.52542 16.3748 7.23375L1.83317 1.00042C1.28317 0.758754 0.674837 1.16709 0.674837 1.75875L0.666504 5.60042C0.666504 6.01709 0.974837 6.37542 1.3915 6.42542L13.1665 8.00042L1.3915 9.56709C0.974837 9.62542 0.666504 9.98375 0.666504 10.4004L0.674837 14.2421C0.674837 14.8338 1.28317 15.2421 1.83317 15.0004Z"
                fill="#00CB82"
              />
            </svg>
          </button>
        </form>
      </div>
    </SChatbox>
  );
};

export default Chatbox;

const DetailOpinion = ({opinion}) => {
  var bodyDetailOpinion;
  if (opinion.data.reply) {
    const replyList = [];
    for (var i = opinion.data.reply.length - 1; i >= 0; i--) {
      replyList.push(opinion.data.reply[i]);
    }
    bodyDetailOpinion = opinion.data.reply.map((item, index) => {
      return (
        <Message
          key={index}
          from="admin"
          message={item.message}
          time={item.time}
        />
      );
    });
  }
  return (
    <SDetailOpinion>
      <Message
        from="shareholder"
        message={opinion.data.opinion}
        time={opinion.createdAt}
      />
      {opinion.data.reply ? bodyDetailOpinion : ''}
    </SDetailOpinion>
  );
};

const Message = ({message, from, time}) => {
  return (
    <SMessage from={from}>
      <Avatar type={from} />

      <div className="text-wrapper">
        <div className="text">
          {message}
          <div className="time">
            {`${new Date(time).toLocaleDateString()} ${new Date(
              time,
            ).toLocaleTimeString('it-IT')}`}
          </div>
        </div>
      </div>
    </SMessage>
  );
};
