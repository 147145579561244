const {CHANGE_LOADING} = require('./constants');

const initialState = {
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_LOADING:
      return {...state, loading: action.payload};

    default:
      return {...state};
  }
};

export default reducer;
