import i18n from 'i18n';
import PNotify_1 from 'pnotify/dist/es/PNotify';

export const UINotify = {
  error: (message) =>
    PNotify_1.error({
      title: i18n?.t('Lỗi'),
      text: i18n?.t(message),
      delay: 2000,
    }),
  success: (message) =>
    PNotify_1.success({
      title: i18n?.t('Thành công'),
      text: i18n?.t(message),
      delay: 2000,
    }),
};
