import styled from 'styled-components';

export const SCheckInHandPage = styled.div``;

export const STable = styled.div`
  table tbody tr {
    user-select: text;
    td {
      min-width: 100px;
    }
    .last_td {
      min-width: 230px !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .last_td_check {
      margin-left: 0px;
      padding: 10px 20px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      .no_check {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: no-drop;
      }
      .btn_check {
        margin-left: 0px !important;
        width: 100%;
        height: 100% !important;
        height: 100% !important;
        padding: 4px 12px !important;
        background: rgba(0, 203, 130, 0.1) !important;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px !important;
        color: #00cb82 !important;
        &:hover {
          box-shadow: 3px 4px 6px 0px rgb(0 0 0 / 30%);
        }
      }
    }
  }
`;

export const SFrom = styled.div`
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #ffffff;
  box-sizing: border-box;
  padding: 30px;
  margin-bottom: 20px;

  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: #333b6a;
    margin: 0 0 25px 0;
  }

  form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: 0 -10px;

    .item {
      margin: 0 10px;
      label {
        height: 40px;
      }
      .common__btn {
        width: 100%;
        border-radius: 0;
      }
    }
  }
  @media only screen and (max-width: 999px) {
    form {
      grid-template-columns: 1fr;
      gap: 15px 0;
      .item {
        button {
          margin-bottom: 0px;
        }
      }
    }
  }
  /* @media only screen and (max-width: 600px) {
    form {
      display: block;
      .item {
        &:first-child {
          margin-bottom: 10px;
        }
      }
    }
  } */
`;

export const SFromAuthority = styled.div`
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #ffffff;
  box-sizing: border-box;
  padding: 40px 72px 20px 40px;
  margin-bottom: 20px;

  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: #333b6a;
    margin: 0 0 40px 0;
  }

  form {
    .item {
      margin: 0 10px 20px 10px;
    }
  }

  .form-handle {
    display: flex;
    justify-content: center;
  }
`;

export const SAuthorityList = styled.div`
  .button-content {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: end;
    .button_add {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0 8px;
      border-radius: 0;
      width: 240px;
      margin-bottom: 20px;
      border: 1px solid #00cb82;
      color: #718098;
      background: #fff;
      font-weight: 500;
    }
  }
`;

export const SAddAuthorityModal = styled.div`
  .form-content {
    position: relative;
    .list_input {
      padding: 0px;
      margin-bottom: 0px;

      .authority-info-item {
        margin-bottom: 20px;
      }
    }
    .authe-default {
      padding: 0px;
      margin-bottom: 30px;
      .text {
        margin: 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #718098;
        padding: 12px 0 8px;
      }
      .item-default {
        padding: 10px 18px;
        border: 1px solid #dfe9f5;
        display: flex;
        flex-direction: column;
        gap: 8px 0;
        .item {
          margin: 0;
          display: flex;
          flex-direction: column;
          gap: 10px 0;
          .item-text {
            padding: 6px 0;
            margin: 0;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #718098;
            &.active {
              color: #333b6a;
            }
          }
        }
      }
    }
  }

  .button_add {
    margin-bottom: 56px;
    display: flex;
    justify-content: flex-end;

    .add_btn {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      text-align: right;
      color: #2f80ed;

      padding: 0;
      background: unset;
      box-shadow: unset;
    }
  }

  .button_common {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
    button {
      min-width: 150px;
      border-radius: 0;
      font-weight: 700;
    }
    .common__cancel {
      color: #718098;
      background: #dfe9f5;
    }
  }
  @media only screen and (max-width: 991px) {
    padding: 0 20px;
  }
  @media only screen and (max-width: 767px) {
    padding: 0 20px;
  }
`;
