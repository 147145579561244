import {combineReducers} from 'redux';
import {header, headerLeft, footer} from './layout';
import admin from './pages/admin';
import login from './pages/loginPage';
import shareholderRelationship from './pages/shareholderRelationship';
import checkin from './pages/checkin';
import checkCoupon from './pages/checkCoupon';
import secretary from './pages/secretary';
import result from './pages/result';
import app from './pages/app';

const rootReducer = combineReducers({
  footer,
  header,
  headerLeft,
  admin,
  login,
  shareholderRelationship,
  checkin,
  checkCoupon,
  secretary,
  result,
  app,
});
export default rootReducer;
