import Button from '@components/button';
import Modal from '@components/modal';
import { SModal } from '@styled/shareholderRelationship/invitationManagement';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { confirmInvitationApi } from '../../../../../states/duck/pages/shareholderRelationship/invitationManagementReducer/actions';
const ConfirmDownloadModal = ({isOpen, setToggleModal}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onSubmit = () => {
    dispatch(confirmInvitationApi());
    setToggleModal(false);
  };
  return (
    <Modal
      isOpen={isOpen}
      toggleModal={setToggleModal}
      title={'Xác nhận đã gửi giấy mời cho tất cả các Cổ đông trong danh sách'}
      overwriteModalStyle={{
        maxWidth: '800px',
      }}
    >
      <SModal>
        <p className="desc">
          {t(
            'Điều này có nghĩa là bạn sẽ đóng chức năng tạo mật khẩu và tải giấy mời cho tất cả Cổ đông',
          )}
        </p>
        <p className="desc">{t('Bạn có chắc chắn với hành động này?')}</p>
        <Button onClick={onSubmit}>{t('Xác nhận')}</Button>
      </SModal>
    </Modal>
  );
};
export default ConfirmDownloadModal;
