import * as constants from './constants';
import {callApi} from '../../../../common';
import PNotify from "pnotify/dist/es/PNotify";


export const changePasswordApi = (body) => {
    return dispatch =>  callApi(`${constants.URL_CHANGE_PASSWORD}`, "PUT", body).then(function(res){
        PNotify.success({
          title: "Thành công",
          text: "Đổi mật khẩu thành công",
          destroy: true,
          delay: 2000
        });
  }).catch(function(err){
      if (err) {
      if (
        err.response &&
        err.response.data &&
        (err.response.data.message)
      ) {
        PNotify.error({
          title: "Lỗi",
          text: err.response.data.message,
          buttons: {
        closer: true,
        sticker: false //ugly
      }
        });
      } else if (err.response) {
        PNotify.error({
          title: "Lỗi",
          text: err.response.statusText,
         buttons: {
        closer: true,
        sticker: false //ugly
      }
        });
      }
      }
  })
}

export const getInfoAdmin = () => {
    return dispatch =>  callApi(`${constants.URL_INFO_ADMIN}`, "GET", null).then(function(res){
       dispatch(setInfoAdmin(res.data.data))
  }).catch(function(err){
      if (err) {
      if (
        err.response &&
        err.response.data &&
        (err.response.data.message)
      ) {
        PNotify.error({
          title: "Lỗi",
          text: err.response.data.message,
          destroy: true,
          delay: 2000
        });
      } else if (err.response) {
        PNotify.error({
          title: "Lỗi",
          text: err.response.statusText,
          destroy: true,
          delay: 2000
        });
      }
      }
  })
}


export const setPhoneTwoAuthApi = (body) => {
    return dispatch =>  callApi(`${constants.URL_SET_PHONE_AUTH}`, "POST", body).then(function(res){
       PNotify.success({
          title: "Thành công",
          text: "Thiết lập số điện thoại thành công",
          delay: 2000,
          closer: true
        });
        dispatch(getInfoAdmin())
  }).catch(function(err){
      if (err) {
      if (
        err.response &&
        err.response.data &&
        (err.response.data.message)
      ) {
        PNotify.error({
          title: "Lỗi",
          text: err.response.data.message,
          delay: 2000,
          closer: true,
          remove: true
        });
      } else if (err.response) {
        PNotify.error({
          title: "Lỗi",
          text: err.response.statusText,
          delay: 2000,
          closer: true,
        });
      }
      }
  })
}
export const getOtpApi = (body) => {
    return dispatch =>  callApi(constants.URL_SET_PHONE_TO_OTP, "POST", body ,function(res){
        // dispatch(setOTP(body.phone));
    });
}
export const setToggleTwoAuthApi = (body) => {
    return dispatch =>  callApi(`${constants.URL_TOGGLE_AUTH}`, "POST", body).then(function(res){
      dispatch(getInfoAdmin())
      dispatch(setToggleModal(false));
        PNotify.success({
          title: "Thành công",
          text: "thành công",
          destroy: true,
          delay: 200000
        });
  }).catch(function(err){
      if (err) {
      if (
        err.response &&
        err.response.data &&
        (err.response.data.message)
      ) {
        PNotify.error({
          title: "Lỗi",
          text: err.response.data.message,
          destroy: true,
          delay: 200000
        });
      } else if (err.response) {
        PNotify.error({
          title: "Lỗi",
          text: err.response.statusText,
          destroy: true,
          delay: 200000
        });
      }
      }
  })
}

export const setInfoAdmin = (value) => {
    return {
        type: constants.GET_INFO,
        value
    }
}
export const setToggleModal = (value) => {
    return {
        type: constants.SET_TOGGLE_MODAL,
        value
    }
}
