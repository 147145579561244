import Modal from '@components/modal';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@components/button';
import {
  VALIDATE_ONLY_NUMBER,
  VALIDATE_PATTERN,
  VALIDATE_REQUIRE,
} from '@constants/validate';
import { sortOrderApiPeople } from '@duck/pages/admin/initializeReportReducer/actions';
import ErrorMessage from '@components/input/ErrorMessage';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

const SortModal = ({ isOpen, setIsOpen, tableList, filter }) => {
  const { t } = useTranslation();
  const [list, setList] = useState([]);
  const dispatch = useDispatch();

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({ shouldUnregister: true });

  const onSubmit = (data) => {
    const dataSort = Object.entries(data).map(([key, value]) => ({
      id: key,
      order: value,
    }));
    dispatch(
      sortOrderApiPeople({ data: dataSort }, filter, () => setIsOpen(false)),
    );
  };

  useEffect(() => {
    if (tableList) {
      setList(tableList);
    }
    if (isOpen) {
      tableList.map((t) => setValue(t._id, t.orderInAuthorizedList));
    }
  }, [tableList, isOpen]);
  return (
    <Modal
      isOpen={isOpen}
      toggleModal={setIsOpen}
      title={t('Sắp xếp thứ tự hiển thị')}
      overwriteModalStyle={{
        maxWidth: '560px',
      }}
    >
      <SModalContent>
        <tr className="header">
          <th>{t('Đại diện nhận uỷ quyền')}</th>
          <th>{t('Thứ tự')}</th>
        </tr>
        {list.length > 0 ? (
          list.map((item, index) => {
            return (
              <>
                <ErrorMessage errors={errors} field={`${item._id}`} />
                <tr className="body" key={index}>
                  <td>{item.name}</td>
                  <td className="input">
                    <input
                      className="order"
                      type="number"
                      {...register(`${item._id}`, {
                        required: t(VALIDATE_REQUIRE),
                        pattern: {
                          value: VALIDATE_ONLY_NUMBER,
                          message: t(VALIDATE_PATTERN),
                        },
                        value: item?.orderInAuthorizedList?.toString(),
                        validate: (value) =>
                          (!isNaN(Number(value)) && Number(value) > 0) ||
                          t('Thứ tự phải lớn hơn 0'),
                      })}
                    />
                  </td>
                </tr>
              </>
            );
          })
        ) : (
          <tr>
            <td colSpan={100} className="center">
              {' '}
              {t('Chưa có dữ liệu')}{' '}
            </td>
          </tr>
        )}
        <div className="button-group">
          <Button className="cancel-button" onClick={() => setIsOpen(false)}>
            {t('Hủy bỏ')}
          </Button>
          <Button onClick={handleSubmit(onSubmit)} className="confirm-button">
            {t('Xác nhận')}
          </Button>
        </div>
      </SModalContent>
    </Modal>
  );
};

export default SortModal;

const SModalContent = styled.div`
  padding: 16px 16px 32px 16px;
  background-color: ${(props) => props.backGroundColor || 'white'};
  margin-top: 30px;
  border-top: 1px solid rgba(223, 233, 245, 1);
  display: flex;
  flex-direction: column;
  gap: 4px;
  .header {
    display: flex;
    justify-content: space-between;
    td {
      color: var(--718098, #718098);
      text-align: center;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }
  .body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .input {
      width: 50px;
      height: 40px;
    }
  }
  .button-group {
    padding-top: 30px;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    .confirm-button {
      background: #00cb82;
      color: #ffffff;
    }
    .cancel-button {
      color: #718098;
      background: #dfe9f5;
    }
  }
  .module-modal {
    text-align: center;
    img {
      max-width: 100%;
    }
  }
  .button-group {
    padding-top: 30px;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    .confirm-button {
      background: #00cb82;
      color: #ffffff;
    }
    .cancel-button {
      color: #718098;
      background: #dfe9f5;
    }
  }

  @media (max-width: 1199px) {
    padding: 0 40px;
  }
  @media (max-width: 991px) {
    padding: 0 20px;
    margin-top: 20px;
  }
  @media (max-width: 767px) {
    padding: 0 15px;
  }

  .group-button {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;
    width: 100%;

    & > ${Button} {
      margin-left: 16px;
      margin-bottom: 8px;
    }

    @media (max-width: 991px) {
      margin-top: 20px;
    }
  }
  .form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;
