import React from 'react';
import {Controller} from 'react-hook-form';
import Wrapper from './Wrapper';
import inputIcons from '../icons/input';
import TimePicker from 'react-time-picker';

const DateWithWrapper = (props) => {
  const {wrapperProps, name, control, rules, defaultValue = null} = props;

  const isLabelOnTop = true;
  return (
    <Wrapper
      {...wrapperProps}
      icon={inputIcons.time}
      isLabelOnTop={isLabelOnTop}
    >
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={({field: {onChange, value}, ...rest}) => {
          return (
            <TimePicker
              format="HH:mm"
              {...rest}
              value={value}
              onChange={onChange}
            />
          );
        }}
      />
    </Wrapper>
  );
};

export default DateWithWrapper;
