export const URL = "topic/upload-report";
export const URL_RESOLUTION = "topic/upload-resolution";
export const URL_INFO = "info/conference-file";
export const GET_INFO = "GET_SECRETARY_REPORT_INFO";








