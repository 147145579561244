import * as types from './constants';
import * as common from './../../../../common/constants';

var initialState = {
  list: [],
  total: 0,
  listChose: [],
  filter: {
    pageSize: common.PAGE_SIZE,
    pageNumber: 1,
    search: '',
  },
  sending: false,
};

var reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_GUEST:
      return {
        ...state,
        list: action.value,
      };
    case types.SET_FILTER:
      return { ...state, filter: action.value };
    case types.SET_TOTAL:
      return { ...state, total: action.value };
    case types.SET_LIST_CHOSE:
      return { ...state, listChose: action.value };
    case types.SET_SENDING:
      return { ...state, sending: action.value };
    default:
      return state;
  }
};

export default reducer;
