import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import videojs from 'video.js';

const VideoLivestream = () => {
  const { conferenceInfo } = useSelector((state) => state.result.result);
  const { i18n } = useTranslation();
  const [link, setLink] = useState('');
  useEffect(() => {
    if (i18n.language === 'vi') setLink(conferenceInfo.livestreamLink);
    else setLink(conferenceInfo.livestreamLink2);
  }, [conferenceInfo, i18n.language]);

  if (
    i18n.language === 'vi' &&
    (!conferenceInfo.livestreamLink || conferenceInfo.livestreamLink === '')
  )
    return null;
  else if (
    i18n.language === 'en' &&
    (!conferenceInfo.livestreamLink2 || conferenceInfo.livestreamLink2 === '')
  )
    return null;

  let src = getYoutubeEmbedLinkFromUrl(
    i18n.language === 'vi'
      ? conferenceInfo.livestreamLink
      : conferenceInfo.livestreamLink2,
  );
  const isCloudFlaresStream = link?.includes('cloudflarestream');
  if (isCloudFlaresStream) src = link + '?autoplay=true&muted=true';

  const isIFrame = link && (link.indexOf('youtube') > 0 || isCloudFlaresStream);
  return (
    <Styles>
      {isIFrame ? (
        <div className="video-content">
          <div className="video">
            <iframe
              width="560"
              height="315"
              src={src}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      ) : link ? (
        <div className="video-content">
          <VideoStream2 videoLink={link} />
        </div>
      ) : (
        ''
      )}
    </Styles>
  );
};

export default VideoLivestream;

export const getYoutubeEmbedLinkFromUrl = (link) => {
  if (link && !link.includes('youtube.com')) return link;
  const re =
    /(https?:\/\/)?((www\.)?(youtube(-nocookie)?|youtube.googleapis)\.com.*(v\/|v=|vi=|vi\/|e\/|embed\/|user\/.*\/u\/\d+\/)|youtu\.be\/)([_0-9a-z-]+)/i;
  if (link.match(re)[7]) {
    const id = link.match(re)[7];
    if (!id) return undefined;
    return getYoutubeEmbebLinkFromId(id);
  } else return undefined;
};

export const getYoutubeEmbebLinkFromId = (id) => {
  return 'https://www.youtube.com/embed/' + id;
};

const Styles = styled.div`
  /* margin-top: -120px; */

  /* clip-path: polygon(0% 0%, 93% 0%, 100% 10%, 100% 100%, 7% 100%, 0% 90%); */

  .video-content {
    position: relative;
    /* iframe, */
    .video-js {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      /* height: 600px; */
      padding-bottom: 56.25%;
    }
    .vjs-big-play-button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .number__seeing {
      position: absolute;
      right: 9px;
      top: 13px;
      background: #fff;
      text-align: center;
      white-space: nowrap;
      padding: 6px 10px;
    }

    /* @media only screen and (max-width: 767px) {
      height: 225px;
    } */
    border: 1px solid #828ea6;
    .video {
      position: relative;
      padding-bottom: 46.9%;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* margin-top: -80px; */
    .video-content {
      iframe,
      .video-js {
        /* height: 400px; */
      }
    }
  }
  @media screen and (max-width: 767px) {
    /* margin-top: -30px; */
    .bs-container {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  @media screen and (max-width: 479px) {
    margin-top: 0px;
    .video-content {
      iframe,
      .video-js {
        /* height: 300px; */
      }
    }
    .bs-container {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
`;

function VideoStream2({ videoLink }) {
  const playerRef = useRef(null);

  useEffect(() => {
    if (
      playerRef &&
      playerRef.current &&
      videoLink &&
      videoLink.indexOf('youtube') < 0
    ) {
      const player = videojs(
        playerRef.current,
        { controls: true, preload: 'auto' },
        () => {
          // console.log('videoLink', videoLink);
          player.src(videoLink);
          player.play();
        },
      );
    }
  }, [videoLink]);

  return (
    <div>
      <video
        id="video-js"
        className="video-js"
        ref={playerRef}
        width="100%"
        height="100%"
      />
    </div>
  );
}
