import styled from 'styled-components';
import { SHotline, SModalEditHotline } from '../../hotlinePage';
import { SSection } from '@components/section';
export const SAuthorizationListPage = styled(SHotline)`
  .module-content {
    margin: 0px;
    .search-wrapper {
      padding: 0 12px;
      display: grid;
      justify-content: center;
      grid-template-columns: 4fr 1fr;
      gap: 20px 16px;
      .search-item {
        padding: 0px;
        width: 100%;
        button {
          width: 100%;
        }
        .search {
          background: #00cb82;
        }
      }
    }
  }
  .tool-container {
    display: block;

    /* .list-handle {
      padding-top: 40px;
      > div:first-child {
        padding-left: 0px;
      }
    } */
  }
  @media only screen and (max-width: 630px) {
    .module-content {
      .search-wrapper {
        display: block;
        padding: 0px;
        .search-item {
          &.filter-search {
            width: 100%;
          }
        }
      }
    }
    .tool-container {
      display: block;
      .list-handle {
        clear: both;
      }
    }
  }
`;
export const SModalEditAuthorizationList = styled(SModalEditHotline)`
  padding: 0 56px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 45px;

  .handle-wrapper {
    width: 100%;
    box-sizing: border-box;
    display: block;
    /* flex-wrap: wrap; */
    &.content {
      padding: 0 50px;
    }
    .handle-item {
      box-sizing: border-box;

      &.width-50 {
        width: 50%;
      }
      &.width-100 {
        width: 100%;
      }
    }
  }
  .button-group {
    padding-left: 16px;
    padding-right: 16px;
    justify-content: center;
    pa .confirm-button {
      background: #00cb82;
      color: #ffffff;
    }
  }
  @media only screen and (max-width: 630px) {
    .handle-wrapper {
      &.content {
        padding: 0;
      }
    }
  }
  @media only screen and (max-width: 479px) {
    padding: 0 22px;
    .handle-wrapper {
      &.content {
        padding: 0;
      }
    }
    .button-group {
      padding-top: 5px;
    }
  }
`;

export const SSectionCustom = styled(SSection)`
  background: rgba(255, 255, 255, 0.5);
  padding: 25px 30px;
  border: 1px solid white;

  .module-header {
    display: none;
  }
  .module-content {
  }
  @media only screen and (max-width: 1326px) {
  }
  @media only screen and (max-width: 1054px) {
  }
  @media only screen and (max-width: 780px) {
  }
  @media only screen and (max-width: 500px) {
  }
`;

export const Styles = styled.div`
  .item-tab {
    display: flex;
    align-items: center;
    gap: 10px 4px;
    .tab-li {
      list-style: none;
      padding: 10px 20px;
      background: #ffffff;
      border-radius: 4px 4px 0px 0px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #00cb82;
      cursor: pointer;
      &.active {
        background: #00cb82;
        color: #ffffff;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .item-tab {
      justify-content: center;
      flex-wrap: wrap;
      padding-bottom: 10px;
      gap: 10px;
      .tab-li {
        border-radius: 2px;
        font-size: 15px;
      }
    }
  }
`;

export const StylesContent = styled.div`
  padding: 40px;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #ffffff;
  .module-content {
    padding: 0 30px;
    .form {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      align-items: center;
      justify-content: content;
      .form-item {
        /* &.with-50 {
        width: 50%;
      } */
      }
    }
    .form-button {
      text-align: center;
      padding-top: 30px;
      .item-button {
        width: 160px;
        background: #00cb82;
        color: #ffffff;
      }
    }
  }
  @media only screen and (max-width: 1199px) {
    .module-content {
      padding: 0;
    }
  }
  @media only screen and (max-width: 820px) {
    padding: 40px 30px;
    .module-content {
      .form {
        grid-template-columns: 1fr;
      }
    }
  }
  @media only screen and (max-width: 480px) {
    padding: 30px 20px;
  }
`;
