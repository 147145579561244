import styled from 'styled-components';
import { SHotline, SModalEditHotline } from '../../hotlinePage';
import { SSection } from '@components/section';
export const SAuthorizationListPage = styled(SHotline)`
  .module-content {
    margin: 0;
  }
  .search-wrapper {
    margin: 0 -12px;
    margin-bottom: -20px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .search-item {
      padding: 0 12px;
      /* min-width: 200px; */
      width: 25%;
      button {
        width: 100%;
      }
      .search {
        background: #00cb82;
      }
      margin-bottom: 20px;
    }
  }
  .tool-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    .export-btn {
      width: 200px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #718098;
      border-radius: 0;
      border: 1px solid #00cb82;
      background: #ffffff;
      svg {
        margin-right: 13px;
        path {
          fill: #00cb82;
        }
      }
    }
    .list-handle {
      > div:first-child {
        margin-left: 5px;
      }
    }
  }

  @media only screen and (max-width: 1326px) {
    .search-wrapper {
      .search-item {
        width: 33.33%;
      }
    }
  }
  @media only screen and (max-width: 780px) {
    .search-wrapper {
      .search-item {
        width: 50% !important;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .tool-container {
      display: block;
      .export-btn {
        float: right;
        margin: 7px 15px;
      }
      .list-handle {
        clear: both;
      }
    }
  }
  @media only screen and (max-width: 555px) {
    .search-wrapper {
      .search-item {
        width: 100% !important;
      }
    }
  }
`;
export const SModalEditAuthorizationList = styled(SModalEditHotline)`
  padding: 0 56px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 45px;

  .handle-wrapper {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    .handle-item {
      box-sizing: border-box;
      padding-left: 16px;
      padding-right: 16px;
      &.width-50 {
        width: 50%;
      }
      &.width-100 {
        width: 100%;
      }
    }
  }
  .button-group {
    padding-left: 16px;
    padding-right: 16px;
    justify-content: flex-end;
  }
`;

export const SSectionCustom = styled(SSection)`
  .module-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .file-handle {
      display: flex;
      justify-content: flex-end;
      margin-right: -8px;
      margin-left: -8px;
      button {
        padding: 2px 4px;
        width: 225px;
        /* max-width: 33.33%; */
        height: 40px;
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #718098;
        margin: 0 8px;
        border-radius: 0;
        border: 1px solid #00cb82;
        background: #ffffff;
        svg {
          margin-right: 12px;
          path {
            fill: #00cb82;
          }
        }
        /* &.grey-button {
          background: #718098;
        }
        &.blue-button {
          background: #2f80ed;
        } */
        &.upload-btn {
          position: relative;
          input {
            opacity: 0;
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            height: 100%;
            cursor: pointer;
          }
          .file {
            cursor: pointer;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #dfe9f5;
            text-align: center;
            color: #2f80ed;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            background: #f5faff;

            svg {
              position: absolute;
              top: -10px;
              right: -20px;
              cursor: pointer;
            }
          }
        }
      }
    }
    .title {
      margin: 0;
      font-size: 20px;
      line-height: 30px;
      color: #333b6a;
      font-weight: bold;
    }
  }
  @media only screen and (max-width: 1326px) {
    .module-header {
      .file-handle {
        margin-top: 10px;
        width: calc(100% + 16px);
        justify-content: space-between;
        button {
          width: 33.33%;
        }
      }
    }
  }
  @media only screen and (max-width: 1054px) {
    .module-header {
      .file-handle {
        button {
          font-size: 13px;
        }
      }
    }
  }
  @media only screen and (max-width: 780px) {
    .module-header {
      .file-handle {
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 10px;
        button {
          margin-top: 10px;
          width: calc(50% - 16px);
        }
      }
    }
  }
  @media only screen and (max-width: 500px) {
    .module-header {
      .file-handle {
        button {
          width: calc(100% - 16px);
        }
      }
    }
  }
`;
