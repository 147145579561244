import * as types from './constants';
const initialState = {
    info: {}
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case types.GET_INFO:
        return {...state, info: action.value};
    default:
        return state
    }
}
export default reducer;