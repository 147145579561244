import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import SectionReport from './sectionReport';
// import SectionResult from "./sectionResult";
import SectionInfo from './sectionInfo';
// import SectionRate from "./sectionRate";
import SectionComment from './sectionComment';
import SectionFinishResult from './sectionFinishResult';
import SectionOpeningResult from './sectionOpeningResult';
import VideoLivestream from './VideoLivestream';

import Modal from '@components/modal';
import { routers } from 'components/layout/router';
import styled from 'styled-components';
import {
  getConferenceInfoApi,
  getDataApi,
  getTopic1Api,
  getTopic2Api,
  getTopic3Api,
  getTopic4Api,
  getTopic6Api,
} from '../../../states/duck/pages/result/resultReducer/actions';
import SettingModal from './components/Setting';
import { ToolSection } from './Tool';
import { t } from 'translate';
// import sectionCommentVitrualLized from './sectionCommentVitrualLized';
// const REQUEST_AGAIN = 60000;

export const pageSize = 5;
const ResultPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const pathname = history.location.pathname;
  // const [activeCount, setActiveCount] = useState(1);
  const data = useSelector((state) => state.result.result.data);
  const [isDetail, setDetail] = useState(false);
  const [isPlay, setPlay] = useState(true);
  const [toggle, setToggle] = useState();
  const ref = useRef();
  const getAllData = () => {
    dispatch(getDataApi());
    dispatch(getConferenceInfoApi());
    dispatch(getTopic1Api(1));
    dispatch(getTopic2Api(2));
    dispatch(getTopic3Api(3));
    dispatch(getTopic4Api(4));
    dispatch(getTopic6Api(6));
  };
  useEffect(() => {
    if (isPlay) {
      getAllData();
      ref.current = setInterval(() => {
        getAllData();
      }, 30000);
    } else clearInterval(ref.current);

    return () => {
      clearInterval(ref.current);
    };
  }, [isPlay]);

  const dataTopic1 = [];
  const dataTopic2 = [];
  const dataTopic3 = [];

  const questions = data && data.topicKind123 ? data.topicKind123 : {};
  for (let i in questions) {
    if (questions[i].topic.kind === 1) {
      dataTopic1.push(questions[i]);
    }
    if (questions[i].topic.kind === 2) {
      dataTopic2.push(questions[i]);
    }
    if (questions[i].topic.kind === 3) {
      dataTopic3.push(questions[i]);
    }
  }
  // console.log(dataTopic3)

  const userInfo = useSelector((state) => state.login.userInfo);

  if (userInfo.permissions) {
    const permissionRouters = routers.filter((r) =>
      userInfo?.permissions?.includes(r.per),
    );

    if (permissionRouters.every((r) => r.path !== '/'))
      return <Redirect to={permissionRouters[0]?.path || '/login'} />;
  }
  return (
    <Styles>
      {/* <ScrollToTop />
      <ScrollToBottom /> */}

      <VideoLivestream />
      <SectionInfo pathname={pathname} isDetail={isDetail} />
      <SectionComment isDetail={isDetail} />
      {/* <sectionCommentVitrualLized /> */}
      {/* {pathname === '/chu-tich' ? <SectionComment /> : ''} */}
      <SectionOpeningResult dataShow={dataTopic1} isDetail={isDetail} />
      <SectionReport dataShow={dataTopic2} isDetail={isDetail} />
      <SectionFinishResult dataShow={dataTopic3} isDetail={isDetail} />
      {/* <Footer /> */}
      <ToolSection
        isDetail={isDetail}
        setDetail={setDetail}
        isPlay={isPlay}
        setPlay={setPlay}
        toggle={toggle}
        setToggle={setToggle}
      />

      {toggle && (
        <Modal
          isOpen={toggle}
          toggleModal={setToggle}
          title={t('Cài đặt hiển thị bảng kết quả trang chủ')}
          overwriteModalStyle={{ maxWidth: '511px' }}
        >
          <SettingModal toggleModal={setToggle} />
        </Modal>
      )}
    </Styles>
  );
};
export default ResultPage;

const Styles = styled.div`
  p {
    margin-top: 0%;
  }

  .topic-label {
    text-align: center;
    margin: 0 0 16px;
    font-size: 24px;
    font-weight: 900;
    text-transform: uppercase;
  }

  .pagination-cover {
    .page-size {
      display: none;
    }

    .rc-pagination {
      margin: 0 auto;
    }
  }
`;
