import React from 'react';
import styled from 'styled-components';

export const UIList = ({items, children, ...props}) => {
  return (
    <Wrapper {...props}>
      {items.map(
        (item, ind) =>
          children && React.cloneElement(children(item, ind), {key: ind}),
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  -webkit-overflow-scrolling: touch;
`;
