export const URL = 'info/opinion';
export const URL_ALL = 'info/shareholder';
export const URL_BLOCK_OPINION = "opinion/block";
export const URL_INVITE_SPEAK = 'opinion/speak';
export const URL_SEND_BOSS = 'opinion/send-boss';
export const URL_DISPLAY = 'opinion/public';
export const URL_REPLY_OPINION = 'opinion/reply';
export const URL_EXPORT = 'info/opinion/export';

export const SET_LOCK_SHAREHOLDER = "SET_LOCK_SHAREHOLDER";
export const GET_LIST = 'GET_SECRETARY_COMMENT_LIST_LIST';
export const SET_CHOOSE_LIST = 'SET_SECRETARY_COMMENT_LIST_LIST';
export const SET_FILTER = 'SET_SECRETARY_COMMENT_LIST_FILTER';
export const SET_TOTAL = 'SET_SECRETARY_COMMENT_LIST_TOTAL';
export const SET_TOGGLE_MODAL = 'SET_SECRETARY_COMMENT_TOGGLE_MODAL';
export const SET_CHATBOX = "SET_CHATBOX";