import * as constants from './constants';
import { callApi, callExportApi, callDownloadApi } from './../../../../common';
import * as common from './../../../../common/constants';
import PNotify from 'pnotify/dist/es/PNotify';
import { handleError } from '@configs/handleError';
var FileSaver = require('file-saver');

export const getUserApi = (body) => {
  console.log('body', body);
  const { pageSize, pageNumber, identityNumber, status, type, form, order } =
    body;
  if (type === 'all' || type === undefined || type === null)
    return (dispatch) =>
      callApi(`${constants.URL}/${pageSize}/${pageNumber}`, 'POST', {
        status: status,
        search: identityNumber || '',
        form: form,
        order,
      })
        .then(function (res) {
          dispatch(getListAccount(res.data.data.data));
          dispatch(setTotal(res.data.data.total));
        })
        .catch(handleError);
  else
    return (dispatch) =>
      callApi(
        `${constants.URL_GET_BY_FILTER}/${pageSize}/${pageNumber}`,
        'POST',
        {
          status: status,
          search: identityNumber || '',
          type: type,
        },
      )
        .then(function (res) {
          dispatch(getListAccount(res.data.data.data));
          dispatch(setTotal(res.data.data.total));
        })
        .catch(handleError);
};

export const exportAuthorityApi = (body) => {
  const { status, identityNumber, form } = body;
  return (dispatch) =>
    callExportApi(`${constants.URL_EXPPORT_AUTHO}`, 'POST', {
      search: identityNumber,
      status: status,
      form: form,
    })
      .then(function (res) {
        PNotify.success({
          title: 'Thành công',
          text: 'Xuất danh sách ủy quyền thành công!',
          destroy: true,
          delay: 2000,
        });
        var file = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        FileSaver.saveAs(file, 'DSUyQuyen');
      })
      .catch(handleError);
};

export const downloadFileAPI = () => {
  return (dispatch) =>
    callDownloadApi(`${constants.URL_DOWNLOAD}`, 'GET', null).then(function (
      res,
    ) {
      console.log('resssss', res);
      var file = new Blob([res.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
      });
      FileSaver.saveAs(file, 'DanhsachUyquyen');
    });
};

export const getEditApi = ({ id, body, filter }) => {
  return (dispatch) =>
    callApi(`${constants.URL_EDIT}/${id}`, 'POST', body)
      .then(function (res) {
        setTimeout(() => {
          dispatch(getUserApi(filter));
        }, 500);
        PNotify.success({
          title: 'Thành công',
          text: 'Sửa thành công',
          destroy: true,
          delay: 2000,
        });
      })
      .catch(handleError);
};

export const getDeleteApi = (ids, filter) => {
  const filterNew = {
    ...filter,
    pageNumber: 1,
  };
  return (dispatch) => {
    callApi(`${constants.URL_DELETE}`, 'DELETE', { ids })
      .then((res) => {
        setTimeout(() => {
          dispatch(setFilter(filterNew));
          dispatch(getUserApi(filterNew));
        }, 200);
        PNotify.success({
          title: 'Thành công',
          text: 'Xóa lệnh ủy quyền thành công',
          destroy: true,
          delay: 2000,
        });
      })
      .catch(handleError);
  };
};

export const getHandleAuthorityApi = (id, action, filter) => {
  return (dispatch) =>
    callApi(`${constants.URL_HANDLE_AUTHORITY}`, 'POST', {
      action,
      authorityIds: [id],
    })
      .then(function (res) {
        setTimeout(() => {
          dispatch(getUserApi(filter));
          dispatch(setChooseList([]));
        }, 200);
        PNotify.success({
          title: 'Thành công',
          text: 'Xử lý thành công',
          destroy: true,
          delay: 2000,
        });
      })
      .catch(handleError);
};
// export const getCreateApi = (body, filter) => {
//   return (dispatch) =>
//     callApi(`${constants.URL_CREATE}`, 'POST', body)
//       .then(function (res) {
//         dispatch(setSending(false));
//         PNotify.success({
//           title: 'Thành công',
//           text: 'Import danh sách ủy quyền thành công',
//           destroy: true,
//           delay: 2000,
//         });
//         setTimeout(() => {
//           dispatch(getUserApi(filter));
//         }, 500);
//       })
//       .catch(handleError);
// };

export const getCreateApi = (body) => {
  return (dispatch) =>
    callApi(`${constants.URL_CREATE}`, 'POST', body)
      .then(function (res) {
        dispatch(setSending(false));
        PNotify.success({
          title: 'Thành công',
          text: 'Tạo danh sách thành công',
          destroy: true,
          delay: 2000,
        });
        setTimeout(() => {
          dispatch(
            setFilter({
              identityNumber: '',
              joinType: 'all',
              status: 'all',
              type: 'all',
              pageSize: common.PAGE_SIZE,
              pageNumber: 1,
            }),
          );
        }, 500);
      })
      .catch(function (err) {
        dispatch(setSending(false));
        // if (err) {
        //   if (err.response && err.response.data && err.response.data.message) {
        //     PNotify.error({
        //       title: 'Lỗi',
        //       text: err.response.data.message,
        //       destroy: true,
        //       delay: 2000,
        //     });
        //   } else if (err.response) {
        //     PNotify.error({
        //       title: 'Lỗi',
        //       text: err.response.statusText,
        //       destroy: true,
        //       delay: 2000,
        //     });
        //   }
        // }
      });
};
export const getListAccount = (value) => {
  return {
    type: constants.GET_LIST,
    value,
  };
};
export const setSending = (value) => {
  return {
    type: constants.SET_SENDING,
    value,
  };
};
export const setChooseList = (value) => {
  return {
    type: constants.SET_CHOOSE_LIST,
    value,
  };
};
export const setFilter = (value) => {
  return {
    type: constants.SET_FILTER,
    value,
  };
};
export const setTotal = (value) => {
  return {
    type: constants.SET_TOTAL,
    value,
  };
};
