import * as types from './constants';
import * as common from './../../../../common/constants';

var initialState = {
  tableList: [],
  listTopicChoose: [],
  toggleModal: false,
  filter: {
    search: '',
    status: 'all',
    pageSize: common.PAGE_SIZE,
    pageNumber: 1,
    type:'all',
  },
  total: 0,
  sending: false,
  loading: false,
};
var reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST:
      return { ...state, tableList: action.value };

    case types.GET_LOADING:
      return { ...state, loading: action.value };

    case types.TOGGLE_MODAL:
      return { ...state, toggleModal: action.value };

    case types.SET_CHOOSE_LIST:
      return { ...state, listTopicChoose: action.value };

    case types.SET_FILTER:
      return { ...state, filter: action.value };

    case types.SET_TOTAL:
      return { ...state, total: action.value };

    case types.SET_SENDING:
      return { ...state, sending: action.value };

    default:
      return state;
  }
};
export default reducer;
