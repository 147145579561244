import Modal from '@components/modal';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  VALIDATE_NO_WHITESPACE_REGEX,
  VALIDATE_NO_WHITESPACE,
  VALIDATE_REQUIRE,
  VALIDATE_PASSWORD,
} from '@constants/validate';
import Input from '@components/input';
import { useForm } from 'react-hook-form';
import inputIcons from '@components/icons/input';
import Button from '@components/button';
import styled from 'styled-components';
import ErrorMessage from '@components/input/ErrorMessage';
import { useDispatch, useSelector } from 'react-redux';
import {
  createGuestApi,
  editGuestApi,
} from '@duck/pages/admin/initializeGuestReducer/actions';

const EditModal = ({ toggleModal, setToggleModal, modalType, item }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { filter } = useSelector((state) => state.admin.initializeGuest);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const onSubmit = (value) => {
    const { name, password, identityNumber, information } = value;
    if (modalType === 'add') {
      dispatch(
        createGuestApi(
          {
            name: name,
            identityNumber: identityNumber,
            guestInfo: information,
            password: password,
          },
          filter,
          setToggleModal,
        ),
      );
    } else {
      dispatch(
        editGuestApi(
          item._id,
          {
            name: name,
            identityNumber: identityNumber,
            guestInfo: information,
            password: password,
          },
          filter,
          setToggleModal,
        ),
      );
    }
  };

  useEffect(() => {
    if (toggleModal && modalType === 'add') {
      console.log(1)
      setValue('name', '');
      setValue('identityNumber', '');
      setValue('password', '');
      setValue('information', '');
    } else if (toggleModal && modalType === 'edit') {
      setValue('name', item && item.name);
      setValue('identityNumber', item && item.identityNumber);
      setValue('password', item && item.password);
      setValue('information', item && item.guestInfo);
    }
  }, [item, toggleModal]);

  return (
    <Modal
      isOpen={toggleModal}
      toggleModal={setToggleModal}
      title={modalType === 'add' ? t('Thêm mới') : t('Sửa thông tin khách mời')}
      overwriteModalStyle={{
        maxWidth: '560px',
      }}
    >
      <SModalContent>
        <form className="form">
          <div className="form-item">
            <Input.Text
              register={register('name', { required: t(VALIDATE_REQUIRE) })}
              wrapperProps={{
                label: t('Họ tên'),
                icon: inputIcons.user,
                required: true,
              }}
              inputProps={{
                defaultValue: item && item.name,
              }}
            />
            <ErrorMessage errors={errors} field="name" />
          </div>
          <div className="form-item">
            <Input.Text
              register={register('identityNumber', {
                required: t(VALIDATE_REQUIRE),
              })}
              wrapperProps={{
                label: t('Tài khoản'),
                icon: inputIcons.user,
                required: true,
              }}
              inputProps={{
                defaultValue: item && item.identityNumber,
              }}
            />
            <ErrorMessage errors={errors} field="identityNumber" />
          </div>
          <div className="form-item">
            <Input.Text
              register={register('password', {
                required: modalType === 'add' ? t(VALIDATE_REQUIRE) : false,
                pattern: {
                  value: VALIDATE_PASSWORD,
                  message: t(VALIDATE_NO_WHITESPACE),
                },
              })}
              wrapperProps={{
                label: t('Mật khẩu'),
                icon: inputIcons.code,
                required: modalType === 'add' ? true : false,
              }}
            />
            <ErrorMessage errors={errors} field="password" />
          </div>
          <div className="form-item">
            <Input.Text
              register={register('information')}
              wrapperProps={{
                label: t('Thông tin'),
                icon: inputIcons.file,
                required: false,
              }}
              inputProps={{
                defaultValue: item && item.guestInfo,
              }}
            />
          </div>
        </form>

        <div className={`group-button`}>
          <Button
            style={{ color: '#718098', backgroundColor: '#DFE9F5' }}
            onClick={() => {
              setToggleModal(false);
            }}
          >
            {t('Hủy bỏ')}
          </Button>
          <Button type="submit" onClick={handleSubmit(onSubmit)}>
            {modalType === 'add' ? t('Thêm') : t('Cập nhật')}
          </Button>
        </div>
      </SModalContent>
    </Modal>
  );
};

export default EditModal;

const SModalContent = styled.div`
  padding: 0 80px;
  background-color: ${(props) => props.backGroundColor || 'white'};

  margin-top: 44px;

  @media (max-width: 1199px) {
    padding: 0 40px;
  }
  @media (max-width: 991px) {
    padding: 0 20px;
    margin-top: 20px;
  }
  @media (max-width: 767px) {
    padding: 0 15px;
  }

  .group-button {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;
    width: 100%;

    & > ${Button} {
      margin-left: 16px;
      margin-bottom: 8px;
    }

    @media (max-width: 991px) {
      margin-top: 20px;
    }
  }
  .form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;
