import React from 'react';
import styled from 'styled-components';

// interface Props {
//   checked: boolean;
//   onChange?: () => void;
// }

const Checkbox = ({checked, onChange, className = ''}) => {
  return (
    <SCheckbox
      onClick={onChange}
      className={checked === true ? `checked ${className}` : className}
    >
      <svg
        width="8"
        height="6"
        viewBox="0 0 8 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.71715 5.86562L0.117152 3.26562C-0.0390508 3.10941 -0.0390508 2.85615 0.117152 2.69993L0.682824 2.13424C0.839027 1.97802 1.09231 1.97802 1.24851 2.13424L3 3.88571L6.75148 0.134242C6.90768 -0.0219609 7.16097 -0.0219609 7.31717 0.134242L7.88284 0.69993C8.03904 0.856133 8.03904 1.1094 7.88284 1.26562L3.28284 5.86563C3.12662 6.02184 2.87336 6.02184 2.71715 5.86562Z"
          fill="white"
        />
      </svg>
    </SCheckbox>
  );
};

export const SCheckbox = styled.div`
  margin: 0 auto;
  transition: 0.15s ease-out;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border-color: #a6b0cf;
  border-width: 1px;
  border-style: solid;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &.checked {
    border-color: #00cb82;
    border-width: 8px;
  }
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export default Checkbox;
