import React, {useState, useEffect} from 'react';
import PNotify from 'pnotify/dist/es/PNotify';
import {useForm} from 'react-hook-form';
import {
  settingAuthorityApi,
  getInfoSettingAuthorityApi,
} from '../../../../states/duck/pages/checkin/authoritySettingReducer/actions';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import LayoutContent from 'components/layout/layoutContent';
import {SAuthoritySettingPage} from '@styled/checkIn/authoritySettingPage';
import Input from '@components/input';
import inputIcons from '@components/icons/input';
import ErrorMessageComponent from '@components/input/ErrorMessage';
import {VALIDATE_REQUIRE} from '@constants/validate';
import Button from '../../../../components/bases/shared/button';
import Iframe from 'react-iframe';
import {useTranslation} from 'react-i18next';

var _validFileExtensions = ['.pdf', '.xlsx', '.xls', '.doc', '.docx'];

function Validate(e) {
  var sFileName = e.target.value;
  if (sFileName.length > 0) {
    var blnValid = false;
    for (var j = 0; j < _validFileExtensions.length; j++) {
      var sCurExtension = _validFileExtensions[j];
      if (
        sFileName
          .substr(sFileName.length - sCurExtension.length, sCurExtension.length)
          .toLowerCase() === sCurExtension.toLowerCase()
      ) {
        blnValid = true;
        break;
      }
    }
    return blnValid;
  }
}

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type: mime});
}

const getPDF = (url, func, id, name) => {
  return axios({
    method: 'get',
    url: url,
    responseType: 'blob',
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
    crossdomain: true,
  }).then(function (response) {
    var reader = new FileReader();
    reader.readAsDataURL(response.data);
    reader.onloadend = function () {
      var file = dataURLtoFile(reader.result, name);
      func(file);
      setTimeout(() => {
        if (document.getElementById(id) !== null) {
          document.getElementById(id).setAttribute('src', reader.result);
        }
      }, 100);
    };
  });
};

const AuthoritySettingPage = () => {
  const {
    handleSubmit,
    register,
    formState: {errors},
    setValue,
  } = useForm();
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const info = useSelector((state) => state.checkin.authoritySetting.info);
  // console.log('info: ', info);

  const [listFile, setListFile] = useState('');
  const [submit, setSubmit] = useState(false);
  const [uploadActive, setUploadActive] = useState(false);

  const readURL = (input, ID) => {
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function (event) {
        if (document.getElementById(ID)) {
          document.getElementById(ID).setAttribute('src', event.target.result);
        }
      };
      reader.readAsDataURL(input.files[0]);
    }
  };

  const isFile = (event) => {
    if (Validate(event)) {
      var files = event.target.files[0];
      readURL(event.target, 'blah1');
      setListFile(files);
      setUploadActive(true);
      PNotify.success({
        title: t('Thành công'),
        text: t('Upload file thành công'),
        destroy: true,
        delay: 2000,
      });
    } else {
      PNotify.error({
        title: t('Lỗi'),
        text: t('Phải là file pdf'),
        destroy: true,
        delay: 2000,
      });
    }
  };

  useEffect(() => {
    if (info) {
      setValue('address', info.address);
      setValue('time', info.time);
      if (info.fileUrl) {
        setUploadActive(false);
        setSubmit(false);
        getPDF(`/${info.fileUrl}`, setListFile, 'blah1', 'mauuyquyen');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  useEffect(() => {
    dispatch(getInfoSettingAuthorityApi());
  }, [dispatch]);

  // console.log('listFile: ', listFile);
  const onSubmit = (values) => {
    // console.log("values:", values);
    setSubmit(true);
    if (listFile) {
      let formData = new FormData();
      formData.append('address', values.address);
      formData.append('time', values.time);
      formData.append('file', listFile);
      dispatch(settingAuthorityApi(formData));
      // console.log("formData: ", formData);
    } else {
      let formData = new FormData();
      formData.append('address', values.address);
      formData.append('time', values.time);
      dispatch(settingAuthorityApi(formData));
    }
  };

  return (
    <LayoutContent
      title={t('Cài đặt nội dung ủy quyền')}
      children={
        <SAuthoritySettingPage>
          <form className="setting_form" onSubmit={handleSubmit(onSubmit)}>
            <div className="item">
              <Input.Text
                wrapperProps={{
                  label: t('Địa chỉ nhận giấy ủy quyền'),
                  icon: inputIcons.address,
                }}
                inputProps={{
                  type: 'text',
                }}
                register={register('address', {
                  required: t(VALIDATE_REQUIRE),
                })}
              />
              <ErrorMessageComponent errors={errors} field="address" />
            </div>

            <div className="item">
              <Input.Text
                wrapperProps={{
                  label: t('Thời gian'),
                  icon: inputIcons.time,
                }}
                inputProps={{
                  type: 'text',
                }}
                register={register('time', {
                  required: t(VALIDATE_REQUIRE),
                })}
              />
              <ErrorMessageComponent errors={errors} field="time" />
            </div>

            <div className="item item_upload">
              <div className="upload_content">
                <input
                  type="file"
                  accept="application/pdf,application/vnd.ms-excel"
                  name=""
                  id=""
                  onChange={(e) => isFile(e)}
                />

                <div className="base_content">
                  <img src="../../images/icon_upload.png" alt="" />
                  <p className="text">
                    {t('Tải lên biểu mẫu ủy quyền')}{' '}
                    {listFile ? `(${listFile.name})` : ''}
                  </p>
                </div>
              </div>

              {/* <span className="error_notifi">
                {submit && !listFile ? 'File tải lên là bắt buộc' : ''}
              </span> */}
            </div>

            <div>
              {!submit && listFile && !uploadActive ? (
                listFile.type === 'application/pdf' ? (
                  <div className="item-result">
                    <div className="result-content">
                      <Iframe url={listFile} id="blah1" />
                    </div>
                  </div>
                ) : (
                  <div className="item result">
                    <a className="text" href={`/${info.fileUrl}`} download>
                      {listFile ? listFile.name : t('Chưa tải file biểu mẫu')}
                    </a>
                    <span id="blah1" style={{display: 'none'}} />
                  </div>
                )
              ) : (
                <div className="item result">
                  <p className="text">
                    {listFile ? listFile.name : t('Chưa tải file biểu mẫu')}
                  </p>
                </div>
              )}
            </div>

            <div className="item item_button">
              <Button type="submit">{t('Lưu')}</Button>
            </div>
          </form>
        </SAuthoritySettingPage>
      }
    ></LayoutContent>
  );
};

export default AuthoritySettingPage;
